import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from "@hookform/error-message";
import { TextFieldCustom as TextField } from 'components/forms/input/TextFieldCustom';
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "components/CustomButtons/Button";
import moment from 'moment';
import { AutocompleteComponent } from 'components/ReactHookForm/AutocompleteComponent';
import { createFilterOptions } from '@material-ui/lab';
import formStyles from "assets/jss/material-dashboard-react/forms/formStyle"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    width: "100%",
    marginBottom: "15px",
  },
  formControl2: {
    width: "100%",
    marginLeft: "10px",
    marginBottom: "15px",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  labelError: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
  button: {
    margin: 7,
  },
  ctnbtn: {
    display: "flex",
    justifyContent: "center",
  },
}));

const useFormStyles = makeStyles(formStyles);

const filter = createFilterOptions();

export const Form = ({ data, onSubmitForm }) => {

  const classes = useStyles();
  const formClasses = useFormStyles();
  const { control, handleSubmit, errors, reset } = useForm();

  const onSubmit = async (data) => {
    try {
      await onSubmitForm(data);
      reset(data);
    } catch (error) {
    }
  };

  const checkKeyDown = (e) => {
    if (e.code === "Enter") e.preventDefault();
  };

  const renderSwitch = (formDataComponent, index) => {
    switch (formDataComponent.component) {
      case "textField":
        return (
          <GridItem item {...formDataComponent.gridProps} key={index}>
            <FormControl error className={classes.formControl}>
              <Controller
                name={`${formDataComponent.name}`}
                control={control}
                defaultValue={formDataComponent.type === 'date' ? moment().format("YYYY-MM-DD") : formDataComponent.defaultValue ?? ''}
                as={<TextField />}
                error={errors[`${formDataComponent.name}`] ? true : false}
                helperText={""}
                label={`${formDataComponent.label}`}
                style={{ margin: 8 }}
                fullWidth
                margin="normal"
                rules={formDataComponent.validationOptions}
                type={formDataComponent.type ?? 'text'}
              />
              <ErrorMessage
                errors={errors}
                name={`${formDataComponent.name}`}
                render={({ message }) => (
                  <FormHelperText
                    className={formClasses.errorValidationLabel}
                  // id="component-error-text"
                  >
                    {message}
                  </FormHelperText>
                )}
              />
            </FormControl>
          </GridItem>
        );
      case "select":
        return (
          <GridItem item {...formDataComponent.gridProps} key={index}>
            <FormControl error={errors[formDataComponent.name] ? true : false} className={classes.formControl2} >
              <InputLabel shrink htmlFor={formDataComponent.name}>
                {formDataComponent.label}
              </InputLabel>
              <Controller
                name={formDataComponent.name}
                control={control}
                className={classes.formControl}
                defaultValue={formDataComponent.defaultValue ?? ''}
                rules={formDataComponent.validationOptions}
                render={({ onChange, value }) => (
                  <Select
                    name={formDataComponent.name}
                    value={value}
                    onChange={onChange}
                    labelId={formDataComponent.name}
                    label="Campaign Budget"
                  >
                    {formDataComponent.options.map((opt, index) => (
                      <MenuItem value={opt.value} disabled={opt.disabled} key={index}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <ErrorMessage
                errors={errors}
                name={`${formDataComponent.name}`}
                render={({ message }) => (
                  <FormHelperText
                    className={formClasses.errorValidationLabel}
                  // id="component-error-text"
                  >
                    {message}
                  </FormHelperText>
                )}
              />
            </FormControl>
          </GridItem>
        );

      case "autocompleteMultipleAsync":
        return (
          <GridItem item {...formDataComponent.gridProps} key={index}>
            <FormControl error={errors[formDataComponent.name] ? true : false} className={classes.formControl2} >
              <Controller
                // value={vacunaSelected}
                name={formDataComponent.name}
                defaultValue={formDataComponent.defaultValue}
                control={control}
                rules={formDataComponent.validationOptions}
                onChange={([, data]) => {
                  return data;
                }}
                render={({ onChange }) => (
                  <AutocompleteComponent
                    multiple={formDataComponent.multiple}
                    // defaultValue={formDataComponent.defaultValue}
                    reduxFunction={false}
                    label={formDataComponent.label}
                    name={formDataComponent.name}
                    type="text"
                    freeSolo={formDataComponent.freeSolo ?? false}
                    fullWidth
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {
                        onChange(newValue);
                      }
                      if (newValue && newValue.inputValue) {
                        onChange({
                          tipo: newValue.inputValue,
                          custom: true,
                        });
                      }

                      else if (newValue) {
                        onChange(newValue);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      // Suggest the creation of a new value
                      if (params.inputValue !== '') {
                        filtered.push({
                          inputValue: params.inputValue,
                          informacion: `personalizado: ${params.inputValue}`,
                        });
                      }

                      return filtered;
                    }}
                    optionLabel={option => {
                      if (option.inputValue) {
                        return option.inputValue;
                      }

                      if (typeof option === 'string') {
                        return (option);
                      }

                      return (option.nombre);
                    }
                    }
                    // getOptionSelected={
                    //   (option, value) => option.informacion === value
                    // }
                    dispatchFunction={formDataComponent.dispatchFunction}
                    dataSearch={formDataComponent.options}
                  />
                )}
              />
            </FormControl>

          </GridItem>

        );
      case "autocompleteMultipleV2":
        return (
          <GridItem item {...formDataComponent.gridProps}>
            <FormControl error className={classes.formControl} style={{ paddingLeft: '5px' }}>
              <Controller
                defaultValue={formDataComponent.defaultValue}
                // value={formDataComponent.defaultValue}
                name={formDataComponent.name}
                reduxFunction={null}
                control={control}
                rules={formDataComponent.validationOptions}
                onChange={([, data]) => {
                  return data;
                }}
                render={({ onChange }) => formDataComponent.freeSolo ? (
                  <AutocompleteComponent
                    multiple={formDataComponent.multiple}
                    defaultValue={formDataComponent.defaultValue}
                    reduxFunction={false}
                    label={formDataComponent.label}
                    name={formDataComponent.name}
                    type="text"
                    freeSolo={formDataComponent.freeSolo ?? false}
                    fullWidth
                    onChange={(event, newValue) => {
                      console.log({ newValue })
                      if (typeof newValue === 'string') {
                        onChange(newValue);
                      }
                      if (newValue && newValue.inputValue) {
                        onChange({
                          tipo: newValue.inputValue,
                          custom: true,
                        });
                      }

                      else if (newValue) {
                        onChange(newValue);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      // Suggest the creation of a new value
                      if (params.inputValue !== '') {
                        filtered.push({
                          inputValue: params.inputValue,
                          informacion: `personalizado: ${params.inputValue}`,
                        });
                      }

                      return filtered;
                    }}
                    optionLabel={option => {
                      if (option.inputValue) {
                        return option.inputValue;
                      }

                      if (typeof option === 'string') {
                        return (option);
                      }

                      return (option.nombre);
                    }
                    }
                    // getOptionSelected={
                    //   (option, value) => option.informacion === value
                    // }
                    dispatchFunction={formDataComponent.dispatchFunction}
                    dataSearch={formDataComponent.options}
                  />
                ) : (
                  <AutocompleteComponent
                    multiple={formDataComponent.multiple}
                    defaultValue={formDataComponent.defaultValue}
                    reduxFunction={false}
                    label={formDataComponent.label}
                    name={formDataComponent.name}
                    type="text"
                    freeSolo={true}
                    fullWidth
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {
                        onChange(newValue);
                      }
                      if (newValue && newValue.inputValue) {
                        onChange({
                          tipo: newValue.inputValue,
                          custom: true,
                        });
                      }

                      else if (newValue) {
                        onChange(newValue);
                      }
                    }}
                    optionLabel={option => {
                      if (option.inputValue) {
                        return option.inputValue;
                      }

                      if (typeof option === 'string') {
                        return (option);
                      }

                      return (option.nombre);
                    }
                    }
                    dispatchFunction={formDataComponent.dispatchFunction}
                    dataSearch={formDataComponent.options}
                  />
                )

                }
              />
            </FormControl>

          </GridItem>

        );
      default:
        return
    }
  }

  const formRenderElements = data.map((fc, index) => <React.Fragment key={index}>{renderSwitch(fc, index)}</React.Fragment>);

  return (
    <>
      <form
        onSubmit={handleSubmit((data) =>
          onSubmit(data)
        )}
        onKeyDown={(e) => checkKeyDown(e)}
      >
        <GridContainer>
          {formRenderElements}
        </GridContainer>
        <div
          style={{
            flex: '1',
            display: 'flex',
            margin: '20px 8px',
            justifyContent: 'center',
          }}
        >
          <Button variant="outlined" color="warning" type="submit">
            Guardar
          </Button>
        </div>

      </form>

    </>

  )
}
