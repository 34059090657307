import { urlDoctorOficinasSucursales } from "constants/urls";

export const getDoctorSucursalesAsociacion = async (data) => {
  const { requestOptions, doctorId } = data;
  const { token } = requestOptions;

  const requestOpt = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  };

  const url = doctorId ? (`${urlDoctorOficinasSucursales}/${doctorId}`) : urlDoctorOficinasSucursales

  const response = await fetch(url, requestOpt);
  const datos = await response.json();

  if (datos.transaccion) return datos.data;

  throw (new Error('Error al recuperar los datos'))

}