import React, { useEffect } from 'react'
import "../../styles/landing/landing.scss"
import gestorDevices from "../../assets/images/gestor_devices.png";
import { RegisterForm } from 'components/forms/register/native/RegisterForm';

export const Home = () => {

  useEffect(() => {
    for (var i = 0; i < document.querySelectorAll(".features_app_elements").length; i++) {
      document.querySelectorAll(".features_app_elements")[i].style.display = "flex";
    }
  }, [])


  const handleClickCarrusel = (index) => {
    document.querySelector("#carrbtn2").style.backgroundColor = index === 1 ? "white" : 'var(--main-yellow)';
    document.querySelector("#carrbtn1").style.backgroundColor = index === 1 ? "var(--main-yellow)" : 'white';

    if (index === 1) {
      for (let j = 0; j < document.querySelectorAll(".features_app_elements").length; j++) {
        document.querySelectorAll(".features_app_elements")[j].style.display = "flex";
      };
      for (let j = 0; j < document.querySelectorAll(".features_app_elements").length; j++) {
        document.querySelectorAll(".features_web_elements")[j].style.display = "none";
      };
    } else {
      for (let j = 0; j < document.querySelectorAll(".features_web_elements").length; j++) {
        document.querySelectorAll(".features_app_elements")[j].style.display = "none";
      };
      for (let j = 0; j < document.querySelectorAll(".features_app_elements").length; j++) {
        document.querySelectorAll(".features_web_elements")[j].style.display = "flex";
      };
    }

  }

  return (
    <div className='landing-page'>
      {/* Sections */}
      <div className="section1 even">
        <div className="hero">
          <div className="medpal_logo_h"></div>
          <div className="medpal_logo_v"></div>
          <h1>Tu plataforma</h1>
          <h1>integral de salud</h1>
          <br></br>
          <ul>
            <li>Gestor de consultorio</li>
            <li>Directorio médico</li>
            <li>Campañas médicas</li>
          </ul>
          <button className="registrarse" onClick={() => window.location.href = '#form'}>Regístrate</button>
        </div>
        <div className="hero_devices"></div>
      </div>

      <div className="section2 even">
        <div className="features1 regular_container">
          <h1>Con MedPal obtendrás</h1>
          <h2>Una variedad de herramientas que facilitan la gestión de tu consultorio,
            además de una innovadora plataforma móvil donde podrás administrar tu agenda fácilmente
          </h2>
          <div id="img_feature1" className="img"></div>
        </div>
      </div>
      <div className="section3 even">
        <h1 className="features_app_elements">Características especiales de la App</h1>
        <h1 className="features_web_elements">Características especiales de la Web</h1>

        <div className="carrusel">

          <div className="features_carrusel">
            <div className="text_features_web features_web_elements">
              <h4>Vademécum en recetario</h4>
              <h3>Contraindicaciones, dosificación, función y más</h3>
              <h4>Campañas médicas</h4>
              <h3>Un simple pero potente sistema de difusión de tus
                servicios médicos a los usuarios de la plataforma</h3>

            </div>
            <div className="text_features_app features_app_elements">
              <h4>Directorio médico</h4>
              <h3>Búsqueda avanzada para encontrar el
                servicio médico que necesitas y
                realizar reservas online</h3>
              <h4>Manejo de citas</h4>
              <h3>Toda la información necesaria
                para que tus pacientes
                reserven citas y tú las
                administres</h3>
            </div>
          </div>

          {/* Carrusel */}
          <div className="img_carrusel_app features_app_elements"></div>
          <div className="img_carrusel_web features_web_elements"></div>

          <div className="features_carrusel">
            <div className="text_features_web features_web_elements">
              <h4>Manejo de citas</h4>
              <h3>Administra con facilidad la gestión de tus citas</h3>
              <h4>Módulo de Secretaría</h4>
              <h3>Manejo de citas, caja chica, configuración de horarios,
                y más herramientas para optimizar la gestión de consultorio
                por medio de tu secretaria</h3>
            </div>
            <div className="text_features_app features_app_elements">
              <h4>Recordatorios</h4>
              <h3>Recordatorios para tus pacientes de citas y toma de medicamentos</h3>
              <h4>Beneficios exclusivos Medpal</h4>
              <h3>Precios preferenciales tanto en consultas como en
                servicios de salud específicos</h3>
            </div>
          </div>
        </div>
        <div className="carrusel_buttons">
          <div id="carrbtn1" onClick={() => handleClickCarrusel(1)}></div>
          <div id="carrbtn2" onClick={() => handleClickCarrusel(2)}></div>
        </div>
      </div>

      <div className="section4 even">
        <div className="features3 regular_container">
          <h1>¡Descarga Ya!</h1>
          <h2>Y aprovecha todos los beneficios que tenemos para ti</h2>
        </div>
        <div className="stores">
          <div className="playstore"></div>
          <div className="appstore"></div>
        </div>
      </div>

      <div className="section5 odd">
        <div className="features4">
          <h1>Herramientas del gestor médico</h1>
          <div className="gestor_features">
            <div className="historial">
              <div id="img_gestor1"></div>
              <h3>Historial Clínico</h3>
              <ul>
                <li>Información guardada en la nube</li>
                <li>Recetario con vademécum online/offline</li>
              </ul>
            </div>
            <div className="consultorios">
              <div id="img_gestor2"></div>
              <h3>Manejo de consultorios</h3>
              <ul>
                <li>Maneja hasta 3 consultorios</li>
                <li>Horarios, ubicación, servicios</li>
              </ul>
            </div>
            <div className="herramientas">
              <div id="img_gestor3"></div>
              <h3>Herramientas Especializadas</h3>
              <ul>
                <li>Respaldo de resultado de exámenes</li>
                <li>Registro de vacunación</li>
              </ul>
            </div>
          </div>
        </div>
        <div id="img_feature4_container">
          <img id="img_feature4" src={gestorDevices} alt="gestorDevices" />
        </div>
      </div>
      
      {/* Formulario de registro */}
      <RegisterForm />
    </div>
  )
}
