import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInputOld from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import WarningLeaveFormPage from "components/ui/WarningLeaveFormPage";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Loading from "components/Loading";
import medicoSVG from "assets/images/icons/medico.svg";
import { cambioPass, loadProfile } from "redux/actions/aLogin";
import Notificacion from "components/ui/Notificacion";
import Chip from '@material-ui/core/Chip';
import _ from 'lodash';
import moment from "moment";
import { getEspecialidades } from "redux/actions/aEspecialidades";
import { urlBucket } from "constants/urls";
import { encriptarRsa } from "utils/encriptar";
import Link from '@material-ui/core/Link';
import { useHistory } from "react-router-dom";
import imgMembership from 'assets/images/icons/afiliacion_mepal.svg';
import { ModalInfo } from "components/Modal/ModalInfo";
import ConfirmDialog from "components/Admin/CRUD/ConfirmDialog";
import dashboardStyles from "assets/jss/material-dashboard-react/views/dashboardStyle";
import { FormErrorLabel } from "components/forms/labels/FormErrorLabel";
import { ModalRequestAccountDeletion } from "components/Modal/ModalRequestAccountDeletion";
import { Controller, useForm } from "react-hook-form";
import CustomInput from "components/CustomInput/controlled/CustomInput";
import { rhRUCValidation } from "utils/validaciones";
import { rhRequiredEmailValidation } from "utils/validaciones";
import { rhRequiredFieldValidation } from "utils/validaciones";
import { AutocompleteComponent } from "components/ReactHookForm/AutocompleteComponent";
import { guardarPerfilInstitucion } from "redux/actions/aInstitucion";

const useStyles = makeStyles((theme) => (
  {
    ...dashboardStyles,
    label: {
      color: '#999 !important',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '500',
      lineHeight: '1.42857 !important',
    },
    chip: {
      margin: "3px",
      color: 'black',
      fontWeight: '400',
    },
    cardBody: {
      '& .MuiGrid-item': {
        marginTop: '27px',
      }
    }
  }
));

export const InstitucionPerfil = () => {

  const history = useHistory();

  const [dataReseted, setDataReseted] = useState(false);

  const [formDataAux, setFormDataAux] = useState({});

  const user = useSelector((state) => state.dataUser.data);

  const formDefaultValues = useMemo(() => ({
    nombre: user.institucion.nombre ?? '',
    ciudad: user.institucion.ciudad ?? '',
    pais: user.institucion.pais ?? '',
    direccion: user.institucion.direccion ?? '',
    longitud: user.institucion.longitud ?? '',
    latitud: user.institucion.latitud ?? '',
    logo_img: user.institucion.logo_img ?? '',
    razon_social: user.institucion.razon_social ?? '',
    RUC: user.institucion.RUC ?? '',
    email: user.institucion.email ?? '',
    telefono: user.institucion.telefono ?? '',
    disponible: user.institucion.disponible,
    cuenta_aprobada: user.institucion.cuenta_aprobada,
    login: user.login,
    especializaciones: !!user.especializaciones ? user.especializaciones : [],
  }), [user]);

  const { trigger, handleSubmit, reset, watch, formState: { errors, isDirty: formDirty }, control, } = useForm({
    defaultValues: formDefaultValues,
    mode: "onChange",
  });

  const especializaciones = useMemo(() => watch('especializaciones'), [watch]);


  useEffect(() => {
    reset(formDefaultValues);
  }, [formDefaultValues, reset])

  React.useEffect(() => {
    dataReseted && trigger();
  }, [trigger, dataReseted])


  const classes = useStyles();
  const dispatch = useDispatch();

  const state = useSelector((state) => state);

  const especialidades = useSelector((state) => state.data_especialidades.data);

  //Modal afiliado
  const [openModalAfiliado, setOpenModalAfiliado] = useState(false);

  // Confirm dialog
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  // const [errors, seterrors] = useState({});
  // const [error, seterror] = useState({ title: "", mensaje: "" });
  const [loading, setloading] = useState(false);
  const [imgTemp, setImgTemp] = useState(null);

  const valoresInit = useRef({
    login: !!user.login ? user.login : "",
    titulo_honorifico: !!user.titulo_honorifico
      ? user.titulo_honorifico
      : "",
    email: !!user.email ? user.email : "",
    nombres: !!user.nombres ? user.nombres : "",
    apellidos: !!user.apellidos ? user.apellidos : "",
    msp: !!user.msp ? user.msp : "",
    acess: !!user.acess ? user.acess : "",
    numero_telefono: !!user.numero_telefono ? user.numero_telefono : "",
    practica_desde: !!user.practica_desde
      ? moment.utc(user.practica_desde).format("YYYY-MM-DD")
      : null,
    declaracion_profesional: !!user.declaracion_profesional
      ? user.declaracion_profesional
      : "",
    especializaciones: !!user.especializaciones
      ? user.especializaciones
      : [],
    med_activo: user.med_activo ? user.med_activo : false,
    med_visible: user.med_visible ? user.med_visible : false,
    foto_nombre: !!user.foto_nombre ? user.foto_nombre : "",
    cedula_identidad: !!user.cedula_identidad ? user.cedula_identidad : "",
  });
  const [valores, setvalores] = useState(valoresInit.current);

  const [categoriasEspecialidades, setCategoriasEspecialidades] = useState([]);

  // Request account deletion
  const [openRequestDeletionAccountModal, setOpenRequestDeletionAccountModal] = useState(false);

  const [pass, setpass] = useState({
    anterior: "",
    nueva: "",
    nuevaConfirmacion: "",
  });

  const fileField = React.useRef(null);

  function handleFileSelected(e) {

    const file = e.target.files[0];
    // divido para dos para 5MB max
    if (file.size / 1024 / 1024 < 5) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setvalores({ ...valores, foto_nombre: reader.result });
        setImgTemp(reader.result);
      };
    } else {
    }
  }

  const handleChangePass = (e) => {

    const { name, value } = e.target;
    setpass({ ...pass, [name]: value });
  };

  const handleClickCambiar = async () => {
    try {
      setloading(true);
      if (pass.nueva === pass.nuevaConfirmacion) {
        if (pass.nueva.length < 5 || !/.*^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/.test(pass.nueva)) {
          setloading(false);
          Notificacion({
            type: "error",
            text: `La contraseña debe tener al menos 5 caracteres, al menos una mayúscula, una minúscula y un número`,
          });
          return;
        } else {
          const data = await encriptarRsa(
            JSON.stringify({ anterior: pass.anterior, nueva: pass.nueva })
          );
          await dispatch(cambioPass(data, state));
          setloading(false);

        }
      } else {
        setloading(false);
        Notificacion({
          type: "error",
          text: `La contraseña nueva no coincide!`,
        });

      }
    } catch (error) {
      setloading(false);
      Notificacion({
        type: "error",
        text: `Ocurrio un error, intente nuevamente ${error}`,
      });
      // seterror(`Ocurrio un error, intente nuevamente ${error}`);
    }
  };

  const handleClickLinkNuevaEspecialidad = () => {
    const data = { asunto: "Nueva especialidad", mensaje: "" }
    localStorage.setItem("soporteMensaje", JSON.stringify(data));
    history.push('/medico/ayuda');
  }

  const onSubmit = async (data) => {
    try {

      // Verificar cambios en especialidades
      const oldEsp = _.sortBy(formDefaultValues.especializaciones.map(esp => esp.id));
      const newEsp = _.sortBy(data.especializaciones.map(esp => esp.id));

      const specialtiesUpdate = !(_.isEqual(oldEsp, newEsp));

      if (specialtiesUpdate) {
        setOpenConfirmDialog(true);
        setFormDataAux(data);
        return
      }

      saveProfile(data);

    } catch (err) {
      Notificacion({
        type: "error",
        text: "Ocurrio un error, intente nuevamente",
      });
      setloading(false);
    }

  };

  const saveProfile = async (data) => {

    const dataToSend = { ...data, logo_img: imgTemp }

    setloading(true);
    await dispatch(guardarPerfilInstitucion(dataToSend, state));
    if (imgTemp) setImgTemp(null);
    setFormDataAux({});
    setloading(false);
  }

  const onSubmitError = () => {
    Notificacion({
      type: "error",
      text: "Debe completar los campos correctamente.",
    });
  }

  useEffect(() => {

    let categoriasIdAgregadosAux = [];
    let categoriasEspecialidadesAux = [];

    if (especializaciones && Array.isArray(especializaciones)) {
      especializaciones.map((esp) => (
        esp.categorias && esp.categorias.map(cat => {
          if (!categoriasIdAgregadosAux.includes(cat.id)) {
            categoriasIdAgregadosAux.push(cat.id);
            categoriasEspecialidadesAux.push(cat);
          }
          return null;
        })
      ))
    }

    setCategoriasEspecialidades(categoriasEspecialidadesAux);

  }, [especializaciones]);

  useEffect(() => {
    dispatch(loadProfile()).then(res => {
      setDataReseted(true);
    });
    dispatch(getEspecialidades());
  }, [dispatch]);


  return (
    <Loading title="Cargando..." loading={loading}>
      <>
        {/* Warning on exit page */}
        <WarningLeaveFormPage when={formDirty} />

        {/* Modal request deletion account*/}
        <ModalRequestAccountDeletion
          open={openRequestDeletionAccountModal}
          onCloseModal={() => setOpenRequestDeletionAccountModal(false)}
        />
        {/* End Modal request deletion account */}

        {/* Modal Afiliado */}
        <ModalInfo open={openModalAfiliado} onCloseModal={() => setOpenModalAfiliado(false)} img={imgMembership} title={'Afiliación MedPal'}>
          {/* Contenido */}
          A los profesionales que afilien su consultorio se les otorga beneficios que comprenden una gran campaña promocional de sus servicios, tener un posicionamiento privilegiado dentro del directorio, y acceso a las herramientas de gestión de consultorio sin costo.
          Al registrarse como afiliado aprueba que los usuarios de la plataforma puedan acceder a un descuento del 50% en las consultas.
          El descuento es únicamente aplicado para consulta y no se aplica a otro servicio que haya registrado.
          El médico es libre de afiliarse o desafiliarse cuando lo desee, tomando en cuenta las siguientes limitaciones:
          El profesional debe considerar que las plazas son limitadas.
          El profesional que decide desafiliarse no podrá volver a afiliar ese consultorio en un periodo de al menos 30 días y no se podrá garantizar su cupo previamente adquirido.
          Los beneficios se mantendrán para los usuarios/pacientes que hayan separado cita en el tiempo en el que el profesional está afiliado, incluso si a la fecha de realizar la consulta ya no se encuentra afiliado.
          Solo los médicos que mantengan la afiliación por un tiempo prolongado, serán considerados para las campañas realizadas en redes sociales o por cualquier otro medio por parte de MedPal.
        </ModalInfo>
        {/* Fin Modal Afiliado */}

        {/* Confirmacion de guardado */}
        <ConfirmDialog
          title={"Confirmación de guardado"}
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onConfirm={() => saveProfile(formDataAux)}
        >
          <div>Ha realizado cambios en la lista de especialidades. Los servicios relacionados con las especialidades eliminadas de la lista seran eliminados. ¿Desea confirmar los cambios?</div>
        </ConfirmDialog>
        {/* Fin confirmacion de guardado */}

        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <form className={classes.root} onSubmit={handleSubmit(onSubmit, onSubmitError)} noValidate="noValidate" autoComplete="off">
                <CardHeader color="primary">
                  <h4 className={classes.cardTitle}>Configuración Perfil Institución</h4>
                </CardHeader>
                {
                  !formDefaultValues.cuenta_aprobada &&
                  <div style={{ marginTop: "10px" }}>
                    <Alert severity="info">Su cuenta está siendo verificada, la respuesta será enviada a su correo electrónico</Alert>
                  </div>
                }
                <CardBody className={classes.cardBody}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        {(formDefaultValues.logo_img !== "" || imgTemp) && (
                          <>
                            <img
                              width="150px"
                              // src={`${urlBucket}${valores.foto_nombre}`}
                              src={imgTemp ? imgTemp : `${urlBucket}${formDefaultValues.logo_img} `}
                              alt='imgPerfil'
                            />
                            <input
                              type="file"
                              name="imagen"
                              id="imagen"
                              accept=".jpeg, .png, .jpg"
                              onChange={handleFileSelected}
                              ref={fileField}
                            />
                          </>
                        )}
                        {formDefaultValues.logo_img === "" && (
                          <>
                            {" "}
                            <img width="150px" src={medicoSVG} alt='imgPerfil' />{" "}
                            <input
                              type="file"
                              name="imagen"
                              id="imagen"
                              accept=".jpeg, .png, .jpg"
                              onChange={handleFileSelected}
                              ref={fileField}
                            />
                          </>
                        )}
                      </div>
                    </GridItem>

                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        label="Nombre de usuario"
                        id="login"
                        name="login"
                        errors={errors}
                        control={control}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        disabled
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>

                      <CustomInput
                        label="* RUC"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        control={control}
                        id="RUC"
                        name="RUC"
                        errors={errors}
                        rules={rhRUCValidation}
                      />

                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        label="* Email"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        control={control}
                        id="email"
                        name="email"
                        errors={errors}
                        rules={rhRequiredEmailValidation}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        label="* Nombre de la institución"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        control={control}
                        id="nombre"
                        name="nombre"
                        errors={errors}
                        rules={rhRequiredFieldValidation}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        label="* Razón social"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        control={control}
                        id="razon_social"
                        name="razon_social"
                        errors={errors}
                        rules={rhRequiredFieldValidation}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        label="Teléfono"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        control={control}
                        id="telefono"
                        name="telefono"
                        errors={errors}
                      // rules={rhRequiredFieldValidation}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>

                      <Controller
                        name={'especializaciones'}
                        reduxFunction={null}
                        control={control}
                        // rules={formDataComponent.validationOptions}
                        onChange={([, data]) => {
                          return data;
                        }}
                        render={({ onChange, value }) =>
                          <AutocompleteComponent
                            value={value}
                            multiple
                            reduxFunction={false}
                            label={'Especialidades'}
                            name='especializaciones'
                            type="text"
                            freeSolo={true}
                            fullWidth
                            onChange={(event, newValue) => {
                              if (typeof newValue === 'string') {
                                onChange(newValue);
                              }
                              if (newValue && newValue.inputValue) {
                                onChange({
                                  tipo: newValue.inputValue,
                                  custom: true,
                                });
                              }

                              else if (newValue) {
                                onChange(newValue);
                              }
                            }}
                            optionLabel={(option => {
                              return (option.nombre ?? '');
                            })
                            }

                            dataSearch={especialidades}
                          />

                        }
                      />
                      <Link
                        style={{ fontWeight: "bold" }}
                        href="#"
                        onClick={() => handleClickLinkNuevaEspecialidad()}
                        component="button"
                        variant="body2"
                        color="primary"
                      >
                        ¿No está listado tu especialidad?, Anótala aquí.
                      </Link>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>

                      <div>
                        <label className={classes.label} htmlFor="">
                          Categorías de especialidades
                        </label>
                        <div style={{ display: 'flexWrap' }}>
                          {
                            categoriasEspecialidades.map(cat => (
                              <Chip className={classes.chip} key={cat.id} label={cat.nombre} />
                            ))
                          }
                        </div>
                        {
                          (categoriasEspecialidades.length === 0) &&
                          <div>
                            <p>No tienes especialidades seleccionadas</p>
                          </div>
                        }

                      </div>

                    </GridItem>
                    <GridItem xs={6} md={6}>
                      <Controller
                        name="disponible"
                        control={control}
                        render={({ onChange, value }) => {
                          return (
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={value}
                                  onChange={(_, val) => onChange(val)}
                                  color="primary"
                                />
                              }
                              label="Disponible"
                            />
                          )
                        }}
                      />
                    </GridItem>

                  </GridContainer>

                  {/* Contraseña */}
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInputOld
                        labelText="Contraseña anterior"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        id="anterior"
                        inputProps={{
                          onChange: handleChangePass,
                          name: "anterior",
                          value: valores.anterior,
                          type: "password",
                          placeholder: "********",
                        }}
                      />
                      {errors.anterior && (
                        <FormErrorLabel>{errors.anterior}</FormErrorLabel>
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInputOld
                        labelText="Nueva contraseña"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        id="nueva"
                        inputProps={{
                          onChange: handleChangePass,
                          name: "nueva",
                          value: valores.nueva,
                          placeholder: "********",
                          type: "password",
                        }}
                      // error={errors.nueva}
                      // success={!errors.nueva}
                      />
                      {errors.nueva && (
                        <FormErrorLabel>{errors.nueva}</FormErrorLabel>
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInputOld
                        labelText="Confirmar contraseña"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        id="nuevaConfirmacion"
                        inputProps={{
                          onChange: handleChangePass,
                          name: "nuevaConfirmacion",
                          value: valores.nuevaConfirmacion,
                          placeholder: "********",
                          type: "password",
                        }}
                      // error={errors.nuevaConfirmacion}
                      // success={!errors.nuevaConfirmacion}
                      />
                      {errors.nuevaConfirmacion && (
                        <FormErrorLabel>{errors.nuevaConfirmacion}</FormErrorLabel>
                      )}
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <Button color="primary" type="submit" >
                    Actualizar Perfil
                  </Button>
                  <Button color="warning" onClick={() => handleClickCambiar()}>
                    Cambiar contraseña
                  </Button>
                  <Button color="danger" onClick={() => setOpenRequestDeletionAccountModal(true)}>
                    Eliminar cuenta
                  </Button>
                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    </Loading>
  );
}
