import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ServiciosCrud } from 'components/Medico/Servicios/ServiciosCrud';
import Loading from 'components/Loading';
import Notificacion from "components/ui/Notificacion";
import { servicioAsignarOficinas } from 'redux/actions/aMedico';

const ServiciosMedico = ({ doctorId: doctor_id, createService, updateService, deleteService, loadServices }) => {

  const [loading, setLoading] = useState(true);
  const [serviciosData, setServiciosData] = useState([]);
  const [serviciosLimite, setServiciosLimite] = useState(0);

  const dispatch = useDispatch();

  const onCreate = async (servicio, oficinas = []) => {
    try {
      const dataToSend = { ...servicio, oficinas };
      const { data } = await dispatch(createService(dataToSend));
      setServiciosData([...serviciosData, data]);
      Notificacion({
        type: "success",
        text: "Datos guardados exitosamente.",
      });

    } catch (error) {
      Notificacion({
        type: "error",
        text: error.toString(),
      });
    }
  }
  const onDelete = async (servicioId) => {
    try {
      setLoading(true);
      await dispatch(deleteService(servicioId));
      setServiciosData(serviciosData.filter(servicio => servicio.id !== servicioId));
      setLoading(false);
      Notificacion({
        type: "success",
        text: "Datos eliminados exitosamente.",
      });
    } catch (error) {
      setLoading(false);
      Notificacion({
        type: "error",
        text: "Ha ocurrido un error al eliminar el registro",
      });
    }
  }

  const onUpdate = async (servicio, oficinas = []) => {
    try {
      const dataToSend = { ...servicio, oficinas };
      const { data } = await dispatch(updateService(servicio.id, dataToSend));
      setServiciosData(serviciosData.map(serv => serv.id === servicio.id ? data : serv));
      Notificacion({
        type: "success",
        text: "Datos actualizados exitosamente.",
      });
    } catch (error) {
      Notificacion({
        type: "error",
        text: "Ha ocurrido un error al actualizar el registro",
      });
    }
  }

  const onSaveOficinasAsignadas = async (servicio, oficinasId) => {
    try {
      const { data } = await dispatch(servicioAsignarOficinas(servicio.id, { oficinas: oficinasId }));
      setServiciosData(serviciosData.map(serv => serv.id === servicio.id ? data : serv));
      Notificacion({
        type: "success",
        text: "Datos actualizados exitosamente.",
      });
    } catch (error) {
      Notificacion({
        type: "error",
        text: "Ha ocurrido un error al actualizar el registro",
      });
    }
  }

  const init = useCallback(
    async () => {
      try {
        const { data, empresa } = await dispatch(loadServices());
        setServiciosLimite(empresa?.servicios_limite ?? 0)
        setServiciosData(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Notificacion({
          type: "error",
          text: "No se pudo recuperar la información recargue la página ",
        });
      }
    },
    [dispatch, loadServices],
  )

  useEffect(() => {
    init();
  }, [init])


  return (
    <Loading title="Cargando..." loading={loading}>
      <ServiciosCrud servicios={serviciosData} doctorId={doctor_id} onCreate={onCreate} onDelete={onDelete} onUpdate={onUpdate} onSaveOficinasAsignadas={onSaveOficinasAsignadas} loading={loading} serviciosLimite={serviciosLimite} />
    </Loading>
  );
};


ServiciosMedico.propTypes = {
  doctorId: PropTypes.string.isRequired,
  loadServices: PropTypes.func.isRequired,
  createService: PropTypes.func.isRequired,
  updateService: PropTypes.func.isRequired,
  deleteService: PropTypes.func.isRequired,
}

export default ServiciosMedico;
