import React from "react";
import TableCell from "@material-ui/core/TableCell";

export default ({ classes = "", data }) => {
  return (
    <TableCell size="small" className={classes} align={"left"}>
      {data}
    </TableCell>
  );
};
