import React, { useCallback, useEffect, useState } from "react";
import _ from 'lodash';
import moment from "moment";
import {
  Fade,
  FormControl,
  Grid,
  Input,
  InputLabel,
  Modal,
  TableHead,
  TableSortLabel,
  TextField,
} from "@material-ui/core";
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

import { loadData, deleteRecord, responderContacto } from 'redux/actions/aAdmin';

import Notificacion from "components/ui/Notificacion";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CustomTableCellCrud from "components/Admin/CRUD/CustomTableCellCrud";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import Loading from "components/Loading";
import { TableRowCrud } from "components/Admin/CRUD/TableRowCrud";
import ConfirmDialog from "components/Admin/CRUD/ConfirmDialog";
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import CustomButton from "components/CustomButtons/Button";
import { NotificationContainer } from 'react-notifications';
import { AdminLayout } from "./AdminLayout";
import { urlListarContactos, urlEliminarContacto } from "constants/urls";
import { SearchTextField } from "components/forms/input/SearchTextField";
import { TableContainer } from "components/Table/TableContainer";

let useStyles = makeStyles(styles);

const TableCellHeader = withStyles((theme) => {
  return {
    head: {
      color: theme.palette.common.white,
      fontWeight: "bolder",
    },
  };
})(TableCell);

const rowsPerPageDefault = 20;

const Contacto = () => {
  const classes = useStyles();
  // 0: tabla, 1:crear, 2: editar
  const [view, setView] = useState(0);
  const [itemSelected, setItemSelected] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");

  //Ordenamiento
  const [orderData, setOrderData] = useState({
    sortBy: 'fecha', // default sort column
    sortOrder: "desc" // default sort oder
  })

  const { sortBy, sortOrder } = orderData;

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const { email: emailContactoSelected, } = itemSelected;

  const dispatch = useDispatch();
  const { dataCRUD, dataPagination } = useSelector((state) => state.data_admin);
  const { total } = dataPagination;

  const [openModalRol, setOpenModal] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageDefault);
  const [skipLocal, setSkipLocal] = React.useState(0);

  // Formulario responder mensaje
  const [errorForm, setErrorForm] = useState({ type: '', title: '', mensaje: '' });
  const [loadingReplyMessage, setloadingReplyMessage] = useState(false);
  const [asunto, setAsunto] = useState('');
  const [mensaje, setMensaje] = useState('');

  const handleInputSearchChange = ({ target }) => {
    setSearch(target.value);
    debouncedSearch(target.value);
  }

  const debouncedSearch = React.useRef(
    _.debounce(async (searchValue) => {
      setSearchValue(searchValue);
    }, 350)
  ).current;

  const handleChangePage = (event, newPage) => {
    if (page > newPage) {
      setSkipLocal(skipLocal - rowsPerPage);
    } else {
      setSkipLocal(skipLocal + rowsPerPage);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickDelete = (item) => {
    setItemSelected(item);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = async () => {
    !loading && setLoading(true);
    let respuesta = await dispatch(deleteRecord(urlEliminarContacto, itemSelected.id));
    setLoading(false);
    const { transaccion, mensaje } = respuesta;
    !transaccion ? setError(mensaje) : setError(false);
  };

  const handleRequestSort = useCallback(
    async (sortByColumn) => {
      // console.log(sortBy);
      // console.log(sortOrder);
      let sortOrderNew;
      if (!sortBy || sortByColumn !== sortBy) {
        sortOrderNew = "asc";
        setOrderData({
          sortBy: sortByColumn, // default sort column
          sortOrder: sortOrderNew // default sort oder
        })
      } else {
        if (sortByColumn === sortBy) {
          sortOrderNew = sortOrder === "asc" ? "desc" : "asc"
          setOrderData({
            sortBy: sortByColumn, // default sort column
            sortOrder: sortOrderNew// default sort oder
          })
        }
      }
    },
    [sortBy, sortOrder],
  );

  const handleOpenModal = useCallback(
    (item) => {
      setOpenModal(true);
      setItemSelected(item);
    },
    [],
  );
  const handleCloseModalRol = useCallback(
    () => {
      setOpenModal(false);
      setItemSelected({});
      setErrorForm({ type: '', title: '', mensaje: '' });
      setAsunto("");
      setMensaje("");
    },
    [],
  );

  const handleReplyMessage = useCallback(
    async () => {
      console.log(itemSelected);
      setloadingReplyMessage(true);
      // return
      if (asunto.length === 0 || mensaje.length === 0) {
        setloadingReplyMessage(false);
        let mensajeErr = "Debe completar los campos: ";
        mensajeErr = asunto.length === 0 ? `${mensajeErr} Asunto,` : mensajeErr;
        mensajeErr = mensaje.length === 0 ? `${mensajeErr} Mensaje,` : mensajeErr;

        setErrorForm({ type: 'error', title: 'error', mensaje: mensajeErr.replace(/.$/, ".") });
        return;
      }
      setErrorForm({ type: '', title: '', mensaje: '' });

      let respuesta = await dispatch(responderContacto({ asunto, mensaje, email: emailContactoSelected }, itemSelected.id));
      console.log({ respuesta });
      setloadingReplyMessage(false);
      const { transaccion, mensaje: respMensaje } = respuesta;
      console.log({ respMensaje, transaccion });
      (!transaccion) ? setErrorForm({ type: 'error', title: 'error', mensaje: respMensaje }) : setErrorForm({ type: '', title: '', mensaje: '' });
      if (transaccion) {
        setOpenModal(false)
        Notificacion({
          type: "success",
          text: "Mensaje enviado correctamente",
        });
        setItemSelected({});
        setErrorForm({ type: '', title: '', mensaje: '' });
      }

    },
    [asunto, mensaje, itemSelected, dispatch, emailContactoSelected],
  );

  const titulosTable = [
    { title: "Id", onClickSort: () => handleRequestSort("id"), columnName: "id" },
    { title: "Nombre", onClickSort: () => handleRequestSort("nombre"), columnName: "nombre" },
    { title: "Email", onClickSort: () => handleRequestSort("email"), columnName: "email" },
    { title: "Telefono", onClickSort: () => handleRequestSort("telefono"), columnName: "telefono" },
    { title: "Ciudad", onClickSort: () => handleRequestSort("ciudad"), columnName: "ciudad" },
    { title: "Asunto", onClickSort: () => handleRequestSort("asunto"), columnName: "asunto" },
    { title: "Mensaje", onClickSort: () => handleRequestSort("mensaje"), columnName: "mensaje" },
    { title: "Canal", onClickSort: () => handleRequestSort("canal"), columnName: "canal" },
    { title: "Fecha", onClickSort: () => handleRequestSort("fecha"), columnName: "fecha" },
    { title: "Estado", onClickSort: () => handleRequestSort("contestado"), columnName: "contestado" },
    { title: "Opciones" },
  ];

  const initLoadData = useCallback(
    async ({ skip = 0, take = rowsPerPageDefault, search = '', sortBy = 'fecha', sort = 'ASC' } = {}) => {
      setLoading(true);
      let respuesta = await dispatch(loadData({ url: urlListarContactos, skip, take, search, sortBy, sort }));
      setLoading(false);
      const { transaccion, mensaje } = respuesta;

      !transaccion ? setError(mensaje) : setError(false);
    },
    [dispatch],
  )

  useEffect(() => {
    initLoadData({ skip: skipLocal, take: rowsPerPage, search: searchValue, sortBy, sort: sortOrder });
  }, [rowsPerPage, skipLocal, initLoadData, sortOrder, sortBy, searchValue]);

  return (
    <AdminLayout viewState={view}>
      <div>
        <NotificationContainer />
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>

            <Modal
              open={openModalRol}
              onClose={handleCloseModalRol}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: "100%" }}
            >
              <Fade in={openModalRol}>
                <Paper className={classes.paper} style={{ textAlign: 'center', padding: "10px", width: "40%" }}>
                  <Loading title="Cargando..." loading={loadingReplyMessage}>
                    <div>
                      <div className={classes.paperTitle}>
                        <h5>Responder Mensaje</h5>
                      </div>
                      <div style={{ width: "100%" }}>
                        <FormControl style={{ width: "100%" }}>
                          <InputLabel htmlFor="asunto">Asunto</InputLabel>
                          <Input id="asunto" onChange={e => setAsunto(e.target.value)}
                            value={asunto} />

                        </FormControl >
                      </div>
                      <div>
                        <FormControl style={{ width: "100%" }}>

                          <TextField
                            id="outlined-multiline-static"
                            label="Mensaje"
                            multiline
                            minRows={4}
                            onChange={e => setMensaje(e.target.value)}
                            value={mensaje}
                          />

                        </FormControl>

                      </div>
                      <div style={{ marginTop: "10px", marginBottom: "10px", textAlign: "left" }}>
                        {errorForm.mensaje && (
                          <Alert severity={`${[errorForm.type]}`}>
                            <AlertTitle>{errorForm.title}</AlertTitle>
                            {errorForm.mensaje}
                          </Alert>
                        )}
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        <CustomButton
                          variant="contained"
                          color="info"
                          size="lg"
                          className={classes.button}
                          startIcon={<QuestionAnswerIcon />}
                          onClick={handleReplyMessage}
                        >
                          Enviar
                        </CustomButton>
                      </div>

                    </div>
                  </Loading>

                </Paper>
              </Fade>
            </Modal>

          </Grid>
        </Grid>

        <ConfirmDialog
          title={"Confirmación de eliminación"}
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onConfirm={handleConfirmDelete}
        >
          <div>¿Esta seguro de eliminar este registro?</div>
        </ConfirmDialog>
        {error && <Alert severity="error">{error}</Alert>}
        {view === 0 ? (
          <div>

            {/* Botón buscar */}
            <SearchTextField
              value={search}
              onChange={handleInputSearchChange}
              style={{ marginTop: '25px', marginBottom: '25px' }}
            />

            <Loading title="Cargando..." loading={loading}>
              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                >
                  <TableHead className={classes[`${'warning'} ${'TableHeader'}`]}>
                    <TableRow className={classes.tableHeadRow}>
                      {titulosTable &&
                        titulosTable.map((item, key) => (
                          item.onClickSort ?
                            <TableCellHeader key={key}>
                              <TableSortLabel
                                active={sortBy === item.columnName}
                                direction={sortOrder}
                                onClick={item.onClickSort}
                                hideSortIcon={sortBy !== item.columnName}
                                className={classes.tableCell + " " + classes.tableHeadCell}
                              >
                                {item.title}
                              </TableSortLabel>
                            </TableCellHeader>
                            :
                            <TableCellHeader
                              key={key}
                              className={classes.tableCell + " " + classes.tableHeadCell}
                            >
                              {item.title}
                            </TableCellHeader>
                        ))}
                    </TableRow>
                  </TableHead>
                  {/* <Loading title="Cargando..." loading={loading}> */}
                  <TableBody>

                    {dataCRUD &&
                      dataCRUD.map((item) => (
                        <TableRowCrud
                          key={item.id}
                          keyValue={item.id}
                          setView={setView}
                          item={item}
                          handleEdit={null}
                          handleDelete={handleClickDelete}
                          optionsButtons={
                            <CustomButton
                              variant="contained"
                              color="primary"
                              size="sm"
                              className={classes.button}
                              startIcon={<QuestionAnswerIcon />}
                              onClick={() => handleOpenModal(item)}
                            >
                              Responder
                            </CustomButton>
                          }
                        >
                          <CustomTableCellCrud
                            data={item.id}
                          ></CustomTableCellCrud>
                          <CustomTableCellCrud
                            data={item.nombre}
                          />
                          <CustomTableCellCrud
                            data={item.email}
                          />
                          <CustomTableCellCrud
                            data={item.telefono}
                          />
                          <CustomTableCellCrud
                            data={item.ciudad}
                          />
                          <CustomTableCellCrud
                            data={item.asunto}
                          />
                          <CustomTableCellCrud
                            data={item.mensaje}
                          />
                          <CustomTableCellCrud
                            data={item.canal}
                          />
                          <CustomTableCellCrud
                            data={moment(item.fecha).format('DD/MMMM/YYYY, h:mm a')}
                          />
                          <CustomTableCellCrud
                            data={item.contestado ? "Contestado" : "No contestado"}
                          />
                        </TableRowCrud>
                      ))}
                    {/* )}  */}
                  </TableBody>
                  {/* </Loading> */}
                </Table>
              </TableContainer>
            </Loading>
            <TablePagination
              rowsPerPageOptions={[10, 20, 100]}
              component="div"
              count={total ? +total : 10}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

          </div>
        ) : (null
        )}
      </div>
    </AdminLayout>

  );
};

export default Contacto;
