import React from "react";

//Components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";

import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';

//Custom Components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

//Icons
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import ConfirmationDialog from "components/dialog/custom-dialog.component";

const useStyles = makeStyles(styles);
const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    fontWeight: "bolder",
  },
  body: {
    fontSize: 15,
  },
}))(TableCell);

export const TableOficina = ({
  titulos,
  oficinas,
  editarOficina,
  deleteOficina,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [id, setid] = React.useState("");
  const handleClickListItem = (id) => {
    setOpen(true);
    setid(id);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOk = () => {
    setOpen(false);
    deleteOficina(id);
  };

  return (
    <TableContainer component={Paper}>
    <Table className={classes.table}>
      {titulos !== undefined ? (
        <TableHead className={classes["primary"]}>
          <TableRow className={classes.tableHeadRow}>
            {titulos.map((prop, key) => {
              return (
                <StyledTableCell
                  align="left"
                  className={classes.tableCell + " " + classes.tableHeadCell}
                  key={key}
                >
                  {prop}
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>
      ) : null}
      <TableBody>
        {oficinas.map((oficina, indice) => (
          <TableRow key={indice} className={classes.tableBodyRow}>
            <TableCell className={classes.tableCell}>
              {oficina.tiempo_por_cliente_minutos} minutos
            </TableCell>
            <TableCell className={classes.tableCell}>
              ${oficina.costo_primera_consulta}
            </TableCell>
            <TableCell className={classes.tableCell}>
              ${oficina.costo_consulta}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {oficina.direccion}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {oficina.ciudad}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {oficina.referencias_fisicas}
            </TableCell>
            <TableCell className={classes.tableActions}>
              <Tooltip
                id="tooltip-editar"
                title="Editar oficina"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <IconButton
                  aria-label="Edit"
                  className={classes.tableActionButton}
                  onClick={() => editarOficina(oficina)}
                >
                  <Edit
                    className={
                      classes.tableActionButtonIcon + " " + classes.edit
                    }
                  />
                </IconButton>
              </Tooltip>
              <Tooltip
                id="tooltip-eliminar"
                title="Eliminar oficina"
                placement="top"
                onClick={() => handleClickListItem(oficina.id)}
                classes={{ tooltip: classes.tooltip }}
              >
                <IconButton
                  aria-label="Close"
                  className={classes.tableActionButton}
                >
                  <Close
                    className={
                      classes.tableActionButtonIcon + " " + classes.close
                    }
                  />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
        <ConfirmationDialog
          id={`${id}dialog`}
          keepMounted
          open={open}
          mensaje="esta oficina?"
          onClose={handleClose}
          onConfirm={handleOk}
        />
      </TableBody>
    </Table>
    </TableContainer>
  );
};

export default TableOficina;
