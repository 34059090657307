import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers';
import thunk from 'redux-thunk';
import throttle from 'lodash.throttle';
// const persistedState = localStorage.getItem('state') ? JSON.parse(localStorage.getItem('state')) : [];
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// export const store = createStore(
//   rootReducer,
//   persistedState,
//   //applyMiddleware(thunk)
//   composeEnhancers(applyMiddleware(thunk))
// );

// const handleChange = () => {
//     let previousValue = persistedState
//     let currentValue = store.getState()

//     if (previousValue !== currentValue) {
//       console.log('Some deep nested property changed from', previousValue, 'to', currentValue)
//       localStorage.setItem('state', JSON.stringify(store.getState()))
//     }
//   }

// store.subscribe( handleChange )

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');

    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    //Ignorar
  }
};

const persistedState = loadState();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  persistedState,
  // applyMiddleware(thunk)
  composeEnhancers(applyMiddleware(thunk))
);

store.subscribe(
  throttle(() => {
    const estado = store.getState();

    // Guardar estado solo cuando existe un usuario autenticado
    if (estado.token)
      saveState({
        dataUser: estado.dataUser,
        token: estado.token,
        rol_cli: estado.rol_cli,
        rolActual: estado.rolActual,
        datos_citas: estado.datos_citas,
        datos_cajas: estado.datos_cajas,
        horariosOficinas: estado.horariosOficinas,
        data_oficinas: estado.data_oficinas,
        data_horarios_reservas: estado.data_horarios_reservas,
        data_clientes: estado.data_clientes,
        data_notificaciones: estado.data_notificaciones,
        data_empresa: estado.data_empresa,
        medicos_secretaria: estado.medicos_secretaria,
      });
    else
      // Limpiar caché cuando no hay un usuario autenticado
      localStorage.clear()
  }, 1)
);
