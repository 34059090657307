import { createAction } from "redux-actions";
import {
  urlLoginDoctor,
  urlMedicos,
  urlCurrentUser,
  urlSecretaria,
  urlLaboratorista,
  urlChangePass,
  urlClientes,
  urlConfirmAccountDeletion,
  urlRequestAccountDeletion,
  urlConfirmLoginAccountDeletion,
  urlValidateRole,
  urlConfirmAccountDeletionByUserId,
} from "../../constants/urls";
import { dataLogin } from "../../constants/estructuras";
import Notificacion from "components/ui/Notificacion";
import { clearNotificaciones } from "./aNotificaciones";
import { subscriptionInitialState } from "./aMedico";
import { connectionErrorMessage } from "constants/messages";
import { encriptarRsa } from "utils/encriptar";
export const login = createAction("SET_DATA_LOGIN");
export const updateDataUser = createAction("UPDATE_DATA_USER");
export const isLoadingLogin = createAction("LOADING_LOGIN");
export const jwt = createAction("SET_TOKEN");
export const rol = createAction("SET_ROLES");
export const setRolActual = createAction("SET_ROL");
export const hasErrorLogin = createAction("ERROR_LOGIN");
export const logOut = createAction("LOG_OUT");
export const userLogout = createAction("USER_LOGOUT");

export const cerrarsesion = () => (dispatch) => {
  dispatch(logOut());
  dispatch(jwt(""));
  dispatch(rol(""));
  dispatch(subscriptionInitialState());
  dispatch(userLogout(""));
};

export const loginFetch = (data, history) => async (dispatch) => {
  dispatch(isLoadingLogin());

  const raw = JSON.stringify({ data });
  var requestOptions = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: raw,
  };

  return await fetch(urlLoginDoctor, requestOptions)
    .then((response) => response.json())
    .then((datos) => {
      if (datos.transaccion) {
        let { token, auth, rol: rolActual } = datos;
        dispatch(jwt(token));
        dispatch(rol(auth));
        dispatch(setRolActual(rolActual));
        dispatch(currentUser(history));
      } else {
        dispatch(hasErrorLogin(false));
        Notificacion({
          type: "error",
          text: "Error ingresando, " + datos.mensaje,
        });
      }
    })
    .catch((e) => {
      dispatch(hasErrorLogin(false));
      // setTimeout(() => {
      //   dispatch(hasErrorLogin(false));
      // }, 7000);
      Notificacion({
        type: "error",
        text: connectionErrorMessage,
      });
    });
};

export const cambioPass = (data, state) => async () => {
  const { token } = state;
  const raw = JSON.stringify({ data });
  var requestOptions = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: raw,
  };

  return await fetch(urlChangePass, requestOptions)
    .then((response) => response.json())
    .then((datos) => {
      if (datos.transaccion) {
        Notificacion({
          type: "success",
          text: "Contraseña guardada exitosamente.",
        });
      } else {
        Notificacion({
          type: "error",
          text: `No se pudo cambiar su contraseña. ${datos.mensaje}`,
        });
        return { error: datos.mensaje };
      }
    })
    .catch((e) => {
      Notificacion({
        type: "error",
        text: "Error, no se pudo cambiar su contraseña.",
      });
    });
};

export const currentUser =
  (history, role = "") =>
    async (dispatch, getstate) => {
      try {
        const state = getstate();
        const { token, rolActual } = state;
        let requestOptions = {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        };

        return fetch(`${urlCurrentUser}?role=${rolActual}`, requestOptions)
          .then((response) => response.json())
          .then((datos) => {
            if (datos.transaccion) {
              let data = {};
              dispatch(clearNotificaciones());
              localStorage.removeItem("notifications");
              if (datos.r === 1) {
                let {
                  token,
                  medico: { id, ...medico },
                } = datos;
                // quito el id y mantengo todo lo demás

                data = {
                  ...dataLogin,
                  id: datos.user_id,
                  doctor_id: datos.medico.id,
                  ...datos,
                  ...medico,
                  isLogin: true,
                };

                if (datos.changePassword)
                  Notificacion({
                    type: "warning",
                    text: "Recuerde cambiar su contraseña en su perfil.",
                  });
                dispatch(login(data));
                dispatch(jwt(token));
                history.push(`/medico/dashboard`);
                return;
              } else if (datos.r === 2) {
                let {
                  secretaria, // quito el id y mantengo todo lo demás
                } = datos;

                data = {
                  ...dataLogin,
                  id: datos.user_id,
                  secretaria_id: datos.secretaria.id,
                  ...datos,
                  ...secretaria,
                  isLogin: true,
                };

                if (datos.changePassword)
                  Notificacion({
                    type: "warning",
                    text: "Recuerde cambiar su contraseña en su perfil.",
                  });
                dispatch(login(data));
                // dispatch(data_oficinas(data.oficinas));
                dispatch(jwt(token));
                history.push({
                  pathname: `/secretaria/agenda`,
                });
              } else if (datos.r === 3) {
                let {
                  laboratorista, // quito el id y mantengo todo lo demás
                } = datos;

                data = {
                  ...dataLogin,
                  id: datos.user_id,
                  laboratorista_id: datos.laboratorista.id,
                  ...datos,
                  ...laboratorista,
                  isLogin: true,
                };

                dispatch(login(data));
                dispatch(jwt(token));
                history.push({
                  pathname: `/laboratorista/dashboard`,
                });
              } else if (datos.r === 4) { //Rol cliente
                data = {
                  ...dataLogin,
                  id: datos.user_id,
                  cliente_id: datos.id,
                  ...datos,
                  isLogin: true,
                };

                dispatch(login(data));
                dispatch(jwt(token));
                history.push({
                  pathname: `/cliente/dashboard`,
                });
              } else if (datos.r === 5) {
                data = {
                  ...dataLogin,
                  id: datos.user_id,
                  ...datos,
                  isLogin: true,
                };

                dispatch(login(data));
                dispatch(jwt(token));
                history.push({
                  pathname: `/adminSecretario/dashboard`,
                });
              } else if (datos.r === 6) {
                data = {
                  ...dataLogin,
                  id: datos.user_id,
                  ...datos,
                  isLogin: true,
                };

                dispatch(login(data));
                dispatch(jwt(token));
                history.push({
                  pathname: `/admin/dashboard`,
                });
              } else if (datos.r === 7) {
                // Institución
                let {
                  token,
                  medico: { id, ...medico },
                } = datos;

                data = {
                  ...dataLogin,
                  id: datos.user_id,
                  ...datos,
                  ...medico,
                  isLogin: true,
                };

                dispatch(login(data));
                dispatch(jwt(token));
                history.push({
                  pathname: `/institucion/perfil`,
                });
              } else if (datos.r === 4) {
                dispatch(login(dataLogin));
                dispatch(jwt(""));
                dispatch(hasErrorLogin(`Paciente no aceptado`));
                Notificacion({
                  type: "error",
                  text: `Paciente no puede iniciar ahora`,
                });
                setTimeout(() => {
                  dispatch(hasErrorLogin(false));
                });
              } else {
                dispatch(login(dataLogin));
                dispatch(jwt(""));
                dispatch(hasErrorLogin(`Rol no aceptado`));
                setTimeout(() => {
                  dispatch(hasErrorLogin(false));
                });
                Notificacion({
                  type: "error",
                  text: `Rol no aceptado`,
                });
              }
            } else {
              dispatch(login(dataLogin));
              dispatch(jwt(""));
              dispatch(hasErrorLogin(`${datos.mensaje}`));
              setTimeout(() => {
                dispatch(hasErrorLogin(false));
              }, 7000);
            }
          }).catch(err => {
            dispatch(isLoadingLogin(false));
            Notificacion({
              type: "error",
              text: 'Error al iniciar sesión, intente mas tarde',
            })
          });
      } catch (error) {

        dispatch(login(dataLogin));
        dispatch(jwt(""));
        dispatch(hasErrorLogin(`Error en el servicio2, ${error}`));
        setTimeout(() => {
          dispatch(hasErrorLogin(false));
        }, 7000);
      }
    };
export const loadProfile =
  (role = "") =>
    async (dispatch, getstate) => {
      try {
        const state = getstate();
        const { token, rolActual } = state;
        let requestOptions = {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        };
        dispatch(isLoadingLogin());

        const response = await fetch(`${urlCurrentUser}?role=${rolActual}`, requestOptions);
        const datos = await response.json();

        if (datos.transaccion) {
          let data = {};
          dispatch(clearNotificaciones());
          localStorage.removeItem("notifications");
          if (datos.r === 1) {
            let {
              medico: { id, ...medico },
            } = datos;
            // quito el id y mantengo todo lo demás

            data = {
              ...dataLogin,
              id: datos.user_id,
              doctor_id: datos.medico.id,
              ...datos,
              ...medico,
              isLogin: true,
            };
            dispatch(login(data));
            return;
          } else if (datos.r === 2) {
            let {
              secretaria, // quito el id y mantengo todo lo demás
            } = datos;

            data = {
              ...dataLogin,
              id: datos.user_id,
              secretaria_id: datos.secretaria.id,
              ...datos,
              ...secretaria,
              isLogin: true,
            };

            // if (datos.changePassword)
            dispatch(login(data));
            // dispatch(data_oficinas(data.oficinas));
          } else if (datos.r === 3) {
            let {
              laboratorista, // quito el id y mantengo todo lo demás
            } = datos;

            data = {
              ...dataLogin,
              id: datos.user_id,
              laboratorista_id: datos.laboratorista.id,
              ...datos,
              ...laboratorista,
              isLogin: true,
            };

            dispatch(login(data));
          } else if (datos.r === 4) { //Rol cliente
            data = {
              ...dataLogin,
              id: datos.user_id,
              cliente_id: datos.id,
              ...datos,
              isLogin: true,
            };

            dispatch(login(data));
          } else if (datos.r === 5) {
            data = {
              ...dataLogin,
              id: datos.user_id,
              ...datos,
              isLogin: true,
            };

            dispatch(login(data));
          } else if (datos.r === 6) {
            data = {
              ...dataLogin,
              id: datos.user_id,
              ...datos,
              isLogin: true,
            };

            dispatch(login(data));
            dispatch(jwt(token));
          } else if (datos.r === 7) {
            // Institución
            let {
              token,
              medico: { id, ...medico },
            } = datos;
            data = {
              ...dataLogin,
              id: datos.user_id,
              ...datos,
              ...medico,
              isLogin: true,
            };

            dispatch(login(data));
            dispatch(jwt(token));
          } else if (datos.r === 4) {
            dispatch(login(dataLogin));
          } else {
            dispatch(login(dataLogin));
            dispatch(jwt(""));
            dispatch(hasErrorLogin(`Rol no aceptado`));
            setTimeout(() => {
              dispatch(hasErrorLogin(false));
            });
            Notificacion({
              type: "error",
              text: `Rol no aceptado`,
            });
          }
        } else {
          dispatch(login(dataLogin));
          dispatch(jwt(""));
          dispatch(hasErrorLogin(`${datos.mensaje}`));
          setTimeout(() => {
            dispatch(hasErrorLogin(false));
          }, 7000);
        }

        return true;

      } catch (error) {
        Notificacion({
          type: "error",
          text: `Error de conexión`,
        });
      }
    };

export const rolUpdate = (rolNuevo, history) => async (dispatch) => {
  try {
    dispatch(isLoadingLogin());
    dispatch(setRolActual(rolNuevo));
    dispatch(currentUser(history));

  } catch (e) {
    dispatch(login(dataLogin));
    dispatch(jwt(""));
    dispatch(rol(""));
    dispatch(hasErrorLogin(e));
    setTimeout(() => {
      dispatch(hasErrorLogin(false));
    }, 7000);
  }
};
export const medicoUpdate = (param, state) => async (dispatch) => {
  // dispatch(isLoadingLogin());
  try {
    // const state = getstate();

    const { token, dataUser } = state;
    const { doctor_id } = dataUser.data;

    const raw = JSON.stringify(param);
    var requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: raw,
    };

    return fetch(urlMedicos + doctor_id, requestOptions)
      .then((response) => response.json())
      .then((datos) => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(""));
          return;
        }
        if (datos.transaccion) {
          const nueva_data = {
            doctor_id: datos.data.id,
            ...dataUser.data,
            ...datos.data,
            isLogin: true,
          };
          Notificacion({
            type: "success",
            text: datos.mensaje,
          });
          // dispatch(login(nueva_data));
          return { nueva_data, mensaje: datos.mensaje };
        } else {
          Notificacion({ type: "error", text: "No se pudo guardar." });
          return { error: datos.mensaje };
        }
      });
  } catch (error) {
    Notificacion({ type: "error", text: "No se pudo guardar." });
    return { error: "No se pudo guardar la información recargue la pagina " };
  }
};

export const clienteUpdate = (param, state) => async (dispatch) => {
  // dispatch(isLoadingLogin());
  try {
    // const state = getstate();

    const { token, dataUser } = state;
    const { cliente_id } = dataUser.data;

    const raw = JSON.stringify(param);
    var requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: raw,
    };

    return fetch(urlClientes + cliente_id, requestOptions)
      .then((response) => response.json())
      .then((datos) => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(""));
          return;
        }
        if (datos.transaccion) {
          const nueva_data = {
            cliente_id: datos.data.id,
            ...dataUser.data,
            ...datos.data,
            isLogin: true,
          };
          Notificacion({
            type: "success",
            text: datos?.mensaje,
          });
          // dispatch(login(nueva_data));
          return { nueva_data, mensaje: datos?.mensaje };
        } else {
          Notificacion({ type: "error", text: "No se pudo guardar." });
          return { error: datos.mensaje };
        }
      });
  } catch (error) {
    Notificacion({ type: "error", text: "No se pudo guardar." });
    return { error: "No se pudo guardar la información recargue la pagina " };
  }
};

export const secretariaUpdate =
  (param, state) => async (dispatch) => {
    // dispatch(isLoadingLogin());
    try {
      // const state = getstate();

      const { token, dataUser } = state;
      const { secretaria_id, id } = dataUser.data;

      const raw = JSON.stringify(param);
      var requestOptions = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: raw,
      };

      return fetch(`${urlSecretaria}${id}/${secretaria_id}`, requestOptions)
        .then((response) => response.json())
        .then((datos) => {
          const { token = true } = datos;
          if (!token) {
            dispatch(jwt(""));
            return;
          }
          if (datos.transaccion) {
            const nueva_data = {
              secretaria_id: datos.data.id,
              ...dataUser.data,
              ...datos.data,
              isLogin: true,
            };
            Notificacion({
              type: "success",
              text: "Datos guardados exitosamente.",
            });
            return { nueva_data };
          } else {
            Notificacion({ type: "error", text: "No se pudo guardar." });
            return { error: datos.mensaje };
          }
        });
    } catch (error) {
      Notificacion({ type: "error", text: "No se pudo guardar." });
      return { error: "No se pudo guardar la información recargue la pagina " };
    }
  };

export const laboratoristaUpdate =
  (param, state) => async (dispatch) => {
    // dispatch(isLoadingLogin());
    try {
      // const state = getstate();

      const { token, dataUser } = state;
      const { laboratorista_id, id } = dataUser.data;

      const raw = JSON.stringify(param);
      var requestOptions = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: raw,
      };

      return fetch(
        `${urlLaboratorista}${id}/${laboratorista_id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((datos) => {
          const { token = true } = datos;
          if (!token) {
            dispatch(jwt(""));
            return;
          }
          if (datos.transaccion) {
            const nueva_data = {
              laboratorista_id: datos.data.id,
              ...dataUser.data,
              ...datos.data,
              isLogin: true,
            };
            Notificacion({
              type: "success",
              text: "Datos guardados exitosamente.",
            });
            return { nueva_data };
          } else {
            Notificacion({ type: "error", text: "No se pudo guardar." });
            return { error: datos.mensaje };
          }
        });
    } catch (error) {
      Notificacion({ type: "error", text: "No se pudo guardar." });
      return { error: "No se pudo guardar la información recargue la pagina " };
    }
  };

// Eliminación de cuenta
export const requestDeleteAccountDeletion = (password) => async (_, getstate) => {
  try {
    const { token, rolActual } = getstate();
    const data = await encriptarRsa(JSON.stringify({ password }));
    const raw = JSON.stringify({ data });

    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: raw,
    };

    const response = await fetch(`${urlRequestAccountDeletion}/${rolActual}`, requestOptions);
    const datos = await response.json();


    if (datos.transaccion) {
      Notificacion({ type: "success", text: datos.mensaje, });
      return { transaccion: true }
    } else {
      Notificacion({ type: "error", text: datos.mensaje, });
      return { transaccion: false, };
    }

  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};
export const confirmDeleteAccount = (code) => async (_, getstate) => {
  try {
    const { token, rolActual } = getstate();

    var requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${urlConfirmAccountDeletion}/${code}/${rolActual}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      Notificacion({ type: "success", text: datos.mensaje, });
      return { transaccion: true }
    } else {
      Notificacion({ type: "error", text: datos.mensaje, });
      return { transaccion: false, };
    }

  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const confirmLoginDeleteAccount = (data) => async (_, getstate) => {
  try {
    data = await encriptarRsa(JSON.stringify(data));
    const raw = JSON.stringify({ data });

    var requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: raw,
    };

    const response = await fetch(`${urlConfirmLoginAccountDeletion}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      Notificacion({ type: "success", text: datos.mensaje, });
      return { transaccion: true }
    } else {
      Notificacion({ type: "error", text: datos.mensaje, });
      return { transaccion: false, };
    }

  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

// TODO: Cambiar al método anterior
export const confirmDeleteAccountByUserId = (clientId) => async (_, getstate) => {
  try {

    var requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await fetch(`${urlConfirmAccountDeletionByUserId}/${clientId}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      Notificacion({ type: "success", text: datos.mensaje, });
      return { transaccion: true }
    } else {
      Notificacion({ type: "error", text: datos.mensaje, });
      return { transaccion: false, };
    }

  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

// Roles
export const startValidateUserRole = (history) => async (dispatch, getstate) => {
  try {
    const { rolActual, token } = getstate();
    if (!rolActual) return;

    var requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${urlValidateRole}/${rolActual}`, requestOptions);
    const datos = await response.json();

    if (!datos.transaccion && datos?.status === 404) dispatch(logout());

  } catch (error) {
    console.log(error)
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const logout = (history) => async (dispatch, getstate) => {
  try {
    localStorage.removeItem("notifications");
    dispatch(cerrarsesion())
    // history.replace({ pathname: `/` });
    window.location.replace('/')

  } catch (error) {
    console.log(error)
  }
};
