import React, { useCallback, useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import { IconButton } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Button from "../../CustomButtons/Button";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { ErrorMessage } from "@hookform/error-message";
import FormHelperText from "@material-ui/core/FormHelperText";

import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { AutocompleteComponent } from "components/ReactHookForm/AutocompleteComponent";
import { getVacunaPorBusqueda } from "redux/actions/aMedico";
import { useMemo } from "react";
import { crearPacienteVacunas } from "redux/actions/aMedico";
import Loading from "components/Loading";
import { editarPacienteVacuna } from "redux/actions/aMedico";
import { eliminarPacienteVacunas } from "redux/actions/aMedico";
import ConfirmDialog from "components/Admin/CRUD/ConfirmDialog";
import { createFilterOptions } from "@material-ui/lab";
import { formatVacuna } from "utils/format";


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  formControl: {
    width: "100%",
    marginBottom: "25px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  tableContainer: {
    width: '100%'
  },
  labelError: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
}));

const filter = createFilterOptions();

export const VacunasForm = ({ cliente_id, setVacunasFormDirty }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const cliente_data = useSelector((state) => state.data_clientes);

  const [edit, setEdit] = useState(false);
  const [dataSelected, setDataSelected] = useState(null);
  const [vacunaSelected, setVacunaSelected] = useState('');
  const [loading, setLoading] = useState(false);


  const data = useMemo(() => cliente_data.data && cliente_data.data.vacunas ? cliente_data.data.vacunas : [], [cliente_data])

  const [vacunaData, setVacunaData] = useState([]);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const { control, handleSubmit, errors, reset, watch, formState: { isDirty } } =
    useForm();

  const checkKeyDown = (e) => {
    if (e.code === "Enter") e.preventDefault();
  };

  const handleCancel = () => {
    setEdit(false);
    setDataSelected(null);
    reset({
      'dosis': '',
      'fecha_aplicacion': new Date(),
      'edad_aplicacion': '',
      'lote': '',
      'lugar': '',
      'informacion': null
    });
    setVacunaSelected(null)
  }
  const handleEdit = (vacuna) => {
    reset({
      'dosis': vacuna.dosis,
      'fecha_aplicacion': new Date(vacuna.fecha_aplicacion),
      'edad_aplicacion': vacuna.edad_aplicacion,
      'lote': vacuna.lote,
      'lugar': vacuna.lugar,
      'vacuna': {
        id: vacuna.vacuna_id,
        informacion: vacuna.informacion,
        edad_optima: vacuna.edad_optima,
        informacion_extra: vacuna.informacion_extra,
        nombre_comercial: vacuna.nombre_comercial,
        tipo: vacuna.vacuna_tipo,
        custom: vacuna.vacuna_id ? false : true
      }
    });
    // setValue('informacion', vacuna.vacuna.split('-').join(' - '))
    if(vacuna.vacuna_id) {
      setVacunaSelected({
        id: vacuna.vacuna_id,
        informacion: vacuna.informacion,
        edad_optima: vacuna.edad_optima,
        informacion_extra: vacuna.informacion_extra,
        nombre_comercial: vacuna.nombre_comercial,
        tipo: vacuna.vacuna_tipo
    })
    }else {
      setVacunaSelected({
        tipo: vacuna.vacuna_tipo,
        custom: true
    })
    }
   
    setEdit(true);
    setDataSelected(vacuna);
  }

  const handleDelete = (vacuna) => {
    setDataSelected(vacuna);
    setOpenConfirmDialog(true);
  }

  const onSubmit = async (data, action) => {
    try {
      setLoading(true);
      if (edit) {
        //edit
        await dispatch(editarPacienteVacuna(dataSelected.id, data));
        setEdit(false);
        setLoading(false);
      } else {
        //create

        await dispatch(crearPacienteVacunas(cliente_id, data));
        setLoading(false);
      }

      reset({
        'dosis': '',
        'fecha_aplicacion': new Date(),
        'edad_aplicacion': '',
        'lote': '',
        'lugar': '',
        'vacuna': null
      });
      setVacunaSelected(null);
    } catch (error) {
      setLoading(false);
    }
  }

  const handleConfirmDelete = useCallback(async () => {
    !loading && setLoading(true);
    await dispatch(eliminarPacienteVacunas(dataSelected.id));
    setLoading(false);
  }, [loading, dataSelected, dispatch]);

  const handleSearchVacunas = async (value) => {
    try {
      const { data } = await dispatch(getVacunaPorBusqueda(value));
      setVacunaData(data);

    } catch (error) {
    }
  }

  useEffect(() => {
    setVacunasFormDirty(isDirty)
  }, [isDirty, setVacunasFormDirty]);

  return (
    <div className={classes.tableContainer}>
      <ConfirmDialog
        title={"Confirmación de eliminación"}
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={handleConfirmDelete}
      >
        <div>¿Esta seguro de eliminar este registro?</div>
      </ConfirmDialog>
      <Loading title="Guardando..." loading={loading}>
        <div>
          <form
            onSubmit={handleSubmit((data) =>
              onSubmit(data, `${edit ? "editar" : "crear"}`)
            )}
            onKeyDown={(e) => checkKeyDown(e)}>
            <Table size="small" aria-label="purchases" className={classes.table} >
              <TableHead>
                <TableRow>
                  <TableCell align="center">Titulo</TableCell>
                  <TableCell align="center">Edad Óptima de Vacunación</TableCell>
                  <TableCell align="center">Dosis</TableCell>
                  <TableCell align="center">Fecha de aplicación</TableCell>
                  <TableCell align="center">Edad de aplicación</TableCell>
                  <TableCell align="center">Lote</TableCell>
                  <TableCell align="center">Lugar</TableCell>
                  <TableCell align="center">Opciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!edit && data.map((vacuna) => (
                  <TableRow key={vacuna.id}>
                    <TableCell align="center">
                      {vacuna.vacuna_tipo + (vacuna.vacuna_nombre_comercial ? '/' : '') + (vacuna.vacuna_nombre_comercial ?? '') }
                    </TableCell>
                    <TableCell align="center">
                      {vacuna.vacuna_edad_optima}
                    </TableCell>
                    <TableCell align="center">
                      {vacuna.dosis}
                    </TableCell>
                    <TableCell align="center">
                      {moment(vacuna.fecha_aplicacion).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell align="center">
                      {vacuna.edad_aplicacion}
                    </TableCell>
                    <TableCell align="center">
                      {vacuna.lote}
                    </TableCell>
                    <TableCell align="center">
                      {vacuna.lugar}
                    </TableCell>
                    <TableCell align="center">
                      <div style={{ display: 'flex' }}>
                        <Tooltip
                          id="tooltip-servicio"
                          title={'Editar'}
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <IconButton
                            aria-label="Edit"
                            style={{ padding: '5px' }}
                            className={classes.tableActionButton}
                            onClick={() => handleEdit(vacuna)}
                          >
                            <Edit
                              className={
                                classes.tableActionButtonIcon + " " + classes.edit
                              }
                            />

                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          id="tooltip-eliminar"
                          title="Eliminar"
                          placement="top"

                          classes={{ tooltip: classes.tooltip }}
                        >
                          <IconButton
                            size={'small'}
                            style={{ padding: '5px' }}
                            aria-label="Close"
                            className={classes.tableActionButton}
                            onClick={() => handleDelete(vacuna)}
                          >
                            <Close
                              className={
                                classes.tableActionButtonIcon + " " + classes.close
                              }
                            />
                          </IconButton>

                        </Tooltip>
                      </div>

                    </TableCell>

                  </TableRow>
                ))}

                {/* Formulario */}
                <TableRow>
                  <TableCell align="center" colSpan={2} style={{ verticalAlign: 'top', paddingTop: '14px' }}>
                    <FormControl error className={classes.formControl}>
                      <Controller
                        // value={vacunaSelected}
                        id='informacion'
                        name="vacuna"
                        defaultValue={null}
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Debe seleccionar una opción",
                          }
                        }}
                        onChange={([, data]) => {
                          return data;
                        }}
                        render={({ onChange }) => (
                          <AutocompleteComponent
                            variant="outlined"
                            value={
                              vacunaSelected
                            }
                            reduxFunction={false}
                            label=""
                            name="vacuna"
                            type="text"
                            freeSolo={true}
                            fullWidth
                            onChange={(event, newValue) => {
                              if (typeof newValue === 'string') {
                                onChange(newValue);
                              }
                              if (newValue && newValue.inputValue) {
                                onChange({
                                  tipo: newValue.inputValue,
                                  custom: true,
                                });
                              }

                              else if (newValue) {
                                onChange(newValue);
                              }
                            }}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);

                              // Suggest the creation of a new value
                              if (params.inputValue !== '') {
                                filtered.push({
                                  inputValue: params.inputValue,
                                  informacion: `personalizado: ${params.inputValue}`,
                                });
                              }

                              return filtered;
                            }}
                            optionLabel={option => {
                              if (option.inputValue) {
                                return option.inputValue;
                              }

                              if (typeof option === 'string') {
                                return (option);
                              }

                              return formatVacuna(option)
                            }
                            }
                            // getOptionSelected={
                            //   (option, value) => option.informacion === value
                            // }
                            dispatchFunction={handleSearchVacunas}
                            dataSearch={vacunaData}
                          />
                        )}

                      />
                      <div style={{ textAlign: 'start' }}>
                        <strong>
                          Opcion seleccionada:
                        </strong>
                        <div>
                          {formatVacuna(watch('vacuna')) ?? 'Ninguna Opcion seleccionada'}
                        </div>
                      </div>
                      <ErrorMessage
                        errors={errors}
                        name='vacuna'
                        render={({ message }) => (
                          <FormHelperText
                            className={classes.labelError}
                            id="component-error-text"
                          >
                            {message}
                          </FormHelperText>
                        )}
                      />
                    </FormControl>
                  </TableCell>
                  {/* Dosis */}
                  <TableCell align="center" style={{ verticalAlign: 'top' }}>
                    <FormControl error className={classes.formControl}>
                      <Controller
                        name={`dosis`}
                        control={control}
                        defaultValue={''}
                        as={<TextField />}
                        error={errors[`dosis`] ? true : false}
                        helperText={""}
                        // id="standard-full-width"
                        // label='Alergias'
                        style={{ margin: 8 }}
                        fullWidth
                        margin="normal"

                        rules={{
                          required: {
                            value: true,
                            message: "El campo dosis es requerido",
                          },
                          validate: (value) => {
                            if (isNaN(value)) {
                              return "Debe ser un valor numérico";
                            }
                            return true;
                          }
                        }}
                        variant="outlined"
                      />
                      <ErrorMessage
                        errors={errors}
                        name='dosis'
                        render={({ message }) => (
                          <FormHelperText
                            className={classes.labelError}
                            id="component-error-text"
                          >
                            {message}
                          </FormHelperText>
                        )}
                      />
                    </FormControl>
                  </TableCell>
                  {/* Fecha */}
                  <TableCell align="center" style={{ verticalAlign: 'top' }}>
                    <FormControl error className={classes.formControl}>
                      <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
                        <Controller
                          name={'fecha_aplicacion'}
                          control={control}
                          defaultValue={new Date()}
                          render={({ onChange, value = new Date() }) => (
                            <KeyboardDatePicker
                              style={{ margin: "8px", fontSize: '15px !important' }}
                              InputProps={{
                                style: {
                                  fontSize: 14,
                                }
                              }}
                              disableToolbar
                              variant="inline"
                              format="DD/MM/yyyy"
                              margin="normal"
                              id="date-picker-inline"
                              // label={`Fecha aplicacion`}
                              value={value}
                              onChange={onChange}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              invalidDateMessage="Formato de fecha no válido"
                            />
                          )}
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>

                    {/* {moment(vacuna.fecha).format('DD/MM/YYYY')} */}
                  </TableCell>
                  {/* Edad */}
                  <TableCell align="center" style={{ verticalAlign: 'top' }}>
                    <FormControl error className={classes.formControl}>
                      <Controller
                        name={`edad_aplicacion`}
                        control={control}
                        defaultValue={''}
                        as={<TextField />}
                        error={errors[`edad_aplicacion`] ? true : false}
                        helperText={""}
                        // id="standard-full-width"
                        // label='Alergias'
                        style={{ margin: 8 }}
                        fullWidth
                        margin="normal"
                        rules={{
                          required: {
                            value: true,
                            message: "El campo edad es requerido",
                          },
                          validate: (value) => {
                            if (isNaN(value)) {
                              return "Debe ser un valor numérico";
                            }
                            return true;
                          }
                        }}
                        variant="outlined"
                      />
                      <ErrorMessage
                        errors={errors}
                        name='edad_aplicacion'
                        render={({ message }) => (
                          <FormHelperText
                            className={classes.labelError}
                            id="component-error-text"
                          >
                            {message}
                          </FormHelperText>
                        )}
                      />
                    </FormControl>
                  </TableCell>
                  {/* Lote */}
                  <TableCell align="center" style={{ verticalAlign: 'top' }}>
                    <FormControl error className={classes.formControl}>
                      <Controller
                        name={`lote`}
                        control={control}
                        defaultValue={''}
                        as={<TextField />}
                        error={errors[`lote`] ? true : false}
                        helperText={""}
                        // id="standard-full-width"
                        // label='Alergias'
                        style={{ margin: 8 }}
                        fullWidth
                        margin="normal"
                        rules={{
                          required: {
                            value: true,
                            message: "El campo lote es requerido",
                          },
                        }}
                        variant="outlined"
                      />
                      <ErrorMessage
                        errors={errors}
                        name='lote'
                        render={({ message }) => (
                          <FormHelperText
                            className={classes.labelError}
                            id="component-error-text"
                          >
                            {message}
                          </FormHelperText>
                        )}
                      />
                    </FormControl>
                  </TableCell>
                  {/* Lugar */}
                  <TableCell align="center" style={{ verticalAlign: 'top' }}>
                    <FormControl error className={classes.formControl}>
                      <Controller
                        name={`lugar`}
                        control={control}
                        defaultValue={''}
                        as={<TextField />}
                        error={errors[`lugar`] ? true : false}
                        helperText={""}
                        // id="standard-full-width"
                        // label='Alergias'
                        style={{ margin: 8 }}
                        fullWidth
                        margin="normal"
                        rules={{
                          required: {
                            value: true,
                            message: "El campo lugar es requerido",
                          },
                        }}
                        variant="outlined"
                      />
                      <ErrorMessage
                        errors={errors}
                        name='lugar'
                        render={({ message }) => (
                          <FormHelperText
                            className={classes.labelError}
                            id="component-error-text"
                          >
                            {message}
                          </FormHelperText>
                        )}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>

                  </TableCell>
                </TableRow>

              </TableBody>
            </Table>
            <div>
              <GridContainer justifyContent="center" direction="row">
                <GridItem xs={"auto"}>
                  <Button
                    variant="contained"
                    color="warning"
                    type="submit"
                  >
                    {
                      edit ?
                        'Actualizar'
                        :
                        'Agregar'
                    }

                  </Button>
                </GridItem>
                {
                  edit &&
                  <GridItem xs={"auto"}>
                    <Button
                      variant="contained"
                      color="error"
                      type="submit"
                      onClick={handleCancel}
                    >
                      Cancelar
                    </Button>
                  </GridItem>
                }

              </GridContainer>
            </div>
          </form>
        </div>
      </Loading>
    </div>
  );
}
