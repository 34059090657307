import React from 'react'
import { Button, makeStyles } from '@material-ui/core';
import CustomButton from "components/CustomButtons/Button";
import Loading from 'components/Loading';
import { FormSelectMUIControlled, TextFieldControlled } from 'components/forms/reactHookForm/FormElementsControlled';
import Notificacion from 'components/ui/Notificacion';
import { clienteFormProps } from 'constants/constants';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { registroFetch } from 'redux/actions/aRegistro';
import { rhPasswordValidation, rhRequiredEmailValidation, rhRequiredFieldValidation } from 'utils/validaciones';
import { rhConfirmPasswordValidation } from 'utils/validaciones';
import { FormCookiesTermsCheckboxControlled } from 'components/forms/reactHookForm/FormElementsControlled';
import { UserRoleTypes } from 'constants/constants';
import { InstitucionRegisterForm } from 'components/forms/auth/register/InstitucionRegisterForm';
import { registrarInstitucion } from 'redux/actions/aInstitucion';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
}));

export const Register = ({ defaultRole, adminMode = false }) => {

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, reset, watch, formState: { errors }, control } = useForm({
    defaultValues: {
      role: defaultRole ?? ''
    }
  });

  const onSubmit = async (data) => {
    // Guardar datos de registro
    try {
      const role = defaultRole ?? data.role;

      setLoading(true);

      let dataToSend;

      if (role === UserRoleTypes.institucion) {
        // Enviar datos usuario institucion
        dataToSend = {
          nombre: data.nombre.trim(),
          razonSocial: data.razonSocial.trim(),
          RUC: data.RUC.trim(),
          email: data.email.trim(),
          username: data.username.trim(),
          password: data.password.trim(),
          role: role,
          empresa: process.env.REACT_APP_EMPRESA,
        }
        console.log({ dataToSend })
        // setLoading(false)
        const response = await registrarInstitucion(dataToSend);
        onResponse(response, dataToSend);
      } else {
        // Enviar datos usuario persona
        dataToSend = {
          nombres: data.nombres.trim(),
          apellidos: data.apellidos.trim(),
          email: data.email.trim(),
          usuario: data.usuario.trim(),
          password: data.password.trim(),
          role: role,
          empresa: process.env.REACT_APP_EMPRESA,
        };

        if (role === 'ROLE_MED')
          dataToSend = {
            ...dataToSend,
            cedula: data.cedula.trim(),
            msp: data.codigo_senescyt?.trim(),
            codigo_senescyt: data.codigo_senescyt?.trim(),
          }

        const response = await registroFetch(dataToSend);
        onResponse(response, dataToSend);
      }

    } catch (error) {
      console.log(error)
      setLoading(false);
      Notificacion({
        type: "error",
        text: 'Ocurrio un error, intente nuevamente',
      });
    }

  }

  const onResponse = (response, dataToSend) => {
    setLoading(false);
    if (response.transaccion) {
      // Si el usuario se guardo correctamente
      Notificacion({
        type: "success",
        text: response.mensaje ?? `Cuenta creada correctamente`,
      });
      reset();
    } else {
      // Si hubo un error al guardar el usuario
      Notificacion({
        type: "error",
        text: response.mensaje ?? 'Error de conexión, intente nuevamente',
      });

      reset(dataToSend);
    }
  }

  return (
    <form className={classes.root} onSubmit={handleSubmit(onSubmit)} noValidate="noValidate" autoComplete="off">
      <Loading title="Cargando..." loading={loading}>
        <>
          {
            !defaultRole &&
            <FormSelectMUIControlled
              id={'role'}
              label={'¿Qué soy?'}
              control={control}
              name={'role'}
              errors={errors}
              register={register}
              defaultValue={defaultRole}
              options={[
                { value: 'ROLE_SEC', label: 'Secretaria/o' },
                { value: 'ROLE_MED', label: 'Profesional de Salud' },
                { value: 'ROLE_LAB', label: 'Laboratorista' },
                { value: 'ROLE_CLI', label: 'Cliente' },
                { value: 'ROLE_INS', label: 'Institución' },
              ]}
              rules={{
                required: {
                  value: true,
                  message: "El campo es requerido",
                }
              }} />
          }

          <>
            {
              watch('role') === UserRoleTypes.institucion
                ?
                // Formulario institución
                <InstitucionRegisterForm
                  control={control}
                  register={register}
                  errors={errors}
                />
                :
                // Formulario usuarios personas
                <>
                  {/* Nombres */}
                  <TextFieldControlled
                    id={'nombres'}
                    label={'Nombres:'}
                    control={control}
                    name={'nombres'}
                    errors={errors}
                    register={register}
                    formInputType={'input'}
                    rules={rhRequiredFieldValidation}
                    variant={adminMode ? 'standard' : undefined}
                  />

                  {/* Apellidos */}
                  <TextFieldControlled
                    id={'apellidos'}
                    label={'Apellidos:'}
                    control={control}
                    name={'apellidos'}
                    errors={errors}
                    register={register}
                    formInputType={'input'}
                    rules={rhRequiredFieldValidation}
                    variant={adminMode ? 'standard' : undefined}
                  />

                  {/* Email */}
                  <TextFieldControlled
                    id='email'
                    label='Email:'
                    control={control}
                    name='email'
                    errors={errors}
                    register={register}
                    formInputType='input'
                    type='email'
                    rules={rhRequiredEmailValidation}
                    variant={adminMode ? 'standard' : undefined}
                  />

                  {/* Nombre de usuario */}
                  <TextFieldControlled
                    id='usuario'
                    label='Nombre de usuario:'
                    control={control}
                    name='usuario'
                    errors={errors}
                    register={register}
                    formInputType='input'
                    type='text'
                    rules={rhRequiredFieldValidation}
                    variant={adminMode ? 'standard' : undefined}
                  />

                  {/* Cédula */}
                  {
                    watch('role') === "ROLE_MED" &&
                    <TextFieldControlled
                      id='cedula'
                      label='Cédula de identidad o pasaporte:'
                      control={control}
                      name='cedula'
                      errors={errors}
                      register={register}
                      formInputType='input'
                      rules={clienteFormProps.cedulaIdentidad.validationOptions}
                      variant={adminMode ? 'standard' : undefined}
                    />

                  }

                  {/* Código senescyt */}
                  {
                    watch('role') === "ROLE_MED" &&
                    <TextFieldControlled
                      id='codigo_senescyt' å
                      label='Código Senescyt:'
                      control={control}
                      name='codigo_senescyt'
                      errors={errors}
                      register={register}
                      formInputType='input'
                      rules={rhRequiredFieldValidation}
                      variant={adminMode ? 'standard' : undefined}
                    />

                  }
                </>

            }
          </>

          {/* Password */}
          <TextFieldControlled
            id='password'
            label='Contraseña:'
            control={control}
            name='password'
            errors={errors}
            register={register}
            formInputType='input'
            type='password'
            rules={rhPasswordValidation}
            variant={adminMode ? 'standard' : undefined}
          />

          {/* Confirm Password */}
          <TextFieldControlled
            id='confirmPassword'
            label='Confirmar contraseña:'
            control={control}
            name='confirmPassword'
            errors={errors}
            register={register}
            formInputType='input'
            type='password'
            rules={rhConfirmPasswordValidation(watch)}
            variant={adminMode ? 'standard' : undefined}
          />

          {/* Términos y condiciones */}
          {
            !adminMode &&
            <FormCookiesTermsCheckboxControlled
              control={control}
              name='terminos'
              errors={errors}
              register={register}
              mui
            />
          }

          <div style={{ margin: '20px 8px' }}>
            {
              adminMode ?
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <CustomButton
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    type="submit"
                  >
                    GUARDAR
                  </CustomButton>
                </div>

                :
                <Button fullWidth size="large" variant="outlined" color="primary" type='submit'>
                  Registrarse
                </Button>
            }

          </div>
        </>

      </Loading>

    </form>

  )
}
