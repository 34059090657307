import React from "react";
// react plugin for creating charts
// import ChartistGraph from "react-chartist";
// @material-ui/core
// import { makeStyles } from "@material-ui/core/styles";

//Importaciones para las citas reservadas

// import {
//   dailySalesChart,
//   emailsSubscriptionChart,
//   completedTasksChart
// } from "variables/charts.js";

// import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Citas from "components/citas/medico/citas.component";

// const useStyles = makeStyles(styles);

function Dashboard() {
  return <Citas />;
}

export default Dashboard;
