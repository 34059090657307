import React from 'react';
import { Typography, Card } from '@material-ui/core';
import { useSelector } from 'react-redux';

const Resumen = ({ selectedHorario, oficinas }) => {

  const clientes = useSelector(state => state.data_clientes.data);
  return (
    <Card style={{ width: '600px', padding: '20px' }}>
      <Typography
        style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}
        display="inline"
        variant="h5"
      >
        Resumen de la cita
      </Typography>
      <Typography style={{ display: 'flex', justifyContent: 'center', padding: '10px' }} variant="body2">
        Paciente
      </Typography>
      <Typography style={{ display: 'flex', justifyContent: 'center' }} variant="subtitle1">
        <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Cédula: </span>
        {clientes.cedula_identidad}
      </Typography>
      <Typography style={{ display: 'flex', justifyContent: 'center' }} variant="subtitle1">
        <span style={{ fontWeight: 'bold', marginRight: '5px' }}> Nombre: </span>
        {clientes.nombres} {clientes.apellidos}
      </Typography>
      <Typography style={{ display: 'flex', justifyContent: 'center', padding: '10px' }} variant="body2">
        Lugar
      </Typography>
      <Typography style={{ display: 'flex', justifyContent: 'center', padding: '10px' }} variant="subtitle1">
        {selectedHorario.referencia_oficina}
      </Typography>
      <Typography style={{ display: 'flex', justifyContent: 'center', padding: '10px' }} variant="body2">
        Fecha y Hora
      </Typography>
      <Typography style={{ display: 'flex', justifyContent: 'center', padding: '10px' }} variant="subtitle1">
        {selectedHorario.fechaSeleccionada} a las {selectedHorario.hora}
      </Typography>
      <Typography style={{ display: 'flex', justifyContent: 'center', padding: '10px' }} variant="body2">
        Servicio Seleccionado
      </Typography>
      <Typography style={{ display: 'flex', justifyContent: 'center', padding: '10px' }} variant="subtitle1">
        {selectedHorario.servicio_nombre}
      </Typography>
      <Typography style={{ display: 'flex', justifyContent: 'center', padding: '10px' }} variant="body2">
        Especialidad
      </Typography>
      <Typography style={{ display: 'flex', justifyContent: 'center', padding: '10px' }} variant="subtitle1">
        {selectedHorario.especializacion_nombre}
      </Typography>

    </Card>
  );
};

export default Resumen;
