import React from 'react'
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as svgIcon } from  "../../../assets/images/icons/sidebar/roles.svg";

import styles from "assets/jss/material-dashboard-react/components/customIconStyle";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(styles);

export const RolesIcon = (props) => {
  const classes = useStyles();

  return (
    <SvgIcon className={classes.svgIconStyle} component={svgIcon}  viewBox="0 0 600 476.6" {...props}/>
  )
}
