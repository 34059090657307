import React from 'react';

//Components
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

//Custom Components
import styles from 'assets/jss/material-dashboard-react/components/tableStyle.js';
import { TableContainer } from 'components/Table/TableContainer';

//Icons
const TableCellHeader = withStyles((theme) => ({
  head: {
    color: '#fff',
    fontWeight: "bolder",
  },
  body: {
    fontSize: 15,
  },

}))(TableCell);
const useStyles = makeStyles(styles);

export const TableCliente = ({ titulos, clientes, editarOficina }) => {
  const classes = useStyles();
  return (
    //'nombres', 'apellidos', 'cedula_identidad', 'sexo', 'telefono'
    <TableContainer style={{ marginTop: 10 }}>
      <Table className={classes.table}>
        {titulos ? (
          <TableHead className={classes['TableHeader']} style={{ color: '#fff' }} >
            <TableRow className={classes.tableHeadRow}>
              {titulos.map((prop, key) => {
                return (
                  <TableCellHeader align="left" className={classes.tableCell + ' ' + classes.tableHeadCell} key={key}>
                    {prop}
                  </TableCellHeader>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}

        <TableBody>
          {clientes ? (
            <TableRow className={classes.tableBodyRow}>
              <TableCell className={classes.tableCell}>{clientes.cedula_identidad}</TableCell>
              <TableCell className={classes.tableCell}>{clientes.nombres}</TableCell>
              <TableCell className={classes.tableCell}>{clientes.apellidos}</TableCell>
              <TableCell className={classes.tableCell}>{clientes.telefono}</TableCell>
              <TableCell className={classes.tableCell}>{clientes.sexo}</TableCell>
            </TableRow>
          )
            : (<TableRow className={classes.tableBodyRow}></TableRow>)
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableCliente;
