import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';

import styles from 'assets/jss/material-dashboard-react/components/customInputStyle.js';

import { ErrorMessage } from "@hookform/error-message";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Controller } from "react-hook-form";

import Clear from '@material-ui/icons/Clear';
import Check from '@material-ui/icons/Check';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles(styles);

export default function CustomInput(props) {
  const classes = useStyles();
  const {

    formControlProps = {},

    name,
    control,
    defaultValue,
    as,
    rules,
    errors,
    helperText,
    //,
    label,
    multiline,
    minRows,
    disabled,
    ...propsInput
    // required: true

  } = props;

  return (
    <FormControl
     {...formControlProps}
      className={formControlProps.className}
    >
      <Controller
        {...propsInput}
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        as={
          <TextField
            multiline={multiline ? true : false}
            minRows={minRows ?? undefined}
            disabled={disabled}
          />
        }
        error={errors[name] ? true : false}
        helperText={helperText}
        
        // id="standard-full-width"
        label={label}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <FormHelperText
            className={classes.labelError}
          >
            {message}
          </FormHelperText>
        )}
      />
      {errors[name] ? (
        <Clear className={classes.feedback + ' ' + classes.labelRootError} />
      ) : !errors[name] ? (
        <Check className={classes.feedback + ' ' + classes.labelRootSuccess} />
      ) : null}


    </FormControl>
  );
}

CustomInput.propTypes = {
  // labelText: PropTypes.node,
  // labelProps: PropTypes.object,
  // id: PropTypes.string,
  // inputProps: PropTypes.object,
  // as: JSX.element,
  formControlProps: PropTypes.object,
  // error: PropTypes.bool,
  // success: PropTypes.bool,
};
