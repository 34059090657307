import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { Alert, AlertTitle } from '@material-ui/lab';
import { TextField, Paper, Typography } from "@material-ui/core";
import Button from "../../../CustomButtons/Button";
import RecetaVademecumDetailForm from "./RecetaVademecumDetailForm";
import { validacionesReceta } from "utils/validaciones";
import BuscadorTodoVadecum from "components/vadecum/vadecumtodo-buscador.component";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

const RecetaVademecumForm = (props) => {
  const {
    handleChangeRecetaFields,
    recetaFieldsValues,
    recetaFieldsErrors,
    handleConfirmSave,
  } = props;

  const firstRender = useRef(true);
  const refForm = useRef(null);
  const dataGeneral = useSelector((state) => state.datos_vademecum.data);

  let initialStateValores = [];

  const { medicinas, setMedicinas, handleDeleteMedicina, errorRecetario, errorVademecumForm } = props;

  if (props.data) {
    initialStateValores = props.data.detalle.map(m => ({
      cantidad: m.cantidad,
      dosificacion: m.dosificacion,
      fecha_inicio_uso: m.fecha_inicio_uso,
      dias_uso: m.dias_uso,
      frecuencia_uso_horas: m.frecuencia_uso_horas,
      medicina: {
        nombre: m.medicamento,
        _idDetalle: m.id,
        'id': m.vademecum_id,
        ...m
      }
    }));
  }

  const [error,] = useState({ title: "", mensaje: "" });
  const [, seterrors] = useState([{}]);
  const valores = useMemo(() => initialStateValores, [initialStateValores]);
  const [medicina, setmedicina] = useState("");

  useEffect(() => {
    if (firstRender.current) {
      return;
    }
    seterrors(validacionesReceta(valores));
  }, [valores]);

  const handleChange = (e, index) => {
    onChanges();
    const { name, value } = e.target;
    const arregoAux = [...medicinas];

    const result = arregoAux.map((item, itemIndex) => itemIndex === index ? { ...item, [name]: value } : item);
    setMedicinas(result);
  };

  const onChanges = () => {
    if (props.setRecetaDirty) props.setRecetaDirty(true);
    if (props.resetConsultaDate) props.resetConsultaDate(true);
  }

  const handleDateChange = (name, value, index) => {
    onChanges();
    const arregoAux = [...medicinas];
    const result = arregoAux.map((item, itemIndex) => itemIndex === index ? { ...item, [name]: value } : item);

    setMedicinas(result);
  };

  const handleAdd = () => {
    if (medicina) {
      onChanges();
      setmedicina("");
      setMedicinas([...medicinas, { medicina, cantidad: "", dosificacion: "", fecha_inicio_uso: new Date(), dias_uso: '', frecuencia_uso_horas: '' }]);
    }
  };

  return (
    <Paper ref={refForm} style={{ marginTop: 20, padding: 10 }} onContextMenu={(e) => e.preventDefault()} onCopy={(e) => e.preventDefault()} onCut={(e) => e.preventDefault()}>
      <Typography variant="body1">
        Receta
      </Typography>
      <div style={{ marginBottom: "10px", marginTop: '10px' }}>
        <span>Nombre/Composición/Función/Presentación/Dosificación/Presentación/Casa comercial</span>
      </div>
      <div style={{ display: "flex", margin: "20px auto" }}>
        <BuscadorTodoVadecum
          freeSolo={true}
          poblarData={dataGeneral}
          onBuscadoChange={(e, val) => {
            setmedicina(val);
          }}
        />
        <Button color="primary" onClick={handleAdd}>
          Agregar
        </Button>
      </div>
      <RecetaVademecumDetailForm
        errors={errorVademecumForm}
        error={error}
        valores={medicinas}
        handleChange={handleChange}
        handleDateChange={handleDateChange}
        handleMedDelete={handleDeleteMedicina}
      />

      {/* INDICACIONES NO FARMACOLÓGICAS  */}
      <GridContainer spacing={2}>
        <GridItem xs={12} style={{ textAlign: "center", marginTop: "20px" }}>
          <span style={{ fontWeight: "bold", fontSize: "large" }}>
            Indicaciones no farmacológicas
          </span>
        </GridItem>
        <GridItem xs={12}>
          <TextField
            label="Indicaciones"
            id="indicaciones"
            name="indicaciones"
            type="text"
            multiline
            minRows={4}
            fullWidth
            onChange={(e) => handleChangeRecetaFields(e)}
            value={recetaFieldsValues.indicaciones}
            error={(recetaFieldsErrors && recetaFieldsErrors.indicaciones) ? true : false}
          />

          {recetaFieldsErrors && recetaFieldsErrors.indicaciones && (
            <p>{recetaFieldsErrors.indicaciones}</p>
          )}
        </GridItem>
      </GridContainer>


      {/* Mostrar Errores */}
      <div style={{ marginTop: "5px" }}>
        {errorRecetario.mensaje && (
          <Alert severity={`${[errorRecetario.title]}`}>
            <AlertTitle>{errorRecetario.title === 'error' ? 'Error' : 'Success'}</AlertTitle>
            {errorRecetario.html
              ?
              (<div dangerouslySetInnerHTML={{ __html: errorRecetario.mensaje }}></div >)
              :
              (<>{errorRecetario.mensaje}</>)
            }
          </Alert>
        )}

      </div>

      {medicinas.length > 0 && (
        <div style={{ display: "flex", padding: 20, justifyContent: "center" }}>
          <Button color="primary" onClick={handleConfirmSave}>
            Guardar
          </Button>
        </div>
      )}
    </Paper>
  );
};

export default RecetaVademecumForm;
