import React from 'react'
import { InstitucionPerfil } from './InstitucionPerfil'
import { DoctorConfig } from 'components/Doctor/DoctorConfig'

export const InsitucionConfig = () => {
  return (
    <>
      <InstitucionPerfil />
      <DoctorConfig />
    </>
  )
}
