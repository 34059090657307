import { handleActions } from 'redux-actions';
import { vademecum, isLoadingVademecum, hasErrorVademecum } from '../actions/aVademecum';
import { vademecumDoctor, isLoadingVademecumDoctor, hasErrorVademecumDoctor } from '../actions/aVademecum';

export const datos_vademecum = handleActions(
  {
    [vademecum]: (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
      hasError: false,
    }),
    [isLoadingVademecum]: state => ({ ...state, isLoading: true, hasError: false }),
    [hasErrorVademecum]: (state, action) => ({
      ...state,
      isLoading: false,
      hasError: action.payload,
    }),
  },
  { isLoading: false, hasError: false, data: [] }
);

export const datos_vademecum_doctor = handleActions(
  {
    [vademecumDoctor]: (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
      hasError: false,
    }),
    [isLoadingVademecumDoctor]: state => ({ ...state, isLoading: true, hasError: false }),
    [hasErrorVademecumDoctor]: (state, action) => ({
      ...state,
      isLoading: false,
      hasError: action.payload,
    }),
  },
  { isLoading: false, hasError: false, data: [] }
);
