import React, { useMemo } from 'react';
import CRUD from 'components/CRUD/Crud.component';
import medicoSVG from "../../../src/assets/images/icons/medico.svg";
import { Register } from 'components/Login/Register';
import { RoleTypes } from 'types';
import { urlAdminLaboratorista } from 'constants/urls';

const LaboratoristaCrud = () => {

  const fileField = React.useRef(null);

  const tableData = useMemo(() => [
    {
      type: "imageInput",
      name: "foto_nombre",
      label: "Imagen",
      imgDefault: medicoSVG,
      ref: fileField
    },
    {
      type: "inputDisabled",
      label: "Id",
      name: 'id',
      showOnCreate: false,
      showOnTable: true,
      tableProps: {
        title: 'Id',
        sort: {
          sortColumnName: 'id',
          sortColumnNameDB: 'id',
        }
      },
    },
    {
      type: "input",
      name: "titulo_honorifico",
      label: "Título Honorífico",
      tableProps: {
        sort: {
          sortColumnName: 'titulo_honorifico',
          sortColumnNameDB: 'titulo_honorifico',
        }
      },
    },
    {
      type: "selectCombo",
      name: "cuenta_activa",
      label: "Cuenta Aprobada",
      defaultValue: null,
      options: [
        {
          value: null,
          label: "Seleccione un valor",
          disabled: true
        },
        {
          value: true,
          label: "Aprobada"
        },
        {
          value: false,
          label: "No aprobada"
        },
      ],
      tableProps: {
        formatData: (value) => value ? 'Aprobada' : 'No Aprobada',
        sort: {
          sortColumnName: 'cuenta_activa',
          sortColumnNameDB: 'cuenta_activa',
        }
      },
    },

  ], []);


  const data = useMemo(() => ({
    tableData,
    urls: {
      url: urlAdminLaboratorista
    }
  }), [tableData])

  return (
    <CRUD data={data} CustomCreateFormComponent={() => <Register defaultRole={RoleTypes.lab} adminMode />} />
  )
}

export default LaboratoristaCrud;
