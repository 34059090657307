import React from 'react'
import { SvgIcon } from '@material-ui/core';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import { ReactComponent as svgIcon } from "assets/images/icons/mail_print.svg";

export const MailPrintIcon = (props) => {

  const classes = useStyles();
  return (
    // <CustomIcon src={imgMailPrint} alt={'mailprintIcon'}/>
    <SvgIcon className={classes.svgIconStyle}  component={svgIcon} viewBox="0 0 600 476.6" {...props}/>
  );
}