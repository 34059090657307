import { urlInsitucionGuardarPerfil } from "constants/urls";
import { urlInstitucionRegistro } from "constants/urls";
import { encriptarRsa } from "utils/encriptar";
import { jwt } from "./aLogin";
import Notificacion from "components/ui/Notificacion";
import { login as loginData } from "redux/actions/aLogin";
import { urlInstitucionAceptarAsociacion } from "constants/urls";
import { urlInstitucionEliminarSolicitudAsociacion } from "constants/urls";
import { urlInstitucionEliminarAsociacion } from "constants/urls";
import { deleteTableCrudItemCustomCondition } from "./aAdmin";
import { urlInstitucionObtenerSucursales } from "constants/urls";

export const registrarInstitucion = async (param) => {
  try {
    const data = await encriptarRsa(JSON.stringify(param));

    if (data.error) return { error: data.mensaje };

    const raw = JSON.stringify({ data });

    var requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: raw,
    };

    const datos = await fetch(urlInstitucionRegistro, requestOptions).then((response) =>
      response.json()
    );

    if (datos.transaccion) {
      return datos;
    } else {
      return datos;
    }
  } catch (error) {
    return { error, transaccion: false };
  }
};

export const guardarPerfilInstitucion = (param, state) => async (dispatch) => {
  // dispatch(isLoadingLogin());
  try {
    const { token, dataUser } = state;

    const raw = JSON.stringify(param);
    var requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: raw,
    };

    return fetch(urlInsitucionGuardarPerfil, requestOptions)
      .then((response) => response.json())
      .then((datos) => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(""));
          return;
        }
        console.log(datos.data)
        if (datos.transaccion) {
          const nueva_data = {
            ...dataUser.data,
            ...datos.data,
          };
          Notificacion({
            type: "success",
            text: datos.mensaje,
          });
          // dispatch(login(nueva_data));

          console.log({ nueva_data })

          dispatch(loginData(nueva_data))
        } else {
          Notificacion({ type: "error", text: "No se pudo guardar." });
        }
      });
  } catch (error) {
    Notificacion({ type: "error", text: "No se pudo guardar." });
    return { error: "No se pudo guardar la información recargue la pagina " };
  }
};

export const aceptarSolicitudAsociacion = (oficinaId, doctorId) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    var requestOptions = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${urlInstitucionAceptarAsociacion}/${oficinaId}/${doctorId}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion)
      Notificacion({ type: "success", text: 'Solicitud aceptada correctamente', });
    else
      Notificacion({ type: "success", text: 'Error al aceptar la solicitud', });

    return datos;
  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const eliminarSolicitudAsociacionDoctorOficina = (oficinaId, doctorId) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    var requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${urlInstitucionEliminarSolicitudAsociacion}/${oficinaId}/${doctorId}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      Notificacion({ type: "success", text: 'Solicitud eliminada correctamente', });
      dispatch(
        deleteTableCrudItemCustomCondition({
          condition: (item) => item.oficina_id !== oficinaId && item.doctor_id !== doctorId
        })
      );
    } else
      Notificacion({ type: "error", text: 'Error al aceptar la solicitud', });

    return datos;
  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const eliminarAsociacionDoctorOficina = (oficinaId, doctorId) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    var requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${urlInstitucionEliminarAsociacion}/${oficinaId}/${doctorId}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      Notificacion({ type: "success", text: 'Asociación eliminada correctamente', });
      dispatch(
        deleteTableCrudItemCustomCondition({
          condition: (item) => item.oficina_id !== oficinaId && item.doctor_id !== doctorId
        })
      );
    }

    else
      Notificacion({ type: "error", text: 'Error al aceptar la solicitud', });

    return datos;
  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const obtenerSucursales = (institucionId) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    var requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${urlInstitucionObtenerSucursales}/${institucionId}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      return datos.data
    }
    throw (new Error('Error al recuperar los datos'))
  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

