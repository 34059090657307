import React, { useState } from 'react'
import Loading from 'components/Loading';
import { useForm } from 'react-hook-form';
import { enviarFormularioContacto } from 'redux/actions/aRegistro';
import Notificacion from 'components/ui/Notificacion';
import { FormInputControlled, FormSelectControlled } from 'components/forms/reactHookForm/FormElementsControlled';

export const Contacto = () => {

  const { register, handleSubmit, formState: { errors }, control } = useForm();

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    try {
      const dataToSend = { ...data, canal: "WEB_MEDICO" };
      setLoading(true);
      await enviarFormularioContacto(dataToSend);
      setLoading(false);
      Notificacion({
        type: "success",
        text: `Mensaje enviado correctamente`,
      });
    } catch (error) {
      setLoading(false);
      Notificacion({
        type: "error",
        text: `Ocurrio un error, intente nuevamente`,
      });
    }
  }

  return (
    <div className='landing-page contact_page'>
      <div className="space_nav"></div>

      <div className="contact_section even">
        <div className="contact form">

          <form onSubmit={handleSubmit(onSubmit)}>
            <h1>Contacto</h1>

            {/* Nombre */}
            <FormInputControlled
              id={'nombre'}
              label={'Nombre:'}
              control={control}
              name={'nombre'}
              errors={errors}
              register={register}
              formInputType={'input'}
              rules={{
                required: {
                  value: true,
                  message: "El campo  es requerido",
                }
              }}
            />
            <br />

            {/* Email */}
            <FormInputControlled
              id={'email'}
              label={'Email:'}
              control={control}
              name={'email'}
              errors={errors}
              register={register}
              formInputType={'input'}
              rules={{
                required: {
                  value: true,
                  message: "El campo  es requerido",
                }
              }}
            />
            <br />

            {/* Teléfono */}
            <FormInputControlled
              id={'telefono'}
              label={'Teléfono:'}
              control={control}
              name={'telefono'}
              errors={errors}
              register={register}
              formInputType={'input'}
              rules={{
                required: {
                  value: true,
                  message: "El campo  es requerido",
                }
              }}
            />
            <br />

            {/* Ciudad */}
            <FormSelectControlled
              id={'ciudad'}
              label={'Ciudad:'}
              control={control}
              name={'ciudad'}
              errors={errors}
              register={register}
              options={[
                { value: 'Cuenca', label: 'Cuenca' },
                { value: 'Otra', label: 'Otra' }
              ]}
              rules={{
                required: {
                  value: true,
                  message: "El campo  es requerido",
                }
              }} />

            {/* Mensaje */}
            <FormInputControlled
              id={'mensaje'}
              label={'Mensaje:'}
              control={control}
              name={'mensaje'}
              errors={errors}
              register={register}
              formInputType={'textarea'}
              rules={{
                required: {
                  value: true,
                  message: "El campo es requerido",
                }
              }}
            />

            <br /><br />

            <Loading title="Enviando mensaje..." loading={loading}>
              <>
                <button className="registrarse" type="submit">Enviar</button>
              </>
            </Loading>

            <div className="info">
              <div id="phone">
                <div id="icon_phone"></div>
                <h4>+593996639451</h4>
              </div>
              <div id="mail">
                <div id="icon_mail"></div>
                <h4>support@medpal.com.ec</h4>
              </div>
            </div>

          </form>
          {/* <div id="img_contact" className="img"></div> */}
        </div>
      </div>
      <div className="space_nav2"></div>
    </div>
  )
}
