import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  IconButton,
  TableHead,
  TableSortLabel,
  Tooltip
} from "@material-ui/core";

import Button from "components/CustomButtons/Button.js";
import _ from 'lodash';
import moment from "moment";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CustomTableCellCrud from "components/Admin/CRUD/CustomTableCellCrud";

import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";

import Loading from "components/Loading";
import { TableRowCrud } from "components/Admin/CRUD/TableRowCrud";
import { FormCrud } from "components/Admin/CRUD/FormCrud";
import ConfirmDialog from "components/Admin/CRUD/ConfirmDialog";
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { startloadPacientesAtendidos, startEditPacienteAtendidos, eliminarPacienteAtendido, setCliente } from "redux/actions/aClientes";
import { useHistory } from "react-router-dom";
import AssignmentIcon from '@material-ui/icons/Assignment';
import AddIcon from '@material-ui/icons/Add';
import { selectInstruccionOptions, selectTipoSangreOptions, selectEstadoCivilOptions } from "constants/constants";
import { ClienteForm } from "components/forms/ClienteForm";
import { getSegurosPorBusqueda } from "redux/actions/aMedico";
import { startSearchPaises } from "redux/actions/aClientes";
import { TableContainer } from "components/Table/TableContainer";
import { SearchTextField } from "components/forms/input/SearchTextField";
import { formatPatientFiliationData } from "utils/paciente/paciente";
import { AgendarCitasIcon } from "components/CustomIcons/Sidebar/AgendarCitasIcon";
import LibraryBooks from "@material-ui/icons/LibraryBooks";

let useStyles = makeStyles(styles);

const TableCellHeader = withStyles((theme) => {
  return {
    head: {
      color: theme.palette.common.white,
      fontWeight: "bolder",
    },
  };
})(TableCell);

const rowsPerPageDefault = 20;

const PacienteAtendidos = ({ doctor_id, secretaryRol }) => {
  const classes = useStyles();
  const history = useHistory();

  // 0: tabla, 1:crear, 2: editar
  const [view, setView] = useState(0);
  const [itemSelected, setItemSelected] = useState({});

  const [formEditActiveUser, setformEditActiveUser] = useState(false);

  const [showFormCliente, setShowFormCliente] = useState(false);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");

  // const debouncedCount = useDebounce(search, 350);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openConfirmDialogDeleteResponse, setOpenConfirmDialogDeleteResponse] = useState(false);


  //Ordenamiento
  const [orderData, setOrderData] = useState({
    sortBy: 'ultima_consulta', // default sort column
    sortOrder: "desc" // default sort oder
  })

  const { sortBy, sortOrder } = orderData;

  const dispatch = useDispatch();
  const { pacientes } = useSelector(
    (state) => state.data_clientes
  );

  const { data, dataPagination } = pacientes;
  const { total } = dataPagination;

  const [formError, setFormError] = useState(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageDefault);
  const [skipLocal, setSkipLocal] = React.useState(0);

  const [segurosData, setSegurosData] = useState([]);
  const [paisesData, setPaisesData] = useState([]);

  const handleInputSearchChange = ({ target }) => {
    setSearch(target.value);
    debouncedSearch(target.value);
  }

  const debouncedSearch = React.useRef(
    _.debounce(async (searchValue) => {
      setSearchValue(searchValue);
    }, 350)
  ).current;

  // Buscar seguros paciente
  const handleSearchSeguros = useCallback(
    async (value) => {
      try {
        const { data } = await dispatch(getSegurosPorBusqueda(value));
        setSegurosData(data);

      } catch (error) {
      }
    },
    [dispatch],
  )

  // Buscar paises
  const handleSearchPaises = useCallback(
    async (value) => {
      try {
        const { data } = await dispatch(startSearchPaises(value));
        setPaisesData(data);

      } catch (error) {
      }
    },
    [dispatch],
  )

  const formatSeguros = (seguro) => ({
    ...seguro, db: true,
  });

  const initialStateFormComponents = useMemo(
    () => [
      {
        type: "input",
        name: "cedula_identidad",
        label: "Cédula Identidad",
        value: itemSelected.cedula_identidad ? itemSelected.cedula_identidad : "",
        disabled: formEditActiveUser,
        validationOptions: {
          required: {
            value: true,
            message: "El campo cedula de Identidad es requerido",
          },
        },
      },
      {
        type: "input",
        name: "cedula_representante",
        label: "Cédula Representante",
        value: itemSelected.cedula_representante ? itemSelected.cedula_representante : "",
        disabled: formEditActiveUser,
      },
      {
        type: "input",
        name: "titulo_honorifico",
        label: "Titulo Honorífico",
        value: itemSelected.titulo_honorifico ? itemSelected.titulo_honorifico : "",
        disabled: formEditActiveUser,
      },
      {
        type: "input",
        name: "nombres",
        label: "Nombres",
        disabled: formEditActiveUser,
        value: itemSelected.nombres ? itemSelected.nombres : "",
        validationOptions: {
          required: {
            value: true,
            message: "El campo nombres es requerido",
          },
        },
      },
      {
        type: "input",
        name: "apellidos",
        label: "Apellidos",
        disabled: formEditActiveUser,
        value: itemSelected.apellidos ? itemSelected.apellidos : "",
        validationOptions: {
          required: {
            value: true,
            message: "El campo apellidos es requerido",
          },
        },
      },
      {
        type: "input",
        name: "nombres_padre",
        label: "Nombres Padre",
        disabled: formEditActiveUser,
        value: itemSelected.nombres_padre ? itemSelected.nombres_padre : "",
      },
      {
        type: "input",
        name: "nombres_madre",
        label: "Nombres Madre",
        disabled: formEditActiveUser,
        value: itemSelected.nombres_madre ? itemSelected.nombres_madre : "",
      },
      {
        type: "input",
        name: "direccion",
        label: "Direccion",
        disabled: formEditActiveUser,
        value: itemSelected.direccion ? itemSelected.direccion : "",
      },
      {
        type: "input",
        name: "ciudad",
        label: "Ciudad",
        disabled: formEditActiveUser,
        value: itemSelected.ciudad ? itemSelected.ciudad : "",
      },
      {
        type: "autocompleteMultipleV2",
        name: "pais",
        label: "País de residencia",
        disabled: formEditActiveUser,
        defaultValue: itemSelected.pais ? itemSelected.pais : "",
        multiple: false,
        freeSolo: false,
        dispatchFunction: handleSearchPaises,
        options: paisesData,
      },
      {
        type: "autocompleteMultipleV2",
        name: "nacionalidad",
        label: "País de origen",
        disabled: formEditActiveUser,
        defaultValue: itemSelected.nacionalidad ? itemSelected.nacionalidad : "",
        multiple: false,
        freeSolo: false,
        dispatchFunction: handleSearchPaises,
        options: paisesData,
      },
      {
        type: "radio",
        name: "sexo",
        label: "Sexo",
        value: itemSelected.sexo,
        disabled: formEditActiveUser,
        defaultValue: itemSelected.sexo ? itemSelected.sexo : '',
        options: [
          {
            label: "Masculino",
            value: "masculino",

          },
          {
            label: "Femenino",
            value: "femenino",

          },
          {
            label: "Otro",
            value: "otro",

          },
        ]
      },
      {
        type: "input",
        name: "telefono",
        label: "Teléfono",
        disabled: formEditActiveUser,
        value: itemSelected.telefono ? itemSelected.telefono : "",
      },
      {
        type: "input",
        name: "celular",
        label: "Celular",
        disabled: formEditActiveUser,
        value: itemSelected.celular ? itemSelected.celular : "",
      },
      {
        type: "date",
        name: "fecha_nacimiento",
        label: "Fecha Nacimiento",
        disabled: formEditActiveUser,
        value: itemSelected.fecha_nacimiento ? moment.utc(itemSelected.fecha_nacimiento) : new Date(),
      },
      {
        type: "selectCombo",
        name: "tipo_sangre",
        label: "Tipo de sangre",
        disabled: formEditActiveUser,
        value: itemSelected.tipo_sangre ?? '',
        options: [
          {
            value: '',
            label: "Seleccione un valor",
            disabled: true
          },
          ...selectTipoSangreOptions
        ]
      },
      {
        type: "selectCombo",
        name: "instruccion",
        label: "Instrucción",
        disabled: formEditActiveUser,
        value: itemSelected.instruccion ? itemSelected.instruccion : '',
        options: [
          {
            value: '',
            label: "Seleccione un valor",
            disabled: true
          },
          ...selectInstruccionOptions
        ]
      },
      {
        type: "input",
        name: "ocupacion",
        label: "Ocupación",
        disabled: formEditActiveUser,
        value: itemSelected.ocupacion ? itemSelected.ocupacion : "",
      },
      {
        type: "selectCombo",
        name: "estado_civil",
        label: "Estado civil",
        disabled: formEditActiveUser,
        value: itemSelected.estado_civil ? itemSelected.estado_civil : '',
        options: [
          {
            value: '',
            label: "Seleccione un valor",
            disabled: true
          },
          ...selectEstadoCivilOptions
        ]
      },
      {
        type: "autocompleteMultipleV2",
        name: "seguros",
        label: "Seguros",
        disabled: formEditActiveUser,
        defaultValue: itemSelected.seguros ? itemSelected.seguros.map(item => formatSeguros(item)) : [],
        multiple: true,
        freeSolo: true,
        dispatchFunction: handleSearchSeguros,
        options: segurosData,
      },
      {
        type: "input",
        name: "correo",
        label: "Correo",
        disabled: formEditActiveUser,
        value: itemSelected.correo ? itemSelected.correo : "",
      },
    ],
    [itemSelected, segurosData, handleSearchSeguros, handleSearchPaises, paisesData, formEditActiveUser]
  );

  // Paginación
  const handleChangePage = useCallback(
    (event, newPage) => {
      if (page > newPage) {
        setSkipLocal(skipLocal - rowsPerPage);
      } else {
        setSkipLocal(skipLocal + rowsPerPage);
      }
      setPage(newPage);
    },
    [skipLocal, page, rowsPerPage]
  );

  // Paginación, cambiar número de registros por página
  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setSkipLocal(0);
  }, []);

  //  Botón editar paciente
  const handleClickEdit = useCallback(
    (item) => {

      setItemSelected(item);
      setFormError(null);

      //Formulario de usuario activo/inactivo
      setformEditActiveUser(!item.habilitar_edicion);

      setView(2);
    },
    []
  );

  //  Botón eliminar paciente
  const handleClickDelete = useCallback((item) => {
    setItemSelected(item);
    setOpenConfirmDialog(true);
  }, []);

  //  Botón regresar a tabla de paciente
  const handleClickReturn = useCallback(() => {
    setFormError(null);
    setItemSelected({});
    setView(0);
  }, []);

  //  Botón editar paciente
  const onSubmitForm = useCallback(
    async (data, type) => {
      let dataForm = { ...data };
      if (type === "editar") {
        !loading && setLoading(true);
        let respuesta = await dispatch(
          startEditPacienteAtendidos(doctor_id, itemSelected.id, dataForm)
        );
        setLoading(false);
        const { transaccion, mensaje } = respuesta;
        !transaccion ? setError(mensaje) : setError(false);
      }
    },
    [loading, itemSelected, dispatch, doctor_id]
  );

  // Confirmar eliminación de usuario
  const handleConfirmDelete = useCallback(async () => {
    !loading && setLoading(true);
    let respuesta = await dispatch(eliminarPacienteAtendido(doctor_id, itemSelected.id));
    setLoading(false);
    const { responseStatus } = respuesta;
    //Cuando el paciente tiene reservas o recetas registradas, mostrar mensaje
    if (responseStatus === 409) setOpenConfirmDialogDeleteResponse(true);

  }, [loading, itemSelected, dispatch, doctor_id]);

  // Redirigir a soporte y adjuntar mensaje de solicitud de eliminación de usuario
  const handleConfirmDeleteResponse = useCallback(async () => {
    const data = { asunto: "Solicitud de eliminación de usuario", mensaje: `Id del paciente: ${itemSelected.id}\n\nRazón:` }
    localStorage.setItem("soporteMensaje", JSON.stringify(data));
    history.push('/medico/ayuda');
  }, [itemSelected, history]);

  // Navegar a historia clínica de un paciente
  const handleClickHistoriaClinica = (data) => {

    const dataPaciente = formatPatientFiliationData(data);

    localStorage.setItem('setCliente', 'true');
    dispatch(setCliente(dataPaciente));
    history.push('/medico/historia-clinica');
  }

  // Secretaria: Navegar a reservas
  const handleClickReservarCita = (data) => {

    const dataPaciente = formatPatientFiliationData(data);

    localStorage.setItem('setCliente', 'true');
    dispatch(setCliente(dataPaciente));
    history.push('/secretaria/reservaciones');
  }

  // Secretaria: Navegar a caja
  const handleClickCajas = (data) => {

    const dataPaciente = formatPatientFiliationData(data);

    localStorage.setItem('setCliente', 'true');
    dispatch(setCliente(dataPaciente));
    history.push('/secretaria/cajas');
  }


  const handleRequestSort = useCallback(
    async (sortByColumn) => {
      let sortOrderNew;
      if (!sortBy || sortByColumn !== sortBy) {
        sortOrderNew = "asc";
        setOrderData({
          sortBy: sortByColumn, // default sort column
          sortOrder: sortOrderNew // default sort oder
        })
      } else {
        if (sortByColumn === sortBy) {
          sortOrderNew = sortOrder === "asc" ? "desc" : "asc"
          setOrderData({
            sortBy: sortByColumn, // default sort column
            sortOrder: sortOrderNew// default sort oder
          })
        }
      }
    },
    [sortBy, sortOrder],
  );

  const titulosTable = [
    { title: "" },
    { title: "Cédula Identidad", onClickSort: () => handleRequestSort("cedula_identidad"), columnName: "cedula_identidad" },
    { title: "Cédula representante", onClickSort: () => handleRequestSort("cedula_representante"), columnName: "cedula_representante" },
    { title: "Última consulta", onClickSort: () => handleRequestSort("ultima_consulta"), columnName: "ultima_consulta" },
    { title: "Título honorífico", onClickSort: () => handleRequestSort("titulo_honorifico"), columnName: "titulo_honorifico" },
    { title: "Nombres", onClickSort: () => handleRequestSort("nombres"), columnName: "nombres" },
    { title: "Apellidos", onClickSort: () => handleRequestSort("apellidos"), columnName: "apellidos" },
    { title: "Nombres Padre", onClickSort: () => handleRequestSort("nombres_padre"), columnName: "nombres_padre" },
    { title: "Nombres Madre", onClickSort: () => handleRequestSort("nombres_madre"), columnName: "nombres_madre" },
    { title: "Dirección", onClickSort: () => handleRequestSort("direccion"), columnName: "direccion" },
    { title: "Ciudad", onClickSort: () => handleRequestSort("ciudad"), columnName: "ciudad" },
    { title: "País", onClickSort: () => handleRequestSort("pais"), columnName: "pais" },
    { title: "Sexo", onClickSort: () => handleRequestSort("sexo"), columnName: "sexo" },
    { title: "Teléfono", onClickSort: () => handleRequestSort("telefono"), columnName: "telefono" },
    { title: "Celular", onClickSort: () => handleRequestSort("celular"), columnName: "celular" },
    { title: "Fecha de nacimiento", onClickSort: () => handleRequestSort("fecha_nacimiento"), columnName: "fecha_nacimiento" },
    { title: "Tipo de Sangre", onClickSort: () => handleRequestSort("tipo_sangre"), columnName: "tipo_sangre" },
    { title: "Seguros" },

    { title: "Correo", onClickSort: () => handleRequestSort("correo"), columnName: "correo" },
    { title: "Opciones" },
  ];

  // Cargar data de pacientes
  const initLoadData = useCallback(
    async ({ doctorId, skip = 0, take = rowsPerPageDefault, search = '', sortBy = 'ultima_consulta', sort = 'ASC' } = {}) => {
      setLoading(true);
      let respuesta = await dispatch(startloadPacientesAtendidos(doctorId, skip, take, search, sortBy, sort));
      setLoading(false);
      const { transaccion, mensaje } = respuesta;

      !transaccion ? setError(mensaje) : setError(false);
    },
    [dispatch],
  )

  // Cerrar formulario y visualizar tabla
  const handleCloseFormCliente = useCallback(
    () => {
      setShowFormCliente(false);
      initLoadData({ doctorId: doctor_id, skip: skipLocal, take: rowsPerPage, search: searchValue, sortBy, sort: sortOrder });
    },
    [rowsPerPage, skipLocal, initLoadData, sortOrder, sortBy, searchValue, doctor_id],
  )

  useEffect(() => {
    //Verificar parámetros de redireccion desde otras páginas
    const redirectDoctorPacientesForm = localStorage.getItem('redirectDoctorPacientesForm');
    localStorage.removeItem('redirectDoctorPacientesForm');
    if (redirectDoctorPacientesForm) setShowFormCliente(true)
  }, [])

  useEffect(() => {
    // Cargar data de pacientes
    initLoadData({ doctorId: doctor_id, skip: skipLocal, take: rowsPerPage, search: searchValue, sortBy, sort: sortOrder });
  }, [rowsPerPage, skipLocal, initLoadData, sortOrder, sortBy, searchValue, doctor_id]);

  if (!showFormCliente)
    return (
      <div>
        <ConfirmDialog
          title={"Confirmación de eliminación"}
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onConfirm={handleConfirmDelete}
        >
          <div>¿Esta seguro de eliminar este registro?</div>
        </ConfirmDialog>
        <ConfirmDialog
          title={"Confirmación de eliminación"}

          open={openConfirmDialogDeleteResponse}
          setOpen={setOpenConfirmDialogDeleteResponse}
          onConfirm={handleConfirmDeleteResponse}
        >
          <div>Este usuario ya tiene registrado datos de reservas o recetas, para borrarlo se requiere de comunicarlo con administración. ¿Desea confirmar la acción?</div>
        </ConfirmDialog>
        {formError && <Alert severity="error">{formError}</Alert>}
        {error && <Alert severity="error">{error}</Alert>}
        {view === 0 ? (
          <div>
            <div>
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonMargin}
                onClick={() => setShowFormCliente(true)}
                startIcon={<AddIcon />}
              >
                Nuevo paciente
              </Button>
            </div>

            <SearchTextField
              value={search}
              onChange={handleInputSearchChange}
              style={{ marginTop: '25px', marginBottom: '25px' }}
            />


            <Loading title="Cargando..." loading={loading}>
              <TableContainer component={Paper}>
                <Table className={classes.table}>
                  <TableHead className={classes[`${'warning'} ${'TableHeader'}`]}>
                    <TableRow className={classes.tableHeadRow}>
                      {titulosTable &&
                        titulosTable.map((item, key) => (
                          item.onClickSort ?
                            <TableCellHeader key={key}>
                              <TableSortLabel
                                active={sortBy === item.columnName}
                                direction={sortOrder}
                                onClick={item.onClickSort}
                                hideSortIcon={sortBy !== item.columnName}
                                className={classes.tableCell + " " + classes.tableHeadCell}
                              >
                                {item.title}
                              </TableSortLabel>
                            </TableCellHeader>
                            :
                            <TableCellHeader
                              key={key}
                              className={classes.tableCell + " " + classes.tableHeadCell}
                            >
                              {item.title}
                            </TableCellHeader>
                        ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {data &&
                      data.map((item) => (
                        <TableRowCrud
                          key={item.id}
                          keyValue={item.id}
                          setView={setView}
                          item={item}
                          handleEdit={handleClickEdit}
                          handleDelete={(item.creado_por === doctor_id && !item.user?.activated) ? handleClickDelete : null}
                        >
                          {/* Button start options */}

                          {secretaryRol
                            ?
                            <>
                              <TableCell
                                classes={classes.sizeCell}
                              >
                                <Tooltip
                                  id="tooltip-servicio"
                                  title={"Nueva cita"}
                                  placement="top"
                                  classes={{ tooltip: classes.tooltip }}
                                >
                                  <IconButton
                                    aria-label="Close"
                                    // className={classes.tableActionButton}
                                    onClick={() => handleClickReservarCita(item)}
                                  >
                                    <AgendarCitasIcon
                                      style={{
                                        marginRight: '0px',
                                        paddingLeft: '3px'
                                        // padding: '8px'
                                      }}
                                    // className={
                                    //   classes.tableActionButtonIcon + " " + classes.close
                                    // }
                                    />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip
                                  id="tooltip-servicio"
                                  title={"Ver en Caja"}
                                  placement="top"
                                  classes={{ tooltip: classes.tooltip }}
                                >
                                  <IconButton
                                    aria-label="Close"
                                    className={classes.tableActionButton}
                                    onClick={() => handleClickCajas(item)}
                                  >
                                    <LibraryBooks
                                      className={
                                        classes.tableActionButtonIcon + " " + classes.close
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </>

                            :
                            <TableCell
                              classes={classes.sizeCell}
                            >
                              <Tooltip
                                id="tooltip-servicio"
                                title={"Ver historia clínica"}
                                placement="top"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <IconButton
                                  aria-label="Close"
                                  className={classes.tableActionButton}
                                  onClick={() => handleClickHistoriaClinica(item)}
                                >
                                  <AssignmentIcon
                                    className={
                                      classes.tableActionButtonIcon + " " + classes.close
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          }


                          <CustomTableCellCrud
                            classes={classes.sizeCell}
                            data={item.cedula_identidad}
                          />
                          <CustomTableCellCrud
                            classes={classes.sizeCell}
                            data={item.cedula_representante}
                          />
                          <CustomTableCellCrud
                            classes={classes.sizeCell}
                            data={item.ultima_consulta ? moment(item.ultima_consulta).format('DD/MMMM/YYYY, h:mm a') : ''}
                          />
                          <CustomTableCellCrud
                            classes={classes.sizeCell}
                            data={item.titulo_honorifico}
                          />
                          <CustomTableCellCrud
                            classes={classes.sizeCell}
                            data={item.nombres}
                          />
                          <CustomTableCellCrud
                            classes={classes.sizeCell}
                            data={item.apellidos}
                          />
                          <CustomTableCellCrud
                            classes={classes.sizeCell}
                            data={item.nombres_padre}
                          />
                          <CustomTableCellCrud
                            classes={classes.sizeCell}
                            data={item.nombres_madre}
                          />
                          <CustomTableCellCrud
                            classes={classes.sizeCell}
                            data={item.direccion}
                          />
                          <CustomTableCellCrud
                            classes={classes.sizeCell}
                            data={item.ciudad}
                          />
                          <CustomTableCellCrud
                            classes={classes.sizeCell}
                            data={item.pais}
                          />
                          <CustomTableCellCrud
                            classes={classes.sizeCell}
                            data={item.sexo}
                          />
                          <CustomTableCellCrud
                            classes={classes.sizeCell}
                            data={item.telefono}
                          />
                          <CustomTableCellCrud
                            classes={classes.sizeCell}
                            data={item.celular}
                          />
                          <CustomTableCellCrud
                            classes={classes.sizeCell}
                            data={moment.utc(item.fecha_nacimiento).format(
                              "DD/MMMM/YYYY"
                            )}
                          />
                          <CustomTableCellCrud
                            classes={classes.sizeCell}
                            data={item.tipo_sangre}
                          />
                          <CustomTableCellCrud
                            classes={classes.sizeCell}
                            data={item.seguros && item.seguros.length > 0 ? item.seguros.map(item => item.nombre).join(', ') : ''}
                          />
                          <CustomTableCellCrud
                            classes={classes.sizeCell}
                            data={item.correo}
                          />

                        </TableRowCrud>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Loading>
            <TablePagination
              rowsPerPageOptions={[10, 20, 100]}
              component="div"
              count={total ? +total : 10}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        ) : (
          <FormCrud
            setView={setView}
            edit={view === 2 ? true : false}
            formComponents={initialStateFormComponents}
            onSubmitForm={onSubmitForm}
            handleReturn={handleClickReturn}
            isLoading={loading}
            hasError={error}
          />
        )
        }
      </div >
    );
  else
    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          className={classes.buttonMargin}
          onClick={() => setShowFormCliente(false)}
        >
          Ver pacientes
        </Button>
        <ClienteForm setShowForm={handleCloseFormCliente} />
      </div>

    )
};

export default PacienteAtendidos;
