// import { createAction } from "redux-actions";
import Notificacion from "components/ui/Notificacion";

import { urlCrearNuevoRol, urlEnviarFormContacto, urlRegistro, urlSolicitarNuevoRol, urlStartResetPass } from "../../constants/urls";
// import { dataRegistro } from "../../constants/estructuras";
import { encriptarRsa } from "../../utils/encriptar";
import { jwt, rol } from "./aLogin";

export const registroFetch = async (param) => {
  try {
    const data = await encriptarRsa(JSON.stringify(param));

    if (data.error) return { error: data.mensaje };

    const raw = JSON.stringify({ data });

    var requestOptions = {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: raw,
    };

    const datos = await fetch(urlRegistro, requestOptions).then((response) =>
      response.json()
    );

    if (datos.transaccion) {
      return datos;
    } else {
      return datos;
    }
  } catch (error) {
    return { error, transaccion: false };
  }
};

export const recuperarPassword = async (param) => {
  
  try {
    const data = await encriptarRsa(JSON.stringify(param));

    if (data.error) return { error: data.mensaje };

    const raw = JSON.stringify({ data });

    var requestOptions = {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: raw,
    };

    const datos = await fetch(urlStartResetPass, requestOptions).then((response) =>
      response.json()
    );

    if (datos.transaccion) {
      Notificacion({
        type: "success",
        text: datos.mensaje,
      });
      return datos;
    } else {
      Notificacion({
        type: "error",
        text: "Error \n" + datos.mensaje,
      });
      return datos;
    }
  } catch (error) {
    return { error, transaccion: false };
  }
};

export const enviarFormularioContacto = async (data) => {
  try {
   
    const raw = JSON.stringify(data);

    var requestOptions = {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: raw,
    };

    const datos = await fetch(urlEnviarFormContacto, requestOptions).then((response) =>
      response.json()
    );

    if (datos.transaccion) {
      return datos;
    } else {
      return datos;
    }
  } catch (error) {
    return { error, transaccion: false };
  }
};


export const requestCreateRol = (data) => async ( dispatch, getstate) => {
  try {
    const { token } = getstate();
    const raw = JSON.stringify(data);

    var requestOptions = {
      method: "post",
      headers: { 
        "Content-Type": "application/json", 
        authorization: `Bearer ${token}`,
      },
      body: raw,
    };

    const response = await fetch(urlSolicitarNuevoRol, requestOptions);
    const datos =  await response.json();

    if (datos.transaccion) {
      Notificacion({ type: "success", text: datos.mensaje,});
      return datos;
    } else {
      Notificacion({ type: "error", text: datos.mensaje,});
      return datos;
    }
  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la página',
    });
    return { error, transaccion: false, mensaje: 'No se pudo recuperar la información recargue la página' };
  }
};

export const createRol = (data) => async ( dispatch, getstate) => {
  try {
    const { token } = getstate();
    const raw = JSON.stringify(data);

    var requestOptions = {
      method: "post",
      headers: { 
        "Content-Type": "application/json", 
        authorization: `Bearer ${token}`,
      },
      body: raw,
    };

    const response = await fetch(urlCrearNuevoRol, requestOptions);
    const datos =  await response.json();

    if (datos.transaccion) {
      dispatch(jwt(datos.token));
      dispatch(rol(datos.auth));
      
      Notificacion({ type: "success", text: datos.mensaje,});
      return datos;
    } else {
      Notificacion({ type: "error", text: datos.mensaje,});
      return datos;
    }
  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la página',
    });
    return { error, transaccion: false, mensaje: 'No se pudo recuperar la información recargue la página' };
  }
};
