import React from 'react'
import InicioUser from "Views/InicioUser"
import { DoctorConfig } from 'components/Doctor/DoctorConfig'
import { DoctorFacturacionConf } from 'components/Doctor/DoctorFacturacionConf'

export const DoctorPerfil = () => {

  return (
    <>
      {/* Perfil */}
      <InicioUser />
      <DoctorFacturacionConf />
      {/* Payphone */}
      <DoctorConfig />
    </>
  )
}
