import {
  warningColor,
  primaryColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  defaultFont,
  // whiteColor,
  primaryTableHeader,
} from "assets/jss/material-dashboard-react.js";

const tableStyle = (theme) => ({
  warningTableHeader: {
    color: warningColor[0],
  },
  primaryTableHeader: {
    //primaryCardHeader
    color: primaryColor[0],
    "&$primaryTableHeader": {
      // margin: "0 15px",
      // padding: "0",
      // position: "relative",
      // color: whiteColor,
    },
    primaryTableHeader,
  },
  dangerTableHeader: {
    color: dangerColor[0],
  },
  successTableHeader: {
    color: successColor[0],
  },
  infoTableHeader: {
    color: infoColor[0],
  },
  roseTableHeader: {
    color: roseColor[0],
  },
  grayTableHeader: {
    color: grayColor[0],
  },
  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "transparent",
    borderSpacing: "0",
    borderCollapse: "collapse",
  },
  tableContainer: {
    borderRadius: '20px'
  },
  tableHeadCell: {
    color: "inherit",
    ...defaultFont,
    "&, &$tableCell": {
      fontSize: "1em",
      fontWeight: 'bold',
      color: 'black',
      textAlign: 'center',
    },
  },
  tableCell: {
    ...defaultFont,
    lineHeight: "1.42857143",
    padding: "12px 8px",
    verticalAlign: "middle",
    fontSize: "0.8125rem",
    textAlign: 'center',
    margin: 'auto',
  },
  tableResponsive: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  tableHeadRow: {
    background: grayColor[11],
    height: "56px",
    color: "inherit",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle",
  },
  tableBodyRow: {
    height: "48px",
    color: "inherit",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle",
  },

  //Estilos Agenda de citas
  tableRowCreditos: {
    backgroundColor: primaryColor[2],
    fontSize: '1.5rem',
    color: 'white',
    fontWeight: 'bolder',
    textAlign: 'center',
    borderRadius: '10px'
  },
  tableRowCreditosCero: {
    fontSize: '1.5rem',
    color: 'var(--greymedium)',
    fontWeight: 'bolder',
    textAlign: 'center',
    borderRadius: '10px'
  },
  tableRowCitaServicio: {
    fontSize: '1.4rem',
    color: 'white',
    backgroundColor: primaryColor[0],
    borderRadius: '10px'
  },
  tableRowCenter: {
    display: 'flex',
    justifyContent: 'center'
  }
});

export default tableStyle;
