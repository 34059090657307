import { secretariaDoctorSelected } from 'redux/actions/aSecretaria';
import { horariosOficina, isLoading, hasError, selectOficina } from '../actions/aOficinas';
import { oficinas, isLoadingOficinas, hasErrorOficinas } from '../actions/aOficinas';
import { horarios_reservas, isLoadingHorarioReservas, hasErrorHorarioReservas } from '../actions/aOficinas';
import { medicos, isLoadingMedicos, hasErrorMedicos } from '../actions/aOficinas';
import { handleActions } from 'redux-actions';

export const medicos_secretaria = handleActions(
  {
    [secretariaDoctorSelected]: (state, action) => ({
      ...state,
      doctorSelected: action.payload,
      isLoadingMedicos: false,
      hasError: false,
    }),
    [medicos]: (state, action) => ({
      ...state,
      data: action.payload,
      isLoadingMedicos: false,
      hasError: false,
    }),
    [isLoadingMedicos]: state => ({ ...state, isLoadingMedicos: true, hasError: false }),
    [hasErrorMedicos]: (state, action) => ({
      ...state,
      isLoadingMedicos: false,
      hasError: action.payload,
    }),
  },
  { isLoadingMedicos: false, hasError: false, data: [] }
);

export const horariosOficinas = handleActions(
  {
    [horariosOficina]: (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
    }),
    [isLoading]: state => ({ ...state, isLoading: true, hasError: false }),
    [hasError]: (state, action) => ({
      ...state,
      isLoading: false,
      hasError: action.payload,
    }),
  },
  { isLoading: false, hasError: false, data: [] }
);

export const data_oficinas = handleActions(
  {
    [oficinas]: (state, action) => ({
      ...state,
      data: action.payload,
      isLoadingOficinas: false,
      hasError: false,
    }),
    [selectOficina]: (state, action) => ({
      ...state,
      oficinaSelected: action.payload,
      isLoadingOficinas: false,
      hasError: false,
    }),
    [isLoadingOficinas]: state => ({ ...state, isLoadingOficinas: true, hasError: false }),
    [hasErrorOficinas]: (state, action) => ({
      ...state,
      isLoadingOficinas: false,
      hasError: action.payload,
    }),
  },
  { isLoadingOficinas: false, hasError: false, data: [], oficinaSelected: null }
);

export const data_horarios_reservas = handleActions(
  {
    [horarios_reservas]: (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
      hasError: false,
    }),
    [isLoadingHorarioReservas]: state => ({ ...state, isLoading: true, hasError: false }),
    [hasErrorHorarioReservas]: (state, action) => ({
      ...state,
      isLoading: false,
      hasError: action.payload,
    }),
  },
  { isLoadingHorarioReservas: false, hasError: false, data: [] }
);
