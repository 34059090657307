import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import Cliente from "./cliente.component";
import OficinaReserva from "./oficina-reservaciones.component";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { useSelector, useDispatch } from "react-redux";
import Resumen from "./resumen.component";
import { agregarCita } from "redux/actions/aCitas";
import moment from "moment";
import { getHorariosReservaLaboratorista } from "redux/actions/aOficinas";
import { cleanCliente } from "redux/actions/aClientes";
// moment.locale('es');
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  components: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
  },
}));

const ReservaStep = ({ ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = ["Paciente", "Horario - Oficina", "Resumen"];

  const [selectedHorario, setSelectedHorario] = React.useState({
    fecha: new Date(),
    hora: "00:00-01:00",
    id_oficina: undefined,
    fechaSeleccionada: undefined,
  });

  const cliente = useSelector((state) => state.data_clientes.data);
  const doctor_id = useSelector((state) => state.dataUser.data.doctor_id);

  const [error, setError] = React.useState(null);
  const { hasError } = useSelector((state) => state.datos_citas);
  const handleClickCard = (objeto) => {
    setSelectedHorario(objeto);
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <Cliente
            setIdCliente={(val) => {}}
            onClick={handleNext}
          />
        );
      case 1:
        return (
          <OficinaReserva
            selectedHorario={selectedHorario}
            handleClickCard={handleClickCard}
            error={error}
          />
        );
      case 2:
        return <Resumen selectedHorario={selectedHorario} />;
      default:
        return "Unknown stepIndex";
    }
  };

  const handleNext = async () => {
    if (activeStep === 0 && cliente !== undefined && !Array.isArray(cliente)) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep === 1) {
      if (selectedHorario.id_oficina !== undefined) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setError(null);
      } else setError("Debe seleccionar la fecha");
    } else if (activeStep === 2) {
      await guardaReserva();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const guardaReserva = async () => {
    try {
      const valores = {
        inicio: `${moment(
          `${selectedHorario.fechaSeleccionada} ${
            selectedHorario.hora.split("-")[0]
          }`
        )
          .local()
          .format()}}`,
        fin: `${moment(
          `${selectedHorario.fechaSeleccionada} ${
            selectedHorario.hora.split("-")[1]
          }`
        )
          .local()
          .format()}}`,
        fecha_reserva: moment(selectedHorario.fechaSeleccionada).format(),
        disponible: "",
        razon_no_disponibilidad: "",
        canal_reserva: "WEB",
        estado_reserva: "",
        phone_number: cliente.telefono,
        especializacion_id: "",
        cliente_id: cliente.id,
        doctor_id: doctor_id,
      };

      await dispatch(agregarCita(valores, selectedHorario.id_oficina));
      if (!hasError) {
        await dispatch(getHorariosReservaLaboratorista());
        setTimeout(async () => {
          await dispatch(cleanCliente());
          setSelectedHorario({
            fecha: new Date(),
            hora: "00:00-01:00",
            id_oficina: undefined,
            fechaSeleccionada: undefined,
          });
        }, 1000);
      }
      // if (!props.hasError) {
      //   seterror({ title: 'success', mensaje: 'Datos guardados exitosamente' });
      //   handleMedicinas(valores);
      // } else {
      //   seterror({ title: 'error', mensaje: props.hasError });
      // }
    } catch (err) {
      // seterror({
      //   title: 'error',
      //   mensaje: 'Ocurrio un error, intente nuevamente' + err,
      // });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <div
              style={{
                margin: "50px auto",
                display: "flex",
                flex: "1",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {!hasError && (
                <>
                  <Typography className={classes.instructions}>
                    Se ha reservado la cita
                  </Typography>
                  <CheckCircleIcon fontSize="large" htmlColor="green" />
                </>
              )}
              {hasError && (
                <>
                  <Typography className={classes.instructions}>
                    No se ha reservado la cita
                  </Typography>
                  <HighlightOffIcon fontSize="large" htmlColor="red" />
                </>
              )}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleReset}
              >
                Nueva reserva
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <div className={classes.components}>
              {getStepContent(activeStep)}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Regresar
              </Button>
              <Button variant="contained" color="primary" onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Finalizar" : "Siguiente"}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReservaStep;
