import { Box, FormControl, InputLabel, MenuItem, Select, Typography, makeStyles } from '@material-ui/core'
import React, { useContext, useMemo, useState } from 'react'
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import moment from 'moment';
import { SucursalReservacionContext } from './medico/reserva-step.component';
import { useSelector } from 'react-redux';
import { formatHorarios } from 'utils/reservas/ReservasUtils';


const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  disponible: {
    cursor: 'pointer',
    borderRightColor: 'green',
    borderRightStyle: 'ridge',
    borderRightWidth: '5px',
  },

  noDisponible: {
    cursor: 'not-allowed',
    borderRightColor: 'red',
    borderRightStyle: 'ridge',
    borderRightWidth: '5px',
  },
  horaDisponible: {
    fontWeight: 'bold',
  },
  horaNoDisponible: {
    textDecoration: 'line-through',
  },
}));


export const ReservasSucursalHorarios = ({ oficina }) => {

  const classes = useStyles();

  const {
    selectedDate,
    selectedHorario,
    reservas,
    handleClickHorario,
    selectedDay,
  } = useContext(SucursalReservacionContext);

  console.log({ oficina });

  const serviciosAsignados = oficina.serviciosAsignados;

  let initNombreServicio = 'Consulta';
  if (selectedHorario.id_oficina === oficina.id && selectedHorario.servicio_id) {
    initNombreServicio = selectedHorario.servicio_nombre;
  }

  const [servicioSelected, setServicioSelected] = useState((selectedHorario.id_oficina === oficina.id && selectedHorario.servicio_id) ? selectedHorario.servicio_id : '0');
  const [servicioNombreSelected, setServicioNombreSelected] = useState(initNombreServicio);

  // Styles
  const seleccionado = {
    border: '2px green solid',
    boxShadow: '0 10px 4px 0 rgba(0, 0, 0, 0.14)', //Para que se alce la card y de el efecto de seleccionada
  };

  const noSeleccionado = {
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)', //Para que se baje la card y de el efecto de seleccionada
  };

  const cliente = useSelector(state => state.data_clientes.data);

  const horariosFormatted = useMemo(() => {

    return formatHorarios({
      horarios: oficina.horarios,
      reservas,
      selectedDate,
      selectedDay,
      cliente
    })

  }, [oficina, reservas, selectedDate, selectedDay, cliente])

  const fechaSeleccionadaCalendario = moment(selectedDate).format('YYYY-MM-DD');

  const handleHorarioClick = valor => {
    if (!valor.estado) {
      handleClickHorario({
        ...selectedHorario,
        fecha: selectedDate,
        hora: `${valor.hora_inicio}-${valor.hora_fin}`,
        id_oficina: oficina.id,
        fechaSeleccionada: fechaSeleccionadaCalendario,
        referencia_oficina: oficina.direccion,
        servicio_id: servicioSelected,
        servicio_nombre: servicioNombreSelected,
      });
    }
  };

  const onServicioSelectChange = (e) => {
    const newValue = e.target.value;
    const servicioFind = serviciosAsignados.filter(servicio => servicio.id === newValue);
    setServicioSelected(newValue)
    if (newValue === '0') {
      setServicioNombreSelected('Consulta')
      // setOficinaSelected({ ...selectedHorario, servicio_id: '0', servicio_nombre: 'Consulta' })
      handleClickHorario({ ...selectedHorario, servicio_id: '0', servicio_nombre: 'Consulta' });
      return;
    }
    if (servicioFind && servicioFind.length > 0) {
      setServicioNombreSelected(servicioFind[0].nombre)
      // setOficinaSelected({ ...selectedHorario, servicio_id: newValue, servicio_nombre: servicioFind[0].nombre })
      handleClickHorario({ ...selectedHorario, servicio_id: newValue, servicio_nombre: servicioFind[0].nombre });
    }
  }

  const handleSelectEspChange = (e) => {
    const id = e.target.value;
    const especialidad = especialidades.find(item => item.id === id);
    if (especialidad)
      handleClickHorario({ ...selectedHorario, especializacion_id: id, especializacion_nombre: especialidad.nombre })
  }

  const especialidades = useMemo(() => oficina.doctorEspecializaciones.map(item => item.especializacion) ?? [], [oficina]);

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box style={{ margin: '30px 50px 0px 50px' }}>
        <Typography display="inline" variant="body2">
          <span style={{ fontWeight: 'bold' }}>{oficina.nombre}</span>
        </Typography>
      </Box>

      {/* Horarios */}
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>

        {horariosFormatted.length > 0 ?
          horariosFormatted.map(horario => {
            const fechaSeleccionada = moment(selectedHorario.fecha).format('YYYY-MM-DD');
            const horaOficina = `${horario.hora_inicio}-${horario.hora_fin}`;
            const horaSeleccionada = `${selectedHorario.hora}`;

            return (
              <GridItem sm={2} xs key={horario.id}>
                <Card
                  style={
                    fechaSeleccionada === fechaSeleccionadaCalendario &&
                      horaOficina === horaSeleccionada
                      ? seleccionado
                      : noSeleccionado
                  }
                  key={`${horario.h_id}`}
                  className={horario.estado ? classes.noDisponible : classes.disponible}
                  onClick={() => handleHorarioClick({ ...horario, servicio_id: servicioSelected, servicio_nombre: servicioNombreSelected })}
                >
                  <div
                    style={{ display: 'flex', flex: 1, justifyContent: 'center' }}
                    className={classes.stats}
                  >
                    <span
                      style={{ padding: '10px 0' }}
                      className={horario.estado ? classes.horaNoDisponible : classes.horaDisponible}
                    >{`${horario.hora_inicio}/${horario.hora_fin}`}</span>
                  </div>
                </Card>
              </GridItem>
            );
          })
          :
          <Typography style={{ margin: '50px auto' }} variant="subtitle1">
            ¡No tiene horarios guardados!
          </Typography>
        }
      </div>
      {
        horariosFormatted.length > 0 &&
        <div style={{ display: 'flex', padding: '10px', paddingLeft: '20px' }} variant="body2">
          <GridItem sm={6} xs={10}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel id={`select-servicio-label_${oficina.id}`}>Servicio seleccionado</InputLabel>
              <Select
                // id={"select-servicio"}
                labelId={`select-servicio-label_${oficina.id}`}
                value={servicioSelected}
                onChange={onServicioSelectChange}
                disabled={selectedHorario.id_oficina !== oficina.id}
              >
                <MenuItem value={'0'}>Consulta</MenuItem>
                {
                  serviciosAsignados.map(servicio => (
                    <MenuItem key={servicio.id} value={servicio.id} selected={selectedHorario.servicio_id === servicio.id}>{servicio.nombre}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </GridItem>

          <GridItem sm={6} xs={10}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel id={`select-esp-label_${oficina.id}`}>Especialidad</InputLabel>
              <Select
                labelId={`select-esp-label_${oficina.id}`}
                value={selectedHorario.especializacion_id}
                onChange={handleSelectEspChange}
              >
                {
                  especialidades.map(especialidad => (
                    <MenuItem key={especialidad.id} value={especialidad.id}>{especialidad.nombre}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </GridItem>
        </div>
      }

    </div>
  )
}
