import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { encriptarRsa } from "../../utils/encriptar";
import { loginFetch, isLoadingLogin } from "../../redux/actions/aLogin";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Loading from "components/Loading";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { recuperarPassword } from "redux/actions/aRegistro";
import Notificacion from "components/ui/Notificacion";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
      },
    },
    link: {
      "&:hover": {
        color: theme.palette.primary.main,
        textDecoration: "underline",
      },
    },
  };
});

const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { data: usuario, hasError, isLoading } = useSelector((state) => state.dataUser);

  const [error, seterror] = useState(false);
  const [username, setusu] = useState("");
  const [password, setpas] = useState("");
  const [showCambiarContrasena, setShowCambiarContrasena] = useState(false);

  const handleLogin = async () => {
    try {
      // Validar campos vacios
      if (!username || !password) {
        Notificacion({
          type: "error",
          text: `Debe ingresar todos los datos del formulario`,
        });
        return
      }

      // Enviar datos de login
      const data = await encriptarRsa(JSON.stringify({ username, password }));
      dispatch(loginFetch(data, history));
    } catch (error) {
      Notificacion({
        type: "error",
        text: `Ocurrio un error, intente nuevamente`,
      });
    }
  };

  useEffect(() => {
    if (hasError) seterror(hasError);
    const { isLogin, r = 1 } = usuario;

    // Si el usuario está autenticado enviar a su página principal según su rol
    if (isLogin) {
      if (r === 1) history.push("/medico/dashboard");
      if (r === 2) history.push("/secretaria/agenda");
      if (r === 4) history.push("/cliente/dashboard");
      if (r === 6) history.push("/admin/dashboard");
      if (r === 7) history.push("/institucion/perfil");
    }
  }, [usuario, hasError, history]);

  const handleClickPassword = async () => {
    // Enviar datos para restablecer password
    try {
      if (username !== "") {
        dispatch(isLoadingLogin())
        let data = { username };
        await recuperarPassword(data).then(() => dispatch(isLoadingLogin(false)));
        setShowCambiarContrasena(false);
      } else {
        Notificacion({
          type: "error",
          text: `Para restablecer su contraseña, ingrese el Usuario / Email`,
        });
      }
    } catch (error) {
      Notificacion({
        type: "error",
        text: `Ocurrio un error, intente nuevamente ${error}`,
      });
    }
  };

  return (
    <>
      {
        !showCambiarContrasena ?
          <form className={classes.root} noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
            {/* Login Form */}
            <Loading title="Cargando..." loading={isLoading}>
              <>
                <TextField
                  id="usuario"
                  label={"Nombre de Usuario/Cédula"}
                  // defaultValue=""
                  variant="outlined"
                  style={{ width: "96%" }}
                  value={username}
                  onChange={(e) => setusu(e.target.value)}
                />

                <div>
                  <TextField
                    //error
                    id="Contrasena"
                    label="Contraseña"
                    // defaultValue=""
                    value={password}
                    variant="outlined"
                    style={{ width: "96%" }}
                    type="password"
                    onChange={(e) => setpas(e.target.value)}
                  />
                </div>

                {/* Toggle button */}
                <Typography className={classes.root}>
                  <Link
                    onClick={() => setShowCambiarContrasena(true)}
                    className={classes.link}
                    style={{ cursor: "pointer" }}
                  >
                    Olvidé mi contraseña
                  </Link>
                </Typography>

                {/* Submit button */}
                <div style={{ margin: "20px 8px" }}>
                  <Button
                    fullWidth
                    size="large"
                    variant="outlined"
                    color="primary"
                    type="submit"
                    onClick={() => handleLogin()}
                  >
                    Iniciar sesión
                  </Button>
                </div>

              </>
            </Loading>
            {error && (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {error}
              </Alert>
            )}
          </form>
          :

          <form className={classes.root} noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
            {/* Forgot password form */}
            <Loading title="Cargando..." loading={isLoading}>
              <>
                <TextField
                  id="usuario"
                  label={'Correo Electrónico'}
                  type="email"
                  variant="outlined"
                  style={{ width: "96%" }}
                  value={username}
                  onChange={(e) => setusu(e.target.value)}
                />

                {/* Toggle button */}
                <Typography className={classes.root}>
                  <Link
                    onClick={() => setShowCambiarContrasena(false)}
                    className={classes.link}
                    style={{ cursor: "pointer" }}
                  >
                    Iniciar Sesión
                  </Link>
                </Typography>

                {/* Submit button */}
                <div style={{ margin: "20px 8px" }}>
                  <Button
                    fullWidth
                    size="large"
                    variant="outlined"
                    // color="warning"
                    color="primary"
                    onClick={() => handleClickPassword()}
                  >
                    Cambiar Contraseña
                  </Button>
                </div>

              </>
            </Loading>
          </form >

      }

    </>

  );
};

export default Login;
