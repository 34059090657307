import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { FormCookiesTermsCheckboxControlled, FormInputControlled } from '../../reactHookForm/FormElementsControlled';
import { rhRequiredEmailValidation } from 'utils/validaciones';
import { rhRequiredFieldValidation } from 'utils/validaciones';
import { rhPasswordValidation } from 'utils/validaciones';
import { registroFetch } from 'redux/actions/aRegistro';
import Notificacion from 'components/ui/Notificacion';
import Loading from 'components/Loading';
import { rhConfirmPasswordValidation } from 'utils/validaciones';

export const UserRegisterForm = ({ userRole }) => {

  const { register, handleSubmit, reset, watch, formState: { errors }, control } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {

    try {
      setLoading(true);
      let dataToSend = {
        nombres: data.nombres.trim(),
        apellidos: data.apellidos.trim(),
        email: data.email.trim(),
        usuario: data.usuario.trim(),
        password: data.password.trim(),
        role: userRole,
        empresa: process.env.REACT_APP_EMPRESA,
      };

      const response = await registroFetch(dataToSend);

      setLoading(false);
      if (response.transaccion)
        Notificacion({
          type: "success",
          text: response.mensaje ?? `Cuenta creada correctamente`,
        });
      else {
        Notificacion({
          type: "error",
          text: response.mensaje ?? 'Error de conexión, intente nuevamente',
        });

        reset(dataToSend);
      }

    } catch (error) {
      setLoading(false);
      Notificacion({
        type: "error",
        text: 'Ocurrio un error, intente nuevamente',
      });
    }
  }

  return (
    <>
      <Loading loading={loading} title='Creando usuario...'>
        <form onSubmit={handleSubmit(onSubmit)}>

          {/* Nombres */}
          <FormInputControlled
            id={'nombres'}
            label={'Nombres:'}
            control={control}
            name={'nombres'}
            errors={errors}
            register={register}
            formInputType={'input'}
            rules={rhRequiredFieldValidation}
          />


          {/* Apellidos */}
          <FormInputControlled
            id={'apellidos'}
            label={'Apellidos:'}
            control={control}
            name={'apellidos'}
            errors={errors}
            register={register}
            formInputType={'input'}
            rules={rhRequiredFieldValidation}
          />

          {/* Email */}
          <FormInputControlled
            id='email'
            label='Email:'
            control={control}
            name='email'
            errors={errors}
            register={register}
            formInputType='input'
            type='email'
            rules={rhRequiredEmailValidation}
          />

          {/* Nombre de usuario */}
          <FormInputControlled
            id='usuario'
            label='Nombre de usuario:'
            control={control}
            name='usuario'
            errors={errors}
            register={register}
            formInputType='input'
            type='text'
            rules={rhRequiredFieldValidation}
          />

          {/* Password */}
          <FormInputControlled
            id='password'
            label='Contraseña:'
            control={control}
            name='password'
            errors={errors}
            register={register}
            formInputType='input'
            type='password'
            rules={rhPasswordValidation}
          />

          {/* Confirm Password */}
          <FormInputControlled
            id='confirmPassword'
            label='Confirmar contraseña:'
            control={control}
            name='confirmPassword'
            errors={errors}
            register={register}
            formInputType='input'
            type='password'
            rules={rhConfirmPasswordValidation(watch)}
          />

          <br /><br />

          <FormCookiesTermsCheckboxControlled
            control={control}
            name='terminos'
            errors={errors}
            register={register}
          />

          <button className="registrarse" type="submit">Enviar</button>

        </form>
      </Loading>
    </>
  )
}
