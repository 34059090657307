import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import { Alert, AlertTitle } from "@material-ui/lab";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import dashboardStyles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';

import Loading from "components/Loading";

//tiempo

//Validaciones

import { enviarMensajeAyuda } from "redux/actions/aAdmin";
import Notificacion from "components/ui/Notificacion";
import { FormErrorLabel } from "components/forms/labels/FormErrorLabel";

// moment.locale("es");

const styles = {
  ...dashboardStyles,
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

const AyudaSoporte = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [errors, seterrors] = useState({ mensaje: null, asunto: null });
  const [error] = useState({ title: "", mensaje: "" });
  const [loading, setloading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const initState = {
    asunto: "",
    mensaje: "",
  }
  const { asunto, mensaje } = JSON.parse(localStorage.getItem("soporteMensaje")) || initState;
  localStorage.removeItem('soporteMensaje');

  const [valores, setvalores] = useState({ asunto, mensaje });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setvalores({ ...valores, [name]: value });
  };


  const handleSubmit = async () => {
    try {
      setIsSubmit(true);
      console.log({ valores });

      if (errors.mensaje || errors.asunto) {
        // setearError({
        //   title: "error",
        //   mensaje: "Debe completar los campos correctamente",
        // });
        Notificacion({
          type: "error",
          text: "Debe completar los campos correctamente.",
        });
        return;
      }
      setloading(true);
      const res = await dispatch(enviarMensajeAyuda(valores));

      if (!res.error) {
        setIsSubmit(false);
        setvalores({
          asunto: "",
          mensaje: "",
        })
        // setearError({
        //   title: "success",
        //   mensaje: "Mensaje enviado correctamente",
        // });
        Notificacion({
          type: "success",
          text: "Mensaje enviado correctamente.",
        });
      } else {
        // setearError({ title: "error", mensaje: res.error });
        Notificacion({
          type: "error",
          text: res.error,
        });
      }
    } catch (err) {
      Notificacion({
        type: "error",
        text: "Ocurrio un error, intente nuevamente",
      });
      // setearError({
      //   title: "error",
      //   mensaje: "Ocurrio un error, intente nuevamente",
      // });
    }
    setloading(false);
  };
  // const setearError = ({ title, mensaje }) => {
  //   seterror({ title, mensaje });
  //   setTimeout(() => {
  //     seterror({ title: "", mensaje: "" });
  //   }, 3000);
  // };

  useEffect(() => {
    let errors = { mensaje: null, asunto: null };
    if (valores.mensaje.length === 0) {
      errors.mensaje = "Mensaje es obligatorio"
    }
    if (valores.asunto.length === 0) {
      errors.asunto = "Asunto es obligatorio"
    }
    seterrors(errors);
  }, [valores]);

  return (
    <Loading title="Cargando..." loading={loading}>
      <>
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitle}>Ayuda y soporte</h4>
              </CardHeader>
              {error.mensaje && (
                <Alert style={{ margin: 20 }} severity={`${[error.title]}`}>
                  <AlertTitle>
                    {(error.title === "error" && isSubmit) ? "Error" : "Success"}
                  </AlertTitle>
                  {error.mensaje}
                </Alert>
              )}
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Asunto"
                      id="asunto"
                      inputProps={{
                        onChange: handleChange,
                        name: "asunto",
                        value: valores.asunto,
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      error={(isSubmit && errors.asunto && true)}
                      success={isSubmit && !errors.asunto && true}
                    />
                    {(errors.asunto && isSubmit) && (
                      <FormErrorLabel>{errors.asunto}</FormErrorLabel>
                    )}
                  </GridItem>
                </GridContainer>
                <GridContainer style={{ marginTop: 10 }}>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Mensaje"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      id="mensaje"
                      inputProps={{
                        onChange: handleChange,
                        name: "mensaje",
                        multiline: true,
                        rows: 5,

                        value: valores.mensaje,
                      }}
                      error={isSubmit && errors.mensaje && true}
                      success={isSubmit && !errors.mensaje && true}
                    />
                    {(errors.mensaje && isSubmit) && (
                      <FormErrorLabel>{errors.mensaje}</FormErrorLabel>
                    )}
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" onClick={() => handleSubmit()}>
                  Enviar Mensaje
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    </Loading>
  );
};
export default AyudaSoporte;
