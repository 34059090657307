import React from 'react'
import { TableContainer as Container, makeStyles } from '@material-ui/core';
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

const useStyles = makeStyles(styles);

export const TableContainer = (props) => {
  const { children } = props;
  const classes = useStyles();

  return (
    <Container className={classes.tableContainer} {...props}>
      {children}
    </Container>
  )
}
