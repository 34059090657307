import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import '../../styles/notifications/notifications.scss';

const Notificacion = ({ type = 'info', text = '', title = '', click }) => {
  switch (type) {
    case 'info':
      NotificationManager.info(text, title, 7000);
      break;
    case 'infoFixed':
      // 10 minutos duración
      NotificationManager.info(text, title, 600000);
      break;
    case 'success':
      NotificationManager.success(text, title, 9000);
      break;
    case 'warning':
      NotificationManager.warning(text, title, 3000);
      break;
    case 'error':
      NotificationManager.error(text, title, 9000, () => {
        if (click) click();
      });
      break;
    default:
      NotificationManager.info(text);
  }
};

export default Notificacion;