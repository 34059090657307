
import React from 'react'
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => (
  {
    container: {
      maxWidth: '80%',
      margin: 'auto',
      textAlign: 'justify',
    }
  }
));

export const Banner = ({ children, title }) => {

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <header className="n-banner banner">
        <div className="n-banner-empty"></div>
        <div className="banner-tex n-banner-tex container-small" style={{ padding: '0px', paddingTop: '140px', textAlign: 'center' }}>
          <h1>{title}</h1>
        </div>
      </header>
      <section>
        <div className="n-testimonial container-large">
          <div className="titlesh1" style={{ marginRight: "10px", marginLeft: "10px" }}>
            {children}
          </div>
        </div>
      </section>
    </div>

  )
}
