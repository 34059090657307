import React from 'react'
import formStyles from "assets/jss/material-dashboard-react/forms/formStyle"
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(formStyles);

export const FormErrorLabel = ({ children }) => {

  const classes = useStyles();

  return (
    <p className={classes.errorValidationLabel}>
      {children}
    </p>
  )
}
