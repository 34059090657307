import React, { useEffect, useState, useMemo } from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useSelector } from "react-redux";
import GridContainer from "components/Grid/GridContainer.js";
import FormControl from "@material-ui/core/FormControl";
import GridItem from "components/Grid/GridItem.js";
import Button from "../../CustomButtons/Button";
import { Checkbox, FormControlLabel, FormGroup, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, ListSubheader, Radio, RadioGroup } from "@material-ui/core";
import { primaryColor } from "assets/jss/material-dashboard-react.js";
import Notificacion from "components/ui/Notificacion";
import { AutocompleteComponent } from "components/ui/AutocompleteComponent";
import { getCie10PorBusqueda } from "redux/actions/aRecetas";
import DeleteIcon from '@material-ui/icons/Delete';
import RecetaVademecumForm from "./receta/RecetaVademecumForm";
import { formatCie10 } from "utils/format";


const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginBottom: "25px",
  },
  formControl2: {
    width: "100%",
    marginBottom: "10px",
  },
  radio: {
    marginLeft: '5px'
  },
}));

const RadioButton = withStyles({
  root: {
    '&$checked': {
      color: primaryColor[0],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export const RecetarioForm = ({
  onSubmit,
  handleBack,
  receta,
  handleReuseLastReceta,
  lastReceta,
  resetConsultaDate,
  setRecetaDirty,
  recetaDirty,
  setCie10SelectedGlobalList,
  // Receta form
  cie10Data,
  setCie10Data,
  vademecumData,
  setVademecumData,
  errorVademecum,
  includesReceta,
  setIncludesReceta,
  recetaFormErrors,
  recetaFormValues,
  setRecetaFormValues,
}) => {

  const classes = useStyles();

  //Receta area
  const { dataCie10 } = useSelector(state => state.data_recetas);

  const [cie10, setCie10] = useState(null);

  const handleBuscado = (event, values, esNuevo) => {
    if (values !== null && values.cod_4) {
      setRecetaFormValues({ ...recetaFormValues, cie: `${values.cod_4} ${values.descripcion_codigos_cuatro_caracteres}` });
      setCie10(`${values.cod_4} ${values.descripcion_codigos_cuatro_caracteres}`);
    } else {
      setRecetaFormValues({ ...recetaFormValues, cie: '' });
    }
  };

  const handleChangeRecetaFields = (e) => {
    const { name, value } = e.target;
    setRecetaFormValues({ ...recetaFormValues, [name]: value });
  };

  const handleContinue = () => {

    if (cie10Data.length === 0) {
      Notificacion({
        type: "error",
        text: "Debe seleccionar al menos un diagnóstico",
      });
      return
    }

    setShowFormRecetaDetalle(true);

  }

  //Verificar si un cie10 existe en la lista de cie10 seleccionados
  //return true si cie10 existe en la lista, caso contrario retorna false
  const cie10IncludesInSelectedList = (cie10) => {
    try {
      const findElement = cie10Data.filter(el => cie10 === el);
      return (findElement.length > 0) ? true : false;
    } catch (error) {
      // console.log('Error al buscar elemento cie10 en lista cie10 seleccionados', error);
    }
  }

  //Agregar un cie10 a la lista de cie10 seleccionados
  const handleAddCie10 = () => {
    if (!cie10)
      return Notificacion({
        type: "warning",
        text: "Debe seleccionar un cie10",
      });
    if (cie10 && !cie10IncludesInSelectedList(cie10)) {
      const presuntivoDefinitivo = recetaFormValues.presuntivoDefinitivo ?? '';
      setCie10Data([...cie10Data, cie10 + '|' + presuntivoDefinitivo]);
      setCie10SelectedGlobalList([...cie10Data, cie10 + '|' + presuntivoDefinitivo]);
      if (cie10Data.length === 0) {
        setSelectedCie10Index(0);
      }
      if (vademecumData.length > 0) setShowFormRecetaDetalle(true);
      setRecetaFormValues({ ...recetaFormValues, cie: '' });
      setCie10('');
      setRecetaDirty(true);
      resetConsultaDate();
    } else {
      Notificacion({
        type: "warning",
        text: "El cie10 ya se encuentra seleccionado",
      });
    }
  }

  //Seleccionar cie10 de la lista de cie10 seleccionados
  const handleCie10ListItemClick = (index) => {
    setSelectedCie10Index(index);
  };

  //Eliminar cie10 de lista de cie10 seleccionados
  const handleDeleteCie10 = (cie10Object, index) => {
    // return;
    //Eliminar elemento cie
    const newCieList = cie10Data.filter(cie10 => cie10 !== cie10Object);
    setCie10Data(newCieList);
    setCie10SelectedGlobalList(newCieList);

    resetConsultaDate();
    setRecetaDirty(true);
    //Establecer cie10 seleccionado en null (por defecto)
    if (newCieList.length === 0) {
      setShowFormRecetaDetalle(false)
      setSelectedCie10Index(null);
      // setIncludesReceta(true);
    }
  }

  let initStateCie10SelectedList = useMemo(() => {
    if (receta) {
      return receta.detalleCie10.map(({ cie10, recetaDetalleCie10 }) => {
        return cie10
      });
    }
    return [];
  }, [receta]);

  let initStateMedicinas = useMemo(() => {
    if (receta) {
      let array = [];
      receta.detalle.map(({ vademecum_id, medicamento, cantidad, dosificacion, composicion, casa_comercial, presentacion, dias_uso, frecuencia_uso_horas }) => {
        array.push(
          {
            cantidad,
            dosificacion,
            fecha_inicio_uso: new Date(),
            dias_uso,
            frecuencia_uso_horas,
            medicina: {
              casa_comercial,
              composicion,
              dosificacion,
              id: vademecum_id,
              nombre: medicamento,
              presentacion,
            }
          }
        )
        return null;
      })
      return array;
    }
    return [];
  }, [receta]);

  //Receta area
  const [selectedCie10Index, setSelectedCie10Index] = useState(null);

  //Receta detalle
  const [showFormRecetaDetalle, setShowFormRecetaDetalle] = useState(false);


  const handleDeleteMedicina = (index) => {
    try {
      // //Medicinas del cie10 seleccionado
      const newArrayMedicinas = vademecumData.filter((_, i) => i !== index);
      setVademecumData(newArrayMedicinas);
      setRecetaDirty(true);
      resetConsultaDate();
    } catch (error) {
      // console.log("Error al tratar de eliminar vademecum de cie10", error);
    }

  }

  useEffect(() => {
    if ((receta && !recetaDirty) || (!receta?.detalle && !recetaDirty)) {
      setVademecumData(initStateMedicinas);
    }

  }, [initStateMedicinas, receta, recetaDirty, setVademecumData])

  useEffect(() => {
    if ((receta && !recetaDirty) || (!receta && !recetaDirty)) {
      setCie10Data(initStateCie10SelectedList);
      setCie10SelectedGlobalList(initStateCie10SelectedList);
    }

  }, [initStateCie10SelectedList, receta, recetaDirty, setCie10SelectedGlobalList, setCie10Data])

  useEffect(() => {
    if (!receta) return;
    setRecetaFormValues({
      cie: "",
      presuntivoDefinitivo: null,
      indicaciones: receta.indicaciones
    })
    if (receta.detalle && receta.detalle.length === 0) {
      setIncludesReceta(false);
      setShowFormRecetaDetalle(false);


    } else {
      setIncludesReceta(true);
      setShowFormRecetaDetalle(true);
    }
  }, [receta, setIncludesReceta, setRecetaFormValues])

  const handleSubmit = () => {
    onSubmit(true);
  }

  const handleReuse = () => {
    let array = [];
    lastReceta.detalle.map(({ vademecum_id, medicamento, cantidad, dosificacion, composicion, casa_comercial, presentacion, dias_uso, frecuencia_uso_horas }) => {
      array.push(
        {
          cantidad,
          dosificacion,
          fecha_inicio_uso: new Date(),
          dias_uso,
          frecuencia_uso_horas,
          medicina: {
            casa_comercial,
            composicion,
            dosificacion,
            id: vademecum_id,
            nombre: medicamento,
            presentacion,
          }
        }
      )
      return null;
    })
    setVademecumData(array);
    handleReuseLastReceta();
  }

  return (
    <div style={{ width: '100%' }}>

      <div style={{ marginBottom: '10px' }}>
        <p style={{ margin: '2px' }}>PR = Presuntivo | DF = Definitivo</p>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={4}>
          <FormControl className={classes.formControl2} >
            <RadioGroup
              name="presuntivoDefinitivo"
              value={recetaFormValues.presuntivoDefinitivo}
              onChange={handleChangeRecetaFields}
              defaultValue={null}
              row
            >
              <FormControlLabel
                value={'PR'}
                control={<RadioButton />}
                color="primary"
                label={'PR'}
                className={classes.radio}
              // checked={false} 
              />
              <FormControlLabel
                value={'DF'}
                control={<RadioButton />}
                label={'DF'}
                className={classes.radio}
              // checked={opt.checked}
              />
            </RadioGroup>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={4}>
          <AutocompleteComponent
            label="cie10"
            id="cie"
            name="cie"
            type="text"
            freeSolo={true}
            fullWidth
            poblarData={[]}
            onBuscadoChange={(event, values) => handleBuscado(event, values, true)}
            optionLabel={option => option && option.cod_4
              ? `${option.cod_4} ${option.descripcion_codigos_cuatro_caracteres} `
              : ''
            }
            dispatchFunction={getCie10PorBusqueda}
            dataSearch={dataCie10}
            value={
              (recetaFormValues.cie) ? { cod_4: recetaFormValues.cie, descripcion_codigos_cuatro_caracteres: '' } : null
            }
          />
          <GridItem xs={4} />
        </GridItem>

        <GridItem xs={12} sm={2}>
          <GridContainer justifyContent="center" direction="row">
            <GridItem xs={"auto"}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddCie10}
              >
                Agregar
              </Button>
            </GridItem>

          </GridContainer>
        </GridItem>
        {/* Lista de CIE10 Seleccionados */}
        <GridItem xs={12}>
          <List component="nav" aria-label="main mailbox folders" subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              CIE10 Seleccionados
            </ListSubheader>
          }>
            {
              cie10Data.map((cie10, index) => (
                <ListItem
                  key={index}
                  button
                  selected={selectedCie10Index === index}
                  onClick={() => handleCie10ListItemClick(index)}
                >
                  <ListItemAvatar>
                    <div style={{ fontWeight: 'bold' }}>
                      {formatCie10(cie10, true)}
                    </div>
                  </ListItemAvatar>
                  <ListItemText primary={formatCie10(cie10)} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteCie10(cie10, index)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>

              ))
            }
          </List>
        </GridItem>
      </GridContainer>

      <div style={{ marginTop: '10px' }}>
        <GridContainer justifyContent="center" direction="row" >
          <GridItem xs={"auto"}>
            {
              !showFormRecetaDetalle &&
              <Button
                variant="contained"
                color="primary"
                onClick={handleContinue}
              >
                Continuar
              </Button>
            }
          </GridItem>
        </GridContainer>
      </div>

      {/* Option includes receta */}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {
          lastReceta && (showFormRecetaDetalle) && includesReceta && (
            <div style={{ marginRight: '5px' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleReuse}
              >
                Reutilizar última receta sugerida
              </Button>
            </div>
          )
        }

        {
          (showFormRecetaDetalle || (receta)) && (cie10Data.length > 0) && (
            <div>
              <FormGroup>
                <FormControlLabel control={<Checkbox color="primary" checked={!includesReceta} onChange={(e) => setIncludesReceta(!e.target.checked)} />} label="Sin receta" />
              </FormGroup>
            </div>
          )
        }

      </div>
      {
        !includesReceta && (cie10Data.length > 0) && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button color="warning" onClick={handleSubmit}>
              Guardar
            </Button>
          </div>
        )
      }
      {/* Receta Detalle */}
      <div>
        {(showFormRecetaDetalle || (receta && receta.detalle && receta.detalle.length > 0)) && (cie10Data.length > 0) && includesReceta && (
          <RecetaVademecumForm
            medicinas={vademecumData}
            setMedicinas={setVademecumData}
            handleConfirmSave={handleSubmit}
            data={receta} handleBack={handleBack}
            handleDeleteMedicina={handleDeleteMedicina}
            handleChangeRecetaFields={handleChangeRecetaFields}
            recetaFieldsValues={recetaFormValues}
            recetaFieldsErrors={recetaFormErrors}
            errorRecetario={recetaFormErrors}
            errorVademecumForm={errorVademecum}
            setRecetaDirty={setRecetaDirty}
            resetConsultaDate={resetConsultaDate}
          />
        )}
      </div>

    </div>
  );
};

export default RecetarioForm;
