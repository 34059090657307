import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//Socket
import socketIOClient from "socket.io-client";
import { urlSocket } from "constants/urls";

//Moment
import moment from "moment";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes/medico.routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

// import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/images/medpal_lineal_white.svg";
import { loadNotificaciones } from "redux/actions/aNotificaciones";
import Notificacion from "components/ui/Notificacion";
import { cerrarsesion } from "redux/actions/aLogin";
import { loadProfile } from "redux/actions/aLogin";
import { useCallback } from "react";
import Topbar from "components/Navbars/Topbar";
// import { getEspecialidades } from "redux/actions/aEspecialidades";

let ps;

const useStyles = makeStyles(styles);

const Medico = ({ ...rest }) => {

  // Top menu
  const [showTopMenu, setShowTopMenu] = useState(false);

  const { cuenta_activa } = useSelector((state) => state.dataUser.data);
  const condicionCuentaActiva = useCallback(
    (prop) => {
      return cuenta_activa ||
        (
          prop.path !== "/vadecum" &&
          prop.path !== "/recetario" &&
          prop.path !== "/promociones" &&
          prop.path !== "/reservaciones" &&
          prop.path !== "/pacientes-lista" &&
          prop.path !== "/historia-clinica"
          // prop.path !== "/promociones" 
        );
    },
    [cuenta_activa],
  );

  const localRoutes = routes.map(
    prop => !(prop.layout === "/medico" && (cuenta_activa || (condicionCuentaActiva(prop)))) ? { ...prop, hidden: true } : prop
  );
  // const [localRoutes] = useState(initRoutes);
  // console.log({cuenta_activa})

  const switchRoutes = (
    <Switch>
      {localRoutes.map((prop, key) => {
        if (!(prop.layout === "/medico" && (cuenta_activa || (condicionCuentaActiva(prop))))) {
          return null;
        }
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      })}
      <Route
        path={"/medico"}
        component={() => {
          return <Redirect to={{ pathname: "/medico/dashboard" }} />;
        }}
      />
    </Switch>
  );
  const user = useSelector((state) => state.dataUser.data);

  if (!user || !user.isLogin) {
    rest.history.replace("/");
  }

  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  // const [image, setImage] = React.useState(bgImage);
  // const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  // const handleImageClick = (image) => {
  //   setImage(image);
  // };
  // const handleColorClick = (color) => {
  //   setColor(color);
  // };
  // const handleFixedClick = () => {
  //   if (fixedClasses === "dropdown") {
  //     setFixedClasses("dropdown show");
  //   } else {
  //     setFixedClasses("dropdown");
  //   }
  // };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/medico/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      // document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  //Conectar socket client
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();

  useEffect(() => {
    if (token === "") {
      dispatch(cerrarsesion());
      rest.history.replace({ pathname: `/` });
    }
  }, [token, dispatch, rest.history]);

  useEffect(() => {
    if (user || user.isLogin) {
      //Cargar notificaciones
      const storageNotificaciones = localStorage.getItem("notifications");
      if (storageNotificaciones) {
        let notificacionesData = JSON.parse(storageNotificaciones);

        //
        //Actualizar notificaciones por fecha
        localStorage.setItem(
          "notifications",
          JSON.stringify(
            notificacionesData.filter(
              (n) =>
                n.r_inicio &&
                moment(n.r_inicio).isAfter(moment().startOf("day"), "hour")
            )
          )
        );
      }

      const socket = socketIOClient(urlSocket, {
        transport: ["websocket"],
        reconnection: false,
      });

      socket.once("connect", () => {
        socket.emit("authenticate", { token });
        socket.on("authenticated", (data) => {
          socket.on("welcome_event", (data) => { });

          //Recibir notificaciones de citas
          socket.on("notificacionesCitas", (data) => {
            const savedNot = localStorage.getItem("notifications");
            if (savedNot) {
              let notificacionesData = JSON.parse(savedNot);
              localStorage.setItem(
                "notifications",
                JSON.stringify([...notificacionesData, data])
              );
            } else {
              const arrNotificaciones = [];
              arrNotificaciones.push(data);
              localStorage.setItem(
                "notifications",
                JSON.stringify(arrNotificaciones)
              );
            }
            Notificacion({
              title: "Cita pendiente",
              text: `Tienes una cita médica el día ${moment(
                data.r_fecha_reserva
              ).format("DD-MMMM-YYYY")} de ${moment(data.r_inicio).format(
                "HH:mm"
              )} a ${moment(data.r_fin).format("HH:mm")}`,
            });
            dispatch(loadNotificaciones());
          });
        });
        socket.on("unauthorized", (msg) => { });
      });

      socket.on("connect_error", () => {
        // setTimeout(() => {
        //   socket.connect();
        // }, 2000);
      });

      socket.on("disconnect", () => {
        setTimeout(() => {
          socket.connect();
        }, 500);
      });
    }
  }, [dispatch, token, user]);

  useEffect(() => {
    dispatch(loadProfile());
  }, [dispatch]);

  useEffect(() => {
    // Mostrar mensaje de cuenta no aprobada
    if (!cuenta_activa)
      Notificacion({
        type: "infoFixed",
        text: 'Su cuenta está siendo verificada, la respuesta será enviada a su correo electrónico',
      });
  }, [cuenta_activa])


  return (
    <div className={classes.wrapper}>
      {
        !showTopMenu &&
        <Sidebar
          routes={localRoutes}
          logo={logo}
          image={null}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          setShowTopMenu={setShowTopMenu}
          // color={'red'}
          {...rest}
        />
      }

      <div className={showTopMenu ? classes.mainPanel2 : classes.mainPanel} ref={mainPanel} id='doctorMainPanel' style={{
        width: '100% !important'
      }}>

        <Topbar
          routes={localRoutes}
          logo={logo}
          image={null}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          showTopMenu={showTopMenu}
          setShowTopMenu={setShowTopMenu}
          // color={'red'}
          {...rest} />

        <div style={{
          marginTop: '120px !important'
        }}>
          <Navbar
            customClasses={showTopMenu ? classes.marginBarTop : ''}
            routes={localRoutes}
            handleDrawerToggle={handleDrawerToggle}
            {...rest}
          />
        </div>

        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            {/* <NotificationContainer /> */}
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
        {getRoute() ? <Footer /> : null}

        {/* <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          bgColor={color}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
        /> */}
      </div>
    </div>
  );
};


export default Medico;
