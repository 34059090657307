import React, { useEffect, useState, useCallback } from 'react'
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize } from 'lodash';
import { TableHead, TableSortLabel } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from '@material-ui/core/TablePagination';
import AddIcon from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';

import { loadCiudadesEspecializacionAfiliacion } from 'redux/actions/aAdmin';
import { AdminLayout } from './AdminLayout';
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import CustomTableCellCrud from "components/Admin/CRUD/CustomTableCellCrud";
import ConfirmDialog from 'components/Admin/CRUD/ConfirmDialog';
import Button from "components/CustomButtons/Button";
import { AutocompleteComponent } from 'components/ui/AutocompleteComponent';
import Loading from 'components/Loading';
import { FormCrud } from 'components/Admin/CRUD/FormCrud';
import { TableRowCrud } from 'components/Admin/CRUD/TableRowCrud';
import Notificacion from 'components/ui/Notificacion';
import {
  crearCiudadEspecializacionAfiliacion,
  editarCiudadEspecializacionAfiliacion,
  eliminarCiudadEspecializacionAfiliacion,
  startBusquedaCiudades
} from 'redux/actions/aAdmin';
import { SearchTextField } from 'components/forms/input/SearchTextField';
import { TableContainer } from 'components/Table/TableContainer';


let useStyles = makeStyles(styles);

const TableCellHeader = withStyles((theme) => {
  return {
    head: {
      color: theme.palette.common.white,
      fontWeight: "bolder",
    },
  };
})(TableCell);

const rowsPerPageDefault = 20;

export const CiudadesAfiliacionCrud = () => {

  const dispatch = useDispatch();
  const classes = useStyles();

  //Autocomplete
  const [ciudadSelected, setCiudadSelected] = useState(null);
  const [ciudadesBusqueda, setCiudadesBusqueda] = useState([]);
  const [searchCiudadLoading, setSearchCiudadLoading] = useState(false);

  //CRUD
  const [view, setView] = useState(0);
  const [itemSelected, setItemSelected] = useState({});

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  //Ordenamiento
  const [orderData, setOrderData] = useState({
    sortBy: 'c.nombre', // default sort column
    sortOrder: "asc" // default sort oder
  })

  const { sortBy, sortOrder } = orderData;

  const { dataCRUD, dataPagination, dataForeign } = useSelector(state => state.data_admin);
  const { especializaciones } = dataForeign;

  const { total } = dataPagination;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [skipLocal, setSkipLocal] = React.useState(0);

  const [especializacionSelected, setEspecializacionSelected] = useState(null);

  // Autocomplete
  const handleSelectOption = (_, value, __) => {
    if (value !== null) setCiudadSelected(value)
  };

  const startSearchCiudades = useCallback(
    async (value) => {
      try {
        setSearchCiudadLoading(true);
        const { data, transaccion } = await dispatch(startBusquedaCiudades(value));
        if (transaccion)
          setCiudadesBusqueda(data);
        setSearchCiudadLoading(false);
      } catch (error) {
        console.log({ error })
        setSearchCiudadLoading(false);
      }
    },
    [dispatch],
  )

  //CRUD
  const handleInputSearchChange = ({ target }) => {
    setSearch(target.value);
    debouncedSearch(target.value);
  }

  const debouncedSearch = React.useRef(
    _.debounce(async (searchValue) => {
      setSearchValue(searchValue);
    }, 350)
  ).current;

  const handleSelectChange = useCallback(
    (name, newValue) => {
      setEspecializacionSelected(newValue);
    },
    [],
  )

  const initialStateFormComponents =
    [
      {
        type: 'input',
        name: "doctores_afiliados_limite",
        label: "Límite de afiliaciones",
        value: itemSelected.doctores_afiliados_limite ?? "",
        validationOptions: {
          pattern: {
            value: /^\d+$/,
            message: "El campo tiempoPorClienteMinutos debe ser de tipo entero"
          }
        },
      },
      {
        disabled: view === 2 ? true : false,
        type: "select",
        name: "especializacion_id",
        label: "Especialización",
        value: especializacionSelected,
        optionsLabel: (option) => (option ? `${option.nombre}` : null),
        renderOption: (option) => (
          <span>
            {`${option.nombre}`}
          </span>
        ),
        options: especializaciones,
        handleSelect: (value) => handleSelectChange("especializacion_id", value),
      },
    ];

  const handleChangePage = useCallback(
    (_, newPage) => {
      if (page > newPage) {
        setSkipLocal(skipLocal - rowsPerPage);
      } else {
        setSkipLocal(skipLocal + rowsPerPage);
      }
      setPage(newPage);
    },
    [skipLocal, page, rowsPerPage],
  )

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
      setSkipLocal(0);
    },
    [],
  )

  const handleClickCreate = useCallback(
    () => {
      setEspecializacionSelected(null);
      setItemSelected({});
      setView(1);
    },
    [],
  )

  const handleClickEdit = useCallback(
    (item) => {
      setItemSelected(item);
      setEspecializacionSelected(item.especializacion)
      setView(2);
    },
    [],
  )

  const handleClickDelete = useCallback(
    item => {
      setItemSelected(item);
      setOpenConfirmDialog(true);
    },
    [],
  )

  const handleClickReturn = useCallback(
    () => {
      setItemSelected({});
      setView(0);
      setEspecializacionSelected(null);
    },
    [],
  )

  const onSubmitForm = useCallback(
    async (data, type) => {
      if (!especializacionSelected) {
        Notificacion({
          type: "error",
          text: 'Debe seleccionar una especialización',
        });
        return;
      }
      let dataForm = { ...data, especializacion_id: especializacionSelected.id };

      if (type === "editar") {
        (!loading) && setLoading(true);
        let respuesta = await dispatch(editarCiudadEspecializacionAfiliacion(ciudadSelected.id, dataForm));
        setLoading(false);
        const { transaccion, mensaje } = respuesta;
        !transaccion ?
          Notificacion({
            type: "error",
            text: mensaje,
          }) : setError(false);
      } else if (type === "crear") {
        (!loading) && setLoading(true);
        let respuesta = await dispatch(crearCiudadEspecializacionAfiliacion(ciudadSelected.id, dataForm));
        setLoading(false);
        const { transaccion, mensaje } = respuesta;
        transaccion && setItemSelected({});
        !transaccion ?
          Notificacion({
            type: "error",
            text: mensaje,
          }) : setError(false);
      }
    },
    [loading, especializacionSelected, ciudadSelected, dispatch],
  )

  const handleConfirmDelete = useCallback(
    async () => {
      (!loading) && setLoading(true);
      let respuesta = await dispatch(eliminarCiudadEspecializacionAfiliacion(ciudadSelected.id, itemSelected.especializacion_id));
      setLoading(false);
      const { transaccion, mensaje } = respuesta;
      !transaccion ? setError(mensaje) : setError(false);
    },
    [loading, itemSelected, ciudadSelected, dispatch],
  )

  const handleRequestSort = useCallback(
    async (sortByColumn) => {
      let sortOrderNew;
      if (!sortBy || sortByColumn !== sortBy) {
        sortOrderNew = "asc";
        setOrderData({
          sortBy: sortByColumn, // default sort column
          sortOrder: sortOrderNew // default sort oder
        })
      } else {
        if (sortByColumn === sortBy) {
          sortOrderNew = sortOrder === "asc" ? "desc" : "asc"
          setOrderData({
            sortBy: sortByColumn, // default sort column
            sortOrder: sortOrderNew// default sort oder
          })
        }
      }
    },
    [sortBy, sortOrder],
  );

  const titulosTable = [
    { title: "Nombre", onClickSort: () => handleRequestSort("c.nombre"), columnName: "nombre" },
    { title: "límite de afiliaciones", onClickSort: () => handleRequestSort("e.doctores_afiliados_limite"), columnName: "doctores_afiliados_limite" },
    { title: "Total afiliaciones", onClickSort: () => handleRequestSort("e.numero_afiliaciones"), columnName: "numero_afiliaciones" },
    { title: "Opciones" },
  ];

  const initLoadData = useCallback(
    async ({ cityId, skip = 0, take = rowsPerPageDefault, search = '', sortBy = 'c.nombre', sort = 'ASC' } = {}) => {
      setLoading(true);
      let respuesta = await dispatch(loadCiudadesEspecializacionAfiliacion(cityId, skip, take, search, sortBy, sort));
      setLoading(false);
      const { transaccion, mensaje } = respuesta;

      !transaccion ? setError(mensaje) : setError(false);
    },
    [dispatch],
  )

  useEffect(() => {
    if (ciudadSelected)
      initLoadData({ cityId: ciudadSelected.id, skip: skipLocal, take: rowsPerPage, search: searchValue, sortBy, sort: sortOrder });
  }, [ciudadSelected, rowsPerPage, skipLocal, initLoadData, sortOrder, sortBy, searchValue]);

  return (
    <AdminLayout viewState={view}>
      {/* Autocomplete ciudades */}
      <div style={{ marginBottom: '20px' }}>
        <AutocompleteComponent
          variant="outlined"
          label="Buscar ciudad"
          id="ciudad"
          name="ciudad"
          type="text"
          freeSolo={true}
          fullWidth
          poblarData={[]}
          onBuscadoChange={(event, values) => handleSelectOption(event, values, true)}
          optionLabel={option => option
            ? `${capitalize(option.nombre)}`
            : ''
          }
          dispatchFunction={startSearchCiudades}
          reduxFunction={false}
          dataSearch={ciudadesBusqueda}
          loading={searchCiudadLoading}
          value={
            ciudadSelected
          }
          InputProps={{
            style: {
              borderRadius: "50px",
              background: 'white'
            }
          }}
     
        />
      </div>

      {/* CRUD */}
      {
        ciudadSelected &&
        <div>
          <ConfirmDialog title={"Confirmación de eliminación"} open={openConfirmDialog}
            setOpen={setOpenConfirmDialog} onConfirm={handleConfirmDelete}  >
            <div>¿Esta seguro de eliminar este registro?</div>
          </ConfirmDialog>
          {
            view === 0 ?

              <div>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonMargin}
                    onClick={handleClickCreate}
                    startIcon={<AddIcon />}
                  >
                    Nuevo
                  </Button>
                </div>


                {/* Botón buscar */}
                <SearchTextField
                  value={search}
                  onChange={handleInputSearchChange}
                  style={{ marginTop: '25px', marginBottom: '25px' }}
                />

                <Loading title="Cargando..." loading={loading}>

                  <TableContainer component={Paper}>

                    <Table className={classes.table}>

                      <TableHead className={classes[`warningTableHeader`]}>

                        <TableRow className={classes.tableHeadRow}>
                          {titulosTable &&
                            titulosTable.map((item, index) => (
                              item.onClickSort ?
                                <TableCellHeader key={index}>
                                  <TableSortLabel
                                    active={sortBy === item.columnName}
                                    direction={sortOrder}
                                    onClick={item.onClickSort}
                                    hideSortIcon={sortBy !== item.columnName}
                                    className={classes.tableCell + " " + classes.tableHeadCell}
                                  >
                                    {item.title}
                                  </TableSortLabel>
                                </TableCellHeader>
                                :
                                <TableCellHeader
                                  key={index}
                                  className={classes.tableCell + " " + classes.tableHeadCell}
                                >
                                  {item.title}
                                </TableCellHeader>
                            ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          dataCRUD &&
                          dataCRUD.map((item) => (
                            <TableRowCrud key={`${item.especializacion.id}${item.ciudad.id}`} keyValue={item.id} setView={setView} item={item}
                              handleEdit={handleClickEdit} handleDelete={handleClickDelete}>
                              <CustomTableCellCrud
                                data={item.especializacion.nombre}
                              />
                              <CustomTableCellCrud
                                data={item.doctores_afiliados_limite ?? 'Sin límite establecido'}
                              />
                              <CustomTableCellCrud
                                data={item.numero_afiliaciones}
                              />
                            </TableRowCrud>
                          ))
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Loading>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 100]}
                  component="div"
                  count={total ? +total : 10}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />

              </div>
              :
              (
                <FormCrud setView={setView} edit={view === 2 ? true : false}
                  formComponents={initialStateFormComponents}
                  onSubmitForm={onSubmitForm} handleReturn={handleClickReturn} isLoading={loading}
                  hasError={error}
                />
              )
          }

        </div>
      }

    </AdminLayout>
  )
}
