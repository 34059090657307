import React, { useState } from "react";

import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "../CustomButtons/Button";

import moment from "moment";
// moment.locale("es");
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
}));

const lunes_viernes = [
  { id: "LUNES" },
  { id: "MARTES" },
  { id: "MIÉRCOLES" },
  { id: "JUEVES" },
  { id: "VIERNES" },
];
const dias_semana = [
  { id: "LUNES A VIERNES" },
  ...lunes_viernes,
  { id: "SÁBADO" },
  { id: "DOMINGO" },
];

const HorarioForm1 = ({
  tiempoConsulta,
  oficina_id,
  handleSetHoras,
  todosHorariosOcupados,
  diasConHorario, cerrar
}) => {
  const [tiempoentre, settiempoentre] = useState(15);
  const [jornadaIni, setjornadaIni] = useState("09:00");
  const [jornadaFin, setjornadaFin] = useState("17:00");
  const [dia_semana, setdia] = useState("");
  const [hasError, sethasError] = useState("");
  const [espacios_disponibles, setEspacios_disponibles] = useState(1);

  // const [arreglo_hora, setArregloHora] = useState([]);

  const classes = useStyles();

  React.useEffect(() => { }, []);
  const handleError = (val) => {
    sethasError(val);
    setTimeout(() => {
      sethasError("");
    }, 3000);
  };
  const handleClick = async () => {
    if (!dia_semana || !tiempoentre || !jornadaIni || !jornadaFin) {
      handleError("Ingrese todos los datos");
      return;
    }

    // console.log('horasAnteriores', todosHorariosOcupados, diasConHorario);

    let miarray = [];

    let horarioDia = [];
    // let i = 0;
    let dias = [{ id: dia_semana }];
    if (dia_semana === `LUNES A VIERNES`) dias = lunes_viernes;
    dias.forEach(({ id }) => {

      horarioDia = todosHorariosOcupados.filter(horario_dia => horario_dia.dia_semana.toUpperCase() === id);

      let mi = moment().utcOffset(0);
      let mf = moment().utcOffset(0);
      const ini = jornadaIni.split(":");
      const fin = jornadaFin.split(":");

      mi.set({ hour: ini[0], minute: ini[1] });
      mf.set({ hour: fin[0], minute: fin[1] });

      if (mi >= mf) {
        handleError("La hora de finalización debe ser mayor");
      }


      while (mf.isAfter(mi) && (mf.toString() !== mi.toString())) { //mi < mf
        mi.toISOString();
        let horario = {
          dia_semana: id,
          hora_inicio: mi.format("HH:mm"),
        };

        const startDate = moment(new Date('2017-04-14 ' + mi.format("HH:mm"))).format();

        mi.add(tiempoConsulta, "m");
        mi.toISOString();

        horario = { ...horario, hora_fin: mi.format("HH:mm"), hora_inicio_tz: startDate, hora_fin_tz: moment(new Date('2017-04-14 ' + mi.format("HH:mm"))).format(), espacios_disponibles };

        // console.log({horario});

        //si hay horarios anteriores para comparar si no agregar diractamente
        if (horarioDia.length > 0) {
          //Validar el horario ingresado contra todos los horarios guardados anteriormente,
          //Si el horario ingresado por el usuario cumple con la validación se guarda en el arreglo
          if (validarNuevoHorario(horario, horarioDia)) miarray.push(horario);
        } else {
          miarray.push(horario);
        }

        mi.add(tiempoentre, "m");
        // console.log({mi: mi.toString(), mf: mf.toString()});
        // console.log({isSame: mf.isSame(mi)})
      }

    });
    // console.log({ arregloHoras: miarray })
    handleSetHoras(miarray);

  };
  const onDiaChange = (event, values) => {
    setdia(values !== null ? values.id : "");
  };

  //Metodos utils
  const validarNuevoHorario = (horario, horariosDia) => {
    let flagValidation = true;
    for (let o of horariosDia) {
      let inicial = moment().utcOffset(0);
      let final = moment().utcOffset(0);
      let inicialNueva = moment().utcOffset(0);
      let finalNueva = moment().utcOffset(0);

      inicial.set({ hour: o.hora_inicio.split(":")[0], minute: o.hora_inicio.split(":")[1] });
      inicialNueva.set({ hour: horario.hora_inicio.split(":")[0], minute: horario.hora_inicio.split(":")[1] });
      final.set({ hour: o.hora_fin.split(":")[0], minute: o.hora_fin.split(":")[1] });
      finalNueva.set({ hour: horario.hora_fin.split(":")[0], minute: horario.hora_fin.split(":")[1] });

      //Si el inicio nuevo está entre un horario guardado o el final nuevo está entre un horario guardado o simplemenete el horario es el mismo no hacer nada
      if (moment(inicialNueva).isBetween(inicial, final) || moment(finalNueva).isBetween(inicial, final) || (o.hora_inicio === horario.hora_inicio && o.hora_fin === horario.hora_fin)) {
        // console.log("HORARIO FINAL SOLAPADO")
        flagValidation = false;
        break;
      } else {
        //tiene que ser válido siempre que sea mayor el inicio nuevo o que el horario nuevo fin sea menor al horario guardado fin
        if (!(moment(inicialNueva).isAfter(inicial) || moment(final).isAfter(finalNueva))) flagValidation = false;
      }
    }
    return flagValidation;
  }

  return (
    <form
      style={{
        margin: "15px auto",
        // width: "600px",
      }}
      className={classes.root}
      noValidate="noValidate"
      autoComplete="off"
    >
      {hasError && (
        <Alert severity={`Error`}>
          <AlertTitle>{"Error"}</AlertTitle>
          {hasError}
        </Alert>
      )}
      <GridItem xs={12} style={{ margin: 20 }}>
        Horarios repetidos o sobrepuestos no se tomarán en cuenta
      </GridItem>
      <Autocomplete
        id="dia"
        name="dia"
        options={dias_semana}
        //Si es que va a editar entonces deshabilitar: si viene el objeto a editar deshabilitar si no es uno nuevo
        getOptionLabel={(option) => option.id}
        onChange={onDiaChange}
        defaultValue={""}
        renderInput={(params) => (
          <TextField {...params} label="Seleccione el día" />
        )}
      />

      <GridContainer
      // style={{
      //   display: "flex",
      //   flex: 1,
      //   justifyContent: "center",
      // }}
      >
        <GridItem xs={12} style={{ margin: 20 }}>
          Ingrese el horario de la jornada laboral
        </GridItem>

        <GridItem xs={6} md={2}>
          <TextField
            label="Inicio"
            id="hora_inicioini"
            name="hora_inicioini"
            type="time"
            defaultValue={jornadaIni}
            fullWidth
            placeholder="hh:mm"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setjornadaIni(e.target.value)}
            value={jornadaIni}
          />
        </GridItem>
        <GridItem xs={6} md={2}>
          <TextField
            id="hora_finfin"
            name="hora_finfin"
            label="Fin"
            type="time"
            fullWidth
            placeholder="hh:mm"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setjornadaFin(e.target.value)}
            value={jornadaFin}
          />
        </GridItem>
        <GridItem xs={6} md={4}>
          <TextField
            label="Tiempo entre consultas(min)"
            id="tiempoentre"
            name="tiempoentre"
            type="number"
            fullWidth
            placeholder="mm"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => settiempoentre(e.target.value)}
            value={tiempoentre}
          />
        </GridItem>
        <GridItem xs={6} md={4}>
          <TextField
            label="Espacios disponibles"
            id="espaciosDisponibles"
            name="espacios_disponibles"
            type="number"
            fullWidth
            placeholder="mm"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setEspacios_disponibles(+e.target.value)}
            value={espacios_disponibles}
          />
        </GridItem>
        <GridItem xs={6} md={2} style={{ alignSelf: "center", margin: "auto" }}>
          <Button
            size="large"
            variant="outlined"
            color="primary"
            onClick={() => handleClick()}
          >
            Crear
          </Button>
        </GridItem>
      </GridContainer>
    </form>
  );
};

export default HorarioForm1;
