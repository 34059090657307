import React from 'react';

import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
//Icons

//Custom components

//Styles
// import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';

import { makeStyles } from '@material-ui/core/styles';

//Tiempo
import moment from 'moment';
import 'moment/locale/es';
// moment.locale('es');

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  disponible: {
    cursor: 'pointer',
    borderRightColor: 'green',
    borderRightStyle: 'ridge',
    borderRightWidth: '5px',
  },

  noDisponible: {
    cursor: 'not-allowed',
    borderRightColor: 'red',
    borderRightStyle: 'ridge',
    borderRightWidth: '5px',
  },
  horaDisponible: {
    fontWeight: 'bold',
  },
  horaNoDisponible: {
    textDecoration: 'line-through',
  },
}));

// const useStyles = makeStyles(styles);

const OficinaCard = ({ oficinas, dia, selectedHorario, fecha, ocupadosHorarios, onClick, ...props }) => {
  const classes = useStyles();

  const { data = [] } = useSelector(state => state.data_oficinas);
  const todas_oficinas = data.map(d => d.direccion);

  const fechaSeleccionadaCalendario = moment(fecha).format('YYYY-MM-DD');

  //Ordenar oficinas por hora de inicio
  oficinas = oficinas.sort((a,b) => (a.h_hora_inicio > b.h_hora_inicio) ? 1 : ((b.h_hora_inicio > a.h_hora_inicio) ? -1 : 0));

  //Pone en ocupado los horarios que han sido reservados
  for (let i = 0; i < oficinas.length; i++) {
    for (let j = 0; j < ocupadosHorarios.length; j++) {
      const fechaReserva = moment(ocupadosHorarios[j].inicio).format('YYYY-MM-DD');
      const horaReserva = `${moment(ocupadosHorarios[j].inicio).format('HH:mm')}-${moment(
        ocupadosHorarios[j].fin
        ).format('HH:mm')}`;
        const horaOficina = `${oficinas[i].h_hora_inicio}-${oficinas[i].h_hora_fin}`;
        if (moment(fechaSeleccionadaCalendario).isSame(fechaReserva) && horaOficina === horaReserva) {
        oficinas[i].estado = true;
        oficinas[i].esReserva = true;
       }else{
        oficinas[i].esReserva = false;
       }
    }
  }

   //Pone en ocupado los horarios que ya son anteriores
   for (let i = 0; i < oficinas.length; i++) {
    const nowDiaHora = moment(new Date()).format('YYYY-MM-DD HH:mm')
    const auxConcat = moment(fechaSeleccionadaCalendario + ' ' +oficinas[i].h_hora_inicio)
    const fechaSeleccionadaCalendarioHoras =  moment(auxConcat).format('YYYY-MM-DD HH:mm')
    if(moment(fechaSeleccionadaCalendarioHoras).isBefore(nowDiaHora) ){
      oficinas[i].estado = true;
    } else {
      if(oficinas[i].estado === true && !oficinas[i].esReserva){
          oficinas[i].estado = false;
      }
  }
}

  const seleccionado = {
    border: '2px green solid',
    boxShadow: '0 10px 4px 0 rgba(0, 0, 0, 0.14)', //Para que se alce la card y de el efecto de seleccionada
  };

  const noSeleccionado = {
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)', //Para que se baje la card y de el efecto de seleccionada
  };

  const handleCardClick = valor => {
    if (!valor.estado) {
      onClick({
        fecha: fecha,
        hora: `${valor.h_hora_inicio}-${valor.h_hora_fin}`,
        id_oficina: valor.h_oficina_id,
        fechaSeleccionada: fechaSeleccionadaCalendario,
        referencia_oficina: valor.o_direccion,
      });
    }
  };

  return (
    <div>
      <Box borderBottom={1}>
        <Typography display="inline" variant="subtitle1">
          {dia}
        </Typography>
      </Box>
      <GridContainer>
        {oficinas.length > 0 ? (
          todas_oficinas.map(element => {
            return (
              <div key={`${element}`} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box style={{ margin: '30px 50px 0px 50px' }}>
                  <Typography display="inline" variant="body">
                    <span style={{ fontWeight: 'bold' }}>{element}</span>
                  </Typography>
                </Box>
                <div style={{ display: 'flex',flexWrap:'wrap' }}>
                  {oficinas
                    .filter(unaOficina => unaOficina.o_direccion === element)
                    .map((oficina, index) => {
                      const fechaSeleccionada = moment(selectedHorario.fecha).format('YYYY-MM-DD');
                      const horaOficina = `${oficina.h_hora_inicio}-${oficina.h_hora_fin}`;
                      const horaSeleccionada = `${selectedHorario.hora}`;
                      return (
                        <GridItem xs={2}>
                          <Card
                            style={
                              fechaSeleccionada === fechaSeleccionadaCalendario &&
                              horaOficina === horaSeleccionada
                                ? seleccionado
                                : noSeleccionado
                            }
                            key={`${oficina.h_id}-${index}`}
                            className={oficina.estado ? classes.noDisponible : classes.disponible}
                            onClick={() => handleCardClick(oficina)}
                          >
                            <div
                              style={{ display: 'flex', flex: 1, justifyContent: 'center' }}
                              className={classes.stats}
                            >
                              <span
                                style={{ padding: '10px 0' }}
                                className={oficina.estado ? classes.horaNoDisponible : classes.horaDisponible}
                              >{`${oficina.h_hora_inicio}/${oficina.h_hora_fin}`}</span>
                            </div>
                          </Card>
                        </GridItem>
                      );
                    })
                  }
                </div>
              </div>
            );
          })
        ) : (
          <Typography style={{ margin: '50px auto' }} variant="subtitle1">
            ¡No tiene horarios guardados!
          </Typography>
        )}
      </GridContainer>
    </div>
  );
};

export default OficinaCard;
