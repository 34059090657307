import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

//Icons
import AddCircleIcon from '@material-ui/icons/AddCircle';
//Custom components

//REDUX
import { useSelector, useDispatch } from 'react-redux';
import { Alert, AlertTitle } from '@material-ui/lab';
import TableCliente from 'components/reservaciones/medico/cliente-table.component';
import { AutocompleteComponent } from 'components/ui/AutocompleteComponent';
import { setCliente } from 'redux/actions/aClientes';
import { getClientesPorBusqueda } from 'redux/actions/aClientes';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
}));

const Cliente = ({ setIdCliente, isLoaded, ...props }) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const { data: clientes = '' } = useSelector(state => state.data_clientes);
  const { clients_search } = useSelector(state => state.data_clientes);

  const handleBuscado = useCallback((_, values, __) => {
    setShowForm(false);
    if (values !== null && values.id) {
      dispatch(setCliente(values));
      setIdCliente(values.id);
    }
  }, [dispatch, setIdCliente]);


  const handleNavToPacientesForm = () => {
    localStorage.setItem('redirectDoctorPacientesForm', 'historiaClinica');
    history.push('/medico/pacientes-lista');
  }

  return (
    <div
      style={{
        display: 'flex',
        flex: '1',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div style={{ width: '300px' }}>
        <GridContainer className={classes.root} style={{ flex: 1 }}>
          <GridItem xs={12}>
            <AutocompleteComponent
              freeSolo={true}
              poblarData={[]}
              onBuscadoChange={(event, values) => handleBuscado(event, values, true)}
              optionLabel={option => option.id ? `${option.cedula_identidad} ${option.nombres} ${option.apellidos}` : ''}
              placeholder="CI/Nombres/Apellidos"
              dispatchFunction={getClientesPorBusqueda}
              dataSearch={clients_search}
              onFocus={() => setShowForm(false)}
            />
          </GridItem>
        </GridContainer>

        {props.hasError && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {props.hasError}
          </Alert>
        )}
      </div>

      {!Array.isArray(clientes) && (
        <TableCliente clientes={clientes} titulos={['Cédula', 'Nombres', 'Apellidos', 'Telefono', 'Sexo']} />
      )}
      <Button style={{ marginTop: '10px' }} variant="contained" color="primary" onClick={props.onClick} disabled={!clientes || !isLoaded} >
        Siguiente
      </Button>

      <IconButton
        style={{ marginTop: '10px', justifyContent: 'flex-end', color: showForm ? 'red' : '' }}
        onClick={handleNavToPacientesForm}
      >
        <AddCircleIcon />
        Añadir paciente
      </IconButton>


    </div>
  );
};

export default Cliente;
