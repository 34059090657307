/*eslint-disable*/
import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import { TopMenuIcon } from "components/CustomIcons/Sidebar/TopMenuIcon";

import styles from "../../assets/jss/material-dashboard-react/components/sidebarStyle";
import { Button, IconButton, Tooltip } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const classes = useStyles();
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }

  // color: color de fondo de menu item
  const { color = 'white', logo, image, logoText, routes, setShowTopMenu } = props;


  let logoutOption = null;

  const whiteFontClasses = classNames({
    [" " + classes.whiteFont]: activeRoute(logoutOption ? (logoutOption.layout + logoutOption.path) : ''),
  });

  const blackFontClasses = classNames({
    [" " + classes.blackFont]: activeRoute(logoutOption ? (logoutOption.layout + logoutOption.path) : ''),
  });


  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        var activePro = " ";
        var listItemClasses;
        if (prop.path === "/cerrar-sesion") {
          logoutOption = prop;
          activePro = classes.activePro + " ";
          listItemClasses = classNames({
            [" " + classes[color]]: true,
          });
        } else {
          listItemClasses = classNames({
            [" " + classes[color]]: activeRoute(prop.layout + prop.path),
          });
        }
        const blackFontClasses = classNames({
          [" " + classes.blackFont]: activeRoute(prop.layout + prop.path),
        });

        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
        });

        if (prop.hidden) return null;
        if (prop.path !== "/cerrar-sesion")
          return (
            <NavLink
              to={prop.layout + prop.path}
              className={activePro + classes.item}
              activeClassName="active"
              key={key}
            >
              <ListItem button className={classes.itemLink + listItemClasses}>
                {typeof prop.icon === "string" ? (
                  <Icon
                    className={classNames(classes.itemIcon, blackFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive,
                    })}
                  >
                    {prop.icon}
                  </Icon>
                ) : (
                  <prop.icon
                    className={classNames(classes.itemIcon, blackFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive,
                    })}
                  />
                )}
                <ListItemText
                  primary={props.rtlActive ? prop.rtlName : prop.name}
                  className={classNames(classes.itemText, blackFontClasses, {
                    [classes.itemTextRTL]: props.rtlActive,
                  })}
                  disableTypography={true}
                />
              </ListItem>
            </NavLink>
          );
      })}

      {/* Logout button */}
      <NavLink
        to={logoutOption.layout + logoutOption.path}
        className={classes.activePro + classes.item}
        activeClassName="active"
      >
        <ListItem button className={classes.itemLink + classNames({
          [" " + classes['whiteNoSelected']]: true,
        })}>
          {typeof logoutOption.icon === "string" ? (
            <Icon
              className={classNames(classes.itemIcon, blackFontClasses, {
                [classes.itemIconRTL]: props.rtlActive,
              })}
            >
              {logoutOption.icon}
            </Icon>
          ) : (
            <logoutOption.icon
              className={classNames(classes.itemIcon, blackFontClasses, {
                [classes.itemIconRTL]: props.rtlActive,
              })}
            />
          )}
          <ListItemText
            primary={props.rtlActive ? logoutOption.rtlName : logoutOption.name}
            className={classNames(classes.itemText, blackFontClasses, {
              [classes.itemTextRTL]: props.rtlActive,
            })}
            disableTypography={true}
          />
        </ListItem>

      </NavLink>
    </List>
  );

  let fixedBottomArea = (
    <div className={classes.fixedBottomAreaWrapper}>
      {
        logoutOption &&
        // <div style={{zIndex: 3}}>
        // <List>
        <NavLink
          to={logoutOption.layout + logoutOption.path}
          className={classes.activePro + classes.item}
          activeClassName="active"
        >
          <ListItem button className={classes.itemLink + classNames({
            [" " + classes['whiteNoSelected']]: true,
          })}>
            {typeof logoutOption.icon === "string" ? (
              <Icon
                className={classNames(classes.itemIcon, blackFontClasses, {
                  [classes.itemIconRTL]: props.rtlActive,
                })}
              >
                {logoutOption.icon}
              </Icon>
            ) : (
              <logoutOption.icon
                className={classNames(classes.itemIcon, blackFontClasses, {
                  [classes.itemIconRTL]: props.rtlActive,
                })}
              />
            )}
            <ListItemText
              primary={props.rtlActive ? logoutOption.rtlName : logoutOption.name}
              className={classNames(classes.itemText, blackFontClasses, {
                [classes.itemTextRTL]: props.rtlActive,
              })}
              disableTypography={true}
            />
          </ListItem>

        </NavLink>
        // </List>

        // </div>
      }
    </div>
  )

  const menuType = (
    <div className={classes.fixedButtonAreaWrapper}>
      {/* <Button className={classes.buttonLink} onClick={() => setShowTopMenu(true)} variant="contained">
        Cambiar vista
      </Button> */}
      {
        setShowTopMenu &&
        <Tooltip title='Mostrar menú superior' >
          <IconButton aria-label="delete" size="small" onClick={() => setShowTopMenu(true)}
            style={{ color: 'white', fontSize: '25px' }}>
            <TopMenuIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      }
    </div>
  )

  var brand = (
    <div className={classes.logo}>
      <a
        href="/"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive,
        })}
      // target="_blank"
      >
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        {logoText}
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          // anchor={"left"}
          anchor={props.rtlActive ? "right" : "left"}
          // anchor={props.rtlActive ? 'left' : 'right'}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              // [classes.drawerPaperRTL]: props.rtlActive,
              [classes.drawerPaperRTL]: true,
            }),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}

          <div className={classes.sidebarWrapper}>
            {/* {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />} */}
            {/* {props.rtlActive ? <div>rtNavbarlinks</div> : <div>adminnavbar</div>} */}
            {links}
            {fixedBottomArea}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          // anchor={"left"}
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
        >
          <div className={classes.sidebarWrapper}>{links}</div>
          {/* {fixedBottomArea} */}
          {/* Logo area */}

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {brand}
            {menuType}
          </div>



          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};
