import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import classnames from "classnames";

import { IconButton, TableHead, Typography } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

const useStyles = makeStyles(styles);
const TableCellStyle = withStyles((theme) => ({
  head: {},
  body: {
    padding: 5,
    fontSize: 15,
  },
}))(TableCell);
const HorarioTable = ({
  horario,
  dia,
  deleteHorario,
  onUnHorario,
  ...props
}) => {
  const classes = useStyles();
  // const { rtlActive } = props;

  const tableCellClasses = classnames(classes.tableCell, {
    padding: 5,
  });

  return (
    <Table className={classes.table}>
      <TableHead className={"TableHeader"}>
        <TableRow className={classes.tableHeadRow}>
          <TableCell
            className={classes.tableCell + " " + classes.tableHeadCell}
            key={"diaOficina"}
          >
            <Typography display="inline" variant="subtitle1">
              {dia}
            </Typography>
          </TableCell>
          <TableCell
            className={classes.tableCell + " " + classes.tableHeadCell}
            key={"iniciooficina"}
          >
            Hora inicio
          </TableCell>
          <TableCell
            className={classes.tableCell + " " + classes.tableHeadCell}
            key={"finOficina"}
          >
            Hora fin
          </TableCell>
          <TableCell
            className={classes.tableCell + " " + classes.tableHeadCell}
            key={"otroOficina"}
          />
        </TableRow>
      </TableHead>
      <TableBody>
        {horario.map((dia, indice) => (
          <TableRow key={indice} className={classes.tableRow}>
            <TableCellStyle className={tableCellClasses} />
            <TableCellStyle className={tableCellClasses}>
              {dia.hora_inicio}
            </TableCellStyle>
            <TableCellStyle className={tableCellClasses}>
              {dia.hora_fin}
            </TableCellStyle>
            <TableCellStyle className={[ tableCellClasses]}>
              <div style={{ flex:1, justifyContent:'space-around' }}>


              <Tooltip
                id="tooltip-editar"
                title="Editar horario"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <IconButton
                  aria-label="Edit"
                  onClick={() => onUnHorario(dia)}
                  className={classes.tableActionButton}
                >
                  <Edit
                    className={
                      classes.tableActionButtonIcon + " " + classes.edit
                    }
                  />
                </IconButton>
              </Tooltip>
              <Tooltip
                id="tooltip-eliminar"
                title="Eliminar horario"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <IconButton
                  aria-label="Close"
                  onClick={() => deleteHorario(dia.id)}
                  className={classes.tableActionButton}
                >
                  <Close
                    className={
                      classes.tableActionButtonIcon + " " + classes.close
                    }
                  />
                </IconButton>
              </Tooltip>
              </div>
            </TableCellStyle>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default HorarioTable;
