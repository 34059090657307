import { handleActions } from 'redux-actions';
import { recetas, isLoadingRecetas, hasErrorRecetas, setRecetaSeleccionada, ci10Busqueda, resetRecetaSeleccionada } from '../actions/aRecetas';

export const data_recetas = handleActions(
  {
    [recetas]: (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
      hasError: false,
    }),
    [isLoadingRecetas]: state => ({ ...state, isLoading: true, hasError: false }),
    [hasErrorRecetas]: (state, action) => ({
      ...state,
      isLoading: false,
      hasError: action.payload,
    }),
    [setRecetaSeleccionada]: (state, action) => ({
      ...state,
      recetaSeleccionada: action.payload,
    }),
    [resetRecetaSeleccionada]: (state, action) => ({
      ...state,
      recetaSeleccionada: null,
    }),
    [ci10Busqueda]: (state, action) => ({
      ...state,
      isLoading: false,
      dataCie10: action.payload,
    }),
  },
  { isLoading: false, hasError: false, data: [], recetaSeleccionada: null, dataCie10: []}
);
