import moment from 'moment';

//Verificar si una promoción se encuentra activa
export const validarServicioRangoFechaPromocion = (servicio) => {
    if(!servicio.descuento_inicio_tz || !servicio.descuento_fin_tz) return false;

    //Verificar fecha seleccionada este dentro del rango de promocion de servicio
    if(  (moment().isSameOrAfter(new Date(servicio.descuento_inicio_tz), 'day') && moment().isSameOrBefore(new Date(servicio.descuento_fin_tz), 'day')) || (moment().isBefore(new Date(servicio.descuento_inicio_tz), 'day'))  ){
      return true;
    }else{
      return false;
    }
}

export const obtenerPromocionesRestantes = (promocionesLimitReset, promocionesCreadas, promocionesLimite) => {
  if(promocionesLimitReset && moment(promocionesLimitReset).isAfter(moment(), 'day')){
    const diferencia =  (+promocionesLimite) - (+promocionesCreadas);
    return (diferencia >= 0) ? diferencia : 0;
  }else{
    return +promocionesLimite;
  }
}