import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormCrud } from "components/Admin/CRUD/FormCrud";
import { loadEmpresa } from "redux/actions/aAdmin";
import { editarDatosEmpresa } from "redux/actions/aAdmin";
import Loading from "components/Loading";

const Empresa = () => {

  const { descuento_afiliado } = useSelector(state => state.data_empresa.data);

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const initialStateFormComponents = useMemo(() => 
    [
      {
        type: "input",
        name: "descuento_afiliado",
        label: "Descuento afiliado",
        value: descuento_afiliado ? descuento_afiliado : "",
        validationOptions: {
          validate: (value) => {
            if (!/^\d+$/.test(value) || (parseInt(value) > 100 || parseInt(value) < 0)) {
              return 'El valor de descuento debe ser un valor entero entre 0 y 100'
            }
            return true;
          }
        },
      },
    ]
  , [descuento_afiliado])

  const onSubmitForm = async (data) => {
    !loading && setLoading(true);
    await dispatch(editarDatosEmpresa(data));
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(loadEmpresa()).then(() => setLoading(false));
  }, [dispatch]); // debouncedCount solo despues de 500ms consulta


  return (
    <Loading title="Cargando..." loading={loading}>
      <FormCrud
        edit={true}
        formComponents={initialStateFormComponents}
        onSubmitForm={onSubmitForm}
        isLoading={loading}
        hasError={false}
      />
    </Loading>
  );
};

export default Empresa;
