import { handleActions } from 'redux-actions';
import {
  especialidades,
  isLoadingEspecialidades,
  hasErrorEspecialidades,
} from '../actions/aEspecialidades';

export const data_especialidades = handleActions(
  {
    [especialidades]: (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
      hasError: false,
    }),
    [isLoadingEspecialidades]: state => ({
      ...state,
      isLoading: true,
      hasError: false,
    }),
    [hasErrorEspecialidades]: (state, action) => ({
      ...state,
      isLoading: false,
      hasError: action.payload,
    }),
  },
  { isLoading: false, hasError: false, data: [] }
);
