import ConfirmDialog from 'components/Admin/CRUD/ConfirmDialog';
import React, { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';

function onBeforeUnload(e) {
  let confirmationMessage = "Los datos aún no han sido guardados.";
  (e || window.event).returnValue = confirmationMessage; // Gecko + IE
  return confirmationMessage; // Webkit, Safari, Chrome
}

const WarningLeaveFormPage = ({ when }) => {

  let history = useHistory();

  const [lastLocation, setLastLocation] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [blockNavigation, setBlockNavigation] = useState(when);

  useEffect(() => {
    setBlockNavigation(when);
    setLastLocation(null);
  }, [when]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (blockNavigation) {
        onBeforeUnload(e);
      }
    };

    if (blockNavigation) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    } else {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [blockNavigation]);

  const handleBlockedNavigation = (nextLocation) => {
    if (blockNavigation) {
      setLastLocation(nextLocation);
      setOpenModal(true);
      return false;
    }
    return true;
  };

  const confirmExit = () => {
    setBlockNavigation(false);
    setOpenModal(false);
  };

  useEffect(() => {
    if (lastLocation && !blockNavigation) {
      history.push(lastLocation.pathname);
    }
  }, [lastLocation, blockNavigation, history]);

  return (
    <div>
      <Prompt when={blockNavigation} message={handleBlockedNavigation} />
      <ConfirmDialog
        title={"Los datos aún no han sido guardados."}
        open={openModal}
        setOpen={setOpenModal}
        onConfirm={confirmExit}
      >
        <div>¿Está seguro que desea salir sin guardar los cambios?</div>
      </ConfirmDialog>
    </div>
  );
};

export default WarningLeaveFormPage;
