import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import Cliente from "./cliente.component";
import OficinaReserva from "./oficina-reservaciones.component";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { useSelector, useDispatch } from "react-redux";
import Resumen from "./resumen.component";
import { agregarCita } from "redux/actions/aCitas";
import moment from "moment";
import { getHorariosOficina } from "redux/actions/aOficinas";
import { getHorariosReservaSecretaria } from "redux/actions/aOficinas";
import { cleanCliente } from "redux/actions/aClientes";
import Loading from "components/Loading";
import Notificacion from "components/ui/Notificacion";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    color: "white",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  components: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
  },
}));

const ReservaStep = ({ doctor_id, oficinas, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = ["Paciente", "Horario - Oficina", "Resumen"];
  const [isLoading, setisLoading] = React.useState(false);

  //Fecha date picker
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [selectedDay, setSelectedDay] = React.useState(moment(new Date()).format('dddd'));

  const [selectedHorario, setSelectedHorario] = React.useState({
    fecha: new Date(),
    hora: "00:00-01:00",
    id_oficina: undefined,
    fechaSeleccionada: undefined,
    especializacion_id: '',
  });
  const [, setIdCliente] = React.useState("");


  const cliente = useSelector((state) => state.data_clientes.data);
  const { hasError } = useSelector((state) => state.datos_citas);
  // const especializacion = useSelector(state => state.dataUser.data.especializaciones[0]);

  useEffect(() => {
    dispatch(getHorariosOficina(doctor_id));
  }, [dispatch, doctor_id]);

  const handleClickCard = (objeto) => {
    setSelectedHorario(objeto);
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <Cliente
            doctor={doctor_id}
            setIdCliente={(val) => setIdCliente(val)}
            onClick={handleNext}
          />
        );
      case 1:
        return (
          <OficinaReserva
            oficinas={oficinas}
            selectedHorario={selectedHorario}
            handleClickCard={handleClickCard}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay} />
        );
      case 2:
        return <Resumen selectedHorario={selectedHorario} oficinas={oficinas} />;
      default:
        return "Unknown stepIndex";
    }
  };

  const handleNext = async () => {
    if (activeStep === 0 && cliente !== undefined && !Array.isArray(cliente)) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep === 1) {
      if (selectedHorario.id_oficina !== undefined) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else
        Notificacion({
          type: "error",
          text: "Debe seleccionar la fecha",
        });


    } else if (activeStep === 2) {
      await guardaReserva();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const guardaReserva = async () => {
    try {
      const valores = {
        inicio: `${moment(
          `${selectedHorario.fechaSeleccionada} ${selectedHorario.hora.split("-")[0]
          }`
        )
          .local()
          .format()}`,
        fin: `${moment(
          `${selectedHorario.fechaSeleccionada} ${selectedHorario.hora.split("-")[1]
          }`
        )
          .local()
          .format()}`,
        fecha_reserva: moment(selectedHorario.fechaSeleccionada).format(),
        disponible: "",
        razon_no_disponibilidad: "",
        canal_reserva: "WEB_SECRETARIA",
        estado_reserva: "APROBADO",
        oficinaId: selectedHorario.id_oficina,
        phone_number: cliente.telefono,
        especializacion_id:
          selectedHorario.especializacion_id === '' ? null : selectedHorario.especializacion_id,
        cliente_id: cliente.id,
        doctor_id: doctor_id,
        servicio_id: selectedHorario.servicio_id === '0' ? null : selectedHorario.servicio_id,
        secretariaReserva: true,
      };
      setisLoading(true);
      await dispatch(agregarCita(valores));
      if (!hasError) {
        await dispatch(getHorariosReservaSecretaria(doctor_id));
        setTimeout(async () => {
          await dispatch(cleanCliente());
          setSelectedHorario({
            fecha: new Date(),
            hora: "00:00-01:00",
            id_oficina: undefined,
            fechaSeleccionada: undefined,
          });
        }, 1000);
      }
      setisLoading(false);
    } catch (err) {
      // seterror({
      //   title: 'error',
      //   mensaje: 'Ocurrio un error, intente nuevamente' + err,
      // });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    window.location.reload(false);
  };
  // todo: ver el estado de error
  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <div
              style={{
                margin: "50px auto",
                display: "flex",
                flex: "1",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {!hasError && (
                <>
                  <Typography className={classes.instructions}>
                    Se ha reservado la cita
                  </Typography>
                  <CheckCircleIcon fontSize="large" htmlColor="#00c5b4" />
                </>
              )}
              {hasError && (
                <>
                  <Typography className={classes.instructions}>
                    No se ha reservado la cita
                  </Typography>
                  <HighlightOffIcon fontSize="large" htmlColor="red" />
                </>
              )}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="primary"
                // variant="contained"
                // color="primary"
                onClick={handleReset}
                className={classes.button}
              >
                Nueva reserva
              </Button>
            </div>
          </div>
        ) : (
          <Loading title="Cargando..." loading={isLoading}>
            <div>
              <div className={classes.components}>
                {getStepContent(activeStep)}
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.backButton}
                >
                  Regresar
                </Button>
                <Button variant="contained" className={classes.button} color="primary" onClick={handleNext} disabled={!cliente}>
                  {activeStep === steps.length - 1 ? "Finalizar" : "Siguiente"}
                </Button>
              </div>
            </div>
          </Loading>
        )}
      </div>
    </div>
  );
};

export default ReservaStep;
