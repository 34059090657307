var crypto = require('crypto');

const publicKey = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAgGUG5LPS0Dm7kCCl+NmQ
/B2JvQCw65uF7sqUfJAKkU49kcseKOR0mopVtI6Qg/2ybpVYlvyDLSLfJaIBEVHU
d7TiWSJIWvRck0W1EPMCWzbJVe7/Ko0gPN9IZ9tiNqgdG/1dqkWygJDFPP8GtIda
qrsl4vbWE3AXVfvX9M/L4nva7lA5Ao9frTb9CCXvcAz+6C9/OTYT2Rzi6j7008wb
mMTeBwy3cbyfW6ewUeYqq8704wsqNh/c7Xun6XB34TtGDv9tCzhdxB4lk4W0Uacs
7U5pMob86Aewg6OhcYrm0YECcmaAkeh1Av7+PQPUMpgZDaEKuKiIxVUr6vx55zeI
cLqET1dP6I5q70xqGMVO29Diwrwa7PG2qL0om2etGVbfRPOIGPBLFDRt0yn7HTUl
zzXnniR00k3LDhcDJE5tAC/WwV5/mT178atgDK2jk9nNHo0E5e7cEYtM15CUk+dl
7FrSXTwT/nr5TtM+hzrK/lFUcR0kLvZ+9vJzKSCbUTCSkK1YMXR5yNTp7KwTi8Rf
LvLuEPy+AUV7tx+i3tVJG5HbMUSPX+UDkvmHfgnPbmlNR60ztMJNLy8ho6WgKKFF
eFRpKPh/onuAf3CZFUuj2r8vgT6xDWn3Mykhq1gWkXPWzmni0Uk+8JqHVOhe+Kcb
xP2VJm1vINPIPM9HfEz7XPcCAwEAAQ==
-----END PUBLIC KEY-----`;

export const encriptarRsa = async toEncrypt => {
  // var absolutePath = path.resolve(relativeOrAbsolutePathToPublicKey);
  // var publicKey = fs.readFileSync(absolutePath, "utf8");
  try {
    const buffer = Buffer.from(toEncrypt);
    const encrypted = crypto.publicEncrypt(publicKey, buffer);
    const data = await encrypted.toString('base64');

    return data;
  } catch (error) {
    return { error };
  }
};
