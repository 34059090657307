import React, { useCallback, useMemo, useState } from 'react'
import { ModalCliente } from './ModalCliente'
import { FormControl, MenuItem, Select, TableCell, makeStyles } from '@material-ui/core';
import TableRow from "@material-ui/core/TableRow";
import { InputTableCell } from './InputTableCell';
import { limitString } from "utils/secretaria/secretaria";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "components/CustomButtons/Button.js";
import { ModalContainer } from 'components/Modal/ModalContainer';
import { DoctorBilling } from 'components/Billing/DoctorBilling';


const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));


export const CajaRow = ({
  data,
  doctor_id,
  handleNuevoCliente,
  setData: setDataProp,
  fecha,
  index,
  serviciosMedico,
  dataProgramas,
  cajasData,
  servicesOptions,
  paymentOptions,
  setFormDirty,
  formErrors
}) => {

  const [openClientModal, setOpenClientModal] = useState("");
  const classes = useRowStyles();

  const [consultaIdSelected, setConsultaIdSelected] = useState(null);
  const [openModalBilling, setOpenModalBilling] = useState(false);

  const handleChange = (e, key) => {
    let new_value = e.target.value;

    let newArr = [...cajasData];
    newArr.map((i) => {
      i[key] = i === data ? new_value : i[key];
      return null;
    });
    setData(newArr);
    setFormDirty(true);
  };

  const setData = useCallback(
    (value) => {
      setDataProp(value)
      setFormDirty(true);
    },
    [setDataProp, setFormDirty],
  );

  const errors = useMemo(() => {
    const arrErrorsFiltered = formErrors.filter(item => item.id === data.c_id)
    return arrErrorsFiltered.length > 0 ? arrErrorsFiltered[0] : null;
  }, [formErrors, data]);

  const handleClickFactura = () => {
    const consultaId = data.r_consulta_id;
    console.log({ consultaId })
    setConsultaIdSelected(consultaId);
    setOpenModalBilling(true);
  }


  return (
    <>
      <ModalCliente
        open={openClientModal}
        setOpen={setOpenClientModal}
        dataCliente={handleNuevoCliente}
        doctorId={doctor_id}
      />
      <TableRow>
        <InputTableCell
          index={index}
          row={data}
          valueKey="r_hora_pago"
          rowItem={data.r_hora_pago}
          editable={data.r_entregado ? false : true}
          Data={cajasData}
          esHora={true}
          setData={setData}
          error={errors?.r_hora_pago}
        />
        <InputTableCell
          index={index}
          row={data}
          valueKey="c_nombres"
          rowItem={data.c_nombres}
          editable={false}
          Data={cajasData}
          fecha={fecha}
          setData={setData}
        />
        <InputTableCell
          index={index}
          row={data}
          valueKey="c_apellidos"
          rowItem={data.c_apellidos}
          editable={false}
          Data={cajasData}
          setData={setData}
        />
        <InputTableCell
          index={index.toString()}
          row={data}
          valueKey="c_cedula_identidad"
          rowItem={data.c_cedula_identidad}
          editable={data.r_entregado ? false : true}
          Data={cajasData}
          setOpen={setOpenClientModal}
          esCedula={true}
          setData={setData}
          error={errors?.c_cedula_identidad}
        />

        {/* Tipo de servicio */}
        <TableCell>
          {
            data.r_entregado
              ?
              limitString(data.r_tipo_servicio, 20)
              :
              <FormControl className={classes.formControl}>
                <Select
                  value={data.r_tipo_servicio}
                  onChange={(e) => handleChange(e, 'r_tipo_servicio')}
                >
                  {servicesOptions.map((opt, index) => (
                    <MenuItem value={opt} key={index}>
                      {opt}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
          }
        </TableCell>

        {/* Programa */}
        <TableCell>
          {
            data.r_entregado
              ?
              limitString(data.r_programa, 20)
              :
              <FormControl className={classes.formControl}>
                <Select
                  value={data.r_programa}
                  onChange={(e) => handleChange(e, 'r_programa')}
                >
                  {dataProgramas.map((opt, index) => (
                    <MenuItem value={opt} key={index}>
                      {opt}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
          }
        </TableCell>

        {/* Detalle */}
        <InputTableCell
          index={index}
          editable={data.r_entregado ? false : true}
          valueKey="r_detalle"
          rowItem={data.r_detalle}
          row={data}
          Data={cajasData}
          setData={setData}
        />

        {/* Forma de pago */}
        <TableCell>
          {
            data.r_entregado
              ?
              data.r_forma_pago
              :
              <FormControl className={classes.formControl}>
                <Select
                  value={data.r_forma_pago}
                  onChange={(e) => handleChange(e, 'r_forma_pago')}
                >
                  {paymentOptions.map((opt, index) => (
                    <MenuItem value={opt} key={index}>
                      {opt}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

          }
        </TableCell>

        {/* Valor cobro */}
        <InputTableCell
          index={index}
          editable={data.r_entregado ? false : true}
          valueKey="r_valor_cobro"
          rowItem={data.r_valor_cobro}
          row={data}
          Data={cajasData}
          setData={setData}
        />

        {/* Estado entregado */}
        <TableCell align="center">
          {data.r_entregado ? (
            <Checkbox
              checked={true}
              color="primary"
              inputProps={{ "aria-label": "uncontrolled-checkbox" }}
              disabled={true}
            />
          ) : (
            <Checkbox
              checked={data.r_entregado}
              color="primary"
              inputProps={{ "aria-label": "uncontrolled-checkbox" }}
              disabled={true}
            />
          )}
        </TableCell>

        {/* Facturación */}
        <TableCell>
          {
            data.r_consulta_id &&
            <Button color="primary" onClick={handleClickFactura}>
              Factura
            </Button>
          }

        </TableCell>

      </TableRow>

      <ModalContainer
        open={openModalBilling}
        onCloseModal={() => setOpenModalBilling(false)}
        title={'Facturación electrónica'}
      >
        {
          consultaIdSelected &&
          <DoctorBilling consultaId={consultaIdSelected} secretaria />
        }

      </ModalContainer>
    </>
  )
}
