import React from 'react';
import Citas from 'components/citas/secretaria/citas.component';
import SelectOficina from 'components/SeleccionarOficina/SelectOficina.component';
import { useSelector } from 'react-redux';

function Dashboard() {

  const { oficinaSelected } = useSelector(state => state.data_oficinas);

  return (
    <div>
      <SelectOficina />
      {oficinaSelected && <Citas oficina={oficinaSelected.id} />}
    </div>
  );
}

export default Dashboard;
