import React from 'react'
import { useSelector } from 'react-redux';
import { Typography, makeStyles } from '@material-ui/core';
import moment from "moment";

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import caracteristicas from '../../../assets/images/medpal.svg';
import { capitalize } from 'lodash';
import { calculateAgeString } from 'utils/paciente/paciente';

const useStyles = makeStyles(() => ({
  mainContent: { overflow: "visible  !important", },
  header: {
    textAlign: 'start',
    height: "100px",
    borderBottom: "1px solid black",
    display: "flex", alignItems: "center",
    marginBottom: '10px'
  },
  headerNoBorder: {
    textAlign: 'start',
    height: "101px",
    display: "flex", alignItems: "center",
    marginBottom: '10px'
  },
  headerAreaDoctor: {
    marginLeft: '20px'
  },
  doctorName: {
    fontWeight: 'bold',
    color: "black"
  },
  doctorSpecialty: {
    color: "black",
    marginTop: "10px"
  },
  // Tables
  mainTable: {
  },
  tablePrint: {
    width: '100%',
    tableLayout: 'fixed',
    "& td": {
      width: "50%"
    }
  },
  // Logo
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    "& img": {
      marginLeft: 'auto',
      marginRight: '10px',
    }
  }
}));

const formatDosificacion = ({ dias_uso, frecuencia_uso_horas, dosificacion }) => {
  let dosificacionString = '';
  if (dias_uso) dosificacionString += `Por ${dias_uso} días,`;
  if (frecuencia_uso_horas) dosificacionString += ` cada ${frecuencia_uso_horas} horas, `;
  dosificacionString += dosificacion;
  return capitalize(dosificacionString).trim();
}

export const RecetaContentPdf = React.forwardRef(({ data, header = true, preview = false }, ref) => {

  const { detalle, indicaciones } = data;
  const classes = useStyles();

  return (
    <div>
      <table style={{ width: '100%', pageBreakAfter: 'always' }}>
        <thead style={{ display: 'table-header-group' }}>
          <tr>
            <th>
              <div className={header ? classes.header : classes.headerNoBorder} >
                {
                  header &&
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <td>
                          <RecetaMembrete />
                        </td>
                        <td>
                          <RecetaMembrete />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                }
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className={classes.mainContent}>
                <table className={classes.tablePrint}>
                  <tbody>
                    <tr>
                      <td>
                        <div className='receta_printTableContentColumn'>
                          <RecetaCliente data={data} />
                        </div>
                      </td>
                      <td>
                        <div className='receta_printTableContentColumn'>
                          <RecetaCliente data={data} />
                        </div>
                      </td>
                    </tr>
                    {/* Receta detalle vademecum */}
                    {detalle.map(({ cantidad, medicamento, composicion, medicina, dosificacion, dias_uso, frecuencia_uso_horas }, index) => (
                      <tr key={index} style={{ pageBreakAfter: 'auto', verticalAlign: 'center' }}>
                        <td style={{ paddingTop: '10px' }}>
                          <div className='receta_printTableContentColumn'>
                            <div className='receta_printTableContentContainer'
                            >
                              <div className='receta_printTableContent'>
                                <div >
                                  <span
                                    style={{ fontWeight: 'bolder' }}
                                  >
                                    {`${medicina ? medicina.nombre : medicamento}`}  &mdash;  {` ${cantidad}`}
                                  </span>
                                </div>
                                <div>
                                  <span>{`${medicina ? medicina.composicion : composicion}`}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td style={{ paddingTop: '10px' }}>
                          <div className='receta_printTableContentColumn'>
                            <div className='receta_printTableContentContainer'

                            >
                              <div className='receta_printTableContent'>
                                <div  >
                                  <span
                                    style={{ fontWeight: 'bolder' }}
                                  >
                                    {`${medicina ? medicina.nombre : medicamento}`}
                                  </span>
                                </div>
                                <div> <span>{formatDosificacion({ dias_uso, frecuencia_uso_horas, dosificacion })}</span></div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}

                    {/* Indicaciones no farmacológicas */}
                    {
                      indicaciones ?
                        <tr style={{ verticalAlign: 'top' }}>
                          <td></td>
                          <td>
                            <div className='receta_printTableContentColumn'>
                              <div className='receta_printTableContent'>
                                <div style={{ marginTop: '40px' }}>
                                  <Typography variant="body2">
                                    <strong>Indicaciones no farmacológicas </strong>
                                  </Typography>
                                </div>
                                <div style={{ marginBottom: '0px' }}>
                                  <Typography variant="body2">
                                    {indicaciones}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        : null
                    }
                  </tbody>
                </table>
              </div>
            </td>
          </tr>

        </tbody>
        <tfoot style={{ display: 'table-footer-group' }}>
          <tr>
            <td>
              {
                preview
                  ?
                  <div className='receta_printTableFooterContainer'>
                    <div className={classes.logoContainer}>
                      <img
                        alt='logo'
                        src={caracteristicas}
                        width={75}
                      />
                      <img
                        alt='logo'
                        src={caracteristicas}
                        width={75}
                      />

                    </div>
                  </div>
                  :
                  <div className='receta_printTableFooterContainer'>
                    <div className='receta_printTableFooterContent'>
                      <div className={classes.logoContainer}>
                        <img
                          alt='logo'
                          src={caracteristicas}
                          width={75}
                        />
                        <img
                          alt='logo'
                          src={caracteristicas}
                          width={75}
                        />

                      </div>
                    </div>
                  </div>
              }
            </td>
          </tr>
        </tfoot>
      </table>

    </div>
  )
})

//Componentes
export const RecetaMembrete = () => {

  const classes = useStyles();

  const { titulo_honorifico, nombres, apellidos, medico } = useSelector(state => state.dataUser.data);
  const { data: dataUser } = useSelector((state) => state.dataUser);

  const doctorNombre = `${titulo_honorifico} ${nombres} ${apellidos}`;
  const { especializaciones } = medico;

  let especializacionesDoctor = "";

  especializaciones.map(({ nombre }) => (especializacionesDoctor.length === 0 ? especializacionesDoctor = especializacionesDoctor.concat(nombre) : especializacionesDoctor = especializacionesDoctor.concat(`, ${nombre}`)));

  return (
    <div className='receta_printTableContentColumn'>
      <div className={classes.doctorName}>
        {doctorNombre}
      </div>
      <div className={classes.doctorSpecialty}>
        {especializacionesDoctor}
      </div>
      <div className={classes.doctorSpecialty}>
        {dataUser.cedula_identidad}
      </div>
    </div>
  );

}

const RecetaCliente = ({ data }) => {

  const { peso, talla, alergias, cliente, created_date = new Date().toString(), cie10 = [], numero } = data;
  const { msp, acess } = useSelector(state => state.dataUser.data);

  const edadString = calculateAgeString(cliente.fecha_nacimiento, created_date);

  return (
    <GridContainer >
      <GridItem xs={6}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            marginBottom: '15px',
          }}
        >
          <span>SENESCYT---: {msp}</span>
        </div>
      </GridItem>
      <GridItem xs={6}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            marginBottom: '15px',
          }}
        >
          <span>ACESS: {acess}</span>
        </div>
      </GridItem>

      {
        numero &&
        <GridItem xs={12}>
          <Typography style={{ display: 'flex', justifyContent: 'left' }} variant="body2">
            Nro. {numero}
          </Typography>
        </GridItem>
      }

      <GridItem xs={7}>
        <Typography style={{ display: 'flex', justifyContent: 'left' }} variant="body2">
          Fecha: {moment(new Date(created_date)).format('YYYY-MM-DD HH:mm')}
        </Typography>
      </GridItem>

      <GridItem xs={5}>
        <Typography style={{ display: 'flex', justifyContent: 'left' }} variant="body2">
          Ciudad: Cuenca
        </Typography>
      </GridItem>

      <GridItem xs={7}>
        <Typography style={{ display: 'flex', justifyContent: 'left' }} variant="body2">
          Cédula/Pasaporte: {cliente.cedula_identidad}
        </Typography>
      </GridItem>

      <GridItem xs={5}>
        <Typography style={{ display: 'flex', justifyContent: 'left' }} variant="body2">
          Edad: {edadString}
        </Typography>
      </GridItem>
      <GridItem xs={6}>
        <Typography style={{ display: 'flex', justifyContent: 'left' }} variant="body2">
          Nombre: {cliente.nombres} {cliente.apellidos}
        </Typography>
      </GridItem>

      <GridItem xs={6} />

      <GridItem xs={7}>
        <GridContainer>
          <GridItem xs={12}>
            <Typography style={{ display: 'flex', justifyContent: 'left' }} variant="body2">
              Peso: {peso} kg
            </Typography>
          </GridItem>
          <GridItem xs={12}>
            <Typography style={{ display: 'flex', justifyContent: 'left' }} variant="body2">
              Talla: {talla} m
            </Typography>
          </GridItem>
        </GridContainer>
      </GridItem>

      <GridItem xs={5}>
        <Typography style={{ display: 'flex', justifyContent: 'left' }} variant="body2">
          Alergias: {alergias}
        </Typography>
      </GridItem>

      <GridItem xs={12}>
        <Typography style={{ display: 'flex', justifyContent: 'left' }} variant="body2">
          CIE10: {cie10.map(({ cie10 }) => ' ' + cie10.toString().split(' ', 2)[0]).toString()}
        </Typography>
      </GridItem>
      <GridItem xs={6}></GridItem>
    </GridContainer>
  );
}