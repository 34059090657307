import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { loadServicios } from 'redux/actions/aMedico';
import { editarServicio } from 'redux/actions/aMedico';
import { eliminarServicio } from 'redux/actions/aMedico';
import PromocionesMedico from 'components/Medico/Promociones/PromocionesMedico';
import { useDispatch } from 'react-redux';
import { getOficinasDoctor } from 'redux/actions/aOficinas';

const PromocionesDoctor = () => {
  const { doctor_id } = useSelector(state => state.dataUser.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOficinasDoctor());
  }, [dispatch])
  

  return (
    <PromocionesMedico doctorId={doctor_id} updateService={editarServicio} deleteService={eliminarServicio} loadServices={loadServicios}   />
  );
};

export default PromocionesDoctor;
