import React, { useEffect, useRef, useState } from 'react';
import Chartjs from 'chart.js';

// const randomInt = () => Math.floor(Math.random() * (10 - 1 + 1)) + 1;



export const LineChart = ({data: dataIn}) => {
  
  const chartContainer = useRef(null);
  const [, setChartInstance] = useState(null);

  useEffect(() => {
    const chartConfig = {
      type: "bar",
      data: {
        labels: dataIn.map( item => `${item.first_name} ${item.last_name}` ),
        datasets: [
          {
            label: "# de Reservas y Cajas",
            data:  dataIn.map( item => parseInt(item.cuenta) ),
          //   backgroundColor: [
          //     "rgba(255, 99, 132, 0.2)",
          //     "rgba(54, 162, 235, 0.2)",
          //     "rgba(255, 206, 86, 0.2)",
          //     "rgba(75, 192, 192, 0.2)",
          //     "rgba(153, 102, 255, 0.2)",
          //     "rgba(255, 159, 64, 0.2)"
          //   ],
            borderColor: "rgba(255, 99, 132, 1)",
            borderWidth: 1
          }
        ]
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      }
    };

    if (chartContainer && chartContainer.current) {
      const newChartInstance = new Chartjs(chartContainer.current, chartConfig);
      setChartInstance(newChartInstance);
    }
  }, [chartContainer, dataIn]);


    return (
        <div>
            <canvas ref={chartContainer} /> 
        </div>
    )
}
