import React, { useEffect } from "react";
import ViewPrincipal from "./Views";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./theme";
import "./styles/variables.scss";
import "./styles/estilos.scss";

import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

// core components
import Medico from "Layout/Medico.js";
import Cliente from "Layout/Cliente.js";
import Secretaria from "Layout/Secretaria.js";
import Laboratorista from "Layout/Laboratorista.js";
import AdminSecretario from "Layout/AdminSecretario.js";
import Admin from "Layout/Admin.js";
import Institucion from "Layout/Institucion";

import "assets/css/material-dashboard-react.css?v=1.8.0";
// redux
import { useDispatch, useSelector } from "react-redux";
import { ActualizarPassword } from "Views/Auth/ActualizarPassword";
import { CuentaActivacion } from "Views/Auth/CuentaActivacion";
import { LayoutRoute } from "Layout";
import MainLayout from "Layout/MainLayout";
import { EmailUpdate } from "Views/Auth/EmailUpdate";
import { CreditsPayment } from "Views/Payment/credits/CreditsPayment";
import { ConfirmPayment } from "Views/Payment/ConfirmPayment";
import { DoctorLandingPage } from "Views/Landing/DoctorLandingPage";
import { UserLandingPage } from "Views/Landing/UserLandingPage";
import { ReservaPayment } from "Views/Payment/reservas/ReservaPayment";
import { TermsPage } from "Views/terms/TermsPage";
import { startValidateUserRole } from "redux/actions/aLogin";
import { StartDeleteAccount } from "Views/Auth/StartDeleteAccount";
import { DeleteAccount } from "Views/Auth/DeleteAccount";

const hist = createBrowserHistory();

// ReactDOM.render(

//   document.getElementById("root")
// );

const PATH = {
  1: {
    path: "/medico",
    Componente: Medico,
    pathname: "/medico/dashboard",
  },
  2: {
    path: "/secretaria",
    Componente: Secretaria,
    pathname: "/secretaria/dashboard",
  },
  3: {
    path: "/laboratorista",
    Componente: Laboratorista,
    pathname: "/laboratorista/dashboard",
  },
  4: {
    path: "/cliente",
    Componente: Cliente,
    pathname: "/cliente/dashboard",
  },
  5: {
    path: "/adminSecretario",
    Componente: AdminSecretario,
    pathname: "/adminSecretario/dashboard",
  },
  6: {
    path: "/admin",
    Componente: Admin,
    pathname: "/admin/dashboard",
  },
  7: {
    path: "/institucion",
    Componente: Institucion,
    pathname: "/institucion/perfil",
  },
};

const App = () => {
  const dispatch = useDispatch();
  const rol = useSelector((state) => state?.dataUser?.data?.r);

  let path = "/";
  let Componente = ViewPrincipal;
  let pathname = "/";

  useEffect(() => {
    dispatch(startValidateUserRole());
  }, [dispatch])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Router history={hist}>
            <Switch>

              {rol && (
                <Route
                  exact
                  path={`${PATH[rol].path}/*`}
                  component={PATH[rol].Componente}
                />
              )}
              <LayoutRoute exact path="/activacionCuenta/:codigoActivacion" component={CuentaActivacion} layout={MainLayout} />
              <LayoutRoute exact path="/actualizarEmail/:codigo" component={EmailUpdate} layout={MainLayout} />
              <Route exact path="/actualizarContrasena/:resetKey" component={ActualizarPassword} />

              {/* Payments */}

              {/* Créditos */}
              <Route exact path="/comprarCreditos/:clientId/:creditsItemId" component={CreditsPayment} />

              {/* Eliminación de cuenta */}
              {/* <Route exact path="/deleteAccount/" component={DeleteAccount} /> */}
              <Route exact path="/deleteAccount" component={StartDeleteAccount} />
              <Route exact path="/account/delete-account" component={DeleteAccount} />

              {/* Reservas pagos */}
              <Route exact path="/pagarReserva" component={ReservaPayment} />
              <Route exact path="/pagarReserva/:reservaId" component={ReservaPayment} />

              {/* Confirmación de transacción (Respuesta payphone) */}
              <Route exact path="/confirmarTransaccion/:transactionId/:clientTransactionId" component={ConfirmPayment} />

              <LayoutRoute exact path="/terms/" component={TermsPage} layout={MainLayout} />
              <LayoutRoute exact path="/cookies/" component={() => <TermsPage type='cookies' />} layout={MainLayout} />
              <LayoutRoute exact path="/privacy/" component={() => <TermsPage type='privacy' />} layout={MainLayout} />

              <Route exact path="/medicos/" component={DoctorLandingPage} />
              <Route exact path="/usuarios/" component={UserLandingPage} />

              <Route exact path="/*" component={Componente} />

              {/* <Route exact path={`${path1}/*`} component={Componente} /> */}

              {/* <Route exact path="/" component={ViewPrincipal} /> */}
              <Route
                exact
                path={"*" || path}
                component={() => {
                  return <Redirect to={{ pathname }} />;
                }}
              />
            </Switch>
          </Router>
        </div>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default App;
