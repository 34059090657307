import React from 'react'
import "../../styles/landing/usuarioLanding.scss"

export const UserLandingPage = () => {
  return (
    <div className='user-landing-page'>
      <div className="section1 even">
        <div className="hero">
          <div className="medpal_logo_h"></div>
          <div className="medpal_logo_v"></div>
          <h1 className="side_l">Salud a tu alcance</h1>
          <h2 className="side_l">MedPal te permite acceder a la mejor
            atención médica a precios realmente
            accesibles y cerca de ti</h2>
          <div className="stores">
            <div className="playstore"></div>
            <div className="appstore"></div>
          </div>
        </div>
      </div>
      <div className="section_intro">
        <h1>Descarga nuestra aplicación y descubre un nuevo mundo
          de posibilidades para cuidar de tu salud.
          ¿Qué nos hace diferentes de los demás?</h1>
        <h2>Permíteme contarte:</h2>
      </div>
      <div className="section2 even">
        <div className="features1 features">
          <h1>Ahorra con nuestro sistema de créditos</h1>
          <h2>Con Medpal, no solo encontrarás a los
            mejores profesionales de la salud, sino que
            también podrás acceder a sus <b>servicios a
              precios más bajos</b>. Nuestro sistema de
            pagos mediante créditos te permite
            disfrutar de citas médicas con increíbles
            descuentos.</h2>
          <div id="img_feature1" className="img"></div>
        </div>
      </div>
      <div className="section3 odd">
        <div className="features2 features">
          <h1>Recordatorios Inteligentes</h1>
          <h2>Olvídate de las preocupaciones por tomar
            tus medicamentos a tiempo o de perder
            una cita médica importante. <b>Medpal te
              ofrece una funcionalidad única para
              añadir recordatorios personalizados.
              Nunca más te saltarás una dosis o
              llegarás tarde a una consulta.</b> Nos
            preocupamos por tu bienestar y queremos
            hacerte la vida más fácil.</h2>
          <div id="img_feature2" className="img"></div>
        </div>
      </div>
      <div className="section4 even">
        <div className="features3 features">
          <h1>Variedad de profesionales</h1>
          <h2>En Medpal, entendemos que cada paciente
            es único y tiene necesidades específicas.
            Por eso, te ofrecemos una amplia gama de
            profesionales de la salud en nuestra
            plataforma. <b>Sea cual sea tu especialidad
              requerida, ya sea un médico general, un
              dentista, un especialista o un terapeuta,
              encontrarás al experto adecuado para
              cuidar de ti.</b> </h2>
          <div id="img_feature3" className="img"></div>
        </div>
      </div>

      <div className="section5 odd">
        <div className="features4 features">
          <h1>Experiencia simplificada</h1>
          <h2>Nuestra aplicación ha sido diseñada
            pensando en ti. Con una interfaz intuitiva
            y fácil de usar, podrás navegar por
            nuestra plataforma y encontrar lo que
            necesitas en cuestión de segundos. Tu
            tiempo es valioso, y en Medpal nos
            aseguramos de que aproveches cada instante</h2>
          <div id="img_feature4" className="img"></div>
        </div>
      </div>

      <div className="section6 even">
        <div className="reviews">
          <h1>Ellos ya disfrutan de las ventajas de Medpal</h1>
          <div id="img_customer"></div>
          <h3>"Me encanta la facilidad y rapidez al momento de realizar
            una cita médica, y no se diga de los beneficios económicos
            que me ofrecen en servicios de salud, ¡son excelentes!.
            Realmente muy recomendado"</h3>
          <h4>Marie Flasher</h4>
        </div>
      </div>
      <div className="footer landing">
        <div className="footer_content features">
          <h1>Tu bienestar y satisfacción son nuestra prioridad.
            Descarga la aplicación hoy mismo y descubre por
            qué miles de personas confían en nosotros para su
            cuidado de salud.</h1>
        </div>
        <div className="stores">
          <div className="playstore"></div>
          <div className="appstore"></div>
        </div>
      </div>
    </div>
  )
}
