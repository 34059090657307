import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TableContainer, TableHead } from "@material-ui/core";


import { useDispatch, useSelector } from 'react-redux';

import { LineChart } from 'components/Charts/LineChart';


import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

import classnames from "classnames";
import { startLoadCajasReservasBySecretaria, startLoadNumeroCajasReservasBySecretaria } from 'redux/actions/aCajas';
import Loading from 'components/Loading';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },

});

function Dashboard() {
  
  const classes = useStyles();

  const tableCellClasses = classnames(classes.tableCell, {
    padding: 10,
  });


  const dispatch = useDispatch();
  
  const {dataEstadisticas} = useSelector(state => state.datos_cajas);
  const {NumeroCajasReservasPorSecretaria, CajasReservasPorSecretaria} = dataEstadisticas;

  useEffect(() => {
    dispatch(startLoadCajasReservasBySecretaria());
  }, [dispatch]);

  useEffect(() => {
    dispatch(startLoadNumeroCajasReservasBySecretaria());
  }, [dispatch]);

  const dataCajasReservas = CajasReservasPorSecretaria.cajas.concat(CajasReservasPorSecretaria.reservas);
  
  dataCajasReservas.sort(function(a,b){
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(b.fecha) - new Date(a.fecha);
  });

  console.log('dataCajasReservas', dataCajasReservas);

  const reducer = (accumulator, currentValue) => accumulator + parseInt(currentValue.cuenta);
  const totalNumeroClientes = NumeroCajasReservasPorSecretaria.data.reduce(reducer, 0);

  //Manejo de Fechas
  const [errorDate, setErrorDate] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [startDateClientePorSecretaria, setStartDateClientePorSecretaria] = useState(moment(new Date()).subtract(1, 'months'));
  const [endDateClientePorSecretaria, setEndDateClientePorSecretaria] = useState(moment(new Date()));
  
  
  const [selectedDateNumeroClientePorSecretaria, setSelectedDateNumeroClientePorSecretaria] = React.useState(moment(new Date()));

  const handleStartDateChangeClientePorSecretaria = (date) => {
    setStartDateClientePorSecretaria(moment(date));
    handleSubmitDateClientePorSecretaria(date, 'start');
  };
  const handleEndDateChangeClientePorSecretaria = (date) => {
    setEndDateClientePorSecretaria(moment(date));
    handleSubmitDateClientePorSecretaria(date, 'end');
  };

  const handleSubmitDateClientePorSecretaria = (date, type) => {
    let startDate = startDateClientePorSecretaria;
    let endDate = endDateClientePorSecretaria;

    if(type === 'start'){
      startDate = moment(date);
    }else{
      endDate = moment(date);
    }

    if(moment(startDate).isAfter(endDate, 'day')){
      console.log('adad');
      setErrorDate(true);
      setErrorMessage('La fecha inicial debe ser menor a la fecha final');
    }else{
      setErrorDate(false);
      dispatch(startLoadCajasReservasBySecretaria(startDate, endDate));
    }
  };

  const handleDateChangeNumeroClientePorSecretaria = (date) => {
    console.log(date);
    setSelectedDateNumeroClientePorSecretaria(date);
    dispatch(startLoadNumeroCajasReservasBySecretaria(date));
  };

  return (
    <div>
      <h4>Datos Secretarios</h4>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around">
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Fecha Inicial"
              value={startDateClientePorSecretaria}
              onChange={handleStartDateChangeClientePorSecretaria}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              margin="normal"
              format="MM/dd/yyyy"
              id="date-picker-inline"
              label="Fecha Final"
              value={endDateClientePorSecretaria}
              onChange={handleEndDateChangeClientePorSecretaria}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
      </MuiPickersUtilsProvider>
       {
         (errorDate) && 
         <Alert severity="error"> {errorMessage} </Alert>
       }
    
      <Loading title="Cargando..." loading={CajasReservasPorSecretaria.isLoading}>
       

        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead className={"TableHeader"}>
              <TableRow className={classes.tableHeadRow}>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                  key={"nombre"}
                >
                  Nombres
                </TableCell>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                  key={"puesto"}
                >
                  Puesto
                </TableCell>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                  key={"cliente"}
                >
                  Paciente
                </TableCell>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                  key={"fecha"}
                >
                  Fecha
                </TableCell>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                  key={"hora"}
                >
                  Hora
                </TableCell>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                  key={"medico"}
                >
                  Médico
                </TableCell>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                  key={"metodoPago"}
                >
                  Método de pago
                </TableCell>
                {/* <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                  key={"otroOficina"}
                /> */}
              </TableRow>
            </TableHead>
        
            <TableBody>
              {dataCajasReservas.map((item, indice) => (

                  (item.reserva_id) ?
                  <TableRow hover role="checkbox" tabIndex={-1} key={item.reserva_id} >
                    <TableCell align={'left'}>
                      {`${item.secretaria_nombres} ${item.secretaria_apellidos}`}
                    </TableCell>
                    <TableCell className={tableCellClasses}>
                    {`${item.oficina_fisicas}`}
                    </TableCell>
                    <TableCell className={tableCellClasses}>
                    {`${item.cliente_nombres} ${item.cliente_apellidos}`}
                    </TableCell>
                    <TableCell className={tableCellClasses}>
                    {`${moment(item.fecha).format('YYYY-MM-DD')}`}
                    </TableCell>
                    <TableCell className={tableCellClasses}>
                    {`${moment( item.fecha).format('h:mm a')}`}
                    </TableCell>
                    <TableCell className={tableCellClasses}>
                    {`${item.doctor_nombres} ${item.doctor_apellidos}`}
                    </TableCell>
                    <TableCell className={tableCellClasses}>
                    {`${item.reserva_metodo_pago}`}
                    </TableCell>
                  
                  </TableRow>
                  : (
                    <TableRow hover role="checkbox" tabIndex={-1} key={item.cajas_id} >
                    <TableCell align={'left'}>
                      {`${item.secretaria_nombres} ${item.secretaria_apellidos}`}
                    </TableCell>
                    <TableCell className={tableCellClasses}>
                    {`${item.oficina_fisicas}`}
                    </TableCell>
                    <TableCell className={tableCellClasses}>
                    {`${item.cliente_nombres} ${item.cliente_apellidos}`}
                    </TableCell>
                    <TableCell className={tableCellClasses}>
                    {`${moment(item.fecha).format('YYYY-MM-DD')}`}
                    </TableCell>
                    <TableCell className={tableCellClasses}>
                    {`${moment(item.fecha).format('h:mm a')}`}
                    </TableCell>
                    <TableCell className={tableCellClasses}>
                    {`${item.doctor_nombres} ${item.doctor_apellidos}`}
                    </TableCell>
                    <TableCell className={tableCellClasses}>
                    {`${item.cajas_forma_pago}`}
                    </TableCell>
                  
                  </TableRow>
                  )
              ))} 

            </TableBody>
        </Table>
      </TableContainer>
    </Loading> 
    <br/>

      <div className="container">
        <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="space-around">
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Fecha"
                  value={selectedDateNumeroClientePorSecretaria}
                  onChange={handleDateChangeNumeroClientePorSecretaria}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                
              </Grid>
          </MuiPickersUtilsProvider>
        </div>
        <Loading title="Cargando..." loading={NumeroCajasReservasPorSecretaria.isLoading}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead className={"TableHeader"}>
                    <TableRow className={classes.tableHeadRow}>
                      <TableCell
                        className={classes.tableCell + " " + classes.tableHeadCell}
                        key={"Secretaria"}
                      >
                        Secretario/a
                      </TableCell>
                      <TableCell
                        className={classes.tableCell + " " + classes.tableHeadCell}
                        key={"Cuenta"}
                      >
                        Cuenta
                      </TableCell>
                    
                    </TableRow>
                  </TableHead>
              
                  <TableBody>
                    {NumeroCajasReservasPorSecretaria.data.map((item, indice) => (
                        <TableRow hover role="checkbox" tabIndex={-1} key={item.id_secretaria} >
                          <TableCell align={'left'}>
                            {`${item.first_name} ${item.last_name}`}
                          </TableCell>
                          <TableCell className={tableCellClasses}>
                          {`${item.cuenta}`}
                          </TableCell>
                          
                        
                        </TableRow>
                    ))} 
                  </TableBody>

                  <TableHead className={"TableHeader"}>
                    <TableRow className={classes.tableHeadRow}>
                      <TableCell
                        className={classes.tableCell + " " + classes.tableHeadCell}
                        key={"Secretaria"}
                      >
                        Total: 
                      </TableCell>
                      <TableCell
                        className={classes.tableCell + " " + classes.tableHeadCell}
                        key={"Cuenta"}
                      >
                        {totalNumeroClientes}
                      </TableCell>
                    </TableRow>
                  </TableHead>
              </Table>
            </TableContainer>
            </Grid>
            <Grid item xs={6}>
                <LineChart data={NumeroCajasReservasPorSecretaria.data}/>
            </Grid>
          </Grid>
        </Loading> 
      </div>
  
  

    </div>
  );
}

export default Dashboard;