import React from 'react';

// import { makeStyles } from '@material-ui/core/styles';
// import Table from "@material-ui/core/Table";
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
// import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';


// react plugin for creating charts
// import ChartistGraph from "react-chartist";
// @material-ui/core


import TableCitas from './citas-table.component';

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Loading from 'components/Loading';
import moment from "moment";
import MomentUtils from '@date-io/moment';
// import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';


import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Calendar from './calendario/Calendar.component';
import { useCitasState } from 'hooks/medico/citas/useCitasState';
import { useSelector } from 'react-redux';
//Redux
moment.normalizeUnits('GMT');

const Citas = () => {
  // const classes = useStyles();

  const {
    calendarOnNavigate,
    calendarOnRangeChange,
    calendarOnSelectEvent,
    calendarOnSelectSlot,
    calendarOnViewChange,
    calendarView,
    citas,
    citasScreenState,
    handleDateChangeFinal,
    handleDateChangeInicial,
    isLoading,
    modeOptions,
    onCalendarOnDoubleClick,
    handleSelectMode,
  } = useCitasState({});

  const { selectedMode, selectedDateInicial, selectedDateFinal, selectedCalendarDate } = citasScreenState;
  const { med_activo } = useSelector((state) => state.dataUser.data);

  return (
    <Loading title="Cargando..." loading={isLoading}>
      <Card>
        <CardBody>
          <div>
            {/* Selector de vista */}
            <div >
              <GridContainer >
                <GridItem xs={12} sm={5}>
                  <FormControl style={{ width: '100%' }}>
                    <InputLabel id="selectModeVistaLabel">Seleccione modo de visualización</InputLabel>
                    <Select
                      labelId="selectModeVistaLabel"
                      id="selectMode"
                      value={selectedMode}
                      onChange={handleSelectMode}

                    >
                      {
                        modeOptions.map(({ label, value }) => (
                          <MenuItem key={value} value={value}>{label}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </div>

            {
              selectedMode === 0
                ?
                //  Vista Tabla
                <div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '150px', alignContent: 'center', marginBottom: '20px', marginRight: '15px' }}>
                      <span style={{ 'marginRight': '20px' }}>Fecha Inicial</span>
                      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="YYYY-MM-DD"
                          value={selectedDateInicial}
                          onChange={handleDateChangeInicial}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '150px', alignContent: 'center', marginBottom: '20px' }}>
                      <span style={{ 'marginRight': '20px' }}>Fecha Final</span>
                      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="YYYY-MM-DD"
                          value={selectedDateFinal}
                          onChange={handleDateChangeFinal}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                  <TableCitas
                    tableHeaderColor="warning"
                    citas={citas}
                    fecha={selectedDateInicial}
                    titulos={
                      med_activo
                        ? ['Nombre', 'Identificación', 'Fecha', 'Hora', 'Estado', 'Disponibilidad', 'Motivo', 'Créditos', 'Pago pendiente', 'Servicio',] :
                        ['Nombre', 'Identificación', 'Fecha', 'Hora', 'Estado', 'Disponibilidad', 'Motivo', 'Pago pendiente', 'Servicio',]
                    }
                  />
                </div>
                :
                //  Vista Calendario
                <div style={{ marginTop: '20px' }}>
                  <Calendar
                    onDoubleClickEvent={onCalendarOnDoubleClick}
                    onSelectEvent={calendarOnSelectEvent}
                    onView={calendarOnViewChange}
                    onRangeChange={calendarOnRangeChange}
                    events={citas}
                    // date={selectedCalendarDateInicial}
                    onSelectSlot={calendarOnSelectSlot}
                    defaultDate={selectedCalendarDate}
                    startAccessor={'r_inicio'}
                    endAccessor={'r_fin'}
                    onNavigate={calendarOnNavigate}
                    view={calendarView}
                  />

                </div>
            }
          </div>

        </CardBody>
      </Card>
    </Loading >
  );
};

export default Citas;
