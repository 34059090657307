import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Servicios } from 'components/Medico/Promociones/Servicios';
import Loading from 'components/Loading';
import Notificacion from "components/ui/Notificacion";
import { obtenerPromocionesRestantes } from 'utils/doctor/servicios/servicios';

const PromocionesMedico = ({ doctorId: doctor_id, updateService, deleteService, loadServices }) => {

  const [loading, setLoading] = useState(true);
  const [serviciosData, setServiciosData] = useState([]);
  const [medicoParamsData, setMedicoParamsData] = useState(null);

  const promocionesRestantes = useMemo(() => 
    medicoParamsData
      ? obtenerPromocionesRestantes(medicoParamsData.promociones_limite_reset, medicoParamsData.promociones_creadas, medicoParamsData.promocionesLimite)
      : null, [medicoParamsData]
  );

  const dispatch = useDispatch();

  const onDelete = async (servicioId) => {
    try {
      setLoading(true);
      await dispatch(deleteService(servicioId));
      setServiciosData(serviciosData.filter(servicio => servicio.id !== servicioId));
      setLoading(false);
      Notificacion({
        type: "success",
        text: "Datos eliminados exitosamente.",
      });
    } catch (error) {
      setLoading(false);
      Notificacion({
        type: "error",
        text: "Ha ocurrido un error al eliminar el registro",
      });
      console.log({ error });
    }
  }

  const onUpdate = async (servicio) => {
    try {
      const { data, dataMedico } = await dispatch(updateService(servicio.id, servicio));
      setServiciosData(serviciosData.map(serv => serv.id === servicio.id ? data : serv));
      setMedicoParamsData(dataMedico);
      Notificacion({
        type: "success",
        text: "Datos actualizados exitosamente.",
      });
    } catch (error) {
      Notificacion({
        type: "error",
        text: "Ha ocurrido un error al actualizar el registro",
      });
      console.log({ error });
    }
  }

  const init = useCallback(
    async () => {
      try {
        const { data, dataMedico } = await dispatch(loadServices());
        setServiciosData(data);
        setMedicoParamsData(dataMedico);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Notificacion({
          type: "error",
          text: "No se pudo recuperar la información recargue la página ",
        });
      }
    },
    [dispatch, loadServices],
  )

  useEffect(() => {
    init();
  }, [init])


  return (
    <Loading title="Cargando..." loading={loading}>
      <Servicios servicios={serviciosData} doctorId={doctor_id} onDelete={onDelete} onUpdate={onUpdate} promocionesRestantes={promocionesRestantes} doctorEspecializaciones={medicoParamsData?.especializaciones ?? []}/>
    </Loading>
  );
};


PromocionesMedico.propTypes = {
  doctorId: PropTypes.string.isRequired,
  loadServices: PropTypes.func.isRequired,
  updateService: PropTypes.func.isRequired,
  deleteService: PropTypes.func.isRequired,
}

export default PromocionesMedico;
