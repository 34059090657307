import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash';
import { TableHead, TableSortLabel } from "@material-ui/core";
import Button from "components/CustomButtons/Button";

import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CustomTableCellCrud from "components/Admin/CRUD/CustomTableCellCrud";

import TablePagination from '@material-ui/core/TablePagination';
import AddIcon from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper'

import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Alert } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';

import Loading from 'components/Loading';
import { TableRowCrud } from 'components/Admin/CRUD/TableRowCrud';
import { FormCrud } from 'components/Admin/CRUD/FormCrud';
import ConfirmDialog from 'components/Admin/CRUD/ConfirmDialog';

import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { AdminLayout } from './AdminLayout';
import { urlListarOficinas, urlCrearOficina, urlEditarOficina, urlEliminarOficina } from 'constants/urls';
import { loadData, createRecord, editRecord, deleteRecord, adminRestartState } from 'redux/actions/aAdmin';
import { SearchTextField } from 'components/forms/input/SearchTextField';
import { TableContainer } from 'components/Table/TableContainer';

let useStyles = makeStyles(styles);

const TableCellHeader = withStyles((theme) => {
  return {
    head: {
      color: theme.palette.common.white,
      fontWeight: "bolder",
    },
  };
})(TableCell);

const rowsPerPageDefault = 20;

const OficinaCrud = () => {
  const classes = useStyles();
  // 0: tabla, 1:crear, 2: editar
  const [view, setView] = useState(0);
  const [itemSelected, setItemSelected] = useState({});

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  //Ordenamiento
  const [orderData, setOrderData] = useState({
    sortBy: 'id', // default sort column
    sortOrder: "asc" // default sort oder
  })

  const { sortBy, sortOrder } = orderData;

  const dispatch = useDispatch();
  const { dataCRUD, dataForeign, dataPagination } = useSelector(state => state.data_admin);

  const { doctores, laboratoristas, secretarias } = dataForeign;
  const { total } = dataPagination;

  //Formulario
  const initialState = {
    doctor_id: "",
    laboratorista_id: "",
    secretaria_id: "",
  };

  const [formValues, setFormValues] = useState(initialState);


  const [formError, setFormError] = useState(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [skipLocal, setSkipLocal] = React.useState(0);

  const [doctorSelected, setDoctorSelected] = useState(null);
  const [laboratoristaSelected, setLaboratoristaSelected] = useState(null);
  const [secretariaSelected, setSecretariaSelected] = useState(null);

  const handleSelectChange = useCallback(
    (name, newValue) => {
      switch (name) {
        case "laboratorista_id":
          setLaboratoristaSelected(newValue);
          break;
        case "doctor_id":
          setDoctorSelected(newValue);
          break;
        case "secretaria_id":
          setSecretariaSelected(newValue);
          break;

        default:
          break;
      }
      setFormValues({
        ...formValues,
        [name]: newValue ? newValue.id : "",
      });
    },
    [formValues],
  )

  const handleInputSearchChange = ({ target }) => {
    setSearch(target.value);
    debouncedSearch(target.value);
  }

  const debouncedSearch = React.useRef(
    _.debounce(async (searchValue) => {
      setSearchValue(searchValue);
    }, 350)
  ).current;

  const initialStateFormComponents = useMemo(() => (
    [
      {
        type: "inputDisabled",
        label: "Id",
        value: itemSelected.id,
        showOnCreate: false
      },
      {
        type: "selectCombo",
        name: "afiliacion",
        label: "Afiliación",
        value: itemSelected.afiliacion,
        defaultValue: false,
        options: [
          {
            value: null,
            label: "Seleccione un valor",
            disabled: true
          },
          {
            value: true,
            label: "Activa"
          },
          {
            value: false,
            label: "Inactiva"
          },
        ],
        // tableProps: {
        //   formatData: (value) => value ? 'Activa' : 'Inactiva',
        //   sort: {
        //     sortColumnName: 'afiliacion',
        //     sortColumnNameDB: 'afiliacion',
        //   }
        // },
      },
      {
        type: "input",
        name: "tiempoPorClienteMinutos",
        label: "Tiempo por paciente minutos",
        value: itemSelected.tiempoPorClienteMinutos ? itemSelected.tiempoPorClienteMinutos : "",
        validationOptions: {
          pattern: {
            value: /^\d+$/,
            message: "El campo tiempoPorClienteMinutos debe ser de tipo entero"
          }
        },
      },
      {
        type: "input",
        name: "costoConsulta",
        label: "Costo consulta",
        value: itemSelected.costoConsulta ? itemSelected.costoConsulta : "",
        validationOptions: {
          validate: (value) => {
            if (isNaN(value)) {
              return "El campo costoConsulta debe ser un valor numérico"
            }
            return true;
          }
        }
      },
      {
        type: "input",
        name: "direccion",
        label: "Dirección",
        value: itemSelected.direccion ? itemSelected.direccion : "",
        validationOptions: {
          required: {
            value: true,
            message: 'El campo direccion es requerido',
          },
        },
      },
      {
        type: "input",
        name: "ciudad",
        label: "Ciudad",
        value: itemSelected.ciudad ? itemSelected.ciudad : "",
        validationOptions: {
          required: {
            value: true,
            message: 'El campo ciudad es requerido',
          },
        },
      },
      {
        type: "input",
        name: "provincia",
        label: "provincia",
        value: itemSelected.provincia ? itemSelected.provincia : "",
        validationOptions: {
          required: {
            value: true,
            message: 'El campo provincia es requerido',
          },
        },
      },
      {
        type: "input",
        name: "pais",
        label: "País",
        value: itemSelected.pais ? itemSelected.pais : "",
        validationOptions: {
          required: {
            value: true,
            message: 'El campo pais es requerido',
          },
        },
      },
      {
        type: "input",
        name: "codigoZip",
        label: "Código Zip",
        value: itemSelected.codigoZip ? itemSelected.codigoZip : "",
      },
      {
        type: "input",
        name: "referenciasFisicas",
        label: "Referencias Físicas",
        value: itemSelected.referenciasFisicas ? itemSelected.referenciasFisicas : "",
      },
      // {
      //   type: "input",
      //   name: "longitud",
      //   label: "Longitud",
      //   value: itemSelected.longitud ? itemSelected.longitud : "",
      //   validationOptions: {
      //     validate: (value) => {
      //       if (isNaN(value)) {
      //         return "El campo longitud debe ser un valor numérico"
      //       }
      //       return true;
      //     }
      //   }
      // },
      // {
      //   type: "input",
      //   name: "latitud",
      //   label: "latitud",
      //   value: itemSelected.latitud ? itemSelected.latitud : "",
      //   validationOptions: {
      //     validate: (value) => {
      //       if (isNaN(value)) {
      //         return "El campo latitud debe ser un valor numérico"
      //       }
      //       return true;
      //     }
      //   }
      // },
      {
        type: "select",
        name: "doctorId",
        label: "Doctor ID",
        value: doctorSelected,
        optionsLabel: (option) => (option ? `${option.id} / ${option.nombres} ${option.apellidos}` : null),
        renderOption: (option) => (
          <span>
            {` ${option.id} / ${option.nombres} ${option.apellidos}`}
          </span>
        ),
        options: doctores,
        handleSelect: (value) => handleSelectChange("doctor_id", value),
      },
      {
        type: "select",
        name: "secretariaId",
        label: "Secretaria ID",
        value: secretariaSelected,
        optionsLabel: (option) => (option ? `${option.id} / ${option.nombres} ${option.apellidos}` : null),
        renderOption: (option) => (
          <span>
            {` ${option.id} / ${option.nombres} ${option.apellidos}`}
          </span>
        ),
        options: secretarias,
        handleSelect: (value) => handleSelectChange("secretaria_id", value),
      },
    ]
  ), [itemSelected, doctorSelected, secretariaSelected, doctores, secretarias, handleSelectChange]);

  const handleChangePage = useCallback(
    (event, newPage) => {
      if (page > newPage) {
        setSkipLocal(skipLocal - rowsPerPage);
      } else {
        setSkipLocal(skipLocal + rowsPerPage);
      }
      setPage(newPage);
    },
    [skipLocal, page, rowsPerPage],
  )

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
      setSkipLocal(0);
    },
    [],
  )

  const handleClickCreate = useCallback(
    () => {
      setItemSelected({});
      setFormError(null);
      setDoctorSelected(null);
      setSecretariaSelected(null);
      setLaboratoristaSelected(null);
      setView(1);
    },
    [],
  )

  const handleClickEdit = useCallback(
    (item) => {
      const findDoctor = doctores.find((doc) => doc.id === item.doctorId);
      const findLaboratorista = laboratoristas.find((lab) => lab.id === item.laboratoristaId);
      const findSecretaria = secretarias.find((sec) => sec.id === item.secretariaId);

      setDoctorSelected(findDoctor);
      setLaboratoristaSelected(findLaboratorista);
      setSecretariaSelected(findSecretaria);

      setItemSelected(item);
      setFormError(null);

      setView(2);
    },
    [doctores, secretarias, laboratoristas],
  )

  const handleClickDelete = useCallback(
    item => {
      setItemSelected(item);
      setOpenConfirmDialog(true);
    },
    [],
  )

  const handleClickReturn = useCallback(
    () => {
      setFormError(null);
      setItemSelected({});
      setView(0);
    },
    [],
  )

  const onSubmitForm = useCallback(
    async (data, type) => {
      let dataForm = { ...data };

      if (doctorSelected) {
        dataForm = {
          ...dataForm,
          doctorId: doctorSelected.id
        }
      }
      if (laboratoristaSelected) {
        dataForm = {
          ...dataForm,
          laboratoristaId: laboratoristaSelected.id
        }
      }
      if (secretariaSelected) {
        dataForm = {
          ...dataForm,
          secretariaId: secretariaSelected.id
        }
      }

      if (type === "editar") {
        (!loading) && setLoading(true);
        let respuesta = await dispatch(editRecord(urlEditarOficina, itemSelected.id, dataForm));
        setLoading(false);
        const { transaccion, mensaje } = respuesta;
        !transaccion ? setError(mensaje) : setError(false);
      } else if (type === "crear") {
        (!loading) && setLoading(true);
        let respuesta = await dispatch(createRecord(urlCrearOficina, dataForm));
        setLoading(false);
        const { transaccion, mensaje } = respuesta;
        transaccion && setItemSelected({});
        !transaccion ? setError(mensaje) : setError(false);
      }
    },
    [doctorSelected, secretariaSelected, laboratoristaSelected, loading, itemSelected, dispatch],
  )

  const handleConfirmDelete = useCallback(
    async () => {
      (!loading) && setLoading(true);
      let respuesta = await dispatch(deleteRecord(urlEliminarOficina, itemSelected.id));
      setLoading(false);
      const { transaccion, mensaje } = respuesta;
      !transaccion ? setError(mensaje) : setError(false);
    },
    [loading, itemSelected, dispatch],
  )

  //Ordenamiento
  const handleRequestSort = useCallback(
    async (sortByColumn) => {
      let sortOrderNew;
      if (!sortBy || sortByColumn !== sortBy) {
        sortOrderNew = "asc";
        setOrderData({
          sortBy: sortByColumn, // default sort column
          sortOrder: sortOrderNew // default sort oder
        })
      } else {
        if (sortByColumn === sortBy) {
          sortOrderNew = sortOrder === "asc" ? "desc" : "asc"
          setOrderData({
            sortBy: sortByColumn, // default sort column
            sortOrder: sortOrderNew// default sort oder
          })
        }
      }
    },
    [sortBy, sortOrder],
  );

  const titulosTable = [
    { title: "Id", onClickSort: () => handleRequestSort("id"), columnName: "id" },
    { title: "Afiliación", onClickSort: () => handleRequestSort("afiliacion"), columnName: "afiliacion" },
    { title: "Tiempo Por Paciente Minutos", onClickSort: () => handleRequestSort("tiempo_por_cliente_minutos"), columnName: "tiempo_por_cliente_minutos" },
    { title: "Costo Consulta", onClickSort: () => handleRequestSort("costo_consulta"), columnName: "costo_consulta" },
    { title: "Dirección", onClickSort: () => handleRequestSort("direccion"), columnName: "direccion" },
    { title: "Ciudad", onClickSort: () => handleRequestSort("ciudad"), columnName: "ciudad" },
    { title: "Provincia", onClickSort: () => handleRequestSort("provincia"), columnName: "provincia" },
    { title: "Pais", onClickSort: () => handleRequestSort("pais"), columnName: "pais" },
    { title: "Codigo Zip", onClickSort: () => handleRequestSort("codigo_zip"), columnName: "codigo_zip" },
    { title: "Referencias Físicas", onClickSort: () => handleRequestSort("referencias_fisicas"), columnName: "referencias_fisicas" },
    { title: "Longitud", onClickSort: () => handleRequestSort("longitud"), columnName: "longitud" },
    { title: "Latitud", onClickSort: () => handleRequestSort("latitud"), columnName: "latitud" },
    { title: "Doctor Id", onClickSort: () => handleRequestSort("doctor_id"), columnName: "doctor_id" },
    { title: "Secretaria Id", onClickSort: () => handleRequestSort("secretaria_id"), columnName: "secretaria_id" },
    // {title: "Activated", onClickSort: () => handleRequestSort("activated"), columnName: "activated"},
    { title: "Opciones" },
  ];

  const initLoadData = useCallback(
    async ({ skip = 0, take = rowsPerPageDefault, search = '', sortBy = 'fecha_reserva', sort = 'DESC' } = {}) => {
      setLoading(true);
      let respuesta = await dispatch(loadData({ url: urlListarOficinas, skip, take, search, sortBy, sort }));
      setLoading(false);
      const { transaccion, mensaje } = respuesta;

      !transaccion ? setError(mensaje) : setError(false);
    },
    [dispatch],
  )

  useEffect(() => {
    initLoadData({ skip: skipLocal, take: rowsPerPage, search: searchValue, sortBy, sort: sortOrder });
  }, [rowsPerPage, skipLocal, initLoadData, sortOrder, sortBy, searchValue]);

  useEffect(() => {
    return () => {
      dispatch(adminRestartState())
    }
  }, [dispatch])

  return (
    <AdminLayout viewState={view}>
      <div>
        <ConfirmDialog title={"Confirmación de eliminación"} open={openConfirmDialog}
          setOpen={setOpenConfirmDialog} onConfirm={handleConfirmDelete}  >
          <div>¿Esta seguro de eliminar este registro?</div>
        </ConfirmDialog>
        {
          formError &&
          <Alert severity="error">{formError}</Alert>
        }
        {
          error &&
          <Alert severity="error">{error}</Alert>
        }
        {
          view === 0 ?

            <div>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonMargin}
                  onClick={handleClickCreate}
                  startIcon={<AddIcon />}
                >
                  Nuevo
                </Button>
              </div>

              {/* Botón buscar */}
              <SearchTextField
                value={search}
                onChange={handleInputSearchChange}
                style={{ marginTop: '25px', marginBottom: '25px' }}
              />
              <Loading title="Cargando..." loading={loading}>

                <TableContainer component={Paper}>

                  <Table className={classes.table}>

                    <TableHead className={classes["warningTableHeader"]}>

                      <TableRow className={classes.tableHeadRow}>
                        {titulosTable &&
                          titulosTable.map((item, key) => (
                            item.onClickSort ?
                              <TableCellHeader key={key}>
                                <TableSortLabel
                                  active={sortBy === item.columnName}
                                  direction={sortOrder}
                                  onClick={item.onClickSort}
                                  hideSortIcon={sortBy !== item.columnName}
                                  className={classes.tableCell + " " + classes.tableHeadCell}
                                >
                                  {item.title}
                                </TableSortLabel>
                              </TableCellHeader>
                              :
                              <TableCellHeader
                                key={key}
                                className={classes.tableCell + " " + classes.tableHeadCell}
                              >
                                {item.title}
                              </TableCellHeader>
                          ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {
                        dataCRUD &&
                        dataCRUD.map((item) => (
                          <TableRowCrud key={item.id} keyValue={item.id} setView={setView} item={item}
                            handleEdit={handleClickEdit} handleDelete={handleClickDelete}>
                            <CustomTableCellCrud
                              data={item.id}
                            />
                            <CustomTableCellCrud
                              data={item.afiliacion ? 'Activa' : 'Inactiva'}
                            />
                            <CustomTableCellCrud
                              data={item.tiempoPorClienteMinutos}
                            />
                            <CustomTableCellCrud
                              data={item.costoConsulta}
                            />
                            <CustomTableCellCrud
                              data={item.direccion}
                            />
                            <CustomTableCellCrud
                              data={item.ciudad}
                            />
                            <CustomTableCellCrud
                              data={item.provincia}
                            />
                            <CustomTableCellCrud
                              data={item.pais}
                            />
                            <CustomTableCellCrud
                              data={item.codigoZip}
                            />
                            <CustomTableCellCrud
                              data={item.referenciasFisicas}
                            />
                            <CustomTableCellCrud
                              data={item.longitud}
                            />
                            <CustomTableCellCrud
                              data={item.latitud}
                            />
                            <CustomTableCellCrud
                              data={item.doctorId}
                            />
                            <CustomTableCellCrud
                              data={item.secretariaId}
                            />

                          </TableRowCrud>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Loading>
              <TablePagination
                rowsPerPageOptions={[10, 20, 100]}
                component="div"
                count={total ? +total : 10}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
            :
            <FormCrud setView={setView} edit={view === 2 ? true : false}
              formComponents={initialStateFormComponents}
              onSubmitForm={onSubmitForm} handleReturn={handleClickReturn} isLoading={loading}
              hasError={error}
            />
        }

      </div>
    </AdminLayout>

  )
}

export default OficinaCrud;
