import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import Alert from '@material-ui/lab/Alert'
import Button from "components/CustomButtons/Button.js";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from '@material-ui/core/Select';
import { makeStyles } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import TextField from '@material-ui/core/TextField';
import { SET_COLOR_ESTADO } from "constants/colores";
import Link from '@material-ui/core/Link';
import Loading from "../../Loading";
import ConfirmDialog from "components/Admin/CRUD/ConfirmDialog";

import moment from "moment";

//Redux
import { updateCitaEstado } from "redux/actions/aCitas";
import { useDispatch } from "react-redux";
import { Checkbox, MenuItem } from "@material-ui/core";
import { setCliente } from 'redux/actions/aClientes';
import { primaryColor } from "assets/jss/material-dashboard-react";
import { computeTextColor } from "utils/styles/color";
import { CheckIcon } from "components/icons/CheckIcon";
import { formatPatientFiliationData2 } from "utils/paciente/paciente";
import { reservaCalcularPagoPendiente } from "utils/calculos";
import { UserRoleTypes } from "constants/constants";
import { setReservationPaidState } from "redux/actions/aCitas";
import { cancelReservaDoctor } from "redux/actions/aCitas";

moment.locale("es");
const useStyles = makeStyles(styles);

export const CitasTableRow = ({ cita, role = UserRoleTypes.doctor }) => {

  const history = useHistory();
  const [loading, setloading] = useState(false);
  const [loadingRazon, setloadingRazon] = useState(false);

  const [razonCancelacionEnabled, setRazonCancelacionEnabled] = useState(false);

  const [razonSelect, setRazonSelect] = useState("No disponibilidad");
  const [otraRazonEnabled, setRazonEnabled] = useState(false);
  const [otraRazonValue, setRazonValue] = useState("");
  const [error, setError] = useState(null);

  // Payment
  const pendingPayment = useMemo(() => reservaCalcularPagoPendiente(cita.r_precio, cita.r_descuento, cita.r_pago_total), [cita]);
  const [openConfirmPaymentDialog, setOpenConfirmPaymentDialog] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleRadioChange = (event) => {
    // console.log("Event", event.target.value);

    if (event.target.value === "RECHAZADO") {
      setRazonCancelacionEnabled(true);
      return;
    }

    setloading(true);
    dispatch(updateCitaEstado(cita.r_id, event.target.value)).then(() =>
      setloading(false)
    );
  }
  const handleSelectRazon = (event) => {
    const value = event.target.value;
    console.log(value);
    setRazonSelect(event.target.value);
    if (value === "Otro") {
      setRazonEnabled(true)
    } else {
      setRazonEnabled(false)
    };
  }
  const handleChangeTextRazon = (event) => {
    setRazonValue(event.target.value);
  }

  //Confirmar razon no disponibilidad
  const handleConfirmarRazon = () => {
    setloadingRazon(true);
    let razon
    if (otraRazonEnabled) {
      if (otraRazonValue.length === 0) {
        return setError("Debe llenar el campo de la descripción de la razón")
      }
      razon = `Otro (${otraRazonValue})`;
    } else {
      razon = razonSelect;
    }

    dispatch(cancelReservaDoctor(cita, razon)).then(() => {
      setloadingRazon(false);
      setRazonCancelacionEnabled(false);
    });

  }
  const desableRButton =
    moment(new Date()).isAfter(cita.r_fin) ||
    cita.r_estado_reserva.toUpperCase() === "CANCELADO";

  const onClickPacienteNombre = () => {
    const dataPaciente = formatPatientFiliationData2(cita);
    localStorage.setItem('setCliente', 'true');
    dispatch(setCliente(dataPaciente));
    history.push('/medico/historia-clinica');
  }

  const handleConfirmPayment = () => {
    if (pendingPayment === 0) return
    setOpenConfirmPaymentDialog(true);
  }

  const confirmPayment = () => {
    dispatch(setReservationPaidState(cita.r_id, role));
  }

  const fontColor = useMemo(() => cita.s_background_color ? computeTextColor(cita.s_background_color, true) : '#ffffff', [cita]);

  return (
    <>
      <ConfirmDialog
        title={"¿Desea registrar este cita como pagada?"}
        open={openConfirmPaymentDialog}
        setOpen={setOpenConfirmPaymentDialog}
        onConfirm={confirmPayment}
      >
        <div>Este registro no se puede borrar</div>
      </ConfirmDialog>

      <TableRow key={cita.r_id} className={classes.tableBodyRow}>
        {
          role === UserRoleTypes.secretaria &&
          <TableCell className={classes.tableCell}>
            {`${cita.d_titulo_honorifico} ${cita.d_nombres} ${cita.d_apellidos}`}
          </TableCell>
        }

        <TableCell
          className={classes.tableCell}
        >
          {
            (cita.r_cedula_tercera_persona)
              ? (
                <div
                >
                  {`${cita.r_nombre_tercera_persona}`}
                </div>
              )
              : (
                role === UserRoleTypes.secretaria
                  ?
                  <>
                    {`${cita.nombres} ${cita.apellidos}`}
                  </>
                  :
                  <Link
                    style={{ fontWeight: "bold" }}
                    href="#"
                    onClick={() => onClickPacienteNombre()}
                    component="button"
                    variant="body2"
                    color="primary"
                  >
                    {`${cita.nombres} ${cita.apellidos}`}
                  </Link>
              )
          }

        </TableCell>
        <TableCell className={classes.tableCell}>{cita.r_cedula_tercera_persona ?? cita.identidad}</TableCell>
        <TableCell className={classes.tableCell}>
          {moment(cita.r_inicio).local().format("DD-MM-YYYY")}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {`${moment(cita.r_inicio).format("HH:mm")} / ${moment(
            cita.r_fin
          ).format("HH:mm")}`}
        </TableCell>
        <TableCell className={classes.tableCell}>
          <FiberManualRecordIcon
            style={{
              color: SET_COLOR_ESTADO(cita.r_estado_reserva.toUpperCase()),
            }}
          />
        </TableCell>

        <TableCell className={classes.tableRowCenter}>
          <Loading loading={loading}>
            <RadioGroup
              color="primary"
              aria-label="quiz"
              name="quiz"
              value={cita.r_estado_reserva.toUpperCase()}
              style={{}}
              onChange={(event) => {
                handleRadioChange(event);
              }}
            >
              <FormControlLabel
                value="RECHAZADO"
                control={<Radio color="secondary" disabled={desableRButton} />}
                label="Cancelado"
                style={{ marginRight: '0' }}
              />
              <FormControlLabel
                value="APROBADO"
                control={<Radio color="secondary" disabled={desableRButton} />}
                label="Aprobado"
                style={{ marginRight: '0' }}
              />
            </RadioGroup>
          </Loading>


        </TableCell>
        <TableCell className={classes.tableCell}>
          {
            // cita.r_estado_reserva.toUpperCase() === "RECHAZADO" && (cita.r_razon_no_disponibilidad === "" || cita.r_razon_no_disponibilidad === null)
            razonCancelacionEnabled
              ?
              <Loading loading={loadingRazon}>
                <div>
                  <div>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={razonSelect}
                      onChange={handleSelectRazon}
                    >
                      <MenuItem value={"No disponibilidad"}>No disponibilidad</MenuItem>
                      <MenuItem value={"Reagendamiento"}>Reagendamiento</MenuItem>
                      <MenuItem value={"Redireccionamiento"}>Redireccionamiento</MenuItem>
                      <MenuItem value={"Otro"}>Otro</MenuItem>
                    </Select>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    {
                      otraRazonEnabled &&
                      <TextField
                        id="outlined-textarea"
                        label="Descripción de la razón"
                        multiline
                        variant="outlined"
                        minRows={4}
                        onChange={handleChangeTextRazon}
                        value={otraRazonValue}
                      />
                    }
                  </div>
                  {
                    error &&
                    <div>
                      <Alert severity="error">{error}</Alert>
                    </div>
                  }
                  <div>
                    <Button color="warning" onClick={() => handleConfirmarRazon()}>
                      Guardar
                    </Button>
                  </div>
                </div>

              </Loading>
              :
              cita.r_razon_no_disponibilidad
          }
        </TableCell>
        {/* Créditos */}
        {
          // cita.d_med_activo &&
          <TableCell className={`${classes.tableCell} ${classes.tableRowCreditosCero}`}>{cita.r_creditos > 0 ? (<CheckIcon style={{ fontSize: 60 }} />) : ''}</TableCell>
        }

        {/* Pago pendiente */}
        <TableCell className={`${classes.tableCell} ${classes.tableRowCreditosCero}`}>
          <>
            <div style={{ textAlign: 'center' }}>
              ${reservaCalcularPagoPendiente(cita.r_precio, cita.r_descuento, cita.r_pago_total).toFixed(2)}
            </div>

            <div className={classes.tableRowCenter}>
              <FormControlLabel control={<Checkbox checked={pendingPayment === 0} onClick={handleConfirmPayment} color="primary" />} label="Pagado" />
            </div>

          </>

        </TableCell>
        <TableCell
          className={`${classes.tableCell} ${classes.tableRowCitaServicio}`}
          style={{ backgroundColor: cita.s_background_color ? cita.s_background_color : primaryColor[0], color: fontColor }}>{cita.r_servicio ? cita.r_servicio : 'Consulta'}
        </TableCell>
      </TableRow >
    </>

  );
};

CitasTableRow.propTypes = {
  cita: PropTypes.object.isRequired,
};
