import React from 'react';
import { Typography, Card } from '@material-ui/core';

//REDUX
import { useSelector } from 'react-redux';

// const useStyles = makeStyles(theme => ({
//   root: {
//     '& .MuiTextField-root': {
//       margin: theme.spacing(1),
//     },
//   },
// }));

const Resumen = ({ selectedHorario}) => {

  // const classes = useStyles();
  const clientes = useSelector(state => state.data_clientes.data);
  const informacion = {...selectedHorario,...clientes};

  return (
    <Card style={{ width: '600px', padding: '20px' }}>
      <Typography
        style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}
        display="inline"
        variant="h5"
      >
        Resumen de la cita
      </Typography>
      <Typography style={{ display: 'flex', justifyContent: 'center', padding: '10px' }} variant="body2">
        Paciente
      </Typography>
      <Typography style={{ display: 'flex', justifyContent: 'center' }} variant="subtitle1">
        <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Cédula: </span>
        {informacion.cedula_identidad}
      </Typography>
      <Typography style={{ display: 'flex', justifyContent: 'center' }} variant="subtitle1">
        <span style={{ fontWeight: 'bold', marginRight: '5px' }}> Nombre: </span>
        {informacion.nombres} {informacion.apellidos}
      </Typography>
      <Typography style={{ display: 'flex', justifyContent: 'center', padding: '10px' }} variant="body2">
        Lugar
      </Typography>
      <Typography style={{ display: 'flex', justifyContent: 'center', padding: '10px' }} variant="subtitle1">
        {informacion.referencia_oficina}
      </Typography>
      <Typography style={{ display: 'flex', justifyContent: 'center', padding: '10px' }} variant="body2">
        Fecha y Hora
      </Typography>
      <Typography style={{ display: 'flex', justifyContent: 'center', padding: '10px' }} variant="subtitle1">
        {informacion.fechaSeleccionada} a las {informacion.hora}
      </Typography>
      <Typography style={{ display: 'flex', justifyContent: 'center', padding: '10px' }} variant="body2">
        Servicio Seleccionado
      </Typography>
      <Typography style={{ display: 'flex', justifyContent: 'center', padding: '10px' }} variant="subtitle1">
       {informacion.servicio_nombre}
      </Typography>
      <Typography style={{ display: 'flex', justifyContent: 'center', padding: '10px' }} variant="body2">
        Especialidad
      </Typography>
      <Typography style={{ display: 'flex', justifyContent: 'center', padding: '10px' }} variant="subtitle1">
       {informacion.especializacion_nombre}
      </Typography>
    
      
    </Card>
  );
};

export default Resumen;
