import Notificacion from "components/ui/Notificacion";
import { createAction } from "redux-actions";
import {
  urlListarCitas,
  urlUpdateCitaEstadoDisponible,
  urlUpdateEstadoCita,
  urlListarCitasOficina,
  urlUpdateRazonNoDisponibilidad,
  urlSaveReservationPaidState,
  urlCancelReservaDoctor,
  urlDoctorCrearReserva,
} from "../../constants/urls";
import { getHorariosReserva } from "redux/actions/aOficinas";
import moment from "moment";
import { jwt } from "./aLogin";
import { ReservaEstadoType } from "types";

export const citas = createAction("SET_DATA_CITAS");
export const isLoadingCitas = createAction("LOADING_CITAS");
export const hasErrorCitas = createAction("ERROR_CITAS");
export const updateEstadoDisponible = createAction(
  "UPDATE_ESTADO_DISPONIBLE_CITA"
);
export const updateRazonNoDisponible = createAction(
  "UPDATE_RAZON_NO_DISPONIBLE_CITA"
);
export const updateEstado = createAction("UPDATE_ESTADO_CITA");

export const consultarCitas = (doctor_id, fecha, fechaFinal) => async (
  dispatch,
  getstate
) => {
  dispatch(isLoadingCitas());
  const state = getstate();

  const { token } = state;

  var requestOptions = {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  return fetch(
    `${urlListarCitas}${doctor_id}?fecha=${moment(fecha).format(
      "YYYY-MM-DD"
    )}&fechaFinal=${moment(fechaFinal).format("YYYY-MM-DD")}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((datos) => {
      const { token = true } = datos;
      if (!token) {
        dispatch(jwt(""));
        return;
      }
      if (datos.transaccion) {
        const { data } = datos;
        dispatch(citas(data));
      } else {
        dispatch(hasErrorCitas(`${datos.mensaje}`));
        dispatch(citas([]));
      }
    })
    .catch((e) => {
      dispatch(hasErrorCitas(`Error en el servicio, ${e}`));
    });
};

export const consultarCitasOficina = (
  oficina_id,
  fechaInicial,
  fechaFinal
) => async (dispatch, getstate) => {
  dispatch(isLoadingCitas());
  const state = getstate();

  const { token } = state;

  var requestOptions = {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  return fetch(
    `${urlListarCitasOficina}${oficina_id}?fecha=${moment(fechaInicial).format(
      "YYYY-MM-DD"
    )}&fechaFinal=${moment(fechaFinal).format("YYYY-MM-DD")}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((datos) => {
      const { token = true } = datos;
      if (!token) {
        dispatch(jwt(""));
        return;
      }
      if (datos.transaccion) {
        const { data } = datos;
        dispatch(citas(data));
      } else {
        dispatch(hasErrorCitas(`${datos.mensaje}`));
        dispatch(citas([]));
      }
    })
    .catch((e) => {
      dispatch(hasErrorCitas(`Error en el servicio, ${e}`));
    });
};

export const agregarCita = (data) => async (dispatch, getstate) => {
  dispatch(isLoadingCitas());

  try {
    const state = getstate();
    const { token } = state;

    const raw = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: raw,
    };

    return fetch(`${urlDoctorCrearReserva}`, requestOptions)
      .then((response) => response.json())
      .then((datos) => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(""));
          return { transaccion: false };
        }
        if (datos.transaccion) {
          Notificacion({
            type: "success",
            text: "Cita guardada exitosamente.",
          });
          dispatch(citas(datos.data));
          dispatch(getHorariosReserva());
        } else {
          Notificacion({ type: "error", text: datos.mensaje });
          dispatch(hasErrorCitas(datos.mensaje));
        }
        return datos;
      });
  } catch (error) {
    dispatch(
      hasErrorCitas("No se pudo guardar la información recargue la pagina ")
    );
    return {
      transaccion: false,
      mensaje: "No se pudo guardar la información recargue la pagina",
    };
  }
};

export const updateCitas = (data, cita_id) => async (dispatch, getstate) => {
  dispatch(isLoadingCitas());
  try {
    const state = getstate();
    const { token } = state;

    const raw = JSON.stringify(data);
    var requestOptions = {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: raw,
    };

    return fetch(`${urlListarCitas}${cita_id}`, requestOptions)
      .then((response) => response.json())
      .then((datos) => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(""));
          return;
        }
        if (datos.transaccion) {
          dispatch(citas(datos.data));
        } else {
          dispatch(hasErrorCitas(datos.mensaje));
        }
      });
  } catch (error) {
    dispatch(
      hasErrorCitas("No se pudo guardar la información recargue la pagina ")
    );
  }
};

export const updateCitaEstadoDisponible = (
  cita_id,
  estadoDisponibleNuevo
) => async (dispatch, getstate) => {
  dispatch(isLoadingCitas());
  try {
    const state = getstate();
    const { token } = state;

    const raw = JSON.stringify({
      disponible: estadoDisponibleNuevo,
    });

    var requestOptions = {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: raw,
    };

    return fetch(`${urlUpdateCitaEstadoDisponible}${cita_id}`, requestOptions)
      .then((response) => response.json())
      .then((datos) => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(""));
          return;
        }
        if (datos.transaccion) {
          dispatch(updateEstadoDisponible(datos.data));
        } else {
          dispatch(hasErrorCitas(datos.mensaje));
        }
      });
  } catch (error) {
    dispatch(
      hasErrorCitas("No se pudo guardar la información recargue la pagina ")
    );
  }
};

export const updateCitaEstado = (
  cita_id,
  estadoNuevo,
  razonNoDisponibilidad = ""
) => async (dispatch, getstate) => {
  // dispatch(isLoadingCitas());
  try {
    const state = getstate();
    const { token } = state;

    if (estadoNuevo !== "RECHAZADO") razonNoDisponibilidad = "";

    const raw = JSON.stringify({
      estado_reserva: estadoNuevo,
      razonNoDisponibilidad,
    });

    var requestOptions = {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: raw,
    };

    return fetch(`${urlUpdateEstadoCita}${cita_id}`, requestOptions)
      .then((response) => response.json())
      .then((datos) => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(""));
          return;
        }
        if (datos.transaccion) {
          dispatch(updateEstado(datos.data));
        } else {
          Notificacion({ type: "error", text: datos.mensaje });
          dispatch(hasErrorCitas(datos.mensaje));
        }
      });
  } catch (error) {
    dispatch(
      hasErrorCitas("No se pudo guardar la información recargue la pagina ")
    );
  }
};

export const cancelReservaDoctor = (
  cita,
  razonNoDisponibilidad = ""
) => async (dispatch, getstate) => {
  // dispatch(isLoadingCitas());
  try {
    const state = getstate();
    const { token } = state;


    const raw = JSON.stringify({ razonNoDisponibilidad })

    var requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: raw,
    };

    return fetch(`${urlCancelReservaDoctor}${cita.r_id}`, requestOptions)
      .then((response) => response.json())
      .then((datos) => {
        if (datos.transaccion) {
          dispatch(updateEstado({
            id: cita.r_id,
            estado_reserva: ReservaEstadoType.Rechazado,
            disponible: true,
            razonNoDisponibilidad,
          }));
        } else {
          Notificacion({ type: "error", text: datos.mensaje });
          dispatch(hasErrorCitas(datos.mensaje));
        }
      });
  } catch (error) {
    dispatch(
      hasErrorCitas("No se pudo guardar la información recargue la pagina ")
    );
  }
};

export const updateRazonNoDisponibilidad = (
  cita_id,
  razonNoDisponibilidad = ""
) => async (dispatch, getstate) => {
  // dispatch(isLoadingCitas());
  try {
    const state = getstate();
    const { token } = state;

    const raw = JSON.stringify({
      razonNoDisponibilidad,
    });

    var requestOptions = {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: raw,
    };

    return fetch(`${urlUpdateRazonNoDisponibilidad}${cita_id}`, requestOptions)
      .then((response) => response.json())
      .then((datos) => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(""));
          return;
        }
        if (datos.transaccion) {
          dispatch(updateRazonNoDisponible(datos.data));
        } else {
          dispatch(hasErrorCitas(datos.mensaje));
        }
      });
  } catch (error) {
    dispatch(
      hasErrorCitas("No se pudo guardar la información recargue la pagina ")
    );
  }
};

// Payment
export const setReservationPaidState = (
  citaId,
  role,
) => async (dispatch, getstate) => {
  try {
    dispatch(isLoadingCitas());
    const state = getstate();
    const { token } = state;
    const { data: citasData } = state.datos_citas;

    const queryParams = { role };
    const searchParams = new URLSearchParams(queryParams);

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${urlSaveReservationPaidState}${citaId}?${searchParams.toString()}`, requestOptions);
    const { transaccion, data } = await response.json();

    if (transaccion)
      Notificacion({ type: "success", text: 'Reserva actualizada correctamente', });
    else
      Notificacion({ type: "error", text: 'Error al actualizar la reserva', });

    const newData = citasData.map(cita => cita.r_id === citaId ? { ...cita, r_pago_total: data.pago_total } : cita)

    dispatch(citas(newData));

  } catch (error) {
    console.log(error)
    dispatch(
      hasErrorCitas("No se pudo guardar la información recargue la pagina ")
    );
  }
};