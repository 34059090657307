import React from 'react'
import { ErrorMessage } from "@hookform/error-message";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/ReactHookForm/CustomInput";
import { Controller } from "react-hook-form";
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles(() => ({
  formControl: {
    width: "100%",
    marginBottom: "25px",
  },
}));

export const SignosVitalesAntropometriaForm = ({ control, errors, IMC }) => {

  const classes = useStyles();

  return (
    <GridContainer>
      {/* sv_pulso */}
      <GridItem xs={12} sm={12} md={2}>
        <FormControl error className={classes.formControl}>
          <Controller
            name={`sv_pulso`}
            control={control}
            defaultValue={''}
            as={<TextField />}
            error={errors[`sv_pulso`] ? true : false}
            helperText={""}
            label="Pulso (X')"
            style={{ margin: 8 }}
            fullWidth
            margin="normal"
            rules={{
              validate: (value) => {
                if (isNaN(value)) {
                  return "Debe ser un valor numérico";
                }
                return true;
              }
            }}
          />
          <ErrorMessage
            errors={errors}
            name='sv_pulso'
            render={({ message }) => (
              <FormHelperText
                className={classes.labelError}
              >
                {message}
              </FormHelperText>
            )}
          />
        </FormControl>
      </GridItem>

      {/* sv_pres_sist */}
      <GridItem xs={12} sm={12} md={2}>
        <FormControl error className={classes.formControl}>
          <Controller
            name={`sv_pres_sist`}
            control={control}
            defaultValue={''}
            as={<TextField />}
            error={errors[`sv_pres_sist`] ? true : false}
            helperText={""}
            label="Pres Sist (mmHg)"
            style={{ margin: 8 }}
            fullWidth
            margin="normal"
            rules={{
              validate: (value) => {
                if (isNaN(value)) {
                  return "Debe ser un valor numérico";
                }
                return true;
              }
            }}
          />
          <ErrorMessage
            errors={errors}
            name='sv_pres_sist'
            render={({ message }) => (
              <FormHelperText
                className={classes.labelError}
              >
                {message}
              </FormHelperText>
            )}
          />
        </FormControl>
      </GridItem>

      {/* sv_pres_dias */}
      <GridItem xs={12} sm={12} md={2}>
        <FormControl error className={classes.formControl}>
          <Controller
            name={`sv_pres_dias`}
            control={control}
            defaultValue={''}
            as={<TextField />}
            error={errors[`sv_pres_dias`] ? true : false}
            helperText={""}
            label="Pres Dias (mmHg) "
            style={{ margin: 8 }}
            fullWidth
            margin="normal"
            rules={{
              validate: (value) => {
                if (isNaN(value)) {
                  return "Debe ser un valor numérico";
                }
                return true;
              }
            }}
          />
          <ErrorMessage
            errors={errors}
            name='sv_pres_dias'
            render={({ message }) => (
              <FormHelperText
                className={classes.labelError}
              >
                {message}
              </FormHelperText>
            )}
          />
        </FormControl>
      </GridItem>

      {/* sv_temp */}
      <GridItem xs={12} sm={12} md={2}>
        <FormControl error className={classes.formControl}>
          <Controller
            name={`sv_temp`}
            control={control}
            defaultValue={''}
            as={<TextField />}
            error={errors[`sv_temp`] ? true : false}
            helperText={""}
            label="Temp (°C)"
            style={{ margin: 8 }}
            fullWidth
            margin="normal"
            rules={{
              validate: (value) => {
                if (isNaN(value)) {
                  return "Debe ser un valor numérico";
                }
                return true;
              }
            }}
          />
          <ErrorMessage
            errors={errors}
            name='sv_temp'
            render={({ message }) => (
              <FormHelperText
                className={classes.labelError}
              >
                {message}
              </FormHelperText>
            )}
          />
        </FormControl>
      </GridItem>

      {/* sv_frec_resp */}
      <GridItem xs={12} sm={12} md={2}>
        <FormControl error className={classes.formControl}>
          <Controller
            name={`sv_frec_resp`}
            control={control}
            defaultValue={''}
            as={<TextField />}
            error={errors[`sv_frec_resp`] ? true : false}
            helperText={""}
            label="Frec Resp (x') "
            style={{ margin: 8 }}
            fullWidth
            margin="normal"
            rules={{
              validate: (value) => {
                if (isNaN(value)) {
                  return "Debe ser un valor numérico";
                }
                return true;
              }
            }}
          />
          <ErrorMessage
            errors={errors}
            name='sv_frec_resp'
            render={({ message }) => (
              <FormHelperText
                className={classes.labelError}
              >
                {message}
              </FormHelperText>
            )}
          />
        </FormControl>
      </GridItem>

      {/* sv_oximetria */}
      <GridItem xs={12} sm={12} md={2}>
        <FormControl error className={classes.formControl}>
          <Controller
            name={`sv_oximetria`}
            control={control}
            defaultValue={''}
            as={<TextField />}
            error={errors[`sv_oximetria`] ? true : false}
            helperText={""}
            label="Oximetría (%)"
            style={{ margin: 8 }}
            fullWidth
            margin="normal"
            rules={{
              validate: (value) => {
                if (isNaN(value)) {
                  return "Debe ser un valor numérico";
                }
                return true;
              }
            }}
          />
          <ErrorMessage
            errors={errors}
            name='sv_oximetria'
            render={({ message }) => (
              <FormHelperText
                className={classes.labelError}
              >
                {message}
              </FormHelperText>
            )}
          />
        </FormControl>
      </GridItem>

      {/* antropometria_peso */}
      <GridItem xs={12} sm={12} md={2}>
        <CustomInput
          formControlProps={{
            error: true,
            className: classes.formControl
          }}
          name={`antropometria_peso`}
          control={control}
          defaultValue={''}
          as={<TextField />}
          error={errors[`antropometria_peso`] ? true : false}
          helperText={""}
          label="Peso (kg)"
          style={{ margin: 8 }}
          fullWidth
          margin="normal"
          rules={{
            validate: (value) => {
              if (isNaN(value)) {
                return "Debe ser un valor numérico";
              }
              return true;
            }
          }}
          errors={errors}
        />
      </GridItem>

      {/* antropometria_talla */}
      <GridItem xs={12} sm={12} md={2}>
        <FormControl error className={classes.formControl}>
          <Controller
            name={`antropometria_talla`}
            control={control}
            defaultValue={''}
            as={<TextField />}
            error={errors[`antropometria_talla`] ? true : false}
            helperText={""}
            label="Talla (cm)"
            style={{ margin: 8 }}
            fullWidth
            margin="normal"
            rules={{
              validate: (value) => {
                if (isNaN(value)) {
                  return "Debe ser un valor numérico";
                }
                return true;
              }
            }}
          />
          <ErrorMessage
            errors={errors}
            name='antropometria_talla'
            render={({ message }) => (
              <FormHelperText
                className={classes.labelError}
              >
                {message}
              </FormHelperText>
            )}
          />
        </FormControl>
      </GridItem>

      {/* antropometria_imc */}
      <GridItem xs={12} sm={12} md={2}>
        <FormControl error className={classes.formControl}>
          <TextField
            style={{ margin: 8 }}
            label="IMC"
            disabled={true}
            value={IMC}
          />
        </FormControl>
      </GridItem>


    </GridContainer>
  )
}
