import { Typography, makeStyles } from '@material-ui/core';
import Loading from 'components/Loading';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { confirmTransaction } from 'redux/actions/aClientes';
import paymentStyle from 'assets/jss/payment/paymentStyle';
import '../../styles/payment/confirmPayment.scss'

const transactionTypes = {
  appointment: 'appointment',
  credits: 'credits',
}

const useStyles = makeStyles(paymentStyle);

export const ConfirmPayment = () => {

  const classes = useStyles();

  const { transactionId, clientTransactionId } = useParams();
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState('')
  const [error, setError] = useState(null);

  const paramsAppointmentApp = useMemo(() => {
    const searchParams = new URLSearchParams(document.location.search);
    const transactionType = searchParams.get('transactionType');

    switch (transactionType) {
      case transactionTypes.appointment:
        if (searchParams.get('reservaId'))
          return {
            reservaId: searchParams.get('reservaId'),
            dataEncrypted: searchParams.get('dataEncrypted'),
            trasactionType: searchParams.get('trasactionType'),
          }

        return {
          canal_reserva: searchParams.get('canal_reserva'),
          cedulaTerceraPersona: searchParams.get('cedulaTerceraPersona'),
          cliente_id: searchParams.get('cliente_id'),
          pagoTotal: searchParams.get('pagoTotal'),
          doctor_id: searchParams.get('doctor_id'),
          empresaId: searchParams.get('empresaId'),
          estado_reserva: searchParams.get('estado_reserva'),
          fin: searchParams.get('fin'),
          inicio: searchParams.get('inicio'),
          nombreTerceraPersona: searchParams.get('nombreTerceraPersona'),
          oficina_id: searchParams.get('oficina_id'),
          paciente: (searchParams.get('paciente') === 'true'),
          paymentType: searchParams.get('paymentType'),
          razon_no_disponibilidad: searchParams.get('razon_no_disponibilidad'),
          servicio_id: searchParams.get('servicio_id'),
          usarMembresia: (searchParams.get('usarMembresia') === 'true'),
          dataEncrypted: searchParams.get('dataEncrypted'),
          transactionType: searchParams.get('transactionType'),
        }

      case transactionTypes.credits:
        return {
          creditItemId: searchParams.get('clientId'),
          dataEncrypted: searchParams.get('dataEncrypted'),
          transactionType: searchParams.get('transactionType'),
        }

      default:
        return null;
    }

  }, []);


  const dispatch = useDispatch();

  const startConfirmTransaction = useCallback(
    async () => {
      try {
        if (paramsAppointmentApp) {
          const { mensaje } = await dispatch(confirmTransaction({ transactionId, clientTransactionId, ...paramsAppointmentApp }));
          setLoading(false);
          setSuccessMessage(mensaje)
        }

      } catch (error) {
        setLoading(false)
        console.log(error)
        setError(error.message)
      }

    },
    [dispatch, clientTransactionId, transactionId, paramsAppointmentApp],
  )

  useEffect(() => {
    startConfirmTransaction();
  }, [startConfirmTransaction])


  return (
    <div className={classes.confirmPaymentContainer}>
      <Loading loading={loading} title={"Cargando información de la transacción, no abandones la página"}>
        <>
          <Typography variant="h4" className='text-message'>
            <h2>
              {error ?? successMessage}
            </h2>
            <p>
              Para continuar por favor cierre esta página (botón "x" o "listo" de la parte superior izquierda).
            </p>
          </Typography>
        </>
      </Loading>
    </div>
  )
}
