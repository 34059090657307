import React from 'react';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import Loading from '../../../Loading';
import Tooltip from '@material-ui/core/Tooltip';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import DeleteIcon from '@material-ui/icons/Delete';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { FormErrorLabel } from 'components/forms/labels/FormErrorLabel';
import { TextFieldCustom } from 'components/forms/input/TextFieldCustom';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
}));

const RecetaVademecumDetailForm = ({ handleClick, errors, handleChange, valores, handleMedDelete, handleDateChange, error, ...props }) => {
  //estilos
  const classes = useStyles();

  return (
    <div
      style={{
        margin: '15px',
      }}
      className={classes.root}
    >
      <Loading title="Cargando..." loading={props.isLoading}>
        <>
          {valores.map((medicina, index) => (
            <div style={{ marginTop: "10px" }} key={index}>
              <GridContainer spacing={2}>
                <GridItem xs={5}>
                  <span style={{ paddingTop: "10px", overflowWrap: "break-word" }}>
                    {
                      `
                      ${!!medicina.medicina.nombre ? medicina.medicina.nombre : ''}
                      ${!!medicina.medicina.composicion ? medicina.medicina.composicion : ''}
                      ${!!medicina.medicina.funcion ? medicina.medicina.funcion : ''}
                      ${!!medicina.medicina.dosificacion ? medicina.medicina.dosificacion : ''}
                      ${!!medicina.medicina.presentacion ? medicina.medicina.presentacion : ''}
                      ${!!medicina.medicina.casa_comercial ? medicina.medicina.casa_comercial : ''}
                      `
                    }
                  </span>
                </GridItem>
                <GridItem xs={3}>
                  <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                    <TextFieldCustom
                      label="Cantidad"
                      id="cantidad"
                      name="cantidad"
                      type="text"
                      fullWidth
                      onChange={e => handleChange(e, index)}
                      value={medicina.cantidad}
                      // error={errors[index] !== undefined ? errors[index].cantidad : false}
                      error={errors[index] !== undefined ? (errors[index].cantidad ? true : false) : false}
                    />
                    {errors[index] !== undefined && errors[index].cantidad && (
                      <FormErrorLabel>{errors[index].cantidad}</FormErrorLabel>
                    )}
                  </div>

                </GridItem>
                <GridItem xs={3}>
                  <div style={{ height: '100%', display: 'flex', alignItems: 'center', marginLeft: '8px' }}>
                    <div>Dosificación</div>
                  </div>

                </GridItem>
                <GridItem xs={1}>
                  <Tooltip
                    style={{ marginTop: '8px', marginLeft: '0' }}
                    id="tooltip-eliminar"
                    title="Eliminar"
                    placement="top"
                  >
                    <IconButton aria-label="Eliminar" onClick={() => handleMedDelete(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </GridItem>

                {/* Segunda columna */}
                <GridItem xs={5} />
                <GridItem xs={3} />
                <GridItem xs={3}>
                  <KeyboardDateTimePicker
                    variant="inline"
                    name='fecha_inicio_uso'
                    ampm={false}
                    label="Inicio (fecha y hora)"
                    value={medicina.fecha_inicio_uso}
                    onChange={(e) => handleDateChange('fecha_inicio_uso', e, index)}
                    minDateMessage={'La fecha debe ser mayor a la fecha actual'}
                    onError={console.log}
                    disablePast
                    format="yyyy/MM/dd HH:mm"
                  />
                  <TextFieldCustom
                    label="Días"
                    id={"dosificacion" + index}
                    name="dias_uso"
                    type="number"
                    fullWidth
                    onChange={e => handleChange(e, index)}
                    value={medicina.dias_uso ?? ''}
                    error={errors[index] !== undefined ? (errors[index].dias_uso ? true : false) : false}
                  />
                  {errors[index] !== undefined && errors[index].dias_uso && (
                    <FormErrorLabel>{errors[index].dias_uso}</FormErrorLabel>
                  )}

                  <TextFieldCustom
                    label="Cada (horas)"
                    id={"cada" + index}
                    name="frecuencia_uso_horas"
                    type="number"
                    fullWidth
                    onChange={e => handleChange(e, index)}
                    value={medicina.frecuencia_uso_horas ?? ''}
                    error={errors[index] !== undefined ? (errors[index].frecuencia_uso_horas ? true : false) : false}
                  />
                  {errors[index] !== undefined && errors[index].frecuencia_uso_horas && (
                    <FormErrorLabel>{errors[index].frecuencia_uso_horas}</FormErrorLabel>
                  )}

                  <TextFieldCustom
                    label="Detalles"
                    id="dosificacion"
                    name="dosificacion"
                    type="text"
                    fullWidth
                    onChange={e => handleChange(e, index)}
                    value={medicina.dosificacion}
                    error={errors[index] !== undefined ? (errors[index].dosificacion ? true : false) : false}
                  />
                  {errors[index] !== undefined && errors[index].dosificacion && (
                    <FormErrorLabel>{errors[index].dosificacion}</FormErrorLabel>
                  )}
                </GridItem>
              </GridContainer>
            </div>
          ))}
        </>
      </Loading>
      {error.mensaje && (
        <Alert severity={`${[error.title]}`}>
          <AlertTitle>{error.title === 'error' ? 'Error' : 'Success'}</AlertTitle>
          {error.mensaje}
        </Alert>
      )}
    </div>
  );
};

export default RecetaVademecumDetailForm;
