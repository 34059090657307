import React, { useMemo, useState } from 'react';
import CRUD from 'components/CRUD/Crud.component';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { urlAdminCliente } from 'constants/urls';
import iconDefaultSVG from "../../../src/assets/images/icons/medico.svg";
import { selectTipoSangreOptions } from 'constants/constants';
import { loadClienteReferenteForeign } from 'redux/actions/aAdmin';
import { startSearchPaises } from 'redux/actions/aClientes';
import { selectEstadoCivilOptions } from 'constants/constants';
import { selectInstruccionOptions } from 'constants/constants';
import { getSegurosPorBusqueda } from 'redux/actions/aMedico';
import { formatDataDB } from 'utils/format';
import { Register } from 'components/Login/Register';
import { RoleTypes } from 'types';

const DoctorCrud = () => {

  const fileField = React.useRef(null);

  const [paisesData, setPaisesData] = useState([]);
  // Cliente referente
  const [clientesData, setClientesData] = useState([]);
  const [segurosData, setSegurosData] = useState([]);


  const dispatch = useDispatch();

  // Buscar paises
  const handleSearchUsers = useCallback(
    async (value) => {
      try {
        const { data } = await dispatch(loadClienteReferenteForeign(0, 30, value));
        setClientesData(data.data);

      } catch (error) {
      }
    },
    [dispatch],
  )

  const handleSearchPaises = useCallback(
    async (value) => {
      try {
        const { data } = await dispatch(startSearchPaises(value));
        setPaisesData(data);
      } catch (error) {
      }
    },
    [dispatch],
  )

  const handleSearchSeguros = useCallback(
    async (value) => {
      try {
        const { data } = await dispatch(getSegurosPorBusqueda(value));
        setSegurosData(data);

      } catch (error) {
      }
    },
    [dispatch],
  )

  const tableData = useMemo(() => [
    {
      type: "imageInput",
      name: "foto_nombre",
      label: "Imagen",
      imgDefault: iconDefaultSVG,
      ref: fileField
    },
    {
      type: "inputDisabled",
      label: "Id",
      name: 'id',
      showOnCreate: false,
      showOnTable: true,
      tableProps: {
        // title: 'Id',
        sort: {
          sortColumnName: 'id',
          sortColumnNameDB: 'id',
        }
      },
    },
    {
      type: "input",
      name: "cedula_representante",
      label: "Cédula Representante",
      tableProps: {
        sort: {
          sortColumnName: 'cedula_representante',
          sortColumnNameDB: 'cedula_representante',
        }
      },
    },
    {
      type: "input",
      name: "cedula_identidad",
      label: "Cédula Identidad",
      tableProps: {
        sort: {
          sortColumnName: 'cedula_identidad',
          sortColumnNameDB: 'cedula_identidad',
        }
      },
      validationOptions: {
        required: {
          value: true,
          message: "El campo cédula de Identidad es requerido",
        },
      },
    },
    {
      type: "input",
      name: "titulo_honorifico",
      label: "Titulo Honorífico",
      tableProps: {
        sort: {
          sortColumnName: 'titulo_honorifico',
          sortColumnNameDB: 'titulo_honorifico',
        }
      },
    },
    {
      type: "input",
      name: "nombres",
      label: "Nombres",
      tableProps: {
        sort: {
          sortColumnName: 'nombres',
          sortColumnNameDB: 'nombres',
        }
      },
      validationOptions: {
        required: {
          value: true,
          message: "El campo nombres es requerido",
        },
      },
    },
    {
      type: "input",
      name: "apellidos",
      label: "Apellidos",
      tableProps: {
        sort: {
          sortColumnName: 'apellidos',
          sortColumnNameDB: 'apellidos',
        }
      },
      validationOptions: {
        required: {
          value: true,
          message: "El campo apellidos es requerido",
        },
      },
    },
    {
      type: "input",
      name: "nombres_padre",
      label: "Nombres Padre",
      tableProps: {
        sort: {
          sortColumnName: 'nombres_padre',
          sortColumnNameDB: 'nombres_padre',
        }
      },
    },
    {
      type: "input",
      name: "nombres_madre",
      label: "Nombres Madre",
      tableProps: {
        sort: {
          sortColumnName: 'nombres_madre',
          sortColumnNameDB: 'nombres_madre',
        }
      },
    },
    {
      type: "input",
      name: "direccion",
      label: "Dirección",
      tableProps: {
        sort: {
          sortColumnName: 'direccion',
          sortColumnNameDB: 'direccion',
        }
      },
    },
    {
      type: "input",
      name: "ciudad",
      label: "Ciudad",
      tableProps: {
        sort: {
          sortColumnName: 'ciudad',
          sortColumnNameDB: 'ciudad',
        }
      },
    },
    {
      type: "autocompleteMultipleV2",
      name: "pais",
      label: "País de residencia",
      multiple: false,
      freeSolo: false,
      dispatchFunction: handleSearchPaises,
      options: paisesData,
    },
    {
      type: "autocompleteMultipleV2",
      name: "nacionalidad",
      label: "País de origen",
      multiple: false,
      freeSolo: false,
      dispatchFunction: handleSearchPaises,
      options: paisesData,
    },
    {
      type: "selectCombo",
      name: "sexo",
      label: "Sexo",
      defaultValue: null,
      tableProps: {
        sort: {
          sortColumnName: 'sexo',
          sortColumnNameDB: 'sexo',
        }
      },
      options: [
        {
          value: null,
          label: "Seleccione un valor",
          disabled: true
        },
        {
          label: "Masculino",
          value: "masculino",

        },
        {
          label: "Femenino",
          value: "femenino",

        },
        {
          label: "Otro",
          value: "otro",

        },
      ]
    },
    {
      type: "input",
      name: "telefono",
      label: "Teléfono",
      tableProps: {
        sort: {
          sortColumnName: 'telefono',
          sortColumnNameDB: 'telefono',
        }
      },
    },
    {
      type: "input",
      name: "celular",
      label: "Celular",
      tableProps: {
        sort: {
          sortColumnName: 'celular',
          sortColumnNameDB: 'celular',
        }
      },
    },
    {
      type: "date",
      name: "fecha_nacimiento",
      utc: true,
      label: "Fecha Nacimiento",
    },
    {
      type: "selectCombo",
      name: "tipo_sangre",
      label: "Tipo de sangre",
      tableProps: {
        sort: {
          sortColumnName: 'tipo_sangre',
          sortColumnNameDB: 'tipo_sangre',
        }
      },
      options: [
        {
          value: null,
          label: "Seleccione un valor",
          disabled: true
        },
        ...selectTipoSangreOptions
      ]
    },
    {
      type: "input",
      name: "total_creditos",
      label: "Total Créditos",
      tableProps: {
        sort: {
          sortColumnName: 'total_creditos',
          sortColumnNameDB: 'total_creditos',
        }
      },
      validationOptions: {
        validate: (value) => {
          if (isNaN(value)) {
            return "Debe ser un valor numérico";
          }
          return true;
        },
      },
    },
    {
      type: "selectCombo",
      name: "instruccion",
      label: "Instrucción",
      defaultValue: null,
      tableProps: {
        sort: {
          sortColumnName: 'instruccion',
          sortColumnNameDB: 'instruccion',
        }
      },
      options: [
        {
          value: '',
          label: "Seleccione un valor",
          disabled: true
        },
        ...selectInstruccionOptions
      ]
    },
    {
      type: "selectCombo",
      name: "estado_civil",
      label: "Estado civil",
      defaultValue: null,
      tableProps: {
        sort: {
          sortColumnName: 'estado_civil',
          sortColumnNameDB: 'estado_civil',
        }
      },
      options: [
        {
          value: '',
          label: "Seleccione un valor",
          disabled: true
        },
        ...selectEstadoCivilOptions
      ]
    },
    {
      type: "input",
      name: "ocupacion",
      label: "Ocupacion",
      tableProps: {
        sort: {
          sortColumnName: 'ocupacion',
          sortColumnNameDB: 'ocupacion',
        }
      },
    },
    {
      type: "autocompleteMultipleV2",
      name: "clienteReferente",
      label: "Cliente referente",
      multiple: false,
      freeSolo: false,
      dispatchFunction: handleSearchUsers,
      options: clientesData,
      optionLabel: (option) => (option ? `${option.first_name} ${option.last_name} / ${option.id} ` : null),
    },
    {
      type: "autocompleteMultipleV2",
      name: "seguros",
      label: "Seguros",
      multiple: true,
      freeSolo: true,
      dispatchFunction: handleSearchSeguros,
      defaultValueFormat: (data) => data ? data.map(item => formatDataDB(item)) : [],
      options: segurosData,
    },
    {
      type: "input",
      name: "correo",
      label: "Correo",
      tableProps: {
        sort: {
          sortColumnName: 'correo',
          sortColumnNameDB: 'correo',
        }
      },
    },

  ], [clientesData, paisesData, segurosData, handleSearchPaises, handleSearchSeguros, handleSearchUsers]);


  const data = useMemo(() => ({
    tableData,
    foreignData: {
      clientesData
    },
    urls: {
      url: urlAdminCliente,
    }
  }), [clientesData, tableData])

  return (
    <CRUD data={data} CustomCreateFormComponent={() => <Register defaultRole={RoleTypes.client} adminMode />} />
  )
}

export default DoctorCrud;
