import { TextField, withStyles } from "@material-ui/core";
import { dangerColor } from "assets/jss/material-dashboard-react";

export const TextFieldCustom = withStyles({
  root: {
    '& label.Mui-error': {
      color: dangerColor[0],
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderBottomColor: dangerColor[0],
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-error fieldset': {
        borderColor: dangerColor[0],
      },
    },
  },
})(TextField);