import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//componentes
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { selectOficina } from 'redux/actions/aOficinas';


const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 340,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SelectOficina = () => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const { oficinas = [] } = useSelector(state => state.dataUser.data);
  const { oficinaSelected } = useSelector(state => state.data_oficinas);

  const firstLoad = useRef(true);

  const handleChange = event => {
    try {
      firstLoad.current = false;
      const oficinaId = event.target.value;
      let oficinasFind = oficinas.filter(item => item.id === oficinaId);
      const oficina = oficinasFind[0] ?? {};

      dispatch(selectOficina({ id: event.target.value, medico: { id: oficina.medico.id, med_activo: oficina.medico.med_activo } }))
    } catch (error) {
    }
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-label">Seleccione la oficina</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        value={oficinaSelected?.id}
        onChange={handleChange}
      >
        {oficinas &&
          oficinas.map((oficina) => (
            <MenuItem key={oficina.id} value={oficina.id}>{`${oficina.referencias_fisicas} ${oficina.medico?.nombres} ${oficina.medico?.apellidos}`}</MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default SelectOficina;
