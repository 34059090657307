import CRUD from 'components/CRUD/Crud.component'
import { urlLoadInstitucionAsociaciones } from 'constants/urls'
import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux';
import { eliminarAsociacionDoctorOficina } from 'redux/actions/aInstitucion';
import CustomButton from "components/CustomButtons/Button";
import Loading from 'components/Loading';
import ConfirmDialog from 'components/Admin/CRUD/ConfirmDialog';

export const InstitucionAsociaciones = ({ tableData }) => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [itemSelected, setItemSelected] = useState(null);

  const data = useMemo(() => ({
    // defaultSortByColumn: 'registro_date',
    defaultSortOrder: 'desc',
    tableData,
    urls: {
      url: urlLoadInstitucionAsociaciones,
    }
  }), [tableData])

  const handleOpenConfirmDeleteDialog = (item) => {
    setItemSelected(item);
    setOpenConfirmDialog(true);
  }

  const handleDeleteRequest = async () => {
    const item = itemSelected;
    setLoading(true);
    await dispatch(eliminarAsociacionDoctorOficina(item.oficina_id, item.doctor_id));
    setLoading(false);
  }

  return (
    <>
      <ConfirmDialog title={"Confirmación de eliminación"} open={openConfirmDialog}
        setOpen={setOpenConfirmDialog} onConfirm={handleDeleteRequest}  >
        <div>¿Esta seguro de eliminar esta asociación?</div>
      </ConfirmDialog>
      <Loading loading={loading}>
        <CRUD data={data}
          hideEditButton
          hideDeleteButton
          showNewButton={false}
          optionsButtonsCallback={(item) =>
            <>
              <CustomButton
                variant="contained"
                color="danger"
                size="sm"
                // className={classes.button}
                // startIcon={<QuestionAnswerIcon />}
                onClick={() => handleOpenConfirmDeleteDialog(item)}
              >
                Eliminar
              </CustomButton>
            </>
          }
        />
      </Loading>
    </>


  )
}
