import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
const ConfirmationDialog = props => {
  const { onClose, open, onConfirm, ...other } = props;

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onConfirm();
  };

  return (
    <Dialog
      // disableBackdropClick
      // disableEscapeKeyDown
      // maxWidth="xs"
      // aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">Confirmación</DialogTitle>
      {/* <DialogContent dividers>{`Está seguro que desea eliminar ${props.mensaje}`}</DialogContent> */}
      <DialogContent>{`¿Está seguro que desea eliminar ${props.mensaje}`}</DialogContent>
      <DialogActions>
        <Button variant="contained" autoFocus onClick={handleCancel}  color="secondary">
          Cancelar
        </Button>
        <Button variant="contained" onClick={handleOk}  color="default">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmationDialog;
