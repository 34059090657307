import React, { useCallback, useMemo } from 'react'
import moment from 'moment';
import MomentUtils from "@date-io/moment";
import { CircularProgress, IconButton, Tooltip, makeStyles } from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Delete from "@material-ui/icons/Delete";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useSelector } from 'react-redux';


const useStyles = makeStyles((theme) => ({
  day: {
  },
  dayHighlight: {
    background: '#20a4a4',
    color: 'white',
    borderRadius: '100%',
    margin: '0 2px',
    height: '36px',
    fontSize: '14px',
    '&:hover': {
      color: "black",
      background: '#bce3e3'
    },
  },
}));

export const ConsultaDatePicker = (props) => {

  const {
    disabledArrowBackButton,
    disabledArrowForwardButton,
    formConsultaDirty,
    handleClickArrowButton,
    handleDateChange,
    handleDeleteForm,
    loadingPagination,
    selectedDate,
    setOpenModalWarning,
  } = props;

  const classes = useStyles();

  const cliente_data_state = useSelector((state) => state.data_clientes.data);
  const cliente_data = useMemo(() => (cliente_data_state && cliente_data_state.consultas) ? cliente_data_state : { ...cliente_data_state, 'consultas': { data: [] } }, [cliente_data_state])

  //Calendar day format html
  const renderWrappedWeekDay = useCallback(
    (date, selectedDate, dayInCurrentMonth, dayComponent) => {
      let flag = false;
      if (cliente_data && cliente_data.consultas) {
        for (let i = 0; i < cliente_data.consultas.data.length; i++) {
          const item = cliente_data.consultas.data;
          const dateToCompare = item[i].created_date;
          if ((moment(dateToCompare).isSame(date, 'day'))) {
            flag = true;
            break;
          }
        }
      }

      // Si se encontro consultas en el dia del mes actual, establecer formato
      if (flag && dayInCurrentMonth) {
        return (
          <div >
            <IconButton className={flag ? classes.dayHighlight : classes.day}>
              <span> {moment(date).format('D')} </span>
            </IconButton>
          </div>
        );
      }

      if (date.isSame(moment(), 'day') || selectedDate.isSame(date, 'day')) return dayComponent

      return dayComponent
    },
    [cliente_data, classes],
  );

  return (
    <div style={{
      width: '100%',
      minWidth: '350px',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '5px'
    }}>
      <div>
        {
          loadingPagination ?
            <IconButton disabled={true}>
              <CircularProgress size={20} />
            </IconButton>
            :
            <IconButton onClick={() => !formConsultaDirty ? handleClickArrowButton(0) : setOpenModalWarning(true)} disabled={disabledArrowBackButton}>
              <ArrowBackIosIcon color={disabledArrowBackButton ? "disabled" : "primary"} />
            </IconButton>
        }
      </div>

      <div>
        <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
          <KeyboardDatePicker
            variant="inline"
            label="Fecha consulta"
            inputVariant="outlined"
            format="DD/MM/YYYY HH:mm"
            value={selectedDate}
            onChange={handleDateChange}
            renderDay={renderWrappedWeekDay}
            maxDate={new Date()}
          />
        </MuiPickersUtilsProvider>
      </div>

      <div>
        <IconButton onClick={() => handleClickArrowButton(1)} disabled={disabledArrowForwardButton}>
          <ArrowForwardIosIcon color={disabledArrowForwardButton ? "disabled" : "primary"} />
        </IconButton>
      </div>

      <div>
        <Tooltip
          title="Limpiar formulario"
          placement="top"
        >
          <IconButton onClick={handleDeleteForm}>
            <Delete />
          </IconButton>
        </Tooltip>

      </div>

    </div>
  )
}
