import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, Autocomplete } from "@material-ui/lab";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import WarningLeaveFormPage from "components/ui/WarningLeaveFormPage";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { MenuItem, Select, TextField } from "@material-ui/core";
import Loading from "components/Loading";
import medicoSVG from "../../src/assets/images/icons/medico.svg";
import { cambioPass, loadProfile } from "../redux/actions/aLogin";
import Notificacion from "components/ui/Notificacion";
import Chip from '@material-ui/core/Chip';
import _ from 'lodash';
import moment from "moment";
import { validacionesPerfil } from "utils/validaciones";
import { medicoUpdate, login as loginData } from "redux/actions/aLogin";
import { getEspecialidades } from "../redux/actions/aEspecialidades";
import { urlBucket } from "constants/urls";
import { encriptarRsa } from "utils/encriptar";
import Link from '@material-ui/core/Link';
import { useHistory } from "react-router-dom";
import imgMembership from 'assets/images/icons/afiliacion_mepal.svg';
import { ModalInfo } from "components/Modal/ModalInfo";
import ConfirmDialog from "components/Admin/CRUD/ConfirmDialog";
import dashboardStyles from "assets/jss/material-dashboard-react/views/dashboardStyle";
import { FormErrorLabel } from "components/forms/labels/FormErrorLabel";
import { ModalRequestAccountDeletion } from "components/Modal/ModalRequestAccountDeletion";

const useStyles = makeStyles((theme) => (
  {
    ...dashboardStyles,
    label: {
      color: '#999 !important',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '500',
      lineHeight: '1.42857 !important',
    },
    chip: {
      margin: "3px",
      color: 'black',
      fontWeight: '400',
    },
  }
));

const User = () => {
  const history = useHistory();
  const [formDirty, setFormDirty] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const usuario = useSelector((state) => state.dataUser.data);
  const especialidades = useSelector((state) => state.data_especialidades.data);

  //Modal afiliado
  const [openModalAfiliado, setOpenModalAfiliado] = useState(false);

  // Confirm dialog
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [errors, seterrors] = useState({});
  // const [error, seterror] = useState({ title: "", mensaje: "" });
  const [loading, setloading] = useState(false);
  const [imgTemp, setImgTemp] = useState(null);

  const [specialtiesUpdate, setSpecialtiesUpdate] = useState(false);

  const tituloHonorificoOpciones = [
    'Dr.',
    'Dra.',
    'Lcdo.',
    'Lcda.',
    'Sr.',
    'Sra.',
    'Srta.',
    'Otro',
  ]

  const valoresInit = useRef({
    login: !!usuario.login ? usuario.login : "",
    titulo_honorifico: !!usuario.titulo_honorifico
      ? usuario.titulo_honorifico
      : "",
    email: !!usuario.email ? usuario.email : "",
    nombres: !!usuario.nombres ? usuario.nombres : "",
    apellidos: !!usuario.apellidos ? usuario.apellidos : "",
    msp: !!usuario.msp ? usuario.msp : "",
    acess: !!usuario.acess ? usuario.acess : "",
    numero_telefono: !!usuario.numero_telefono ? usuario.numero_telefono : "",
    practica_desde: !!usuario.practica_desde
      ? moment.utc(usuario.practica_desde).format("YYYY-MM-DD")
      : null,
    declaracion_profesional: !!usuario.declaracion_profesional
      ? usuario.declaracion_profesional
      : "",
    especializaciones: !!usuario.especializaciones
      ? usuario.especializaciones
      : [],
    med_activo: usuario.med_activo ? usuario.med_activo : false,
    med_visible: usuario.med_visible ? usuario.med_visible : false,
    foto_nombre: !!usuario.foto_nombre ? usuario.foto_nombre : "",
    cedula_identidad: !!usuario.cedula_identidad ? usuario.cedula_identidad : "",
  });
  const [valores, setvalores] = useState(valoresInit.current);

  const [categoriasEspecialidades, setCategoriasEspecialidades] = useState([]);
  const tituloHonorificoInputRef = useRef(null);

  // Request account deletion
  const [openRequestDeletionAccountModal, setOpenRequestDeletionAccountModal] = useState(false);

  const [pass, setpass] = useState({
    anterior: "",
    nueva: "",
    nuevaConfirmacion: "",
  });
  let timeout = useRef(null);
  const fileField = React.useRef(null);

  function handleFileSelected(e) {

    const file = e.target.files[0];
    // divido para dos para 5MB max
    if (file.size / 1024 / 1024 < 5) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setvalores({ ...valores, foto_nombre: reader.result });
        setImgTemp(reader.result);
      };
    } else {
    }
  }

  const handleChange = (e) => {

    let { name, value } = e.target;
    if (name === 'titulo_honorifico_select') {
      name = 'titulo_honorifico';
      if (value === 'Otro') {
        value = '';
        timeout.current = setTimeout(() => {
          // inputRef.current.focus();
          tituloHonorificoInputRef.current.focus();
        }, 400);
      };
    }

    setvalores({ ...valores, [name]: value });
  };

  const handleChangePass = (e) => {

    const { name, value } = e.target;
    setpass({ ...pass, [name]: value });
  };

  const handleChangeAuto = (_, values) => {
    setSpecialtiesUpdate(true);
    setvalores({
      ...valores,
      //Elimino que no ingrese repetidos
      especializaciones: values
        .map((item) => item)
        .filter((item, pos, self) => self.indexOf(item) === pos),
    });
  };

  const handleChangeSwitch1 = (event, values) => {
    setvalores({ ...valores, med_visible: values });
  };

  const handleClickCambiar = async () => {
    try {
      // console.log("pass", pass);
      setloading(true);
      if (pass.nueva === pass.nuevaConfirmacion) {
        if (pass.nueva.length < 5 || !/.*^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/.test(pass.nueva)) {
          setloading(false);
          Notificacion({
            type: "error",
            text: `La contraseña debe tener al menos 5 caracteres, al menos una mayúscula, una minúscula y un número`,
          });
          // seterror({ type:'error',title: 'error', mensaje: 'La contraseña debe tener al menos 5 caracteres, al menos una mayúscula, una minúscula y un número' });
          return;
        } else {
          const data = await encriptarRsa(
            JSON.stringify({ anterior: pass.anterior, nueva: pass.nueva })
          );
          // console.log('correcto');
          await dispatch(cambioPass(data, state));
          setloading(false);

        }
      } else {
        setloading(false);
        Notificacion({
          type: "error",
          text: `La contraseña nueva no coincide!`,
        });

      }
    } catch (error) {
      setloading(false);
      Notificacion({
        type: "error",
        text: `Ocurrio un error, intente nuevamente ${error}`,
      });
      // seterror(`Ocurrio un error, intente nuevamente ${error}`);
    }
  };

  const handleClickLinkNuevaEspecialidad = () => {
    const data = { asunto: "Nueva especialidad", mensaje: "" }
    localStorage.setItem("soporteMensaje", JSON.stringify(data));
    history.push('/medico/ayuda');
  }

  const handleSubmit = async () => {
    try {
      if (Object.keys(errors).length > 0) {
        Notificacion({
          type: "error",
          text: "Debe completar los campos correctamente.",
        });
        return;
      }

      //Verificar cambios en cedula
      const cedulaAnterior = !!usuario.cedula_identidad ? usuario.cedula_identidad : "";
      let dataToSend;
      if (cedulaAnterior === valores.cedula_identidad) {
        const { cedula_identidad, ...restValues } = valores;
        dataToSend = { ...restValues };
      } else {
        dataToSend = { ...valores };
      }

      setloading(true);
      const res = await dispatch(medicoUpdate(dataToSend, state));

      if (!res.error) {
        if (imgTemp) setImgTemp(null);
        dispatch(loginData(res.nueva_data));
      } else {
        Notificacion({
          type: "error",
          text: res.error,
        });
      }
    } catch (err) {
      Notificacion({
        type: "error",
        text: "Ocurrio un error, intente nuevamente",
      });
    }
    setloading(false);
  };

  useEffect(() => {
    valoresInit.current = {
      msp: usuario.msp !== undefined ? usuario.msp : "",
      acess: usuario.acess !== undefined ? usuario.acess : "",
      login: usuario.login !== undefined ? usuario.login : "",
      titulo_honorifico:
        usuario.titulo_honorifico !== undefined
          ? usuario.titulo_honorifico
          : "",
      email: usuario.email !== undefined ? usuario.email : "",
      nombres: usuario.nombres !== undefined ? usuario.nombres : "",
      apellidos: usuario.apellidos !== undefined ? usuario.apellidos : "",
      numero_telefono:
        usuario.numero_telefono !== undefined ? usuario.numero_telefono : "",
      practica_desde:
        usuario.practica_desde !== undefined
          ? moment.utc(usuario.practica_desde).format("YYYY-MM-DD")
          : null,
      declaracion_profesional:
        usuario.declaracion_profesional !== undefined
          ? usuario.declaracion_profesional
          : "",
      especializaciones: !!usuario.especializaciones
        ? usuario.especializaciones
        : [],
      med_activo: usuario.med_activo ? usuario.med_activo : false,
      med_visible: usuario.med_visible ? usuario.med_visible : false,
      foto_nombre: !!usuario.foto_nombre ? usuario.foto_nombre : "",
      cedula_identidad: !!usuario.cedula_identidad ? usuario.cedula_identidad : "",
    };
    setvalores(valoresInit.current);
  }, [usuario]);

  //Espero que se termine de setear los valores, para con esos valores validar
  useEffect(() => {
    //Validaciones form
    seterrors(validacionesPerfil(valores));

    //Verificar si se ha modificado el formulario
    if (_.isEqual(valores, valoresInit.current)) setFormDirty(false);
    else setFormDirty(true);
  }, [valores]);

  useEffect(() => {

    let categoriasIdAgregadosAux = [];
    let categoriasEspecialidadesAux = [];

    if (valores.especializaciones && Array.isArray(valores.especializaciones)) {
      valores.especializaciones.map((esp) => (
        esp.categorias && esp.categorias.map(cat => {
          if (!categoriasIdAgregadosAux.includes(cat.id)) {
            categoriasIdAgregadosAux.push(cat.id);
            categoriasEspecialidadesAux.push(cat);
          }
          return null;
        })
      ))
    }

    setCategoriasEspecialidades(categoriasEspecialidadesAux);

  }, [valores.especializaciones]);

  useEffect(() => {
    dispatch(loadProfile());
    dispatch(getEspecialidades());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
  }, []);

  return (
    <Loading title="Cargando..." loading={loading}>
      <>
        {/* Warning on exit page */}
        {/* Form stateL: {formDirty ? 'form dirty' : 'form no dirty'} */}
        <WarningLeaveFormPage when={formDirty} />

        {/* Modal request deletion account*/}
        <ModalRequestAccountDeletion
          open={openRequestDeletionAccountModal}
          onCloseModal={() => setOpenRequestDeletionAccountModal(false)}
        />
        {/* End Modal request deletion account */}

        {/* Modal Afiliado */}
        <ModalInfo open={openModalAfiliado} onCloseModal={() => setOpenModalAfiliado(false)} img={imgMembership} title={'Afiliación MedPal'}>
          {/* Contenido */}
          A los profesionales que afilien su consultorio se les otorga beneficios que comprenden una gran campaña promocional de sus servicios, tener un posicionamiento privilegiado dentro del directorio, y acceso a las herramientas de gestión de consultorio sin costo.
          Al registrarse como afiliado aprueba que los usuarios de la plataforma puedan acceder a un descuento del 50% en las consultas.
          El descuento es únicamente aplicado para consulta y no se aplica a otro servicio que haya registrado.
          El médico es libre de afiliarse o desafiliarse cuando lo desee, tomando en cuenta las siguientes limitaciones:
          El profesional debe considerar que las plazas son limitadas.
          El profesional que decide desafiliarse no podrá volver a afiliar ese consultorio en un periodo de al menos 30 días y no se podrá garantizar su cupo previamente adquirido.
          Los beneficios se mantendrán para los usuarios/pacientes que hayan separado cita en el tiempo en el que el profesional está afiliado, incluso si a la fecha de realizar la consulta ya no se encuentra afiliado.
          Solo los médicos que mantengan la afiliación por un tiempo prolongado, serán considerados para las campañas realizadas en redes sociales o por cualquier otro medio por parte de MedPal.
        </ModalInfo>
        {/* Fin Modal Afiliado */}

        {/* Confirmacion de guardado */}
        <ConfirmDialog
          title={"Confirmación de guardado"}
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onConfirm={handleSubmit}
        >
          <div>Ha realizado cambios en la lista de especialidades. Los servicios relacionados con las especialidades eliminadas de la lista seran eliminados. ¿Desea confirmar los cambios?</div>
        </ConfirmDialog>
        {/* Fin confirmacion de guardado */}

        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitle}>Configuración Perfil Profesional</h4>
              </CardHeader>
              {
                !usuario.cuenta_activa &&
                <div style={{ marginTop: "10px" }}>
                  <Alert severity="info">Su cuenta está siendo verificada, la respuesta será enviada a su correo electrónico</Alert>
                </div>
              }
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      {valores.foto_nombre !== "" && (
                        <>
                          <img
                            width="150px"
                            // src={`${urlBucket}${valores.foto_nombre}`}
                            src={imgTemp ? imgTemp : `${urlBucket}${valores.foto_nombre} `}
                            alt='imgPerfil'
                          />
                          <input
                            type="file"
                            name="imagen"
                            id="imagen"
                            accept=".jpeg, .png, .jpg"
                            onChange={handleFileSelected}
                            ref={fileField}
                          />
                        </>
                      )}
                      {valores.foto_nombre === "" && (
                        <>
                          {" "}
                          <img width="150px" src={medicoSVG} alt='imgPerfil' />{" "}
                          <input
                            type="file"
                            name="imagen"
                            id="imagen"
                            accept=".jpeg, .png, .jpg"
                            onChange={handleFileSelected}
                            ref={fileField}
                          />
                        </>
                      )}
                    </div>
                  </GridItem>

                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Nombre de usuario"
                      formControlProps={{
                        fullWidth: true,
                        disabled: true,
                      }}
                      inputProps={{
                        id: "login",
                        name: "login",
                        value: valores.login,
                        disabled: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Cédula Identidad o pasaporte"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      id="cedula_identidad"
                      inputProps={{
                        onChange: handleChange,
                        name: "cedula_identidad",
                        value: valores.cedula_identidad,
                      }}
                      error={errors.cedula_identidad ? true : false}
                      success={!errors.cedula_identidad}
                    />
                    {errors.cedula_identidad && (
                      <FormErrorLabel> {errors.cedula_identidad}</FormErrorLabel>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <GridContainer>
                      <GridItem xs={10} >
                        <CustomInput
                          labelText="* Título Honorífico"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          id="titulo_honorifico"
                          inputProps={{

                            inputRef: tituloHonorificoInputRef,
                            onChange: handleChange,
                            name: "titulo_honorifico",
                            value: valores.titulo_honorifico,
                            placeholder: "Dr.",
                          }}
                          error={errors.titulo_honorifico ? true : false}
                          success={!errors.titulo_honorifico}
                        />
                      </GridItem>
                      <GridItem xs={2} >
                        <Select
                          labelId="demo-simple-select-label"
                          name="titulo_honorifico_select"
                          id="titulo_honorifico-select"
                          value={tituloHonorificoOpciones.includes(valores.titulo_honorifico) ? valores.titulo_honorifico : 'Otro'}
                          onChange={handleChange}
                          style={{ 'marginTop': '43px', width: '100%' }}
                        >
                          {
                            tituloHonorificoOpciones.map((op, index) => (
                              <MenuItem key={index} value={op}>{op}</MenuItem>
                            ))
                          }
                        </Select>
                      </GridItem>
                    </GridContainer>
                    {errors.titulo_honorifico && (
                      <FormErrorLabel> {errors.titulo_honorifico}</FormErrorLabel>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="* Email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      id="email"
                      inputProps={{
                        onChange: handleChange,
                        name: "email",
                        value: valores.email,
                      }}
                      error={errors.email ? true : false}
                      success={!errors.email}
                    />
                    {errors.email && (
                      <FormErrorLabel> {errors.email}</FormErrorLabel>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="* Nombres"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      id="nombres"
                      inputProps={{
                        onChange: handleChange,
                        name: "nombres",
                        value: valores.nombres,
                      }}
                      error={errors.nombres ? true : false}
                      success={!errors.nombres}
                    />
                    {errors.nombres && (
                      <FormErrorLabel> {errors.nombres}</FormErrorLabel>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="* Apellidos"
                      id="apellidos"
                      inputProps={{
                        onChange: handleChange,
                        name: "apellidos",
                        value: valores.apellidos,
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      error={errors.apellidos ? true : false}
                      success={!errors.apellidos}
                    />
                    {errors.apellidos && (
                      <FormErrorLabel> {errors.apellidos}</FormErrorLabel>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="* Teléfono"
                      id="numero_telefono"
                      inputProps={{
                        onChange: handleChange,
                        name: "numero_telefono",
                        value: valores.numero_telefono,
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      error={errors.numero_telefono ? true : false}
                      success={!errors.numero_telefono}
                    />
                    {errors.numero_telefono && (
                      <FormErrorLabel> {errors.numero_telefono}</FormErrorLabel>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="* Inicio de práctica profesional"
                      labelProps={{ shrink: true }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      id="practica_desde"
                      inputProps={{
                        onChange: handleChange,
                        name: "practica_desde",
                        type: "date",
                        value: valores.practica_desde,
                      }}
                      error={errors.practica_desde ? true : false}
                      success={!errors.practica_desde}
                    />
                    {errors.practica_desde && (
                      <FormErrorLabel>{errors.practica_desde}</FormErrorLabel>
                    )}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <Autocomplete
                      multiple
                      style={{ paddingTop: "27px" }}
                      id="especialidades"
                      options={especialidades}
                      onChange={handleChangeAuto}
                      name="especializaciones"
                      getOptionLabel={(option) => option.nombre}
                      value={valores.especializaciones}
                      getOptionSelected={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="especialidades"
                          label="Especialidades"
                        />
                      )}
                    />
                    {errors.especializaciones && (
                      <FormErrorLabel>{errors.especializaciones}</FormErrorLabel>
                    )}
                    <Link
                      style={{ fontWeight: "bold" }}
                      href="#"
                      onClick={() => handleClickLinkNuevaEspecialidad()}
                      component="button"
                      variant="body2"
                      color="primary"
                    >
                      ¿No está listado tu especialidad?, Anótala aquí.
                    </Link>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>

                    <div style={{ paddingTop: "27px" }}>
                      <label className={classes.label} htmlFor="">
                        Categorías de especialidades
                      </label>
                      <div style={{ display: 'flexWrap' }}>
                        {
                          categoriasEspecialidades.map(cat => (
                            <Chip className={classes.chip} key={cat.id} label={cat.nombre} />
                          ))
                        }
                      </div>
                      {
                        (categoriasEspecialidades.length === 0) &&
                        <div>
                          <p>No tienes especialidades seleccionadas</p>
                        </div>
                      }

                    </div>

                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="* Senescyt (correspondiente a la especialidad selecionada)"
                      id="msp"
                      inputProps={{
                        onChange: handleChange,
                        name: "msp",
                        value: valores.msp,
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      error={errors.msp ? true : false}
                      success={!errors.msp}
                    />
                    {errors.msp && (
                      <FormErrorLabel>{errors.msp}</FormErrorLabel>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Acess (unicódigo)"
                      id="acess"
                      inputProps={{
                        onChange: handleChange,
                        name: "acess",
                        value: valores.acess,
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      error={errors.acess ? true : false}
                      success={!errors.acess}
                    />
                    {errors.acess && (
                      <FormErrorLabel>{errors.acess}</FormErrorLabel>
                    )}
                  </GridItem>
                </GridContainer>
                <GridContainer style={{ marginTop: 10 }}>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Declaracion Profesional"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      id="declaracion_profesional"
                      inputProps={{
                        onChange: handleChange,
                        name: "declaracion_profesional",
                        multiline: true,
                        rows: 5,

                        value: valores.declaracion_profesional,
                      }}
                      error={errors.declaracion_profesional ? true : false}
                      success={!errors.declaracion_profesional}
                    />
                    {errors.declaracion_profesional && (
                      <FormErrorLabel>{errors.declaracion_profesional}</FormErrorLabel>
                    )}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={6} md={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={valores.med_visible}
                          onChange={handleChangeSwitch1}
                          name="activado"
                          color="primary"
                          disabled={!usuario.cuenta_activa}
                        />
                      }
                      label="Disponible"
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Contraseña anterior"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      id="anterior"
                      inputProps={{
                        onChange: handleChangePass,
                        name: "anterior",
                        value: valores.anterior,
                        type: "password",
                        placeholder: "********",
                      }}
                    />
                    {errors.anterior && (
                      <FormErrorLabel>{errors.anterior}</FormErrorLabel>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Nueva contraseña"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      id="nueva"
                      inputProps={{
                        onChange: handleChangePass,
                        name: "nueva",
                        value: valores.nueva,
                        placeholder: "********",
                        type: "password",
                      }}
                    // error={errors.nueva}
                    // success={!errors.nueva}
                    />
                    {errors.nueva && (
                      <FormErrorLabel>{errors.nueva}</FormErrorLabel>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Confirmar contraseña"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      id="nuevaConfirmacion"
                      inputProps={{
                        onChange: handleChangePass,
                        name: "nuevaConfirmacion",
                        value: valores.nuevaConfirmacion,
                        placeholder: "********",
                        type: "password",
                      }}
                    // error={errors.nuevaConfirmacion}
                    // success={!errors.nuevaConfirmacion}
                    />
                    {errors.nuevaConfirmacion && (
                      <FormErrorLabel>{errors.nuevaConfirmacion}</FormErrorLabel>
                    )}
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="warning" onClick={() => specialtiesUpdate ? setOpenConfirmDialog(true) : handleSubmit()}>
                  Actualizar Perfil
                </Button>
                <Button color="warning" onClick={() => handleClickCambiar()}>
                  Cambiar contraseña
                </Button>
                <Button color="danger" onClick={() => setOpenRequestDeletionAccountModal(true)}>
                  Eliminar cuenta
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    </Loading>
  );
};
export default User;
