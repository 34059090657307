import React, { useRef } from 'react'
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
// import "bootstrap/dist/css/bootstrap.css";
import * as doc from "../assets/images/doc-running.json";


const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: doc.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};


export default ({ loading, title, children }) => {
  const { className, style } = children.props
  const ref = useRef(null)

  const height = ref && ref.current ? ref.current.clientHeight : ''

  if (loading)
    return (
      <div className={className} style={{ ...style, display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', height, color: '#3177b9' }}>
        <FadeIn>
          <div className="d-flex justify-content-center align-items-center">
            <h3 style={{ textAlign: "center" }}>{title}</h3>
            <Lottie options={defaultOptions} height={120} width={120} />
          </div>
        </FadeIn>

      </div>
    )
  return < div ref={ref}>{children}</div>
}
