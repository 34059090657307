import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Loading from "components/Loading";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import WarningLeaveFormPage from "components/ui/WarningLeaveFormPage";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import personSVG from "../../../src/assets/images/icons/friends.svg";
import { urlBucket } from "constants/urls";
import { encriptarRsa } from 'utils/encriptar';
import { cambioPass } from 'redux/actions/aLogin';
import { validacionesCliente } from "utils/validaciones";
import { clienteUpdate, login as loginData } from 'redux/actions/aLogin';
import { FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField } from '@material-ui/core';
import { loadProfile } from 'redux/actions/aLogin';
import Notificacion from "components/ui/Notificacion";
import _ from 'lodash';
import GoogleMaps from 'components/Maps/GoogleMaps';
import { selectTipoSangreOptions, selectEstadoCivilOptions, selectInstruccionOptions } from 'constants/constants';
import { AutocompleteComponent } from 'components/ReactHookForm/AutocompleteComponent';
import { startSearchPaises } from 'redux/actions/aClientes';
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import { FormErrorLabel } from 'components/forms/labels/FormErrorLabel';
import InfoIcon from '@material-ui/icons/Info';
import { ModalInfo } from 'components/Modal/ModalInfo';
import imgEdit from 'assets/images/icons/edit.svg';
import { ModalRequestAccountDeletion } from 'components/Modal/ModalRequestAccountDeletion';


const useStyles = makeStyles({
  ...styles,
  formControl: {
    width: '100%',
    marginTop: '27px'
  },
  formControlAutocomplete: {
    width: "100%",
    marginTop: '27px'
  },

});

function Dashboard() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setloading] = useState(false);
  const [formDirty, setFormDirty] = useState(false);
  const [errors, seterrors] = useState({});
  const usuario = useSelector((state) => state.dataUser.data);
  const state = useSelector((state) => state);
  const [imgTemp, setImgTemp] = useState(null);
  const [pass, setpass] = useState({
    anterior: "",
    nueva: "",
    nuevaConfirmacion: "",
  });

  const [paisesData, setPaisesData] = useState([]);

  // Request account deletion
  const [openRequestDeletionAccountModal, setOpenRequestDeletionAccountModal] = useState(false);

  //Modal info, checkbox habilitar edición
  const [openModalInfo, setOpenModalInfo] = useState(false);

  const handleSearchPaises = useCallback(
    async (value) => {
      try {
        const { data } = await dispatch(startSearchPaises(value));
        setPaisesData(data);
      } catch (error) {
      }
    },
    [dispatch],
  )

  const tituloHonorificoOpciones = [
    'Dr.',
    'Dra.',
    'Lcdo.',
    'Lcda.',
    'Sr.',
    'Sra.',
    'Srta.',
    'Otro',
  ];

  let timeout = useRef(null);
  const tituloHonorificoInputRef = useRef(null);

  const valoresInit = useRef({
    login: !!usuario.login ? usuario.login : "",
    cedulaIdentidad: !!usuario.cedulaIdentidad ? usuario.cedulaIdentidad : "",
    cedulaRepresentante: !!usuario.cedulaRepresentante ? usuario.cedulaRepresentante : "",
    tituloHonorifico: !!usuario.tituloHonorifico ? usuario.tituloHonorifico : "",
    email: !!usuario.email ? usuario.email : "",
    nombres: !!usuario.nombres ? usuario.nombres : "",
    apellidos: !!usuario.apellidos ? usuario.apellidos : "",
    sexo: !!usuario.sexo ? usuario.sexo : "",
    direccion: !!usuario.direccion ? usuario.direccion : "",
    pais: !!usuario.pais ? usuario.pais : "",
    ciudad: !!usuario.ciudad ? usuario.ciudad : "",
    telefono: !!usuario.telefono ? usuario.telefono : "",
    celular: !!usuario.celular ? usuario.celular : "",
    nacionalidad: !!usuario.nacionalidad ? usuario.nacionalidad : "",
    instruccion: !!usuario.instruccion ? usuario.instruccion : "",
    ocupacion: !!usuario.ocupacion ? usuario.ocupacion : "",
    estado_civil: !!usuario.estadoCivil ? usuario.estadoCivil : "",
    direccion_longitud: !!usuario.direccionLongitud ? usuario.direccionLongitud : 0,
    direccion_latitud: !!usuario.direccionLatitud ? usuario.direccionLatitud : 0,
    habilitarEdicion: usuario.habilitarEdicion ?? false,
    fechaNacimiento:
      !!usuario.fechaNacimiento
        ? moment.utc(usuario.fechaNacimiento).format("YYYY-MM-DD")
        : null,

    fotoNombre: !!usuario.fotoNombre ? usuario.fotoNombre : "",
    tipoSangre: !!usuario.tipoSangre ? usuario.tipoSangre : "",
  });

  const [valores, setvalores] = useState(valoresInit.current);

  useEffect(() => {
    valoresInit.current = {
      login: usuario.login !== undefined ? usuario.login : "", //
      cedulaIdentidad: usuario.cedulaIdentidad !== undefined ? usuario.cedulaIdentidad : "",
      cedulaRepresentante: !!usuario.cedulaRepresentante ? usuario.cedulaRepresentante : "",
      tituloHonorifico: !!usuario.tituloHonorifico ? usuario.tituloHonorifico : "",
      email: usuario.email ? usuario.email : "",
      nombres: usuario.nombres !== undefined ? usuario.nombres : "",//
      apellidos: usuario.apellidos !== undefined ? usuario.apellidos : "",//
      sexo: usuario.sexo !== undefined ? usuario.sexo : "",
      direccion: usuario.direccion !== undefined ? usuario.direccion : "",
      pais: !!usuario.pais ? usuario.pais : "",
      ciudad: !!usuario.ciudad ? usuario.ciudad : "",
      telefono: usuario.telefono !== undefined ? usuario.telefono : "",//
      celular: usuario.celular !== undefined ? usuario.celular : "",//
      nacionalidad: !!usuario.nacionalidad ? usuario.nacionalidad : "",
      instruccion: !!usuario.instruccion ? usuario.instruccion : "",
      ocupacion: !!usuario.ocupacion ? usuario.ocupacion : "",
      estado_civil: !!usuario.estadoCivil ? usuario.estadoCivil : "",
      direccion_longitud: !!usuario.direccionLongitud ? usuario.direccionLongitud : 0,
      direccion_latitud: !!usuario.direccionLatitud ? usuario.direccionLatitud : 0,
      habilitarEdicion: usuario.habilitarEdicion ?? false,
      fechaNacimiento:
        !!usuario.fechaNacimiento
          ? moment.utc(usuario.fechaNacimiento).format("YYYY-MM-DD")  //
          : null,

      fotoNombre: !!usuario.fotoNombre ? usuario.fotoNombre : "", //
      tipoSangre: !!usuario.tipoSangre ? usuario.tipoSangre : "",
    };
    setvalores(valoresInit.current);
  }, [usuario]);

  useEffect(() => {
    seterrors(validacionesCliente(valores));

    //Verificar si se ha modificado el formulario
    if (_.isEqual(valores, valoresInit.current)) setFormDirty(false);
    else setFormDirty(true);
  }, [valores]);

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === 'titulo_honorifico_select') {
      name = 'tituloHonorifico';
      if (value === 'Otro') {
        value = '';
        timeout.current = setTimeout(() => {
          // inputRef.current.focus();
          tituloHonorificoInputRef.current.focus();
        }, 400);
      };
    }

    setvalores({ ...valores, [name]: value });
  };

  const handleChangeAutocomplete = (name, value) => {
    setvalores({ ...valores, [name]: value });
  }

  const handleChangeCombo = (event) => {
    const { name, value } = event.target;
    setvalores({ ...valores, [name]: value });
  };

  const handleChangeSwitch = (event, values) => {
    const { name } = event.target;
    console.log(name)
    console.log(values)
    setvalores({ ...valores, [name]: values });
  };

  const handleChangePass = (e) => {
    const { name, value } = e.target;
    setpass({ ...pass, [name]: value });
  };

  const fileField = React.useRef(null);
  function handleFileSelected(e) {
    const file = e.target.files[0];
    // divido para dos para 5MB max
    if (file.size / 1024 / 1024 < 5) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setvalores({ ...valores, fotoNombre: reader.result });
        setImgTemp(reader.result);
      };
    } else {
    }
  }

  const handleClickCambiar = async () => {
    try {
      // return
      setloading(true);
      if (pass.nueva === pass.nuevaConfirmacion) {
        if (pass.nueva.length < 5 || !/.*^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/.test(pass.nueva)) {
          setloading(false);
          Notificacion({
            type: "error",
            text: `La contraseña debe tener al menos 5 caracteres, al menos una mayúscula, una minúscula y un número`,
          });
          return;
        } else {
          const data = await encriptarRsa(
            JSON.stringify({ anterior: pass.anterior, nueva: pass.nueva })
          );
          await dispatch(cambioPass(data, state));
          setloading(false);
        }
      } else {

        Notificacion({
          type: "error",
          text: `La contraseña nueva no coincide!`,
        });
        setloading(false);

      };
    } catch (error) {

      Notificacion({
        type: "error",
        text: `Ocurrio un error, intente nuevamente ${error}`,
      });
      setloading(false);
    }
  };

  const handleSubmit = async () => {
    console.log(errors);
    if (imgTemp) setImgTemp(null);
    try {
      if (Object.keys(errors).length > 0) {
        Notificacion({
          type: "error",
          text: "Debe completar los campos correctamente.",
        });
        return;
      }
      console.log("Datos correcto");
      console.log({ valores })
      setloading(true);
      const res = await dispatch(clienteUpdate(valores, state));
      console.log({ res });

      if (!res.error) {
        if (imgTemp) setImgTemp(null);
        dispatch(loginData(res.nueva_data));
      } else {
        Notificacion({
          type: "error",
          text: res.error,
        });

      }
    } catch (err) {
      Notificacion({
        type: "error",
        text: "Ocurrio un error, intente nuevamente",
      });
    }
    setloading(false);
  };

  useEffect(() => { dispatch(loadProfile()) }, [dispatch]);

  useEffect(() => {
    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    }
  }, []);

  // Google Maps
  const handleChangeDireccionLatLng = (
    lat, lng,
    address = { formattedAddress: null, zipCode: null, country: null, state: null, city: null },
  ) => {
    const { formattedAddress, country, city } = address;
    // console.log({ lat, lng });
    setvalores({
      ...valores,
      direccion_latitud: lat,
      direccion_longitud: lng,
      direccion: formattedAddress ?? valores.direccion,
      // codigo_zip: zipCode ?? valores.codigo_zip,
      pais: country ?? valores.pais,
      // provincia: state ?? valores.provincia,
      ciudad: city ?? valores.ciudad,
    });
  }


  return (
    <Loading title="Cargando..." loading={loading}>
      <>
        {/* Warning on exit page */}
        {/* Form stateL: {formDirty ? 'form dirty' : 'form no dirty'} */}
        <WarningLeaveFormPage when={formDirty} />

        {/* Modal Información */}
        <ModalInfo open={openModalInfo} onCloseModal={() => setOpenModalInfo(false)} img={imgEdit} title={'Edición de datos filiación'}>
          {/* Contenido */}
          Esta función permite habilitar o deshabilitar la edición de sus datos de filiación a los profesionales de la plataforma.
        </ModalInfo>
        {/* Fin Modal Información */}

        {/* Modal request deletion account*/}
        <ModalRequestAccountDeletion
          open={openRequestDeletionAccountModal}
          onCloseModal={() => setOpenRequestDeletionAccountModal(false)}
        />
        {/* End Modal request deletion account */}

        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitle}>Editar Información</h4>
                <p className={classes.cardCategory}>Complete su información</p>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      {valores.fotoNombre !== "" && (
                        <>
                          <img
                            width="150px"
                            src={imgTemp ? imgTemp : `${urlBucket}${valores.fotoNombre} `}
                            alt='imgCliente'
                          />
                          <input
                            type="file"
                            name="imagen"
                            id="imagen"
                            accept=".jpeg, .png, .jpg"
                            onChange={handleFileSelected}
                            ref={fileField}
                          />
                        </>
                      )}
                      {valores.fotoNombre === "" && (
                        <>
                          {" "}
                          <img width="150px" src={personSVG} alt='imgCliente' />{" "}
                          <input
                            type="file"
                            name="imagen"
                            id="imagen"
                            accept=".jpeg, .png, .jpg"
                            onChange={handleFileSelected}
                            ref={fileField}
                          />
                        </>
                      )}
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Nombre de usuario"
                      formControlProps={{
                        fullWidth: true,
                        disabled: true,
                      }}
                      inputProps={{
                        id: "login",
                        name: "login",
                        value: valores.login,
                        disabled: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Cédula Identidad o pasaporte"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      id="cedula_identidad"
                      inputProps={{
                        onChange: handleChange,
                        name: "cedulaIdentidad",
                        value: valores.cedulaIdentidad,
                      }}
                      error={errors.cedulaIdentidad ? true : false}
                      success={!errors.cedulaIdentidad}
                    />
                    {errors.cedulaIdentidad && (
                      <FormErrorLabel>{errors.cedulaIdentidad}</FormErrorLabel>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Cédula representante"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      id="cedula_representante"
                      inputProps={{
                        onChange: handleChange,
                        name: "cedulaRepresentante",
                        value: valores.cedulaRepresentante,
                      }}
                      error={errors.cedulaRepresentante ? true : false}
                      success={!errors.cedulaRepresentante}
                    />
                    {errors.cedulaRepresentante && (
                      <FormErrorLabel>{errors.cedulaRepresentante}</FormErrorLabel>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <GridContainer>
                      <GridItem xs={10}>
                        <CustomInput
                          labelText="Título Honorífico"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          id="titulo_honorifico"
                          inputProps={{
                            inputRef: tituloHonorificoInputRef,
                            onChange: handleChange,
                            name: "tituloHonorifico",
                            value: valores.tituloHonorifico,
                            placeholder: "Título Honorífico",
                          }}
                          error={errors.tituloHonorifico ? true : false}
                          success={!errors.tituloHonorifico}
                        />
                      </GridItem>
                      <GridItem xs={2}>
                        <Select
                          labelId="demo-simple-select-label"
                          name="titulo_honorifico_select"
                          id="titulo_honorifico-select"
                          value={tituloHonorificoOpciones.includes(valores.tituloHonorifico) ? valores.tituloHonorifico : 'Otro'}
                          onChange={handleChange}
                          style={{ 'marginTop': '43px', width: '100%' }}
                        >
                          {
                            tituloHonorificoOpciones.map((op, index) => (
                              <MenuItem key={index} value={op}>{op}</MenuItem>
                            ))
                          }

                        </Select>
                      </GridItem>
                    </GridContainer>
                    {errors.tituloHonorifico && (
                      <FormErrorLabel>{errors.tituloHonorifico}</FormErrorLabel>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      id="email"
                      inputProps={{
                        onChange: handleChange,
                        name: "email",
                        value: valores.email,
                      }}
                      error={errors.email ? true : false}
                      success={!errors.email}
                    />
                    {errors.email && (
                      <FormErrorLabel>{errors.email}</FormErrorLabel>
                    )}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <TextField InputLabelProps={{ className: classes.labelInput }} placeholder="Sexo" id="select" name="sexo" label="Sexo"
                      value={((valores.sexo !== "femenino") && (valores.sexo !== "masculino")) ? "otro" : valores.sexo} style={{ width: "100%", marginTop: "27px" }} select onChange={handleChangeCombo}>
                      <MenuItem value={"masculino"}>Masculino</MenuItem>
                      <MenuItem value={"femenino"}>Femenino</MenuItem>
                      <MenuItem value={"otro"}>Otro</MenuItem>
                    </TextField>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Nombres"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      id="nombres"
                      inputProps={{
                        onChange: handleChange,
                        name: "nombres",
                        value: valores.nombres,
                      }}
                      error={errors.nombres ? true : false}
                      success={!errors.nombres}
                    />
                    {errors.nombres && (
                      <FormErrorLabel>{errors.nombres}</FormErrorLabel>
                    )}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Apellidos"
                      id="apellidos"
                      inputProps={{
                        onChange: handleChange,
                        name: "apellidos",
                        value: valores.apellidos,
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      error={errors.apellidos ? true : false}
                      success={!errors.apellidos}
                    />
                    {errors.apellidos && (
                      <FormErrorLabel>{errors.apellidos}</FormErrorLabel>
                    )}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Teléfono"
                      id="numero_telefono"
                      inputProps={{
                        onChange: handleChange,
                        name: "telefono",
                        value: valores.telefono,
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      error={errors.telefono ? true : false}
                      success={!errors.telefono}
                    />
                    {errors.telefono && (
                      <FormErrorLabel>{errors.telefono}</FormErrorLabel>
                    )}

                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Celular"
                      id="numero_celular"
                      inputProps={{
                        onChange: handleChange,
                        name: "celular",
                        value: valores.celular,
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      error={errors.celular ? true : false}
                      success={!errors.celular}
                    />
                    {errors.celular && (
                      <FormErrorLabel>{errors.celular}</FormErrorLabel>
                    )}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Fecha de nacimiento"
                      labelProps={{ shrink: true }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      id="fechaNacimiento"
                      inputProps={{
                        onChange: handleChange,
                        name: "fechaNacimiento",
                        type: "date",
                        value: valores.fechaNacimiento,
                      }}
                      error={errors.fechaNacimiento ? true : false}
                      success={!errors.fechaNacimiento}
                    />
                    {errors.fechaNacimiento && (
                      <FormErrorLabel>{errors.fechaNacimiento}</FormErrorLabel>
                    )}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Dirección"
                      id="direccion"
                      inputProps={{
                        onChange: handleChange,
                        name: "direccion",
                        value: valores.direccion,
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      error={errors.direccion ? true : false}
                      success={!errors.direccion}
                    />
                    {errors.direccion && (
                      <FormErrorLabel>{errors.direccion}</FormErrorLabel>
                    )}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <FormControl error className={classes.formControlAutocomplete}>
                      <AutocompleteComponent
                        multiple={false}
                        reduxFunction={false}
                        defaultValue={valores.pais}
                        label={'País de residencia'}
                        name={'pais'}
                        type="text"
                        freeSolo={true}
                        success={!errors.pais}
                        fullWidth
                        onChange={(event, newValue) => {
                          if (typeof newValue === 'string') {
                            handleChangeAutocomplete('pais', newValue);
                          }
                          if (newValue && newValue.inputValue) {
                            handleChangeAutocomplete('pais', {
                              tipo: newValue.inputValue,
                              custom: true,
                            });
                          }

                          else if (newValue) {
                            handleChangeAutocomplete('pais', newValue);
                          }
                        }}
                        optionLabel={option => {
                          if (option.inputValue) {
                            return option.inputValue;
                          }

                          if (typeof option === 'string') {
                            return (option);
                          }

                          return (option.nombre);
                        }
                        }
                        dispatchFunction={handleSearchPaises}
                        dataSearch={paisesData}
                      />
                    </FormControl>

                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Ciudad"
                      id="ciudad"
                      inputProps={{
                        onChange: handleChange,
                        name: "ciudad",
                        value: valores.ciudad,
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      error={errors.ciudad ? true : false}
                      success={!errors.ciudad}
                    />
                    {errors.ciudad && (
                      <FormErrorLabel>{errors.ciudad}</FormErrorLabel>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControl error className={classes.formControlAutocomplete}>
                      <AutocompleteComponent
                        multiple={false}
                        reduxFunction={false}
                        defaultValue={valores.nacionalidad}
                        label={'País de origen'}
                        name={'nacionalidad'}
                        type="text"
                        freeSolo={true}
                        success={!errors.nacionalidad}
                        fullWidth
                        onChange={(event, newValue) => {
                          if (typeof newValue === 'string') {
                            handleChangeAutocomplete('nacionalidad', newValue);
                          }
                          if (newValue && newValue.inputValue) {
                            handleChangeAutocomplete('nacionalidad', {
                              tipo: newValue.inputValue,
                              custom: true,
                            });
                          }

                          else if (newValue) {
                            handleChangeAutocomplete('nacionalidad', newValue);
                          }
                        }}
                        optionLabel={option => {
                          if (option.inputValue) {
                            return option.inputValue;
                          }

                          if (typeof option === 'string') {
                            return (option);
                          }

                          return (option.nombre);
                        }
                        }
                        dispatchFunction={handleSearchPaises}
                        dataSearch={paisesData}
                      />
                    </FormControl>

                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="tipo_sangre">Instrucción</InputLabel>
                      <Select
                        // labelId=""
                        name="instruccion"
                        id="instruccion"
                        value={valores.instruccion}
                        onChange={handleChange}
                        style={{ 'marginBottom': '43px' }}
                      >
                        {
                          selectInstruccionOptions.map((op, index) => (
                            <MenuItem key={index} value={op.value}>{op.label}</MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Ocupacion"
                      id="ocupacion"
                      inputProps={{
                        onChange: handleChange,
                        name: "ocupacion",
                        value: valores.ocupacion,
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      error={errors.ocupacion ? true : false}
                      success={!errors.ocupacion}
                    />
                    {errors.ocupacion && (
                      <FormErrorLabel>{errors.ocupacion}</FormErrorLabel>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="tipo_sangre">Estado civil</InputLabel>
                      <Select
                        // labelId=""
                        name="estado_civil"
                        id="estado_civil"
                        value={valores.estado_civil}
                        onChange={handleChange}
                        style={{ 'marginBottom': '43px' }}
                      >
                        {
                          selectEstadoCivilOptions.map((op, index) => (
                            <MenuItem key={index} value={op.value}>{op.label}</MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </GridItem>

                  {/* Tipo de sangre */}
                  <GridItem xs={12} sm={12} md={6} >
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="tipo_sangre">Tipo de sangre</InputLabel>
                      <Select
                        // labelId=""
                        name="tipoSangre"
                        id="tipo_sangre"
                        value={valores.tipoSangre}
                        onChange={handleChange}
                        style={{ 'marginBottom': '43px' }}
                      >
                        {
                          selectTipoSangreOptions.map((op, index) => (
                            <MenuItem key={index} value={op.value}>{op.label}</MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>

                  </GridItem>

                  {/* Habilitar edición */}
                  <GridContainer>
                    <GridItem xs={12} md={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={valores.habilitarEdicion}
                            onChange={handleChangeSwitch}
                            name="habilitarEdicion"
                            color="primary"
                          />
                        }
                        label="Datos filiación: Habilitar edición "
                      />
                      <div style={{ display: 'inline-flex', verticalAlign: 'top', marginTop: '6px' }}>
                        <InfoIcon style={{ cursor: 'pointer' }} color="primary" onClick={() => setOpenModalInfo(true)} />
                      </div>
                    </GridItem>
                  </GridContainer>

                  <GridItem xs={12} >
                    <GoogleMaps
                      values={{ lat: valores.direccion_latitud, lng: valores.direccion_longitud }}
                      onMapClickUpdate={handleChangeDireccionLatLng}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Contraseña anterior"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      id="anterior"
                      inputProps={{
                        onChange: handleChangePass,
                        name: "anterior",
                        value: valores.anterior,
                        type: "password",
                        placeholder: "********",
                      }}
                    />
                    {errors.anterior && (
                      <FormErrorLabel>{errors.anterior}</FormErrorLabel>
                    )}

                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Nueva contraseña"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      id="nueva"
                      inputProps={{
                        onChange: handleChangePass,
                        name: "nueva",
                        value: valores.nueva,
                        placeholder: "********",
                        type: "password",
                      }}
                    />
                    {errors.nueva && (
                      <FormErrorLabel>{errors.nueva}</FormErrorLabel>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Confirmar contraseña"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      id="nuevaConfirmacion"
                      inputProps={{
                        onChange: handleChangePass,
                        name: "nuevaConfirmacion",
                        value: valores.nuevaConfirmacion,
                        placeholder: "********",
                        type: "password",
                      }}
                    />
                    {errors.nueva && (
                      <FormErrorLabel>{errors.nuevaConfirmacion}</FormErrorLabel>
                    )}
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="warning" onClick={() => handleSubmit()}>
                  Actualizar Perfil
                </Button>
                {/* <Loading title="Cargando..." loading={loadingChangePass}> */}
                <Button color="warning" onClick={() => handleClickCambiar()}>
                  Cambiar contraseña
                </Button>
                {/* </Loading> */}
                <Button color="danger" onClick={() => setOpenRequestDeletionAccountModal(true)}>
                  Eliminar cuenta
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    </Loading>
  );
}

export default Dashboard;