import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import { Alert, AlertTitle } from "@material-ui/lab";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { Fade, FormControl, InputLabel, MenuItem, Modal, Paper, Select, TextField } from "@material-ui/core";
import Loading from "components/Loading";
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import { rolUpdate } from "redux/actions/aLogin";
import { useHistory } from "react-router";
import { validacionesRolDoctor, validacionesRolSecretaria } from "utils/validaciones";
import Notificacion from "components/ui/Notificacion";
import { createRol, requestCreateRol } from "redux/actions/aRegistro";
import ConfirmDialog from "components/Admin/CRUD/ConfirmDialog";
import { FormErrorLabel } from "components/forms/labels/FormErrorLabel";


const useStyles = makeStyles((theme) => (
  {
    ...styles,
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
      },
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    formControl: {
      margin: theme.spacing(1),
      width: '96%',
    },
    formRolContainer: {
      padding: '24px'
    },
    modalForm: {
      padding: '24px'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      width: "90%",
      maxWidth: 500,
    },
  }
));


const RoleChange = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [openModal, setOpenModal] = useState(false);

  // Confirm message
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const rolActual = useSelector((state) => state.rolActual);
  const rolesState = useSelector((state) => state.rol_cli);
  const loading = useSelector((state) => state.dataUser.isLoading);
  let roles = rolesState ? rolesState.split(",") : [];
  roles = roles.filter(r => r !== "");

  const [errors] = useState({});
  const [error, seterror] = useState({ title: "", mensaje: "" });


  const [rolValue, setRolValue] = useState(rolActual)

  // Form nuevo rol
  const [newRoleSelected, setNewRoleSelected] = useState('');

  // Request Form
  const [roleRequestForm, setRolRequestForm] = useState(false);

  const [requestKey, setRequestKey] = useState("");

  const [loadingSaveNewRol, setLoadingSaveNewRol] = useState(false);
  const firstRender = useRef(true);

  const usuario = useSelector((state) => state.dataUser.data);
  const valoresInit = useRef({
    nombres: (!!usuario.nombres && usuario.nombres !== 'Sin definir') ? usuario.nombres : "",
    apellidos: (!!usuario.apellidos && usuario.apellidos !== 'Sin definir') ? usuario.apellidos : "",
    email: !!usuario.email ? usuario.email : "",
    cedula_identidad: rolActual === 'ROLE_CLI' ? ((!!usuario.cedulaIdentidad && usuario.cedulaIdentidad !== 'Sin definir') ? usuario.cedulaIdentidad : "") : ((!!usuario.cedula_identidad && usuario.cedula_identidad !== 'Sin definir') ? usuario.cedula_identidad : ""),
    msp: ""
  });

  const [formNewRolValues, setFormNewRolValues] = useState(valoresInit.current);
  const [formNewRolErrors, setFormNewRolErrors] = useState({});

  const handleChange = (e) => {
    let { name, value } = e.target;
    setFormNewRolValues({ ...formNewRolValues, [name]: value });
  };

  const handleRequestNewRole = useCallback(
    async () => {
      try {
        setLoadingSaveNewRol(true);
        firstRender.current = false;
        let res = {};
        switch (newRoleSelected) {
          case 'ROLE_SEC':
            res = validacionesRolSecretaria(formNewRolValues);
            break;

          default:
            break;
        }
        console.log(res)
        if (Object.keys(res).length > 0) {
          setFormNewRolErrors(res);
          Notificacion({
            type: "error",
            text: "Debe completar los campos correctamente.",
          });
          setLoadingSaveNewRol(false);
          return;
        }

        const data = { ...formNewRolValues, newRole: newRoleSelected };
        const { transaccion } = await dispatch(requestCreateRol(data));
        if (transaccion) {
          setRolRequestForm(true);
        }
        setLoadingSaveNewRol(false);
      } catch (error) {
        setLoadingSaveNewRol(false);
        console.log(error);
      }
    },
    [formNewRolValues, newRoleSelected, dispatch],
  );

  const handleCreateNewRole = useCallback(
    async () => {
      try {
        setLoadingSaveNewRol(true);
        firstRender.current = false;
        let res = {};
        switch (newRoleSelected) {
          case 'ROLE_MED':
            res = validacionesRolDoctor(formNewRolValues);
            break;
          case 'ROLE_SEC':
            res = validacionesRolSecretaria(formNewRolValues);
            break;

          default:
            break;
        }
        if (Object.keys(res).length > 0) {
          setFormNewRolErrors(res);
          Notificacion({
            type: "error",
            text: "Debe completar los campos correctamente.",
          });
          setLoadingSaveNewRol(false);
          return;
        }

        const data = { ...formNewRolValues, newRole: newRoleSelected, key: requestKey };
        const { transaccion } = await dispatch(createRol(data));
        if (transaccion) {
          dispatch(rolUpdate(newRoleSelected, history));
        } else {
          setLoadingSaveNewRol(false);
        }
        // console.log({resp})
      } catch (error) {
        setLoadingSaveNewRol(false);
        console.log(error);
      }
    },
    [formNewRolValues, newRoleSelected, dispatch, history, requestKey],
  )
  useEffect(() => {
    //Validaciones form
    if (firstRender.current) return;

    switch (newRoleSelected) {
      case 'ROLE_MED':
        setFormNewRolErrors(validacionesRolDoctor(formNewRolValues));
        break;
      case 'ROLE_SEC':
        setFormNewRolErrors(validacionesRolSecretaria(formNewRolValues));
        break;

      default:
        break;
    }
    // //Verificar si se ha modificado el formulario
    // if (_.isEqual(valores, valoresInit.current)) setFormDirty(false);
    // else setFormDirty(true);
  }, [formNewRolValues, newRoleSelected]);

  //Fin form nuevo rol

  const handleChangeCombo = (event, values) => {
    setRolValue(event.target.value);
    // console.log(event.target.value);

  };
  const setearError = ({ title, mensaje }) => {
    seterror({ title, mensaje });
    setTimeout(() => {
      seterror({ title: "", mensaje: "" });
    }, 3000);
  };

  const getNameRol = (rol) => {
    switch (rol) {
      case "ROLE_ADMIN":
        return "Administrador"
      case "ROLE_ADMINSEC":
        return "Administrador Secretario"
      case "ROLE_CLI":
        return "Cliente/Paciente"
      case "ROLE_LAB":
        return "Laboratorista"
      case "ROLE_MED":
        return "Profesional"
      case "ROLE_SEC":
        return "Secretaria"
      case "ROLE_MED_2":
        return "Médico 2"
      default:
        return "Otro rol";
    }
  }

  const handleSubmit = async () => {
    try {
      dispatch(rolUpdate(rolValue, history));

    } catch (error) {
      setearError({
        title: "error",
        mensaje: "Ocurrio un error, intente nuevamente",
      });
    }
  };

  return (
    <Loading title="Cargando..." loading={loading}>
      <>
        {/* Modal agregar rol */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openModal}
          onClose={() => setOpenModal(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            {/* <Loading title="Guardando..." loading={loadingSaveNewRol}> */}
            <Paper className={classes.paper} square>
              <ConfirmDialog title={"Confirmación de eliminación"} open={openConfirmDialog}
                setOpen={setOpenConfirmDialog} onConfirm={() => newRoleSelected === 'ROLE_SEC' ? handleRequestNewRole() : handleCreateNewRole()}  >
                <div>¿Esta seguro de crear el nuevo rol con los datos ingresados?</div>
              </ConfirmDialog>
              <div style={{ textAlign: 'center' }}>
                <h4 style={{ marginBottom: '10px' }}>{roleRequestForm ? 'Confirmación de creación de rol' : 'Nuevo Rol'}</h4>
              </div>
              <div className={classes.formRolContainer}>
                <form className={classes.root} noValidate="noValidate" autoComplete="off">
                  <Loading title="Guardando..." loading={loadingSaveNewRol}>
                    <>
                      {
                        !roleRequestForm &&
                        <FormControl className={classes.formControl}>
                          <InputLabel htmlFor="grouped-select">Seleccione nuevo rol</InputLabel>


                          <Select defaultValue="" id="grouped-select" value={newRoleSelected} onChange={e => setNewRoleSelected(e.target.value)}>
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {
                              !roles.includes('ROLE_MED') &&
                              <MenuItem value="ROLE_MED">Doctor/a</MenuItem>
                            }
                            {
                              !roles.includes('ROLE_SEC') &&
                              <MenuItem value="ROLE_SEC">Secretaria/o</MenuItem>
                            }
                          </Select>


                        </FormControl>
                      }
                      {newRoleSelected && !roleRequestForm &&
                        <>
                          <TextField
                            id="nombres"
                            name="nombres"
                            label="Nombres"
                            variant="outlined"
                            style={{
                              width: '96%',
                            }}
                            value={formNewRolValues.nombres}
                            onChange={handleChange}
                            error={formNewRolErrors.nombres ? true : false}
                            helperText={formNewRolErrors.nombres}
                          />
                          <TextField
                            id="apellidos"
                            name="apellidos"
                            label="Apellidos"
                            variant="outlined"
                            style={{
                              width: '96%',
                            }}
                            value={formNewRolValues.apellidos}
                            onChange={handleChange}
                            error={formNewRolErrors.apellidos ? true : false}
                            helperText={formNewRolErrors.apellidos}
                          />
                          {/* <TextField
                            id="email"
                            name="email"
                            label="Email"
                            variant="outlined"
                            style={{
                              width: '96%',
                            }}
                            value={formNewRolValues.email}
                            onChange={handleChange}
                            error={formNewRolErrors.email ? true : false}
                            helperText={formNewRolErrors.email}
                          /> */}
                          <TextField
                            id="cedula_identidad"
                            name="cedula_identidad"
                            label="Cédula de identidad / Pasaporte"
                            variant="outlined"
                            style={{
                              width: '96%',
                            }}
                            value={formNewRolValues.cedula_identidad}
                            onChange={handleChange}
                            error={formNewRolErrors.cedula_identidad ? true : false}
                            helperText={formNewRolErrors.cedula_identidad}
                          />
                          {
                            newRoleSelected === 'ROLE_MED' &&
                            <TextField
                              id="msp"
                              name="msp"
                              label="Código Senescyt"
                              variant="outlined"
                              style={{
                                width: '96%',
                              }}
                              value={formNewRolValues.msp}
                              onChange={handleChange}
                              error={formNewRolErrors.msp ? true : false}
                              helperText={formNewRolErrors.msp}
                            />
                          }
                          <Button fullWidth size="lg" variant="outlined" color="primary" onClick={() => setOpenConfirmDialog(true)}>
                            Registrar Rol
                          </Button>
                        </>

                      }
                      {
                        roleRequestForm &&
                        <>
                          <div style={{ textAlign: 'center' }}>
                            Se ha enviado un código a tu correo electrónico, ingrésalo en el siguiente formulario para confirmar la creación del nuevo rol
                          </div>
                          <TextField
                            id="request_key"

                            label="Código de verificación"
                            variant="outlined"
                            style={{
                              width: '96%',
                            }}
                            value={requestKey}
                            onChange={(e) => setRequestKey(e.target.value)}
                          />
                          <Button fullWidth size="lg" variant="outlined" color="primary" onClick={handleCreateNewRole}>
                            Confirmar Solicitud
                          </Button>
                        </>
                      }
                    </>
                  </Loading>
                </form>
              </div>
            </Paper>
            {/* </Loading> */}
          </Fade>
        </Modal>
        {/* Fin Modal agregar rol */}

        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitle}>Roles de usuario</h4>
              </CardHeader>
              {error.mensaje && (
                <Alert style={{ margin: 20 }} severity={`${[error.title]}`}>
                  <AlertTitle>
                    {error.title === "error" ? "Error" : "Success"}
                  </AlertTitle>
                  {error.mensaje}
                </Alert>
              )}
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField id="select" label="Roles" value={rolValue} style={{ width: "100%" }} select onChange={handleChangeCombo}>
                      {
                        roles && roles.map((rol, key) => (
                          <MenuItem key={key} value={rol}>{getNameRol(rol)}</MenuItem>
                        ))
                      }
                      {/* <MenuItem value="ROLE_MED">Medico</MenuItem> */}
                    </TextField>
                    {errors.especializaciones && (
                      <FormErrorLabel>{errors.especializaciones}</FormErrorLabel>
                    )}
                  </GridItem>

                </GridContainer>
              </CardBody>
              <CardFooter>
                <div>
                  <Button color="primary" onClick={() => handleSubmit()}>
                    Seleccionar Rol
                  </Button>
                  {
                    !(roles.includes('ROLE_MED') && roles.includes('ROLE_SEC')) &&
                    <Button color="warning" onClick={() => setOpenModal(true)}>
                      Agregar Rol
                    </Button>
                  }
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    </Loading>
  );
};
export default RoleChange;
