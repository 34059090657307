import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { ErrorMessage } from "@hookform/error-message";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Notificacion from "components/ui/Notificacion";
import Loading from "components/Loading";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "../../CustomButtons/Button";
import { clienteActualizarAntecedentes } from "redux/actions/aClientes";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  formControl: {
    width: "100%",
    marginBottom: "25px",
  },
}));

export const PacienteAntecedentesForm = ({ setAntecedentesFormDirty }) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { id, alergias = '', antecedentes_familiares = '', antecedentes_personales = '' } = useSelector((state) => state.data_clientes.data);

  const { control, handleSubmit, errors, reset, formState: { isDirty } } = useForm();

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      reset(data);
      const { transaccion } = await dispatch(
        clienteActualizarAntecedentes(
          data,
          id
        ));

      setLoading(false);
      if (transaccion)
        Notificacion({
          type: "success",
          text: "Datos actualizados correctamente.",
        });
      else
        Notificacion({
          type: "error",
          text: "Ha ocurrido un error de procesamiento.",
        });

    } catch (error) {
      setLoading(false);
      Notificacion({
        type: "error",
        text: "Ha ocurrido un error de procesamiento.",
      });
    }

  }

  useEffect(() => {
    setAntecedentesFormDirty(isDirty)
  }, [isDirty, setAntecedentesFormDirty]);

  return (
    <div style={{ width: '100%' }}>
      <Loading title="Guardando..." loading={loading}>
        <form
          onSubmit={handleSubmit((data) =>
            onSubmit(data)
          )}>
          <GridContainer>
            {/* Antecedentes personales */}
            <GridItem xs={12} sm={12} md={6}>
              <FormControl error className={classes.formControl}>
                <Controller
                  name={`antecedentes_personales`}
                  control={control}
                  defaultValue={antecedentes_personales}
                  as={<TextField />}
                  error={errors[`antecedentes_personales`] ? true : false}
                  helperText={""}
                  // id="standard-full-width"
                  label='Personales'
                  style={{ margin: 8 }}
                  fullWidth
                  margin="normal"
                  multiline
                  minRows={4}
                  rules={{
                    // required: true
                  }}
                />
                <ErrorMessage
                  errors={errors}
                  name='antecedentes_personales'
                  render={({ message }) => (
                    <FormHelperText
                      className={classes.labelError}
                      id="component-error-text"
                    >
                      {message}
                    </FormHelperText>
                  )}
                />
              </FormControl>
            </GridItem>

            {/* Antecedentes familiares */}
            <GridItem xs={12} sm={12} md={6}>
              <FormControl error className={classes.formControl}>
                <Controller
                  name={`antecedentes_familiares`}
                  control={control}
                  defaultValue={antecedentes_familiares}
                  as={<TextField />}
                  error={errors[`antecedentes_familiares`] ? true : false}
                  helperText={""}
                  // id="standard-full-width"
                  label='Familiares'
                  style={{ margin: 8 }}
                  fullWidth
                  margin="normal"
                  multiline
                  minRows={4}
                  rules={{
                    // required: true
                  }}
                />
                <ErrorMessage
                  errors={errors}
                  name='antecedentes_familiares'
                  render={({ message }) => (
                    <FormHelperText
                      className={classes.labelError}
                      id="component-error-text"
                    >
                      {message}
                    </FormHelperText>
                  )}
                />
              </FormControl>
            </GridItem>

            {/* Alergias */}
            <GridItem xs={12} sm={12} md={6}>
              <FormControl error className={classes.formControl}>
                <Controller
                  name={`alergias`}
                  control={control}
                  defaultValue={alergias}
                  as={<TextField />}
                  error={errors[`alergias`] ? true : false}
                  helperText={""}
                  // id="standard-full-width"
                  label='Alergias'
                  style={{ margin: 8 }}
                  fullWidth
                  margin="normal"
                  multiline
                  minRows={3}
                  rules={{
                    // required: true
                  }}
                />
                <ErrorMessage
                  errors={errors}
                  name='alergias'
                  render={({ message }) => (
                    <FormHelperText
                      className={classes.labelError}
                      id="component-error-text"
                    >
                      {message}
                    </FormHelperText>
                  )}
                />
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer justifyContent="center" direction="row">
            <GridItem xs={"auto"}>
              <Button
                variant="contained"
                color="warning"
                type="submit"
              >
                Actualizar
              </Button>
            </GridItem>
          </GridContainer>
        </form>
      </Loading>
    </div>
  );
};

export default PacienteAntecedentesForm;
