import React from 'react'
import { TableHead, TableSortLabel } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CustomTableCellCrud from "components/Admin/CRUD/CustomTableCellCrud";
import Card from "components/Card/Card";

import TablePagination from '@material-ui/core/TablePagination';
import AddIcon from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Loading from 'components/Loading';
import { TableRowCrud } from 'components/Admin/CRUD/TableRowCrud';
import { FormCrud } from 'components/Admin/CRUD/FormCrud';
import ConfirmDialog from 'components/Admin/CRUD/ConfirmDialog';
import Button from "components/CustomButtons/Button";
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { useAdminCrud } from 'hooks/admin/useAdminCrud';
import { AdminLayout } from 'Views/Admin/AdminLayout';
import { SearchTextField } from 'components/forms/input/SearchTextField';
import { TableContainer } from 'components/Table/TableContainer';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import dashboardStyles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import CardFooter from 'components/Card/CardFooter';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

let useStyles = makeStyles(styles);
let useDashboardStyles = makeStyles(dashboardStyles);

const TableCellHeader = withStyles((theme) => {
  return {
    head: {
      color: theme.palette.common.white,
      fontWeight: "bolder",
    },
  };
})(TableCell);

const rowsPerPageDefault = 20;

const CRUD = ({ data, showNewButton = true, CustomCreateFormComponent, hideEditButton = false, hideDeleteButton = false, hideOptions = false, optionsButtonsCallback }) => {
  const classes = useStyles();
  const dashboardClasses = useDashboardStyles();
  // 0: tabla, 1:crear, 2: editar

  const { tableData, urls, foreignData, defaultSortByColumn, defaultSortOrder, defaultSearchText } = data;

  const {
    dataCRUD,
    error,
    handleChangePage,
    handleChangeRowsPerPage,
    handleClickCreate,
    handleClickDelete,
    handleClickEdit,
    handleClickReturn,
    handleConfirmDelete,
    handleInputSearchChange,
    initialStateFormComponents,
    loading,
    onSubmitForm,
    openConfirmDialog,
    page,
    rowsPerPage,
    search,
    setOpenConfirmDialog,
    setView,
    sortBy,
    sortOrder,
    tableTitles,
    total,
    view,

  } = useAdminCrud({
    defaultSortByColumn,
    defaultSortOrder,
    defaultSearchText,
    foreignData,
    hideOptions,
    rowsPerPageDefault,
    tableData,
    urls,
  })

  return (
    <AdminLayout viewState={view}>
      <div>
        <ConfirmDialog title={"Confirmación de eliminación"} open={openConfirmDialog}
          setOpen={setOpenConfirmDialog} onConfirm={handleConfirmDelete}  >
          <div>¿Esta seguro de eliminar este registro?</div>
        </ConfirmDialog>
        {
          view === 0 ?

            <div>
              {
                showNewButton &&
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonMargin}
                    onClick={handleClickCreate}
                    startIcon={<AddIcon />}
                  >
                    Nuevo
                  </Button>
                </div>
              }


              {/* Botón buscar */}
              <SearchTextField
                value={search}
                onChange={handleInputSearchChange}
                style={{ marginTop: '25px', marginBottom: '25px' }}
              />

              <Loading title="Cargando..." loading={loading}>

                <TableContainer component={Paper}>

                  <Table className={classes.table}>

                    <TableHead className={classes[`warningTableHeader`]}>

                      <TableRow className={classes.tableHeadRow}>
                        {tableTitles &&
                          tableTitles.map((item, index) => (
                            item.onClickSort ?
                              <TableCellHeader key={index}>
                                <TableSortLabel
                                  active={sortBy === item.columnName}
                                  direction={sortOrder}
                                  onClick={item.onClickSort}
                                  hideSortIcon={sortBy !== item.columnName}
                                  className={classes.tableCell + " " + classes.tableHeadCell}
                                >
                                  {item.title}
                                </TableSortLabel>
                              </TableCellHeader>
                              :
                              <TableCellHeader key={index} className={classes.tableCell + " " + classes.tableHeadCell}>
                                {item.title}
                              </TableCellHeader>
                          ))
                        }
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        dataCRUD &&
                        dataCRUD.map((item, index) => (
                          <TableRowCrud
                            key={item.id ?? index} keyValue={item.id} setView={setView} item={item} hideOptions={hideOptions}
                            handleEdit={(hideEditButton || hideOptions) ? null : handleClickEdit} handleDelete={(hideDeleteButton || hideOptions) ? null : handleClickDelete}
                            // optionsButtons={optionsButtons}
                            optionsButtonsCallback={optionsButtonsCallback ? optionsButtonsCallback(item) : undefined}
                          >
                            {tableTitles &&
                              tableTitles.map(({ name, formatData, objectName }) => (
                                name &&
                                <CustomTableCellCrud
                                  data={formatData ? formatData(item[objectName ?? name]) : item[name]} key={name}
                                />
                              ))}
                          </TableRowCrud>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Loading>
              <TablePagination
                rowsPerPageOptions={[10, 20, 100]}
                component="div"
                count={total ? +total : 10}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              {/* </Paper> */}
            </div>

            :
            (
              CustomCreateFormComponent && view === 1
                ?
                <Card className={classes.root}>
                  <CardHeader color="primary" size="sm" stats>
                    <h3 className={dashboardClasses.cardTitle}>Nuevo</h3>
                  </CardHeader>

                  <CardBody>
                    <CustomCreateFormComponent />
                  </CardBody>
                  <CardFooter>
                    <GridContainer>
                      <GridItem item xs={12}>

                        <Button
                          variant="contained"
                          color="warning"
                          className={classes.button}
                          onClick={handleClickReturn}
                        >
                          Regresar
                        </Button>

                      </GridItem>
                    </GridContainer>
                  </CardFooter>
                </Card>

                :
                <FormCrud setView={setView} edit={view === 2 ? true : false}
                  formComponents={initialStateFormComponents}
                  onSubmitForm={onSubmitForm} handleReturn={handleClickReturn} isLoading={loading}
                  hasError={error}
                />

            )
        }

      </div>
    </AdminLayout>

  )
}

export default CRUD;
