import React, { useState } from 'react';
import { CircularProgress, InputAdornment, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';

export const AutocompleteComponent = ({
  poblarData,
  freeSolo,
  onBuscadoChange,
  optionLabel,
  placeholder,
  dispatchFunction,
  reduxFunction = true,
  dataSearch,
  inputValue,
  defaultValue,
  id = 'searchComponent',
  name = 'searchComponent',
  onFocus,
  multiple = false,
  innerRef,
  filterOptions,
  onChange,
  getOptionSelected,
  // loading = false,
  value,
  ...props
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const data = dataSearch;

  const handleOnKeyUp = valor => {
    if (freeSolo && valor) callDebounce(valor);
  };

  const callFunction = async (valor) => {
    setLoading(true);
    if (reduxFunction) await dispatch(dispatchFunction(valor))
    else await dispatchFunction(valor)
    setLoading(false);
  }

  const callDebounce = _.debounce((valor) => callFunction(valor), 500)

  return (
    <Autocomplete
      defaultValue={defaultValue}
      filterOptions={filterOptions}
      freeSolo={freeSolo}
      getOptionLabel={optionLabel}
      getOptionSelected={getOptionSelected}
      id={id}
      loading={loading}
      loadingText='Buscando...'
      multiple={multiple}
      noOptionsText="No se encontraron resultados"
      onChange={onChange}
      options={freeSolo ? data : poblarData}
      ref={innerRef}
      value={value}
      disableClearable
      renderInput={params => (
        <TextField
          {...params}
          {...props}
          InputProps={{
            ...params.InputProps,
            ...props.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {/* {params.InputProps.endAdornment} */}
                {loading ? <CircularProgress color="inherit" size={20} /> :
                  <SearchIcon />
                }

              </InputAdornment>
            ),
          }}
          type="input"
          onKeyUp={(e) => dispatchFunction && handleOnKeyUp(e.target.value)}
          placeholder={placeholder}
        />
      )}
    />
  );
};
