import { urlEliminarNotificacionAdmin } from 'constants/urls';
import { urlNotificacionesInstitucion } from 'constants/urls';
import { urlCrearNotificacionPushGlobal } from 'constants/urls';
import { urlListarNotificacionesAdmin } from 'constants/urls';
import { createAction } from 'redux-actions';

//Notificaciones
export const notificaciones = createAction('NOTIFICACIONES');
export const isLoadingNotificaciones = createAction('IS_LOADING_NOTIFICACIONES');
export const hasErrorNotificaciones = createAction('HAS_ERROR_NOTIFICACIONES');
export const clearNotificaciones = createAction('CLEAR_NOTIFICACIONES');


export const loadNotificaciones = () => async (dispatch, getstate) => {
  dispatch(isLoadingNotificaciones());

  try {
    let notificacionesData = localStorage.getItem('notifications');
    if (notificacionesData) {
      notificacionesData = JSON.parse(notificacionesData);
      dispatch(notificaciones(notificacionesData));
    } else {
      dispatch(notificaciones([]));
    }
  } catch (error) {
    dispatch(hasErrorNotificaciones('No se pudo recuperar la información recargue la pagina '));
  }
};

export const fetchNotificacionesAdmin = (skip, take, search, sortBy = "fecha", sort = "desc") => async (dispatch, getstate) => {

  try {
    const { token } = getstate();

    var requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(`${urlListarNotificacionesAdmin}&skip=${skip}&take=${take}&search=${search}&orderBy=${sortBy}&order=${sort}`, requestOptions);
    const datos = await response.json();
    return datos;

  } catch (error) {
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const getNotificacionesInstitucion = () => async (dispatch, getstate) => {

  try {
    const { token } = getstate();

    var requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(`${urlNotificacionesInstitucion}`, requestOptions);
    const datos = await response.json();
    if (datos.transaccion) {
      return datos.data
    }

    throw (new Error('Error al cargar notificaciones de institución'));
  } catch (error) {
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const startEliminarNotificacionAdmin = (id) => async (dispatch, getstate) => {

  try {
    const { token } = getstate();

    var requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };

    //Eliminar notificacion de localStorage
    let notificacionesData = localStorage.getItem('notifications');
    if (notificacionesData) {
      notificacionesData = JSON.parse(notificacionesData);
      if (Array.isArray(notificacionesData)) {
        const notificacionesUpdated = notificacionesData.filter(n => n.id !== id);
        localStorage.setItem('notifications', JSON.stringify(notificacionesUpdated));
        dispatch(notificaciones(notificacionesUpdated));
      }
    }
    const response = await fetch(`${urlEliminarNotificacionAdmin}/${id}`, requestOptions);
    const datos = await response.json();
    return datos;

  } catch (error) {
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const crearNotificacionPushGlobal = (data) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    const raw = JSON.stringify(data);

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: raw
    };

    const response = await fetch(urlCrearNotificacionPushGlobal, requestOptions);
    const datos = await response.json();

    return datos;
  } catch (error) {
    return {
      transaccion: false,
      mensaje: "No se pudo recuperar la información recargue la pagina ",
    };
  }
};





