import React, { useState } from 'react';

//Core components
import { CircularProgress, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import SearchIcon from '@material-ui/icons/Search';
//Redux
import { useDispatch } from 'react-redux';

export const AutocompleteComponent = ({
  dataSearch,
  defaultValue,
  dispatchFunction,
  filterOptions,
  freeSolo,
  getOptionSelected,
  id = 'searchComponent',
  innerRef,
  inputValue,
  label,
  multiple = false,
  name = 'searchComponent',
  onBuscadoChange,
  onFocus,
  optionLabel,
  placeholder,
  poblarData,
  reduxFunction = true,
  renderOption,
  value,
  ...props
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const handleOnKeyUp = valor => {
    if (freeSolo && valor) callDebounce(valor);
  };

  const callFunction = async (valor) => {
    setLoading(true);
    if (reduxFunction) await dispatch(dispatchFunction(valor))
    else await dispatchFunction(valor)
    setLoading(false);
  }

  const callDebounce = _.debounce((valor) => callFunction(valor), 500)

  return (
    <Autocomplete
      defaultValue={defaultValue}
      filterOptions={filterOptions}
      freeSolo={freeSolo}
      getOptionLabel={optionLabel}
      getOptionSelected={getOptionSelected}
      id={id}
      loading={loading}
      loadingText='Buscando...'
      multiple={multiple}
      name={name}
      noOptionsText="No se encontraron resultados"
      onChange={onBuscadoChange}
      options={freeSolo ? dataSearch : poblarData}
      ref={innerRef}
      renderOption={renderOption}
      value={value}
      renderInput={params => (
        <TextField
          type="input"
          {...params}
          {...props}
          onKeyUp={(e) => handleOnKeyUp(e.target.value)}
          placeholder={placeholder}
          label={label}
          InputProps={{
            ...params.InputProps,
            ...props.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : <SearchIcon />}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),

          }}
        />
      )}

    />
  );
};
