
const customIconStyle = {
  svgIconStyle: {
    // color: "rgba'(255, 255, 255, 0.8)'",
    float: 'left',
    width: '24px',
    height: '30px',
    fontSize:' 24px',
    textAlign:' center',
    lineHeight:' 30px',
    marginRight:' 15px',
    verticalAlign:' middle',
  },
  
};

export default customIconStyle;
