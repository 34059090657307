import React from 'react';
import { useSelector } from 'react-redux';
// import HorarioOficinaSecretaria from '../components/horario-oficina/secretaria/horario-oficina.component';
import SelectMedico from 'components/SeleccionarMedico/SelectMedico.component';
import PacientesAtendidos from 'components/Pacientes/PacientesAtendidos/PacientesAtendidos.component';

const PacientesDoctor = () => {
  const doctor_id = useSelector(state => state.dataUser.data.doctor_id);
  const [medico, setMedico] = React.useState('');
  const [oficinasMedico, setOficinasMedico] = React.useState([]);
  if(!doctor_id)
    return (
      <>
        <SelectMedico
          medico={medico}
          setMedico={setMedico}
          oficinasMedico={oficinasMedico}
          setOficinasMedico={setOficinasMedico}
        />
        {medico !== '' && <PacientesAtendidos doctor_id={medico} />}
      </>
    );
  else{
    return ( <><PacientesAtendidos  doctor_id={doctor_id}/></>)
  }
};

export default PacientesDoctor;
