import React from 'react'
import moment from 'moment';
import { ErrorMessage } from '@hookform/error-message';
import { Controller } from 'react-hook-form';
import { Link } from "react-router-dom"
import { Checkbox, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, makeStyles, withStyles } from '@material-ui/core';
import formStyles from "assets/jss/material-dashboard-react/forms/formStyle"
import { dangerColor } from 'assets/jss/material-dashboard-react';
import { primaryColor } from 'assets/jss/material-dashboard-react';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    width: "100%",
    marginBottom: "0px",
  },
  formControSelect: {
    width: "96%",
    marginInline: "10px",
    marginBottom: "25px",
    marginTop: '10px',
  },
  textField: {
    width: "96%",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  linkLabel: {
    cursor: "pointer",
    color: `${primaryColor[0]} !important`,
    "&:hover": {
      textDecoration: "underline",
      // color: "rgba(0, 0, 0, 0.54) !important"
    }
  },
  button: {
    margin: 7,
  },
  ctnbtn: {
    display: "flex",
    justifyContent: "center",
  },
}));

const useFormStyles = makeStyles(formStyles);

const ValidationTextField = withStyles({
  root: {
    '& label.Mui-error': {
      color: dangerColor[0],
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-error fieldset': {
        borderColor: dangerColor[0],
      },
    },
  },
})(TextField);

// Material UI Form
export const TextFieldControlled = ({ control, name, errors, label, type = 'text', rules, defaultValue = '', variant = 'outlined', styles, formControlStyles }) => {

  const classes = useStyles();
  const formClasses = useFormStyles();

  return (
    <>
      <FormControl error className={classes.formControl}>
        <Controller
          className={classes.textField}
          name={name}
          control={control}
          defaultValue={type === 'date' ? moment().format("YYYY-MM-DD") : defaultValue ?? ''}
          as={<ValidationTextField />}
          error={errors[`${name}`] ? true : false}
          helperText={""}
          label={label}
          variant={variant}
          fullWidth
          margin="normal"
          rules={rules}
          type={type}
        />
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <FormHelperText className={formClasses.errorValidationLabel}>
              {message}
            </FormHelperText>
          )}
        />
      </FormControl>

    </>
  )
}

export const FormCheckboxMUIControlled = ({ id, control, name, errors, label, LabelComponent, link, rules, defaultValue = false, linkTarget = '_blank' }) => {
  const classes = useStyles();
  const formClasses = useFormStyles();

  return (
    <div style={{ marginLeft: '6px' }}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field, onChange }) => (
          <>
            <Checkbox
              style={{ paddingLeft: '0px' }}
              {...field}
              defaultChecked={
                defaultValue === true ? defaultValue : defaultValue === false ? false : false
              }
              onChange={(e) =>
                onChange(e.target.checked)
              }
            />
            {
              LabelComponent
                ?
                <LabelComponent />
                :
                <Link className={classes.linkLabel} to={link} target={linkTarget}>
                  {label}
                </Link>
            }
          </>

        )}
      />

      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <FormHelperText
            className={formClasses.errorValidationLabel}
          >
            {message}
          </FormHelperText>
        )}
      />
    </div>
  )
}
export const FormSelectMUIControlled = ({ id, control, name, defaultValue = '', errors, register, label, type = 'text', rules, options = [] }) => {

  const classes = useStyles();
  const formClasses = useFormStyles();

  return (
    <>
      <FormControl fullWidth className={classes.formControSelect} >
        <InputLabel htmlFor={name}>
          {label}
        </InputLabel>
        <Controller
          name={name}
          control={control}
          className={classes.formControl}
          defaultValue={defaultValue}
          rules={rules}
          as={
            <Select>
              {options.map((opt, index) => (
                <MenuItem value={opt.value} disabled={opt.disabled} key={index}>
                  {opt.label}
                </MenuItem>
              ))}
            </Select>
          }
        />
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <FormHelperText className={formClasses.errorValidationLabel}>
              {message}
            </FormHelperText>
          )}
        />
      </FormControl>
    </>
  )
}

// Form Native
export const FormInputControlled = ({ id, control, name, errors, register, formInputType, label, type = 'text', rules, defaultValue = '' }) => {
  return (
    <>
      <label htmlFor={id}>{label}</label><br />
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        rules={rules}
        render={(props) => {

          switch (formInputType) {
            case 'input':
              return (
                <>
                  <input
                    onChange={props.onChange}
                    ref={register}
                    type={type}
                    id={id}
                    name={name}
                  />
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => (
                      <div className='form-error-message-container'>
                        <div className='form-error-message'>
                          {message}
                        </div>
                      </div>

                    )}
                  />
                  <br />
                </>
              );
            case 'textarea':
              return (
                <>
                  <textarea
                    id={id}
                    onChange={props.onChange}
                    rows="3"
                  >
                  </textarea>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => (

                      <div className='form-error-message-container'>
                        <div className='form-error-message'>
                          {message}
                        </div>
                      </div>

                    )}
                  />
                </>
              );
            default:
              return null;
          }

        }}
      />
    </>
  )
}

export const FormCheckboxControlled = ({ id, control, name, errors, label, LabelComponent, link, rules, defaultValue = false, linkTarget = '_blank' }) => {

  return (
    <>
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        rules={rules}
        render={(props) => {
          return (
            <>
              <div className="check">
                <input
                  type="checkbox"
                  onChange={(e) => props.onChange(e.target.checked)}
                  id={id}
                  name={name}
                />
                <label htmlFor={id}>
                  {
                    LabelComponent
                      ?
                      <LabelComponent />
                      :
                      <>
                        {
                          link
                            ?
                            <Link to={link} target={linkTarget}>
                              {label}
                            </Link>

                            : <>{label}</>
                        }
                      </>


                  }
                </label>
              </div>
              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                  <div className='form-error-message-container'>
                    <div className='form-error-message'>
                      {message}
                    </div>
                  </div>

                )}
              />
            </>
          );
        }}
      />
    </>
  )
}

export const FormSelectControlled = ({ id, control, name, errors, register, label, type = 'text', rules, options = [] }) => {
  return (
    <>
      <label htmlFor={id}>{label}</label><br />
      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={''}
        render={(props) => {
          return (
            <>
              <select
                onChange={props.onChange}
                ref={register}
                type={type}
                id={id}
                name={name}
                defaultValue={''}
              >
                <option value={''} disabled >--Seleccione--</option>
                {
                  options.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                  ))
                }
              </select>

              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                  <div className='form-error-message-container'>
                    <div className='form-error-message'>
                      {message}
                    </div>
                  </div>

                )}
              />
            </>
          );
        }}
      />
    </>
  )
}

// Custom checkbox
export const FormCookiesTermsCheckboxControlled = ({ control, errors, register, name, link, mui = false }) => {
  const classes = useStyles();

  const LabelComponent = () => <span >
    Al registrarte, aceptas los <Link className={classes.linkLabel} to={'/terms'} target={'_blank'}>
      Términos de servicio</Link> y
    la <Link className={classes.linkLabel} to={'/privacy'} target={'_blank'}>Política de privacidad</Link>,
    incluida la política de <Link className={classes.linkLabel} to={'/cookies'} target={'_blank'}>Uso de Cookies</Link>.
  </span>;

  return (
    mui ?
      <FormCheckboxMUIControlled
        LabelComponent={LabelComponent}
        control={control}
        name={name}
        errors={errors}
        register={register}
        link={link}
        rules={{
          validate: (value) => !value ? 'Debe aceptar los términos y condiciones' : true
        }}

      />
      :
      <FormCheckboxControlled
        LabelComponent={LabelComponent}
        control={control}
        name={name}
        errors={errors}
        register={register}
        link={link}
        rules={{
          validate: (value) => !value ? 'Debe aceptar los términos y condiciones' : true
        }}
      />

  )
}