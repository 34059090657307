import React from "react";

//Components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";

import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';

//Custom Components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

//Icons
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import ConfirmationDialog from "components/dialog/custom-dialog.component";
import { validarServicioRangoFechaPromocion } from "utils/doctor/servicios/servicios";
import { Visibility } from "@material-ui/icons";


const useStyles = makeStyles(styles);
const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    fontWeight: "bolder",
  },
  body: {
    fontSize: 15,
  },
}))(TableCell);

export const ServiciosTable = ({
  fields,
  servicios,
  editService,
  deleteService,
  promotionsTable,
  promocionesRestantes
}) => {
  // console.log({ servicios })
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [id, setid] = React.useState("");
  const handleClickListItem = (id) => {
    setOpen(true);
    setid(id);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOk = () => {
    setOpen(false);
    deleteService(id);
  };

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table}>
        {fields !== undefined ? (
          // <TableHead className={classes[ "warning" + "TableHeader" + "primary"]}>
          <TableHead className={classes[`${'warning'}${'TableHeader'}${'primary'}`]}>
            <TableRow className={classes.tableHeadRow}>
              {fields.map(({ title }, key) => {
                return (
                  <StyledTableCell
                    align="left"
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {title}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {servicios.map((servicio) => (
            <TableRow key={servicio.id} className={classes.tableBodyRow}>
              {fields.map(({ name, formatValue, htmlString, formatObjectValues }, key) => {

                // console.log({ servicio })
                if (name) {
                  if (htmlString) {
                    return (
                      <TableCell key={key} className={classes.tableCell} dangerouslySetInnerHTML={{ __html: formatValue ? formatValue(servicio[name]) : servicio[name] }} />
                    );
                  } else {
                    return (
                      <TableCell key={key} className={classes.tableCell}>
                        {formatValue ? formatValue(servicio[name]) : servicio[name]}
                      </TableCell>
                    );
                  }
                } else if (!name && formatObjectValues) {
                  return (
                    <TableCell key={key} className={classes.tableCell}>
                      {formatObjectValues(servicio)}
                    </TableCell>
                  );
                } else {
                  return null;
                }
              })}

              <TableCell className={classes.tableActions}>
                <Tooltip
                  id="tooltip-servicio"
                  title={ ( (promotionsTable && (validarServicioRangoFechaPromocion(servicio) || promocionesRestantes === 0)) || (!promotionsTable && validarServicioRangoFechaPromocion(servicio)) ) ? "Ver servicio" : "Editar servicio"}
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <IconButton
                    aria-label="Edit"
                    className={classes.tableActionButton}
                    onClick={() => editService(servicio)}
                  >
                    {
                      ( (promotionsTable && (validarServicioRangoFechaPromocion(servicio) || promocionesRestantes === 0)) ||  (!promotionsTable && validarServicioRangoFechaPromocion(servicio)) )
                        ?
                        (<Visibility
                          className={
                            classes.tableActionButtonIcon + " " + classes.edit
                          }
                        />)
                        :
                        (<Edit
                          className={
                            classes.tableActionButtonIcon + " " + classes.edit
                          }
                        />)
                    }

                  </IconButton>
                </Tooltip>
                {
                 ( (promotionsTable && ( !(validarServicioRangoFechaPromocion(servicio)) && promocionesRestantes !== 0) ) || (!promotionsTable && !validarServicioRangoFechaPromocion(servicio))) &&
                  <Tooltip
                    id="tooltip-eliminar"
                    title="Eliminar servicio"
                    placement="top"
                    onClick={() => handleClickListItem(servicio.id)}
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <IconButton
                      aria-label="Close"
                      className={classes.tableActionButton}
                    >
                      <Close
                        className={
                          classes.tableActionButtonIcon + " " + classes.close
                        }
                      />
                    </IconButton>
                  </Tooltip>
                }
              </TableCell>
            </TableRow>
          ))}
          <ConfirmationDialog
            id={`${id}dialog`}
            keepMounted
            open={open}
            mensaje="este servicio?"
            onClose={handleClose}
            onConfirm={handleOk}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ServiciosTable;
