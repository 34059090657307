import CerrarSesion from "../Views/CerrarSesion";
import Cie10Crud from "Views/Admin/Cie10Crud";
import ClienteCrud from "Views/Admin/ClienteCrud";
import Contacto from "Views/Admin/Contacto";
import Dashboard from "@material-ui/icons/Dashboard";
import DoctorCrud from "Views/Admin/DoctorCrud";
import EspecializacionCrud from "Views/Admin/EspecializacionCrud";
import CategoriaEspecializacionCrud from "Views/Admin/CategoriaEspecializacionCrud";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HorarioOficinaCrud from "Views/Admin/HorarioOficinaCrud";
import LaboratoristaCrud from "Views/Admin/LaboratoristaCrud";
import NotificacionesPush from "Views/Admin/NotificacionesPush";
import OficinaCrud from "Views/Admin/OficinaCrud";
import ReservaCrud from "Views/Admin/ReservaCrud";
import RolesChange from "Views/Roles/RolesChange";
import SecretariaCrud from "Views/Admin/SecretariaCrud";
import SitiosRecomendadosCrud from "Views/Admin/SitiosRecomendadosCrud";
import TransaccionCrud from "Views/Admin/TransaccionCrud";
import Transactions from "Views/Admin/Transactions";
import UsuarioCrud from "Views/Admin/UsuarioCrud";
import VademecumCrud from "Views/Admin/VademecumCrud";
import { RolesIcon } from "components/CustomIcons/Sidebar/RolesIcons";
import { AyudaSoporteIcon } from "components/CustomIcons/Sidebar/AyudaSoporteIcon";
import Empresa from "Views/Admin/Empresa";
import DoctorPromocionesCrud from "Views/Admin/DoctorPromocionesCrud";
import { CiudadesAfiliacionCrud } from "Views/Admin/CiudadesAfiliacionCrud";
import CiudadesCrud from "Views/Admin/CiudadesCrud";
import SegurosCrud from "Views/Admin/SegurosCrud";
import { NoticiasCrud } from "Views/Admin/NoticiasCrud";
import { BlogsCrud } from "Views/Admin/BlogsCrud";
import VacunasCrud from "Views/Admin/VacunasCrud";
import ExamenCrud from "Views/Admin/ExamenCrud";
import { InstitucionesCrud } from "Views/Admin/InstitucionesCrud";


const layout = "/admin"
const dashboardRoutes = [
    {
        path: "/roles",
        name: "Roles",
        icon: RolesIcon,
        component: RolesChange,
        layout,
        rol: true,
    },
    {
        path: "/usuario",
        name: "Usuarios",
        icon: Dashboard,
        component: UsuarioCrud,
        layout
    },
    {
        path: "/cliente",
        name: "Clientes",
        icon: Dashboard,
        component: ClienteCrud,
        layout
    },
    {
        path: "/doctor",
        name: "Doctores",
        icon: Dashboard,
        component: DoctorCrud,
        layout
    },
    {
        path: "/promociones",
        name: "Promociones",
        icon: Dashboard,
        component: DoctorPromocionesCrud,
        layout
    },
    {
        path: "/secretarias",
        name: "Secretarias",
        icon: Dashboard,
        component: SecretariaCrud,
        layout
    },
    {
        path: "/laboratoristas",
        name: "Laboratoristas",
        icon: Dashboard,
        component: LaboratoristaCrud,
        layout
    },
    {
        path: "/especializacion",
        name: "Especializaciones",
        icon: Dashboard,
        component: EspecializacionCrud,
        layout
    },
    {
        path: "/categorias-especializacion",
        name: "Categorías",
        icon: Dashboard,
        component: CategoriaEspecializacionCrud,
        layout
    },
    {
        path: "/ciudades",
        name: "Ciudades",
        icon: Dashboard,
        component: CiudadesCrud,
        layout
    },
    {
        path: "/afiliacion-ciudades-especializacion",
        name: "Ciudades Afiliación",
        icon: Dashboard,
        component: CiudadesAfiliacionCrud,
        layout
    },
    {
        path: "/oficina",
        name: "Oficinas",
        icon: Dashboard,
        component: OficinaCrud,
        layout
    },
    {
        path: "/horariosOficinas",
        name: "Oficinas Horarios",
        icon: Dashboard,
        component: HorarioOficinaCrud,
        layout
    },
    {
        path: "/reservas",
        name: "Reservas",
        icon: Dashboard,
        component: ReservaCrud,
        layout
    },
    {
        path: "/transacciones",
        name: "Transacciones",
        icon: Dashboard,
        component: TransaccionCrud,
        layout
    },
    {
        path: "/movimientos",
        name: "Movimientos",
        icon: Dashboard,
        component: Transactions,
        layout
    },
    {
        path: "/vademecum",
        name: "Vademecum",
        icon: Dashboard,
        component: VademecumCrud,
        layout
    },
    {
        path: "/vacunas",
        name: "Vacunas",
        icon: Dashboard,
        component: VacunasCrud,
        layout
    },
    {
        path: "/examenes",
        name: "Exámenes",
        icon: Dashboard,
        component: ExamenCrud,
        layout
    },
    {
        path: "/ci10",
        name: "CIE10",
        icon: Dashboard,
        component: Cie10Crud,
        layout
    },
    {
        path: "/seguros",
        name: "Seguros",
        icon: Dashboard,
        component: SegurosCrud,
        layout
    },
    {
        path: "/sitios-recomendados",
        name: "Sitios Recomendados",
        icon: Dashboard,
        component: SitiosRecomendadosCrud,
        layout
    },
    {
        path: "/notificaciones-push",
        name: "Notificaciones Push",
        icon: Dashboard,
        component: NotificacionesPush,
        layout
    },
    {
        path: "/empresa",
        name: "Descuentos",
        icon: Dashboard,
        component: Empresa,
        layout
    },
    {
        path: "/noticias",
        name: "Noticias",
        icon: Dashboard,
        component: NoticiasCrud,
        layout
    },
    {
        path: "/blogs",
        name: "Blogs",
        icon: Dashboard,
        component: BlogsCrud,
        layout
    },
    {
        path: "/instituciones",
        name: "Instituciones",
        icon: Dashboard,
        component: InstitucionesCrud,
        layout
    },
    {
        path: "/contactos",
        name: "Ayuda y Soporte",
        icon: AyudaSoporteIcon,
        component: Contacto,
        layout
    },
    {
        path: "/cerrar-sesion",
        name: "Cerrar Sesión",
        icon: ExitToAppIcon,
        component: CerrarSesion,
        layout
    },

];

export default dashboardRoutes;