import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// import InputLabel from '@material-ui/core/InputLabel';// core components
import { Alert, AlertTitle } from '@material-ui/lab';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import Loading from 'components/Loading';

//tiempo

//Validaciones
import { validacionesPerfilSecretaria } from 'utils/validaciones';

import { laboratoristaUpdate, login as loginData } from 'redux/actions/aLogin';
import { urlBucket } from 'constants/urls';
import { FormErrorLabel } from 'components/forms/labels/FormErrorLabel';
// moment.locale("es");

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

const useStyles = makeStyles(styles);

const PerfilLaboratorista = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const state = useSelector(state => state);
  const usuario = useSelector(state => state.dataUser.data);

  const [errors, seterrors] = useState({});
  const [error, seterror] = useState({ title: '', mensaje: '' });

  const [loading, setloading] = useState(false);

  // const [cambioImagen, setcambioimagen] = useState(false);

  const [valores, setvalores] = useState({
    login: !!usuario.login ? usuario.login : '',
    titulo_honorifico: !!usuario.titulo_honorifico ? usuario.titulo_honorifico : '',
    email: !!usuario.email ? usuario.email : '',
    nombres: !!usuario.nombres ? usuario.nombres : '',
    apellidos: !!usuario.apellidos ? usuario.apellidos : '',
    foto_nombre: !!usuario.foto_nombre ? usuario.foto_nombre : '',
  });

  const fileField = React.useRef(null);

  function handleFileSelected(e) {
    const file = e.target.files[0];
    // divido para dos para 5MB max
    if (file.size / 1024 / 1024 < 5) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setvalores({ ...valores, foto_nombre: reader.result });
      };
    } else {
    }
  }

  const handleChange = e => {
    const { name, value } = e.target;
    setvalores({ ...valores, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      if (Object.keys(errors).length > 0) {
        setearError({
          title: 'error',
          mensaje: 'Debe completar los campos correctamente',
        });
        return;
      }
      const res = await dispatch(laboratoristaUpdate(valores, state));
      setloading(true);
      if (!res.error) {
        dispatch(loginData(res.nueva_data));
        setearError({
          title: 'success',
          mensaje: 'Datos actualizados correctamente',
        });
      } else {
        setearError({ title: 'error', mensaje: res.error });
      }
    } catch (err) {
      setearError({
        title: 'error',
        mensaje: 'Ocurrio un error, intente nuevamente',
      });
    }
    setloading(false);
  };
  const setearError = ({ title, mensaje }) => {
    seterror({ title, mensaje });
    setTimeout(() => {
      seterror({ title: '', mensaje: '' });
    }, 3000);
  };

  useEffect(() => {
    setvalores({
      login: usuario.login !== undefined ? usuario.login : '',
      titulo_honorifico: usuario.titulo_honorifico !== undefined ? usuario.titulo_honorifico : '',
      email: usuario.email !== undefined ? usuario.email : '',
      nombres: usuario.nombres !== undefined ? usuario.nombres : '',
      apellidos: usuario.apellidos !== undefined ? usuario.apellidos : '',
      // numero_telefono: usuario.numero_telefono !== undefined ? usuario.numero_telefono : '',
      foto_nombre: !!usuario.foto_nombre ? usuario.foto_nombre : '',
    });
  }, [usuario]);

  //Espero que se termine de setear los valores, para con esos valores validar
  useEffect(() => {
    seterrors(validacionesPerfilSecretaria(valores));
  }, [valores]);

  // const arrayBufferToBase64 = buffer => {
  //   var binary = '';
  //   var bytes = [].slice.call(new Uint8Array(buffer));
  //   bytes.forEach(b => (binary += String.fromCharCode(b)));
  //   return window.btoa(binary);
  // };

  return (
    <Loading title="Cargando..." loading={loading}>
      <>
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Editar Perfil</h4>
              </CardHeader>
              {error.mensaje && (
                <Alert style={{ margin: 20 }} severity={`${[error.title]}`}>
                  <AlertTitle>{error.title === 'error' ? 'Error' : 'Success'}</AlertTitle>
                  {error.mensaje}
                </Alert>
              )}
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <img width="150px" src={`${urlBucket}${valores.foto_nombre}`} alt='profileImage' />
                      <input
                        type="file"
                        name="imagen"
                        id="imagen"
                        accept=".jpeg, .png, .jpg"
                        onChange={handleFileSelected}
                        ref={fileField}
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Nombre de usuario"
                      formControlProps={{
                        fullWidth: true,
                        disabled: true,
                      }}
                      inputProps={{
                        id: 'login',
                        name: 'login',
                        value: valores.login,
                        enable: false,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Título Honorífico"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      id="titulo_honorifico"
                      inputProps={{
                        onChange: handleChange,
                        name: 'titulo_honorifico',
                        value: valores.titulo_honorifico,
                        placeholder: 'Sr.',
                      }}
                      error={errors.titulo_honorifico}
                      success={!errors.titulo_honorifico}
                    />
                    {errors.titulo_honorifico && (
                      <FormErrorLabel>{errors.titulo_honorifico}</FormErrorLabel>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      id="email"
                      inputProps={{
                        onChange: handleChange,
                        name: 'email',
                        value: valores.email,
                      }}
                      error={errors.email}
                      success={!errors.email}
                    />
                    {errors.email && <FormErrorLabel>{errors.email}</FormErrorLabel>}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Nombres"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      id="nombres"
                      inputProps={{
                        onChange: handleChange,
                        name: 'nombres',
                        value: valores.nombres,
                      }}
                      error={errors.nombres}
                      success={!errors.nombres}
                    />
                    {errors.nombres && <FormErrorLabel>{errors.nombres}</FormErrorLabel>}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Apellidos"
                      id="apellidos"
                      inputProps={{
                        onChange: handleChange,
                        name: 'apellidos',
                        value: valores.apellidos,
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      error={errors.apellidos}
                      success={!errors.apellidos}
                    />
                    {errors.apellidos && <FormErrorLabel>{errors.apellidos}</FormErrorLabel>}
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="warning" onClick={() => handleSubmit()}>
                  Actualizar Perfil
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    </Loading>
  );
};

export default PerfilLaboratorista;
