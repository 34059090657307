import { handleActions } from 'redux-actions';
import {
  empresa,

} from '../actions/aAdmin';

export const data_empresa = handleActions(
  {
    [empresa]: (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
      hasError: false,
    }),
  },
  { isLoading: false, hasError: false, data: {} }
);
