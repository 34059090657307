import React, { useEffect } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import { Typography } from '@material-ui/core';
import TodayIcon from '@material-ui/icons/Today';
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';

import ReservaStep from 'components/reservaciones/medico/reserva-step.component';
import { getHorariosReserva } from 'redux/actions/aOficinas';
import { cleanCliente } from 'redux/actions/aClientes';
import { useSelector, useDispatch } from 'react-redux';
import { getHorariosOficina } from 'redux/actions/aOficinas';
//REDUX

const useStyles = makeStyles(styles);

const Reservar = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const doctor_id = useSelector(state => state.dataUser.data.doctor_id);

  useEffect(() => {
    const init = async () => {
      await dispatch(cleanCliente());
      // await dispatch(getOficinasDoctor());
      await dispatch(getHorariosOficina(doctor_id));
      await dispatch(getHorariosReserva());
    };
    init();
  }, [doctor_id, dispatch]);

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <TodayIcon />
        <div borders="borderBottom">
          <Typography display="inline" variant="subtitle1">
            Reserva citas
          </Typography>
        </div>
      </div>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitle}>Reserva una cita</h4>
        </CardHeader>
        <CardBody>
          <ReservaStep />
        </CardBody>
      </Card>
    </div>
  );
};

export default Reservar;
