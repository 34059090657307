import React from 'react'
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => (
  {
    unorderedList: {
      'paddingLeft': '40px'
    }
  }
));

export const TermsConditions = () => {

  const classes = useStyles();

  return (
    <>
      <h2>Términos de uso</h2>
      <p>Estos términos y condiciones de uso (los “términos de uso”) aplican al uso del sitio web ubicado en
        www.medpal.com.ec, así como la aplicación o aplicaciones y dispositivos desarrollados por
        MEDAPPS S.H. como la aplicación o aplicaciones contenidas en las tiendas o “stores” de
        dispositivos móviles “PLAY STORE” y “APPSTORE”, o cualquier otro repositorio bajo el nombre
        “MEDPAL”. El sitio y sus diversos servicios, así como los textos, animaciones, imágenes son de
        propiedad de MEDAPPS S.H., AL UTILIZAR NUESTRA PLAFORMA Y/O SERVICIOS, usted acepta
        los términos y condiciones concernientes a los mismos, si usted no está de acuerdo, no use el sitio
        y/o los servicios relacionados.</p>

      <p>MEDAPPS S.H. se reserva el derecho exclusivo, y a su criterio de agregar, modificar o eliminar
        partes de los términos y condiciones descritos líneas abajo en cualquier momento, quedando a
        entera responsabilidad del usuario la constante revisión y verificación de los mismos de manera
        periódica para ver los cambios que se realicen. El uso continuo de nuestro sitio y servicios después
        de nuestros cambios, significará que se encuentra de acuerdo y a entera satisfacción con los
        mismos. El usuario siempre que respete lo estipulado en los “Términos y Condiciones de Uso” podrá
        seguir utilizando el sitio y los servicios que MEDAPPS S.H. brinda, caso contrario MEDAPPS S.H.
        podrá restringir su acceso.</p>

      <p>MEDAPPS S.H. se compromete a desarrollar, mantener y mejorar periódicamente herramientas
        tecnológicas, tales como aplicaciones, página web, interfaces de usuarios y almacenamiento de
        data, que permitan y faciliten la comunicación entre profesionales de la salud y pacientes, así como
        entre los diferentes colaboradores estratégicos que MEDAPPS S.H. adquiera, facilitando la
        interconexión entre todo el ecosistema de MEDAPPS S.H..
        MEDAPPS S.H. podrá terminar o modificar unilateralmente este contrato en cualquier momento sin
        previo aviso, así como, limitar el acceso a todo o parte de los servicios que ofrece.</p>

      <h2>Acerca del sitio</h2>
      <p>La plataforma virtual MEDPAL está pensada para brindar un acceso a servicios de salud,
        proporcionando una herramienta que facilite al usuario un acceso fiable y oportuno con un
        profesional de la salud a su elección, así mismo permite a los profesionales de la salud y a sus
        usuarios múltiples servicios, y a través de sus diversas plataformas.</p>

      <p>El sitio y servicios fungen como un medio para poner en contacto (agendamiento de citas y otros
        servicios), tanto a los usuarios (pacientes afiliados y generales) como a los médicos (afiliados y
        generales). Los servicios son de libre acceso y consulta para los usuarios, quienes tendrán libre
        visualización de la información y preparación profesional de los médicos enlistados; así como
        visualización y acceso a la agenda para programar la cita médica.</p>

      <p>Bajo ningún concepto el sitio y los servicios proporcionarán ningún tipo de asesoría médica,
        entendido esto, que todo el contenido visualizado y distribuido por el sitio y los servicios, así como
        de sus representantes, socios, empleados, patrocinadores y colaboradores es meramente
        informativo, y no deberán ser usados bajo ningún concepto como tratamiento ni prescripción
        médica, con lo cual el uso incorrecto, negligente e irresponsable de todos los datos y la información
        proporcionada será de entera responsabilidad de los usuarios y deslinda de toda responsabilidad a
        MEDAPPS S.H. y sus integrantes por los daños y perjuicios ocasionados por la incorrecta utilización
        de la información.</p>

      <p>El sitio y las diversas plataformas que integran MEDPAL pueden hacer uso de cookies para su
        correcto funcionamiento, por lo que se recomienda permitir el acceso a los mismos desde los
        diferentes dispositivos que se usen para acceder al sitio o plataformas de MEDPAL, si bloquea o
        rechaza el funcionamiento de los mismos es posible que el sitio y/o algunos servicios no se
        encuentren disponibles, por lo que en dicho caso, queda a su entera responsabilidad permitir o
        rechazar el uso de los cookies mientras acceda a nuestro sitio o servicios.</p>

      <p>Todos los mecanismos, objetos, gifs, cookies, imágenes etc., han sido pensados y utilizados para
        que las plataformas y el sitio funcionen de manera óptima, por lo que, si tiene algún problema,
        deberá comprobar que su dispositivo o conexión a internet se encuentren en buenas condiciones,
        así como comprobar que la aplicación se encuentre actualizada, antes de enviar una queja o
        sugerencia al administrador.</p>

      <p>El sitio podrá incluir y/o proporcionar anuncios publicitarios, ya sea propios o de terceros en la
        utilización del mismo, lo cual se procurará se integre como parte interactiva del uso del sitio, sin que
        afecte la interacción, transacciones o el uso mismo del sitio.</p>

      <h2>Servicios</h2>
      <p>Los servicios comprenden una aplicación, plataforma web, así como los distintos productos y
        servicios que se pueda implementar en un futuro además de información con acceso por conexión
        a la web y otras herramientas tecnológicas propiedad de MEDAPPS S.H. que permiten a los
        usuarios, tanto, pacientes y médicos, así mismo, programar y realizar consultas médicas, incluyendo
        todo lo necesario para la mejor experiencia en la consulta, diagnóstico (por parte del profesional de
        la salud), seguimiento y almacenamiento del historial de consultas y tratamientos que podrá el
        usuario y el médico visualizar dentro de nuestras plataformas.
        Los servicios y productos estarán disponibles para los usuarios, médicos y pacientes, sólo para su
        uso personal (a menos que utilice los créditos de su plan o membresía para un tercero) y facilitar la
        comunicación entre ellos; en ningún caso los usuarios o pacientes podrán utilizar la información
        para uso comercial, dicha acción sería causa de expulsión o restricción de acceso a la plataforma
        y/o servicios brindados. Para hacer uso de los servicios de la plataforma MEDPAL, usted deberá
        registrarse siguiendo las instrucciones que aparecen en la página web o en la aplicación de
        MEDAPPS S.H. que forma parte de este contrato.
        Tanto la aplicación como la página web son propiedad exclusiva de MEDAPPS S.H. y no podrá ser
        transferida, copiada, subcontratada, sin la autorización expresa de MEDAPPS S.H..</p>

      <p>Como expansión de los servicios que implementa MEDAPPS S.H., se tiene la creación de distintos
        módulos, que permitan el fácil manejo y utilización de los servicios dentro de las casas de salud, así
        como la intercomunicación departamental de los planteles de salud, y de ser posible la
        automatización de ciertos procesos internos, a fin de que puedan brindar una mejor experiencia al
        usuario final (paciente), y de esta manera mejorar y optimizar los servicios a nivel de consultoría,
        clínico y hospitalario.</p>

      <h2>Registro y Acceso</h2>
      <p><strong>MÉDICO / PROFESIONAL DE LA SALUD.- </strong> El médico o profesional de la salud que se registre en
        la plataforma, deberá llenar el formulario profesional que le será proporcionado, en donde deberá
        ingresar todos los datos allí requeridos, como credenciales, lugar de domicilio, ubicación del
        consultorio, teléfono, celular, correo electrónico, entre otros, así como todas las titulaciones
        obtenidas en sus estudios (detallando cada especialidad y universidad y/o casa de estudios). Todos
        los documentos ingresados y necesarios para el registro del profesional de la salud, serán validadas
        por MEDAPPS S.H., cuando se considere necesario, o por procesos internos de auditoría o
        revisiones generales que se programen, a fin de que se mantenga un control de los profesionales
        que integran nuestras listas; en caso de verificarse que existen inconsistencias en las titulaciones
        que dice haber obtenido el profesional de la salud, se aplicarán diversos mecanismos para solventar
        el problema, pudiendo llegar hasta a denunciar a las autoridades correspondientes, la falsedad de
        los títulos profesionales, y la expulsión del profesional de la aplicación y plataformas de MEDPAL.</p>

      <p><strong>USUARIO Y/O PACIENTE.- </strong> El usuario, de igual manera deberá, al momento de registrarse en la
        plataforma de MEDPAL, llenar el formulario de registro proporcionando todo los datos y llenando
        todos los campos requeridos, siempre proporcionando información fiable, que de igual manera será
        verificada a través de los mecanismos de control de MEDAPPS S.H.</p>

      <p>En general, para iniciar el uso de la plataforma y sus diversos servicios, previamente será necesario
        el registro en el mismo.</p>

      <p>Todos los datos proporcionados en el registro de nuestros servicios, serán validados por MEDAPPS
        S.H. a través de diversos mecanismos de control, a fin de que toda la información ingresada en la
        plataforma sea verídica y confiable para el usuario y de esa manera garantizar el mejor servicio
        posible. La falsedad en la información proporcionada podría ser utilizada por MEDAPPS S.H. a fin
        de procurar evitar cualquier tipo de estafa, fraude o delito relacionado a dicha información.</p>

      <p>Al llenar el formulario, el usuario/médico da fe que todos los datos en él consignados corresponden
        a la verdad, siendo el usuario el único responsable de inexactitudes diligenciadas y de los perjuicios
        causados a terceros por esa causa, así como toda documentación e información subida a la
        plataforma o base da datos de MEDPAL, donde MEDPAL y por ende MEDAPPS S.H. no tendrán
        responsabilidad alguna tanto civil, penal o administrativa por ello.</p>

      <p>Usted, como usuario, es responsable por la información suministrada y por la actividad de su cuenta,
        por tanto, deberá mantener la seguridad y confidencialidad de sus datos, tales como nombre de
        usuario y contraseña en completa reserva, dichas credenciales son de uso exclusivo del usuario y
        no podrán ser transferidas a un tercero.</p>

      <p>Cada vez que un profesional médico realice un registro y/o cambio en la historia clínica de un
        usuario-paciente, tal anotación será encriptada y no podrá ser susceptible de modificación, salvo
        por el médico que ingresó los datos, en cuanto al usuario/paciente dicha información solo podrá ser
        visualizada y no será susceptible de modificación.</p>

      <p>Usted como usuario es responsable por obtener el acceso a las redes de internet, requisito
        necesario para usar los servicios de MEDAPPS S.H.; igualmente será responsable por adquirir y
        actualizar los equipos o teléfonos móviles requeridos para tener acceso a las aplicaciones y
        productos de MEDAPPS S.H.. Queda entendido que el uso de los servicios de MEDAPPS S.H.
        podría generar gastos para usted, por el acceso a redes o medios de comunicación, los cuales
        serán de su única responsabilidad.</p>

      <p>Al utilizar el registro por referencias externas, esto es por medios como por ejemplo, Facebook,
        cuentas de Google y otros, usted acepta la verificación hecha por los terceros, así como la
        información que estos nos puedan proporcionar sobre usted, dicha información también será
        comprobada y en ciertos casos podría requerirse información adicional en caso de que esta esté
        incompleta, si no completare la información MEDPAL podría restringir el uso de ciertos mecanismos
        dentro de las plataformas hasta que dicha información se complete o se valide.</p>

      <p>MEDAPPS S.H. se reserva el derecho a aprobar o negar el acceso, ingreso, registro y/o uso a sus
        servicios en cualquier momento tanto a médicos como pacientes y sin que deba anteceder aviso,
        notificación, justificación y/o explicación alguna; así mismo, a exigir a los médicos afiliados la
        presentación de credenciales o títulos que le autoricen para el ejercicio de su profesión. Usted podrá
        utilizar los servicios una vez haya sido aprobado luego del proceso de selección realizado por
        MEDAPPS S.H.. </p>

      <h2>Privacidad</h2>

      <p>MEDAPPS S.H. recogerá información sobre los usuarios cuando se registren o utilicen la aplicación,
        pagina web o cualquier otra herramienta tecnológica desarrollada por MEDAPPS S.H.. La
        información recolectada por MEDAPPS S.H. es la que usted suministre al registrar su cuenta,
        realizar consultas médicas o comunicarse con MEDAPPS S.H. de cualquier forma, la simple
        interacción en la plataforma y el sitio de MEDPAL generará datos que serán recogidos y utilizados
        por MEDAPPS S.H.. Dicha información podrá incluir, aunque no estará limitada a su nombre, correo
        electrónico, número de teléfono, forma de pago, información de su teléfono móvil, los apartados que
        visite, así como cualquier otra información que resulte de su interés, etc. MEDAPPS S.H. garantiza
        un sistema de protección de datos de acuerdo a la legislación vigente y según eficientes métodos
        de seguridad, y tiene total libertad de hacer uso de esos datos como crea conveniente, para lo cual
        no necesitará ningún tipo de autorización por parte de los usuarios, pacientes o médicos.</p>

      <p>MEDAPPS S.H. y sus socios comerciales podrán utilizar la información recolectada para:</p>

      <ul className={classes.unorderedList}>
        <li>Mantener o mejorar sus servicios.</li>
        <li>Prevenir fraudes y abusos en el uso de los servicios.</li>
        <li>Resolver problemas operacionales.</li>
        <li>Realizar análisis de data.</li>
        <li>Llevar a cabo investigación y desarrollo.</li>
        <li>Hacer seguimiento a las actividades.</li>
        <li>Creación de balances de datos.</li>
        <li>Mercantilización de datos anónimos.</li>
      </ul>

      <p>Toda la información referida a los pacientes, tales como historias médicas, será protegida según
        mecanismos de seguridad.
        Usted como usuario es responsable por mantener los datos de su cuenta en forma segura y
        confidencial. Los mismos no podrán ser utilizados por terceros para acceder a los servicios de
        MEDPAL.</p>
      <p>Los servicios y productos provistos por MEDAPPS S.H., serán en la modalidad “como están
        establecidos” y según la disponibilidad, por tanto, MEDAPPS S.H. no reconocerá garantías ni
        responsabilidades, directas o indirectas por el acto del profesional de salud o por cualquier acción
        u omisión de alguno de los usuarios o cualquier persona que haga uso de la aplicación o servicios
        brindados, así como de las aplicaciones o herramientas tecnológicas ofrecidas por
        MEDPAL/MEDAPPS S.H..</p>

      <h2>Obligaciones del Usuario</h2>
      <p>Serán obligaciones del Usuario, las contenidas en la ley y las del presente clausulado:</p>
      <p>El usuario con el diligenciamiento del formulario de inscripción, da fe que la totalidad de los datos y
        documentos en él consignados corresponde a la verdad, siendo éste el único responsable por
        falsedades o inexactitudes y de los perjuicios que ocasione a terceros por tal acción, debiendo
        actualizar los datos en el caso de ser necesario.</p>
      <p>El usuario se obliga a proteger tanto su identificación/usuario como tal y su clave para acceder al
        servicio.</p>
      <p>El usuario se compromete a asumir el pago del servicio prestado cuando contrate o adquiera un
        plan o paquete de créditos difundidos por MEDPAL, en el evento en que se presenten dificultades
        de conectividad con el banco que expida la tarjeta de crédito registrada para efectuar los pagos o
        que no exista autorización del banco para que se realice la transacción, la aplicación no podrá
        procesar el pago, por lo que los créditos no serán adjudicados a su cuenta, en caso de que exista
        algún error interno y se cobre dos veces por el mismo producto, se podrá presentar el reclamo de
        reverso de cobro al soporte de MEDPAL.</p>
      <p>El usuario se compromete a informar a MEDAPPS S.H. cualquier irregularidad que observe en el
        funcionamiento de la plataforma.</p>

      <p>El usuario se abstendrá de efectuar maniobras ilícitas o fraudulentas, que puedan afectar derechos
        o intereses de MEDAPPS S.H., médicos o de terceros, para lo cual MEDAPPS S.H. podrá utilizar la
        información para reportar dichos actos a las autoridades competentes, además de la eliminación de
        la cuenta.</p>

      <p>El usuario se abstendrá de realizar cualquier actividad tendiente a sobrecargar o inutilizar la página
        web o la aplicación de  MEDAPPS S.H., caso contrario la cuenta podría ser inhabilitada o eliminada
        criterio de MEDPAL unilateralmente.</p>

      <p>El usuario médico limitará su acceso únicamente a las fichas médicas de los pacientes que
        expresamente le hayan autorizado el acceso, o que él mismo maneje (pacientes propios).</p>

      <p>El usuario podrá evaluar la atención prestada por el profesional de la salud que realice el servicio,
        en sección de comentarios que se pone a su disposición.</p>

      <p>El usuario autoriza a MEDAPPS S.H. a modificar el contenido y estructura de los formularios, y la
        respectiva actualización de estos cuando sea necesario por razones técnicas, de seguridad o por
        disposición interna de MEDAPPS S.H..</p>

      <p>El usuario se compromete a no difundir ningún tipo de información de propiedad exclusiva de
        MEDPAL y a MEDAPPS S.H., si eso llegara a suceder, será causa de expulsión del usuario de la
        plataforma, así como de inicio de acciones legales por parte de MEDAPPS S.H..</p>

      <h2>Obligaciones del Médico.</h2>
      <p>Son obligaciones del médico, las contenidas en la ley y en los siguientes clausulados.</p>

      <p>El médico, tendrá la obligación de adecuar su comportamiento y servicios a su profesión y a la rama
        de especialización en la que se desempeñe, pudiendo ser denunciado por el usuario/ paciente por
        comportamientos inadecuados y/o ajenos a cualquier comportamiento que se considere prudente o
        necesario dentro de la profesión médica, pudiendo el usuario y/o paciente realizar la denuncia a
        MEDAPPS S.H. o a las autoridades competentes de forma directa.</p>

      <p>El médico tendrá la obligación de absolver consultas, recetar, administrar los tratamientos que sean
        adecuados, siempre procurando el bienestar y la salud del paciente/usuario, en ningún caso
        administrará tratamiento médico o medicina que pueda afectar al paciente/usuario o que ponga en
        peligro su salud.</p>

      <p>El trato brindado por el médico a sus pacientes, será siempre con cortesía y amabilidad, a fin de
        que se establezca una buena relación con el paciente/usuario, y la afinidad médico paciente sea
        una ayuda para el tratamiento y el bienestar que busca el paciente/usuario.</p>

      <p>El valor cobrado por la consulta por el médico afiliado a la plataforma MEDPAL, será con el
        descuento para los pacientes/usuarios que utilicen nuestros planes de beneficios, en el porcentaje
        que previamente se establezca entre médico y MEDAPPS S.H., pudiendo incluso existir un convenio
        para otorgar descuentos en tratamientos médicos, que de la misma manera deberá ser
        consensuado entre médico y MEDAPPS S.H..</p>

      <p>MEDAPPS S.H. no será responsable por la mala práctica profesional por parte de los profesionales
        de la salud, así como de los tratamientos, actos y comportamientos de los médicos con sus
        pacientes, así como por ninguna circunstancia que pudiere generarse en la relación médicopaciente y que pudiere afectar a este último, pudiendo el paciente/usuario hacer uso de los
        mecanismos de denuncia y protección que estén a su alcance y frente a las autoridades
        competentes; en caso de malos tratos recibidos por el médico, el paciente/usuario podrá poner la
        queja correspondiente a la administración de MEDPAL, en cuyo caso se tomarán las medidas
        sancionadoras correspondientes, pudiendo incluso causar la expulsión del médico de la plataforma
        de MEDPAL.</p>

      <h2>Créditos y Planes de MEDPAL</h2>
      <p>El usuario paciente, podrá acceder a los planes y beneficios que ofrece MEDPAL en sus
        plataformas, mismo que constan en la adquisición de créditos, que le permitirá tener acceso a
        descuentos en las consultas con los diferentes profesionales de la salud, así como a los diferentes
        descuentos que mediante alianzas estratégicas MEDPAL logre establecer con los diferentes
        sectores de la salud que pueden abarcar, pero no estar limitado a farmacias, laboratorios,
        medicinas, spas, gimnasios, etc.</p>

      <p>Cada uno de los planes ofertados por MEDPAL tendrá su correspondiente valor en función de los
        beneficios que se otorgue y las ventajas que representa para el usuario, paciente o médico.</p>

      <p>Los créditos otorgados en los distintos planes podrán ser transferidos a un tercero, ya sea que éste
        tenga su correspondiente cuenta, como si no (en caso de beneficiar a un hijo menor de edad), en
        dicho caso se deberá detallar los datos completos del menor, así como el parentesco que tiene con
        el titular de los créditos, entre otros datos que se solicite.</p>

      <p>En caso de transferir los créditos a un tercero que tenga su cuenta activa en las plataformas de
        MEDPAL, esta transacción se hará mediante los mecanismos implementados dentro de las mimas
        plataformas de la aplicación MEDPAL.</p>

      <p>MEDPAL podrá implementar cualquier sistema de impulso de adquisición de créditos, por lo que
        podrá crear un sistema de recompensas por la compra de planes de créditos mediante referencias,
        el grado o tipo de recompensas que se reciba por este tipo de sistema, será a criterio de MEDPAL,
        dicha recompensa será notificada por MEDPAL en sus plataformas y estará a su disposición para
        su consulta.</p>

      <p>El profesional de la salud, tendrá la posibilidad de reservar una nueva cita para su paciente, en dicho
        caso el descuento del crédito para esa nueva cita, tendrá que ser autorizado por el usuario/ paciente
        dentro de su cuenta de MEDPAL, en caso de no autorizar el descuento o en su defecto no tener los
        créditos, este beneficio no se aplicará, pero si en caso de no contar con los créditos el
        usuario/paciente adquiere un nuevo plan de créditos MEDPAL, podrá abonar dichos créditos a las
        consultas y/o beneficios que se encuentren pendientes de aplicar los beneficios.</p>

      <p>Los créditos incluidos en los planes de MEDPAL, podrán ser utilizados una sola vez y en la cantidad
        que cada objeto del beneficio requiera.</p>

      <h2>De los Pagos/Forma de Pago.</h2>
      <p>Para tener acceso a todos los servicios que proporciona la plataforma de MEDPAL, se deberá
        realizar la compra de planes o beneficios, mediante los cuales podrá aprovechar la plataforma de
        forma completa.</p>

      <p>El pago de los distintos planes y/o beneficios ofrecidos por MEDPAL, se lo hará por vía electrónica,
        mediante el uso de una tarjeta de crédito o débito que pueda ser vinculada con nuestro colaborador
        y validada por el mismo a fin de que su pueda completar la transacción de una manera ágil y sencilla,
        tratando de que el usuario/paciente o médico pueda acceder a los servicios sin inconvenientes.</p>

      <p>Nuestro colaborador o colaboradores, será/n el/los responsable/s de la validación, verificación y
        comprobación de que los datos proporcionados y requeridos por Usted para el pago, así como que
        estos sean los necesarios y los correctos para que se realice la transacción.</p>

      <p>Nuestro colaborador o colaboradores, son entidades dedicadas a la transacción y comercialización
        electrónica, es decir, que MEDPAL al adquirir sus servicios, delega a dicho colaborador o
        colaboradores lo referente al cobro de los planes o beneficios que brinda en sus plataformas, en
        este caso nuestros colaboradores actúan como intermediarios para el procesamiento del pago de
        los distintos planes/servicios o beneficios que MEDPAL promociones a través de sus plataformas.</p>

      <p>El modo de registro de la forma de pago (tarjeta) será según las condiciones y estándares que
        maneje el colaborador dentro de los lineamientos internos, que pudiere abarcar, pero no limitar al
        ingreso de número de tarjeta, así como a su correspondiente código de seguridad, banco al que
        pertenece, detallar si es de crédito o débito y lo demás que considere necesario a fin de validar
        dicha información.</p>

      <h2>Avisos y Notificaciones</h2>
      <p>El correo electrónico y los datos facilitados por el Usuario durante el proceso de registro en la
        aplicación y en el sitio web, podrán ser utilizados por MEDAPPS S.H. para efectos de la prestación
        de servicios así como también para enviar distintas notificaciones al Usuario.</p>

      <p>Las notificaciones se realizarán a través del correo electrónico registrado por el usuario en el
        formulario de inscripción, MEDAPPS S.H. no se hará responsable en el evento que el usuario no
        haya actualizado los datos en el formulario para tal efecto, de igual manera se podrán hacer
        notificaciones dentro de las distintas plataformas en un apartado dedicado a ello.</p>

      <p>A través del correo electrónico proporcionado por el usuario, acepta que MEDAPPS S.H. envíe
        información acerca de los productos y servicios que se ofrece, así como de los productos y servicios
        nuevos que se introduzcan, de igual manera autoriza que se le envíen las ofertas y descuento, así
        como promociones y paquetes de nuestros productos y de terceros.</p>

      <h2>Cancelación de Usuario y Notificaciones</h2>
      <p>Una vez se produzca la solicitud de retiro del usuario, la exclusión se realizará de manera inmediata.</p>

      <p>El usuario es responsable por la utilización de su identificación como tal y su clave de acceso, de
        tal manera que, en el evento de presentarse suplantación personal, éste no tendrá derecho a
        reclamo alguno a MEDAPPS S.H..</p>

      <p>En caso que MEDAPPS S.H. descubra que el usuario ha faltado a la verdad, ingrese contenido
        inapropiado o incorpore inexactitudes en el proceso de registro, podrá inmediatamente y sin previo
        aviso excluir al usuario de sus bases de datos con la posterior notificación al correo electrónico del
        usuario, se procederá de la misma manera, cuando MEDAPPS S.H. descubra que algún usuario
        intenta o vulnera las seguridades y protecciones propias del servicio y sus plataformas.</p>

      <p>La falsedad de los datos personales y/o profesionales ingresados por el paciente o profesional de
        salud durante el proceso de registro en la plataforma o en cualquiera de los servicios prestados
        desde o a través del mismo conllevará la inmediata exclusión de la página web y de la aplicación,
        con pérdida de su condición de prestador del servicio, sin derecho a indemnización alguna y sin
        perjuicio de las correspondientes acciones legales a que por parte de MEDAPPS S.H.  o de terceros
        hubiere lugar.</p>

      <p>El incumplimiento por parte del usuario, de la presente declaración de términos y condiciones dará
        lugar a su exclusión de la base de datos de la plataforma de MEDAPPS S.H. sin derecho a
        indemnización alguna y sin perjuicio de las acciones legales que MEDAPPS S.H. pudiera adelantar
        en contra del usuario infractor.</p>

      <p>Los profesionales de la salud, en el evento de manifestar su intención de ser retirados de la base
        de datos de la plataforma de MEDAPPS S.H., aceptan que los conceptos emitidos por ellos respecto
        de los usuarios-pacientes continúan siendo de su responsabilidad.</p>

      <p>El servicio prestado por MEDAPPS S.H.  es de intermediación entre el usuario paciente y el
        profesional de la salud, para lo cual, MEDAPPS S.H. evaluó y verificó su idoneidad profesional,
        motivo por el cual no deriva ningún tipo de garantía respecto de la responsabilidad de la acción
        médica.</p>

      <p>Tanto el acceso a la plataforma como el uso que se le dé a la misma, es de la exclusiva
        responsabilidad del Usuario. MEDAPPS S.H. no asume responsabilidad derivada del uso de los
        datos contenidos en la plataforma, ni del uso no autorizado de la plataforma por parte de terceros.</p>

      <p>El usuario queda enterado y acepta que, al usar el servicio de atención en línea, la información
        recibida a través de éste medio, es a título informativo y es de exclusiva responsabilidad del
        profesional de salud siendo únicamente intermediarios para llegar al diagnóstico y tratamiento
        correspondiente, como tampoco sustituye la relación médico paciente ante la ley.</p>

      <p>La edad mínima para el registro en la aplicación de MEDAPPS S.H. es de dieciocho años (18)
        debiendo contar con una cédula de ciudadanía, de extranjería o pasaporte. La plataforma de
        MEDAPPS S.H.  podrá contener enlaces a otras páginas que están bajo responsabilidad de
        terceros, sin que la sociedad extienda su responsabilidad sobre el contenido de las mismas, en
        cuanto a informaciones, comunicaciones, opiniones, productos o servicios prestados u ofertados
        por otras personas o entidades.</p>

      <h2>Seguridad</h2>
      <p>MEDAPPS S.H. utiliza todos los medios tecnológicos a disposición para la protección de sus
        sistemas y la información de sus usuarios contra ataques cibernéticos, no obstante, el usuario
        entiende y acepta que los servicios prestados a través de la red se encuentran expuestos a diversas
        eventualidades.</p>

      <p>El usuario al suscribir el formulario de la aplicación de MEDAPPS S.H.  acepta que la prestación del
        servicio se presta a través de la red, sin que sea responsabilidad de la sociedad la interrupción del
        servicio a causa de sucesos ajenos que puedan ser clasificados como caso fortuito.</p>

      <p>MEDAPPS S.H.  queda eximida de cualquier tipo de responsabilidad de cualquier clase derivada de
        las consecuencias que pudieran producirse por la suspensión temporal o definitiva en la prestación
        del servicio de internet.</p>

      <p>Los profesionales de la salud aceptan que al registrarse en la plataforma los servicios, conceptos y
        resultados de éstos, sean utilizados por MEDAPPS S.H., para análisis estadísticos, propósitos de
        investigación y fines comerciales, siempre conforme a lo dispuesto en las normas vigentes en
        materia de protección de datos personales.</p>

      <h2>Límite de Responsabilidad</h2>

      <p>MEDAPPS S.H. en su afán de proporcionar un servicio a la comunidad en general, ha desarrollado
        el producto MEDPAL y sus diversas plataformas, desde una perspectiva de ayuda social y como
        una herramienta que pone al alcance y de manera accesible el servicio médico al público, en tal
        sentido no se hace responsable del uso que se le pueda dar al producto así como a las plataformas
        por parte de los participantes (usuarios - médicos), esto es uso e interpretación de la información
        generada y proporcionada en dichas plataformas, servicio e información por parte de los
        profesionales de la salud, medicamentos y tratamientos, información de páginas externas, servicios
        externos y ajenos al producto o plataformas; al ser un producto que funge como intermediario
        (conducto) entre los pacientes y sus médicos, MEDAPPS S.H. no es responsable por las consultas
        proporcionadas por los profesionales de la salud y por ende no realiza dicha actividad de ninguna
        forma, por lo que la comunicación y entendimiento entre los participantes (médico - paciente -
        servicios externos o de externos) de estas plataformas queda a entera responsabilidad de los
        mismos, en este sentido, al usar el producto MEDPAL en cualquiera de sus plataformas o cualquier
        otro producto de MEDAPPS S.H. Usted declara que libera de responsabilidad a MEDAPPS S.H.
        por cualquier tipo de inconveniente o cualquier tipo de mala interpretación sufrida a causa de la
        relación y uso del producto MEDPAL y sus plataformas o cualquier otro producto de MEDAPPS
        S.H., por lo que nada tendrá que reclamar bajo ningún concepto y por ninguna vía leal.</p>

      <h2>Quejas y Sugerencias</h2>
      <p>En caso de que se quiera presentar alguna queja o sugerencia a la administración de MEDPAL, el
        usuario/paciente podrá ponerse en contacto con nosotros mediante la misma plataforma, en la
        sección destinada para ello.</p>
    </>
  )
}
