import React, { useEffect } from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";

import Notifications from "@material-ui/icons/Notifications";

import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import { useDispatch, useSelector } from "react-redux";
import { loadNotificaciones } from "redux/actions/aNotificaciones";

import moment from "moment";
import { useHistory, useLocation } from "react-router";
import { startEliminarNotificacionAdmin } from "redux/actions/aNotificaciones";

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks() {
  const classes = useStyles();
  const [openNotification, setOpenNotification] = React.useState(null);
  // const [openProfile, setOpenProfile] = React.useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const handleClickNotification = event => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };

  const handleClickNotificationItem = (notification) => {
    try {
      const { linkTo, entity_id, id } = notification;
      setOpenNotification(null);

      //Enviar a local storage datos de la notificacion seleccionada
      localStorage.setItem('notificationSelected', JSON.stringify({ route: linkTo, entity_id }))
      if (location.pathname !== linkTo) {
        history.push(linkTo);
      } else {
        history.go();
      }

      //Eliminar notificacion de la base de datos
      dispatch(startEliminarNotificacionAdmin(id));

    } catch (error) {
      console.log("Ha ocurrido un error", error);
    }

  };
  // const handleClickProfile = event => {
  //   if (openProfile && openProfile.contains(event.target)) {
  //     setOpenProfile(null);
  //   } else {
  //     setOpenProfile(event.currentTarget);
  //   }
  // };
  // const handleCloseProfile = () => {
  //   setOpenProfile(null);
  // };

  useEffect(() => {
    //Cargar notificaciones
    dispatch(loadNotificaciones());
  }, [dispatch])
  const { data: notificaciones } = useSelector(state => state.data_notificaciones);
  const { data: user } = useSelector(state => state.dataUser);


  return (
    <div>
      {/* <div className={classes.searchWrapper}>
        <CustomInput
          formControlProps={{
            className: classes.margin + " " + classes.search
          }}
          inputProps={{
            placeholder: "Search",
            inputProps: {
              "aria-label": "Search"
            }
          }}
        />
        <Button color="white" aria-label="edit" justIcon round>
          <Search />
        </Button>
      </div> */}
      {/* <Button
        color={window.innerWidth > 959 ? "transparent" : "white"}
        justIcon={window.innerWidth > 959}
        simple={!(window.innerWidth > 959)}
        aria-label="Dashboard"
        className={classes.buttonLink}
      >
        <Dashboard className={classes.icons} />
        <Hidden mdUp implementation="css">
          <p className={classes.linkText}>Dashboard</p>
        </Hidden>
      </Button> */}
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openNotification ? "notification-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={classes.buttonLink}
        >
          <Notifications className={classes.icons} />
          {
            notificaciones.length > 0 &&
            <span className={classes.notifications}>{notificaciones.length}</span>
          }
          <Hidden mdUp implementation="css">
            <p onClick={handleCloseNotification} className={classes.linkText}>
              Notification
            </p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal={false}
          style={{ maxHeight: '250px', overflowY: 'scroll', zIndex: 1000 }}
          className={
            classNames({ [classes.popperClose]: !openNotification }) +
            " " +
            classes.popperNav
          }
        >
          {/* style={{zIndex: 1000000, backgroundColor:'red', position: 'sticky'}} */}
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper >
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu" >
                    {notificaciones.length > 0 ?
                      notificaciones.map((n, index) =>
                        n.tipo === "Reserva" ? (n.uid && user.id === n.uid &&
                          <MenuItem key={index} onClick={handleCloseNotification}
                            className={classes.dropdownItem}
                          >
                            {`Tienes una cita médica el día ${moment(n.r_fecha_reserva).format("DD-MMMM-YYYY")} de ${moment(n.r_inicio).format("HH:mm")} a ${moment(n.r_fin).format("HH:mm")}`}
                          </MenuItem>
                        ) : (n.tipo === "ROLE_ADMIN" || n.tipo === "ROLE_INS") && (
                          <MenuItem key={index} onClick={() => handleClickNotificationItem(n)}
                            className={classes.dropdownItem}
                          >
                            <strong>{`${moment(new Date(n.fecha)).format('DD-MM-YYYY h:mm a')}: `}</strong>
                            {`${n.descripcion}`}
                          </MenuItem>
                        )
                      )
                      : 'No existen notificaciones'
                    }
                    {/* <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      Mike John responded to your email
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      You have 5 new tasks
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      You{"'"}re now friend with Andrew
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      Another Notification
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      Another One
                    </MenuItem> */}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
      {/* <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <Person className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Profile</p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
       
            >
              <Paper styles={{zIndex: -3000000,
                backgroundColor: 'red',}}>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={classes.dropdownItem}
                    >
                      Profile
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={classes.dropdownItem}
                    >
                      Settings
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={classes.dropdownItem}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div> */}
    </div>
  );
}
