import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash';
import { TableHead, TableSortLabel } from "@material-ui/core";
import Button from "components/CustomButtons/Button";

import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CustomTableCellCrud from "components/Admin/CRUD/CustomTableCellCrud";

import TablePagination from '@material-ui/core/TablePagination';
import AddIcon from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Alert } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';

import Loading from 'components/Loading';
import { TableRowCrud } from 'components/Admin/CRUD/TableRowCrud';
import { FormCrud } from 'components/Admin/CRUD/FormCrud';
import ConfirmDialog from 'components/Admin/CRUD/ConfirmDialog';

import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

import medicoSVG from "../../../src/assets/images/icons/medico.svg";
import { AdminLayout } from './AdminLayout';
import { urlListarCategoriasEspecializaciones, urlCrearCategoriaEspecializacion, urlEditarCategoriaEspecializacion, urlEliminarCategoriaEspecializacion } from 'constants/urls';
import { loadData, createRecord, editRecord, deleteRecord, adminRestartState } from 'redux/actions/aAdmin';
import { SearchTextField } from 'components/forms/input/SearchTextField';
import { TableContainer } from 'components/Table/TableContainer';

let useStyles = makeStyles(styles);

const TableCellHeader = withStyles((theme) => {
  return {
    head: {
      color: theme.palette.common.white,
      fontWeight: "bolder",
    },
  };
})(TableCell);

const rowsPerPageDefault = 20;

const CategoriaEspecializacionCrud = () => {
  const classes = useStyles();
  const fileField = React.useRef(null);
  // 0: tabla, 1:crear, 2: editar
  const [view, setView] = useState(0);
  const [itemSelected, setItemSelected] = useState({});

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  //Ordenamiento
  const [orderData, setOrderData] = useState({
    sortBy: 'id', // default sort column
    sortOrder: "asc" // default sort oder
  })

  const { sortBy, sortOrder } = orderData;

  const dispatch = useDispatch();
  const { dataCRUD, dataPagination, dataForeign } = useSelector(state => state.data_admin);
  const { especializaciones } = dataForeign;
  const [especializacionesSelected, setEspecializacionesSelected] = useState([]);

  const { total } = dataPagination;
  //Formulario
  const initialState = {
    fotoNombre: null
  };

  const [formValues, setFormValues] = useState(initialState);
  const { fotoNombre } = formValues;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [skipLocal, setSkipLocal] = React.useState(0);


  const handleInputSearchChange = ({ target }) => {
    setSearch(target.value);
    debouncedSearch(target.value);
  }

  const debouncedSearch = React.useRef(
    _.debounce(async (searchValue) => {
      setSearchValue(searchValue);
    }, 350)
  ).current;

  const handleSelectChangeEspecializaciones = useCallback(
    (name, newValues) => {
      console.log(newValues);
      setEspecializacionesSelected(
        //Elimino que no ingrese repetidos
        newValues
          .map((item) => item)
          .filter((item, pos, self) => self.indexOf(item) === pos),
      );
    },
    [],
  )

  const initialStateFormComponents = useMemo(() => (
    [
      {
        type: "imageInput",
        name: "fotoNombre",
        value: !!itemSelected.foto_nombre ? itemSelected.foto_nombre : null,
        valueTemp: fotoNombre,
        label: "Imagen",
        imgDefault: medicoSVG,
        formValues,
        setFormValues,
        ref: fileField
      },
      {
        type: "inputDisabled",
        label: "Id",
        value: itemSelected.id,
        showOnCreate: false
      },
      {
        type: "input",
        name: "nombre",
        label: "Nombre",
        value: itemSelected.nombre ? itemSelected.nombre : "",
      },
      {
        type: "autocompleteMultiple",
        name: "especializaciones",
        label: "Especializaciones",
        value: especializacionesSelected,
        optionsLabel: (option) => (option ? option.nombre : null),
        renderOption: (option) => (
          <span>
            {`${option.nombre}`}
          </span>
        ),
        options: especializaciones,
        getOptionSelected: (option, value) => option.id === value.id,
        handleSelect: (value) => handleSelectChangeEspecializaciones("espdata", value),
      },
    ]
  ), [itemSelected, especializacionesSelected, fotoNombre, formValues, especializaciones, handleSelectChangeEspecializaciones]);

  const handleChangePage = useCallback(
    (event, newPage) => {
      if (page > newPage) {
        setSkipLocal(skipLocal - rowsPerPage);
      } else {
        setSkipLocal(skipLocal + rowsPerPage);
      }
      setPage(newPage);
    },
    [skipLocal, page, rowsPerPage],
  )

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
      setSkipLocal(0);
    },
    [],
  )

  const handleClickCreate = useCallback(
    () => {
      setFormValues(initialState);
      setEspecializacionesSelected([]);
      setItemSelected({});
      setView(1);
    },
    [initialState],
  )

  const handleClickEdit = useCallback(
    (item) => {
      setItemSelected(item);
      setEspecializacionesSelected(item.especializaciones);
      setView(2);
    },
    [],
  )

  const handleClickDelete = useCallback(
    item => {
      setItemSelected(item);
      setOpenConfirmDialog(true);
    },
    [],
  )

  const handleClickReturn = useCallback(
    () => {
      setFormValues(initialState);
      setItemSelected({});
      setEspecializacionesSelected([]);
      setView(0);
    },
    [initialState],
  )

  const onSubmitForm = useCallback(
    async (data, type) => {
      let dataForm = { ...data, especializaciones: especializacionesSelected };

      //Imagen
      if (fotoNombre) {
        dataForm = {
          ...dataForm,
          foto_nombre: fotoNombre
        }
      }
      // console.log({dataForm})
      // return
      if (type === "editar") {
        (!loading) && setLoading(true);
        let respuesta = await dispatch(editRecord(urlEditarCategoriaEspecializacion, itemSelected.id, dataForm));
        setLoading(false);
        const { transaccion, mensaje } = respuesta;
        !transaccion ? setError(mensaje) : setError(false);
      } else if (type === "crear") {
        (!loading) && setLoading(true);
        let respuesta = await dispatch(createRecord(urlCrearCategoriaEspecializacion, dataForm));
        setLoading(false);
        const { transaccion, mensaje } = respuesta;
        transaccion && setItemSelected({});
        !transaccion ? setError(mensaje) : setError(false);
      }
    },
    [loading, itemSelected, especializacionesSelected, fotoNombre, dispatch],
  )

  const handleConfirmDelete = useCallback(
    async () => {
      (!loading) && setLoading(true);
      let respuesta = await dispatch(deleteRecord(urlEliminarCategoriaEspecializacion, itemSelected.id));
      setLoading(false);
      const { transaccion, mensaje } = respuesta;
      !transaccion ? setError(mensaje) : setError(false);
    },
    [loading, itemSelected, dispatch],
  )

  //Ordenamiento
  const handleRequestSort = useCallback(
    async (sortByColumn) => {
      // console.log(sortBy);
      // console.log(sortOrder);
      let sortOrderNew;
      if (!sortBy || sortByColumn !== sortBy) {
        sortOrderNew = "asc";
        setOrderData({
          sortBy: sortByColumn, // default sort column
          sortOrder: sortOrderNew // default sort oder
        })
      } else {
        if (sortByColumn === sortBy) {
          sortOrderNew = sortOrder === "asc" ? "desc" : "asc"
          setOrderData({
            sortBy: sortByColumn, // default sort column
            sortOrder: sortOrderNew// default sort oder
          })
        }
      }

    },
    [sortBy, sortOrder],
  );

  const titulosTable = [
    { title: "Id", onClickSort: () => handleRequestSort("id"), columnName: "id" },
    { title: "Nombre", onClickSort: () => handleRequestSort("nombre"), columnName: "nombre" },
    { title: "Opciones" },
  ];

  const initLoadData = useCallback(
    async ({ skip = 0, take = rowsPerPageDefault, search = '', sortBy = 'fecha_reserva', sort = 'DESC' } = {}) => {
      setLoading(true);
      let respuesta = await dispatch(loadData({ url: urlListarCategoriasEspecializaciones, skip, take, search, sortBy, sort }));
      setLoading(false);
      const { transaccion, mensaje } = respuesta;

      !transaccion ? setError(mensaje) : setError(false);
    },
    [dispatch],
  )

  useEffect(() => {
    initLoadData({ skip: skipLocal, take: rowsPerPage, search: searchValue, sortBy, sort: sortOrder });
  }, [rowsPerPage, skipLocal, initLoadData, sortOrder, sortBy, searchValue]);

  useEffect(() => {
    return () => {
      dispatch(adminRestartState())
    }
  }, [dispatch])

  return (
    <AdminLayout viewState={view}>
      <div>
        <ConfirmDialog title={"Confirmación de eliminación"} open={openConfirmDialog}
          setOpen={setOpenConfirmDialog} onConfirm={handleConfirmDelete}  >
          <div>¿Esta seguro de eliminar este registro?</div>
        </ConfirmDialog>
        {error && <Alert severity="error">{error}</Alert>}
        {
          view === 0 ?

            <div>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonMargin}
                  onClick={handleClickCreate}
                  startIcon={<AddIcon />}
                >
                  Nuevo
                </Button>
              </div>

              {/* Botón buscar */}
              <SearchTextField
                value={search}
                onChange={handleInputSearchChange}
                style={{ marginTop: '25px', marginBottom: '25px' }}
              />

              <Loading title="Cargando..." loading={loading}>

                <TableContainer component={Paper}>

                  <Table className={classes.table}>

                    <TableHead className={classes["warningTableHeader"]}>

                      <TableRow className={classes.tableHeadRow}>
                        {titulosTable &&
                          titulosTable.map((item, index) => (
                            item.onClickSort ?
                              <TableCellHeader key={index}>
                                <TableSortLabel
                                  active={sortBy === item.columnName}
                                  direction={sortOrder}
                                  onClick={item.onClickSort}
                                  hideSortIcon={sortBy !== item.columnName}
                                  className={classes.tableCell + " " + classes.tableHeadCell}
                                >
                                  {item.title}
                                </TableSortLabel>
                              </TableCellHeader>
                              :
                              <TableCellHeader
                                key={index}
                                className={classes.tableCell + " " + classes.tableHeadCell}
                              >
                                {item.title}
                              </TableCellHeader>
                          ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        dataCRUD &&
                        dataCRUD.map((item) => (
                          <TableRowCrud key={item.id} keyValue={item.id} setView={setView} item={item}
                            handleEdit={handleClickEdit} handleDelete={handleClickDelete}>
                            <CustomTableCellCrud
                              // classes={classes.sizeCell}
                              data={item.id}
                            />
                            <CustomTableCellCrud
                              // classes={classes.sizeCell}
                              data={item.nombre}
                            />
                          </TableRowCrud>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Loading>
              <TablePagination
                rowsPerPageOptions={[10, 20, 100]}
                component="div"
                count={total ? +total : 10}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>

            :
            <FormCrud setView={setView} edit={view === 2 ? true : false}
              formComponents={initialStateFormComponents}
              onSubmitForm={onSubmitForm} handleReturn={handleClickReturn} isLoading={loading}
              hasError={error}
            />
        }

      </div>
    </AdminLayout>

  )
}

export default CategoriaEspecializacionCrud;
