import React from 'react'
import { LoginForm } from 'components/forms/register/native/LoginForm'

export const DeleteAccount = () => {

  return (
    <div className='doctor-landing-page'>
      <div id="form" className="form odd" style={{ marginTop: '10px' }}>
        <LoginForm deleteAccount title='Debes ingresar tus datos para confirmar la eliminación de tu cuenta.'/>
      </div>
    </div>
  )
}
