import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import IconButton from "@material-ui/core/IconButton";
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { consultarCajas, guardarCajas } from "redux/actions/aCajas";
import SelectMedico from "components/SeleccionarMedico/SelectMedico.component";
import { Typography, makeStyles } from "@material-ui/core";
import { createDataCajas } from "utils/secretaria/secretaria";
import { CajaRow } from "components/Secretaria/Cajas/CajaRow";
import { CajaPrint } from "components/Secretaria/Cajas/caja-print";
import { useReactToPrint } from "react-to-print";
import { cleanCliente } from "redux/actions/aClientes";
import { removeMoneyType } from "utils/secretaria/secretaria";
import Loading from "components/Loading";
import WarningLeaveFormPage from "components/ui/WarningLeaveFormPage";
import { validacionesCaja } from "utils/validaciones";
import Notificacion from "components/ui/Notificacion";

const useStyles = makeStyles(styles);

export const CajasSecretaria = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const componentRef = useRef();

  const secretaria_id = useSelector(
    (state) => state.dataUser.data.secretaria_id
  );
  const { data: cajas } = useSelector(state => state.datos_cajas);
  const { dataProgramas = [] } = useSelector(state => state.datos_cajas);
  const { doctorSelected } = useSelector(state => state.medicos_secretaria);
  const { data: clienteGlobal = null } = useSelector(state => state.data_clientes);

  const programasOptions = useMemo(() => dataProgramas ? dataProgramas.split(',') : [], [dataProgramas]);

  const [loading, setLoading] = useState(false);
  const [loadingText, setloadingText] = useState('Cargando...');

  const [serviciosMedico, setServiciosMedico] = React.useState([]);

  const [formDirty, setFormDirty] = useState(false);

  const [selectedDate, setSelectedDate] = React.useState(new Date());

  // Cliente Selected, 
  const [clienteSelected] = useState(clienteGlobal);

  const [cajasData, setCajasData] = useState([]);
  const [cajasStatsData, setCajasStatsData] = useState({
    totalCost: 0,
    checkedCost: 0,
  });

  // Errors
  const [formErrors, setFormErrors] = useState([]);

  // Options
  const servicesOptions = useMemo(() =>
    serviciosMedico
      ?
      ['Consulta', ...serviciosMedico.map(servicio => servicio.nombre)]
      :
      [
        "Consulta",
        "Consulta subsecuente",
        "Revisión de exámenes",
        "Intervención",
        "Cortesía",
        "Otro",
      ], [serviciosMedico]);


  const paymentOptions = useMemo(() => ["Efectivo", "Cheque", "Transferencia", "Tarjeta", 'PayPhone', "Otro"], []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const createCajaObject = useCallback(
    (cliente = null) => ({
      c_nombres: cliente ? cliente.nombres : '',
      c_apellidos: cliente ? cliente.apellidos : '',
      c_cedula_identidad: cliente ? cliente.cedula_identidad : '',
      r_tipo_servicio: servicesOptions[0],
      r_programa: programasOptions[0],
      r_forma_pago: paymentOptions[0],
      r_detalle: "",
      r_valor_cobro: "$0",
      c_id: new Date().getTime().toString(),
      newData: true,
      r_fecha: selectedDate,
      r_hora_pago: "",
      s_id: secretaria_id,
    }),
    [paymentOptions, programasOptions, servicesOptions, secretaria_id, selectedDate],
  )

  const addNewCajas = useCallback(
    (cliente = null) => {
      setFormDirty(true);
      setCajasData([
        ...cajasData,
        createCajaObject(cliente),
      ]);
    },
    [cajasData, createCajaObject],
  )

  useEffect(() => {
    dispatch(cleanCliente())
    let arr = [];
    let rows = [];

    if (Array.isArray(cajas)) {
      let sort_cajas = cajas;

      sort_cajas.forEach((caja, index) => {
        arr.push(caja);
      });

      if (arr.length !== 0) {
        rows.push(createDataCajas(arr))
      }

      const setClienteFlag = localStorage.getItem('setCliente');

      let cajasDataAux = [];
      if (rows.length !== 0) {
        cajasDataAux = rows[0].row_data
        // setCajasData(rows[0].row_data);
        setCajasStatsData({
          totalCost: rows[0].total_cost,
          checkedCost: rows[0].checked_cost
        })
      } else {
        setCajasStatsData({
          totalCost: 0,
          checkedCost: 0,
        })
      }

      if (setClienteFlag && clienteSelected)
        cajasDataAux = [...cajasDataAux, createCajaObject(clienteSelected)]

      setCajasData(cajasDataAux)
    }
  }, [cajas, createCajaObject, clienteSelected, dispatch]);

  useEffect(() => {
    let ini_total_cost = 0;
    let ckeckedCost = 0;
    cajasData.map(caja => {
      ini_total_cost += parseFloat(removeMoneyType(caja['r_valor_cobro']));
      if (caja.r_entregado) {
        ckeckedCost += parseFloat(removeMoneyType(caja['r_valor_cobro']));
      }
      return null;
    });
    setCajasStatsData({
      totalCost: ini_total_cost,
      checkedCost: ckeckedCost
    })
  }, [cajasData]);


  useEffect(() => {
    if (doctorSelected) {
      setLoadingState(true, 'Cargando...');
      dispatch(consultarCajas(secretaria_id, doctorSelected.id, selectedDate))
        .then(() => setLoadingState(false));
    }

  }, [dispatch, secretaria_id, selectedDate, doctorSelected]);

  useEffect(() => {
    return () => {
      localStorage.removeItem('setCliente');
      dispatch(cleanCliente())
    };
  }, [dispatch])

  const handleClickImprimir = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleNuevoCliente = (value, index) => {
    let newArr = [...cajasData];
    newArr[index].c_cedula_identidad = value.cedula_identidad;
    newArr[index].c_nombres = value.nombres;
    newArr[index].c_apellidos = value.apellidos;
    setCajasData(newArr);
  };

  const setLoadingState = (state, text = 'Cargando...') => {
    setLoading(state);
    setloadingText(text);
  }

  const handleSaveCajas = async (deliver = false) => {
    try {
      setLoadingState(true, 'Guardando...');
      const data = [];

      const currentMomentDate = moment(selectedDate);
      const cajaDateString = currentMomentDate.format("YYYY-MM-DD");

      let formErrorsUpdated = [...formErrors];

      for (let i = 0; i < cajasData.length; i++) {
        const item = cajasData[i];

        if (item['r_entregado'] === true) continue;

        // Establecer fecha de caja
        const cajaDateTime = item['r_hora_pago'] ?
          moment(cajaDateString + ' ' + item['r_hora_pago']).toISOString() :
          undefined;

        let caja = {
          id: item.r_id === undefined ? 0 : item.r_id,
          c_cedula_identidad: item['c_cedula_identidad'],
          c_id: item['c_id'],
          r_detalle: item['r_detalle'],
          r_entregado: item['r_entregado'],
          r_fecha: cajaDateTime,
          r_forma_pago: item['r_forma_pago'],
          r_hora_pago: item['r_hora_pago'],
          r_programa: item['r_programa'],
          r_tipo_servicio: item['r_tipo_servicio'],
          r_valor_cobro: item['r_valor_cobro'],
          s_id: item['s_id'],
          newData: item['newData'],
        };

        const errors = validacionesCaja(caja)

        if (errors) {
          const object = formErrorsUpdated.filter(item => item.id === caja.c_id);
          if (object.length > 0) {
            const cajaFind = object[0];
            formErrorsUpdated =
              formErrorsUpdated.map(item => item.id === cajaFind.id ? { id: item.id, ...errors } : item);

          } else {
            formErrorsUpdated = [...formErrorsUpdated, { id: caja.c_id, ...errors }]
          }

        } else {
          formErrorsUpdated =
            formErrorsUpdated.filter(item => item.id !== caja.c_id);
        }

        data.push(caja);
      }

      setFormErrors(formErrorsUpdated);

      if (formErrorsUpdated.length > 0) {
        Notificacion({
          type: "error",
          text: 'Debe completar los campos correctamente. ',
        });
        setLoadingState(false);
        return
      }

      await dispatch(guardarCajas({ data, deliver }, doctorSelected.id, new Date(selectedDate).toISOString()));

      await dispatch(consultarCajas(secretaria_id, doctorSelected.id, selectedDate));
      setLoadingState(false);

      // Reset form dirty state
      setFormDirty(false);

    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      {/* Warning leave page */}
      <WarningLeaveFormPage when={formDirty} />

      <SelectMedico setServiciosMedico={setServiciosMedico} />
      {doctorSelected && (
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitle}>Sistema de Caja</h4>
              </CardHeader>

              <CardBody>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "150px",
                    alignContent: "center",
                    marginBottom: "20px",
                  }}
                >
                  <span style={{ "marginRight": "20px" }}>Fecha</span>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-dd"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </div>

                <Loading title={loadingText} loading={loading}>
                  <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="right">Hora</TableCell>
                          <TableCell align="right">Nombre</TableCell>
                          <TableCell align="right">Apellido</TableCell>
                          <TableCell align="right">Identificación</TableCell>
                          <TableCell align="center">Tipo de servicio</TableCell>
                          <TableCell align="center">Programa</TableCell>
                          <TableCell>Detalle</TableCell>
                          <TableCell>Forma pago</TableCell>
                          <TableCell align="center">Costo</TableCell>
                          <TableCell>Entregado</TableCell>
                          <TableCell>Facturación</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          cajasData.map((caja, index) => (
                            <CajaRow
                              key={index}
                              cajasData={cajasData}
                              data={caja}
                              index={index}
                              handleNuevoCliente={handleNuevoCliente}
                              doctor_id={doctorSelected.id}
                              setData={setCajasData}
                              fecha={selectedDate}
                              serviciosMedico={serviciosMedico}
                              dataProgramas={programasOptions}
                              servicesOptions={servicesOptions}
                              paymentOptions={paymentOptions}
                              setFormDirty={setFormDirty}
                              formErrors={formErrors}
                            />
                          ))
                        }
                        <TableRow>
                          <TableCell colSpan={9} align={"center"}>
                            <IconButton onClick={addNewCajas} disabled={cajasData.length === 11}>
                              <AddCircleIcon />
                              Agregar Nuevo Registro
                            </IconButton>
                            <Button variant="contained" color="primary" onClick={() => handleSaveCajas()}>
                              Guardar
                            </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell align="right">
                            <Typography>Valor por pagar</Typography>
                            <Typography>Total</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>$ {cajasStatsData?.totalCost - cajasStatsData?.checkedCost}</Typography>
                            <Typography>$ {cajasStatsData?.totalCost}</Typography>
                          </TableCell>
                          {/* Div imprimir caja */}
                          <TableCell>
                            <div style={{ display: "none" }}>
                              <CajaPrint ref={componentRef} data={{ fecha: selectedDate, data: cajasData, total: cajasStatsData?.totalCost, checkedCost: cajasStatsData?.checkedCost }} />
                            </div>
                          </TableCell>
                          {/* Fin div imprimir caja */}
                          <TableCell align="center">
                            <Button variant="contained" color="primary" onClick={() => handleSaveCajas(true)}>
                              Entregado
                            </Button>
                            <Button variant="contained" color="primary" onClick={handleClickImprimir}>
                              Imprimir
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Loading>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      )}
    </>
  );
}
