export const computeTextColor = function (color, simple = false) {
  try {
    // console.log({color});
    const colorHex = (color.charAt(0) === "#") ? color.substring(1, 7) : color;
    // console.log({colorHex});
    if (colorHex.length !== 6) return '#ffffff';

    let aRgbHex = colorHex.match(/.{1,2}/g);
    // console.log({aRgbHex})
    let aRgb = [
      parseInt(aRgbHex[0], 16),
      parseInt(aRgbHex[1], 16),
      parseInt(aRgbHex[2], 16)
    ];

    let r = aRgb[0];
    let g = aRgb[1];
    let b = aRgb[2];

    if (simple) {
      return ((r * 0.299 + g * 0.587 + b * 0.114) > 186) ?
        '#000000' : '#ffffff';
    } // else complex formula
    let uicolors = [r / 255, g / 255, b / 255];
    let c = uicolors.map((c) => {
      if (c <= 0.03928) {
        return c / 12.92;
      } else {
        return Math.pow((c + 0.055) / 1.055, 2.4);
      }
    });

    let L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
    //console.log(L);
    return (L > 0.179) ? '#000000' : '#ffffff';

  } catch (error) {
    
  }

};