import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { cerrarsesion } from '../redux/actions/aLogin';
const CerrarSesion = props => {
  console.log("cerrar sesion button");

  useEffect(() => {
    // document.body.style.overflow = "hidden"; 
    localStorage.removeItem("notifications");
    props.cerrarsesion();
    props.history.replace({ pathname: `/` });
    // document.body.style.overflow = "";
  }, [props])

 
  
  return null;
};

const mapStateToProps = state => {
  return { books: state.books };
};
const mapDispatchToProps = dispatch => {
  return {
    cerrarsesion: () => dispatch(cerrarsesion()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CerrarSesion));
