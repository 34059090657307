/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons

import Person from "@material-ui/icons/Person";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import LocationOnIcon from "@material-ui/icons/LocationOn";
// core components/views for medico layout

import DashboardPage from "Views/Dashboard/DashboardMedico";
import RolesChange from "Views/Roles/RolesChange";
// import InicioDoctor from "Views/Inicio";
import Reservar from "Views/Reservar";
import CerrarSesion from "Views/CerrarSesion";
import HorarioCitas from "Views/HorarioCitas";
import Vadecum from "Views/Vadecum.view";
import Recetario from "Views/Recetario.view";
import AyudaSoporte from "Views/AyudaSoporte";
import Oficinas from "components/oficina/medico/oficina.component";
import PacientesDoctor from "Views/PacientesDoctor";
import ServiciosDoctor from "Views/ServiciosDoctor";
import PromocionesDoctor from "Views/Doctor/PromocionesDoctor";
// import Seguros from "components/Seguros/Seguro";
import { CitasAgendadasIcon } from "components/CustomIcons/Sidebar/CitasAgendadasIcon";
import { MisServiciosIcon } from "components/CustomIcons/Sidebar/MisServiciosIcon";
import { GestionPromocionesIcon } from "components/CustomIcons/Sidebar/GestionPromocionesIcon";
import { RolesIcon } from "components/CustomIcons/Sidebar/RolesIcons";
import { AgendarCitasIcon } from "components/CustomIcons/Sidebar/AgendarCitasIcon";
import { PacientesIcon } from "components/CustomIcons/Sidebar/PacientesIcon";
import { HorarioCitasIcon } from "components/CustomIcons/Sidebar/HorarioCitasIcon";
import { RecetarioIcon } from "components/CustomIcons/Sidebar/RecetarioIcon";
import { AyudaSoporteIcon } from "components/CustomIcons/Sidebar/AyudaSoporteIcon";
import { DoctorPerfil } from "Views/Doctor/DoctorPerfil";

const layout = "/medico";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Citas Agendadas",
    icon: CitasAgendadasIcon,
    component: DashboardPage,
    layout,
  },
  {
    path: "/pacientes-lista",
    name: "Mis Pacientes",
    icon: PacientesIcon,
    component: PacientesDoctor,
    layout,
  },

  // {
  //   path: "/seguros",
  //   name: "Seguros",
  //   icon: Security,
  //   component: Seguros,
  //   layout,
  // },
  {
    path: "/historia-clinica",
    name: "Historia Clínica",
    icon: RecetarioIcon,
    component: Recetario,
    layout,
  },
  {
    path: "/reservaciones",
    name: "Agendar Citas",
    icon: AgendarCitasIcon,
    component: Reservar,
    layout,
  },
  {
    path: "/vadecum",
    name: "Vademécum",
    icon: LocalHospitalIcon,
    component: Vadecum,
    layout,
  },
  {
    path: "/promociones",
    name: "Campañas",
    icon: GestionPromocionesIcon,
    component: PromocionesDoctor,
    layout,
  },
  {
    path: "/horario-citas",
    name: "Horarios",

    icon: HorarioCitasIcon,
    component: HorarioCitas,
    layout,
  },
  {
    path: "/servicios",
    name: "Mis Servicios",
    icon: MisServiciosIcon,
    component: ServiciosDoctor,
    layout,
  },
  {
    path: "/oficinas",
    name: "Oficinas",
    icon: LocationOnIcon,
    component: Oficinas,
    layout,
  },
  {
    path: "/user",
    name: "Mi Perfil",
    icon: Person,
    component: DoctorPerfil,
    layout,
  },
  {
    path: "/roles",
    name: "Roles",
    icon: RolesIcon,
    component: RolesChange,
    layout,
  },
  {
    path: "/ayuda",
    name: "Ayuda y Soporte",
    icon: AyudaSoporteIcon,
    component: AyudaSoporte,
    layout,
  },
  {
    path: "/cerrar-sesion",
    name: "Cerrar Sesión",
    icon: ExitToAppIcon,
    component: CerrarSesion,
    layout,
  },
];

export default dashboardRoutes;
