import { TextFieldControlled } from 'components/forms/reactHookForm/FormElementsControlled'
import React, { useMemo } from 'react'
import { rhRUCValidation } from 'utils/validaciones';
import { rhRequiredEmailValidation, rhRequiredFieldValidation } from 'utils/validaciones'

export const InstitucionRegisterForm = ({
  adminMode = false,
  register,
  control,
  errors,
}) => {

  const variant = useMemo(() => adminMode ? 'standard' : undefined, [adminMode]);

  return (
    <>
      {/* Nombre institución */}
      <TextFieldControlled
        id={'nombreInstitucion'}
        label={'Nombre de la institución:'}
        control={control}
        name={'nombre'}
        errors={errors}
        register={register}
        formInputType={'input'}
        rules={rhRequiredFieldValidation}
        variant={variant}
      />

      {/* Razón social */}
      <TextFieldControlled
        id={'razonSocial'}
        label={'Razón social:'}
        control={control}
        name={'razonSocial'}
        errors={errors}
        register={register}
        formInputType={'input'}
        rules={rhRequiredFieldValidation}
        variant={variant}
      />

      {/* RUC */}
      <TextFieldControlled
        id={'RUC'}
        label={'RUC:'}
        control={control}
        name={'RUC'}
        errors={errors}
        register={register}
        formInputType={'input'}
        rules={rhRUCValidation}
        variant={variant}
      />

      {/* Email */}
      <TextFieldControlled
        id={'email'}
        label={'Email:'}
        control={control}
        name={'email'}
        errors={errors}
        register={register}
        formInputType={'input'}
        rules={rhRequiredEmailValidation}
        variant={variant}
      />

      {/* Nombre de usuario */}
      <TextFieldControlled
        id='usuario'
        label='Nombre de usuario:'
        control={control}
        name='username'
        errors={errors}
        register={register}
        formInputType='input'
        type='text'
        rules={rhRequiredFieldValidation}
        variant={variant}
      />

    </>
  )
}
