import React, { useMemo, useState } from 'react'
import "moment/locale/es";
import MomentUtils from '@date-io/moment';
import { makeStyles } from '@material-ui/core';
import styles from 'assets/jss/material-dashboard-react/components/customInputStyle.js';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import Button from "../../CustomButtons/Button";
import Loading from "../../Loading";
import moment from 'moment';
import GridContainer from 'components/Grid/GridContainer';
import WarningLeaveFormPage from "components/ui/WarningLeaveFormPage";
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import { useFormServicios } from 'hooks/medico/servicios/useFormServicios';
import { Alert, AlertTitle } from '@material-ui/lab';
import { validacionesServicio2 } from 'utils/validaciones';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { KeyboardDatePickerCustom as KeyboardDatePicker } from 'components/forms/date/KeyboardDatePickerCustom';
import dashboardStyles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';

import { validarServicioRangoFechaPromocion } from 'utils/doctor/servicios/servicios';
import ConfirmDialog from 'components/Admin/CRUD/ConfirmDialog';
import { FormErrorLabel } from 'components/forms/labels/FormErrorLabel';


const useStyles = makeStyles((theme) => ({
  ...dashboardStyles,
  datePicker: {
    width: "100%",
    margin: "27px 0 0 0 !important"
  },
  datePickerLabel: {
    color: '#999',
    fontSize: '16px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '500',
    lineHeight: '1.42857',
  },
  ...styles
}));

export const ServicioPromocionesForm = ({ doctor_id, servicio, isLoading, addService, onCloseForm, promocionesRestantes, doctorEspecializaciones, ...props }) => {
  const classes = useStyles();

  const promocionVigente = useMemo(() => validarServicioRangoFechaPromocion(servicio), [servicio]);
  // const formDisabled = promocionVigente || promocionesRestantes === 0;
  const formDisabled = useMemo(() => promocionVigente || promocionesRestantes === 0, [promocionVigente, promocionesRestantes]);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  // Promociones
  const { valores, handleChange, handleChangeKeyboardDatePicker, handleClick, errors, error, formDirty } = useFormServicios({
    initStateForm: {
      nombre: servicio.nombre ?? "",
      descuento_valor: servicio.descuento_valor.toString() ?? '0',
      promocion_detalles: servicio.promocion_detalles ?? "",
      descuento_inicio: servicio.descuento_inicio ? moment.utc(servicio.descuento_inicio).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
      descuento_fin: servicio.descuento_fin ? moment.utc(servicio.descuento_fin).format("YYYY-MM-DD") : moment().add(3, 'days').format("YYYY-MM-DD"),
      precio: servicio.precio.toString() ?? '0',
      id: servicio.id,
      doctor_id: doctor_id,
      promocion: true,
      especializacion: servicio.especializacion ?? null,
    },
    addService,
    hasError: props.hasError,
    onCloseForm,
    validaciones: validacionesServicio2
  });

  return (
    <div className={classes.mainContent}>
      <ConfirmDialog
        title={"Confirmación de guardado"}
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={() => handleClick()}
        cancelText="Cancelar"
        confirmText="Aceptar"
      >
        <div>La creación de promociones está limitada a 12 por año. La promoción y el servicio relacionado no podrán ser modificados ni eliminados hasta la fecha de finalización. Si está de acuerdo y ha verificado los detalles de la promoción presione "Aceptar", caso contrario presione "Cancelar".</div>
      </ConfirmDialog>
      <Loading title="Cargando..." loading={isLoading}>
        <>
          {/* Warning on exit page */}
          {/* Form stateL: {(formDirty) ? 'form dirty' : 'form no dirty'} */}
          <WarningLeaveFormPage when={formDirty} />
          <Card style={{ paddingRight: "5px", paddingLeft: "5px" }}>
            <CardHeader color="primary">
              <strong className={classes.cardTitle}>{servicio.nombre}</strong>
            </CardHeader>

            {/* Gestión de promociones */}
            <form
              style={{ margin: "15px" }}
              className={classes.root}
              noValidate="noValidate"
              autoComplete="off"
            >
              <GridContainer>
                <GridItem xs={6}>
                  <MuiPickersUtilsProvider locale="es" libInstance={moment} utils={MomentUtils}>
                    <KeyboardDatePicker
                      className={classes.datePicker}
                      InputLabelProps={{
                        style: {
                          color: '#999',
                          fontSize: '16px',
                          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                          fontWeight: '500',
                          lineHeight: '1.42857',
                        },
                      }}
                      disabled={formDisabled}
                      disableToolbar
                      error={errors.descuento_inicio ? true : false}
                      variant="inline"
                      format="YYYY-MM-DD"
                      margin="normal"
                      id="descuento_inicio"
                      label="* Fecha de Inicio"
                      minDate={new Date()}
                      minDateMessage={null}
                      value={valores.descuento_inicio}
                      onChange={(value) => handleChangeKeyboardDatePicker(value, 'descuento_inicio')}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  {errors.descuento_inicio && (
                    <FormErrorLabel>{errors.descuento_inicio}</FormErrorLabel>
                  )}
                </GridItem>
                <GridItem xs={6}>
                  <MuiPickersUtilsProvider locale="es" libInstance={moment} utils={MomentUtils}>
                    <KeyboardDatePicker
                      className={classes.datePicker}
                      InputLabelProps={{
                        className: classes.datePickerLabel,
                        error: errors.descuento_fin ? true : false
                      }}
                      disableToolbar
                      disabled={formDisabled}
                      error={errors.descuento_fin ? true : false}
                      variant="inline"
                      format="YYYY-MM-DD"
                      margin="normal"
                      id="descuento_fin"
                      label="* Fecha de Finalización"
                      minDate={new Date()}
                      minDateMessage={null}
                      value={valores.descuento_fin}
                      onChange={(value) => handleChangeKeyboardDatePicker(value, 'descuento_fin')}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>

                  {errors.descuento_fin && (
                    <FormErrorLabel>{errors.descuento_fin}</FormErrorLabel>
                  )}
                </GridItem>

                <GridItem xs={6}>
                  <CustomInput
                    labelText="* Descuento (%)"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    id="descuento"
                    inputProps={{
                      onChange: handleChange,
                      name: "descuento_valor",
                      value: valores.descuento_valor,
                      disabled: formDisabled
                    }}
                    error={errors.descuento_valor ? true : false}
                    success={!errors.descuento_valor}
                  />
                  {errors.descuento_valor && (
                    <FormErrorLabel>{errors.descuento_valor}</FormErrorLabel>
                  )}
                </GridItem>

                <GridItem xs={6}>
                  <CustomInput
                    labelText="* Promoción detalles"
                    disabled={formDisabled}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    id="promocion_detalles"
                    inputProps={{
                      onChange: handleChange,
                      name: "promocion_detalles",
                      value: valores.promocion_detalles,
                      disabled: formDisabled,
                      multiline: true,
                      rows: 5,
                    }}
                    error={errors.promocion_detalles ? true : false}
                    success={!errors.promocion_detalles}
                  />
                  {errors.promocion_detalles && (
                    <FormErrorLabel>{errors.promocion_detalles}</FormErrorLabel>
                  )}
                </GridItem>

              </GridContainer>
              {
                !formDisabled &&
                <div
                  style={{
                    margin: "20px 8px",
                    flex: "1",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    disabled={formDisabled}
                    variant="outlined"
                    color="warning"
                    onClick={() => setOpenConfirmDialog(true)}
                  >
                    Guardar
                  </Button>
                </div>
              }
            </form>

          </Card>
        </>
      </Loading>
      {error.mensaje && (
        <Alert severity={`${[error.title]}`}>
          <AlertTitle>
            {error.title === "error" ? "Error" : "Success"}
          </AlertTitle>
          {error.mensaje}
        </Alert>
      )}
    </div>
  )
}
