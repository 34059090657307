import _ from 'lodash';

export const calcularPrecioFinal = (valor, descuento) => {
  let precioFinal = _.round(valor - (valor * (descuento / 100)), 2);
  if (precioFinal < 0) precioFinal = 0;
  return ((precioFinal % 1) > 0) ? precioFinal.toFixed(2) : precioFinal;
}

// Reserva
export const reservaCalcularPagoPendiente = (price, descuento, paidValue) => {
  if (!paidValue) paidValue = 0;
  const finalPrice = calcularPrecioFinal(price, descuento);
  const total = finalPrice * 100;
  return (total - paidValue) / 100;
}