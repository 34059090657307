import Loading from 'components/Loading'
import React, { useEffect, useMemo, useState } from 'react'
import Card from 'components/Card/Card'
import CardBody from "components/Card/CardBody.js"
import CardHeader from "components/Card/CardHeader.js"
import { Controller, useForm } from 'react-hook-form'
import Notificacion from 'components/ui/Notificacion'
import { FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, makeStyles } from '@material-ui/core'
import dashboardStyles from "assets/jss/material-dashboard-react/views/dashboardStyle"
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Button from "components/CustomButtons/Button.js";
import CardFooter from 'components/Card/CardFooter'
import { guardarFacturacionConfig } from 'redux/actions/aMedico'
import { useDispatch } from 'react-redux'
import { loadFacturacionParams } from 'redux/actions/aMedico'
import CustomInput from 'components/ReactHookForm/CustomInput'
import TextField from "@material-ui/core/TextField"
import { validarRUC } from 'utils/validaciones'
import WarningLeaveFormPage from 'components/ui/WarningLeaveFormPage'


const useStyles = makeStyles({
  ...dashboardStyles,
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 15,
    fontWeight: 'bold'
  },
  pos: {
    marginBottom: 12,
  },
  formControl: {
    width: "100%",
    marginBottom: "25px",
  },
  formControlSelect: {
    width: "100%",
    marginLeft: "10px",
  },
  formErrorCellphoneText: {
    color: '#f44336',
    margin: 0,
    fontSize: '0.75rem',
    marginTop: '3px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
  }
});
export const DoctorFacturacionConf = () => {

  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [data, setData] = useState({});

  const { control, handleSubmit, reset, errors, formState: { isDirty } } = useForm({
    defaultValues: useMemo(() => ({
      "razonSocial": data.razonSocial,
      "nombreComercial": data.nombreComercial,
      "ruc": data.ruc,
      "estab": data.estab,
      "ptoEmi": data.ptoEmi,
      "dirMatriz": data.dirMatriz,
      "dirEstablecimiento": data.dirEstablecimiento,
      "obligadoContabilidad": data.obligadoContabilidad || 'NO',
      "secuencial": data.secuencial,
      "facturacionActivada": data.facturacionActivada || false,
    }), [data])
  });


  const onSubmit = async (data) => {
    try {
      setData(data);
      setLoading(true);
      reset(data);
      await dispatch(guardarFacturacionConfig(data)).then(data => {
      }).catch(err => {
        setLoading(false);
      }
      );
      setLoading(false);

    } catch (error) {
      setLoading(false);
      Notificacion({
        type: "error",
        text: 'Ha ocurrido un error al guardar',
      });
    }
  };

  useEffect(() => {
    setLoading(true);

    dispatch(loadFacturacionParams()).then(({ data }) => {
      setData(data);
      console.log({ data })
      setLoading(false);
      reset(data)
    }).catch(err => setLoading(false))
  }, [dispatch, reset])


  return (

    <>
      {/* Warning on exit page */}
      <WarningLeaveFormPage when={isDirty} />

      <form
        onSubmit={handleSubmit((data) =>
          onSubmit(data)
        )}
      >
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitle}>Facturación electrónica</h4>
          </CardHeader>
          <CardBody>
            <Loading title="Cargando..." loading={loading}>
              <div>
                <GridContainer>
                  <GridItem xs={6} md={6}>
                    <Controller
                      name="facturacionActivada"
                      control={control}
                      render={({ onChange, value }) => {
                        return (
                          <FormControlLabel
                            control={
                              <Switch
                                checked={value}
                                onChange={(_, val) => onChange(val)}
                                color="primary"
                              />
                            }
                            label="Activado"
                          />
                        )
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} md={6}>
                    <CustomInput
                      formControlProps={{
                        error: true,
                        className: classes.formControl
                      }}
                      name={`razonSocial`}
                      control={control}
                      as={<TextField
                        InputLabelProps={{
                          className: classes.inputTextfield
                        }}
                      />}
                      error={errors[`razonSocial`] ? true : false}
                      helperText={""}
                      label="Razón social"
                      style={{ margin: 8 }}
                      fullWidth
                      margin="normal"
                      errors={errors}
                      rules={{
                        required: true
                      }}
                    />

                  </GridItem>
                  <GridItem xs={6} md={6}>
                    <CustomInput
                      formControlProps={{
                        error: true,
                        className: classes.formControl
                      }}
                      name={`ruc`}
                      control={control}
                      as={<TextField
                        InputLabelProps={{
                          className: classes.inputTextfield
                        }}
                      />}
                      error={errors[`ruc`] ? true : false}
                      helperText={""}
                      label="RUC"
                      style={{ margin: 8 }}
                      fullWidth
                      margin="normal"
                      errors={errors}
                      rules={{
                        // validate: (value) => isValidPhoneNumber(value),
                        validate: (value) => {
                          try {
                            if (!validarRUC(value)) {
                              return "El ruc debe tener 13 dígitos"
                            }
                            return true;

                          } catch (error) {
                            return "El ruc debe tener 13 dígitos"
                          }

                        }
                      }}
                    />

                  </GridItem>
                  <GridItem xs={6} md={6}>
                    <CustomInput
                      formControlProps={{
                        error: true,
                        className: classes.formControl
                      }}
                      name={`nombreComercial`}
                      control={control}
                      as={<TextField
                        InputLabelProps={{
                          className: classes.inputTextfield
                        }}
                      />}
                      error={errors[`nombreComercial`] ? true : false}
                      helperText={""}
                      label="Nombre comercial"
                      style={{ margin: 8 }}
                      fullWidth
                      margin="normal"
                      errors={errors}
                      rules={{
                        required: true
                      }}
                    />
                  </GridItem>

                  <GridItem xs={6} md={6}>
                    <CustomInput
                      formControlProps={{
                        error: true,
                        className: classes.formControl
                      }}
                      name={`estab`}
                      control={control}
                      as={<TextField
                        InputLabelProps={{
                          className: classes.inputTextfield
                        }}
                      />}
                      error={errors[`estab`] ? true : false}
                      helperText={""}
                      label="Establecimiento"
                      style={{ margin: 8 }}
                      fullWidth
                      margin="normal"
                      errors={errors}
                      rules={{
                        required: true
                      }}
                    />

                  </GridItem>


                  <GridItem xs={6} md={6}>
                    <CustomInput
                      formControlProps={{
                        error: true,
                        className: classes.formControl
                      }}
                      name={`ptoEmi`}
                      control={control}
                      as={<TextField
                        InputLabelProps={{
                          className: classes.inputTextfield
                        }}
                      />}
                      error={errors[`ptoEmi`] ? true : false}
                      helperText={""}
                      label="Punto de emisión"
                      style={{ margin: 8 }}
                      fullWidth
                      margin="normal"
                      errors={errors}
                      rules={{
                        required: true
                      }}
                    />

                  </GridItem>

                  <GridItem xs={6} md={6}>
                    <CustomInput
                      formControlProps={{
                        error: true,
                        className: classes.formControl
                      }}
                      name={`dirMatriz`}
                      control={control}
                      as={<TextField
                        InputLabelProps={{
                          className: classes.inputTextfield
                        }}
                      />}
                      error={errors[`dirMatriz`] ? true : false}
                      helperText={""}
                      label="Dirección matriz"
                      style={{ margin: 8 }}
                      fullWidth
                      margin="normal"
                      errors={errors}
                      rules={{
                        required: true
                      }}
                    />

                  </GridItem>

                  <GridItem xs={6} md={6}>
                    <CustomInput
                      formControlProps={{
                        error: true,
                        className: classes.formControl
                      }}
                      name={`dirEstablecimiento`}
                      control={control}
                      as={<TextField
                        InputLabelProps={{
                          className: classes.inputTextfield
                        }}
                      />}
                      error={errors[`dirEstablecimiento`] ? true : false}
                      helperText={""}
                      label="Dirección establecimiento"
                      style={{ margin: 8 }}
                      fullWidth
                      margin="normal"
                      errors={errors}
                      rules={{
                        required: true
                      }}
                    />

                  </GridItem>

                  <GridItem xs={6} md={6}>
                    <CustomInput
                      formControlProps={{
                        error: true,
                        className: classes.formControl
                      }}
                      name={`secuencial`}
                      control={control}
                      as={<TextField
                        type="number"
                        InputLabelProps={{
                          className: classes.inputTextfield
                        }}
                      />}

                      error={errors[`secuencial`] ? true : false}
                      helperText={""}
                      label="Secuencial"
                      style={{ margin: 8 }}
                      fullWidth
                      margin="normal"
                      errors={errors}
                      rules={{
                        required: true
                      }}
                    />

                  </GridItem>
                  <GridItem xs={6} md={6}>
                    <FormControl error={errors['obligadoContabilidad'] ? true : false} className={classes.formControl} >
                      <InputLabel shrink htmlFor={'obligadoContabilidad'}>
                        Obligado Contabilidad
                      </InputLabel>
                      <Controller
                        name={'obligadoContabilidad'}
                        control={control}
                        className={classes.formControl}
                        // defaultValue={formDataComponent.defaultValue ?? ''}
                        // rules={formDataComponent.validationOptions}
                        render={({ onChange, value }) => (
                          <Select
                            name={'obligadoContabilidad'}
                            value={value}
                            onChange={onChange}
                            labelId={'obligadoContabilidad'}
                            label="Campaign Budget"
                          >
                            <MenuItem value={'NO'}>
                              NO
                            </MenuItem>
                            <MenuItem value={'SI'}>
                              SI
                            </MenuItem>
                          </Select>
                        )}
                      />
                    </FormControl>
                  </GridItem>

                </GridContainer>
              </div>
            </Loading>
          </CardBody>
          <CardFooter>
            <Button
              color="warning" type="submit"
            >
              Guardar
            </Button>
          </CardFooter>
        </Card>
      </form>

    </>

  )
}
