import Notificacion from "components/ui/Notificacion";
import { createAction } from "redux-actions";
import { empresaId, urlConfirmTransaction, urlEditarPacientesAtendido, urlEliminarPacientesAtendido, urlGetClientes, urlGetCreditItem, urlGetPaisesBusqueda, urlListarDoctorClienteConsultas, urlListarPacientesAtendidos, urlSetClientes, urlSetClientesAntecedentes, urlSetClientesValoresBiometricos, urlVerificarReserva, urlVerificarReservaCreada } from "../../constants/urls";
import { jwt } from "./aLogin";

//Oficinas
export const clientes = createAction("CLIENTES");
export const isLoadingClientes = createAction("IS_LOADING_C");
export const hasErrorClientes = createAction("HAS_ERROR_C");
export const allclients = createAction("ALL_CLIENTS");
export const clientesBusqueda = createAction("CLIENTS_SEARCH");
export const loadPacientesAtendidos = createAction("LOAD_PACIENTES_ATENDIDOS");
export const paginationPacientes = createAction("LOAD_PAGINATION_PACIENTES_ATENDIDOS");
export const updatePacienteAtendido = createAction("UPDATE_PACIENTE_ATENDIDO");
export const deletePacienteAtendido = createAction("DELETE_PACIENTE_ATENDIDO");

export const getCliente = (campoCliente, doctor = "") => async (
  dispatch,
  getstate
) => {
  dispatch(isLoadingClientes());
  try {
    const state = getstate();
    const { token, dataUser } = state;
    let { doctor_id } = dataUser.data;

    if (doctor !== "") {
      doctor_id = doctor;
    }

    var requestOptions = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    return fetch(
      `${urlGetClientes}${campoCliente}/doctor/${doctor_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((datos) => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(""));
          return;
        }
        if (datos.transaccion) {
          dispatch(clientes(datos.data));
        } else {
          dispatch(clientes(""));
          dispatch(hasErrorClientes(datos.mensaje));
        }
      });
  } catch (error) {
    dispatch(clientes(""));
    dispatch(
      hasErrorClientes(
        "No se pudo recuperar la información recargue la pagina "
      )
    );
  }
};

export const clienteSet = (data, id) => async (dispatch, getstate) => {
  dispatch(isLoadingClientes());
  try {
    const state = getstate();
    const { token } = state;

    const raw = JSON.stringify(data);
    var requestOptions = {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: raw,
    };

    const response = await fetch(urlSetClientes, JSON.parse(JSON.stringify(requestOptions)));
    const datos = await response.json();

    const { token: tokenRes = true } = datos;
    if (!tokenRes) {
      dispatch(jwt(""));
      return;
    }
    if (datos.transaccion) {
      Notificacion({
        type: "success",
        text: "Paciente guardado correctamente.",
      });
      dispatch(clientes(datos.cliente));
      dispatch(hasErrorClientes(false));
    } else {
      // Notificacion({
      //   type: "error",
      //   title:"Error guardando",
      //   text: datos.mensaje ?? "No se pudo guardar el cliente.",
      // });
      dispatch(clientes(""));
      dispatch(hasErrorClientes(datos.mensaje));
    }

    return datos;

    // return fetch(urlSetClientes, JSON.parse(JSON.stringify(requestOptions)))
    //   .then((response) => response.json())
    //   .then((datos) => {
    //     const { token = true } = datos;
    //     if (!token) {
    //       dispatch(jwt(""));
    //       return;
    //     }
    //     if (datos.transaccion) {
    //       Notificacion({
    //         type: "success",
    //         text: "Cliente guardado exitosamente.",
    //       });
    //       dispatch(clientes(datos.cliente));
    //       dispatch(hasErrorClientes(false));
    //     } else {
    //       Notificacion({
    //         type: "error",
    //         title:"Error guardando",
    //         text: datos.mensaje ?? "No se pudo guardar el cliente.",
    //       });
    //       dispatch(clientes(""));
    //       dispatch(hasErrorClientes(datos.mensaje));
    //     }
    //   });
  } catch (error) {
    dispatch(clientes(""));
    dispatch(
      hasErrorClientes("No se pudo guardar la información recargue la pagina ")
    );
    return {
      transaccion: false,
      mensaje: "No se pudo recuperar la información recargue la pagina ",
    };
  }
};

export const clienteSetValoresBiometricos = (data, id) => async (dispatch, getstate) => {
  try {
    const state = getstate();
    const { token } = state;

    const raw = JSON.stringify(data);
    var requestOptions = {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: raw,
    };

    const response = await fetch(urlSetClientesValoresBiometricos + id, JSON.parse(JSON.stringify(requestOptions)))

    const datos = await response.json();
    dispatch(clientes({
      ...state.data_clientes.data,
      talla: datos.data.talla,
      peso: datos.data.peso,
      alergias: datos.data.alergias,
      datos_relevantes: datos.data.datos_relevantes,
    }));

    return { transaccion: true, mensaje: 'Datos biométricos del paciente actualizados correctamente' }

  } catch (error) {
    return { transaccion: false, mensaje: 'No se pudo actualizar datos biométricos del paciente' }
  }
};

export const clienteActualizarAntecedentes = (data, id) => async (dispatch, getstate) => {
  try {
    const state = getstate();
    const { token } = state;

    const raw = JSON.stringify(data);
    var requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: raw,
    };

    const response = await fetch(urlSetClientesAntecedentes + id, JSON.parse(JSON.stringify(requestOptions)))

    const datos = await response.json();
    dispatch(clientes({
      ...state.data_clientes.data,
      talla: datos.data.talla,
      peso: datos.data.peso,
      alergias: datos.data.alergias,
      datos_relevantes: datos.data.datos_relevantes,
    }));

    return { transaccion: true, mensaje: 'Datos biométricos del paciente actualizados correctamente' }

  } catch (error) {
    return { transaccion: false, mensaje: 'No se pudo actualizar datos biométricos del paciente' }
  }
};

export const getAllClientList = () => async (dispatch, getstate) => {
  dispatch(isLoadingClientes());
  try {
    const state = getstate();
    const { token } = state;

    var requestOptions = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    return fetch(`${urlGetClientes}getall`, requestOptions)
      .then((response) => response.json())
      .then((datos) => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(""));
          return;
        }
        if (datos.transaccion) {
          dispatch(allclients(datos.data));
        } else {
          // dispatch(allclients(''));
          dispatch(hasErrorClientes(datos.mensaje));
        }
      })
      .catch((error) => { });
  } catch (error) {
    // dispatch(allclients(''));
    dispatch(
      hasErrorClientes(
        "No se pudo recuperar la información recargue la pagina "
      )
    );
  }
};

export const getClientesPorBusqueda = (cadenaBusqueda, doctorId = null) => async (
  dispatch,
  getstate
) => {
  dispatch(isLoadingClientes());
  try {
    const state = getstate();
    const { token } = state;
    // const { doctor_id } = dataUser.data;

    var requestOptions = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    const url = doctorId ? `${urlGetClientes}search/${cadenaBusqueda}/${doctorId}` : `${urlGetClientes}search/${cadenaBusqueda}`;
    return fetch(url, requestOptions)
      .then((response) => response.json())
      .then((datos) => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(""));
          return;
        }
        if (datos.transaccion) {
          dispatch(clientesBusqueda(datos.data));
        } else {
          dispatch(clientesBusqueda([]));
          dispatch(hasErrorClientes(datos.mensaje));
        }
      })
      .catch(errr => {
        dispatch(clientesBusqueda([]));
      });
  } catch (error) {
    console.log('ererer')
    dispatch(clientes(""));
    dispatch(
      hasErrorClientes(
        "No se pudo recuperar la información recargue la pagina "
      )
    );
  }
};

export const setCliente = (cliente) => (dispatch) => {
  dispatch(isLoadingClientes());
  dispatch(clientes(cliente));
  dispatch(clientesBusqueda([]));
};

export const cleanCliente = () => (dispatch) => {
  dispatch(isLoadingClientes());
  dispatch(clientes(""));
  dispatch(allclients([]));
  dispatch(clientesBusqueda([]));
};

// Pacientes Atendidos
export const startloadPacientesAtendidos = (doctorId, skip, take, search, sortBy = "nombres", sort = "asc") => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    var requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(`${urlListarPacientesAtendidos}/${doctorId}?skip=${skip}&take=${take}&search=${search}&orderBy=${sortBy}&order=${sort}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      dispatch(loadPacientesAtendidos(datos.data.data));
      dispatch(paginationPacientes(datos.data.pagination));
    }

    return datos;

  } catch (error) {
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};
export const startEditPacienteAtendidos = (doctorId, id, data) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    const raw = JSON.stringify(data);

    var requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: raw
    };

    const response = await fetch(`${urlEditarPacientesAtendido}/${doctorId}/${id}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      Notificacion({
        type: "success",
        text: 'Datos guardados exitosamente',
      });
      dispatch(updatePacienteAtendido(datos.data));
    }

    return datos;
  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const eliminarPacienteAtendido = (doctorId, id) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    var requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${urlEliminarPacientesAtendido}/${doctorId}/${id}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      Notificacion({
        type: "success",
        text: 'Registro eliminado exitosamente',
      });
      dispatch(deletePacienteAtendido(id));
    }
    // else{
    //   Notificacion({
    //     type: "error",
    //     text: datos.mensaje,
    //   });
    // }

    return { ...datos, responseStatus: response.status };
  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

//Consultas

export const getClienteConsultas = (clienteId, skip = 0, take = 10, filter = 0) => async (
  dispatch,
  getstate
) => {
  dispatch(isLoadingClientes());
  try {
    const state = getstate();
    const { token, data_clientes } = state;
    // const { doctor_id } = dataUser.data;

    var requestOptions = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${urlListarDoctorClienteConsultas}/${clienteId}?skip=${skip}&take=${take}&filter=${filter}`, requestOptions);
    const datos = await response.json();

    if (!datos.transaccion) throw (new Error('Error al listar consultas'))

    if (+skip > 0) {
      dispatch(clientes({ ...data_clientes.data, consultas: { ...data_clientes.data.consultas, data: [...data_clientes.data.consultas.data, ...datos.data.data], hasMoreData: datos.data.hasMoreData } }));
    } else
      dispatch(clientes({ ...data_clientes.data, consultas: datos.data }));

    return datos

  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

//Paises
export const startSearchPaises = (cadenaBusqueda) => async (
  dispatch,
  getstate
) => {
  try {
    const { token } = getstate();
    // const {token, dataUser} = state;

    var requestOptions = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${urlGetPaisesBusqueda}/${cadenaBusqueda}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) return datos;
    else throw (new Error(datos.mensaje));
  } catch (error) {
    throw (error);
  }
}

//Creditos compra
export const getCreditItem = (id) => async () => {
  try {
    var requestOptions = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await fetch(`${urlGetCreditItem}/${id}/${empresaId}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) return datos;
    else throw (new Error(datos.mensaje));
  } catch (error) {
    throw (error);
  }
}

export const confirmCreditsTransaction = ({ transactionId, clientTransactionId, purchaseItemId, purchaseType }) => async () => {
  try {
    const body = JSON.stringify({
      transactionId,
      clientTransactionId,
      purchaseItemId,
      purchaseType,
      empresa: empresaId,
    });

    var requestOptions = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body
    };

    const response = await fetch(urlConfirmTransaction, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) return datos;
    else throw (new Error(datos.mensaje));
  } catch (error) {
    throw (error);
  }
}

// Pago reservas
export const verificarReserva = (data) => async () => {
  try {
    const raw = JSON.stringify(data);
    var requestOptions = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
    };

    const response = await fetch(`${urlVerificarReserva}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) return datos;
    else throw (new Error(datos.mensaje));
  } catch (error) {
    throw (error);
  }
}

export const verificarReservaCreada = (reservaId) => async () => {
  try {
    var requestOptions = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await fetch(`${urlVerificarReservaCreada}/${reservaId}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) return datos;
    else throw (new Error(datos.mensaje));
  } catch (error) {
    throw (error);
  }
}

export const confirmTransaction = (data) => async () => {
  try {
    const body = JSON.stringify(data);

    var requestOptions = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body
    };

    const response = await fetch(urlConfirmTransaction, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) return datos;
    else throw (new Error(datos.mensaje));
  } catch (error) {
    throw (error);
  }
}


