import React, { useCallback, useEffect, useState } from 'react'
import Button from "components/CustomButtons/Button.js";
import { useDispatch } from 'react-redux';
import { firmarFacturacionConsulta } from 'redux/actions/aMedico';
import Loading from 'components/Loading';
import { loadConsultaFactura } from 'redux/actions/aMedico';
import { enviarFacturaConsultaSRI } from 'redux/actions/aMedico';
import { enviarFacturaConsultaReceipt } from 'redux/actions/aMedico';
import Notificacion from 'components/ui/Notificacion';
import { FormControlLabel, Switch } from '@material-ui/core';

export const DoctorBilling = ({
  consultaId,
  secretaria = false,
}) => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [successBilling, setSuccessBilling] = useState(false);
  const [billingData, setBillingData] = useState({
  });
  const [error, setError] = useState(null);

  // Form
  const [includesIva, setIncludesIva] = useState(false);

  const handleCreateBilling = async () => {
    try {
      if (!consultaId) return;
      setLoading(true);
      const data = await dispatch(firmarFacturacionConsulta(consultaId, { includesIva }, secretaria))
      setBillingData(data);

      setSuccessBilling(true);
      setLoading(false);
      setSuccessBilling(data);


    } catch (error) {
      setLoading(false);
      console.log({ error })
    }

  }

  const handleOpenPdf = () => {
    window.open(billingData.pdfUrl, '_blank').focus();
  }

  const handleSendSRI = async () => {
    try {
      if (!consultaId) return;
      setLoading(true);
      await dispatch(enviarFacturaConsultaSRI(consultaId, secretaria))
      // setBillingData(data);

      setBillingData({
        ...billingData,
        sriSent: true,
      })

      setLoading(false);
      // setSuccessBilling(data);


    } catch (error) {
      setLoading(false);
      console.log({ error })
    }
  }

  const handleSendCliente = async () => {
    try {
      if (!consultaId) return;
      setLoading(true);
      await dispatch(enviarFacturaConsultaReceipt(consultaId, secretaria))
      // setBillingData(data);

      setBillingData({
        ...billingData,
        sriSent: true,
      })

      setLoading(false);
      // setSuccessBilling(data);


    } catch (error) {
      setLoading(false);
      console.log({ error })
    }
  }

  const loadFacturaConsulta = useCallback(
    async () => {
      try {
        setLoading(true);
        if (!consultaId) return;
        const { data } = await dispatch(loadConsultaFactura(consultaId, secretaria));
        setLoading(false);
        if (data) {
          setBillingData(data)
          setSuccessBilling(true);
        }
      } catch (error) {
        setLoading(false);
        setError(error);
        Notificacion({
          type: "error",
          text: error.toString(),
        });
        console.log(error);

      }


    },
    [consultaId, dispatch, secretaria],
  )

  useEffect(() => {
    loadFacturaConsulta()
  }, [loadFacturaConsulta])


  return (
    <Loading loading={loading}>
      {
        error ?
          <div>

          </div>
          :
          <div>
            {
              successBilling ?
                <>
                  <Button
                    fullWidth
                    size="large"
                    color="primary" onClick={handleOpenPdf}
                  >
                    Descargar pdf
                  </Button>

                  {
                    !billingData.sriSent &&
                    <Button
                      fullWidth
                      size="large"
                      color="primary" onClick={handleSendSRI}
                    >
                      Validar factura para envío a SRI
                    </Button>
                  }
                  {
                    billingData.sriSent &&
                    <Button
                      fullWidth
                      size="large"
                      color="primary" onClick={handleSendCliente}
                    >
                      Enviar factura a SRI
                    </Button>
                  }

                </>

                :
                <>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={includesIva}
                        onChange={() => setIncludesIva(!includesIva)}
                        name="includesIva"
                        color="primary"
                      // disabled={!usuario.cuenta_activa}
                      />
                    }
                    label="Incluir IVA"
                  />
                  <Button
                    fullWidth
                    size="large"
                    color="primary" onClick={handleCreateBilling}>Generar Factura</Button>
                </>

            }

          </div>
      }

    </Loading>

  )
}
