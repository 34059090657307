import { handleActions } from 'redux-actions';
import { cajas, isLoadingCajas, hasErrorCajas, LoadCajasReservasbySecretaria,LoadNumeroCajasReservasbySecretaria, 
  isLoadingCajasReservasbySecretaria, isLoadingNumeroCajasReservasbySecretaria, LoadCajasProgramas } from '../actions/aCajas';

export const datos_cajas = handleActions(
  {
    [cajas]: (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
      hasError: false,
    }),
    [LoadCajasReservasbySecretaria]: (state, action) => ({
      ...state,
      dataEstadisticas: {
        ...state.dataEstadisticas,
        CajasReservasPorSecretaria: {
          isLoading: false,
          reservas: action.payload.reservas,
          cajas: action.payload.cajas
        }
      },
      isLoading: false,
      hasError: false,
    }),
    [LoadNumeroCajasReservasbySecretaria]: (state, action) => ({
      ...state,
      dataEstadisticas: {
        ...state.dataEstadisticas,
        NumeroCajasReservasPorSecretaria: {
          isLoading: false,
          data: action.payload
        }
      },
      isLoading: false,
      hasError: false,
    }),
    [LoadCajasProgramas]: (state, action) => ({ ...state,dataProgramas: action.payload, isLoading: true, hasError: false }),
    [isLoadingCajas]: state => ({ ...state, isLoading: true, hasError: false }),
    [isLoadingCajasReservasbySecretaria]: state => ({ ...state, dataEstadisticas: {
      ...state.dataEstadisticas,
      CajasReservasPorSecretaria: {
        ...state.dataEstadisticas.CajasReservasPorSecretaria,
        isLoading: true,
      }
    }, }),
    [isLoadingNumeroCajasReservasbySecretaria]: state => ({ ...state, dataEstadisticas: {
      ...state.dataEstadisticas,
      NumeroCajasReservasPorSecretaria: {
        ...state.dataEstadisticas.NumeroCajasReservasPorSecretaria,
        isLoading: true,
      }
    } }),
    [hasErrorCajas]: (state, action) => ({
      ...state,
      isLoading: false,
      hasError: action.payload,
    }),
  },
  { isLoading: false, hasError: false, data: [],
    dataProgramas: "", 
    dataEstadisticas: { NumeroCajasReservasPorSecretaria: {isLoading: false, data: []}, 
    CajasReservasPorSecretaria: { isLoading: false ,reservas: [], cajas: [] } } }
);
