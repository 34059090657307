import React from 'react'
import { Banner } from 'components/banner/Banner'
import { TermsConditions } from 'components/terms/TermsConditions'
import { PrivacyTerms } from 'components/terms/PrivacyTerms'
import { CookiesTerms } from 'components/terms/CookiesTerms'
import 'styles/terms/terms.scss'

export const TermsPage = ({ type }) => {

  const TermsContent = ({ type }) => {
    switch (type) {
      case 'cookies':
        return (
          <Banner title="Uso de cookies">
            <CookiesTerms />
          </Banner>
        )
      case 'privacy':
        return (
          <Banner title="Política de privacidad">
            <PrivacyTerms />
          </Banner>
        )

      default:
        return (
          <Banner title="Términos y condiciones de uso">
            <TermsConditions />
          </Banner>
        )
    }
  }

  return (
    <div className='terms-content'>
      <TermsContent type={type} />
    </div>

  );
}
