import { validarCelular } from "utils/validaciones";
import { validarCorreo } from "utils/validaciones";
import { validarCedulaPasaporte } from "utils/validaciones";

// Select options
export const selectInstruccionOptions = [
  {
    value: 'ninguna',
    label: "Ninguna",
  },
  {
    value: 'primaria',
    label: "Primaria",
  },
  {
    value: 'secundaria',
    label: "Secundaria",
  },
  {
    value: 'superior',
    label: "Superior",
  },
];
export const selectEstadoCivilOptions = [
  {
    value: 'soltero',
    label: "Soltero",
  },
  {
    value: 'casado',
    label: "Casado",
  },
  {
    value: 'divorciado',
    label: "Divorciado",
  },
  {
    value: 'viudo',
    label: "Viudo",
  },
  {
    value: 'union de hecho',
    label: "Unión de hecho",
  },
];

export const selectTipoSangreOptions = [
  {
    value: "No determinado",
    label: "No determinado"
  },
  {
    value: "O+",
    label: "O+"
  },
  {
    value: "O-",
    label: "O-"
  },
  {
    value: "A+",
    label: "A+"
  },
  {
    value: "A-",
    label: "A-"
  },
  {
    value: "B+",
    label: "B+"
  },
  {
    value: "B-",
    label: "B-"
  },
  {
    value: "AB+",
    label: "AB+"
  },
  {
    value: "AB-",
    label: "AB-"
  },
];

// Form props
export const clienteFormProps = {
  tituloHonorifico: {
    name: "tituloHonorifico",
    label: "Título honorífico",
  },
  cedulaIdentidad: {
    name: "cedulaIdentidad",
    label: "* Cédula o pasaporte",
    validationOptions: {
      required: {
        value: true,
        message: "El campo es requerido",
      },
      validate: (value) => {
        if (!validarCedulaPasaporte(value)) {
          return "La cédula de identificación debe tener entre 9 y 10 dígitos"
        }
        return true;
      }
    },
  },
  nombres: {
    name: "nombres",
    label: "* Nombres",
    validationOptions: {
      required: {
        value: true,
        message: "El campo es requerido",
      },
    },
  },
  apellidos: {
    name: "apellidos",
    label: "* Apellidos",
    validationOptions: {
      required: {
        value: true,
        message: "El campo es requerido",
      },
    },
  },
  correo: {
    name: "correo",
    label: "* Email",
    validationOptions: {
      required: {
        value: true,
        message: "El campo es requerido",
      },
      validate: (value) => {
        if (!validarCorreo(value)) {
          return "El email no tiene un formato válido"
        }
        return true;
      }
    },
  },
  direccion: {
    name: "direccion",
    label: "* Dirección",
    validationOptions: {
      required: {
        value: true,
        message: "El campo es requerido",
      },
    },
  },
  celular: {
    name: "celular",
    label: "* Celular",
    validationOptions: {
      required: {
        value: true,
        message: "El campo es requerido",
      },
      validate: (value) => {
        if (!validarCelular(value)) {
          return "El celular debe tener al menos 10 dígitos"
        }
        return true;
      }
    },
  },
  telefono: {
    name: "telefono",
    label: "Teléfono",
  },
  fechaNacimiento: {
    name: "fechaNacimiento",
    label: "* Fecha de Nacimiento",
  },
  cedulaRepresentante: {
    name: "cedulaRepresentante",
    label: "Cédula Representante",
  },
  nombresPadre: {
    name: "nombresPadre",
    label: "Nombres Padre",
  },
  nombresMadre: {
    name: "nombresMadre",
    label: "Nombres Madre",
  },
  sexo: {
    name: "sexo",
    label: "* Sexo",
    validationOptions: {
      required: {
        value: true,
        message: "El campo es requerido",
      },
    },
    options: [
      {
        value: "masculino",
        label: "Masculino"
      },
      {
        value: "femenino",
        label: "Femenino"
      },
      {
        value: "otro",
        label: "Otro"
      },

    ],
  },
  nacionalidad: {
    name: "nacionalidad",
    label: "País de origen",
  },
  pais: {
    name: "pais",
    label: "País de residencia",
  },
  ciudad: {
    name: "ciudad",
    label: "Ciudad",
  },
  instruccion: {
    name: "instruccion",
    label: "Instrucción",
    defaultValue: '',
    options: [
      ...selectInstruccionOptions
    ],
  },
  ocupacion: {
    name: "ocupacion",
    label: "Ocupación",
  },
  estadoCivil: {
    name: "estadoCivil",
    label: "Estado civil",
    defaultValue: '',
    options: [
      ...selectEstadoCivilOptions
    ],
  },
  tipoSangre: {
    name: "tipoSangre",
    label: "Tipo de sangre",
    defaultValue: '',
    options: [
      ...selectTipoSangreOptions
    ],
  },
}

export const daysWeeksNumber = {
  0: 'DOMINGO',
  1: 'LUNES',
  2: 'MARTES',
  3: 'MIÉRCOLES',
  4: 'JUEVES',
  5: 'VIERNES',
  6: 'SÁBADO',
}

// Types
export const UserRoleTypes = {
  doctor: 'ROLE_MED',
  secretaria: 'ROLE_SEC',
  cliente: 'ROLE_CLI',
  laboratorista: 'ROLE_LAB',
  institucion: 'ROLE_INS'
}

// Validation messages
export const passwordValidationMessage = 'La contraseña debe tener al menos 5 caracteres, al menos una mayúscula, una minúscula y un número';

// Tiny MCE
export const tinyMCEApiKey = 'qricyy2m97st5mquoio1632kp50md4ye1tv8hm6wih2hu3tr';