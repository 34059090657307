import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import HorarioOficina from '../components/horario-oficina/doctor/horario-oficina.component';
import { getHorariosOficina,getOficinasDoctor } from '../redux/actions/aOficinas';
const HorarioCitas = () => {
  const dispatch = useDispatch();
  const doctor_id = useSelector(state => state.dataUser.data.doctor_id);
  useEffect(() => {
    dispatch(getOficinasDoctor());
    dispatch(getHorariosOficina(doctor_id));
  }, [dispatch, doctor_id]);


  return <HorarioOficina />;
};

export default HorarioCitas;
