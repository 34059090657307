import React from 'react'
import { makeStyles } from '@material-ui/core';
import { RecetaMembrete } from '../receta/RecetaContentPdf';
import { calculateAgeString } from 'utils/paciente/paciente';
import { splitLastname } from 'utils/paciente/paciente';
import moment from 'moment';


const useStyles = makeStyles(() => ({
  container: {
    margin: '20px',
  },
  mainContent: { overflow: "visible  !important", },
  header: {
    textAlign: 'start',
    height: "100px",
    borderBottom: "1px solid black",
    display: "flex", alignItems: "center",
    marginBottom: '10px'
  },
  headerNoBorder: {
    textAlign: 'start',
    height: "101px",
    display: "flex", alignItems: "center",
    marginBottom: '10px'
  },
  headerAreaDoctor: {
    marginLeft: '20px'
  },
  doctorName: {
    fontWeight: 'bold',
    color: "black"
  },
  doctorSpecialty: {
    color: "black",
    marginTop: "10px"
  },
  // Tables form
  tablesForm: {
    width: '100%', textAlign: 'center', fontSize: '12px', color: 'black'
  },
  tablesFormContainer: {
    marginTop: '35px'
  },
  formImage: {
    maxWidth: '100%'
  },
  formEmptyImage: {
    maxWidth: '100%',
    border: '1px solid black',
    height: '150px',
  },
  formContainer: {
    color: 'black',
    "& p": {
      lineHeight: '1.8'
    }
  },
  formSection: {

  },
  formFillSection: {
    display: 'flex',
    marginBlock: '12px',
    "& p": {
      margin: '0px'
    },
  }
}));


export const ConsentimientoInformadoPrint = React.forwardRef(({ header = true, data }, ref) => {

  const { patient, doctor, date, cie10, imgIntervencion } = data;

  const { especializaciones } = doctor;

  let especializacionesDoctor = "";
  especializaciones.map(({ nombre }) => (especializacionesDoctor.length === 0 ? especializacionesDoctor = especializacionesDoctor.concat(nombre) : especializacionesDoctor = especializacionesDoctor.concat(`, ${nombre}`)));

  const classes = useStyles();

  return (
    <div ref={ref} >
      <table style={{ width: '100%', pageBreakAfter: 'always' }}>
        <thead style={{ display: 'table-header-group' }}>
          <tr>
            <th>
              <div className={header ? classes.header : classes.headerNoBorder} >
                {
                  header &&
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <td>
                          <RecetaMembrete />
                        </td>
                        <td>
                          <RecetaMembrete />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                }
              </div>
            </th>
          </tr>
        </thead>
        <tbody >
          <tr>
            <td>
              <div className={classes.container}>
                <table style={{ width: '100%' }}>
                  <tbody >
                    {/* <tr>
                      <td align='center'>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div>
                            <img
                              style={{ margin: 'auto' }}
                              alt='logo'
                              src={caracteristicas}
                              width={75}
                            />
                          </div>
                          <div>
                            {moment(date).format('LL')}
                          </div>
                        </div>
                      </td>
                    </tr> */}
                    <tr>
                      <td align='center'>
                        <strong>
                          CONSENTIMIENTO INFORMADO
                        </strong>
                      </td>
                    </tr>
                    {/* <tr>
                      <td style={{ paddingTop: '20px' }}>
                        <div>
                          {doctorName}
                        </div>
                        <div>Especialidad: {especializacionesDoctor}</div>
                      </td>
                    </tr> */}
                    <tr>
                      <td style={{ padding: '30px', fontSize: '12px' }}>
                        <div className={classes.formContainer}>
                          {/* Contenido */}


                          <FormFillSection endContent={'”'}>1.Título: “Consentimiento Informado para</FormFillSection>
                          <FormFillSection>2. Nombre del establecimiento de salud:</FormFillSection>
                          <FormFillSection>3. Servicio del establecimiento de salud:  Consulta Médica de Especialidad</FormFillSection>
                          <p>4. NÚMERO DE CÉDULA/HCU DEL PACIENTE: {patient.cedula_identidad}</p>
                          <p>5. FECHA: {moment(date).format('DD/MM/YYYY')}&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; 6. HORA: {moment(date).format('HH:mm')} </p>

                          <div>
                            7.
                            <TableForm tableType={tableTypes.pacienteCompleto} patient={patient} />
                          </div>

                          <p>8. TIPO DE ATENCIÓN: Ambulatoria: ________________________ Hospitalización: _____ </p>
                          {
                            cie10 ?
                              <p>9. NOMBRE DEL DIAGNÓSTICO (codificación CIE10) {cie10}</p>
                              :
                              <FormFillSection>9. NOMBRE DEL DIAGNÓSTICO (codificación CIE10) {cie10}</FormFillSection>
                          }

                          <FormFillSection>10. NOMBRE DEL PROCEDIMIENTO RECOMENDADO</FormFillSection>
                          <FormFillSection>11. ¿EN QUÉ CONSISTE?</FormFillSection>
                          <FormFillSection>12. ¿CÓMO SE REALIZA?</FormFillSection>

                          <p>13. GRÁFICO DE LA INTERVENCIÓN (incluya un gráfico previamente seleccionado que facilite la comprensión al paciente) </p>

                          {
                            imgIntervencion ?
                              <div>
                                <img className={classes.formImage} src={imgIntervencion} alt="imgIntervencion" />
                              </div>
                              :
                              <div className={classes.formEmptyImage}>

                              </div>
                          }


                          <FormFillSection>14. DURACIÓN ESTIMADA DE LA INTERVENCIÓN:</FormFillSection>
                          <FormFillSection>15. BENEFICIOS DEL PROCEDIMIENTO:</FormFillSection>
                          <FormFillSection>16. RIESGOS FRECUENTES (POCO GRAVES):</FormFillSection>
                          <FormFillSection>17. RIESGOS POCO FRECUENTES (GRAVES):</FormFillSection>
                          <FormFillSection linesExtra={1}>18. DE EXISTIR, ESCRIBA LOS RIESGOS ESPECÍFICOS RELACIONADOS CON EL PACIENTE (edad, estado de salud, creencias, valores, etc.): </FormFillSection>
                          <FormFillSection>19. ALTERNATIVAS AL PROCEDIMIENTO: </FormFillSection>
                          <FormFillSection>20. DESCRIPCIÓN DEL MANEJO POSTERIOR AL PROCEDIMIENTO: </FormFillSection>
                          <FormFillSection>21. CONSECUENCIAS POSIBLES SI NO SE REALIZA EL PROCEDIMIENTO: </FormFillSection>

                          <p>22. DECLARACIÓN DE CONSENTIMIENTO INFORMADO Fecha: Hora: He facilitado la información completa que conozco, y me ha sido solicitada, sobre los antecedentes personales,
                            familiares y de mi estado de salud. Soy consciente de que omitir estos datos puede afectar los resultados del tratamiento. Estoy de acuerdo con el procedimiento que se me ha propuesto;
                            he sido informado de las ventajas e inconvenientes del mismo; se me ha explicado de forma clara en qué consiste, los beneficios y posibles riesgos del procedimiento. He escuchado, leído y comprendido la información recibida
                            y se me ha dado la oportunidad de preguntar sobre el procedimiento. He tomado consciente y libremente la decisión de autorizar el procedimiento. Consiento que durante la intervención, me realicen otro procedimiento adicional,
                            si es considerado necesario según el juicio del profesional de la salud, para mi beneficio. También conozco que puedo retirar mi consentimiento cuando lo estime oportuno.</p>

                          <TableForm tableType={tableTypes.paciente} patient={patient} />

                          <TableForm tableType={tableTypes.profesional} doctor={doctor} />

                          <p>Si el paciente no está en capacidad para firmar el consentimiento informado: </p>

                          <TableForm tableType={tableTypes.representanteLegal} />
                          <p>Parentesco: __________________________</p>

                          {/* Punto 23 */}
                          <p>23. NEGATIVA DEL CONSENTIMIENTO INFORMADO&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;Fecha:______________ </p>
                          <p>
                            Una vez que he entendido claramente el procedimiento propuesto, así como las consecuencias posibles si no se realiza la intervención,
                            no autorizo y me niego a que se me realice el procedimiento propuesto y desvinculo de responsabilidades futuras de cualquier índole al establecimiento de salud
                            y al profesional sanitario que me atiende, por no realizar la intervención sugerida.
                          </p>

                          <TableForm tableType={tableTypes.paciente} patient={patient} />

                          <TableForm tableType={tableTypes.profesionalTratante} doctor={doctor} />

                          <p>Si el paciente no está en capacidad para firmar el consentimiento informado: </p>
                          <TableForm tableType={tableTypes.representanteLegal} />
                          <p>Parentesco: __________________________</p>

                          <p>Si el paciente no acepta el procedimiento sugerido por el profesional y se niega a firmar este acápite: </p>
                          <TableForm tableType={tableTypes.testigo} />

                          {/* Fin punto 23 */}

                          {/* Punto 24 */}
                          <p>24. REVOCATORIA DE CONSENTIMIENTO INFORMADO </p>
                          De forma libre y voluntaria, revoco el consentimiento realizado en fecha y manifiesto expresamente mi deseo de no continuar con el procedimiento médico que doy por finalizado en esta fecha:

                          <p>Libero de responsabilidades futuras de cualquier índole al establecimiento de salud y al profesional sanitario que me atiende.</p>
                          <TableForm tableType={tableTypes.paciente} patient={patient} />

                          <p>Si el paciente no está en capacidad de firmar la negativa del consentimiento informado: </p>
                          <TableForm tableType={tableTypes.representanteLegal} />

                          {/* Fin punto 24 */}

                          {/* Fin Contenido */}
                        </div>
                      </td>
                    </tr>

                  </tbody>

                </table>
              </div>

            </td>
          </tr>

        </tbody>
        <tfoot>
          <tr>
            <td>
              <div style={{ height: '30px' }}></div>
            </td>
          </tr>

        </tfoot>
      </table>
    </div>
  )
})

const tableTypes = {
  representanteLegal: 'representanteLegal',
  paciente: 'paciente',
  pacienteCompleto: 'pacienteCompleto',
  testigo: 'testigo',
  profesional: 'profesional',
  profesionalTratante: 'profesionalTratante',
}
const TableForm = ({ tableType, patient, doctor }) => {
  const classes = useStyles();

  const doctorName = doctor ? `${doctor.titulo_honorifico} ${doctor.nombres} ${doctor.apellidos}` : '';

  switch (tableType) {
    case tableTypes.profesional:
      return (
        <div className={classes.tablesFormContainer}>
          <table className={classes.tablesForm}>
            <tbody>
              <tr>
                <td>{doctorName}</td>
                <td> ________________________________________________</td>
              </tr>
              <tr>
                <td>Nombre de profesional que realiza el procedimiento</td>
                <td>Firma, sello y código del profesional de la salud que realizará el procedimiento</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    case tableTypes.profesionalTratante:
      return (
        <div className={classes.tablesFormContainer}>
          <table className={classes.tablesForm}>
            <tbody>
              <tr>
                <td>{doctorName}</td>
                <td> ________________________________________________</td>
              </tr>
              <tr>
                <td>Nombre del profesional tratante</td>
                <td>Firma, sello y código del profesional tratante</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    case tableTypes.representanteLegal:
      return (
        <div className={classes.tablesFormContainer}>
          <table className={classes.tablesForm}>
            <tbody>
              <tr>
                <td>__________________________________</td>
                <td>__________________________________</td>
                <td>__________________________________</td>
              </tr>
              <tr>
                <td>Nombre del representante legal</td>
                <td>Cédula de ciudadanía</td>
                <td>Firma del representante legal </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    case tableTypes.paciente:
      return (
        <div className={classes.tablesFormContainer}>
          <table className={classes.tablesForm}>
            <tbody>
              <tr>
                <td>{`${patient?.nombres} ${patient?.apellidos}`}</td>
                <td>{patient?.cedula_identidad}</td>
                <td>_______________________________________________</td>
              </tr>
              <tr>
                <td>Nombre completo del paciente </td>
                <td>Cédula de ciudadanía</td>
                <td>Firma del paciente o huella, según el caso </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    case tableTypes.pacienteCompleto:
      return (
        <div>
          <table className={classes.tablesForm} >
            <tbody>
              <tr>
                <td >{patient?.apellidos ? splitLastname(patient?.apellidos).fatherLastname : ''}</td>
                <td >{patient?.apellidos ? splitLastname(patient?.apellidos).motherLastname : ''}</td>
                <td>{patient.nombres}</td>
                <td>{patient ? calculateAgeString(patient.fecha_nacimiento) : ''}</td>
              </tr>
              <tr>
                <td>Apellido Paterno</td>
                <td>Apellido Materno</td>
                <td>Nombres</td>
                <td>Edad</td>
              </tr>
            </tbody>


          </table>
        </div>
      );

    case tableTypes.testigo:
      return (
        <div className={classes.tablesFormContainer}>
          <table className={classes.tablesForm}>
            <tbody>
              <tr>
                <td>__________________________________</td>
                <td>__________________________________</td>
                <td>__________________________________</td>
              </tr>
              <tr>
                <td>Nombre completo de testigo</td>
                <td>Cédula de ciudadanía</td>
                <td>Firma del testigo</td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    default:
      return null;
  }

}


const FormFillSection = ({ children, endContent, linesExtra = 0 }) => {
  const classes = useStyles();

  const componentLines = Array.from(Array(linesExtra).keys());

  const formRenderElements = componentLines.map((fc, index) => <React.Fragment key={index}>{
    <div className={classes.formFillSection}>
      <p style={{ visibility: 'hidden' }}>test</p>
      <span style={{ borderBottom: '1px solid gray', flexGrow: '1' }}></span>
    </div>
  }</React.Fragment>);

  return (
    <>
      <div className={classes.formFillSection}>
        <p>{children} </p>
        <span style={{ borderBottom: '1px solid gray', flexGrow: '1' }}></span>
        {endContent}
      </div>
      {
        formRenderElements
      }

    </>
  )



}


