import React from "react";
import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Close from "@material-ui/icons/Close";
import EditIcon from '@material-ui/icons/Edit';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PostAddIcon from '@material-ui/icons/PostAdd';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

export const TableRowGrupoExamenDoctor = (props) => {
  const { row, handleDelete, handleEdit, handleUse } = props;
  const [open, setOpen] = React.useState(false);
  const classesRow = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classesRow.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.nombre}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.examenes ? (row.examenes.length > 0) ? (<strong>{row.examenes.length}</strong>) : row.examenes.length : 0}
        </TableCell>
        <TableCell>
          <Tooltip
            id="tooltip-editar"
            title="Utilizar"
            placement="top"

            classes={{ tooltip: classesRow.tooltip }}
          >
            <IconButton
              aria-label="Close"
              className={classesRow.tableActionButton}
              onClick={() => handleUse(row)}
            >
              <PostAddIcon
                className={
                  classesRow.tableActionButtonIcon + " " + classesRow.close
                }
              />
            </IconButton>

          </Tooltip>
          <Tooltip
            id="tooltip-editar"
            title="Editar"
            placement="top"

            classes={{ tooltip: classesRow.tooltip }}
          >
            <IconButton
              aria-label="Close"
              className={classesRow.tableActionButton}
              onClick={() => handleEdit(row)}
            >
              <EditIcon
                className={
                  classesRow.tableActionButtonIcon + " " + classesRow.close
                }
              />
            </IconButton>

          </Tooltip>
          <Tooltip
            id="tooltip-eliminar"
            title="Eliminar"
            placement="top"

            classes={{ tooltip: classesRow.tooltip }}
          >
            <IconButton
              aria-label="Close"
              className={classesRow.tableActionButton}
              onClick={() => handleDelete(row.id)}
            >
              <Close
                className={
                  classesRow.tableActionButtonIcon + " " + classesRow.close
                }
              />
            </IconButton>

          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="subtitle1" gutterBottom component="div">
                Exámenes
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.examenes && row.examenes.map((examen) => (
                    <TableRow key={examen.id}>
                      <TableCell component="th" scope="row">
                        {examen.nombre}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
