import React, { useEffect, useRef, useState } from 'react';
import { Alert, AlertTitle } from "@material-ui/lab";
import { useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import { verificarResetKey } from 'services/user';
import { Button, TextField } from "@material-ui/core";
import { confirmarCambioPassword } from 'services/user';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  // content: {
  //   backgroundColor: "#24105a",
  //   height: "100vh"
  // },
  content: {
    // backgroundColor: "--secondarycolor",
    height: "100vh"
  },

  principalText: {
    color: "#3177b9",
    fontSize: "60px",
    textAlign: "center",
    fontWeight: 'bold'
  },

  item: {
    flex: "3 3 auto",
    width: "auto",
    height: "auto"
  },

  boxPassword: {
    backgroundColor: "#bbb",
    padding: "40px",
    maxWidth: "370PX"
  },

  boxPasswordTitle: {
    color: "white",
    fontSize: "20px",
    fontWeight: 'bold'
  },

  boxTextInfoWhite: {
    color: "white",
    fontSize: "15px",
  },
  boxPasswordTextShowPassword: {
    color: "#3177b9",
    fontSize: "15px",
    cursor: "pointer"
  },
  boxTextInputContainer: {marginBottom: "10px", 
  backgroundColor: "white", 
  borderRadius: "4px", color:"white"},

  containerCenter: {
    display: "flex",
    justifyContent: "center",
    color:"white"
  },
  containerFlex: { display: "flex", flexDirection: "row", flexWrap: "wrap ", height: "100vh", alignItems: "center", justifyContent: "center"}

  

}));


export const ActualizarPassword = () => {
  const classes = useStyles();
  let { resetKey } = useParams();

  const [verificado, setVerificado] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorAction, setErrorAction] = useState(null);

  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    console.log("actualizar password");
    verificarResetKey(resetKey)
      .then(datos => {
        if (isMounted.current) {
          if (datos.transaccion) {
            setVerificado(true);
          } else {
            setError(true);
          }
        }
      })
      .catch(err => {
        if (isMounted.current) {
          setError(true);
        }
      });
  }, [resetKey]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const handleClick = () => {
    if (password.trim() !== password2.trim()) {
      setErrorAction("Las contraseñas no coinciden.");
      return;
    }

    if (password.trim().length < 5) {
      setErrorAction("La contraseña debe tener al menos 5 caracteres.");
      return;
    }

    var patt = new RegExp(".*^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$");

    if (!patt.test(password.trim())) {
      setErrorAction("La contraseña debe contener al menos una mayúscula, una minúscula y un número.");
      return;
    }

    setVerificado(false);
    setError(false);
    confirmarCambioPassword(resetKey, password.trim())
      .then(datos => {
        if (isMounted.current) {
          if (datos.transaccion) {
            setSuccess(true);
            setVerificado(true);
          } else {
            setErrorAction("Ha ocurido un error de procesamiento");
          }
        }
      })
      .catch(err => {
        if (isMounted.current) {
          setErrorAction("Ha ocurido un error de procesamiento");
        }
      });
  }

  return (
    <div className="container-fluid">
      <div className={classes.content}>
        <Loading title="Cargando..." loading={!verificado && !error}>
          {
            success ?
            <div className={classes.containerCenter}>
             
                <div style={{textAlign: "center", color: "#3177b9"}}>
                  <>
                    <h1>Recuperación de cuenta</h1>
                    <h2>
                      Se ha actualizado su contraseña correctamente
                    </h2>
                  </>
                </div>
          
            </div>
              :
              <div className={classes.containerFlex}>
                {/* <div className="n-banner-empty"></div> */}
                <div className={classes.item} >
                  <div className={classes.containerCenter}>
                    <h2 className={classes.principalText} style={{color: "--primarycolor"}}>¡TE AYUDAMOS!</h2>
                    
                  </div>
                </div>
                <div className={classes.item}>
                  {verificado && (
                    <div className={classes.containerCenter}>

                      <div className={classes.boxPassword}>
                        <div className={classes.boxPasswordTitle}>Nueva contraseña</div>
                        <div style={{marginTop: "50px", }}>
                          <div className={classes.boxTextInputContainer}>
                            <TextField
                              label="Nueva Contraseña"
                              // defaultValue=""
                              // variant="outlined"
                              type={showPassword ? "text" : "password"}
                              style={{ width: "100%", margin: "0 5px 0 0",   }}
                              value={password}
                              onChange={(e) => setPassword(e.target.value.trim())}
                              variant="outlined"
                              InputLabelProps={{
                                style: {
                                  color: "#9e95a9"
                                } 
                              }}
                              InputProps={{
                                style: {
                                  color: "#9e95a9"
                                } 
                              }}
                            />

                          </div>
                          <div className={classes.boxTextInputContainer}>
                            <TextField
                              //error
                              label="Repetir contraseña"
                              // defaultValue=""
                              value={password2}
                              // variant="outlined"
                              style={{ width: "100%", color: "red"}}
                              type={showPassword ? "text" : "password"}
                              onChange={(e) => setPassword2(e.target.value.trim())}
                              variant="outlined"
                              InputLabelProps={{
                                style: {
                                  color: "#9e95a9"
                                } 
                              }}
                              InputProps={{
                                style: {
                                  color: "#9e95a9"
                                } 
                              }}
                            />
                          </div>

                        </div>
                        <div>
                          <div className={classes.boxPasswordTextShowPassword} onClick={handleClickShowPassword}>{showPassword ? "Ocultar contraseña" :"Mostrar contraseña"}</div>
                        </div>
                        <div style={{marginTop: "20px"}}>
                          <div className={classes.boxTextInfoWhite}>
                            ○ Al menos 5 caracteres
                          </div>
                          <div className={classes.boxTextInfoWhite}>
                            ○ Al menos un número o símbolo especial
                          </div>
                        </div>

                        <Button
                          fullWidth
                          size="large"
                          variant="contained"
                          color="primary"
                          onClick={() => handleClick()}
                          style={{marginTop: "10px", backgroundColor: "#e7ca48", color: "black"}}
                        >
                          Actualizar contraseña
                        </Button>

                        {errorAction && (
                          <Alert severity="error" style={{ marginTop: "5px" }}>
                            <AlertTitle>Error</AlertTitle>
                            {errorAction}
                          </Alert>
                        )}


                        {/* </Paper> */}
                      </div>
                    </div>
                  )}

                  {error && (
                    <>
                      <h1>Error</h1>
                      <h3>Página no encontrada</h3>
                    </>
                  )}

                  {/* <ul>
                        <li className="li-clear n-banner-li"><img src={ nbannericon1 } alt="icono-bn1" className="icon-small"></img>Tu información disponible 24/7</li>
                        <li className="li-clear n-banner-li"><img src={ nbannericon2 } alt="icono-bn2" className="icon-small"></img>Fácil y rápido acceso a tus datos</li>
                        <li className="li-clear n-banner-li"><img src={ nbannericon3 } alt="icono-bn3" className="icon-small"></img>Seuridad y confidencialidad en toda tu información</li>
                    </ul> */}
                </div>
              </div>

          }
        </Loading>

      </div>

    </div>
  );
};
