/**
* @author Gabriel Vidal <gabrielwv1095@gmail.com>
* @desc hook para la reutilización de estado de reservas de un médico
*/


import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import moment from "moment";
import { consultarCitas } from 'redux/actions/aCitas';
import { consultarCitasOficina } from 'redux/actions/aCitas';
import { getReservasDefaultView } from 'utils/LocalStorage/LocalStorage';
import { saveReservasDefaultView } from 'utils/LocalStorage/LocalStorage';
import { useState } from 'react';

export const useCitasState = ({ entityId = null, entityName = 'doctor' }) => {

  const dispatch = useDispatch();
  const { data: citas, isLoading } = useSelector(state => state.datos_citas);
  const fechaInicial = new Date()
  const fechaFinal = new Date()
  fechaFinal.setMonth(fechaFinal.getMonth() + 1)

  //Vista 
  //selectedMode (0: Modo normal, 1: Modo calendario)

  const initCalendarRangeState = {
    calendarRangeInicial: moment(),
    calendarRangeFinal: moment(),
  }

  const [citasScreenState, setCitasScreenState] = React.useState({
    selectedMode: getReservasDefaultView(),
    selectedDateInicial: fechaInicial,
    selectedDateFinal: fechaFinal,
    selectedCalendarDate: new Date(),
    changeFlag: false,
  });

  const [firstLoad, setFirstLoad] = useState(true);

  const handleCitasScreenStateChange = (key, value) => {
    setCitasScreenState({ ...citasScreenState, [key]: value });
  };

  //Calendar
  const [calendarView, setCalendarView] = React.useState('month');
  const [calendarDateRange, setCalendarDateRange] = React.useState(initCalendarRangeState)

  //Reiniciar rangos de fechas al actualizarse entityId
  useEffect(() => {

    setCalendarDateRange({
      calendarRangeInicial: moment(),
      calendarRangeFinal: moment(),
    });
    setCitasScreenState({
      selectedMode: getReservasDefaultView(),
      selectedDateInicial: new Date(),
      selectedDateFinal: new Date().setMonth(new Date().getMonth() + 1),
      selectedCalendarDate: new Date(),
      changeFlag: false,
    });
  }, [entityId, entityName])


  const modeOptions = React.useMemo(() => [
    {
      label: 'Normal',
      value: 0
    },
    {
      label: 'Calendario',
      value: 1
    },
  ], []);

  const handleDateChangeInicial = date => handleCitasScreenStateChange('selectedDateInicial', date);

  const handleDateChangeFinal = date => handleCitasScreenStateChange('selectedDateFinal', date);


  const handleSelectMode = ({ target }) => {
    const { value } = target;
    setCitasScreenState({ ...citasScreenState, selectedMode: value, changeFlag: true });

    // Guardar cambios en local storage
    saveReservasDefaultView(value);
  };

  const doctorId = useSelector(state => state.dataUser.data.doctor_id);

  let { entitySelectedId, action } = useMemo(() => {
    let entitySelectedId;
    let action = consultarCitas;
    if (entityName === 'oficina') {
      entitySelectedId = doctorId;
      action = consultarCitasOficina;
      if (entityId)
        entitySelectedId = entityId;

    } else {
      if (entityId)
        entitySelectedId = entityId;
      else
        entitySelectedId = doctorId;
    }
    return { entitySelectedId, action }
  }, [entityId, entityName, doctorId]);



  //Cambio de modo
  React.useEffect(() => {

    setFirstLoad(false);

    if (citasScreenState.selectedMode === 1) {
      if (!firstLoad && !citasScreenState.changeFlag) {
        return
      }
      const formatString = 'YYYY-MM-DD'
      let startDate, endDate;

      switch (calendarView) {
        case 'day':
          startDate = (moment(citasScreenState.selectedCalendarDate).startOf('day')).format(formatString);
          endDate = (moment(citasScreenState.selectedCalendarDate).endOf('day')).format(formatString);
          break;

        case 'week':
          startDate = (moment(citasScreenState.selectedCalendarDate).startOf('isoWeek')).format(formatString);
          endDate = (moment(citasScreenState.selectedCalendarDate).endOf('isoWeek')).format(formatString);
          break;

        case 'month':
          startDate = (moment(citasScreenState.selectedCalendarDate).startOf('month').subtract(7, 'days')).format(formatString);
          endDate = (moment(citasScreenState.selectedCalendarDate).endOf('month').add(7, 'days')).format(formatString);
          break;

        default:
          startDate = (moment(citasScreenState.selectedCalendarDate).startOf('month').subtract(7, 'days')).format(formatString);
          endDate = (moment(citasScreenState.selectedCalendarDate).endOf('month').add(7, 'days')).format(formatString);
          break;
      }

      dispatch(action(entitySelectedId, startDate, endDate));
    } else {
      // if (!firstLoad) return;
      dispatch(action(entitySelectedId, citasScreenState.selectedDateInicial, citasScreenState.selectedDateFinal));
    }

  }, [dispatch, citasScreenState, calendarView, entitySelectedId, action, firstLoad]);

  const onCalendarOnDoubleClick = (e) => {
    // console.log('doble'+ e);
  }

  const calendarOnSelectEvent = (e) => {
    // console.log({e})
    const { r_inicio } = e;
    const formatString = 'YYYY-MM-DD'
    const startDate = moment(r_inicio).format(formatString);
    setCitasScreenState({
      ...citasScreenState,
      selectedMode: 0,
      selectedDateInicial: startDate,
      selectedDateFinal: startDate,
      changeFlag: true,
    });
    setCalendarDateRange({
      calendarRangeInicial: moment(),
      calendarRangeFinal: moment(),
    });
    // dispatch(eventSetActive(e));
  }

  const calendarOnSelectSlot = (slotInfo) => {
    // console.log({slotInfo})
    const { start } = slotInfo;
    const formatString = 'YYYY-MM-DD'
    const startDate = moment(start).format(formatString);
    setCitasScreenState({
      ...citasScreenState,
      selectedMode: 0,
      selectedDateInicial: startDate,
      selectedDateFinal: startDate,
      changeFlag: true,
    });
    setCalendarDateRange({
      calendarRangeInicial: moment(),
      calendarRangeFinal: moment(),
    });

  }
  const calendarOnViewChange = (view) => setCalendarView(view)


  const calendarOnNavigate = (date, view) => {
    // console.log('#### onNavigate');
    // console.log('#### date=', date);
    // console.log('#### view=', view);
    setCalendarView(view);
    // setSelectedCalendarDate(date);

    // let startDate, endDate;
    // switch (calendarView) {
    //   case 'day':
    //     startDate =(moment(date).startOf('day'));
    //     endDate =(moment(date).endOf('day'));
    //     break;

    //   case 'week':
    //     startDate =(moment(date).startOf('isoWeek'));
    //     endDate =(moment(date).endOf('isoWeek'));
    //     break;

    //   case 'month':
    //     startDate =(moment(date).startOf('month').subtract(7, 'days'));
    //     endDate =(moment(date).endOf('month').add(7, 'days'));
    //     break;

    //   default:
    //     startDate =(moment().startOf('month').subtract(7, 'days'));
    //     endDate =(moment().endOf('month').add(7, 'days'));
    //     break;
    // }

    //Establecer lugar actual del calendario enviando la fecha, flag en false para evitar cargar data
    setCitasScreenState({
      ...citasScreenState,
      selectedCalendarDate: date,
      changeFlag: false,
    });
  }

  const calendarOnRangeChange = (range) => {
    try {
      // console.log({range});
      let startDate;
      let endDate;
      const formatString = 'YYYY-MM-DD'
      if (Array.isArray(range)) {
        if (range.length === 7) {
          startDate = moment(range[0]);
          endDate = moment(range[6]);
        } else if (range.length === 1) {
          startDate = moment(range[0]);
          endDate = moment(range[0]);
        }
      } else {
        const { end, start } = range;
        startDate = moment(start);
        endDate = moment(end);

      }

      //Verificar rango de fechas, evitar llamadas cuando el rango es menor que el rango anterior guardado
      if (startDate.isSameOrAfter(calendarDateRange.calendarRangeInicial) && endDate.isSameOrBefore(calendarDateRange.calendarRangeFinal)) {
        return;
      }
      //Remplazar nuevo rango en caso de que se haya ampliado
      const rangeInicial = startDate.isBefore(calendarDateRange.calendarRangeInicial) ? startDate : calendarDateRange.calendarRangeInicial;
      const rangeFinal = endDate.isAfter(calendarDateRange.calendarRangeFinal) ? endDate : calendarDateRange.calendarRangeFinal;

      setCalendarDateRange({
        calendarRangeInicial: rangeInicial,
        calendarRangeFinal: rangeFinal,
      });

      dispatch(action(entitySelectedId, rangeInicial.format(formatString), rangeFinal.format(formatString)));
      // console.log({range});

    } catch (error) {
      console.log({ error });
    }
  }

  return {
    calendarOnNavigate,
    calendarOnRangeChange,
    calendarOnSelectEvent,
    calendarOnSelectSlot,
    calendarOnViewChange,
    calendarView,
    citas,
    citasScreenState,
    handleCitasScreenStateChange,
    handleDateChangeFinal,
    handleDateChangeInicial,
    handleSelectMode,
    isLoading,
    modeOptions,
    onCalendarOnDoubleClick,
    setCalendarView,
  }
}
