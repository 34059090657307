


import React, { useMemo } from 'react';
import CRUD from 'components/CRUD/Crud.component';
import { urlListarCIE10, urlEditarCIE10, urlEliminarCIE10, urlCrearCIE10 } from 'constants/urls';

const Cie10Crud = () => {

  const tableData = useMemo(() => [
    {
      type: "inputDisabled",
      label: "Id",
      name: 'id',
      showOnCreate: false,
      showOnTable: true,
      tableProps: {
        title: 'Id',
        sort: {
          sortColumnName: 'id',
          sortColumnNameDB: 'id',
        }
      },
    },
    {
      type: "input",
      name: "cod_3",
      label: "COD_3",
      tableProps: {
        title: 'COD_3',
        sort: {
          sortColumnName: 'cod_3',
          sortColumnNameDB: 'cod_3',
        }
      },
      validationOptions: {
        required: {
          value: true,
          message: "El campo cod_3 es requerido",
        },
      },
    },
    {
      type: "input",
      label: "Descripcion categoría tres caracteres",
      name: 'descripcion_categoria_tres_caracteres',
      tableProps: {
        title: 'Descripcion categoría tres caracteres',
        sort: {
          sortColumnName: 'descripcion_categoria_tres_caracteres',
          sortColumnNameDB: 'descripcion_categoria_tres_caracteres',
        }
      },
      validationOptions: {
        required: {
          value: true,
          message:
            "El campo descripcion_categoria_tres_caracteres es requerido",
        },
      },
    },
    {
      type: "input",
      name: "cod_4",
      label: "COD_4",
      tableProps: {
        title: 'COD_4',
        sort: {
          sortColumnName: 'cod_4',
          sortColumnNameDB: 'cod_4',
        }
      },
      validationOptions: {
        required: {
          value: true,
          message: "El campo cod_4 es requerido",
        },
      },
    },
    {
      type: "input",
      name: "descripcion_codigos_cuatro_caracteres",
      label: "Descripción categoría cuatro caracteres",
      tableProps: {
        title: 'Descripción categoría cuatro caracteres',
        sort: {
          sortColumnName: 'descripcion_codigos_cuatro_caracteres',
          sortColumnNameDB: 'descripcion_codigos_cuatro_caracteres',
        }
      },
      validationOptions: {
        required: {
          value: true,
          message:
            "El campo descripcion_codigos_cuatro_caracteres es requerido",
        },
      },
    },

  ], []);

  const data = useMemo(() => ({
    tableData,
    urls: {
      createUrl: urlCrearCIE10,
      updateUrl: urlEditarCIE10,
      readUrl: urlListarCIE10,
      deleteUrl: urlEliminarCIE10,
    }
  }), [tableData])

  return (
    <CRUD data={data} />
  )
}

export default Cie10Crud;
