import React, { useState, useEffect } from 'react';
//Core components
import { IconButton, Typography } from '@material-ui/core';
import Button from "components/CustomButtons/Button.js";

//Custom components
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import BuscadorVadecum from 'components/vadecum/vadecum-buscador.component';
import FormVadecum from 'components/vadecum/vadecum-form.component';
import Paper from '@material-ui/core/Paper';
//Styles
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import { makeStyles } from '@material-ui/core/styles';

//Icons
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { useDispatch, useSelector } from 'react-redux';

import { consultarVademecumDoctor } from 'redux/actions/aVademecum';

const useStyles = makeStyles(styles);

const Vadecum = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [objetoBuscado, setobjetoBuscado] = useState({});
  const [nuevo, setnuevo] = useState(true);
  //tipo '' nuevo, 'doctor', 'general'
  const [tipo, settipo] = useState('');

  const { isLoading, hasError } = useSelector(state => state.datos_vademecum_doctor);
  const { doctor_id } = useSelector(state => state.dataUser.data);
  const dataGeneral = useSelector(state => state.datos_vademecum.data);
  const dataDoctor = useSelector(state => state.datos_vademecum_doctor.data);

  const [keyMiVademecum, setKeyMiVademecum] = useState(1);
  const [keyCasaVademecum, setkeyCasaVademecum] = useState(1);


  useEffect(() => {
    // getAllVademecum();
    dispatch(consultarVademecumDoctor());
  }, [dispatch]);

  // useEffect(() => {
  //   if (Object.keys(objetoBuscado).length > 0) {
  //     setShowForm(true);
  //   } else {
  //     setShowForm(false);
  //   }
  // }, [objetoBuscado]);

  const handleBuscado = (event, values, esNuevo) => {
    if (values !== null) {
      const newValue = { ...values };
      setShowForm(false);
      setobjetoBuscado(newValue);
      setnuevo(esNuevo);
      setShowForm(true);
      if (esNuevo) settipo('')
    } else {
      setShowForm(false);
      setobjetoBuscado({});
    }
  };
  const handleShowForm = (nuevo = false) => {
    if (showForm) {
      setKeyMiVademecum(keyMiVademecum * -1);
      setkeyCasaVademecum(keyCasaVademecum * -1)
    }
    setShowForm(!showForm);
    if (showForm) settipo('')
    if (nuevo) setobjetoBuscado({})
  };

  return (
    <div onContextMenu={(e) => e.preventDefault()} onCopy={(e) => e.preventDefault()} onCut={(e) => e.preventDefault()}>
      <div style={{ display: 'flex' }}>
        <LocalHospitalIcon />
        <div borders="borderBottom">
          <Typography display="inline" variant="subtitle1">
            Vademécum
          </Typography>
        </div>
      </div>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitle}>Vademécum</h4>
        </CardHeader>
        <CardBody>
          <div>
            <Paper style={{ padding: 25, marginBottom: 15 }}>
              <div style={{ marginBottom: 16, fontWeight: 500 }}>
                <span>Nombre composicion casa comercial</span>
              </div>
              <div onClick={() => settipo('general')}>


                <BuscadorVadecum
                  key={keyCasaVademecum}
                  freeSolo={true}
                  poblarData={dataGeneral}
                  onBuscadoChange={(event, values) => handleBuscado(event, values)}
                  clearText='limpiar'
                />
              </div>
            </Paper>
            <Paper style={{ padding: 25, marginBottom: 15 }}>
              <Typography display="inline" variant="body1">
                Mi vademecum
              </Typography>
              <div onClick={() => settipo('doctor')}>
                <BuscadorVadecum
                  key={keyMiVademecum}
                  clearText='limpiar'
                  freeSolo={false}
                  poblarData={dataDoctor}
                  onBuscadoChange={(event, values) => handleBuscado(event, values, false)}
                />
              </div>
            </Paper>
          </div>
          <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
            {/* <IconButton onClick={() => handleShowForm(true)} style={{ color: showForm ? 'red' : '' }}>
              {showForm ? 'Cancelar' : <><AddCircleIcon /> Añadir medicamento</>}
            </IconButton> */}
            {
              showForm ?
                <Button color="error" onClick={() => handleShowForm(true)}>
                  Cancelar
                </Button>
                :
                <IconButton onClick={() => handleShowForm(true)} >
                  <AddCircleIcon /> Añadir medicamento
                </IconButton>
            }
          </div>
          {showForm && (
            <Paper style={{ padding: 25, marginBottom: 15 }}>
              <FormVadecum
                doctor={doctor_id}
                unObjeto={objetoBuscado}
                hasError={hasError}
                esNuevo={nuevo}
                borrar={tipo}
                isLoading={isLoading}
                handleShowForm={handleShowForm}
              />

            </Paper>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Vadecum;
