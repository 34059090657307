import { Box, makeStyles, Typography } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import moment from 'moment';
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux';
import OficinaCardInfo from '../OficinaCardInfo';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  disponible: {
    cursor: 'pointer',
    borderRightColor: 'green',
    borderRightStyle: 'ridge',
    borderRightWidth: '5px',
  },

  noDisponible: {
    cursor: 'not-allowed',
    borderRightColor: 'red',
    borderRightStyle: 'ridge',
    borderRightWidth: '5px',
  },
  horaDisponible: {
    fontWeight: 'bold',
  },
  horaNoDisponible: {
    textDecoration: 'line-through',
  },
}))

export const ReservaOficinaCard = ({ oficina, horarios, selectedDate, selectedDay, selectedHorario, handleClickCard }) => {

  const classes = useStyles();

  const ocupadosHorarios = useSelector(state => state.data_horarios_reservas.data.ocupados);

  const fechaSeleccionadaCalendario = useMemo(() => moment(selectedDate).format('YYYY-MM-DD'), [selectedDate]);

  const cliente = useSelector(state => state.data_clientes.data);

  const oficinaFormatted = useMemo(() => ({ id: oficina.id, direccion: oficina.direccion, nombre: oficina.nombre, serviciosAsignados: oficina.serviciosAsignados, doctorEspecializaciones: oficina.doctorEspecializaciones }), [oficina]);

  const horariosOrdered = useMemo(() => {
    //Ordenar oficinas por hora de inicio
    const horariosAux = horarios.sort((a, b) => (a.h_hora_inicio > b.h_hora_inicio) ? 1 : ((b.h_hora_inicio > a.h_hora_inicio) ? -1 : 0));

    //Pone en ocupado los horarios que han sido reservados
    for (let i = 0; i < horariosAux.length; i++) {
      let j = 0;

      const limiteEspaciosDisponibles = (horariosAux[i].h_espacios_disponibles) ?? 1;
      let espaciosDisponibles = limiteEspaciosDisponibles;

      // console.log('=======', horariosAux[i])
      while (j < ocupadosHorarios.length && !horariosAux[i].esReserva) {


        const fechaReserva = moment(ocupadosHorarios[j].inicio).format('YYYY-MM-DD');
        if (moment(fechaSeleccionadaCalendario).isSame(fechaReserva)) {
          const horaReserva = `${moment(ocupadosHorarios[j].inicio).format('HH:mm')}-${moment(ocupadosHorarios[j].fin).format('HH:mm')}`;
          const horaOficina = `${horariosAux[i].h_hora_inicio}-${horariosAux[i].h_hora_fin}`;

          if (limiteEspaciosDisponibles === 1 && horaOficina === horaReserva) {
            horariosAux[i].estado = true;
            horariosAux[i].esReserva = true;
          } else if (limiteEspaciosDisponibles > 1 && horaOficina === horaReserva) {
            if (ocupadosHorarios[j].cliente_id === cliente.id) {
              horariosAux[i].estado = true;
              horariosAux[i].esReserva = true;
              // console.log('cliente con un horariro')
            } else
              espaciosDisponibles--;
          }
        }
        j = j + 1;
      }

      // console.log({ espaciosDisponibles });
      if (limiteEspaciosDisponibles > 1 && espaciosDisponibles === 0) {
        horariosAux[i].estado = true;
        horariosAux[i].esReserva = true;
      }

      //Pone en ocupado los horarios que ya son anteriores
      for (let i = 0; i < horariosAux.length; i++) {
        const nowDiaHora = moment(new Date()).format('YYYY-MM-DD HH:mm')
        const auxConcat = moment(fechaSeleccionadaCalendario + ' ' + horariosAux[i].h_hora_inicio)
        const fechaSeleccionadaCalendarioHoras = moment(auxConcat).format('YYYY-MM-DD HH:mm')
        if (moment(fechaSeleccionadaCalendarioHoras).isBefore(nowDiaHora)) {
          horariosAux[i].estado = true;
        } else {
          if (horariosAux[i].estado === true && !horariosAux[i].esReserva) {
            horariosAux[i].estado = false;
          }
        }
      }
    }
    return horariosAux
  }, [horarios, fechaSeleccionadaCalendario, cliente, ocupadosHorarios]);

  const handleCardClick = valor => {
    if (!valor.estado) {
      handleClickCard({
        ...selectedHorario,
        fecha: selectedDate,
        hora: `${valor.h_hora_inicio}-${valor.h_hora_fin}`,
        id_oficina: oficina.id,
        fechaSeleccionada: fechaSeleccionadaCalendario,
        referencia_oficina: valor.o_direccion,
        servicio_id: valor.servicio_id,
        servicio_nombre: valor.servicio_nombre,
      });
    }
  };

  const updateHorarioData = valor => {
    handleClickCard(valor);
  };

  return (

    <div>
      <Box borderBottom={1}>
        <Typography display="inline" variant="subtitle1">
          {selectedDay}
        </Typography>
      </Box>
      <GridContainer>
        {horarios.length > 0 ? (
          <OficinaCardInfo oficina={oficinaFormatted} oficinasHorarios={horariosOrdered} classes={classes} selectedHorario={selectedHorario} fechaSeleccionadaCalendario={fechaSeleccionadaCalendario} handleCardClick={handleCardClick}
            setOficinaSelected={updateHorarioData}
          />
        ) : (
          <Typography style={{ margin: '50px auto' }} variant="subtitle1">
            ¡No tiene horarios guardados!
          </Typography>
        )}
      </GridContainer>
    </div>
  )
}
