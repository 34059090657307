import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
// core components
import { TextField } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { crearNotificacionPushGlobal } from "redux/actions/aNotificaciones";
import Notificacion from "components/ui/Notificacion";
import Loading from 'components/Loading';
import ConfirmDialog from "components/Admin/CRUD/ConfirmDialog";
import dashboardStyles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';

const useStyles = makeStyles(dashboardStyles);

const NotificacionesPush = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // console.log({roles});
  const [errors, setErrors] = useState({ title: null, body: null });
  const [error, seterror] = useState({ title: "", mensaje: "" });

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [valores, setValores] = useState({
    title: "",
    body: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValores({ ...valores, [name]: value });
  };


  const setearError = ({ title, mensaje }) => {
    seterror({ title, mensaje });
    setTimeout(() => {
      seterror({ title: "", mensaje: "" });
    }, 5000);
  };


  useEffect(() => {
    let errors = { title: null, body: null };

    //Validar titulo de notificacion
    if (valores.title.length === 0) {
      errors.title = "Título de la notificación es obligatorio";
    }
    if (valores.title.length > 60) {
      errors.title = "Título de la notificación no debe tener mas de 60 caracteres";
    }
    //Validar cuerpo de notificacion
    if (valores.body.length === 0) {
      errors.body = "Cuerpo de la notificación es obligatorio";
    }
    if (valores.body.length > 150) {
      errors.body = "Cuerpo de la notificación no debe tener mas de 150 caracteres";
    }
    setErrors(errors);
  }, [valores]);

  //Enviar datos del formulario
  const handleSubmit = async () => {
    try {
      setIsSubmit(true);

      if (errors.body || errors.title) {
        setearError({
          title: "error",
          mensaje: "Debe completar los campos correctamente",
        });
        return;
      }
      // console.log({ valores });
      setLoading(true);
      const { transaccion, mensaje } = await dispatch(crearNotificacionPushGlobal(valores));

      if (transaccion) {
        setIsSubmit(false);
        setValores({
          title: "",
          body: "",
        })
        Notificacion({
          type: "success",
          text: "Notificación enviada correctamente.",
        });

      } else {
        Notificacion({
          type: "error",
          text: mensaje,
        });
      }

      setLoading(false);
    } catch (error) {
      Notificacion({
        type: "error",
        text: "Ocurrio un error, intente nuevamente.",
      });
    }
  };


  return (
    <>
      <ConfirmDialog title={"Confirmación de envío"} open={openConfirmDialog}
        setOpen={setOpenConfirmDialog} onConfirm={() => handleSubmit()}  >
        <div>¿Está seguro que desea enviar esta notificación push?</div>
      </ConfirmDialog>
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitle}>Notificaciones Push</h4>
              <p className={classes.cardCategory}>Crear notificación push</p>
            </CardHeader>

            <CardBody>
              <Loading title="Cargando..." loading={loading}>
                <GridContainer spacing={5}>
                  <GridItem xs={12}>
                    <TextField
                      id="select"
                      label="Título de la notificación (max: 60 caracteres)"
                      name="title" value={valores.title}
                      style={{ width: "100%" }}
                      onChange={handleChange}
                      helperText={isSubmit && errors.title && errors.title}
                      error={isSubmit && errors.title && true}
                    />
                  </GridItem>
                  <GridItem xs={12}><div style={{ height: "10px" }}></div></GridItem>
                  <GridItem xs={12}>
                    <TextField
                      id="select"
                      label="Cuerpo de la notificación (max: 150 caracteres)"
                      name="body"
                      value={valores.body}
                      style={{ width: "100%" }}
                      onChange={handleChange}
                      multiline
                      minRows={3}
                      helperText={isSubmit && errors.body && errors.body}
                      error={isSubmit && errors.body && true}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    {error.mensaje && (
                      <Alert style={{ margin: 20 }} severity={`${[error.title]}`}>
                        <AlertTitle>
                          {error.title === "error" ? "Error" : "Success"}
                        </AlertTitle>
                        {error.mensaje}
                      </Alert>
                    )}
                  </GridItem>
                </GridContainer>
              </Loading>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={() => setOpenConfirmDialog(true)} disabled={loading}>
                {loading ? "Creando Notificación" : "Crear Notificación"}
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
};
export default NotificacionesPush;
