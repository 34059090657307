export const formatCie10 = (cie10, getPresuntivoDefinitivo = false) => {
  const cie10Array = cie10.split('|');
  if (getPresuntivoDefinitivo && cie10Array.length === 2) {
    return cie10Array[1];
  } else if (getPresuntivoDefinitivo) {
    return ''
  } else {
    return cie10Array[0];
  }
}

export const formatCie10Code = (cie10List) => {

  const cie10codes = [];
  cie10List.forEach(cie10 => {
    cie10codes.push(cie10.split(' ')[0])
  });
  return cie10codes.join(', ')
}


export const formatVacuna = (vacuna, label = true) => {
  if (!vacuna) return null;
  console.log({ vacuna });
  let array
  if (typeof newValue === 'string') {
    if (vacuna === '') return '';
    vacuna = {
      tipo: vacuna.split('-')[0],
      nombre_comercial: vacuna.split('-')[1],
      edad_optima: vacuna.split('-')[2],
      informacion_extra: vacuna.split('-')[3]
    };
  }

  if (label) {
    if (vacuna.custom) {
      return vacuna.tipo
    }
    array = [vacuna.tipo, vacuna.nombre_comercial ?? 'Sin nombre comercial', vacuna.edad_optima, vacuna.informacion_extra];
    return array.join(' - ')
  } else {
    array = [vacuna.tipo, vacuna.nombre_comercial ?? '', vacuna.edad_optima, vacuna.informacion_extra];
    return array.join('-')
  }
}

export const formatDataDB = (data) => (
  { ...data, db: true, }
)

// Precios
export const formatPayphonePrice = (data) => {
  try {
    return data / 100;
  } catch (error) {
    return ""
  }

}

// Transacciones
export const getTransactionString = (type) => {
  switch (type) {
    case 'appointment':
      return 'reserva'
    case 'credits':
      return 'créditos'
    default:
      break;
  }

}
