import React, { useContext } from 'react'
import { Box, Typography } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import { ReservasSucursalHorarios } from './ReservasSucursalHorarios';
import { SucursalReservacionContext } from './medico/reserva-step.component';

export const ReservasSucursales = () => {

  const {
    oficinas = [],
    selectedDay,
  } = useContext(SucursalReservacionContext);

  return (
    <div>
      <Box borderBottom={1}>
        <Typography display="inline" variant="subtitle1">
          {selectedDay}
        </Typography>
      </Box>
      <GridContainer>
        {oficinas.length > 0 ? (
          oficinas.map((oficina) => {
            return (
              <ReservasSucursalHorarios
                key={`${oficina.id}`}
                oficina={oficina}
              />
            );
          })
        ) : (
          <Typography style={{ margin: '50px auto' }} variant="subtitle1">
            ¡No tiene oficinas guardadas!
          </Typography>
        )}
      </GridContainer>
    </div>
  )
}
