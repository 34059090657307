import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

import Loading from '../Loading';
import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from '../CustomButtons/Button';
import HorarioForm1 from './horario-form1.component';

import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { horariosOficinaUpdate, getHorariosOficina, horariosOficinaSet } from 'redux/actions/aOficinas';
import Notificacion from 'components/ui/Notificacion';


const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
}));

const HorarioForm = ({ doctor_id, oficina_id, unHorario, dias, horarios, ...props }) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const [error, seterror] = useState({ title: '', mensaje: '' });
  const [editar, setEditar] = useState(false);

  const { institucion } = useSelector(state => state.dataUser.data);

  const [arreglo_hora, setArregloHora] = useState([]);

  useEffect(() => {
    setEditar(!!unHorario.dia_semana);
    const horario = [
      {
        id: !!unHorario.id ? unHorario.id : '',
        dia_semana: !!unHorario.dia_semana ? unHorario.dia_semana : '',
        hora_inicio: !!unHorario.hora_inicio ? unHorario.hora_inicio : '',
        hora_fin: !!unHorario.hora_fin ? unHorario.hora_fin : '',
        oficina_id: !!unHorario.oficina_id ? unHorario.oficina_id : '',
        espacios_disponibles: !!unHorario.espacios_disponibles ? unHorario.espacios_disponibles : 1,
      },
    ];
    setArregloHora(horario);

  }, [unHorario]);

  const handleClick = async () => {
    // debugger
    try {
      if (!arreglo_hora[0].hora_inicio || !arreglo_hora[0].hora_fin) {
        seterror({ title: 'error', mensaje: 'Debe completar los campos' });
        return;
      }

      const enviar = {
        data: arreglo_hora,
        doctor_id: doctor_id,
      };

      if (Object.keys(unHorario).length === 0) {
        await dispatch(horariosOficinaSet(enviar, oficina_id));
      } else {
        arreglo_hora[0].hora_inicio_tz = moment(new Date('2017-04-14 ' + arreglo_hora[0].hora_inicio)).format();
        arreglo_hora[0].hora_fin_tz = moment(new Date('2017-04-14 ' + arreglo_hora[0].hora_fin)).format();
        await dispatch(horariosOficinaUpdate(arreglo_hora[0], arreglo_hora[0].id));
      }

      if (props.hasError) {
        // setdia('');
        setArregloHora([{}]);
        seterror({ title: 'success', mensaje: props.hasError });
        Notificacion({
          type: "error",
          text: 'Ocurrio un error, intente nuevamente.',
        });
      } else {
        seterror({ title: 'error', mensaje: props.hasError });
        Notificacion({
          type: "success",
          text: 'Datos guardados correctamente.',
        });
      }
      await dispatch(getHorariosOficina(doctor_id));
    } catch (err) {
      seterror({
        title: 'error',
        mensaje: 'Ocurrio un error, intente nuevamente',
      });
      Notificacion({
        type: "error",
        text: 'Ocurrio un error, intente nuevamente',
      });
    }
  };

  const handleArrayHoraChange = (hora, index, key) => {
    //Accedo primero al índice de esa posición y luego agrego como key el contenido de la variable enviada

    const arregoAux = [...arreglo_hora];
    arregoAux[index][key] = (key === 'espacios_disponibles') ? (
      +hora < 1 ? 1 : +hora
    ) : hora;
    setArregloHora(arregoAux);
  };

  const handleArrayHoraDelete = index => {
    if (arreglo_hora.length > 1) {
      const aux = [...arreglo_hora];
      aux.splice(index, 1);
      setArregloHora(aux);
    }
  };

  const handleSetHoras = val => {
    setArregloHora([...val]);
  };

  return (
    <form
      style={{
        margin: '15px auto',
      }}
      className={classes.root}
      noValidate="noValidate"
      autoComplete="off"
    >
      <Loading title="Cargando..." loading={props.isLoading}>
        <>
          {arreglo_hora.length === 0 ||
            (!arreglo_hora[0].hora_inicio && (
              <HorarioForm1
                todosHorariosOcupados={horarios}
                diasConHorario={dias}
                tiempoConsulta={props.tiempoConsulta}
                handleSetHoras={handleSetHoras}
                oficina_id={oficina_id}
              />
            ))}
          {arreglo_hora.length > 0 && !!arreglo_hora[0].hora_inicio && (
            <>
              {arreglo_hora.map((hora, index) => (
                <GridContainer
                  key={index}
                  style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center',
                  }}
                >
                  <GridItem
                    xs={3}
                    md={3}
                    style={{
                      display: 'flex',
                      flex: 1,
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    {hora.dia_semana}
                  </GridItem>
                  <GridItem xs={3} md={3}>
                    <TextField
                      label="Inicio"
                      id="hora_inicio"
                      name="hora_inicio"
                      type="time"
                      fullWidth
                      placeholder="hh:mm"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={e => handleArrayHoraChange(e.target.value, index, 'hora_inicio')}
                      value={hora.hora_inicio}
                    />
                  </GridItem>
                  <GridItem xs={3} md={3}>
                    <TextField
                      id="hora_fin"
                      name="hora_fin"
                      label="Fin"
                      type="time"
                      fullWidth
                      placeholder="hh:mm"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={e => handleArrayHoraChange(e.target.value, index, 'hora_fin')}
                      value={hora.hora_fin}
                    />
                  </GridItem>
                  {
                    institucion &&
                    <GridItem xs={3} md={3}>
                      <TextField
                        id="espacios_disponibles"
                        name="espacios_disponibles"
                        label="Espacios disponibles"
                        type="number"
                        inputProps={{ inputMode: 'numeric', pattern: '[1-9]*' }}
                        // defaultValue={1}
                        fullWidth
                        // placeholder="hh:mm"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={e => handleArrayHoraChange(e.target.value, index, 'espacios_disponibles')}
                        value={hora.espacios_disponibles}
                      />
                    </GridItem>
                  }

                  {!editar && (
                    <>
                      <GridItem xs={2} md={1}>
                        <Tooltip
                          style={{ marginTop: '8px', marginLeft: '0' }}
                          id="tooltip-eliminar"
                          title="Eliminar hora"
                          placement="top"
                        >
                          <IconButton aria-label="Eliminar" onClick={() => handleArrayHoraDelete(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </GridItem>
                    </>
                  )}
                </GridContainer>
              ))}

              <div
                style={{
                  margin: '20px 8px',
                  flex: '1',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button size="large" variant="outlined" color="warning" onClick={() => handleClick()}>
                  Guardar
                </Button>
              </div>
            </>
          )}
        </>
      </Loading>
      {error.mensaje && (
        <Alert severity={`${[error.title]}`}>
          <AlertTitle>{error.title === 'error' ? 'Error' : 'Success'}</AlertTitle>
          {error.mensaje}
        </Alert>
      )}
    </form>
  );
};

export default HorarioForm;
