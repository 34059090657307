


import React from 'react';
import CRUD from 'components/CRUD/Crud.component';
import { urlAdminVacunas } from 'constants/urls';

const VacunasCrud = () => {
  const tableData = [
    {
      type: "inputDisabled",
      label: "Id",
      name: 'id',
      showOnCreate: false,
      showOnTable: true,
      tableProps: {
        sort: {
          sortColumnName: 'id',
          sortColumnNameDB: 'id',
        }
      },

    },
    {
      type: "input",
      name: "informacion",
      label: "Información",
      tableProps: {
        sort: {
          sortColumnName: 'informacion',
          sortColumnNameDB: 'informacion',
        }
      },
      validationOptions: {
        required: {
          value: true,
          message: 'El campo información es requerido',
        },
      },

    },
    {
      type: "input",
      name: "tipo",
      label: "Tipo",
      tableProps: {
        sort: {
          sortColumnName: 'tipo',
          sortColumnNameDB: 'tipo',
        }
      },
    },
    {
      type: "input",
      name: "edad_optima",
      label: "Edad óptima",
      tableProps: {
        sort: {
          sortColumnName: 'edad_optima',
          sortColumnNameDB: 'edad_optima',
        }
      },
    },
    {
      type: "input",
      name: "informacion_extra",
      label: "Información extra",
      tableProps: {
        sort: {
          sortColumnName: 'informacion_extra',
          sortColumnNameDB: 'informacion_extra',
        }
      },
    },
    {
      type: "input",
      name: "nombre_comercial",
      label: "Nombre comercial",
      tableProps: {
        sort: {
          sortColumnName: 'nombre_comercial',
          sortColumnNameDB: 'nombre_comercial',
        }
      },
    },
    
  ];

  const data = {
    tableData,
    urls: {
      createUrl: urlAdminVacunas,
      updateUrl: urlAdminVacunas,
      readUrl: urlAdminVacunas,
      deleteUrl: urlAdminVacunas,
    }
  }

  return (
    <CRUD data={data} />
  )
}

export default VacunasCrud;
