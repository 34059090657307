import React, { useState, useCallback, useEffect, useRef } from 'react';
import { GoogleMap, Marker, StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import _ from 'lodash';

const GoogleMaps = ({
  values,
  onMapClickUpdate
}) => {

  const { lat, lng } = values;
  const containerMapStyle = {
    width: '100%',
    height: '600px',
  };

  useEffect(() => {
    if (lat === 0 && lng === 0) {
      navigator.geolocation.getCurrentPosition(function ({ coords: { latitude: lat, longitude: lng } }) {
        const pos = { lat, lng };
        setCenterMapPoint(pos);
      });

    }
  }, [lat, lng]);

  const [centerMapPoint, setCenterMapPoint] = useState({
    lat: (lat && lat !== 0) ? lat : -2.900841353115272,
    lng: (lng && lng !== 0) ? lng : -79.00609552663141
  })

  const libraries = useRef(['places']);
  const { isLoaded: isLoadedMap } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: libraries.current,
  });

  const [map, setMap] = React.useState(null);
  const [mapZoom, setMapZoom] = useState(10);
  // const [center, setCenter] = useState(null);

  const onMapLoad = useCallback(
    (map) => {
      setMapZoom(20);
      setMap(map);
    },
    [],
  );

  const onUnmountMap = useCallback(
    () => {
      setMap(null);
    },
    [],
  )

  const onMapClick = () => {
    if (map) {
      const newCenter = map.getCenter();
      let geocoder = new window.google.maps.Geocoder();
      geocoder
        .geocode({ location: newCenter })
        .then((response) => {
          if (response.results[0]) {
            const result = response.results[0];
            console.log('Results', result)
            let zipCode = null;
            let country = null;
            let state = null;
            let city = null;
            for (var j = 0; j < result.address_components.length; j++) {
              for (var k = 0; k < result.address_components[j].types.length; k++) {
                switch (result.address_components[j].types[k]) {
                  case "postal_code":
                    zipCode = result.address_components[j].short_name;
                    break;
                  case "country":
                    country = result.address_components[j].long_name;
                    break;
                  case "administrative_area_level_1":
                    state = result.address_components[j].long_name;
                    break;
                  case "locality":
                    city = result.address_components[j].long_name;
                    break;

                  default:
                    break;
                }
              }
            }
            onMapClickUpdate
              (
                newCenter.lat(),
                newCenter.lng(),
                {
                  formattedAddress: result.formatted_address,
                  zipCode,
                  country,
                  state,
                  city
                }
              );
          } else {
            window.alert("No results found");
          }
        })
        .catch((e) => { console.log("Geocoder failed due to: " + e); onMapClickUpdate(newCenter.lat(), newCenter.lng()) });
    }
  }

  const searchBoxMapRef = useRef();

  function handleLoadSearchBox(ref) { searchBoxMapRef.current = ref }

  function onPlacesChanged() {
    const places = searchBoxMapRef.current.getPlaces();
    console.log({ places });

    const nextMarkers = places.map(place => ({
      position: place.geometry.location,
    }));
    const nextCenter = _.get(nextMarkers, '0.position', centerMapPoint);
    setCenterMapPoint(nextCenter);
  }

  // function onBoundsChanged() {
  //   const mapCenter = map.getCenter(); //get map center
  //   setCenter(mapCenter);
  // }

  const preventDefault = (e) => {
    e.stopPropagation()
  }
  const mapContainer = useRef();

  // Solucion bug scroll google maps, 
  // evita que la pagina haga scroll cuando el usuario mueve la rueda del mouse sobre el mapa
  useEffect(() => {
    let observerRefValue = null;
    if (mapContainer.current) {
      mapContainer.current.addEventListener('wheel', preventDefault);
      observerRefValue = mapContainer.current;
    }
    
    return () => {
      if (observerRefValue) {
        observerRefValue.removeEventListener('wheel', preventDefault)
      }
    }

  }, [isLoadedMap]);

  return isLoadedMap ? (

    <div
      ref={mapContainer}

    >
      <GoogleMap
        mapContainerStyle={containerMapStyle}
        center={centerMapPoint}
        zoom={mapZoom}
        onLoad={onMapLoad}
        onUnmount={onUnmountMap}
        // onBoundsChanged={onBoundsChanged}
        options={
          { fullscreenControl: false }
        }
        // libraries={["places"]}
        // onCenterChanged={onCenterChanged}
        onClick={onMapClick}


      >
        <StandaloneSearchBox
          ref={searchBoxMapRef}
          onLoad={handleLoadSearchBox}
          onPlacesChanged={
            onPlacesChanged
          }

        >
          <input
            type="text"
            color="black"
            placeholder="Buscar en google maps"
            // label='Buscar en google maps'
            style={{
              // backgroundColor: 'white',
              boxSizing: `border-box`,
              // border: `1px solid transparent`,
              width: `240px`,
              height: `32px`,
              padding: `0 12px`,
              border: '1px solid #ced4da',
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: "absolute",
              left: "50%",
              marginLeft: "-120px",
              fontWeight: 'bold',
              '::placeholder ': {
                color: 'red !important'
              }
            }}
          />
        </StandaloneSearchBox>
        {/* <Marker position={center} /> */}
        {
          (lng !== 0 && lat !== 0) &&
          <Marker

            position={{
              lat,
              lng
            }}
          />
        }
        <>
          <div onClick={onMapClick} style={{
            position: 'absolute',
            background: 'url(http://maps.gstatic.com/mapfiles/markers2/marker.png) no-repeat',
            top: '50%',
            left: '50%',
            zIndex: 1,
            marginLeft: '-10px',
            marginTop: '-34px',
            height: '34px',
            width: '20px',
          }}></div>
        </>
      </GoogleMap>

    </div>
  ) : (<></>)
}

export default GoogleMaps;