import React from 'react';
import { Fade, Modal, Paper } from '@material-ui/core';
import Backdrop from "@material-ui/core/Backdrop";
import CloseIcon from '@material-ui/icons/Close';
// import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(((theme) => ({
  //Modal
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: '50vw',
    maxWidth: '500px',
    minWidth: '300px',
    margin: 'auto',
  },
  modalContainer: {
    maxHeight: '75vh',
    overflow: 'auto',
    padding: '30px',
  },
  paper: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    width: "90%",
    maxWidth: 1000,
    padding: '5px',
    borderRadius: '15px',
    //flexGrow:1,
  },
  modalFixedTopArea: {
    position: 'absolute',
    top: 10,
    right: 5,
  },

  modalImagen: {
    textAlign: 'center'
  },

  modalTitulo: {
    textAlign: 'center'
  },

  dialogButton: {
    margin: '5px'
  }
})));

export const ModalContainer = ({ open, onCloseModal, children, title, confirmDialog = null }) => {

  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Paper className={classes.paper} square>
          <div>
            <div className={classes.modalFixedTopArea}>
              <CloseIcon color='primary' style={{ cursor: 'pointer' }} onClick={onCloseModal} />
            </div>
            <div className={classes.modalContainer} >
              {/* Exit button */}

              {/* Imagen Modal */}
              {/* <div className={classes.modalImagen}>
                <img src={img} alt="imgModal" style={{ maxWidth: "250px", width: "75%" }} />
              </div> */}
              {/* Contenido Modal */}
              <div>
                {/* Título */}
                <div className={classes.modalTitulo}>
                  <h4>{title}</h4>
                </div>
                {/* Descripción */}
                <div>
                  {children}
                </div>

                {/* Options */}
                {/* {
                  confirmDialog &&
                  (
                    <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'end' }}>
                      <Button
                        className={classes.dialogButton}
                        variant="contained"
                        onClick={onCloseModal}
                        color="secondary"
                      >
                        {cancelText}
                      </Button>
                      <Button
                        className={classes.dialogButton}
                        variant="contained"
                        onClick={handleConfirm}
                        color="default"
                      >
                        {confirmText}
                      </Button>

                    </div>
                  )
                } */}


              </div>
            </div>
          </div>

        </Paper>
      </Fade>
    </Modal>
  )
}
