import React from 'react'
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Login from "../Login";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Register } from '../Register';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    width: "90%",
    maxWidth: 500,
  },
  tabs: {
    height: "5rem",
  },
  divLink: {
    width: "100%",
  },
  link: {
    width: "100%",
    height: "100%",
    color: "#fff",
  },
}));

export const LoginModal = ({ open, handleOpen, handleClose, initValue = 0 }) => {

  const classes = useStyles();
  const theme = useTheme();

  const [value, setValue] = React.useState(initValue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Paper className={classes.paper} square>
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              aria-label="tabs inicio"
              centered
            >
              <Tab icon={<></>} label="Ingresar" />
              <Tab icon={<></>} label="Registrarse" />
            </Tabs>

            <TabPanel value={value} index={0} dir={theme.direction}>
              <Login />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <Register />
            </TabPanel>
          </Paper>
        </Fade>
      </Modal>

    </>
  )
}
