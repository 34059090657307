import React, { useCallback, useEffect, useRef, useState } from 'react';

import { activarUsuario } from 'services/user';
import { useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import { TextPage } from 'components/text/TextPage';
import { MessageTextCentered } from 'components/text/MessageTextCentered';

export const CuentaActivacion = () => {
  let { codigoActivacion } = useParams();

  const [activado, setActivado] = useState(false);
  const [error, setError] = useState(false);
  const [datosUsr, setDatosUser] = useState(null);

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    activarUsuario(codigoActivacion)
      .then(datos => {
        if (isMounted.current) {
          if (datos.transaccion) {
            setDatosUser(datos.usuario);
            setActivado(true);
          } else {
            setError(true);
          }
        }
      })
      .catch(err => {
        if (isMounted.current) {
          setError(true);
        }
      });
  }, [codigoActivacion]);

  const formatStringData = useCallback(
    (data, punto = "") => {
      if (data && data.trim() !== "Sin definir")
        return `${data}${punto}`;
      else
        return "";
    },
    [],
  );

  return (
    <TextPage>
      <div>
        <div className="n-banner-empty"></div>
        <div className="banner-tex n-banner-tex container-small" style={{ height: '100vh' }}>
          <MessageTextCentered>
            <Loading title="Cargando..." loading={!activado && !error}>
              <>
                {activado && (
                  <>
                    <h1>Activación de cuenta</h1>
                    <h3>
                      {datosUsr && `${formatStringData(datosUsr.firstName)} ${formatStringData(datosUsr.lastName, ".")}`} Su cuenta ha sido activada
                      correctamente, ya puede iniciar sesión en nuestro sistema
                    </h3>
                  </>
                )}

                {error && (
                  <>
                    <h1>Error</h1>
                    <h3>Página no encontrada</h3>
                  </>
                )}
              </>
            </Loading>
          </MessageTextCentered>

        </div>
      </div>
    </TextPage>


  );
};
