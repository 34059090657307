import React, { useCallback, useEffect, useState } from 'react'
import { Paper, makeStyles } from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { AutocompleteComponent } from 'components/ui/AutocompleteComponent'
import { useDispatch } from 'react-redux'
import { getInstitucionPorBusqueda } from 'redux/actions/aMedico'
import { OficinaCardCheck } from '../OficinaCardCheck'
import { obtenerSucursales } from 'redux/actions/aInstitucion'
import Loading from 'components/Loading'
import Button from "../../CustomButtons/Button";
import { enviarSolicitudAsociacionInstitucionSucursal } from 'redux/actions/aMedico'
import { obtenerDatosInstitucionesAsociaciones } from 'redux/actions/aMedico'
import ConfirmDialog from 'components/Admin/CRUD/ConfirmDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1.3),
    },
  },
  container: {
    padding: '20px',
  },

}));

export const FormInstitucionSucursal = ({ handleCerrarFormSucursal }) => {

  const dispatch = useDispatch();
  const [institucionesBusqueda, setInstitucionesBusqueda] = useState([]);
  const [institucionSelected, setInstitucionSelected] = useState(null);
  const [sucursales, setSucursales] = useState([]);

  // Confirm dialog
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [doctorAsociacionesData, setDoctorAsociacionesData] = useState({
    "asociacionesOficinasIds": [],
    "asociacionesSolitudesOficinasIds": [],
    "empresaAsociacionesLimite": 0,
    "asociacionesCount": 0,
    "asociacionesSolicitudesCount": 0,
    "asociacionesRestantes": 0,
    "solicitudesAsociacionesRestantes": 0
  });

  const [loading, setLoading] = useState(false);
  const [loadingSucursales, setLoadingSucursales] = useState(false);

  const [officinasIdsSelected, setOfficinasIdsSelected] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    dispatch(obtenerDatosInstitucionesAsociaciones()).then(data => {
      if (!isMounted) return;
      setDoctorAsociacionesData(data)
      setLoading(false);
    })
    return () => { isMounted = false };
  }, [dispatch])

  const startSearchInstituciones = useCallback(
    async (value) => {
      try {
        const { data, transaccion } = await dispatch(getInstitucionPorBusqueda(value));
        if (transaccion)
          setInstitucionesBusqueda(data);
      } catch (error) {
        console.log({ error })
      }
    },
    [dispatch],
  );

  const handleInstitucionSelect = useCallback((___, value, __) => {
    const institucion = (typeof value === "object") ? value : null;
    setInstitucionSelected(institucion);
  }, []);

  const handleAddOfficeId = (officeId) => {
    if (officinasIdsSelected.includes(officeId)) return
    setOfficinasIdsSelected([...officinasIdsSelected, officeId]);
  }

  const handleDeleteOfficeId = (officeId) => {
    if (!officinasIdsSelected.includes(officeId)) return;
    const officesFiltered = officinasIdsSelected.filter(id => id !== officeId);
    setOfficinasIdsSelected(officesFiltered);
  }

  const handleEnviarSolicitudSucursales = async () => {
    if (officinasIdsSelected.length === 0) return
    setLoadingSucursales(true);
    await dispatch(enviarSolicitudAsociacionInstitucionSucursal(officinasIdsSelected));
    setLoadingSucursales(false);
    handleCerrarFormSucursal();
  }

  const filterSucursales = useCallback(
    (sucursalesData = []) => {
      const sucursalesFiltered = sucursalesData.filter(
        item => !doctorAsociacionesData.asociacionesOficinasIds.includes(item.id) &&
          !doctorAsociacionesData.asociacionesSolitudesOficinasIds.includes(item.id)
      )
      setSucursales(sucursalesFiltered);
    },
    [doctorAsociacionesData],
  )


  useEffect(() => {
    if (institucionSelected) {
      setLoadingSucursales(true);
      dispatch(obtenerSucursales(institucionSelected.id))
        .then(data => {
          // setSucursales(data);
          filterSucursales(data);
          setLoadingSucursales(false);
        })
        .catch(err => {
          setLoadingSucursales(false);
        })
    }

  }, [institucionSelected, dispatch, filterSucursales])

  return (
    <>
      <ConfirmDialog title={"Confirmación de envío"} open={openConfirmDialog}
        setOpen={setOpenConfirmDialog} onConfirm={handleEnviarSolicitudSucursales}  >
        <div>¿Está seguro de enviar la/s solicitud/es de asociación a las instituciones de las oficinas seleccionadas?</div>
      </ConfirmDialog>
      <Loading loading={loading} title="Cargando...">
        <Paper className={classes.container}>
          {
            doctorAsociacionesData.asociacionesRestantes === 0 &&
            <div style={{ textAlign: 'center', padding: '5px' }} >
              Se ha excedido el límite de número de asociaciones
            </div>

          }
          <GridContainer>
            <GridItem xs={12}>
              <AutocompleteComponent
                label="Buscar institución"
                freeSolo={true}
                poblarData={[]}
                onBuscadoChange={(event, values) => handleInstitucionSelect(event, values, true)}
                optionLabel={option => option.id ? `${option.nombre}` : ''}
                dispatchFunction={startSearchInstituciones}
                dataSearch={institucionesBusqueda}
                // value={valores.institucion}
                reduxFunction={false}
                getOptionSelected={(option, value) => option.id === value.id}
              />
            </GridItem>
          </GridContainer>

          {/* Area sucursales */}
          <Loading loading={loadingSucursales} title="Cargando...">
            <>
              <div style={{ minHeight: '40px', marginTop: '40px' }}>
                {
                  institucionSelected && sucursales.length === 0 &&
                  <div style={{ textAlign: 'center', padding: '5px' }} >
                    No se han encontrado sucursales en la institución seleccionada
                  </div>
                }

                {
                  !institucionSelected &&
                  <div style={{ textAlign: 'center', padding: '5px' }} >
                    No se ha seleccionado una institución
                  </div>
                }

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  {
                    sucursales.map(sucursal => (
                      <OficinaCardCheck key={sucursal.id}
                        data={sucursal}
                        officinasIdsSelected={officinasIdsSelected}
                        handleAddOfficeId={handleAddOfficeId}
                        handleDeleteOfficeId={handleDeleteOfficeId}
                        asociaciones={doctorAsociacionesData.asociacionesOficinasIds}
                        asociacionesSolicitudes={doctorAsociacionesData.asociacionesSolitudesOficinasIds}
                      />
                    ))
                  }
                </div>

              </div>

              {/* Area guardado */}
              <div>
                {
                  officinasIdsSelected.length > 0 &&
                  <div
                    style={{
                      margin: "20px 8px",
                      flex: "1",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="warning"
                      onClick={() => setOpenConfirmDialog(true)}
                    >
                      Enviar solicitud
                    </Button>
                  </div>
                }

              </div>

            </>

          </Loading>

        </Paper>
      </Loading>
    </>


  )
}
