import React from "react";
import Footer from "../components/Footer/Footer";
import { Navbar } from "components/Header/Navbar";
import { backgroundColor } from "assets/jss/material-dashboard-react"

const MainLayout = ({ children }) => (
  <div className="container-fluid" style={{ background: backgroundColor }}>
    <Navbar />
    <div id="main" >{children}</div>
    <Footer />
  </div>
);

export default MainLayout;
