import React, { useCallback, useMemo, useState } from 'react'
import Notificacion from 'components/ui/Notificacion';
import { clienteFormProps } from 'constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { clienteSet } from 'redux/actions/aClientes';
import { Form } from './Form';
import Loading from 'components/Loading';
import { getSegurosPorBusqueda } from 'redux/actions/aMedico';
import { startSearchPaises } from 'redux/actions/aClientes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const ClienteForm = ({ doctor }) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const doctor_id = useSelector(state => state.dataUser?.data?.doctor_id);
  const { isLoadingClientes } = useSelector(state => state.data_clientes);

  const [segurosData, setSegurosData] = useState([]);
  const [paisesData, setPaisesData] = useState([]);

  const handleSearchSeguros = useCallback(
    async (value) => {
      try {
        const { data } = await dispatch(getSegurosPorBusqueda(value));
        setSegurosData(data);

      } catch (error) {
      }
    },
    [dispatch],
  )

  const handleSearchPaises = useCallback(
    async (value) => {
      try {
        const { data } = await dispatch(startSearchPaises(value));
        setPaisesData(data);

      } catch (error) {
      }
    },
    [dispatch],
  )

  const formData = useMemo(
    () => [
      {
        ...clienteFormProps.tituloHonorifico,
        component: "textField",
        gridProps: {
          md: 3,
          xs: 12,
        }
      },
      {
        ...clienteFormProps.cedulaIdentidad,
        component: "textField",
        gridProps: {
          md: 3,
          xs: 12,
        }
      },
      {
        component: "textField",
        ...clienteFormProps.nombres,
        gridProps: {
          md: 3,
          xs: 12,
        }
      },
      {
        component: "textField",
        ...clienteFormProps.apellidos,
        gridProps: {
          md: 3,
          xs: 12,
        }
      },
      {
        component: "textField",
        ...clienteFormProps.correo,
        gridProps: {
          md: 4,
          xs: 12,
        }
      },
      {
        component: "textField",
        ...clienteFormProps.direccion,
        gridProps: {
          md: 8,
          xs: 12,
        }
      },
      {
        component: "textField",
        ...clienteFormProps.celular,
        gridProps: {
          md: 4,
          xs: 12,
        }
      },
      {
        component: "textField",
        ...clienteFormProps.telefono,
        gridProps: {
          md: 4,
          xs: 12,
        }
      },
      {
        component: "textField",
        type: 'date',
        ...clienteFormProps.fechaNacimiento,
        gridProps: {
          md: 4,
          xs: 12,
        }
      },

      {
        component: "textField",
        ...clienteFormProps.cedulaRepresentante,
        gridProps: {
          md: 4,
          xs: 12,
        }
      },
      {
        component: "textField",
        ...clienteFormProps.nombresPadre,
        gridProps: {
          md: 4,
          xs: 12,
        }
      },
      {
        component: "textField",
        ...clienteFormProps.nombresMadre,
        gridProps: {
          md: 4,
          xs: 12,
        }
      },
      {
        component: "select",
        ...clienteFormProps.sexo,
        gridProps: {
          md: 4,
          xs: 12,
        }
      },
      {
        component: "autocompleteMultipleV2",
        ...clienteFormProps.nacionalidad,
        multiple: false,
        freeSolo: false,
        dispatchFunction: handleSearchPaises,
        options: paisesData,
        gridProps: {
          md: 4,
          xs: 12,
        }
      },
      {
        component: "autocompleteMultipleV2",
        ...clienteFormProps.pais,
        multiple: false,
        freeSolo: false,
        dispatchFunction: handleSearchPaises,
        options: paisesData,
        gridProps: {
          md: 4,
          xs: 12,
        }
      },
      {
        component: "textField",
        ...clienteFormProps.ciudad,
        gridProps: {
          md: 4,
          xs: 12,
        }
      },
      {
        component: "textField",
        ...clienteFormProps.ocupacion,
        gridProps: {
          md: 4,
          xs: 12,
        }
      },
      {
        component: "select",
        ...clienteFormProps.instruccion,
        gridProps: {
          md: 4,
          xs: 12,
        }
      },

      {
        component: "select",
        ...clienteFormProps.estadoCivil,
        gridProps: {
          md: 4,
          xs: 12,
        }
      },

      {
        component: "select",
        ...clienteFormProps.tipoSangre,
        gridProps: {
          md: 4,
          xs: 12,
        }
      },
      {
        component: "autocompleteMultipleAsync",
        name: "seguros",
        label: "Seguros",
        defaultValue: null,
        freeSolo: true,
        multiple: true,
        dispatchFunction: handleSearchSeguros,
        options: segurosData,
        gridProps: {
          md: 4,
          xs: 12,
        }
      },

    ],
    [segurosData, handleSearchSeguros, handleSearchPaises, paisesData]
  );

  const onSubmit = async (data) => {
    try {
      let dataToSend = {
        ...data,
        doctor: doctor ? doctor : doctor_id
      };
      const { transaccion, mensaje } = await dispatch(clienteSet(dataToSend));
      if (transaccion) {
        // Redirigir paciente creado a historia clínica
        localStorage.setItem('setCliente', 'true');
        history.push('/medico/historia-clinica');
      }
      else
        Notificacion({
          type: "error",
          text: mensaje,
        })

    } catch (error) {
      Notificacion({
        type: "error",
        text: 'Ocurrio un error, intente nuevamente',
      })
    }

  }

  return (
    <div>
      <Loading title="Guardando..." loading={isLoadingClientes}>
        <Form data={formData} onSubmitForm={onSubmit} />
      </Loading>
    </div>
  )
}
