import React, { useEffect } from 'react';
import HorarioOficinaSecretaria from '../../components/horario-oficina/secretaria/horario-oficina.component';
import SelectMedico from 'components/SeleccionarMedico/SelectMedico.component';
import { useDispatch, useSelector } from 'react-redux';
import { getHorariosReservaSecretaria } from 'redux/actions/aOficinas';

export const HorarioCitasSecretaria = () => {
  const dispatch = useDispatch();
  const [oficinasMedico, setOficinasMedico] = React.useState([]);
  const { doctorSelected } = useSelector(state => state.medicos_secretaria);

  useEffect(() => {
    if (doctorSelected) dispatch(getHorariosReservaSecretaria(doctorSelected.id));
  }, [doctorSelected, dispatch]);

  return (
    <>
      <SelectMedico setOficinasMedico={setOficinasMedico} />
      {doctorSelected && <HorarioOficinaSecretaria oficinas={oficinasMedico} doctor_id={doctorSelected.id} />}
    </>
  );
};

