import React, { useState } from 'react';
import _ from 'lodash';
//Core components
import { CircularProgress, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

//Custom components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

//Icons
// import SearchIcon from '@material-ui/icons/Search';

//Styles
import { makeStyles } from '@material-ui/core/styles';

//Redux
import { consultarVademecumTodoLimit } from 'redux/actions/aVademecum';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
}));

const BuscadorTodoVadecum = ({ poblarData, freeSolo, onBuscadoChange }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector(state => state.datos_vademecum);

  const callDebounce =  _.debounce((valor) => dispatch(consultarVademecumTodoLimit(valor)), 500) 

  const handleOnKeyUp = valor => {
    if (freeSolo && valor) callDebounce(valor);  
  };

  const [value] = useState(null);

  return (
    <GridContainer className={classes.root} style={{ flex: 1 }}>
      <GridItem xs={12}>
        <Autocomplete
          value={value}
          clearOnBlur
          id="searchVadecum"
          name="searchVadecum"
          options={freeSolo ? data : poblarData}
          onChange={onBuscadoChange}
          freeSolo={freeSolo}
          noOptionsText="No se encontraron resultados"
          getOptionLabel={
            option => 
              option 
                ? `${option.nombre} ${option.composicion} ${option.funcion} ${option.dosificacion} ${option.presentacion} ${option.casa_comercial} `
                : ''
          }
          loading={isLoading}
          loadingText='Buscando...'
          renderInput={params => (
            <TextField
              type="input"
              {...params}
              onKeyUp={e => handleOnKeyUp(e.target.value)}
              placeholder="Nombre/Composición/Función/Dosificación/Presentación/Casa comercial"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </GridItem>
    </GridContainer>
  );
};

export default BuscadorTodoVadecum;
