import { createAction } from 'redux-actions';
import { urlEspecialidades } from '../../constants/urls';
import { jwt } from './aLogin';

//Especialidades
export const especialidades = createAction('ESPECIALIDADES');
export const isLoadingEspecialidades = createAction('IS_LOADING_E');
export const hasErrorEspecialidades = createAction('HAS_ERROR_E');

export const getEspecialidades = params => async (dispatch, getstate) => {
  dispatch(isLoadingEspecialidades());
  try {
    const state = getstate();
    const { token } = state;

    var requestOptions = {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };

    return fetch(urlEspecialidades, requestOptions)
      .then(response => response.json())
      .then(datos => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(''));
          return;
        }

        if (datos.transaccion) {
          dispatch(especialidades(datos.data));
        } else {
          dispatch(hasErrorEspecialidades(datos.mensaje));
        }
      })
      .catch(errr => {});
  } catch (error) {
    dispatch(hasErrorEspecialidades('No se pudo recuperar la información recargue la pagina '));
  }
};

export const getEspecialidadesPorBusqueda = (cadenaBusqueda) => async (
  _,
  getstate
) => {
  try {
    const { token } = getstate();
    // const {token, dataUser} = state;

    var requestOptions = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${urlEspecialidades}/search/${cadenaBusqueda}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) return datos;
    else throw (new Error(datos.mensaje));
  } catch (error) {
    throw (error);
  }
}

export const getEspecialidadesAndCategories = params => async (dispatch, getstate) => {
  dispatch(isLoadingEspecialidades());
  try {
    const state = getstate();
    const { token } = state;

    var requestOptions = {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };

    return fetch(urlEspecialidades, requestOptions)
      .then(response => response.json())
      .then(datos => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(''));
          return;
        }

        if (datos.transaccion) {
          dispatch(especialidades(datos.data));
        } else {
          dispatch(hasErrorEspecialidades(datos.mensaje));
        }
      });
  } catch (error) {
    dispatch(hasErrorEspecialidades('No se pudo recuperar la información recargue la pagina '));
  }
};
