import React from 'react'
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import { RecetaMembrete } from '../receta/RecetaContentPdf';
import caracteristicas from '../../../assets/images/medpal.svg';
import { calculateAgeString } from 'utils/paciente/paciente';

const useStyles = makeStyles(() => ({
  container: {
    margin: '20px',
  },
  header: {
    textAlign: 'start',
    height: "100px",
    borderBottom: "1px solid black",
    display: "flex", alignItems: "center",
    marginBottom: '10px'
  },
  headerNoBorder: {
    textAlign: 'start',
    height: "101px",
    display: "flex", alignItems: "center",
    marginBottom: '10px'
  },
  tableRow: {
    textAlign: 'center',
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  },
  tableRowPadding: {
    padding: '10px 0'
  }
}));

const formatCie10 = (cie10List) => {

  const cie10codes = [];
  cie10List.forEach(cie10 => {
    cie10codes.push(cie10.split(' ')[0])
  });
  return cie10codes.join(', ')
}

export const ExamenPrint = React.forwardRef(({ header = true, data }, ref) => {

  const { patient, doctor, exams = [], cie10List = [] } = data;
  const cie10Text = cie10List ? formatCie10(cie10List) : '';
  const { titulo_honorifico, nombres, apellidos, especializaciones } = doctor;
  const { nombres: patientName, apellidos: patientSurname, cedula_identidad, sexo, fecha_nacimiento } = patient;

  const patientAge = calculateAgeString(fecha_nacimiento);

  const doctorName = `${titulo_honorifico} ${nombres} ${apellidos}`;
  const patientCompleteName = `${patientName} ${patientSurname}`;

  let especializacionesDoctor = "";
  especializaciones.map(({ nombre }) => (especializacionesDoctor.length === 0 ? especializacionesDoctor = especializacionesDoctor.concat(nombre) : especializacionesDoctor = especializacionesDoctor.concat(`, ${nombre}`)));

  const classes = useStyles();

  return (
    <div ref={ref} >
      <table style={{ width: '100%', pageBreakAfter: 'always' }}>
        <thead style={{ display: 'table-header-group' }}>
          <tr>
            <th>
              <div className={header ? classes.header : classes.headerNoBorder} >
                {
                  header &&
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <td>
                          <RecetaMembrete />
                        </td>
                        <td>
                          <RecetaMembrete />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                }
              </div>
            </th>
          </tr>
        </thead>
        <tbody >
          <tr>
            <td>
              <div className={classes.container}>
                <table style={{ width: '100%' }}>
                  <tbody >
                    <tr>
                      <td align='center'>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div>
                            <img
                              style={{ margin: 'auto' }}
                              alt='logo'
                              src={caracteristicas}
                              width={75}
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div>
                          </div>
                          <div>
                            {moment().format('LL')}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} align='center'>
                        <strong>
                          Pedido de exámenes
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingTop: '20px' }}>
                        <div>
                          {doctorName}
                        </div>
                        <div>Especialidad: {especializacionesDoctor}</div>
                        <div>Nombre del paciente: {patientCompleteName}</div>
                        <div>Información clínica del paciente: {cie10Text}</div>
                      </td>
                      <td style={{ paddingTop: '20px' }}>

                        <div>Cédula/Pasaporte: {cedula_identidad}</div>
                        <div>Sexo: {sexo}</div>
                        <div>Fecha de nacimiento: {moment.utc(fecha_nacimiento).format('LL')}</div>
                        <div>Edad: {patientAge}</div>
                      </td>
                    </tr>
                    <tr style={{ paddingTop: '10px' }}>
                      <td colSpan={2} style={{ paddingTop: '20px' }}>
                        {/* Exams Table */}
                        <table style={{ width: '100%' }}>
                          <thead >
                            <tr>
                              <th className={classes.tableRow}>Nombre</th>
                              <th className={classes.tableRow}>Detalles</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              exams.map(exam => (
                                <tr key={exam.id}>
                                  <td className={`${classes.tableRow} ${classes.tableRowPadding}`} width={'40%'}>{exam.nombre}</td>
                                  <td className={`${classes.tableRow} ${classes.tableRowPadding}`} width={'60%'}>{exam.detalles}</td>
                                </tr>
                              ))
                            }
                            <tr>
                              <td>

                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr align='center'>
                      <td colSpan={2} style={{ paddingTop: '40px' }}>
                        <div >
                          Atentamente
                        </div>
                        <div style={{ height: '70px' }}></div>
                        <div>
                          <strong>
                            {doctorName}
                          </strong>

                        </div>
                        <div>Especialidad: {especializacionesDoctor}</div>

                      </td>
                    </tr>
                  </tbody>

                </table>
              </div>

            </td>
          </tr>

        </tbody>
      </table>
    </div>
  )
})

