import { dangerColor } from "assets/jss/material-dashboard-react";

const formStyle = (theme) => ({
  errorValidationLabel: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: '0px',
    width: "100%",
    color: `${dangerColor[0]} !important`,
  },
  formLabel: {
    fontSize: '13px !important',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '500 !important',
    lineHeight: '1.42857 !important',
    color: '#999 !important'
  },
})

export default formStyle;