import React, { useMemo } from 'react';
import CRUD from 'components/CRUD/Crud.component';
import medicoSVG from "../../../src/assets/images/icons/medico.svg";
import { Register } from 'components/Login/Register';
import { RoleTypes } from 'types';
import { urlAdminSecretaria } from 'constants/urls';

const SecretariaCrud = () => {

  const fileField = React.useRef(null);

  const tableData = useMemo(() => [
    {
      type: "imageInput",
      name: "foto_nombre",
      label: "Imagen",
      imgDefault: medicoSVG,
      ref: fileField
    },
    {
      type: "inputDisabled",
      label: "Id",
      name: 'id',
      showOnCreate: false,
      showOnTable: true,
      tableProps: {
        title: 'Id',
        sort: {
          sortColumnName: 'id',
          sortColumnNameDB: 'id',
        }
      },
    },
    {
      type: "input",
      name: "titulo_honorifico",
      label: "Título Honorífico",
      tableProps: {
        sort: {
          sortColumnName: 'titulo_honorifico',
          sortColumnNameDB: 'titulo_honorifico',
        }
      },
    },
    {
      type: "input",
      hideOnForm: true,
      name: "nombres",
      label: "Nombres",
      validationOptions: {
        required: {
          value: true,
          message: 'El campo nombres es requerido',
        },
      },
      tableProps: {
        title: 'Nombres',
        sort: {
          sortColumnName: 'nombres',
          sortColumnNameDB: 'nombres',
        }
      },
    },
    {
      type: "input",
      hideOnForm: true,
      name: "apellidos",
      label: "Apellidos",
      validationOptions: {
        required: {
          value: true,
          message: 'El campo apellidos es requerido',
        },
      },
      tableProps: {
        title: 'Apellidos',
        sort: {
          sortColumnName: 'apellidos',
          sortColumnNameDB: 'apellidos',
        }
      },
    },

  ], []);


  const data = useMemo(() => ({
    // defaultSortByColumn: 'registro_date',
    // defaultSortOrder: 'DESC',
    tableData,
    urls: {
      url: urlAdminSecretaria
    }
  }), [tableData])

  return (
    <CRUD data={data} CustomCreateFormComponent={() => <Register defaultRole={RoleTypes.secretary} adminMode />} />
  )
}

export default SecretariaCrud;
