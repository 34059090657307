export const RoleTypes = {
  secretary: 'ROLE_SEC',
  doctor: 'ROLE_MED',
  lab: 'ROLE_LAB',
  client: 'ROLE_CLI',
}

export const ReservaEstadoType = {
  Aprobado: 'APROBADO',
  Cancelado: 'CANCELADO',
  Rechazado: 'RECHAZADO',
}