import React from 'react'
import { RecetaContentPdf } from './RecetaContentPdf';

export const RecetaPrint = React.forwardRef(({ data, header = true, preview = false }, ref) => {
  return (
    <div ref={ref} >
      {
        <RecetaContentPdf data={data} header={header} preview={preview}/>      
      }
    </div>
  )
})
