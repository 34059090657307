import React from 'react'
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import moment from "moment";

export const CajaPrint = React.forwardRef(({ data }, ref) => {
  const { data: Data, fecha, total, checkedCost } = data;
  const totalFinal = (+total) - ((+total) - (+checkedCost));

  return (
    <div ref={ref}>
      <Box margin={1}>
        <div>
          <strong>Fecha: {moment(fecha).format("DD-MM-yyyy")}</strong>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell align="right">Hora</TableCell>
                <TableCell align="right">Nombre</TableCell>
                <TableCell align="right">Apellido</TableCell>
                <TableCell align="right">Identificación</TableCell>
                <TableCell align="center">Tipo de servicio</TableCell>
                <TableCell align="center">Programa</TableCell>
                <TableCell>Detalle</TableCell>
                <TableCell>Forma pago</TableCell>
                <TableCell align="center">Costo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Data && Data.map((dataRow, index) => (
                dataRow.r_entregado && (
                  <TableRow key={dataRow.r_id}>
                    <TableCell>
                      {dataRow.r_hora_pago}
                    </TableCell>
                    <TableCell>
                      {dataRow.c_nombres}
                    </TableCell>
                    <TableCell>
                      {dataRow.c_apellidos}
                    </TableCell>
                    <TableCell>
                      {dataRow.c_cedula_identidad}
                    </TableCell>
                    <TableCell>
                      {dataRow.r_tipo_servicio}
                    </TableCell>
                    <TableCell>
                      {dataRow.r_programa}
                    </TableCell>
                    <TableCell>
                      {dataRow.r_detalle}
                    </TableCell>
                    <TableCell>
                      {dataRow.r_forma_pago}
                    </TableCell>
                    <TableCell>
                      {dataRow.r_valor_cobro}
                    </TableCell>
                  </TableRow>
                )

              ))}
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell><strong>Total: </strong></TableCell>
                <TableCell>
                  ${(+totalFinal).toFixed(2)}
                </TableCell>

              </TableRow>

            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: "flex", justifyContent: "space-around", marginTop: "100px" }}>
          <div style={{ width: "200px", borderTop: "1px solid black", textAlign: "center" }}>
            <strong>Secretaria</strong>

          </div>
          <div style={{ width: "200px", borderTop: "1px solid black", textAlign: "center" }}>
            <strong>Médico</strong>
          </div>

        </div>
      </Box>
    </div>
  )
})
