import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, SvgIcon, TableCell, TableRow, TextField, Tooltip } from "@material-ui/core";
import Link from '@material-ui/core/Link';
import Close from "@material-ui/icons/Close";
import { Autocomplete } from "@material-ui/lab";
import { ReactComponent as UploadDocumentSvg } from 'assets/images/icons/subir_documento.svg';
import { urlBucket } from "constants/urls";

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'underline',
    color: '#3177b9',
    '&:visited': { color: '#3177b9', },
    '&:hover': { color: '#3177b9', },
    '&:focus': { color: '#3177b9', }
  },
}));

export const TableRowExamen = ({ row, handleDelete, handleEdit, grupoData, handleDownloadBase64, handleUpdateItemGroup }) => {
  const classesRow = useRowStyles();
  const classes = useStyles();

  const grupoInitSelected = () => {
    let groupIdFindIndex = null;
    let find = false;
    for (let i = 0; i < grupoData.length; i++) {
      for (let j = 0; j < grupoData[i].examenes.length; j++) {
        if (row.id === grupoData[i].examenes[j].id) {
          groupIdFindIndex = i;
          find = true;
          break;
        }
        if (find) break;
      }
    }

    if (find) return { ...grupoData[groupIdFindIndex], auxId: groupIdFindIndex }
    return null
  }

  const [grupoSelected, setGrupoSelected] = useState(grupoInitSelected());
  const onChangeGrupo = (newValue) => {
    setGrupoSelected(newValue);
    handleUpdateItemGroup(newValue ? newValue.id : null, row);
  }

  useEffect(() => {
    let groupIdFindIndex = null;
    let find = false;
    for (let i = 0; i < grupoData.length; i++) {
      for (let j = 0; j < grupoData[i].examenes.length; j++) {
        if (row.id === grupoData[i].examenes[j].id) {
          groupIdFindIndex = i;
          find = true;
          break;
        }
        if (find) break;
      }
    }

    if (find) {
      setGrupoSelected({ ...grupoData[groupIdFindIndex], auxId: groupIdFindIndex })
      return
    };
    setGrupoSelected(null)
  }, [grupoData, row.id])

  useEffect(() => {
    if (!grupoSelected) return;
    const grupoFind = grupoData.filter(item => item.id === grupoSelected.id);
    if (grupoFind.length === 0) setGrupoSelected(null);
  }, [grupoData, grupoSelected]);

  return (
    <React.Fragment>
      <TableRow key={row.id}>
        <TableCell align="center">
          {row.nombre}
        </TableCell>
        <TableCell align="center">
          {row.detalles}
        </TableCell>
        <TableCell align="center">
          {
            row.archivo_nombre_base64 ?
              <strong style={{ cursor: 'pointer' }} onClick={() => handleDownloadBase64(row.archivo_nombre_base64)}>Resultados</strong>
              :
              (row.archivo_nombre) ?
                <Link
                  style={{ fontWeight: "bold" }}
                  color="primary"
                  variant="body"
                  className={classes.link}
                  href={`${urlBucket}${row.archivo_nombre}`} target="_blank">
                  Resultados
                </Link>
                :
                <strong>Sin archivo</strong>
          }

        </TableCell>
        <TableCell>
          <Autocomplete
            value={grupoSelected}
            onChange={(event, newValue) => {
              onChangeGrupo(newValue);
            }}
            getOptionSelected={(option, value) => option.id === value.id}
            options={grupoData.map((examen, index) => ({ ...examen, auxId: index }))}
            getOptionLabel={(option) => `#${option.auxId + 1}`}
            renderInput={(params) => <TextField {...params} label="lista" margin="normal" />}
          />
        </TableCell>

        {/* Opciones */}
        <TableCell align="center">
          {
            !row.new && !row.archivo_nombre &&
            <Tooltip
              id="tooltip-servicio"
              title={'Subir resultado'}
              placement="top"
              classes={{ tooltip: classesRow.tooltip }}
            >
              <IconButton
                aria-label="Edit"
                className={classesRow.tableActionButton}
                onClick={() => handleEdit(row)}
              >
                <SvgIcon className={classesRow.mySvgStyle}>
                  <UploadDocumentSvg />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          }
          <Tooltip
            id="tooltip-eliminar"
            title="Eliminar"
            placement="top"
            classes={{ tooltip: classesRow.tooltip }}
          >
            <IconButton
              aria-label="Close"
              className={classesRow.tableActionButton}
              onClick={() => handleDelete(row.id)}
            >
              <Close
                className={
                  classesRow.tableActionButtonIcon + " " + classesRow.close
                }
              />
            </IconButton>

          </Tooltip>
        </TableCell>
      </TableRow>

    </React.Fragment>
  );
}
