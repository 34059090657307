import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TableHead, TableSortLabel } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import moment from "moment";
import _ from 'lodash';
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CustomTableCellCrud from "components/Admin/CRUD/CustomTableCellCrud";
import TablePagination from '@material-ui/core/TablePagination';
import AddIcon from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';


import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Alert } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';

import Loading from 'components/Loading';
import { TableRowCrud } from 'components/Admin/CRUD/TableRowCrud';
import { FormCrud } from 'components/Admin/CRUD/FormCrud';
import ConfirmDialog from 'components/Admin/CRUD/ConfirmDialog';

import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { AdminLayout } from './AdminLayout';
import { urlListarReservas, urlCrearReserva, urlEditarReserva, urlEliminarReserva } from 'constants/urls';
import { loadData, createRecord, editRecord, deleteRecord, adminRestartState } from 'redux/actions/aAdmin';
import { SearchTextField } from 'components/forms/input/SearchTextField';
import { TableContainer } from 'components/Table/TableContainer';

let useStyles = makeStyles(styles);

const TableCellHeader = withStyles((theme) => {
  return {
    head: {
      color: theme.palette.common.white,
      fontWeight: "bolder",
    },
  };
})(TableCell);

const rowsPerPageDefault = 20;

const ReservaCrud = () => {
  const classes = useStyles();
  // 0: tabla, 1:crear, 2: editar
  const [view, setView] = useState(0);
  const [itemSelected, setItemSelected] = useState({});

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  //Ordenamiento
  const [orderData, setOrderData] = useState({
    sortBy: 'fecha_reserva', // default sort column
    sortOrder: "desc" // default sort oder
  })

  const { sortBy, sortOrder } = orderData;

  const dispatch = useDispatch();
  const { dataCRUD, dataForeign, dataPagination } = useSelector(state => state.data_admin);

  const { clientes, especializaciones, oficinas } = dataForeign;
  const { total } = dataPagination;

  //Formulario
  const initialState = {
    cliente_id: "",
    especializacion_id: "",
    oficina_id: "",
  };

  const [formValues, setFormValues] = useState(initialState);


  const [formError, setFormError] = useState(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [skipLocal, setSkipLocal] = React.useState(0);

  const [clienteSelected, setClienteSelected] = useState(null);
  const [especializacionSelected, setEspecializacionSelected] = useState(null);
  const [oficinaSelected, setOficinaSelected] = useState(null);

  const handleSelectChange = useCallback(
    (name, newValue) => {
      switch (name) {
        case "cliente_id":
          setClienteSelected(newValue);
          break;
        case "especializacion_id":
          setEspecializacionSelected(newValue);
          break;
        case "oficina_id":
          setOficinaSelected(newValue);
          break;

        default:
          break;
      }
      setFormValues({
        ...formValues,
        [name]: newValue ? newValue.id : "",
      });
    },
    [formValues],
  )

  const handleInputSearchChange = ({ target }) => {
    setSearch(target.value);
    debouncedSearch(target.value);
  }

  const debouncedSearch = React.useRef(
    _.debounce(async (searchValue) => {
      setSearchValue(searchValue);
    }, 350)
  ).current;


  const initialStateFormComponents = useMemo(() => (
    [
      {
        type: "inputDisabled",
        label: "Id",
        value: itemSelected.id,
        showOnCreate: false
      },
      {
        type: "dateTime",
        name: "inicio",
        label: "Inicio",
        value: itemSelected.inicio ? itemSelected.inicio : new Date(),

      },
      {
        type: "dateTime",
        name: "fin",
        label: "Fin",
        value: itemSelected.fin ? itemSelected.fin : new Date(),

      },
      {
        type: "date",
        name: "fechaReserva",
        label: "Fecha Reserva",
        value: itemSelected.fechaReserva ? itemSelected.fechaReserva : new Date(),

      },
      {
        type: "selectCombo",
        name: "disponible",
        label: "Disponible",
        value: itemSelected.disponible === true ? itemSelected.disponible : itemSelected.disponible === false ? false : null,
        defaultValue: false,
        options: [
          {
            value: null,
            label: "Seleccione un valor",
            disabled: true
          },
          {
            value: true,
            label: "Disponible"
          },
          {
            value: false,
            label: "No disponible"
          },
        ]
      },
      {
        type: "input",
        name: "razonNoDisponibilidad",
        label: "Motivo",
        value: itemSelected.razonNoDisponibilidad ? itemSelected.razonNoDisponibilidad : "",
      },
      {
        type: "input",
        name: "estadoReserva",
        label: "Estado Reserva",
        value: itemSelected.estadoReserva ? itemSelected.estadoReserva : "",
      },
      {
        type: "input",
        name: "phoneNumber",
        label: "Phone Number",
        value: itemSelected.phoneNumber ? itemSelected.phoneNumber : "",
        validationOptions: {
          required: {
            value: true,
            message: 'El campo phoneNumber es requerido',
          },
        },
      },
      {
        type: "input",
        name: "cedulaTerceraPersona",
        label: "Cédula tercera persona",
        value: itemSelected.cedulaTerceraPersona ? itemSelected.cedulaTerceraPersona : "",
      },
      {
        type: "input",
        name: "nombreTerceraPersona",
        label: "Nombre tercera persona",
        value: itemSelected.nombreTerceraPersona ? itemSelected.nombreTerceraPersona : "",
      },
      {
        type: "select",
        name: "clienteId",
        label: "Cliente ID",
        value: clienteSelected,
        optionsLabel: (option) => (option ? `${option.id} / ${option.nombres} ${option.apellidos}` : null),
        renderOption: (option) => (
          <span>
            {` ${option.id} / ${option.nombres} ${option.apellidos}`}
          </span>
        ),
        options: clientes,
        handleSelect: (value) => handleSelectChange("cliente_id", value),
      },
      {
        type: "select",
        name: "especializacionId",
        label: "Especialización ID",
        value: especializacionSelected,
        optionsLabel: (option) => (option ? `${option.id} / ${option.nombre}` : null),
        renderOption: (option) => (
          <span>
            {` ${option.id} / ${option.nombre}`}
          </span>
        ),
        options: especializaciones,
        handleSelect: (value) => handleSelectChange("especializacion_id", value),
      },
      {
        type: "select",
        name: "oficinaId",
        label: "Oficina ID",
        value: oficinaSelected,
        optionsLabel: (option) => (option ? `${option.id} / ${option.direccion}` : null),
        renderOption: (option) => (
          <span>
            {` ${option.id} / ${option.direccion}`}
          </span>
        ),
        options: oficinas,
        handleSelect: (value) => handleSelectChange("oficina_id", value),
      },
    ]
  ), [itemSelected, especializacionSelected, oficinaSelected, clienteSelected, oficinas, especializaciones, clientes, handleSelectChange]);

  const handleChangePage = useCallback(
    (event, newPage) => {
      if (page > newPage) {
        setSkipLocal(skipLocal - rowsPerPage);
      } else {
        setSkipLocal(skipLocal + rowsPerPage);
      }
      setPage(newPage);
    },
    [skipLocal, page, rowsPerPage],
  )

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
      setSkipLocal(0);
    },
    [],
  )

  const handleClickCreate = useCallback(
    () => {
      setItemSelected({});
      setFormError(null);
      setEspecializacionSelected(null);
      setOficinaSelected(null);
      setClienteSelected(null);
      setView(1);
    },
    [],
  )

  const handleClickEdit = useCallback(
    (item) => {
      const findEspecializacion = especializaciones.find((esp) => esp.id === item.especializacionId);
      const findOficina = oficinas.find((of) => of.id === item.oficinaId);
      const findCliente = clientes.find((cli) => cli.id === item.clienteId);

      setEspecializacionSelected(findEspecializacion);
      setOficinaSelected(findOficina);
      setClienteSelected(findCliente);

      setItemSelected(item);
      setFormError(null);

      setView(2);
    },
    [especializaciones, oficinas, clientes],
  )

  const handleClickDelete = useCallback(
    item => {
      setItemSelected(item);
      setOpenConfirmDialog(true);
    },
    [],
  )

  const handleClickReturn = useCallback(
    () => {
      setFormError(null);
      setItemSelected({});
      setView(0);
    },
    [],
  )

  const onSubmitForm = useCallback(
    async (data, type) => {
      let dataForm = { ...data };

      dataForm = {
        ...dataForm,
        inicio: new Date(dataForm.inicio),
        fin: new Date(dataForm.fin),
      }

      if (especializacionSelected) {
        dataForm = {
          ...dataForm,
          especializacionId: especializacionSelected.id
        }
      }
      if (oficinaSelected) {
        dataForm = {
          ...dataForm,
          oficinaId: oficinaSelected.id
        }
      }
      if (clienteSelected) {
        dataForm = {
          ...dataForm,
          clienteId: clienteSelected.id
        }
      }

      if (type === "editar") {
        (!loading) && setLoading(true);
        let respuesta = await dispatch(editRecord(urlEditarReserva, itemSelected.id, dataForm));
        setLoading(false);
        const { transaccion, mensaje } = respuesta;
        !transaccion ? setError(mensaje) : setError(false);
      } else if (type === "crear") {
        (!loading) && setLoading(true);
        let respuesta = await dispatch(createRecord(urlCrearReserva, dataForm));
        setLoading(false);
        const { transaccion, mensaje } = respuesta;
        transaccion && setItemSelected({});
        !transaccion ? setError(mensaje) : setError(false);
      }
    },
    [especializacionSelected, oficinaSelected, loading, itemSelected, dispatch, clienteSelected],
  )

  const handleConfirmDelete = useCallback(
    async () => {
      (!loading) && setLoading(true);
      let respuesta = await dispatch(deleteRecord(urlEliminarReserva, itemSelected.id));
      setLoading(false);
      const { transaccion, mensaje } = respuesta;
      !transaccion ? setError(mensaje) : setError(false);
    },
    [loading, itemSelected, dispatch],
  )

  //Ordenamiento
  const handleRequestSort = useCallback(
    async (sortByColumn) => {
      // console.log(sortBy);
      // console.log(sortOrder);
      let sortOrderNew;
      if (!sortBy || sortByColumn !== sortBy) {
        sortOrderNew = "asc";
        setOrderData({
          sortBy: sortByColumn, // default sort column
          sortOrder: sortOrderNew // default sort oder
        })
      } else {
        if (sortByColumn === sortBy) {
          sortOrderNew = sortOrder === "asc" ? "desc" : "asc"
          setOrderData({
            sortBy: sortByColumn, // default sort column
            sortOrder: sortOrderNew// default sort oder
          })
        }
      }

    },
    [sortBy, sortOrder],
  );

  const titulosTable = [
    { title: "Id", onClickSort: () => handleRequestSort("id"), columnName: "id" },
    { title: "Inicio", onClickSort: () => handleRequestSort("inicio"), columnName: "inicio" },
    { title: "Fin", onClickSort: () => handleRequestSort("fin"), columnName: "fin" },
    { title: "FechaReserva", onClickSort: () => handleRequestSort("fecha_reserva"), columnName: "fecha_reserva" },
    { title: "Disponible", onClickSort: () => handleRequestSort("disponible"), columnName: "disponible" },
    { title: "Motivo", onClickSort: () => handleRequestSort("razon_no_disponibilidad"), columnName: "razon_no_disponibilidad" },
    { title: "Canal Reserva", onClickSort: () => handleRequestSort("canal_reserva"), columnName: "canal_reserva" },
    { title: "Estado Reserva", onClickSort: () => handleRequestSort("estado_reserva"), columnName: "estado_reserva" },
    { title: "Transaction Id", onClickSort: () => handleRequestSort("transaction_id"), columnName: "transaction_id" },
    { title: "Phone Number", onClickSort: () => handleRequestSort("phone_number"), columnName: "phone_number" },
    { title: "Especializacion Id", onClickSort: () => handleRequestSort("especializacion_id"), columnName: "especializacion_id" },
    { title: "Cliente Id", onClickSort: () => handleRequestSort("cliente_id"), columnName: "cliente_id" },
    { title: "Oficina Id", onClickSort: () => handleRequestSort("oficina_id"), columnName: "oficina_id" },
    { title: "Cedula Tercera persona", onClickSort: () => handleRequestSort("cedula_tercera_persona"), columnName: "cedula_tercera_persona" },
    { title: "Nombre Tercera persona", onClickSort: () => handleRequestSort("nombre_tercera_persona"), columnName: "nombre_tercera_persona" },
    { title: "Pago info", onClickSort: () => handleRequestSort("pago_info"), columnName: "pago_info" },
    { title: "pago total", onClickSort: () => handleRequestSort("pago_total"), columnName: "pago_total" },
    { title: "numero_reagendamientos", onClickSort: () => handleRequestSort("numero_reagendamientos"), columnName: "numero_reagendamientos" },
    { title: "cedula", onClickSort: () => handleRequestSort("cedula"), columnName: "cedula" },
    { title: "Tipo Reserva", onClickSort: () => handleRequestSort("tipo_reserva"), columnName: "tipo_reserva" },
    { title: "Opciones" },
  ];

  const initLoadData = useCallback(
    async ({ skip = 0, take = rowsPerPageDefault, search = '', sortBy = 'fecha_reserva', sort = 'DESC' } = {}) => {
      setLoading(true);
      let respuesta = await dispatch(loadData({ url: urlListarReservas, skip, take, search, sortBy, sort }));
      setLoading(false);
      const { transaccion, mensaje } = respuesta;

      !transaccion ? setError(mensaje) : setError(false);
    },
    [dispatch],
  )

  useEffect(() => {
    initLoadData({ skip: skipLocal, take: rowsPerPage, search: searchValue, sortBy, sort: sortOrder });
  }, [rowsPerPage, skipLocal, initLoadData, sortOrder, sortBy, searchValue]);

  useEffect(() => {
    return () => {
      dispatch(adminRestartState())
    }
  }, [dispatch])

  return (
    <AdminLayout viewState={view}>
      <div>
        <ConfirmDialog title={"Confirmación de eliminación"} open={openConfirmDialog}
          setOpen={setOpenConfirmDialog} onConfirm={handleConfirmDelete}  >
          <div>¿Esta seguro de eliminar este registro?</div>
        </ConfirmDialog>
        {
          formError &&
          <Alert severity="error">{formError}</Alert>
        }
        {
          error &&
          <Alert severity="error">{error}</Alert>
        }
        {
          view === 0 ?

            <div>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonMargin}
                  onClick={handleClickCreate}
                  startIcon={<AddIcon />}
                >
                  Nuevo
                </Button>
              </div>

              {/* Botón buscar */}
              <SearchTextField
                value={search}
                onChange={handleInputSearchChange}
                style={{ marginTop: '25px', marginBottom: '25px' }}
              />

              <Loading title="Cargando..." loading={loading}>

                <TableContainer component={Paper}>

                  <Table className={classes.table}>

                    <TableHead className={classes["warningTableHeader"]}>

                      <TableRow className={classes.tableHeadRow}>
                        {titulosTable &&
                          titulosTable.map((item, key) => (
                            item.onClickSort ?
                              <TableCellHeader key={key}>
                                <TableSortLabel
                                  active={sortBy === item.columnName}
                                  direction={sortOrder}
                                  onClick={item.onClickSort}
                                  hideSortIcon={sortBy !== item.columnName}
                                  className={classes.tableCell + " " + classes.tableHeadCell}
                                >
                                  {item.title}
                                </TableSortLabel>
                              </TableCellHeader>
                              :
                              <TableCellHeader
                                key={key}
                                className={classes.tableCell + " " + classes.tableHeadCell}
                              >
                                {item.title}
                              </TableCellHeader>
                          ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        dataCRUD &&
                        dataCRUD.map((item) => (
                          <TableRowCrud key={item.id} keyValue={item.id} setView={setView} item={item}
                            handleEdit={handleClickEdit} handleDelete={handleClickDelete}>
                            <CustomTableCellCrud
                              data={item.id}
                            />
                            <CustomTableCellCrud
                              data={moment(item.inicio).format('DD/MMMM/YYYY, h:mm:ss a')}
                            />
                            <CustomTableCellCrud
                              data={moment(item.fin).format('DD/MMMM/YYYY, h:mm:ss a')}
                            />
                            <CustomTableCellCrud
                              data={moment(item.fechaReserva).format('DD/MMMM/YYYY')}
                            />
                            <CustomTableCellCrud
                              data={(item.disponible === true) ? "Disponible" : (item.disponible === false) ? "No disponible" : "Sin definir"}
                            />
                            <CustomTableCellCrud
                              data={item.razonNoDisponibilidad}
                            />
                            <CustomTableCellCrud
                              data={item.canalReserva}
                            />
                            <CustomTableCellCrud
                              data={item.estadoReserva}
                            />
                            <CustomTableCellCrud
                              data={item.transactionId}
                            />
                            <CustomTableCellCrud
                              data={item.phoneNumber}
                            />
                            <CustomTableCellCrud
                              data={item.especializacionId}
                            />
                            <CustomTableCellCrud
                              data={item.clienteId}
                            />
                            <CustomTableCellCrud
                              data={item.oficinaId}
                            />
                            <CustomTableCellCrud
                              data={item.cedulaTerceraPersona}
                            />
                            <CustomTableCellCrud
                              data={item.nombreTerceraPersona}
                            />
                            <CustomTableCellCrud
                              data={!item.pago_info ? '' : item.pago_info === 'MedicalConsultation' ? 'Consulta completa' : 'Solo reserva'}
                            />
                            <CustomTableCellCrud
                              data={item.pago_total}
                            />
                            <CustomTableCellCrud
                              data={item.numero_reagendamientos}
                            />
                            <CustomTableCellCrud
                              data={item.cedula}
                            />
                            <CustomTableCellCrud
                              data={item.tipoReserva}
                            />

                          </TableRowCrud>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Loading>
              <TablePagination
                rowsPerPageOptions={[10, 20, 100]}
                component="div"
                count={total ? +total : 10}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />

            </div>
            :
            <FormCrud setView={setView} edit={view === 2 ? true : false}
              formComponents={initialStateFormComponents}
              onSubmitForm={onSubmitForm} handleReturn={handleClickReturn} isLoading={loading}
              hasError={error}
            />
        }
      </div>
    </AdminLayout>
  )
}

export default ReservaCrud;
