


import React, { useMemo } from 'react';
import CRUD from 'components/CRUD/Crud.component';
import placeSVG from "../../../src/assets/images/icons/place.svg";
import { urlListarSitiosRecomedados } from 'constants/urls';
import { urlCrearSitioRecomedados } from 'constants/urls';
import { urlEditarSitioRecomedados } from 'constants/urls';
import { urlEliminarSitioRecomedados } from 'constants/urls';

const SitiosRecomendadosCrud = () => {

  const fileField = React.useRef(null);

  const tableData = useMemo(() => [
    {
      type: "imageInput",
      name: "foto_nombre",
      label: "Imagen",
      imgDefault: placeSVG,
      ref: fileField
    },
    {
      type: "inputDisabled",
      label: "Id",
      name: 'id',
      showOnCreate: false,
      showOnTable: true,
      tableProps: {
        title: 'Id',
        sort: {
          sortColumnName: 'id',
          sortColumnNameDB: 'id',
        }
      },
    },
    {
      type: "input",
      label: "Categoría",
      name: 'categoria',
      tableProps: {
        title: 'Categoría',
        sort: {
          sortColumnName: 'categoria',
          sortColumnNameDB: 'categoria',
        }
      },
    },
    {
      type: "input",
      name: "nombre",
      label: "Nombre",
      tableProps: {
        title: 'Nombre',
        sort: {
          sortColumnName: 'nombre',
          sortColumnNameDB: 'nombre',
        }
      },
    },
    {
      type: "input",
      name: "link",
      label: "Link",
      tableProps: {
        title: 'Link',
        sort: {
          sortColumnName: 'link',
          sortColumnNameDB: 'link',
        }
      },
    },

  ], []);


  const data = useMemo(() => ({
    tableData,
    urls: {
      createUrl: urlCrearSitioRecomedados,
      updateUrl: urlEditarSitioRecomedados,
      readUrl: urlListarSitiosRecomedados,
      deleteUrl: urlEliminarSitioRecomedados,
    }
  }), [tableData])

  return (
    <CRUD data={data} />
  )
}

export default SitiosRecomendadosCrud;
