import React, { useState, useRef } from 'react';
import { IconButton } from '@material-ui/core';
import Button from "components/CustomButtons/Button.js";
import PropTypes from 'prop-types';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ServiciosTable from './ServiciosTable';
import ServiciosForm from './ServiciosForm';
import { useSelector } from 'react-redux';


export const ServiciosCrud = ({ servicios, onCreate, onDelete, onUpdate, onSaveOficinasAsignadas, doctorId, loading, serviciosLimite }) => {
  const [isLoading, setisLoading] = useState(false)

  const refForm = useRef(null);

  const [showForm, setShowForm] = useState(false);
  const [showOficinas, setShowOficinas] = useState(false);
  const [servicio, setServicio] = useState({});
  const { data: especialidades = [] } = useSelector((state) => state.data_especialidades);

  // useEffect(() => {
  //   dispatch(getOficinasDoctor());
  // }, []);

  const handleShowForm = valor => {
    if (showOficinas) setShowOficinas(false);
    if (!showForm) {
      refForm.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    setShowForm(!showForm);
    if (valor) {
      setServicio({});
    }
  };

  const handleUnaOficina = servicio => {
    if (showOficinas) setShowOficinas(false);
    // console.log({servicioEdit: servicio})
    setServicio(servicio);
    if (!showForm) {
      if (refForm && refForm.current) refForm.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else if (showForm) {
      setShowForm(!showForm);
    }
    setShowForm(!showForm);
  };

  // const handleVerOficinas = servicio => {
  //   if(showForm) setShowForm(false);

  //   console.log({servicioEdit: servicio})
  //   setServicio(servicio);
  //   if (!showOficinas) {
  //     if (refForm && refForm.current) refForm.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //   } else if (showOficinas) {
  //     setShowOficinas(!showOficinas);
  //   }
  //   setShowOficinas(!showOficinas);
  // };



  const handleDeleteServicio = async servicioId => {
    // console.log({servicioDelete: servicioId})
    await onDelete(servicioId);
    setShowForm(false); //Cierra el formulario si tiene abierto
  };

  const handleCierraForm = () => {
    setShowForm(false); //Cierra el formulario si tiene abierto
  };

  const handleGuardarServicio = async (servicio, oficinasAsignadas) => {
    // console.log({servicio})
    setisLoading(true)
    if (servicio.id) {
      //Editar servicio
      await onUpdate(servicio, oficinasAsignadas);
      setShowForm(false);
    } else {
      //Crear servicio
      await onCreate(servicio, oficinasAsignadas);
      setShowForm(false);
    }
    // const res=await dispatch(oficinaSet(param))
    setisLoading(false)
    // console.log(res)
  }

  const handleGuardarOficinasAsignadas = async (servicio, oficinasId) => {
    setisLoading(true);
    await onSaveOficinasAsignadas(servicio, oficinasId);
    // const res=await dispatch(oficinaSet(param))
    setisLoading(false)
    // console.log(res)
  }

  const formatStringOficinaList = (data) => {
    let stringList = '';
    data.map(o => (
      stringList += `${o.nombre ? o.nombre : o.direccion}<br>`
    ))
    return stringList;
  }

  return (
    <>
      <ServiciosTable
        fields={[
          { title: 'Nombre', name: 'nombre' },
          { title: 'Precio', name: 'precio', formatValue: (value) => `$${value}` },
          { title: 'Oficinas', name: 'oficinasAsignadas', formatValue: (value) => (value && value.length > 0) ? formatStringOficinaList(value) : "No se han asignado oficinas", htmlString: true },
          { title: 'Configuración', name: null },
          // 'Descuento Porcentaje',
          // 'Des. Fecha Inicio',
          // 'Des. Fecha Finalización',
          // 'Configuración',
        ]}
        servicios={servicios}
        editService={handleUnaOficina}
        deleteService={handleDeleteServicio}
      />
      <div
        style={{
          display: 'flex',
          flex: 1,
          marginTop: '50px',
          justifyContent: 'center',
        }}
      >


        {
          showForm ?
            <Button color="error" onClick={() => handleShowForm(true)}>
              Cancelar
            </Button>
            :
            <IconButton onClick={() => handleShowForm(true)} disabled={loading || serviciosLimite <= servicios.length}>
              <AddCircleIcon /> Añadir servicio
            </IconButton>
        }
      </div>
      <div ref={refForm}>
        {showForm && (
          <ServiciosForm
            servicio={servicio}
            onCloseForm={handleCierraForm}
            addService={(servicio, oficinasAsignadas) => handleGuardarServicio(servicio, oficinasAsignadas)}
            doctor_id={doctorId}
            isLoading={isLoading}
            especialidades={especialidades}
            handleGuardarOficinasAsignadas={handleGuardarOficinasAsignadas}
          />
        )}
      </div>
    </>
  )
}

ServiciosCrud.propTypes = {
  servicios: PropTypes.array.isRequired,
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onSaveOficinasAsignadas: PropTypes.func.isRequired,
  doctorId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired
}
