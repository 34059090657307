import React, { useState } from "react";

import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "../CustomButtons/Button";

import moment from "moment";
// moment.locale("es");
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
}));

const lunes_viernes = [
  { id: "LUNES" },
  { id: "MARTES" },
  { id: "MIÉRCOLES" },
  { id: "JUEVES" },
  { id: "VIERNES" },
];
const dias_semana = [
  { id: "LUNES A VIERNES" },
  ...lunes_viernes,
  { id: "SÁBADO" },
  { id: "DOMINGO" },
];

const HorarioForm1 = ({
  tiempoConsulta,
  oficina_id,
  handleSetHoras,
  ...props
}) => {
  const [tiempoentre, settiempoentre] = useState(15);
  const [jornadaIni, setjornadaIni] = useState("09:00");
  const [jornadaFin, setjornadaFin] = useState("17:00");
  const [dia_semana, setdia] = useState("");
  const [hasError, sethasError] = useState("");

  // const [arreglo_hora, setArregloHora] = useState([]);

  const classes = useStyles();

  React.useEffect(() => {}, []);
  const handleError = (val) => {
    sethasError(val);
    setTimeout(() => {
      sethasError("");
    }, 3000);
  };
  const handleClick = async () => {
    if (!dia_semana || !tiempoentre || !jornadaIni || !jornadaFin) {
      handleError("Ingrese todos los datos");
      return;
    }

    let miarray = [];

    // let i = 0;
    let dias = [{ id: dia_semana }];
    if (dia_semana === `LUNES A VIERNES`) dias = lunes_viernes;
    // tiempoConsulta

    dias.forEach(({ id }) => {
      let mi = moment().utcOffset(0);
      let mf = moment().utcOffset(0);
      const ini = jornadaIni.split(":");
      const fin = jornadaFin.split(":");

      mi.set({ hour: ini[0], minute: ini[1] });
      mf.set({ hour: fin[0], minute: fin[1] });

      if (mi >= mf) {
        handleError("La hora de finalización debe ser mayor");
      }

      while (mi < mf) {
        mi.toISOString();
        let horario = {
          dia_semana: id,
          hora_inicio: mi.format("HH:mm"),
        };

        mi.add(tiempoConsulta, "m");
        mi.toISOString();

        horario = { ...horario, hora_fin: mi.format("HH:mm") };
        miarray.push(horario);

        mi.add(tiempoentre, "m");
      }

    });
    handleSetHoras(miarray);
  };
  const onDiaChange = (event, values) => {
    setdia(values !== null ? values.id : "");
  };

  return (
    <form
      style={{
        margin: "15px auto",
        // width: "600px",
      }}
      className={classes.root}
      noValidate="noValidate"
      autoComplete="off"
    >
      {hasError && (
        <Alert severity={`Error`}>
          <AlertTitle>{"Error"}</AlertTitle>
          {hasError}
        </Alert>
      )}
      <Autocomplete
        id="dia"
        name="dia"
        options={dias_semana}
        //Si es que va a editar entonces deshabilitar: si viene el objeto a editar deshabilitar si no es uno nuevo
        getOptionLabel={(option) => option.id}
        onChange={onDiaChange}
        defaultValue={""}
        renderInput={(params) => (
          <TextField {...params} label="Seleccione el día" />
        )}
      />

      <GridContainer
      // style={{
      //   display: "flex",
      //   flex: 1,
      //   justifyContent: "center",
      // }}
      >
        <GridItem xs={12} style={{ margin: 20 }}>
          Ingrese el horario de la jornada laboral
        </GridItem>

        <GridItem xs={6} md={2}>
          <TextField
            label="Inicio"
            id="hora_inicioini"
            name="hora_inicioini"
            type="time"
            defaultValue={jornadaIni}
            fullWidth
            placeholder="hh:mm"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setjornadaIni(e.target.value)}
            value={jornadaIni}
          />
        </GridItem>
        <GridItem xs={6} md={2}>
          <TextField
            id="hora_finfin"
            name="hora_finfin"
            label="Fin"
            type="time"
            fullWidth
            placeholder="hh:mm"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setjornadaFin(e.target.value)}
            value={jornadaFin}
          />
        </GridItem>
        <GridItem xs={6} md={4}>
          <TextField
            label="Tiempo entre consultas(min)"
            id="tiempoentre"
            name="tiempoentre"
            type="number"
            fullWidth
            placeholder="mm"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => settiempoentre(e.target.value)}
            value={tiempoentre}
          />
        </GridItem>
        <GridItem xs={6} md={2} style={{ alignSelf: "center", margin: "auto" }}>
          <Button
            size="large"
            variant="outlined"
            color="primary"
            onClick={() => handleClick()}
          >
            Crear
          </Button>
        </GridItem>
      </GridContainer>
    </form>
  );
};

export default HorarioForm1;
