import React from 'react'
import '../../styles/payment/confirmPayment.scss'

export const PrivacyTerms = () => {
  return (
    <>
      <p>
        Por medio del presente documento se le informa sobre la manera en que MEDAPPS a
        través de su aplicativo MEDPAL y los demás sistemas de los cuales MEDAPSS es
        propietario, puede recopilar, almacenar y utilizar la información personal que se genera por
        el uso de nuestros servicios.
      </p>

      <h2>Compromiso</h2>

      <p>
        MEDAPPS consciente de la importancia de sus datos personales, financieros y los demás
        obtenidos por la utilización de nuestros servicios, se compromete con el manejo correcto de
        los mismos conforme a la ley vigente, de igual manera ponemos a disposición de nuestros
        usuarios y prestadores de servicios afiliados a la plataforma, la atención en caso de
        requerirla a través del correo electrónico support@medpal.com.ec, así como el sitio web
        www.medpal.com.ec.
      </p>

      <p>
        De igual manera, se indica tanto a usuarios como a los diferentes prestadores de servicios
        afiliados a la plataforma, tanto a MEDPAL como a los distintos sistemas de los cuales
        MEDAPSS es propietario, que la información contenida en el presente documento puede
        ser modificada total o parcialmente a criterio de MEDAPPS, por lo que es obligación de los
        distintos usuarios el realizar una constante revisión de estas políticas de privacidad, ya que
        la utilización de nuestras plataformas y servicios confiere una aceptación tácita de
        autorización sobre el manejo de información proporcionada y obtenida
      </p>

      <h2>Información que se obtiene</h2>

      <p>
        Al utilizar nuestros servicios y plataformas tanto en el aplicativo móvil como en la
        plataforma web, usted proporcionará datos personales necesarios para afiliación y uso de
        los distintos servicios que proporciona MEDPAL – MEDAPPS y sus distintos sistemas,
        estos datos de carácter personal pueden ser, pero no se limitan a nombres completos,
        número de identificación (cédula de identidad), género al que pertenece, estado civil,
        dirección, ciudad de residencia, país de nacimiento, datos bancarios y de tarjetas de crédito,
        dirección de correo electrónico, etc.
      </p>

      <p>
        Así mismo se autoriza la obtención de los datos que se puedan extraer de los diferentes
        dispositivos que se utilicen para la utilización de nuestros servicios que pueden ser, pero no
        se limitan a: ubicación del dispositivo, cámara del dispositivo, lectura del estado del
        dispositivo, acceso a directorios de contactos del dispositivo, lectura del disco de
        almacenamiento interno del dispositivo, sistemas biométricos de acceso con los que cuente
        el dispositivo, datos de la red mediante la cual se ha conectado el dispositivo, dirección de
        IP del dispositivo, etc..
      </p>

      <p>
        En el caso donde el usuario no se registra en la plataforma, no se recolectan datos
        personales. Como usuario invitado se puede utilizar la página del mapa de un consultorio
        que utiliza la ubicación del usuario para trazar la ruta entre su ubicación y la dirección del
        consultorio.
      </p>

      <p>
        Para el caso en el que el usuario se registre en la plataforma, se guarda los siguientes datos
        resumidos en la siguiente tabla.
      </p>

      <table>
        <thead>
          <tr>
            <th>Tipo</th>
            <th>Descripción</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Datos personales y contacto</td>
            <td>Correo electrónico, número de cédula, teléfono, dirección, teléfono, dirección.</td>
          </tr>
          <tr>
            <td>Información de cuenta</td>
            <td>
              Nombres completo, nombres de padres, número de cédula dirección, número de
              teléfono, número de celular, fecha y lugar de nacimiento, información de seguros,
              instrucción, ocupación, estado civil, tipo de sangre.
            </td>
          </tr>
          <tr>
            <td>Información de dispositivo y datos identificativos</td>
            <td>
              Para el caso de la app se utilizan Identificadores del dispositivo para el envío de notificaciones push.
            </td>
          </tr>
          <tr>
            <td>Ubicación</td>
            <td>
              <p>
                Para el uso de la web. Para el caso de los profesionales, se solicita y almacena
                información relacionada a la ubicación de sus consultorios. Para el caso de los
                pacientes se solicita y se almacena información de su dirección, esta información se
                utiliza para ayudar al usuario a ubicarse con respecto a los consultorios de los
                médicos.
              </p>
              <p>
                Para el uso de la App. Si el usuario autoriza el uso de los datos de su ubicación. La
                aplicación en primera plano utiliza esta información para ayudar al usuario a ubicarse
                con respecto a los consultorios de los profesionales.
              </p>
              <p>
                No se utiliza datos de ubicación en tiempo real
              </p>
            </td>
          </tr>
          <tr>
            <td>Autenticación y acceso</td>
            <td>
              Correo electrónico, nombre de usuario, número de cédula y contraseña
            </td>
          </tr>
          <tr>
            <td>Servicios</td>
            <td>
              <p>
                Cliente: Información médica del cliente para el uso de las funcionalidades de reservas
                online e historia clínica.
              </p>
              <p>
                Profesional: Información de identificación del profesional. Datos para reserva de
                consulta (fecha, precio, tipo de pago). Datos de historia clínica de un paciente
                utilizados por los profesionales de la plataforma
              </p>
            </td>
          </tr>
          <tr>
            <td>Información de pago </td>
            <td>
              Se solicita al usuario número de teléfono o ruc (registrados en una cuenta de
              PayPhone) para la integración de las funcionalidades de pago de PayPhone. La
              información y datos relacionados con pagos con tarjeta son gestionados por la
              pasarela de PayPhone. La plataforma almacena información de los resultados de las
              transacciones realizadas con PayPhone. No se almacenan datos relacionados con
              tarjetas de crédito.
            </td>
          </tr>

        </tbody>
      </table>



      <h2>Protección de datos personales</h2>

      <p>
        En principio la seguridad de sus datos personales es nuestra prioridad, es por ello que
        mantenemos un compromiso de salvaguardar los mismos con los más altos estándares de
        protección y calidad que tenemos a nuestro alcance, evitando que estos sean filtrados o
        puedan ser comprometidos por agentes externos a nuestra organización, exceptuando
        cuando por ley nos veamos obligados a compartir dichos datos, de así requerirlo alguna
        autoridad y/o entidad estatal que esté debidamente facultada para solicitar dichos datos.
      </p>

      <p>
        De igual manera, se les recuerda a los diferentes usuarios de nuestros plataformas y
        servicios que es de su estricta responsabilidad el manejo correcto de sus datos, que además
        se incluyen las credenciales para acceder a nuestros sistemas, eximiendo, por lo tanto, de
        responsabilidad a MEDAPPS - MEDPAL y sus diferentes sistemas y servicios de
        responsabilidad ante cualquier perjuicio que se pudiera generar por estas situaciones.
      </p>

      <h2>Uso de datos recopilados</h2>

      <p>
        La base de datos recopilada y suministrada por los distintos usuarios de MEDAPPS -
        MEDPAL y sus diferentes sistemas y servicios servirá, pero no se limitará a realizar
        evaluaciones periódicas sobre el uso y utilización de nuestros servicios por parte de los
        distintos usuarios, a fin de mantener siempre una mejora continua y optimización de dichos
        servicios, pudiendo de ese modo a criterio de MEDAPPS excluir a usuarios que mantengan
        o usen nuestros sistemas de manera incorrecta y/o perjudicial para MEDAPPS o el resto de
        usuarios; de igual modo se podrá hacer uso de la información suministrada y obtenida con
        fines publicitarios y de marketing, además de las que estratégicamente le resulte más
        conveniente a MEDAPPS.
      </p>

      <p>La información recopilada de menores de edad será de uso estrictamente médico, por lo que
        se hará una depuración cada 3 años sobre ese tipo de datos de usuarios que no estén
        haciendo uso de los servicios para eliminar estos datos como medida adicional de
        seguridad.
      </p>

      <p>
        En la siguiente tabla se resumen el uso de los datos por parte de MEDAPPS con los datos
        recopilados de los usuarios de la plataforma.
      </p>

      <table>
        <tbody>
          <tr>
            <th>Prestación de servicios</th>
            <td>
              Los datos personales se utilizan para personalizar y mantener la prestación de servicios
              dentro de la plataforma. Los datos son utilizados para las siguientes funcionalidades
              dentro de la plataforma.

              <ul>
                <li>Autenticación y seguridad de la cuenta de usuario y verificación de identidad.</li>
                <li>Mantenimiento de la cuenta del perfil de usuario.</li>
                <li>Habilitación para el uso de servicios.</li>
                <li>Envío de notificaciones personalizadas vía notificaciones push y correo electrónico.</li>
                <li>Valoración de profesionales (médicos recomendados).</li>
                <li>Selección de instituciones favoritas.</li>
                <li>Procesamiento de pagos para obtener servicios (créditos y reservas).</li>
              </ul>
            </td>
          </tr>
          <tr>
            <th>Ayuda y soporte</th>
            <td>Se utilizan datos personales de la cuenta para el uso de la funcionalidad de ayuda y soporte</td>
          </tr>
          <tr>
            <th>Seguridad</th>
            <td>
              Datos personales como nombre de usuario, correo electrónico y contraseña se utilizan
              para asegurar la integridad de los usuarios y los servicios.
            </td>
          </tr>
          <tr>
            <th>Investigación y desarrollo</th>
            <td>
              La información ingresada puede ser utilizada para generar estadísticas e información que
              permita ayudar a la mejora e implementación de funcionalidades dentro de la plataforma.
            </td>
          </tr>
          <tr>
            <th>Publicidad</th>
            <td>
              La plataforma puede enviar avisos de promociones y descuentos vía notificaciones push y
              correo electrónico.
            </td>
          </tr>
          <tr>
            <th>Pagos y transacciones</th>
            <td>
              Si un profesional necesita recibir pagos por servicios prestados dentro de la plataforma, el
              usuario deberá tener registrada una cuenta de PayPhone. Para integrar esta cuenta con
              nuestra plataforma, es necesario utilizar los datos del número de RUC o celular del
              usuario que estén asociados a su cuenta de PayPhone.
            </td>
          </tr>
        </tbody>
      </table>

      <h2>Eliminación de datos</h2>

      <p>
        En caso de que los distintos usuarios deseen darse de baja en los sistemas de MEDAPSS,
        en primera instancia bastará con cerrar la sesión de su cuenta, pero si de manera adicional
        desean que los datos obtenidos sean eliminados, deberán enviar un correo a
        support@medpal.com.ec con la debida solicitud, misma que será revisada y confirmada por
        parte del equipo de MEDAPPS para proceder con dicha solicitud, cuando los datos sean
        eliminados a petición del usuario recibirá un correo electrónico indicando que se ha
        procedido de ese modo.
      </p>

      <h2>Consentimiento</h2>

      <p>
        Mediante el uso de los distintos sistemas proporcionados por MEDAPSS - MEDPAL Usted
        acepta nuestro procesamiento de datos en los términos contenidos dentro de esta política de
        privacidad, y de igual modo se encuentra completamente de acuerdo y acepta el modo de
        uso, gestión, almacenamiento y trato que se le den a los datos proporcionados y obtenidos,
        autorizando a que MEDAPPS/MEDPAL pueda realizar cualquier gestión y uso que crea
        conveniente.
      </p>

      <h2>Contacto</h2>

      <p>
        En caso de cualquier duda, inquietud o requerimiento, puede contactarse con nuestro
        equipo a través del correo electrónico support@medpal.com.ec.
      </p>

    </>
  )
}
