import moment from "moment";

export const calculateAgeString = (bornDate, currentDate = new Date()) => {
  const edad = moment(new Date(currentDate)).diff(moment.utc(bornDate), 'years');
  let yearsText = `años`;
  if (edad === 1) yearsText = `año`;

  let edadString = `${edad} ${yearsText}`;

  //Verificar edad menor a 5 años
  if (edad < 18) {
    const meses = moment(currentDate).diff(moment.utc(bornDate), 'months');
    let monthText = `meses`;
    let daysText = `días`;
    //Months elapsed in decimals
    const months = moment(currentDate).diff(moment.utc(bornDate), 'months', true);
    const days = Math.floor((months % 1) * moment(currentDate).daysInMonth())
    if (days === 1) daysText = `día`;
    if (meses === 1) monthText = 'mes';
    edadString = `${edadString} ${meses % 12} ${monthText} ${days} ${daysText}`;
  }
  return edadString;
}

export const splitLastname = (lastname = '') => {
  const split = lastname.split(' ');
  if (split.length > 1) {
    return {
      fatherLastname: split[0],
      motherLastname: split[1],
    }
  } else {
    return {
      fatherLastname: split[0],
      motherLastname: '',
    }
  }
}

// Formatear datos de filiación de pacientes, historia clínica
export const formatPatientFiliationData = (data) => ({
  id: data.id,
  apellidos: data.apellidos,
  cedula_identidad: data.cedula_identidad,
  cedula_representante: data.cedula_representante,
  celular: data.celular,
  ciudad: data.ciudad,
  correo: data.correo,
  direccion: data.direccion,
  estado_civil: data.estado_civil,
  fecha_nacimiento: data.fecha_nacimiento,
  instruccion: data.instruccion,
  nacionalidad: data.nacionalidad,
  nombres: data.nombres,
  nombres_madre: data.nombres_madre,
  nombres_padre: data.nombres_padre,
  ocupacion: data.ocupacion,
  pais: data.pais,
  seguros: data.seguros,
  sexo: data.sexo,
  telefono: data.telefono,
  tipo_sangre: data.tipo_sangre,
  titulo_honorifico: data.titulo_honorifico,
})

export const formatPatientFiliationData2 = (data) => ({
  id: data.c_id,
  apellidos: data.c_apellidos,
  cedula_identidad: data.c_cedula_identidad,
  cedula_representante: data.c_cedula_representante,
  celular: data.c_celular,
  ciudad: data.c_ciudad,
  correo: data.c_correo,
  direccion: data.c_direccion,
  estado_civil: data.c_estado_civil,
  fecha_nacimiento: data.c_fecha_nacimiento,
  instruccion: data.c_instruccion,
  nacionalidad: data.c_nacionalidad,
  nombres: data.c_nombres,
  nombres_madre: data.c_nombres_madre,
  nombres_padre: data.c_nombres_padre,
  ocupacion: data.c_ocupacion,
  pais: data.c_pais,
  seguros: [],
  sexo: data.c_sexo,
  telefono: data.c_telefono,
  tipo_sangre: data.c_tipo_sangre,
  titulo_honorifico: data.c_titulo_honorifico,
})