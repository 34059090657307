import React from 'react'
import { Link } from "react-router-dom"
import Login from "../../Views/Login"

export const Navbar = () => {

  const toggleNavbar = () => {
    var navbarLinks = document.getElementById("navbarLinks");
    navbarLinks.classList.toggle("show");
  }

  return (
    <div className='landing-page-navbar'>
      <nav className="desktop">
        <div className="home">
          <Link id="medpal_icon" to="/"></Link>
        </div>
        <div className="nav">
          <Link to="/nosotros" >
            Nosotros
          </Link>

          <Link to="/contacto">
            Contacto
          </Link>

          <Login />
        </div>

      </nav>

      <nav className="mobile">

        <Link className="navbar-brand" id="medpal_icon" to="/"></Link>
        <div className="navbar-icon" onClick={toggleNavbar}>
          &#9776;
        </div>
        <div className="navbar-links" id="navbarLinks">
          {/* <a href="./nosotros.html">Nosotros</a>
          <a href="./contacto.html">Contacto</a>
          <a href="#ingresar">Ingresar</a> */}
          <Link to="/nosotros" >
            Nosotros
          </Link>

          <Link to="/contacto">
            Contacto
          </Link>

          <Login />
        </div>
      </nav>
    </div>
  )
}
