import React, { useRef, useState, useEffect } from 'react';
import { FormControl, FormControlLabel, FormHelperText, IconButton, makeStyles, Switch, TextField, Tooltip } from '@material-ui/core'
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { CertificadoPrint } from './CertificadoPrint';
import { useReactToPrint } from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import Notificacion from 'components/ui/Notificacion';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { descargarCertificadoFirmado, enviarEmailCertificado } from 'redux/actions/aMedico';
import Loading from 'components/Loading';
import PrintIcon from '@material-ui/icons/Print';
import EmailIcon from '@material-ui/icons/Email';
import { MailPrintIcon } from 'assets/images/js/icons/MailPrintIcon';
import formStyles from "assets/jss/material-dashboard-react/forms/formStyle"
import { TextFieldCustom } from 'components/forms/input/TextFieldCustom';

const useStyles = makeStyles((theme) => (
  {
    formControl: {
      width: "100%",
      marginBottom: "25px",
    },
    ...styles
  }
));

const useFormStyles = makeStyles(formStyles);

export const validaciones = (values) => {
  let errors = {};
  if (!values.text) {
    errors.text = 'El texto es obligatorio';
  }
  return errors;
}

export const CertificadoMedico = ({ setCertificadoFormDirty = null }) => {

  const fileField = React.useRef(null);
  const formClasses = useFormStyles();

  const { data: dataUser } = useSelector(
    (state) => state.dataUser
  );

  const { data: dataCliente } = useSelector(
    (state) => state.data_clientes
  );

  const { control, errors, getValues, reset, trigger, formState: { isDirty } } = useForm();

  const classes = useStyles();
  const componentRef = React.useRef();

  const [showHeader, setShowHeader] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('Enviando...')

  const [includesSign, setIncludesSign] = useState(false);

  const dispatch = useDispatch();
  // const [first, setFirst] = useState(false);

  const [certData, setCertData] = useState({
    patient: { ...dataCliente },
    doctor: { ...dataUser.medico },
    date: new Date(),
    text: 'texto personalizado'
  });

  const onPrint = async (data) => {
    try {
      if (includesSign) {
        if (!file) {
          Notificacion({
            type: "error",
            text: "Debe seleccionar el archivo.",
          });
          return;
        }

        const values = {
          ...data,
          ...formValues,
          file,
        }
        setLoadingText('Descargando...');
        setLoading(true);

        const { transaccion } = await dispatch(descargarCertificadoFirmado(dataCliente.id, values, showHeader));
        setLoading(false);
        if (transaccion) {
          reset();
        } else {
          reset({
            'text': data.text,
          })
        }
      } else {
        // return

        setCertData({ ...certData, ...formValues, ...data })
        setFormSubmit(true);
      }

    } catch (error) {
      setLoading(false);
    }

  }

  const onSendEmail = async (data, download = false) => {

    if (includesSign) {
      if (!file) {
        Notificacion({
          type: "error",
          text: "Debe seleccionar el archivo.",
        });
        return;
      }

      const values = {
        ...data,
        ...formValues,
        file,
      }
      setLoadingText('Enviando...');

      setLoading(true)
      const { transaccion } = await dispatch(enviarEmailCertificado(dataCliente.id, values, { signed: true, showHeader, download }));
      setLoading(false);
      if (transaccion) {
        reset();
      } else {
        reset({
          'text': data.text,
        })
      }
    } else {
      const values = { ...formValues, ...data };

      setLoading(true)
      const { transaccion } = await dispatch(enviarEmailCertificado(dataCliente.id, values, { showHeader, download }));
      setLoading(false);
      if (transaccion) {
        reset();
      } else {
        reset({
          'text': data.text,
        })
      }
    }

  }

  const handleReactPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (formSubmit) {
      handleReactPrint();
      setFormSubmit(false);
    }
  }, [certData, handleReactPrint, formSubmit])


  const valoresInit = useRef({
    date: new Date(),
  });

  const [formValues, setFormValues] = useState(valoresInit.current);

  const handleDateChange = (e, name) => {
    setFormValues({ ...formValues, [name]: e.toDate() });
  }

  function handleFileSelected(e) {
    const file = e.target.files[0];
    setFile(file);
  }

  // Verificar valores de form consultas
  const getFormValues = async () => {
    const formValues = getValues({ nest: true });

    //trigger validation on fields 
    const value = await trigger();
    if (value) {
      return formValues;
    } else {
      Notificacion({
        type: "error",
        text: "Debe completar los campos correctamente",
      });
      return null;
    }
  }

  const handleClickPrint = async () => {
    const formValues = await getFormValues();
    if (!formValues) return;
    onPrint(formValues);
  }

  const handleClickSendEmail = async (download = false) => {
    const formValues = await getFormValues();
    if (!formValues) return;
    onSendEmail(formValues, download)
  }

  useEffect(() => {
    if (setCertificadoFormDirty) setCertificadoFormDirty(isDirty)
  }, [isDirty, setCertificadoFormDirty]);

  return (

    <div style={{ width: '100%' }}>
      <Loading loading={loading} title={loadingText}>
        <form >

          <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
              <FormControl error className={classes.formControl}>
                <Controller
                  name={`text`}
                  control={control}
                  defaultValue={''}
                  as={<TextFieldCustom
                    multiline
                    minRows={4}
                  />}
                  error={errors[`text`] ? true : false}
                  helperText={""}
                  // id="standard-full-width"

                  label="Se ha determinando clínicamente que:"
                  style={{ margin: 8 }}
                  fullWidth
                  margin="normal"
                  rules={{
                    required: {
                      value: true,
                      message: "El campo es requerido",
                    },
                  }}
                />
                <ErrorMessage
                  errors={errors}
                  name='text'
                  render={({ message }) => (
                    <FormHelperText
                      className={formClasses.errorValidationLabel}
                    >
                      {message}
                    </FormHelperText>
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  label="Fecha"
                  inputVariant="standard"
                  format="DD/MM/YYYY"
                  value={formValues.date}
                  onChange={(e) => handleDateChange(e, 'date')}
                  maxDate={new Date()}
                />
              </MuiPickersUtilsProvider>
            </GridItem>

            {/* Sign fields */}
            {
              includesSign && (
                <>
                  <GridItem xs={12} sm={12} md={8}>
                    <FormControl error className={classes.formControl}>
                      <Controller
                        name={`password`}
                        control={control}
                        defaultValue={''}
                        as={<TextField
                          type='password'
                        />}
                        error={errors[`password`] ? true : false}
                        helperText={""}
                        // id="standard-full-width"

                        label="Clave de firma electrónica"
                        style={{ margin: 8 }}
                        fullWidth
                        margin="normal"
                        rules={{
                          required: {
                            value: includesSign,
                            message: "El campo es requerido",
                          },
                        }}
                      />
                      <ErrorMessage
                        errors={errors}
                        name='password'
                        render={({ message }) => (
                          <FormHelperText
                            className={formClasses.errorValidationLabel}
                          >
                            {message}
                          </FormHelperText>
                        )}
                      />
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <input
                      type="file"
                      name="archivoFirma"
                      id="archivoFirma"
                      accept=".p12"
                      onChange={handleFileSelected}
                      ref={fileField}
                    />
                  </GridItem>
                </>
              )
            }
          </GridContainer>

          {/* Div imprimir */}
          <div
            style={{ display: "none" }}
          >
            <CertificadoPrint ref={componentRef} header={showHeader}
              data={certData}
              pdf
            />
          </div>
          {/* Fin div imprimir */}

          {/* Opciones */}
          <div>

            <div style={{ textAlign: "center" }}>
              <FormControlLabel control={<Switch color="primary" checked={includesSign} onChange={(e) => setIncludesSign(e.target.checked)} />} label="Incluir Firma electrónica" />
            </div>
            <div style={{ textAlign: "center" }}>
              <FormControlLabel control={<Switch color="primary" checked={showHeader} onChange={(e) => setShowHeader(e.target.checked)} />} label="Imprimir con membrete" />
            </div>

            <GridContainer justifyContent="center" direction="row">

              <GridItem xs={"auto"}>


                <Tooltip
                  title="Imprimir"
                  placement="top"
                >
                  <IconButton
                    className="no-printme"
                    variant="outlined"
                    color="primary"
                    onClick={handleClickPrint}
                  >
                    <PrintIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip
                  title="Enviar email"
                  placement="top"
                >
                  <IconButton
                    className="no-printme"
                    variant="outlined"
                    color="primary"
                    onClick={() => handleClickSendEmail()}
                  >
                    <EmailIcon />

                  </IconButton>
                </Tooltip>

                <Tooltip
                  title="Enviar email e imprimir"
                  placement="top"
                >
                  <IconButton
                    className="no-printme"
                    variant="outlined"
                    color="primary"
                    onClick={() => handleClickSendEmail(true)}
                  >
                    <MailPrintIcon />
                  </IconButton>
                </Tooltip>

              </GridItem>
            </GridContainer>
          </div>

        </form>

      </Loading>
    </div>



  )
}
