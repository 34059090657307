import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Alert, AlertTitle } from '@material-ui/lab';

import CustomTabsControlled from 'components/CustomTabs/CustomTabsControlled.js';

import Horario from 'components/horario/horario.component';
import { useDispatch, useSelector } from 'react-redux';
import { getHorariosOficina } from 'redux/actions/aOficinas';
import Loading from 'components/Loading';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import dashboardStyles from "assets/jss/material-dashboard-react/views/dashboardStyle"

const useStyles = makeStyles(theme => ({
  ...dashboardStyles,
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const HorarioOficina = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const doctor_id = useSelector(state => state.dataUser.data.doctor_id);
  const oficinas = useSelector(state => state.data_oficinas.data);
  const [selectOficina, setSelectOficina] = useState(0);
  const { data, isLoading, hasError } = useSelector(state => state.horariosOficinas);

  return (
    <Loading title="Cargando..." loading={isLoading}>
      <div className={classes.root}>
        {hasError && (
          <Alert severity={`Error`}>
            <AlertTitle>{'Error'}</AlertTitle>
            {hasError}
          </Alert>
        )}

        {data && (
          <CustomTabsControlled
            value={selectOficina}
            setValue={setSelectOficina}
            title="Oficinas"
            headerColor="primary"
            tabsPosition="body"
            tabs={oficinas.map((oficina, i) => ({
              tabName: oficina.nombre,
              tabIcon: LocationOnIcon,
              tabContent: (
                <div style={{ marginTop: '40px' }}>
                  <Card style={{ boxShadow: 'none' }}>
                    <CardHeader color="primary">
                      <div className={classes.cardTitle}>Horarios</div>
                    </CardHeader>
                    <CardBody>
                      <Horario
                        doctor_id={doctor_id}
                        key={`oficina${i}`}
                        oficina_id={oficina.id}
                        tiempoConsulta={oficina.tiempo_por_cliente_minutos}
                        horarios={data.filter(horario => horario.oficina_id === oficina.id)}
                        getHorariosOficina={() => dispatch(getHorariosOficina())}
                      />
                    </CardBody>

                  </Card>
                </div>


              ),
            }))}
          />
        )}
      </div>
    </Loading>
  );
};

export default HorarioOficina;
