/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Person from '@material-ui/icons/Person';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LocationOnIcon from '@material-ui/icons/LocationOn';
// core components/views for medico layout

import DashboardPage from 'Views/Dashboard/DashboardLaboratorista';

import HorarioCitas from 'Views/HorarioCitasLaboratorista';
import Oficinas from 'components/oficina/laboratorista/oficina.component';

import CerrarSesion from 'Views/CerrarSesion';
import PerfilLaboratorista from 'Views/PerfilLaboratorista';
import ReservarLaboratorista from 'Views/ReservarLaboratorista';
import RolesChange from "Views/Roles/RolesChange";
import AyudaSoporte from "Views/AyudaSoporte";
import { RolesIcon } from 'components/CustomIcons/Sidebar/RolesIcons';
import { CitasAgendadasIcon } from "components/CustomIcons/Sidebar/CitasAgendadasIcon";
import { AgendarCitasIcon } from "components/CustomIcons/Sidebar/AgendarCitasIcon";
import { HorarioCitasIcon } from "components/CustomIcons/Sidebar/HorarioCitasIcon";
import { AyudaSoporteIcon } from "components/CustomIcons/Sidebar/AyudaSoporteIcon";

const dashboardRoutes = [
  {
    path: '/dashboard',
    name: 'Citas Agendadas',
    icon: CitasAgendadasIcon,
    component: DashboardPage,
    layout: '/laboratorista',
  },
  {
    path: '/reservaciones',
    name: 'Agendar Citas',
    icon: AgendarCitasIcon,
    component: ReservarLaboratorista,
    layout: '/laboratorista',
  },
  {
    path: '/horario-citas',
    name: 'Horarios',
    icon: HorarioCitasIcon,
    component: HorarioCitas,
    layout: '/laboratorista',
  },
  {
    path: '/oficinas',
    name: 'Oficinas',
    icon: LocationOnIcon,
    component: Oficinas,
    layout: '/laboratorista',
  },
  {
    path: '/user',
    name: 'Mi Perfil',
    icon: Person,
    component: PerfilLaboratorista,
    layout: '/laboratorista',
  },
  {
    path: "/roles",
    name: "Roles",
    icon: RolesIcon,
    component: RolesChange,
    layout: '/laboratorista',
  },
  {
    path: "/ayuda",
    name: "Ayuda y Soporte",
    icon: AyudaSoporteIcon,
    component: AyudaSoporte,
    layout: '/laboratorista',
  },
  {
    path: '/cerrar-sesion',
    name: 'Cerrar Sesión',
    icon: ExitToAppIcon,
    component: CerrarSesion,
    layout: '/laboratorista',
  },
];

export default dashboardRoutes;
