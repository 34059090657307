
import React, { useEffect, useState } from 'react';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import { useDispatch } from 'react-redux';
import Notificacion from 'components/ui/Notificacion';
import { firmarEnviaRecetaMailCliente, descargarRecetaFirmada } from 'redux/actions/aRecetas';
import { FormControlLabel, IconButton, Switch, TextField, Tooltip } from '@material-ui/core';
import Loading from 'components/Loading';
import { RecetaPrint } from 'components/HistoriaClinica/receta/RecetaPrint';
import { useReactToPrint } from 'react-to-print';
import { enviarRecetaMailCliente } from 'redux/actions/aRecetas';
import PrintIcon from '@material-ui/icons/Print';
import EmailIcon from '@material-ui/icons/Email';
import { MailPrintIcon } from 'assets/images/js/icons/MailPrintIcon';

export const FormRecetarioImprimirFirma = ({ data, showPreview = false }) => {

  const classes = useStyles();

  const fileField = React.useRef(null);

  const [password, setPassword] = useState('');
  const [file, setFile] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);

  const [includesSign, setIncludesSign] = useState(false);
  const [showHeader, setShowHeader] = useState(false);

  const componentRef = React.useRef();

  const dispatch = useDispatch();

  function handleFileSelected(e) {
    const file = e.target.files[0];
    setFile(file);
  }

  const handleReactPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrint = async () => {
    try {
      if (includesSign) {
        setIsSubmit(true);
        if (!validateForm()) return;

        const values = {
          file,
          password: password.trim()
        }
        setLoadingText('Descargando...');
        setLoading(true);
        const { transaccion } = await dispatch(descargarRecetaFirmada(data.id, values, { showHeader }));
        setLoading(false);
        if (transaccion) {
          setIsSubmit(false);
          setPassword('');
          setFile(null);
        }
      } else {
        handleReactPrint()
      }

    } catch (error) {
      setLoading(false);
    }

  }

  const handleSendEmail = async (download = false) => {
    try {
      if (includesSign) {
        setIsSubmit(true);
        if (!validateForm()) return;

        const values = {
          file,
          password: password.trim()
        }
        setLoadingText('Enviando email...');
        setLoading(true);
        const { transaccion } = await dispatch(firmarEnviaRecetaMailCliente(data.id, values, { download, showHeader: true }));
        setLoading(false);
        if (transaccion) {
          setIsSubmit(false);
          setPassword('');
          setFile(null);
        }

      } else {
        setLoading(true)
        setLoadingText('Enviando email...');
        await dispatch(enviarRecetaMailCliente(data.id, download, true));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }

  }

  const validateForm = () => {
    if (Object.keys(formErrors).length > 0) {
      Notificacion({
        type: "error",
        text: "Debe completar los campos correctamente.",
      });
      return false;
    }

    if (!file) {
      Notificacion({
        type: "error",
        text: "Debe seleccionar el archivo.",
      });
      return false;
    }
    return true;
  }

  useEffect(() => {
    const errors = {};
    if (password === '') {
      errors.password = 'Campo obligatorio';
    }
    setFormErrors(errors)
  }, [password])

  return (
    <div style={{ paddingTop: '20px' }}>
      <div style={{ position: 'relative' }}>
        {
          showPreview &&
          <div >
            <RecetaPrint data={data} header={showHeader} preview />
          </div>
        }

        <div
          style={{ display: "none" }}
        >
          <div ref={componentRef} >
            <RecetaPrint data={data} header={showHeader} />
          </div>
        </div>
      </div>

      {/* <div style={{ display: "none" }}>
        <RecetaPrint ref={componentRef} data={data} header={showHeader} pdf />
      </div> */}
      {/* Fin div imprimir receta */}
      <div style={{ marginTop: '10px' }}>
        <div>
          {/* Descripción */}
          <div className={classes.form}>
            <Loading title={loadingText} loading={loading}>
              <>
                {
                  includesSign &&
                  <div>
                    <TextField
                      id="password"
                      name="password"
                      label="Clave de la firma electrónica"
                      type='password'
                      style={{
                        width: '96%',
                      }}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      error={formErrors.password && isSubmit ? true : false}
                      helperText={formErrors.password && isSubmit ? formErrors.password : ''}
                    />
                    <div style={{ margin: '10px 0px' }}>
                      <input
                        type="file"
                        name="archivoFirma"
                        id="archivoFirma"
                        accept=".p12"
                        onChange={handleFileSelected}
                        ref={fileField}
                      />
                    </div>
                  </div>
                }

                {/* Options */}

                <div style={{ textAlign: "center" }}>
                  <FormControlLabel control={<Switch color="primary" checked={includesSign} onChange={(e) => setIncludesSign(e.target.checked)} />} label="Incluir Firma electrónica" />
                </div>
                <div>
                  <div style={{ textAlign: "center" }}>
                    <FormControlLabel control={<Switch color="primary" checked={showHeader} onChange={(e) => setShowHeader(e.target.checked)} />} label="Imprimir con membrete" />
                  </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Tooltip
                    title="Imprimir"
                    placement="top"
                  >
                    <IconButton
                      className="no-printme"
                      variant="outlined"
                      color="primary"
                      onClick={handlePrint}
                    >
                      <PrintIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    title="Enviar email"
                    placement="top"
                  >
                    <IconButton
                      className="no-printme"
                      variant="outlined"
                      color="primary"
                      onClick={() => handleSendEmail()}
                    >
                      <EmailIcon />

                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    title="Enviar email e imprimir"
                    placement="top"
                  >
                    <IconButton
                      color="primary"
                      className="no-printme"
                      variant="outlined"
                      onClick={() => handleSendEmail(true)}
                    >
                      <MailPrintIcon />
                    </IconButton>
                  </Tooltip>

                </div>
              </>

            </Loading>
          </div>

        </div>
      </div>
    </div>

  )
}
