import React, { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { TextFieldCustom as TextField } from 'components/forms/input/TextFieldCustom';
import formStyles from "assets/jss/material-dashboard-react/forms/formStyle"
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Editor } from '@tinymce/tinymce-react';

import {
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@material-ui/core";

import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/Check";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { ErrorMessage } from "@hookform/error-message";
import Loading from "components/Loading";
import "moment/locale/es";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useForm, Controller } from "react-hook-form";
import CardHeader from "components/Card/CardHeader";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button";
import { urlBucket } from "constants/urls";
import WarningLeaveFormPage from "components/ui/WarningLeaveFormPage";
import ConfirmDialog from "./ConfirmDialog";
import { AutocompleteComponent } from "components/ReactHookForm/AutocompleteComponent";
import { useDispatch } from "react-redux";
import { uploadImage } from "redux/actions/aAdmin";
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import { tinyMCEApiKey } from "constants/constants";

const useStyles = makeStyles((theme) => ({
  ...styles,
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    width: "100%",
    marginBottom: "25px",
  },
  formControl2: {
    width: "100%",
    marginLeft: "10px",
    marginBottom: "25px",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
    margin: 7,
  },
  ctnbtn: {
    display: "flex",
    justifyContent: "center",
  },
}));

const useFormStyles = makeStyles(formStyles);

const filter = createFilterOptions();

export const FormCrud = ({
  edit,
  formComponents,
  onSubmitForm,
  handleReturn,
  isLoading,
  hasError,
}) => {
  const classes = useStyles();
  const formClasses = useFormStyles();

  const waiting = useRef(false);
  const [success, setSuccess] = useState(false);
  const [openModalOnReturn, setOpenModalOnReturn] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoading) {
      if (success) {
        setSuccess(false);
      }
      waiting.current = true;
    }
  }, [isLoading, success]);

  useEffect(() => {
    if (!isLoading && waiting.current && !hasError) {
      setSuccess(true);
      waiting.current = false;
    }
  }, [hasError, isLoading]);

  const { register, control, handleSubmit, setValue, errors, reset, formState: { isDirty }, } =
    useForm();

  const handleClickReturn = () => {
    if (isDirty) {
      setOpenModalOnReturn(true);
      return;
    }
    handleReturn();
  };

  const confirmReturn = () => handleReturn();

  const onSubmit = async (data, type) => {
    try {
      await onSubmitForm(data, type);
      reset(data);
    } catch (error) {
      console.log({ error })
    }
  };

  const checkKeyDown = (e) => {
    if (e.code === "Enter") e.preventDefault();
  };

  const handleFileSelected = (formValues, setFormValues, name, event) => {
    const file = event.target.files[0];
    // divido para dos para 5MB max
    if (file.size / 1024 / 1024 < 5) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setFormValues({ ...formValues, [name]: reader.result });
        setValue(name, reader.result, { shouldDirty: true });
      };
    }
  };

  const renderSwitch = (formDataComponent, index) => {
    if (formDataComponent.hideOnForm) return;
    switch (formDataComponent.type) {
      case "inputDisabled":
        if (!formDataComponent.showOnCreate && edit) {
          return (
            <GridItem item xs={12} md={6} key={index}>
              <FormControl error className={classes.formControl} >
                <TextField
                  className={classes.textField}
                  disabled
                  label={formDataComponent.label}
                  defaultValue={formDataComponent.value}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </GridItem>
          );
        } else {
          return null;
        }
      case "input":
        return (
          <GridItem item xs={12} md={6} key={index}>
            <FormControl error className={classes.formControl}>
              <Controller
                name={`${formDataComponent.name}`}
                control={control}
                defaultValue={formDataComponent.value ?? ''}
                disabled={formDataComponent.disabled}
                as={
                  <TextField
                    multiline={formDataComponent.multiline ? true : false}
                    minRows={formDataComponent.minRows ?? undefined}
                  />
                }
                error={errors[`${formDataComponent.name}`] ? true : false}
                helperText={""}
                label={`${formDataComponent.label}`}
                style={{ margin: 8 }}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                rules={formDataComponent.validationOptions}
              />
              <ErrorMessage
                errors={errors}
                name={`${formDataComponent.name}`}
                render={({ message }) => (
                  <FormHelperText
                    className={formClasses.errorValidationLabel}
                  // id="component-error-text"
                  >
                    {message}
                  </FormHelperText>
                )}
              />
            </FormControl>
          </GridItem>
        );

      case "select":
        return (
          <GridItem item xs={12} md={6} key={index}>
            <FormControl error className={classes.formControl}>
              <Autocomplete
                key={index}
                value={formDataComponent.value}
                // defaultValue={formDataComponent.value}
                // inputValue={formDataComponent.inputValue }
                onChange={(event, newValue) => {
                  if (formDataComponent.setValueToOnChange) {
                    formDataComponent.setValueToOnChange.map((name) =>
                      setValue(
                        name,
                        formDataComponent.valueToSendOnChange
                          ? newValue[formDataComponent.valueToSendOnChange]
                          : "",
                        {
                          shouldValidate: true,
                          shouldDirty: true,
                        }
                      )
                    );
                  }
                  formDataComponent.handleSelect(newValue);
                }}
                getOptionSelected={
                  formDataComponent.getOptionSelected
                    ? formDataComponent.getOptionSelected
                    : (option, value) => option.id === value.id
                }
                options={formDataComponent.options}
                getOptionLabel={formDataComponent.optionsLabel}
                style={{ width: 300 }}
                disabled={formDataComponent.disabled ? formDataComponent.disabled : false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${formDataComponent.label}`}
                    name={`${formDataComponent.name}`}
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </GridItem>
        );
      case "selectMultiple":
        return (
          <GridItem item xs={12} md={6} key={index}>
            <FormControl error className={classes.formControl} >
              <Autocomplete
                key={index}
                multiple={true}
                defaultValue={formDataComponent.value}
                // inputValue={formDataComponent.inputValue }
                onChange={(event, newValue) => {
                  formDataComponent.handleSelect(newValue);
                }}
                options={formDataComponent.options}
                getOptionLabel={formDataComponent.optionsLabel}
                // style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${formDataComponent.label}`}
                    name={`${formDataComponent.name}`}
                  // variant="outlined"
                  />
                )}
              />
            </FormControl>
          </GridItem>
        );
      case "autocompleteMultiple":
        return (
          <GridItem item xs={12} md={6} key={index}>
            <FormControl error className={classes.formControl}>
              <Autocomplete
                key={index}
                multiple={true}
                value={formDataComponent.value}
                // inputValue={formDataComponent.inputValue }
                onChange={(_, newValue) => {
                  formDataComponent.handleSelect(newValue);
                }}
                style={{ paddingTop: "9px" }}
                options={formDataComponent.options}
                getOptionLabel={formDataComponent.optionsLabel}
                getOptionSelected={formDataComponent.getOptionSelected}
                // style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${formDataComponent.label}`}
                    name={`${formDataComponent.name}`}
                  // variant="outlined"
                  />
                )}
              />
            </FormControl>
          </GridItem>
        );

      // Autocomplete, async with custom options select
      case "autocompleteMultipleV2":
        return (
          <GridItem item xs={12} md={6} key={index}>
            <FormControl error className={classes.formControl} style={{ paddingLeft: '5px' }}>
              <Controller
                defaultValue={formDataComponent.defaultValue}
                disabled={formDataComponent.disabled}
                name={formDataComponent.name}
                reduxFunction={null}
                control={control}
                rules={formDataComponent.validationOptions}
                onChange={([, data]) => {
                  return data;
                }}
                render={({ onChange }) => formDataComponent.freeSolo ? (
                  <AutocompleteComponent
                    multiple={formDataComponent.multiple}
                    defaultValue={formDataComponent.defaultValueFormat ? formDataComponent.defaultValueFormat(formDataComponent.defaultValue) : formDataComponent.defaultValue}
                    reduxFunction={false}
                    label={formDataComponent.label}
                    name={formDataComponent.name}
                    type="text"
                    freeSolo={formDataComponent.freeSolo ?? false}
                    fullWidth
                    // getOptionLabel={formDataComponent.optionsLabel}
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {
                        onChange(newValue);
                      }
                      if (newValue && newValue.inputValue) {
                        onChange({
                          tipo: newValue.inputValue,
                          custom: true,
                        });
                      } else if (newValue) {
                        onChange(newValue);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      // Suggest the creation of a new value
                      if (params.inputValue !== '') {
                        filtered.push({
                          inputValue: params.inputValue,
                          informacion: `personalizado: ${params.inputValue}`,
                        });
                      }

                      return filtered;
                    }}
                    optionLabel={option => {
                      if (option.inputValue) {
                        return option.inputValue;
                      }

                      if (typeof option === 'string') {
                        return (option);
                      }

                      return (option.nombre);
                    }
                    }
                    dispatchFunction={formDataComponent.dispatchFunction}
                    dataSearch={formDataComponent.options}
                  />
                ) : (
                  <AutocompleteComponent
                    disabled={formDataComponent.disabled}
                    multiple={formDataComponent.multiple}
                    defaultValue={formDataComponent.defaultValueFormat ? formDataComponent.defaultValueFormat(formDataComponent.defaultValue) : formDataComponent.defaultValue}
                    reduxFunction={false}
                    label={formDataComponent.label}
                    name={formDataComponent.name}
                    type="text"
                    freeSolo={true}
                    fullWidth
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {
                        onChange(newValue);
                      }
                      if (newValue && newValue.inputValue) {
                        onChange({
                          tipo: newValue.inputValue,
                          custom: true,
                        });
                      }

                      else if (newValue) {
                        onChange(newValue);
                      }
                    }}
                    optionLabel={formDataComponent.optionLabel ?? (option => {
                      if (option.inputValue) {
                        return option.inputValue;
                      }

                      if (typeof option === 'string') {
                        return (option);
                      }

                      return (option.nombre);
                    })
                    }
                    dispatchFunction={formDataComponent.dispatchFunction}
                    dataSearch={formDataComponent.options}
                  />
                )

                }
              />
            </FormControl>

          </GridItem>

        );
      case "selectAsync":
        return (
          <GridItem item xs={12} md={6} key={index}>
            <FormControl error className={classes.formControl}>
              <Autocomplete
                key={index}
                value={formDataComponent.value}
                // inputValue={formDataComponent.inputValue }
                onChange={(event, newValue) => {
                  formDataComponent.handleSelect(newValue);
                }}
                getOptionSelected={(option, value) => option.id === value.id}
                options={formDataComponent.options}
                getOptionLabel={formDataComponent.optionsLabel}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={formDataComponent.onChangeText}
                    label={`${formDataComponent.label}`}
                    name={`${formDataComponent.name}`}
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </GridItem>
        );
      case "date":
        return (
          <GridItem item xs={12} md={6} key={index}>
            <FormControl error className={classes.formControl}>
              <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
                <Controller
                  name={formDataComponent.name}
                  control={control}
                  defaultValue={formDataComponent.utc ? moment.utc(formDataComponent.value) : formDataComponent.value}
                  render={({ onChange, value = formDataComponent.value }) => (
                    <KeyboardDatePicker
                      disabled={formDataComponent.disabled}
                      style={{ margin: "8px" }}
                      disableToolbar
                      variant="inline"
                      format="DD/MM/yyyy"
                      margin="normal"
                      label={`${formDataComponent.label}`}
                      value={value}
                      onChange={onChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      invalidDateMessage="Formato de fecha no válido"
                    />
                  )}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </GridItem>
        );
      case "dateTime":
        return (
          //Maneja datetime en formato texto, se debe capturar en submit y transformar en tipo date para manejar horas correctas
          <GridItem item xs={12} md={6} key={index}>
            <FormControl error className={classes.formControl}>
              <Controller
                className={classes.textField}
                name={formDataComponent.name}
                control={control}
                defaultValue={moment(formDataComponent.value).format(
                  "YYYY-MM-DDTHH:mm:ss"
                )} //"2017-05-24T10:30"
                label={`${formDataComponent.label}`}
                type="datetime-local"
                InputLabelProps={{
                  shrink: true,
                }}
                as={<TextField />}
              // render={({ onChange, value = formDataComponent.value  }) => (
              //   <TextField
              //     // onChange={onChange}

              //     // defaultValue={formDataComponent.value}
              //     type="datetime-local"
              //     className={classes.textField}
              //     InputLabelProps={{
              //       shrink: true,
              //     }}
              //   />
              // )}
              />
            </FormControl>
          </GridItem>
        );
      case "selectCombo":
        return (
          <GridItem item xs={12} md={6} key={index}>
            <FormControl className={classes.formControl2} >
              <InputLabel shrink htmlFor={formDataComponent.name}>
                {formDataComponent.label}
              </InputLabel>
              <Controller
                name={formDataComponent.name}
                control={control}
                className={classes.formControl}
                defaultValue={formDataComponent.value ?? null}
                disabled={formDataComponent.disabled}
                as={
                  <Select>
                    {formDataComponent.options.map((opt, index) => (
                      <MenuItem value={opt.value} disabled={opt.disabled} key={index}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </Select>
                }
              />
            </FormControl>
          </GridItem>
        );
      case "radio":
        return (
          <GridItem item xs={12} md={6} key={index}>
            <FormControl className={classes.formControl2}>
              <InputLabel shrink component="legend" htmlFor={formDataComponent.name} >
                {formDataComponent.label}
              </InputLabel>
              <Controller
                as={
                  <RadioGroup aria-label="gender">
                    {formDataComponent.options.map((opt, index) => (
                      <FormControlLabel
                        key={index}
                        value={opt.value}
                        control={<Radio />}
                        label={opt.label}
                        checked={opt.checked}
                        disabled={formDataComponent.disabled}
                      />
                    ))}
                  </RadioGroup>
                }
                name={formDataComponent.name}
                row
                defaultValue={formDataComponent.defaultValue}
                control={control}
                style={{ marginTop: '10px' }}

              />
            </FormControl>
          </GridItem>
        );
      case "checkbox":
        return (
          <GridItem item xs={12} md={6} key={index}>
            <FormControlLabel
              style={{ margin: "8px", color: "rgba(0, 0, 0, 0.54)" }}
              control={
                <Controller
                  name={formDataComponent.name}
                  control={control}
                  defaultValue={formDataComponent.checked}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      defaultChecked={
                        formDataComponent.value === true ? formDataComponent.value : formDataComponent.value === false ? false : false
                      }
                      onChange={(e) =>
                        setValue(formDataComponent.name, e.target.checked)
                      }
                    />
                  )}
                />
              }
              label={formDataComponent.label}
              labelPlacement={formDataComponent.labelPlacement}
            />
          </GridItem>
        );
      case "imageInput":
        return (
          <GridItem item xs={12} md={6} key={index}>
            <FormControl className={classes.formControl2}>
              <InputLabel shrink htmlFor={formDataComponent.name}>
                {formDataComponent.label}
              </InputLabel>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {/* Img input value */}
                <input type="hidden" name={formDataComponent.name} ref={register} />
                {formDataComponent.value && formDataComponent.value !== "n/a" && (
                  <>
                    <img
                      width="150px"
                      src={
                        formDataComponent?.formValues[formDataComponent.name]
                          ? formDataComponent?.formValues[formDataComponent.name]
                          : `${urlBucket}${formDataComponent.value}`
                      }
                      alt='imgForm'
                    />
                    <input
                      type="file"
                      name="imagen"
                      accept=".jpeg, .png, .jpg"
                      onChange={(e) =>
                        handleFileSelected(
                          formDataComponent.formValues,
                          formDataComponent.setFormValues,
                          formDataComponent.name,
                          e
                        )
                      }
                      ref={formDataComponent.ref}
                    />
                  </>
                )}
                {!formDataComponent.value && (
                  <>
                    {" "}
                    <img
                      width="150px"
                      src={
                        formDataComponent?.formValues[formDataComponent.name]
                          ? formDataComponent?.formValues[formDataComponent.name]
                          : formDataComponent.imgDefault
                      }
                      alt='imgForm'
                    />{" "}
                    <input
                      type="file"
                      name="imagen"
                      accept=".jpeg, .png, .jpg"
                      onChange={(e) =>
                        handleFileSelected(
                          formDataComponent.formValues,
                          formDataComponent.setFormValues,
                          formDataComponent.name,
                          e
                        )
                      }
                      ref={formDataComponent.ref}
                    />
                  </>
                )}
              </div>
            </FormControl>
          </GridItem>
        );

      case "editorTinyMCE":
        return (
          <GridItem item xs={12} md={12} key={index}>
            <FormControl error={errors[formDataComponent.name] ? true : false} className={classes.formControl}>
              <InputLabel shrink htmlFor={formDataComponent.name}>
                {formDataComponent.label}
              </InputLabel>
              <Controller
                defaultValue={formDataComponent.defaultValue ?? ''}
                name={formDataComponent.name}
                reduxFunction={null}
                control={control}
                rules={formDataComponent.validationOptions}
                onChange={([, data]) => {
                  return data;
                }}
                render={({ onChange }) => (
                  <div style={{ marginTop: '20px' }}>
                    <ErrorMessage
                      errors={errors}
                      name={`${formDataComponent.name}`}
                      render={({ message }) => (
                        <FormHelperText
                          className={formClasses.errorValidationLabel}
                        >
                          {message}
                        </FormHelperText>
                      )}
                    />
                    <Editor
                      onEditorChange={onChange}
                      initialValue={formDataComponent.value ?? ''}
                      apiKey={tinyMCEApiKey}
                      init={{
                        language: 'es',
                        height: formDataComponent.tinyMCEOptions?.height ?? 500,
                        menubar: formDataComponent.tinyMCEOptions?.menubar ?? true,
                        // id: formDataComponent.name,
                        plugins: [
                          'image'
                        ],
                        image_title: true,
                        images_upload_handler: images_upload_handler,
                        file_picker_types: 'image',
                        images_reuse_filename: true,
                        toolbar: 'undo redo | formatselect | ' +
                          'bold italic backcolor | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | ' +
                          'removeformat',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                      }}
                    />

                  </div>
                )

                }
              />
            </FormControl>

          </GridItem>

        );
      default:
        break;
    }
  };

  const images_upload_handler = useCallback(
    (blobInfo) => {
      return new Promise(async (resolve, reject) => {
        try {
          var image_size = blobInfo.blob().size / 1000;  // image size in kbytes
          var max_size = 1024 * 1024                // max size in kbytes
          if (image_size > max_size) {
            reject('Image is too large( ' + image_size + ') ,Maximum image size is:' + max_size + ' kB');
            return;
          }
          const imgUrl = await (dispatch(uploadImage(blobInfo)));
          resolve(`${urlBucket}${imgUrl}`)

        } catch (error) {
          reject(error);
        }

      });
    },
    [dispatch],
  )

  const formRenderElements = formComponents.map((fc, index) => <React.Fragment key={index}>{renderSwitch(fc, index)}</React.Fragment>);

  return (
    // <Loading title="Guardando..." loading={isLoading}>
    <Card className={classes.root}>
      {/* Modal local on return page */}

      <ConfirmDialog
        title={"Los datos aún no han sido guardados."}
        open={openModalOnReturn}
        setOpen={setOpenModalOnReturn}
        onConfirm={confirmReturn}
      >
        <div> ¿Está seguro que desea salir sin guardar los cambios?</div>
      </ConfirmDialog>
      {/* Warning on exit page */}
      {/* Form stateL: {formDirty ? 'form dirty' : 'form no dirty'} */}
      <WarningLeaveFormPage when={isDirty} />
      <CardHeader color="primary" size="sm" stats>
        {edit ? <h3 className={classes.cardTitle}>Editar</h3> : <h3 className={classes.cardTitle}>Crear</h3>}
      </CardHeader>
      <CardBody>
        {/* <Grid container spacing={5}> */}

        <form
          onSubmit={handleSubmit((data) =>
            onSubmit(data, `${edit ? "editar" : "crear"}`)
          )}
          onKeyDown={(e) => checkKeyDown(e)}
        >
          <GridContainer style={{ display: isLoading ? 'none' : '' }}>
            {formRenderElements}
          </GridContainer>
          <GridItem item xs={12} className={classes.ctnbtn}>
            <Loading title="Guardando..." loading={isLoading}>
              <Button
                color="warning"
                variant="contained"
                className={classes.button}
                type="submit"
              >
                {isLoading && <CircularProgress />}
                {!isLoading && success && <CheckIcon />}
                GUARDAR
              </Button>

            </Loading>
          </GridItem>
        </form>
      </CardBody>
      <CardFooter>
        <GridContainer>
          <GridItem item xs={12}>
            {
              handleReturn &&
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleClickReturn}
              >
                Regresar
              </Button>
            }
          </GridItem>
        </GridContainer>
      </CardFooter>
    </Card>

    // </Loading>
  );
};
