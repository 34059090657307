/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Person from "@material-ui/icons/Person";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";

// core components/views for medico layout

import DashboardSecretaria from 'Views/Dashboard/DashboardSecretaria';
import CerrarSesion from 'Views/CerrarSesion';
import RolesChange from "Views/Roles/RolesChange";

import PerfilSecretaria from "Views/PerfilSecretaria";
import AyudaSoporte from "Views/AyudaSoporte";
import { RolesIcon } from "components/CustomIcons/Sidebar/RolesIcons";
import { SecretariaIcon } from "components/CustomIcons/Sidebar/SecretariaIcon";
import { AgendarCitasIcon } from "components/CustomIcons/Sidebar/AgendarCitasIcon";
import { HorarioCitasIcon } from "components/CustomIcons/Sidebar/HorarioCitasIcon";
import { AyudaSoporteIcon } from "components/CustomIcons/Sidebar/AyudaSoporteIcon";
import { CajaIcon } from "components/CustomIcons/Sidebar/CajaIcon";
import { PacientesIcon } from "components/CustomIcons/Sidebar/PacientesIcon";
import { CajasSecretaria } from "Views/Secretaria/CajasSecretaria";
import { ReservarSecretaria } from "Views/Secretaria/ReservarSecretaria";
import { HorarioCitasSecretaria } from "Views/Secretaria/HorarioCitasSecretaria";
import { PacientesDoctorSecretaria } from "Views/Secretaria/PacientesDoctorSecretaria";

const dashboardRoutes = [
  {
    path: '/agenda',
    name: 'Agenda general',
    icon: SecretariaIcon,
    component: DashboardSecretaria,
    layout: "/secretaria",
  },
  {
    path: "/reservaciones",
    name: "Crear cita",
    icon: AgendarCitasIcon,
    component: ReservarSecretaria,
    layout: "/secretaria",
  },
  {
    path: "/pacientes-atendidos",
    name: "Pacientes",
    icon: PacientesIcon,
    component: PacientesDoctorSecretaria,
    layout: "/secretaria",
  },
  {
    path: "/cajas",
    name: "Caja",
    icon: CajaIcon,
    component: CajasSecretaria,
    layout: "/secretaria",
  },
  {
    path: "/horario-citas",
    name: "Horarios",
    icon: HorarioCitasIcon,
    component: HorarioCitasSecretaria,
    layout: "/secretaria",
  },
  {
    path: "/user",
    name: "Mi Perfil",
    icon: Person,
    component: PerfilSecretaria,
    layout: "/secretaria",
  },
  {
    path: "/roles",
    name: "Roles",
    icon: RolesIcon,
    component: RolesChange,
    layout: '/secretaria',
  },
  {
    path: "/ayuda",
    name: "Ayuda y Soporte",
    icon: AyudaSoporteIcon,
    component: AyudaSoporte,
    layout: "/secretaria",
  },
  {
    path: "/cerrar-sesion",
    name: "Cerrar Sesión",
    icon: ExitToAppIcon,
    component: CerrarSesion,
    layout: "/secretaria",
  },
];

export default dashboardRoutes;
