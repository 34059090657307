
import React, { useMemo } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from "moment";
import { CalendarEvent } from '../CalendarEvent';
import { makeStyles } from '@material-ui/core';

import 'moment/locale/es';
import { computeTextColor } from 'utils/styles/color';
import { primaryColor } from 'assets/jss/material-dashboard-react';
moment.locale('es');

const localizer = momentLocalizer(moment);

const eventStyleGetter = (event) => {
  const { s_background_color } = event;

  const fontColor = s_background_color ?  computeTextColor(s_background_color, true) : '#ffffff';
  // console.log({fontColor})

  const style = {
    backgroundColor: s_background_color ?? primaryColor[0],
    borderRadius: '0px',
    opacity: 0.8,
    display: 'block',
    color: fontColor,
    // mixBlendMode: 'difference'
  }

  return {
    style
  }
};

const styles = {
  container: {
    height: '100vh'
  },
};

const useStyles = makeStyles(styles);


export const messages = {
  allDay: 'Todo el día',
  previous: '<',
  next: '>',
  today: 'Hoy',
  month: 'Mes',
  week: 'Semana',
  day: 'Día',
  agenda: 'Agenda',
  date: 'Fecha',
  time: 'Hora',
  event: 'Evento',
  noEventsInRange: 'No hay reservas en este rango',
  showMore: total => `+ Ver más (${total})`
};



const CalendarComponent = ({ onDoubleClickEvent, onSelectEvent, events, startAccessor, endAccessor, onRangeChange, onNavigate, onView, view, date, defaultDate, onSelectSlot }) => {
  
  
  const classes = useStyles();
  
  const eventsFormatted = useMemo(() =>
  events.map(e => ({
    ...e,
    [startAccessor]: moment(e[startAccessor]).toDate(),
    [endAccessor]: moment(e[endAccessor]).toDate(),
  }))
  , [events, startAccessor, endAccessor]);

  //Config
  
  const formats = useMemo(() => ({
    eventTimeRangeFormat: () => { 
      return " ";
    },
  }), []);

  return (
    <div className={classes.container}>
      <Calendar
        formats={formats}
        localizer={localizer}
        events={eventsFormatted}
        startAccessor={startAccessor}
        endAccessor={endAccessor}
        messages={messages}
        eventPropGetter={eventStyleGetter}
        onDoubleClickEvent={onDoubleClickEvent}
        onSelectEvent={onSelectEvent}
        onView={onView}
        onSelectSlot={onSelectSlot}
        onNavigate = {onNavigate}
        onRangeChange={onRangeChange}
        selectable={true}
        view={view}
        views={['month', 'week']}
        date={date}
        defaultDate={defaultDate}
        components={{
          event: CalendarEvent,
          week: {
            event: CalendarEvent
            // header: ({ date, localizer }) => localizer.format(date, '')
          }
        }}
      />
    </div>
  )
}

export default CalendarComponent;
