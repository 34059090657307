import React, { useContext, useState } from 'react'
import { makeStyles } from '@material-ui/core';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { ConsultaContext } from '../ConsultaStepper';
import Button from "components/CustomButtons/Button.js";
import Notificacion from 'components/ui/Notificacion';
import Loading from 'components/Loading';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '140px',
    padding: '0 60px',
  },
  formControlSelect: {
    margin: theme.spacing(1),
    minWidth: 340,
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  }
}));


export const OficinaSelector = () => {

  const classes = useStyles();

  const {
    oficinasOptions,
    serviciosOptions,
    oficinaSelected,
    servicioSelected,
    setOficinaSelected,
    setServicioSelected,
    handleNext,
    loading,
  } = useContext(ConsultaContext);

  const [oficinaError, setOficinaError] = useState(null);

  const onClickNext = () => {

    if (!oficinaSelected) {
      Notificacion({
        type: "error",
        text: "Debe seleccionar una oficina.",
      });
      setOficinaError('Debe seleccionar una oficina');
      return;
    }

    handleNext();
  }

  return (
    <Loading loading={loading} title="Cargando...">
      <div className={classes.container}>

        <GridContainer >
          <GridItem xs={12} sm={12} md={6}>
            <FormControl className={classes.formControlSelect}>
              <InputLabel id="demo-simple-select-label">Seleccione una oficina</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={oficinaSelected ?? ''}
                onChange={(e) => { setOficinaSelected(e.target.value) }}
                error={oficinaError}
              >
                {oficinasOptions &&
                  oficinasOptions.map((oficina) => (
                    <MenuItem key={oficina.id} value={oficina.id}>{`${oficina.nombre}`}</MenuItem>
                  ))}
              </Select>
            </FormControl>

          </GridItem>
          <GridItem xs={12} sm={12} md={6}>

            <FormControl className={classes.formControlSelect}>
              <InputLabel id="demo-simple-select-label">Seleccione un servicio</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={servicioSelected}
                onChange={(e) => { setServicioSelected(e.target.value) }}
                disabled={serviciosOptions.length === 0}
              >
                <MenuItem key={0} value={0}>{`Consulta`}</MenuItem>
                {serviciosOptions &&
                  serviciosOptions.map((servicio) => (
                    <MenuItem key={servicio.id} value={servicio.id}>{`${servicio.nombre}`}</MenuItem>
                  ))}
              </Select>
            </FormControl>

          </GridItem>

        </GridContainer>


        <div className={classes.buttonContainer}>
          <Button style={{ margin: 'auto' }} variant="contained" color="primary" onClick={onClickNext} disabled={!oficinaSelected} >
            Siguiente
          </Button>
        </div>


      </div>
    </Loading>

  )
}
