


import React, { useMemo } from 'react';
import { capitalize } from 'lodash';
import CRUD from 'components/CRUD/Crud.component';
import { urlListarCiudades,  urlCrearCiudad, urlEditarCiudad, urlEliminarCiudad } from 'constants/urls';

const CiudadesCrud = () => {

  const tableData = useMemo(() => [
    {
      type: "inputDisabled",
      label: "Id",
      name: 'id',
      showOnCreate: false,
      showOnTable: true,
      tableProps: {
        title: 'Id',
        sort: {
          sortColumnName: 'id',
          sortColumnNameDB: 'id',
        }
      },
    },
    {
      type: "input",
      label: "Nombre",
      name: 'nombre',
      tableProps: {
        formatData: (value) => value ? capitalize(value) : "",
        sort: {
          sortColumnName: 'nombre',
          sortColumnNameDB: 'nombre',
        }
      },
    },
    {
      type: "input",
      label: "País",
      name: 'pais',
      tableProps: {
        formatData: (value) => value ? capitalize(value) : "",
        sort: {
          sortColumnName: 'pais',
          sortColumnNameDB: 'pais',
        }
      },
    },
   
  ], []);


  const data = useMemo(() => ({
    tableData,
    urls: {
      createUrl: urlCrearCiudad,
      updateUrl: urlEditarCiudad,
      readUrl: urlListarCiudades,
      deleteUrl: urlEliminarCiudad,
    }
  }), [tableData])

  return (
    <CRUD data={data} />
  )
}

export default CiudadesCrud;
