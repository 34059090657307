import React, { useState, useRef } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Notificacion from "components/ui/Notificacion";
import Button from "components/CustomButtons/Button.js";
// import _ from 'lodash';

import InfoIcon from '@material-ui/icons/Info';
import { ServicioPromocionesForm } from './ServicioPromocionesForm';
import ServiciosTable from 'components/Medico/Servicios/ServiciosTable';
import { ModalInfo } from 'components/Modal/ModalInfo';
import { calcularPrecioFinal } from 'utils/calculos';

import imgCampaing from 'assets/images/icons/promociones_mepal.svg';


export const Servicios = ({ servicios, onDelete, onUpdate, doctorId, promocionesRestantes, doctorEspecializaciones }) => {
  const [isLoading, setisLoading] = useState(false)

  const refForm = useRef(null);

  const [showForm, setShowForm] = useState(false);
  const [showOficinas, setShowOficinas] = useState(false);
  const [servicio, setServicio] = useState({});

  //Modal información
  const [openModalInfo, setOpenModalInfo] = useState(false);

  const handleShowForm = valor => {
    if (showOficinas) setShowOficinas(false);
    if (!showForm) {
      refForm.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    setShowForm(!showForm);
    if (valor) {
      setServicio({});
    }
  };

  const handleUnaOficina = servicio => {
    if (showOficinas) setShowOficinas(false);

    setServicio(servicio);
    if (!showForm) {
      if (refForm && refForm.current) refForm.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else if (showForm) {
      setShowForm(!showForm);
    }
    setShowForm(!showForm);
  };

  const handleVerOficinas = servicio => {
    if (showForm) setShowForm(false);

    setServicio(servicio);
    if (!showOficinas) {
      if (refForm && refForm.current) refForm.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else if (showOficinas) {
      setShowOficinas(!showOficinas);
    }
    setShowOficinas(!showOficinas);
  };

  const handleDeleteServicio = async servicioId => {
    await onDelete(servicioId);
    setShowForm(false); //Cierra el formulario si tiene abierto
  };

  const handleCierraForm = () => {
    setShowForm(false); //Cierra el formulario si tiene abierto
  };

  const handleGuardarServicio = async (servicio) => {
    setisLoading(true)
    if (servicio.id) {
      const { descuento_valor, precio } = servicio;
      const descuentoValor = precio * (+descuento_valor / 100);
      //Validar valor mínimo de descuento
      if (descuentoValor < 4) {
        setisLoading(false);
        return Notificacion({
          type: "error",
          text: `El valor de descuento no puede ser menor a $4, el valor del descuento ingresado es de $${(Math.round(descuentoValor * 100) / 100).toFixed(2)}`,
        });
      }


      const { descuento_inicio, descuento_fin } = servicio;
      const descuento_inicio_tz = moment(descuento_inicio).local().format();
      const descuento_fin_tz = moment(descuento_fin).local().format();
      const payload = { ...servicio, descuento_inicio_tz, descuento_fin_tz };

      // return
      //Editar servicio
      await onUpdate(payload);
      setServicio(payload);
    }
    // const res=await dispatch(oficinaSet(param))
    setisLoading(false)
    // console.log(res)
  }

  //Helpers
  // const calcularPrecioFinal = (valor, descuento) => {
  //   const precioFinal = _.round( valor - (valor * (descuento/100)), 2);
  //   return ((precioFinal % 1 )> 0) ? precioFinal.toFixed(2)  : precioFinal ;
  // }

  return (
    <>
      {/* Modal Información */}
      <ModalInfo open={openModalInfo} onCloseModal={() => setOpenModalInfo(false)} img={imgCampaing} title={'Herramienta de Campañas'}>
        {/* Contenido */}
        Medpal le ofrece la posibilidad de manejar sus campañas médicas, y con ellas brindarle un gran alcance entre los usuarios de la plataforma.
        La herramienta le proporciona al profesional hasta 12 campañas anuales, las cuales pueden ser distribuidas según lo requiera.
        Antes de usar la herramienta es necesario tomar en cuenta las siguientes consideraciones:
        Los beneficios de las campañas aplican para los servicios ofertados por el profesional, pero no para consultas habituales.
        Una vez creada la campaña no podrá ser modificada o eliminada por el profesional y tendrá que ser cumplida a cabalidad.
        En caso de tener algún problema o de haberse generado accidentalmente la campaña, por favor diríjase inmediatamente al apartado de “Ayuda y soporte” para reportar lo sucedido y brindar soporte.
      </ModalInfo>
      {/* Fin Modal Información */}

      {/* Valor promociones restantes */}
      <div style={{ marginBottom: '10px' }}>
        Campañas Restantes: {promocionesRestantes ?? 0}
        <div style={{ display: 'inline-flex', verticalAlign: 'top', marginLeft: '5px' }}>
          <InfoIcon style={{ cursor: 'pointer' }} color="primary" onClick={() => setOpenModalInfo(true)} />
        </div>
      </div>
      <ServiciosTable
        fields={[
          { title: 'Nombre', name: 'nombre' },
          { title: 'Precio', name: 'precio', formatValue: (value) => `$${value}` },
          { title: 'Descuento Porcentaje', name: 'descuento_valor', formatValue: (value) => `${value}%` },
          { title: 'Precio Final', name: null, formatObjectValues: ({ precio, descuento_valor }) => `$${calcularPrecioFinal(precio, descuento_valor)}` },
          { title: 'Fecha Inicio', name: 'descuento_inicio_tz', formatValue: (value) => value ? moment(value).format("DD-MM-YYYY") : "Sin asignar" },
          { title: 'Fecha Finalización', name: 'descuento_fin_tz', formatValue: (value) => value ? moment(value).format("DD-MM-YYYY") : "Sin asignar" },
          { title: 'Configuración', name: null },
        ]}
        servicios={servicios}
        editService={handleUnaOficina}
        showOficinas={handleVerOficinas}
        deleteService={handleDeleteServicio}
        promotionsTable={true}
        promocionesRestantes={promocionesRestantes}
      />
      <div
        style={{
          display: 'flex',
          flex: 1,
          marginTop: '50px',
          justifyContent: 'center',
        }}
      >
        {/* <IconButton onClick={() => handleShowForm(true)} style={{ color: showForm ? 'red' : '' }} >
          {showForm && 'Cancelar'}
        </IconButton> */}
        {
          showForm &&
          <Button color="error" onClick={() => handleShowForm(true)}>
            Cancelar
          </Button>
        }

      </div>
      <div ref={refForm}>
        {showForm && (
          <ServicioPromocionesForm
            servicio={servicio}
            promocionesRestantes={promocionesRestantes}
            doctorEspecializaciones={doctorEspecializaciones}
            onCloseForm={handleCierraForm}
            addService={param => handleGuardarServicio(param)}
            doctor_id={doctorId}
            isLoading={isLoading}
          />
        )}
      </div>
    </>
  )
}

Servicios.propTypes = {
  servicios: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  doctorId: PropTypes.string.isRequired
}
