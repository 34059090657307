import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { loadData, createRecord, editRecord, deleteRecord, adminRestartState } from 'redux/actions/aAdmin';
import Notificacion from 'components/ui/Notificacion';

export const useAdminCrud = ({ rowsPerPageDefault, tableData, urls, defaultSortByColumn, defaultSortOrder, hideOptions = false, defaultSearchText }) => {

  const { createUrl, updateUrl, readUrl, deleteUrl, url } = urls;

  // 0: table, 1: Form create, 2: Form edit
  const [view, setView] = useState(0);
  const [itemSelected, setItemSelected] = useState({});

  const [formValues, setFormValues] = useState({});

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [search, setSearch] = useState(defaultSearchText ?? "");
  const [searchValue, setSearchValue] = useState(defaultSearchText ?? "");
  // const debouncedCount = useDebounce(search, 350);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  //Ordenamiento
  const [orderData, setOrderData] = useState({
    sortBy: defaultSortByColumn ?? '', // default sort column
    sortOrder: defaultSortOrder ?? "asc" // default sort oder
  });

  const { sortBy, sortOrder } = orderData;

  const dispatch = useDispatch();
  const { dataCRUD, dataPagination } = useSelector(state => state.data_admin);
  const { total } = dataPagination;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageDefault);
  const [skipLocal, setSkipLocal] = React.useState(0);

  const handleInputSearchChange = ({ target }) => {
    setSearch(target.value);
    debouncedSearch(target.value);
  }

  const debouncedSearch = React.useRef(
    _.debounce(async (searchValue) => {
      // init({ skip: 0, sortBy: 'nombre', search: searchValue });
      setSearchValue(searchValue);

    }, 350)
  ).current;

  const initialStateFormComponents = useMemo(() => tableData.map(item => ({ ...item, value: itemSelected[item.name], defaultValue: itemSelected[item.name], formValues, setFormValues })), [itemSelected, formValues, tableData])

  const handleChangePage = useCallback(
    (event, newPage) => {
      if (page > newPage) {
        setSkipLocal(skipLocal - rowsPerPage);
      } else {
        setSkipLocal(skipLocal + rowsPerPage);
      }
      setPage(newPage);
    },
    [skipLocal, page, rowsPerPage],
  )

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
      setSkipLocal(0);
    },
    [],
  )

  const handleClickCreate = useCallback(
    () => {
      setItemSelected({});
      setView(1);
    },
    [],
  )

  const handleClickEdit = useCallback(
    (item) => {
      setItemSelected(item);
      setView(2);
    },
    [],
  )

  const handleClickDelete = useCallback(
    item => {
      setItemSelected(item);
      setOpenConfirmDialog(true);
    },
    [],
  )

  const handleClickReturn = useCallback(
    () => {
      setItemSelected({});
      setFormValues({});
      setView(0);
    },
    [],
  )

  const onSubmitForm = useCallback(
    async (data, type) => {
      let dataForm = data;

      (!loading) && setLoading(true);
      let success;
      let responseMessage;
      if (type === "editar") {
        let respuesta = await dispatch(editRecord(url ?? updateUrl, itemSelected.id, dataForm));
        setLoading(false);
        const { transaccion, mensaje } = respuesta;
        success = transaccion;
        responseMessage = mensaje;

      } else if (type === "crear") {
        let respuesta = await dispatch(createRecord(url ?? createUrl, dataForm));
        setLoading(false);
        const { transaccion, mensaje } = respuesta;
        success = transaccion;
        responseMessage = mensaje;
        transaccion && setItemSelected({});
      }
      if (success) {
        setItemSelected({});
        setFormValues({});
        setView(0);
        setError(false)
      } else
        Notificacion({
          type: "error",
          text: responseMessage,
        })
    },
    [loading, itemSelected, dispatch, createUrl, updateUrl, url],
  )

  const handleConfirmDelete = useCallback(
    async () => {
      (!loading) && setLoading(true);
      let respuesta = await dispatch(deleteRecord(url ?? deleteUrl, itemSelected.id));
      setLoading(false);
      const { transaccion, mensaje } = respuesta;
      !transaccion ?
        Notificacion({
          type: "error",
          text: mensaje,
        }) : setError(false);
    },
    [loading, itemSelected, dispatch, deleteUrl, url],
  )

  //Ordenamiento
  const handleRequestSort = useCallback(
    async (sortByColumn) => {
      let sortOrderNew;
      if (!sortBy || sortByColumn !== sortBy) {
        sortOrderNew = "asc";
        setOrderData({
          sortBy: sortByColumn, // default sort column
          sortOrder: sortOrderNew // default sort oder
        })
      } else {
        if (sortByColumn === sortBy) {
          sortOrderNew = sortOrder === "asc" ? "desc" : "asc"
          setOrderData({
            sortBy: sortByColumn, // default sort column
            sortOrder: sortOrderNew// default sort oder
          })
        }
      }
    },
    [sortBy, sortOrder],
  );


  const tableTitles = useMemo(
    () => {
      const tableDataFiltered = tableData.filter(item => item.tableProps);
      const tableFormatted = tableDataFiltered.map(({ tableProps, name, label, objectName }) => ({
        title: tableProps?.title ?? label,
        onClickSort: () => handleRequestSort(tableProps.sort.sortColumnNameDB),
        columnName: tableProps.sort.sortColumnName, name, objectName,
        formatData: tableProps?.formatData
      }));

      return hideOptions ? tableFormatted : [
        ...tableFormatted,
        { title: "Opciones" }
      ]
    },
    [tableData, hideOptions, handleRequestSort],
  )

  const initLoadData = useCallback(
    async ({ skip = 0, take = rowsPerPageDefault, search = '', sortBy = 'nombre', sort = 'ASC' } = {}) => {
      setLoading(true);
      let respuesta = await dispatch(loadData({ url: url ?? readUrl, skip, take, search, sortBy, sort }));
      setLoading(false);
      const { transaccion, mensaje } = respuesta;

      !transaccion ? setError(mensaje) : setError(false);
    },
    [dispatch, rowsPerPageDefault, readUrl, url],
  )

  useEffect(() => {
    initLoadData({ skip: skipLocal, take: rowsPerPage, search: searchValue, sortBy, sort: sortOrder });
  }, [rowsPerPage, skipLocal, initLoadData, sortOrder, sortBy, searchValue]);

  useEffect(() => {
    return () => {
      dispatch(adminRestartState())
    }
  }, [dispatch])

  // Clean debounce
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  return {
    openConfirmDialog,
    setOpenConfirmDialog,
    handleConfirmDelete,
    handleClickCreate,
    search,
    handleInputSearchChange,
    loading,
    tableTitles,
    view,
    sortBy,
    sortOrder,
    dataCRUD,
    setView,
    handleClickEdit,
    handleClickDelete,
    total,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    initialStateFormComponents,
    onSubmitForm,
    handleClickReturn,
    error,
  }

}
