import { handleActions } from "redux-actions";
import { updateTableCrud } from "redux/actions/aAdmin";
import { deleteTableCrudItem } from "redux/actions/aAdmin";
import { updateTableCrudPropertyItem } from "redux/actions/aAdmin";
import { deleteTableCrudPropertyItem } from "redux/actions/aAdmin";
import { updateTableCrudProperty } from "redux/actions/aAdmin";
import { adminItemCRUD } from "redux/actions/aAdmin";
import { updateTableCrudItem } from "redux/actions/aAdmin";
import { updateTableCrudItemCustomCondition } from "redux/actions/aAdmin";
import { deleteTableCrudItemCustomCondition } from "redux/actions/aAdmin";
import { paginationData } from "redux/actions/aAdmin";
import { adminRestartState } from "redux/actions/aAdmin";
import {
  adminTableCRUD,
  isLoadingAdmin,
  hasErrorAdmin,
  adminForeignData,
} from "redux/actions/aAdmin";

export const data_admin = handleActions(
  {
    [adminTableCRUD]: (state, action) => ({
      ...state,
      dataCRUD: action.payload,
      isLoadingAdmin: false,
      isLoaded: true,
      hasErrorAdmin: false,
    }),
    [adminItemCRUD]: (state, action) => ({
      ...state,
      dataCRUDItem: action.payload,
    }),
    [adminForeignData]: (state, action) => ({
      ...state,
      dataForeign: action.payload,
      isLoadingAdmin: false,
      isLoaded: true,
      hasErrorAdmin: false,
    }),
    [paginationData]: (state, action) => ({
      ...state,
      dataPagination: action.payload,
      isLoadingAdmin: false,
      isLoaded: true,
      hasErrorAdmin: false,
    }),
    [updateTableCrud]: (state, action) => ({
      ...state,
      dataCRUD: [...state.dataCRUD, action.payload],
      isLoaded: true,
      isLoadingAdmin: false,
      hasErrorAdmin: false,
    }),
    [updateTableCrudItem]: (state, action) => ({
      ...state,
      dataCRUD: state.dataCRUD.map((item) =>
        item.id === action.payload.id ? action.payload : item
      ),
      isLoaded: true,
      isLoadingAdmin: false,
      hasErrorAdmin: false,
    }),
    [updateTableCrudItemCustomCondition]: (state, action) => ({
      ...state,
      dataCRUD: state.dataCRUD.map((item) =>
        action.payload.condition(item) ? action.payload.data : item
      ),
      isLoaded: true,
      isLoadingAdmin: false,
      hasErrorAdmin: false,
    }),
    [deleteTableCrudItem]: (state, action) => ({
      ...state,
      dataCRUD: state.dataCRUD.filter((item) => item.id !== action.payload),
      isLoaded: true,
      isLoadingAdmin: false,
      hasErrorAdmin: false,
    }),
    [deleteTableCrudItemCustomCondition]: (state, action) => ({
      ...state,
      dataCRUD: state.dataCRUD.filter((item) => action.payload.condition(item)),
      isLoaded: true,
      isLoadingAdmin: false,
      hasErrorAdmin: false,
    }),
    //id: idReceta, dataProperty: data: recetaDetalleCreadad, property: nombre de la propiedad "detalle"
    [updateTableCrudProperty]: (state, action) => ({
      ...state,
      dataCRUD: state.dataCRUD.map((item) =>
        item.id === action.payload.id
          ? {
            ...item,
            [action.payload.property]: [
              ...item[action.payload.property],
              action.payload.dataProperty,
            ],
          }
          : item
      ),
      isLoaded: true,
      isLoadingAdmin: false,
      hasErrorAdmin: false,
    }),
    [updateTableCrudPropertyItem]: (state, action) => ({
      ...state,
      dataCRUD: state.dataCRUD.map((item) =>
        item.id === action.payload.id
          ? {
            ...item,
            [action.payload.property]: item[
              action.payload.property
            ].map((subItem) =>
              subItem.id === action.payload.dataProperty.id
                ? action.payload.dataProperty
                : subItem
            ),
          }
          : item
      ),
      isLoaded: true,
      isLoadingAdmin: false,
      hasErrorAdmin: false,
    }),
    [deleteTableCrudPropertyItem]: (state, action) => ({
      ...state,
      dataCRUD: state.dataCRUD.map((item) =>
        item.id === action.payload.id
          ? {
            ...item,
            [action.payload.property]: item[action.payload.property].filter(
              (subItem) => subItem.id !== action.payload.dataPropertyid
            ),
          }
          : item
      ),
      isLoaded: true,
      isLoadingAdmin: false,
      hasErrorAdmin: false,
    }),

    // [ addRoleUser ]: (state, action) => ({
    //   ...state,
    //   dataCRUD: state.dataCRUD.map( item => ( item.id !== action.payload.id ?
    //     {...item, ite}
    //   )),
    //   isLoaded: true,
    //   isLoadingAdmin: false,
    //   hasErrorAdmin: false,
    // }),
    [isLoadingAdmin]: (state) => ({
      ...state,
      isLoadingAdmin: true,
      hasErrorAdmin: false,
    }),
    [hasErrorAdmin]: (state, action) => ({
      ...state,
      isLoadingAdmin: false,
      isLoaded: false,
      hasErrorAdmin: action.payload,
    }),
    [adminRestartState]: () => ({
      isLoadingAdmin: false,
      isLoaded: false,
      hasErrorAdmin: false,
      data: [],
      dataCRUD: [],
      dataCRUDItem: {},
      dataForeign: [],
      dataPagination: {},
    }),
  },
  {
    isLoadingAdmin: false,
    isLoaded: false,
    hasErrorAdmin: false,
    data: [],
    dataCRUD: [],
    dataCRUDItem: {},
    dataForeign: [],
    dataPagination: {},
  }
);
