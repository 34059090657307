import React from 'react'
import "../../styles/landing/doctorLanding.scss"
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const StartDeleteAccount = () => {

  const history = useHistory();

  const handleConfirm = async () =>
    history.push('/account/delete-account');

  return (

    <div className='doctor-landing-page'>
      <div id="form" className="form odd" style={{ marginTop: '10px' }}>
        <h1>Eliminar cuenta</h1>
        <p>Estas por eliminar tu cuenta Medpal. Debes iniciar sesión para confirmar la eliminación de tu cuenta</p>
        <button className="registrarse" onClick={handleConfirm}>Confirmar</button>
      </div>
    </div>

  )
}
