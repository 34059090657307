import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormControl, IconButton, InputLabel, MenuItem, Select } from '@material-ui/core';
import Button from "components/CustomButtons/Button.js";
import FormOficina from './oficina-form.component';
import { TableOficina } from 'components/oficina/medico/oficina-table.component';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { oficinaSet, deleteOficina } from '../../../redux/actions/aOficinas';
import { especialidades } from 'redux/actions/aEspecialidades';
import { FormInstitucionSucursal } from '../institucion/FormInstitucionSucursal';
import { getOficinasDoctor } from 'redux/actions/aMedico';
import { OficinasAfiliadasTable } from './OficinasAfiliadasTable';


const Oficinas = props => {
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false)

  const { data: oficinas, isLoadingOficinas } = useSelector(state => state.data_oficinas);
  const { doctor_id, med_activo } = useSelector(state => state.dataUser.data);

  const { descuento_afiliado, oficinas_limite = 0 } = useSelector(state => state.data_empresa.data);

  const refForm = useRef(null);

  const [showForm, setShowForm] = useState(false);
  const [showFormSucursal, setShowFormSucursal] = useState(false);
  const [unaOficina, setOficina] = useState({});

  // Oficinas tipos, seleccion
  const [showOficinasTable, setShowOficinasTable] = useState(true);

  // Oficinas Afiliadas
  const [oficinasAfiliadas, setOficinasAfiliadas] = useState([]);

  // Obtener datos de institucion
  const { institucion } = useSelector(state => state.dataUser.data);

  // Formtype: 1: Form oficina, 2: Form sucursal
  const handleShowForm = (valor, formType = 1) => {
    if (!showForm) {
      refForm.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    if (formType === 1) {
      setShowForm(!showForm);
      if (valor) {
        setOficina({});
      }
    } else {
      setShowFormSucursal(!showFormSucursal);
    }

  };

  const handleUnaOficina = oficina => {
    setOficina(oficina);
    if (!showForm) {
      if (refForm && refForm.current) refForm.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else if (showForm) {
      setShowForm(!showForm);
    }
    setShowForm(!showForm);
  };

  const handleDeleteOficina = async oficina => {
    await dispatch(deleteOficina(oficina));
    setShowForm(false); //Cierra el formulario si tiene abierto
  };

  const handleCierraForm = () => {
    setShowForm(false); //Cierra el formulario si tiene abierto
  };

  const handleCerrarFormSucursal = () => {
    setShowFormSucursal(false);
  };

  const handleGuardarOficina = async param => {
    setisLoading(true)
    await dispatch(oficinaSet(param, institucion ? true : false));
    setisLoading(false)
  }
  useEffect(() => {
    dispatch(getOficinasDoctor()).then(data => {
      if (data) setOficinasAfiliadas(data);
    });
  }, [dispatch])

  useEffect(() => {
    return () => {
      dispatch(especialidades([]));
    }
  }, [dispatch])


  return (
    <>
      {
        !institucion &&
        <FormControl style={{ width: '250px', margin: '15px 15px' }}>
          <InputLabel id="selectModeVistaLabel">Tipo de oficinas</InputLabel>
          <Select
            labelId="selectModeVistaLabel"
            id="selectMode"
            value={showOficinasTable}
            onChange={() => setShowOficinasTable(!showOficinasTable)}
          >
            <MenuItem value={true}>Oficinas</MenuItem>
            <MenuItem value={false}>Oficinas Afiliadas</MenuItem>
          </Select>
        </FormControl>
      }

      {
        showOficinasTable ?
          // Oficinas Creadas
          <div>
            <TableOficina
              titulos={[
                institucion ? 'Nombre' : 'Nombre de la institución',
                'Tiempo por Paciente',
                'Afiliación',
                'Precio consulta afiliado',
                'Precio consulta',
                'Dirección',
                'Ciudad',
                'Configuración',
              ]}
              oficinas={oficinas}
              descuentoAfiliado={descuento_afiliado}
              oficinasLimite={oficinas_limite}
              editarOficina={handleUnaOficina}
              deleteOficina={handleDeleteOficina}
              afiliado={med_activo}
            />
            <div
              style={{
                display: 'flex',
                flex: 1,
                marginTop: '50px',
                justifyContent: 'center',
              }}
            >
              {
                (showForm || showFormSucursal) ?
                  <Button color="error" onClick={() => handleShowForm(true, showForm ? 1 : 2)}>
                    Cancelar
                  </Button>
                  :
                  <>
                    <IconButton onClick={() => handleShowForm(true)} disabled={isLoadingOficinas || oficinas_limite <= oficinas.length} >
                      <AddCircleIcon /> {institucion ? 'Oficinas por especialidad' : 'Añadir oficina'}
                    </IconButton>
                    {
                      !institucion &&
                      <IconButton onClick={() => handleShowForm(true, 2)} >
                        <AddCircleIcon /> Vincularse a Institución
                      </IconButton>
                    }

                  </>

              }
            </div>
            <div ref={refForm}>
              {showForm && (
                <FormOficina
                  afiliado={med_activo}
                  oficina={unaOficina}
                  onCierraForm={handleCierraForm}
                  agregarOficina={param => handleGuardarOficina(param)}
                  doctor_id={doctor_id}
                  isLoading={isLoading}
                  {...props}
                />
              )}
              {
                showFormSucursal &&
                <FormInstitucionSucursal
                  handleCerrarFormSucursal={handleCerrarFormSucursal}
                />
              }
            </div>
          </div>
          :
          // Oficinas afiliadas
          <div>
            <OficinasAfiliadasTable data={oficinasAfiliadas} setOficinasAfiliadas={setOficinasAfiliadas} />
          </div>

      }

    </>
  );
};

export default Oficinas;
