import { combineReducers } from 'redux';
import { dataUser, token, rol_cli, rolActual, subscription } from './rLogin';
import { horariosOficinas, data_oficinas, data_horarios_reservas, medicos_secretaria } from './rOficinas';
import { datos_citas } from './rCitas';
import { data_clientes } from './rClientes';
import { datos_vademecum, datos_vademecum_doctor } from './rVademecum';
import { data_especialidades } from './rEspecialidades';
import { data_notificaciones } from './rNotificaciones';
import { data_recetas } from './rRecetas';
import { datos_cajas } from './rCajas';
import { data_admin } from './rAdmin';
import { data_servicios } from './rServicios';
import { data_empresa } from './rEmpresa';

const appReducer = combineReducers({
  dataUser,
  token,
  rol_cli,
  data_empresa,
  datos_citas,
  datos_cajas,
  horariosOficinas,
  data_oficinas,
  data_horarios_reservas,
  data_clientes,
  datos_vademecum,
  data_especialidades,
  data_servicios,
  datos_vademecum_doctor,
  data_recetas,
  data_notificaciones,
  data_admin,
  medicos_secretaria,
  rolActual,
  subscription
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    // Restart store
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export default rootReducer;