import React from 'react'
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import { RecetaMembrete } from '../receta/RecetaContentPdf';
import caracteristicas from '../../../assets/images/medpal.svg';


const useStyles = makeStyles(() => ({
  container: {
    margin: '20px',
  },
  mainContent: { overflow: "visible  !important", },
  header: {
    textAlign: 'start',
    height: "100px",
    borderBottom: "1px solid black",
    display: "flex", alignItems: "center",
    marginBottom: '10px'
  },
  headerNoBorder: {
    textAlign: 'start',
    height: "101px",
    display: "flex", alignItems: "center",
    marginBottom: '10px'
  },
  headerAreaDoctor: {
    marginLeft: '20px'
  },
  doctorName: {
    fontWeight: 'bold',
    color: "black"
  },
  doctorSpecialty: {
    color: "black",
    marginTop: "10px"
  },
}));

export const CertificadoPrint = React.forwardRef(({ header = true, data }, ref) => {

  const { patient, doctor, date, text = '' } = data;
  const { titulo_honorifico, nombres, apellidos, especializaciones } = doctor;
  const { nombres: patientName, apellidos: patientSurname } = patient;
  // const { titulo_honorifico, nombres, apellidos, especializaciones } = paciente;

  const doctorName = `${titulo_honorifico} ${nombres} ${apellidos}`;

  let especializacionesDoctor = "";
  especializaciones.map(({ nombre }) => (especializacionesDoctor.length === 0 ? especializacionesDoctor = especializacionesDoctor.concat(nombre) : especializacionesDoctor = especializacionesDoctor.concat(`, ${nombre}`)));

  const classes = useStyles();

  return (
    <div ref={ref} >
      <table style={{ width: '100%', pageBreakAfter: 'always' }}>
        <thead style={{ display: 'table-header-group' }}>
          <tr>
            <th>
              <div className={header ? classes.header : classes.headerNoBorder} >
                {
                  header &&
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <td>
                          <RecetaMembrete />
                        </td>
                        <td>
                          <RecetaMembrete />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                }
              </div>
            </th>
          </tr>
        </thead>
        <tbody >
          <tr>
            <td>
              <div className={classes.container}>
                <table style={{ width: '100%' }}>
                  <tbody >
                    <tr>
                      <td align='center'>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div>
                            <img
                              style={{ margin: 'auto' }}
                              alt='logo'
                              src={caracteristicas}
                              width={75}
                            />
                          </div>
                          <div>
                            {moment(date).format('LL')}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td align='center'>
                        <strong>
                          CERTIFICADO MÉDICO
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingTop: '20px' }}>
                        <div>
                          {doctorName}
                        </div>
                        <div>Especialidad: {especializacionesDoctor}</div>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingTop: '20px' }}>
                        <div>
                          {`${patientName} ${patientSurname}`} fue atendido profesionalmente el día {moment(date).format('LL')} determinando clínicamente que {text}
                        </div>
                      </td>
                    </tr>
                    <tr align='center'>
                      <td style={{ paddingTop: '40px' }}>
                        <div >
                          Atentamente
                        </div>
                        <div style={{ height: '70px' }}></div>
                        <div>
                          <strong>
                            {doctorName}
                          </strong>

                        </div>
                        <div>Especialidad: {especializacionesDoctor}</div>

                        {/* <div style={{ marginTop: '20px' }}>
                          <img
                            style={{ margin: 'auto' }}
                            alt='logo'
                            src={caracteristicas}
                            width={75}
                          />
                        </div> */}

                      </td>
                    </tr>
                  </tbody>

                </table>
              </div>

            </td>
          </tr>

        </tbody>
      </table>
    </div>
  )
})

