import { makeStyles } from '@material-ui/core';
import React from 'react'

const useStyles = makeStyles((theme) => (
  {
    container: {
      maxWidth: '80%',
      margin: 'auto',
      textAlign: 'justify',
    }
  }
));


export const TextPage = ({ children }) => {

  const classes = useStyles();

  return (
    <div className={classes.container}>
      {children}
    </div>
  )
}
