import React, { useState, useEffect, useRef } from "react";

import { TextFieldCustom as TextField } from 'components/forms/input/TextFieldCustom';
import { makeStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";

import Loading from "../../Loading";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "../../CustomButtons/Button";


import { validacionesOficina } from "utils/validaciones";
import Paper from "@material-ui/core/Paper";

import styles from 'assets/jss/material-dashboard-react/components/customInputStyle.js';
import { FormErrorLabel } from "components/forms/labels/FormErrorLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1.3),
    },
  },
  ...styles
}));

const FormOficina = ({
  doctor_id,
  agregarOficina,
  oficina,
  onCierraForm,
  ...props
}) => {
  //estilos
  const classes = useStyles();
  const firstRender = useRef(true);

  //estados para el formulario
  const [error, seterror] = useState({ title: "", mensaje: "" });

  const [valores, setvalores] = React.useState({
    tiempo_por_cliente_minutos:
      oficina.tiempo_por_cliente_minutos === undefined
        ? ""
        : oficina.tiempo_por_cliente_minutos,
    costo_primera_consulta:
      oficina.costo_primera_consulta === undefined
        ? ""
        : oficina.costo_primera_consulta,
    costo_consulta:
      oficina.costo_consulta === undefined ? "" : oficina.costo_consulta,
    direccion: oficina.direccion === undefined ? "" : oficina.direccion,
    ciudad: oficina.ciudad === undefined ? "" : oficina.ciudad,
    provincia: oficina.provincia === undefined ? "" : oficina.provincia,
    pais: oficina.pais === undefined ? "" : oficina.pais,
    codigo_zip: oficina.codigo_zip === undefined ? "" : oficina.codigo_zip,
    referencias_fisicas:
      oficina.referencias_fisicas === undefined
        ? ""
        : oficina.referencias_fisicas,
    longitud: oficina.longitud === undefined ? 0 : oficina.longitud,
    latitud: oficina.latitud === undefined ? 0 : oficina.latitud,
    doctor_id: doctor_id,
    oficina_id: oficina.id === undefined ? "" : oficina.id,
  });

  //Espero que se termine de setear los valores, para con esos valores validar
  useEffect(() => {
    if (firstRender.current) {
      return;
    }
    seterrors(validacionesOficina(valores));
  }, [valores]);

  const [errors, seterrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setvalores({ ...valores, [name]: value });
  };

  const handleClick = async () => {
    firstRender.current = false;
    const res = validacionesOficina(valores);
    if (Object.keys(res).length > 0) {
      seterrors(validacionesOficina(valores));
      return;
    }
    try {
      if (Object.keys(errors).length > 0) {
        seterror({
          title: "error",
          mensaje: "Debe completar los campos correctamente",
        });
        return;
      }

      await agregarOficina(valores);

      if (props.hasError) {
        setvalores({
          tiempo_por_cliente_minutos: !oficina.tiempo_por_cliente_minutos
            ? ""
            : oficina.tiempo_por_cliente_minutos,
          costo_primera_consulta: !oficina.costo_primera_consulta
            ? ""
            : oficina.costo_primera_consulta,
          costo_consulta: !oficina.costo_consulta ? "" : oficina.costo_consulta,
          direccion: !oficina.direccion ? "" : oficina.direccion,
          ciudad: !oficina.ciudad ? "" : oficina.ciudad,
          provincia: !oficina.provincia ? "" : oficina.provincia,
          pais: !oficina.pais ? "" : oficina.pais,
          codigo_zip: !oficina.codigo_zip ? "" : oficina.codigo_zip,
          referencias_fisicas: !oficina.referencias_fisicas
            ? ""
            : oficina.referencias_fisicas,
          longitud: !oficina.longitud ? 0 : oficina.longitud,
          latitud: !oficina.latitud ? 0 : oficina.latitud,
          doctor_id: doctor_id,
        });
        seterror({ title: "success", mensaje: props.hasError });
      } else {
        onCierraForm();
        seterror({ title: "error", mensaje: props.hasError });
      }
    } catch (err) {
      seterror({
        title: "error",
        mensaje: "Ocurrio un error, intente nuevamente",
      });
    }
  };

  return (
    <Paper>
      <form
        style={{ margin: "15px" }}
        className={classes.root}
        noValidate="noValidate"
        autoComplete="off"
      >
        <Loading title="Cargando..." loading={props.isLoading}>
          <>
            <GridContainer>
              <GridItem xs={4}>
                <TextField
                  label="* Tiempo por paciente (en minutos)"
                  id="tiempo_por_cliente_minutos"
                  name="tiempo_por_cliente_minutos"
                  type="number"
                  inputProps={{ min: 0, max: 60 }}
                  fullWidth
                  onChange={handleChange}
                  value={valores.tiempo_por_cliente_minutos}
                  error={errors.tiempo_por_cliente_minutos}
                />
                {errors.tiempo_por_cliente_minutos && (
                  <FormErrorLabel> {errors.tiempo_por_cliente_minutos}</FormErrorLabel>
                )}
              </GridItem>
              <GridItem xs={4}>
                <TextField
                  label="Costo afiliado (para afiliados)"
                  id="costo_primera_consulta"
                  name="costo_primera_consulta"
                  type="text"
                  fullWidth
                  onChange={handleChange}
                  value={valores.costo_primera_consulta}
                  error={errors.costo_primera_consulta}
                />
                {errors.costo_primera_consulta && (
                  <FormErrorLabel> {errors.costo_primera_consulta}</FormErrorLabel>
                )}
              </GridItem>
              <GridItem xs={4}>
                <TextField
                  label="* Costo consulta"
                  id="costo_consulta"
                  name="costo_consulta"
                  type="text"
                  fullWidth
                  onChange={handleChange}
                  value={valores.costo_consulta}
                  error={errors.costo_consulta}
                />
                {errors.costo_consulta && (
                  <FormErrorLabel> {errors.costo_consulta}</FormErrorLabel>
                )}
              </GridItem>
              <GridItem xs={8}>
                <TextField
                  error={errors.direccion}
                  label="* Dirección"
                  id="direccion"
                  name="direccion"
                  type="text"
                  fullWidth
                  onChange={handleChange}
                  value={valores.direccion}
                />
                {errors.direccion && (
                  <FormErrorLabel> {errors.direccion}</FormErrorLabel>
                )}
              </GridItem>
              <GridItem xs={4}>
                <TextField
                  label="Codigo ZIP"
                  id="codigo_zip"
                  name="codigo_zip"
                  type="text"
                  fullWidth
                  onChange={handleChange}
                  value={valores.codigo_zip}
                  error={errors.codigo_zip}
                />
                {errors.codigo_zip && (
                  <FormErrorLabel> {errors.codigo_zip}</FormErrorLabel>
                )}
              </GridItem>
              <GridItem xs={4}>
                <TextField
                  error={errors.pais}
                  label="* Pais"
                  id="pais"
                  name="pais"
                  type="text"
                  fullWidth
                  onChange={handleChange}
                  value={valores.pais}
                />
                {errors.pais && (
                  <FormErrorLabel> {errors.pais}</FormErrorLabel>
                )}
              </GridItem>
              <GridItem xs={4}>
                <TextField
                  error={errors.provincia}
                  label="* Provincia"
                  id="provincia"
                  name="provincia"
                  type="text"
                  fullWidth
                  onChange={handleChange}
                  value={valores.provincia}
                />
                {errors.provincia && (
                  <FormErrorLabel> {errors.provincia}</FormErrorLabel>
                )}
              </GridItem>
              <GridItem xs={4}>
                <TextField
                  error={errors.ciudad}
                  label="* Ciudad"
                  id="ciudad"
                  name="ciudad"
                  type="text"
                  fullWidth
                  onChange={handleChange}
                  value={valores.ciudad}
                />
                {errors.ciudad && (
                  <FormErrorLabel> {errors.ciudad}</FormErrorLabel>
                )}
              </GridItem>
              <GridItem xs={8}>
                <TextField
                  error={errors.referencias_fisicas}
                  label="* Referencias"
                  id="referencias_fisicas"
                  name="referencias_fisicas"
                  type="text"
                  fullWidth
                  onChange={handleChange}
                  value={valores.referencias_fisicas}
                />
                {errors.referencias_fisicas && (
                  <FormErrorLabel> {errors.referencias_fisicas}</FormErrorLabel>
                )}
              </GridItem>
            </GridContainer>

            <div
              style={{
                margin: "20px 8px",
                flex: "1",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleClick()}
              >
                Guardar
              </Button>
            </div>
          </>
        </Loading>
        {error.mensaje && (
          <Alert severity={`${[error.title]}`}>
            <AlertTitle>
              {error.title === "error" ? "Error" : "Success"}
            </AlertTitle>
            {error.mensaje}
          </Alert>
        )}
      </form>
    </Paper>
  );
};

export default FormOficina;
