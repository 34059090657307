import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { getHorariosOficina } from 'redux/actions/aOficinas';
import { getMedicos } from 'redux/actions/aOficinas';
import { secretariaDoctorSelected } from 'redux/actions/aSecretaria';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 340,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SelectMedico = ({ setOficinasMedico, setServiciosMedico }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const medicos = useSelector(state => state.medicos_secretaria.data);
  const { doctorSelected } = useSelector(state => state.medicos_secretaria);
  // create key value pair from array of array

  const result =
    useMemo(() => medicos.reduce((acc, item) => {
      let setObj = new Set();
      if (!setObj.has(item.id)) {
        setObj.add(item.id, item)
        acc.push(item)
      }
      return acc;
    }, []), [medicos])
    ;//converting back to array from mapobject

  const oficinas = useSelector(state => state.dataUser.data.oficinas);

  const handleChange = event => {
    dispatch(secretariaDoctorSelected({ id: event.target.value }))

    if (setServiciosMedico) {
      const medicoFind = result.filter(medico => medico.id === event.target.value);
      if (medicoFind.length > 0) setServiciosMedico(medicoFind[0].servicios);
    }
  };

  useEffect(() => {
    dispatch(getMedicos());
  }, [dispatch]);

  useEffect(() => {
    // Horarios de una oficina
    if (doctorSelected && setOficinasMedico) {
      dispatch(getHorariosOficina(doctorSelected.id));
      const nuevo = oficinas.filter(oficina => oficina.doctor_id === doctorSelected.id);
      setOficinasMedico(nuevo);
    }
  }, [dispatch, oficinas, setOficinasMedico, doctorSelected]);
  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-label">Seleccione el doctor</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={doctorSelected?.id}
        onChange={handleChange}
      >
        {result !== undefined &&
          result.map((medico, key) => (
            <MenuItem key={key} value={medico.id}>{`${medico.nombres} ${medico.apellidos}`}</MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default SelectMedico;
