import React, { useMemo } from 'react';
import CRUD from 'components/CRUD/Crud.component';
import placeSVG from "../../../src/assets/images/icons/place.svg";
import { urlAdminInstitucion } from 'constants/urls';

export const InstitucionesCrud = () => {
  const fileField = React.useRef(null);

  const tableData = useMemo(() => [
    {
      type: "imageInput",
      name: "logo_img",
      label: "Imagen",
      imgDefault: placeSVG,
      ref: fileField
    },
    {
      type: "inputDisabled",
      label: "Id",
      name: 'id',
      showOnCreate: false,
      showOnTable: true,
      tableProps: {
        title: 'Id',
        sort: {
          sortColumnName: 'id',
          sortColumnNameDB: 'id',
        }
      },
    },
    {
      type: "input",
      name: "nombre",
      label: "Nombre",
      tableProps: {
        sort: {
          sortColumnName: 'nombre',
          sortColumnNameDB: 'nombre',
        }
      },
      validationOptions: {
        required: {
          value: true,
          message: "El campo es requerido",
        },
      },
    },
    {
      type: "input",
      name: "RUC",
      label: "RUC",
      validationOptions: {
        required: {
          value: true,
          message: 'El campo es requerido',
        },
      },
      tableProps: {
        title: 'RUC',
        sort: {
          sortColumnName: 'RUC',
          sortColumnNameDB: 'RUC',
        }
      },
    },
    {
      type: "input",
      name: "razon_social",
      label: "Razón social",
      validationOptions: {
        required: {
          value: true,
          message: 'El campo es requerido',
        },
      },
      tableProps: {
        title: 'razon_social',
        sort: {
          sortColumnName: 'razon_social',
          sortColumnNameDB: 'razon_social',
        }
      },
    },
    {
      type: "input",
      name: "email",
      label: "Email",
      validationOptions: {
        required: {
          value: true,
          message: 'El campo es requerido',
        },
      },
      tableProps: {
        title: 'email',
        sort: {
          sortColumnName: 'email',
          sortColumnNameDB: 'email',
        }
      },
    },
    {
      type: "input",
      name: "direccion",
      label: "Dirección",
      tableProps: {
        sort: {
          sortColumnName: 'direccion',
          sortColumnNameDB: 'direccion',
        }
      },
    },
    {
      type: "selectCombo",
      name: "disponible",
      label: "Disponibilidad",
      defaultValue: null,
      options: [
        {
          value: null,
          label: "Seleccione un valor",
          disabled: true
        },
        {
          value: true,
          label: "Disponible"
        },
        {
          value: false,
          label: "No disponible"
        },
      ],
      tableProps: {
        formatData: (value) => value ? 'Disponible' : 'No disponible',
        sort: {
          sortColumnName: 'disponible',
          sortColumnNameDB: 'disponible',
        }
      },
    },
    {
      type: "selectCombo",
      name: "cuenta_aprobada",
      label: "Cuenta Aprobada",
      defaultValue: null,
      options: [
        {
          value: null,
          label: "Seleccione un valor",
          disabled: true
        },
        {
          value: true,
          label: "Aprobada"
        },
        {
          value: false,
          label: "No aprobada"
        },
      ],
      tableProps: {
        formatData: (value) => value ? 'Aprobada' : 'No aprobada',
        sort: {
          sortColumnName: 'cuenta_aprobada',
          sortColumnNameDB: 'cuenta_aprobada',
        }
      },
    },


  ], []);


  const data = useMemo(() => ({
    tableData,
    urls: { url: urlAdminInstitucion }
  }), [tableData])

  return (
    <CRUD data={data} />
  )
}
