import { RegisterForm } from 'components/forms/register/native/RegisterForm';
import React from 'react'

const Nosotros = () => {

  return (
    <div className='landing-page'>
      <div className="space_nav"></div>

      <div className="abus_section1">
        <div className="about_us">
          <h1>ACERCA DE NOSOTROS</h1>
          <h3>Somos un equipo de profesionales que entendemos el gran
            valor de tu tiempo, es por eso que pensando en tus
            necesidades hemos creado MEDPAL, un sitema que brinda
            al médico las más modernas herramientas tecnológicas
            para otimizar, organizar y simplificar su trabajo; así como
            proporcionar al cliente una manera fácil, cómoda y rápida
            de acceder a un servicio de salud</h3>
          <div className="features6_container">
            <div className="features6">
              <div id="icon_features6_1"></div>
              <div className="text_features6">
                TU INFORMACIÓN DISPONIBLE 24/7
              </div>
            </div>
            <div className="features6">
              <div id="icon_features6_2"></div>
              <div className="text_features6">
                FÁCIL Y RÁPIDO ACCESO A TUS DATOS
              </div>
            </div>
            <div className="features6">
              <div id="icon_features6_3"></div>
              <div className="text_features6">
                SEGURIDAD Y CONFIDENCIALIDAD EN
                TODA TU INFORMACIÓN
              </div>
            </div>
          </div>
          <div id="img_about_us" className="img"></div>
        </div>
      </div>

      {/* Formulario de registro */}
      <RegisterForm />
    </div>
  );

}
export default Nosotros