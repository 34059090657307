import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
// import classnames from "classnames";
import Button from "../../CustomButtons/Button";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  button: {
    margin: 5,
  },
  redButton: {
    backgroundColor: "#d9534f",
    color: "#fff",
  },
});

export const TableRowCrud = ({
  item,
  handleEdit,
  handleDelete,
  children,
  keyValue,
  hideOptions = false,
  optionsButtons,
  optionsButtonsCallback,
}) => {
  const classes = useStyles();
  
  const handleClickEdit = () => {
    handleEdit(item);
  };
  const handleClickDelete = () => {
    handleDelete(item);
  };

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={keyValue}>
      {children}
      {
        !hideOptions &&
        <TableCell size="small" className={classes.tableCell}>
          <>
            {optionsButtonsCallback && optionsButtonsCallback}
          </>
          <div style={{ display: "flex", flexDirection: "row" }}>

            {
              optionsButtons
            }
            {
              handleEdit &&
              <Button
                variant="contained"
                color="primary"
                size="sm"
                className={classes.button}
                startIcon={<EditIcon />}
                onClick={handleClickEdit}
              >
                Editar
              </Button>
            }
            {
              handleDelete &&
              <Button
                variant="contained"
                color="danger"
                size="sm"
                className={`${classes.button} ${classes.info}`}
                startIcon={<DeleteIcon />}
                onClick={handleClickDelete}
              >
                Eliminar
              </Button>
            }
          </div>
        </TableCell>
      }

    </TableRow>
  );
};
