import React, { useState, useEffect } from 'react';

import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

import Loading from '../Loading';
import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from '../CustomButtons/Button';
import HorarioForm1 from './horario-form1.component';

import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from 'react-redux';
import { horariosOficinaUpdate, getHorariosLaboratorista, horariosOficinaSetLaboratorista } from 'redux/actions/aOficinas';

// moment.locale('es');
const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
}));

// const lunes_viernes = [
//   { id: 'LUNES' },
//   { id: 'MARTES' },
//   { id: 'MIÉRCOLES' },
//   { id: 'JUEVES' },
//   { id: 'VIERNES' },
// ];
// const dias_semana = [{ id: 'LUNES A VIERNES' }, ...lunes_viernes, { id: 'SÁBADO' }, { id: 'DOMINGO' }];

const HorarioForm = ({ laboratorista_id, oficina_id, unHorario, ...props }) => {


  const classes = useStyles();
  const dispatch = useDispatch();

  const [error, seterror] = useState({ title: '', mensaje: '' });
  const [editar, setEditar] = useState(false);

  const [arreglo_hora, setArregloHora] = useState([]);

  useEffect(() => {
    // setdia(!!unHorario.dia_semana ? dias_semana.filter(d => d.id === unHorario.dia_semana)[0].id : '');
    setEditar(!!unHorario.dia_semana);
    const horario = [
      {
        id: !!unHorario.id ? unHorario.id : '',
        dia_semana: !!unHorario.dia_semana ? unHorario.dia_semana : '',
        hora_inicio: !!unHorario.hora_inicio ? unHorario.hora_inicio : '',
        hora_fin: !!unHorario.hora_fin ? unHorario.hora_fin : '',
        oficina_id: !!unHorario.oficina_id ? unHorario.oficina_id : '',
      },
    ];
    setArregloHora(horario);
  }, [unHorario]);

  const handleClick = async () => {
    // debugger
    try {
      if (!arreglo_hora[0].hora_inicio || !arreglo_hora[0].hora_fin) {
        seterror({ title: 'error', mensaje: 'Debe completar los campos' });
        return;
      }
      // Para validar que la hora de inicio siempre sea menor a la hora final
      // for (let index = 0; index < arreglo_hora.length; index++) {
      //   const inicio = moment(arreglo_hora[index].hora_inicio, "HH:mm");
      //   const fin = moment(arreglo_hora[index].hora_fin, "HH:mm");

      //   if (!moment(inicio).isBefore(fin)) {
      //     seterror({
      //       title: "error",
      //       mensaje: "La hora de inicio tiene que ser menor a la hora final",
      //     });
      //     const aux = [...arreglo_hora];
      //     aux.splice(index, 1, {});
      //     setArregloHora(aux);
      //     return;
      //   }
      // }

      // let data;
      // if (dia_semana !== dias_semana[0].id) {
      //   data = arreglo_hora.map((hora) => ({
      //     dia_semana,
      //     hora_inicio: hora.hora_inicio,
      //     hora_fin: hora.hora_fin,
      //   }));
      // } else {
      //   data = lunes_viernes
      //     .map((entre_semana) =>
      //       arreglo_hora.map((hora) => ({
      //         dia_semana: entre_semana.id,
      //         hora_inicio: hora.hora_inicio,
      //         hora_fin: hora.hora_fin,
      //       }))
      //     )
      //     .flat(Infinity);
      // }

      const enviar = {
        data: arreglo_hora,
        laboratorista_id: laboratorista_id,
      };

      if (Object.keys(unHorario).length === 0) {
        await dispatch(horariosOficinaSetLaboratorista(enviar, oficina_id));
      } else {
        await dispatch(horariosOficinaUpdate(arreglo_hora[0], arreglo_hora[0].id));
      }

      if (props.hasError) {
        // setdia('');
        setArregloHora([{}]);
        seterror({ title: 'success', mensaje: props.hasError });
      } else {
        seterror({ title: 'error', mensaje: props.hasError });
      }
      await dispatch(getHorariosLaboratorista(laboratorista_id));
    } catch (err) {
      seterror({
        title: 'error',
        mensaje: 'Ocurrio un error, intente nuevamente',
      });
    }
  };

  // const onDiaChange = (event, values) => {
  //   setdia(values !== null ? values.id : '');
  // };

  const handleArrayHoraChange = (hora, index, key) => {
    //Accedo primero al índice de esa posición y luego agrego como key el contenido de la variable enviada

    const arregoAux = [...arreglo_hora];
    arregoAux[index][key] = hora;
    setArregloHora(arregoAux);
  };

  const handleArrayHoraDelete = index => {
    if (arreglo_hora.length > 1) {
      const aux = [...arreglo_hora];
      aux.splice(index, 1);
      setArregloHora(aux);
    }
  };

  const handleSetHoras = val => {
    setArregloHora([...val]);
  };



  return (
    <form
      style={{
        margin: '15px auto',
        // width: "600px",
      }}
      className={classes.root}
      noValidate="noValidate"
      autoComplete="off"
    >
      <Loading title="Cargando..." loading={props.isLoading}>
        <>
          {arreglo_hora.length === 0 ||
            (!arreglo_hora[0].hora_inicio && (
              <HorarioForm1
                tiempoConsulta={props.tiempoConsulta}
                handleSetHoras={handleSetHoras}
                oficina_id={oficina_id}
              />
            ))}
          {arreglo_hora.length > 0 && !!arreglo_hora[0].hora_inicio && (
            <>
              {/* <Autocomplete
                id="dia"
                name="dia"
                options={dias_semana}
                //Si es que va a editar entonces deshabilitar: si viene el objeto a editar deshabilitar si no es uno nuevo
                disabled={Object.keys(unHorario).length === 0 ? false : true}
                getOptionLabel={(option) => option.id}
                onChange={onDiaChange}
                defaultValue={
                  arreglo_hora.length > 0 && arreglo_hora[0].dia_semana
                    ? dias_semana.find(
                        (d) => d.id === arreglo_hora[0].dia_semana
                      )
                    : ""
                }
                renderInput={(params) => (
                  <TextField {...params} label="Seleccione el día" />
                )}
              /> */}

              {arreglo_hora.map((hora, index) => (
                <GridContainer
                  key={index}
                  style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center',
                  }}
                >
                  <GridItem
                    xs={3}
                    md={3}
                    style={{
                      display: 'flex',
                      flex: 1,
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    {hora.dia_semana}
                  </GridItem>
                  <GridItem xs={3} md={3}>
                    <TextField
                      label="Inicio"
                      id="hora_inicio"
                      name="hora_inicio"
                      type="time"
                      fullWidth
                      placeholder="hh:mm"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={e => handleArrayHoraChange(e.target.value, index, 'hora_inicio')}
                      value={hora.hora_inicio}
                    />
                  </GridItem>
                  <GridItem xs={3} md={3}>
                    <TextField
                      id="hora_fin"
                      name="hora_fin"
                      label="Fin"
                      type="time"
                      fullWidth
                      placeholder="hh:mm"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={e => handleArrayHoraChange(e.target.value, index, 'hora_fin')}
                      value={hora.hora_fin}
                    />
                  </GridItem>
                  {!editar && (
                    <>
                      {/* <GridItem xs={2} md={1}>
                        <Tooltip
                          style={{ marginTop: "8px" }}
                          id="tooltip-agregar"
                          title="Agregar hora"
                          placement="top"
                        >
                          <IconButton
                            aria-label="Agregar"
                            onClick={() =>
                              setArregloHora([...arreglo_hora, {}])
                            }
                            //Mantengo el mismo array de objetos anteriores y agrego un objeto vacio al final
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </Tooltip>
                      </GridItem> */}
                      <GridItem xs={2} md={1}>
                        <Tooltip
                          style={{ marginTop: '8px', marginLeft: '0' }}
                          id="tooltip-eliminar"
                          title="Eliminar hora"
                          placement="top"
                        >
                          <IconButton aria-label="Eliminar" onClick={() => handleArrayHoraDelete(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </GridItem>
                    </>
                  )}
                </GridContainer>
              ))}

              <div
                style={{
                  margin: '20px 8px',
                  flex: '1',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button size="large" variant="outlined" color="primary" onClick={() => handleClick()}>
                  Guardar
                </Button>
                <Button size="large" variant="outlined" color="error" onClick={() => props.handleCerrar()}>
                  Cancelar
                </Button>
              </div>
            </>
          )}
        </>
      </Loading>
      {error.mensaje && (
        <Alert severity={`${[error.title]}`}>
          <AlertTitle>{error.title === 'error' ? 'Error' : 'Success'}</AlertTitle>
          {error.mensaje}
        </Alert>
      )}
    </form>
  );
};

export default HorarioForm;
