import { handleActions } from 'redux-actions';
import { login, rol, isLoadingLogin, hasErrorLogin, jwt, logOut, setRolActual, updateDataUser } from '../actions/aLogin';
import { dataLogin } from '../../constants/estructuras';
import { subscripcion, isLoadingSubscription, hasErrorSubscripcion, subscriptionInitialState } from 'redux/actions/aMedico';

export const dataUser = handleActions(
  {
    [login]: (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
      hasError: false,
    }),
    [updateDataUser]: (state, action) => ({
      ...state,
      data: { ...state.data, ...action.payload },
      isLoading: false,
      hasError: false,
    }),
    [isLoadingLogin]: (state, action) => ({ ...state, isLoading: action.payload ?? true, hasError: false }),
    [hasErrorLogin]: (state, action) => ({
      ...state,
      isLoading: false,
      hasError: action.payload,
    }),
    [logOut]: (state, action) => ({
      isLoading: false,
      hasError: false,
      data: { ...dataLogin },
    }),
  },
  { isLoading: false, hasError: false, data: { ...dataLogin } }
);

export const token = handleActions({ [jwt]: (state, action) => action.payload }, false);
export const rol_cli = handleActions({ [rol]: (state, action) => action.payload }, false);
export const rolActual = handleActions({ [setRolActual]: (state, action) => action.payload }, false);

export const subscription = handleActions({
  [subscripcion]: (state, action) => ({ ...state, data: action.payload }),
  [isLoadingSubscription]: (state, action) => ({ ...state, isLoading: action.payload }),
  [hasErrorSubscripcion]: (state, action) => ({ ...state, error: action.payload }),
  [subscriptionInitialState]: () => ({ data: { id: null, nextBilling: null, finalPayment: null, endTime: null }, isLoading: true, error: null, }),
}, { data: { id: null, nextBilling: null, finalPayment: null, endTime: null }, isLoading: true, error: null });
