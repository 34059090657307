import { Typography, makeStyles } from '@material-ui/core';
import Loading from 'components/Loading';
import { urlPayphoneButton } from 'constants/urls';
import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux';
import { verificarReserva } from 'redux/actions/aClientes';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { verificarReservaCreada } from 'redux/actions/aClientes';
import paymentStyle from 'assets/jss/payment/paymentStyle';

const useStyles = makeStyles(paymentStyle);

export const ReservaPayment = () => {

  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const [error, setError] = useState(null);

  let { reservaId } = useParams();

  const paramsAppointmentApp = useMemo(() => {
    const searchParams = new URLSearchParams(document.location.search);
    
    return {
      inicio: searchParams.get('inicio'),
      fin: searchParams.get('fin'),
      cliente_id: searchParams.get('cliente_id'),
      doctor_id: searchParams.get('doctor_id'),
      oficina_id: searchParams.get('oficina_id'),
      usarMembresia: (searchParams.get('usarMembresia') === 'true'),
      cedulaTerceraPersona: searchParams.get('cedulaTerceraPersona'),
      nombreTerceraPersona: searchParams.get('nombreTerceraPersona'),
      paciente: (searchParams.get('paciente') === 'true'),
      servicio_id: searchParams.get('servicio_id'),
      paymentType: searchParams.get('paymentType'),
      estado_reserva: searchParams.get('estado_reserva'),
      razon_no_disponibilidad: searchParams.get('razon_no_disponibilidad'),
      confirmPaymentCodeApp: searchParams.get('confirmPaymentCodeApp'),
    }
  }, []);

  console.log(paramsAppointmentApp)


  // Consultar datos de credito item seleccionado por el usuario
  const loadCreditItem = useCallback(
    async () => {
      try {
        console.log(reservaId)
        let data;

        if (reservaId) {
          let { data: dataRes } = await dispatch(verificarReservaCreada(reservaId));
          data = dataRes;
        } else {
          let { data: dataRes } = await dispatch(verificarReserva(paramsAppointmentApp));
          data = dataRes;
        }

        console.log(data)

        // return 
        const { transferTo, precioTotal, empresa, ...reservaData } = data;
        const { pp_identifier: appId, pp_token: token } = empresa;

        Object.keys(reservaData).forEach(key => reservaData[key] === null ? delete reservaData[key] : {});

        const searchParams = new URLSearchParams(reservaData);
        console.log(reservaData)

        const pagoInfo = `http:localhost:3000/confirmarTransaccion/123/abc?${searchParams.toString()}`
        console.log({ pagoInfo })

        setLoading(false);

        //Cargar script payphone
        loadScript(`${urlPayphoneButton}${appId}`).then(() => {
          const scriptPPButtons = document.createElement("script");
          scriptPPButtons.type = 'text/javascript';
          scriptPPButtons.id = "scriptPayphone";

          const currentTime = new Date().getTime();
          const clientTransactionId = `${currentTime}.${reservaId ?? paramsAppointmentApp.cliente_id}`;

          scriptPPButtons.innerHTML = `
            const loadFormScript = function() {
              payphone.Button({
              
                //token obtenido desde la consola de developer
                token: '${token}',
                
                //PARÁMETROS DE CONFIGURACIÓN
                btnHorizontal: true,
                btnCard: true,
                
                createOrder: function(actions){
                
                  //Se ingresan los datos de la transaccion ej. monto, impuestos, etc
                  return actions.prepare({
                  
                    amount: ${precioTotal},
                    amountWithoutTax: ${precioTotal},
                    currency: "USD",
                    clientTransactionId: '${clientTransactionId}',
                    transferTo: "${transferTo}",
            
                    lang:"es",
                  }).then(function(paramlog){
                    console.log(paramlog);
                    return paramlog;
                  }).catch(function(paramlog2){
                      console.log(paramlog2);
                      return paramlog2;
                  });
                
                },
                onComplete: function(model, actions){
                
                  //Se confirma el pago realizado
                  actions.confirm({
                    id: model.id,
                    clientTxId: model.clientTxId
                  }).then(function(value){
                  
                    //EN ESTA SECCIÓN SE RECIBE LA RESPUESTA Y SE MUESTRA AL USUARIO
                    
                    if (value.transactionStatus == "Approved"){
                      console.log("Pago " + value.transactionId + " recibido, estado " + value.transactionStatus );
                      console.log(value)
                      window.location.href = '/confirmarTransaccion/' + value.transactionId  + '/' + value.clientTransactionId + '?${searchParams.toString()}';
                    }
                  }).catch(function(err){
                    console.log(err);
                  });
              
              }
              }).render("#pp-button");
              
            }
            loadFormScript();
          `;

          document.body.appendChild(scriptPPButtons);
        });

      } catch (error) {
        setLoading(false);
        setError(error.message)
      }
    },
    [dispatch, paramsAppointmentApp, reservaId],
  )

  const loadScript = (url) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = url
      script.id = "scriptClienteId"

      document.body.appendChild(script);

      const onLoadScript = () => resolve(script);

      const onErrorScript = () => reject(new Error(`${url} failed to load.`));

      // Resolve the promise once the script is loaded
      script.addEventListener('load', onLoadScript, { once: true })

      // Catch any errors while loading the script
      script.addEventListener('error', onErrorScript, { once: true })
    })
  }

  useEffect(() => {
    loadCreditItem();

    return () => {
      // Eliminar scripts al salir página
      let script = document.getElementById("scriptClienteId");
      script.remove();

      let script2 = document.getElementById("scriptPayphone");
      script2.remove();
    }
  }, [loadCreditItem])


  return (
    <div className={classes.mainContainer}>
      <Loading title="Cargando..." loading={loading}>
        <>
          {
            !error
              ?
              // Botón de pago payphone
              <div id='pp-button' className={classes.paymentContainer}></div>
              :
              // Mensaje de error
              <Typography variant="h4" style={{ textAlign: 'center' }}>
                {error}
              </Typography>
          }
        </>
      </Loading>
    </div>
  );
}
