import { withStyles } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { dangerColor } from 'assets/jss/material-dashboard-react';

export const KeyboardDatePickerCustom = withStyles({
  root: {
    '& label.Mui-error': {
      color: dangerColor[0],
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderBottomColor: dangerColor[0],
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-error fieldset': {
        borderColor: dangerColor[0],
      },
    },
  },
})(KeyboardDatePicker);