import { passwordValidationMessage } from "constants/constants";
import { rucValidationMessage } from "constants/validations/validationsMessages";
import moment from "moment";

export const validarCedulaPasaporte = (value) => {
  if (!value || (value.trim().length > 10 || value.trim().length < 9)) return false;
  return true;
}

export const validarRUC = (value) => {
  if (value.trim().length !== 13) return false;
  return true;
}

export const validarCorreo = (value) => {
  if (!value || !/\S+@\S+\.\S+/.test(value)) return false;
  return true;
}

export const validarCelular = (value) => {
  if (!value || value.length < 10) return false;
  return true;
}

export const validarPassword = (value) => (value.length < 5 || !/.*^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/.test(value))
  ? false : true;


//Blogs y noticias
export const validarBlogIntroduccion = (value) => {
  var tempDivElement = document.createElement("div");
  tempDivElement.innerHTML = value;
  //Html to plain text
  const textFormatted = tempDivElement.textContent || tempDivElement.innerText || "";
  if (textFormatted.trim().length > 200) return false;
  return true;
}


export const validacionesPerfil = valores => {
  let errors = {};
  if (!validarCedulaPasaporte(valores.cedula_identidad)) {
    errors.cedula_identidad = 'Cédula de identidad es obligatoria y debe tener entre 9 y 10 caracteres';
  }
  if (!valores.titulo_honorifico) {
    errors.titulo_honorifico = 'Titulo honorifico es obligatorio';
  } else if (!/\S+/.test(valores.titulo_honorifico)) {
    errors.titulo_honorifico = 'Formato incorrecto debe empezar con mayúscula (Dr.)';
  }
  if (!valores.email) {
    errors.email = 'Email es obligatorio';
  } else if (!/\S+@\S+\.\S+/.test(valores.email)) {
    errors.email = 'Formato de email es incorrecto';
  }
  if (!valores.nombres) {
    errors.nombres = 'Nombres es obligatorio';
  } else if (!/\S+\W*\S+/.test(valores.nombres)) {
    errors.nombres = 'Nombres es incorrecto';
  }
  if (!valores.apellidos) {
    errors.apellidos = 'Apellidos es obligatorio';
  } else if (!/\S+\W*\S+/.test(valores.apellidos)) {
    errors.apellidos = 'Apellidos es incorrecto';
  }
  if (!valores.numero_telefono) {
    errors.numero_telefono = 'Número de teléfono es obligatorio';
  } else if (!/[+]*\d+$/.test(valores.numero_telefono)) {
    errors.numero_telefono = 'Número de teléfono es incorrecto, solo números';
  }
  if (!valores.practica_desde) {
    errors.practica_desde = 'Fecha de practica profesional es obligatoria';
  } else if (valores.practica_desde === 'Fecha invalida') {
    errors.practica_desde = 'Fecha de practica profesional es obligatoria';
  }
  if (valores.declaracion_profesional && valores.declaracion_profesional.length > 75) {
    errors.declaracion_profesional = 'Declaración profesional no debe tener mas de 75 caracteres';
  }
  if (!valores.msp) {
    errors.msp = 'El código es obligatorio';
  }
  //Revisar creo que está mal el redux al actualizar
  // if (valores.especializaciones.length === 0) {
  //   errors.especializaciones = 'Al menos una especialidad';
  // }
  return errors;
};

export const validacionesCliente = valores => {
  let errors = {};

  if (!valores.tituloHonorifico) {
    errors.tituloHonorifico = 'Título honorifico es obligatorio';
  }
  if (!validarCedulaPasaporte(valores.cedulaIdentidad)) {
    errors.cedulaIdentidad = 'Cédula de identidad es obligatorio y debe tener entre 9 y 10 caracteressss';
  }
  if (valores.cedulaRepresentante && valores.cedulaRepresentante.trim().length !== 10) {
    errors.cedulaRepresentante = 'Cédula de representante debe tener 10 caracteres';
  }
  if (!valores.email) {
    errors.email = 'Email es obligatorio';
  } else if (!/\S+@\S+\.\S+/.test(valores.email)) {
    errors.email = 'Formato de email es incorrecto';
  }
  if (!valores.nombres) {
    errors.nombres = 'Nombres es obligatorio';
  } else if (!/\S+\W*\S+/.test(valores.nombres)) {
    errors.nombres = 'Nombres es incorrecto';
  }
  if (!valores.apellidos) {
    errors.apellidos = 'Apellidos es obligatorio';
  } else if (!/\S+\W*\S+/.test(valores.apellidos)) {
    errors.apellidos = 'Apellidos es incorrecto';
  }
  if (valores.telefono && !/[+]*\d+$/.test(valores.telefono)) {
    errors.telefono = 'Número de teléfono es incorrecto, solo números';
  }
  if (!valores.celular) {
    errors.celular = 'Número de celular es obligatorio';
  } else if (!/[+]*\d+$/.test(valores.celular)) {
    errors.celular = 'Número de celular es incorrecto, solo números';
  } else if (valores.celular.length < 10) {
    errors.celular = 'El número de celular debe tener al menos 10 dígitos';
  }
  // if (!valores.direccion) {
  //   errors.direccion = 'Dirección es obligatoria';
  // } 
  return errors;
};
export const validacionesPerfilSecretaria = valores => {
  let errors = {};

  if (!valores.titulo_honorifico) {
    errors.titulo_honorifico = 'Titulo honorifico es obligatorio';
  } else if (!/\S+/.test(valores.titulo_honorifico)) {
    errors.titulo_honorifico = 'Formato incorrecto debe empezar con mayúscula (Dr.)';
  }
  if (!valores.email) {
    errors.email = 'Email es obligatorio';
  } else if (!/\S+@\S+\.\S+/.test(valores.email)) {
    errors.email = 'Formato de email es incorrecto';
  }
  if (!valores.nombres) {
    errors.nombres = 'Nombres es obligatorio';
  } else if (!/\S+\W*\S+/.test(valores.nombres)) {
    errors.nombres = 'Nombres es incorrecto';
  }
  if (!valores.apellidos) {
    errors.apellidos = 'Apellidos es obligatorio';
  } else if (!/\S+\W*\S+/.test(valores.apellidos)) {
    errors.apellidos = 'Apellidos es incorrecto';
  }
  // if (!valores.numero_telefono) {
  //   errors.numero_telefono = 'Número de teléfono es obligatorio';
  // } else if (!/[+]*\d+$/.test(valores.numero_telefono)) {
  //   errors.numero_telefono = 'Número de teléfono es incorrecto, solo números';
  // }

  return errors;
};
export const validacionesOficina = (valores) => {
  let errors = {};

  if (!valores.tiempo_por_cliente_minutos) {
    errors.tiempo_por_cliente_minutos = 'El tiempo por paciente es obligatorio';
  } else if (!/\d+$/.test(valores.tiempo_por_cliente_minutos)) {
    errors.tiempo_por_cliente_minutos = 'El tiempo por paciente tiene que ser solamente números';
  }

  if (!valores.costo_consulta) {
    errors.costo_consulta = 'El costo de consulta es obligatorio';
  } else if (!/^\d*\.?\d+$/.test(valores.costo_consulta)) {
    errors.costo_consulta = 'El costo de consulta tiene que ser en números';
  }

  if (!valores.nombre) {
    errors.nombre = 'El nombre es obligatorio';
  }
  if (!valores.direccion) {
    errors.direccion = 'La dirección es obligatoria';
  }
  if (!valores.ciudad) {
    errors.ciudad = 'La ciudad es obligatoria';
  }
  if (!valores.provincia) {
    errors.provincia = 'La provincia es obligatoria';
  }
  if (!valores.pais) {
    errors.pais = 'El pais es obligatorio';
  }

  if (!valores.referencias_fisicas) {
    errors.referencias_fisicas = 'Las referencias fisicas son obligatorias';
  }
  return errors;
};

export const validacionesServicio = valores => {
  let errors = {};

  if (!valores.nombre) errors.nombre = 'El nombre es obligatorio';

  if (!valores.doctorEspecializacion) errors.doctorEspecializacion = 'La especialización es obligatoria';

  if (!/^\d*\.?\d+$/.test(valores.precio)) {
    errors.precio = 'El valor del precio que ser un valor numérico';
  }

  if (!/\d+$/.test(valores.descuento_valor) || (parseInt(valores.descuento_valor) > 100 || parseInt(valores.descuento_valor) < 0)) {
    errors.descuento_valor = 'El valor de descuento debe ser un valor numérico entre 0 y 100';
  }

  return errors;
};

export const validacionesServicio2 = valores => {
  // console.log({valores})
  let errors = {};

  if (!valores.nombre) {
    errors.nombre = 'El nombre es obligatorio';
  }

  if (!valores.promocion_detalles || valores.promocion_detalles.length > 200) {
    errors.promocion_detalles = 'El campo promoción detalles es obligatorio y debe tener máximo 200 caracteres';
  }

  if (!valores.descuento_inicio) {
    errors.promocion_detalles = 'El campo fecha de inicio es obligatorio';
  }

  if (!valores.descuento_fin) {
    errors.promocion_detalles = 'El campo fecha de finalización es obligatorio';
  }

  if (!moment(valores.descuento_fin).isAfter(moment(valores.descuento_inicio))) {
    errors.descuento_inicio = 'La fecha final debe ser mayor a la fecha inicial';
  }

  if (moment(valores.descuento_inicio).isBefore(moment(), 'date')) {
    errors.descuento_inicio = 'La fecha de inicio debe ser mayor a la fecha actual';
  }
  if (moment(valores.descuento_fin).isBefore(moment(), 'date')) {
    errors.descuento_fin = 'La fecha de finalización debe ser mayor a la fecha actual';
  }

  // console.log({cero: parseInt(valores.descuento_valor)})
  if (!/^\d+$/.test(valores.descuento_valor) || (parseInt(valores.descuento_valor) > 100 || parseInt(valores.descuento_valor) < 1)) {
    errors.descuento_valor = 'El valor de descuento debe ser un valor entero entre 1 y 100';
  }

  return errors;
};

export const validacionesVadecum = valores => {
  let errors = {};

  if (!valores.nombre) {
    errors.nombre = 'El nombre es obligatorio';
  }
  if (!valores.composicion) {
    errors.composicion = 'La composicion es obligatoria';
  }
  if (!valores.funcion) {
    errors.funcion = 'La funcion es obligatoria';
  }
  if (!valores.presentacion) {
    errors.presentacion = 'La presentación es obligatoria';
  }
  if (!valores.dosificacion) {
    errors.dosificacion = 'La dosificación es obligatoria';
  }
  if (!valores.casa_comercial) {
    errors.casa_comercial = 'La casa comercial es obligatoria';
  }
  if (!valores.contraindicaciones) {
    errors.contraindicaciones = 'Las contraindicaciones fisicas son obligatorias';
  }

  return errors;
};

export const validacionesMedidas = valores => {
  let errors = {};

  if (!valores.talla) {
    errors.talla = 'La talla es obligatorio';
  } else if (!/^\d*\.?\d+$/.test(valores.talla)) {
    errors.talla = 'La talla tiene que ser en números';
  }
  if (!valores.peso) {
    errors.peso = 'El peso es obligatorio';
  } else if (!/^\d*\.?\d+$/.test(valores.peso)) {
    errors.peso = 'El peso tiene que ser en números';
  }
  if (!valores.alergias) {
    errors.alergias = 'El campo de alergias es obligatorio';
  }
  // if (!valores.pa) {
  //   errors.pa = 'El P.A. es obligatorio';
  // }
  // if (!valores.cie) {
  //   errors.cie = 'El CIE10 es obligatorio';
  // }
  return errors;
};

export const validacionesReceta = valores => {
  let errors = [];

  valores.map((valor, index) => {
    errors.push({});
    // let flagIsValid = true;
    if (!valor.cantidad) {
      errors[index].cantidad = 'La cantidad es obligatoria';
      // flagIsValid = false;
    }
    if (!valor.dosificacion) {
      errors[index].dosificacion = 'El campo detalles de dosis es obligatoria';
      // flagIsValid = false;
    }

    if (valor.dias_uso && (!parseInt(valor.dias_uso) || parseInt(valor.dias_uso) < 1)) {
      errors[index].dias_uso = 'El valor de dias de uso debe ser un número entero positivo';
    }
    if (valor.frecuencia_uso_horas && (!parseInt(valor.frecuencia_uso_horas) || parseInt(valor.frecuencia_uso_horas) < 1)) {
      errors[index].frecuencia_uso_horas = 'El valor debe ser un número entero positivo';
    }

    // if(valor.dias_uso && !valor.frecuencia_uso_horas) {
    //   errors[index].frecuencia_uso_horas = 'El valor es obligatorio si se ingresa un valor en el campo "Días"';
    // }

    // if(valor.frecuencia_uso_horas && !valor.dias_uso) {
    //   errors[index].dias_uso = 'El valor es obligatorio si se ingresa un valor en el campo "Cada (horas)"';
    // }

    return null;
  });
  return errors;
};

//Validaciones nuevo rol
export const validacionesRolDoctor = valores => {
  let errors = {};

  if (!validarCedulaPasaporte(valores.cedula_identidad))
    errors.cedula_identidad = 'Cédula de identidad es obligatoria y debe tener entre 9 y 10 caracteres';

  if (!valores.nombres) {
    errors.nombres = 'Nombres es obligatorio';
  } else if (!/\S+\W*\S+/.test(valores.nombres)) {
    errors.nombres = 'Nombres es incorrecto';
  }
  if (!valores.apellidos) {
    errors.apellidos = 'Apellidos es obligatorio';
  } else if (!/\S+\W*\S+/.test(valores.apellidos)) {
    errors.apellidos = 'Apellidos es incorrecto';
  }
  if (!valores.msp) {
    errors.msp = 'El código senescyt es obligatorio';
  }
  return errors;
};

export const validacionesRolSecretaria = valores => {
  let errors = {};

  if (!validarCedulaPasaporte(valores.cedula_identidad))
    errors.cedula_identidad = 'Cédula de identidad es obligatoria y debe tener entre 9 y 10 caracteres';

  if (!valores.nombres) {
    errors.nombres = 'Nombres es obligatorio';
  } else if (!/\S+\W*\S+/.test(valores.nombres)) {
    errors.nombres = 'Nombres es incorrecto';
  }
  if (!valores.apellidos) {
    errors.apellidos = 'Apellidos es obligatorio';
  } else if (!/\S+\W*\S+/.test(valores.apellidos)) {
    errors.apellidos = 'Apellidos es incorrecto';
  }

  return errors;
};

// Validaciones registros de cajas
export const validacionesCaja = data => {
  let errors = {};

  if (!data.c_cedula_identidad)
    errors.c_cedula_identidad = 'Cédula de identidad es obligatorio';


  if (!data.r_hora_pago)
    errors.r_hora_pago = 'Hora es un campo obligatorio';


  return (Object.keys(errors).length > 0) ? errors : undefined;

};

// React hook validation rules
export const rhRequiredFieldValidation = {
  required: {
    value: true,
    message: "El campo es requerido",
  }
}

export const rhRequiredEmailValidation = {
  ...rhRequiredFieldValidation,
  validate: (value) => !validarCorreo(value) ? 'El email no tiene un formato válido' : true
}

export const rhPasswordValidation = {
  ...rhRequiredFieldValidation,
  validate: (value) => !validarPassword(value) ? passwordValidationMessage : true
}

export const rhRUCValidation = {
  ...rhRequiredFieldValidation,
  validate: (value) => !validarRUC(value) ? rucValidationMessage : true
}

export const rhConfirmPasswordValidation = (watch) => ({
  ...rhRequiredFieldValidation,
  validate: (value) => {
    if (watch('password') !== value)
      return "Las contraseña no coinciden";
    return true;
  }
})