import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { FormInputControlled } from '../../reactHookForm/FormElementsControlled';
import { rhRequiredFieldValidation } from 'utils/validaciones';
import Notificacion from 'components/ui/Notificacion';
import Loading from 'components/Loading';
import { useDispatch } from 'react-redux';
import { confirmLoginDeleteAccount } from 'redux/actions/aLogin';

export const LoginForm = ({ deleteAccount = false, title }) => {

  const { register, handleSubmit, formState: { errors }, control } = useForm();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      let dataToSend = {
        username: data.username.trim(),
        password: data.password.trim(),
      };

      if (deleteAccount) {
        const response = await dispatch(confirmLoginDeleteAccount(dataToSend));
        setLoading(false);
        if (response.transaccion) setSuccess(true);

      } else {
        // Enviar datos de login
        // dispatch(loginFetch(data, history));
      }

    } catch (error) {
      setLoading(false);
      Notificacion({
        type: "error",
        text: 'Ocurrio un error, intente nuevamente',
      });
    }

  }

  return (
    <>
      {
        title && !success &&
        <h3>{title}</h3>
      }
      <Loading loading={loading} title='Eliminando cuenta de usuario...'>
        <form onSubmit={handleSubmit(onSubmit)}>
          {
            !success ?
              <>
                {/* Nombres */}
                <FormInputControlled
                  id={'username'}
                  label={'Nombre de usuario/Cédula:'}
                  control={control}
                  name={'username'}
                  errors={errors}
                  register={register}
                  formInputType={'input'}
                  rules={rhRequiredFieldValidation}
                />

                {/* Password */}
                <FormInputControlled
                  id='password'
                  label='Contraseña:'
                  control={control}
                  name='password'
                  errors={errors}
                  register={register}
                  formInputType='input'
                  type='password'
                  rules={rhRequiredFieldValidation}
                />

                <button className="registrarse" type="submit">Confirmar</button>
              </>
              :
              (
                <div className='message-container' style={{ padding: '30px', paddingTop: '140px' }}>
                  <>
                    <h1>Eliminación de cuenta</h1>
                    <h3>
                      Su cuenta ha sido eliminada correctamente
                    </h3>
                  </>
                </div>
              )
          }

        </form>
      </Loading>

    </>
  )
}
