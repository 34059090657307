import React, { useState } from 'react'
import { FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, Tooltip, makeStyles, withStyles } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import ClearIcon from '@material-ui/icons/Clear';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/ReactHookForm/CustomInput";
import { Controller } from "react-hook-form";
import { primaryColor } from "assets/jss/material-dashboard-react.js";
import { ModalContainer } from 'components/Modal/ModalContainer';
import Button from "components/CustomButtons/Button.js";
import { DoctorBilling } from 'components/Billing/DoctorBilling';

const RadioButton = withStyles({
  root: {
    '&$checked': {
      color: primaryColor[0],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const useStyles = makeStyles(() => ({
  formControl: {
    width: "100%",
    marginBottom: "25px",
  },
  radio: {
    marginLeft: '5px'
  },
  inputLabel: {
    fontSize: '14px !important',
  },
  hr: {
    border: '1px solid #e6e6e6',
    maxWidth: '800px',
    width: 'unset !important',
    marginTop: '20px',
    marginBottom: '20px',
  },
  inputTextfield: {
    color: 'black',
    fontWeight: '700',
    fontSize: '14px',
  }
}));

export const ConsultaForm = ({ control, errors, handleResetExamenOrganosSistemas, handleResetExamenFisicoRegional, consultaSelected }) => {

  const classes = useStyles();

  console.log({ consultaSelected })

  const [openModalBilling, setOpenModalBilling] = useState(false);

  return (
    <div style={{ width: '100%' }}>
      <GridContainer>
        {/* Motivo de la consulta */}
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            formControlProps={{
              error: true,
              className: classes.formControl
            }}
            name={`motivo`}
            control={control}
            defaultValue={''}
            as={<TextField
              InputLabelProps={{
                className: classes.inputTextfield
              }}
            />}
            error={errors[`motivo`] ? true : false}
            helperText={""}
            label="Motivo de la consulta"
            style={{ margin: 8, color: 'red' }}
            fullWidth
            margin="normal"
            multiline
            minRows={4}
            errors={errors}
          />
        </GridItem>

        {/* Enfermedad actual */}
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            formControlProps={{
              error: true,
              className: classes.formControl
            }}
            name={`enfermedad_actual`}
            control={control}
            defaultValue={''}
            as={<TextField
              InputLabelProps={{
                className: classes.inputTextfield
              }}
            />}
            error={errors[`enfermedad_actual`] ? true : false}
            helperText={""}
            label="Enfermedad actual"
            style={{ margin: 8 }}
            fullWidth
            margin="normal"
            multiline
            minRows={4}
            errors={errors}
          />
        </GridItem>
      </GridContainer>

      <div></div>

      <hr className={classes.hr} />

      {/* Examen Órganos y Sistemas */}
      <div style={{ marginBottom: '10px' }}>
        <strong>Examen Órganos y Sistemas</strong>
        <Tooltip title="Resetear sección examen órganos y sistemas">
          <IconButton onClick={handleResetExamenOrganosSistemas}>
            <ClearIcon />
          </IconButton>
        </Tooltip>
        <p style={{ margin: '2px' }}>CP = Con evidencia patológica | SP = Sin evidencia patológica</p>
      </div>

      <GridContainer spacing={2}>

        {/* Organos de los sentidos */}
        <GridItem xs={12} sm={12} md={2}>
          <FormControl className={classes.formControl2}>
            <FormLabel htmlFor={'exa_organos_sentidos'} size="normal" className={classes.inputLabel} >
              {'Organos de los sentidos'}
            </FormLabel>
            <Controller
              as={
                <RadioGroup >
                  <FormControlLabel
                    value={'CP'}
                    control={<RadioButton />}
                    color="primary"
                    label={'CP'}
                    className={classes.radio}
                  />
                  <FormControlLabel
                    value={'SP'}
                    control={<RadioButton />}
                    label={'SP'}
                    className={classes.radio}
                  />
                </RadioGroup>
              }
              name={'exa_organos_sentidos'}
              row
              defaultValue={null}
              control={control}
            />
          </FormControl>
        </GridItem>

        {/* Respiratorio */}
        <GridItem xs={12} sm={12} md={2}>
          <FormControl className={classes.formControl2}>
            <FormLabel htmlFor={'exa_respiratorio'} size="normal" className={classes.inputLabel} >
              {'Respiratorio'}
            </FormLabel>
            <Controller
              as={
                <RadioGroup >
                  <FormControlLabel
                    value={'CP'}
                    control={<RadioButton />}
                    color="primary"
                    label={'CP'}
                    className={classes.radio}
                  />
                  <FormControlLabel
                    value={'SP'}
                    control={<RadioButton />}
                    label={'SP'}
                    className={classes.radio}
                  />
                </RadioGroup>
              }
              name={'exa_respiratorio'}
              row
              defaultValue={null}
              control={control}
            />
          </FormControl>
        </GridItem>

        {/* Cardiovascular */}
        <GridItem xs={12} sm={12} md={2}>
          <FormControl className={classes.formControl2}>
            <FormLabel htmlFor={'exa_cardiovascular'} size="normal" className={classes.inputLabel} >
              {'Cardiovascular'}
            </FormLabel>
            <Controller
              as={
                <RadioGroup >
                  <FormControlLabel
                    value={'CP'}
                    control={<RadioButton />}
                    color="primary"
                    label={'CP'}
                    className={classes.radio}
                  />
                  <FormControlLabel
                    value={'SP'}
                    control={<RadioButton />}
                    label={'SP'}
                    className={classes.radio}
                  />
                </RadioGroup>
              }
              name={'exa_cardiovascular'}
              row
              defaultValue={null}
              control={control}
            />
          </FormControl>
        </GridItem>

        {/* Digestivo */}
        <GridItem xs={12} sm={12} md={2}>
          <FormControl className={classes.formControl2}>
            <FormLabel htmlFor={'exa_digestivo'} size="normal" className={classes.inputLabel} >
              {'Digestivo'}
            </FormLabel>
            <Controller
              as={
                <RadioGroup >
                  <FormControlLabel
                    value={'CP'}
                    control={<RadioButton />}
                    color="primary"
                    label={'CP'}
                    className={classes.radio}
                  />
                  <FormControlLabel
                    value={'SP'}
                    control={<RadioButton />}
                    label={'SP'}
                    className={classes.radio}
                  />
                </RadioGroup>
              }
              name={'exa_digestivo'}
              row
              defaultValue={null}
              control={control}
            />
          </FormControl>
        </GridItem>

        {/* Genital */}
        <GridItem xs={12} sm={12} md={2}>
          <FormControl className={classes.formControl2}>
            <FormLabel htmlFor={'exa_genital'} size="normal" className={classes.inputLabel} >
              {'Genital'}
            </FormLabel>
            <Controller
              as={
                <RadioGroup >
                  <FormControlLabel
                    value={'CP'}
                    control={<RadioButton />}
                    color="primary"
                    label={'CP'}
                    className={classes.radio}
                  />
                  <FormControlLabel
                    value={'SP'}
                    control={<RadioButton />}
                    label={'SP'}
                    className={classes.radio}
                  />
                </RadioGroup>
              }
              name={'exa_genital'}
              row
              defaultValue={null}
              control={control}
            />
          </FormControl>
        </GridItem>

        {/* Urinario */}
        <GridItem xs={12} sm={12} md={2}>
          <FormControl className={classes.formControl2}>
            <FormLabel htmlFor={'exa_urinario'} size="normal" className={classes.inputLabel} >
              {'Urinario'}
            </FormLabel>
            <Controller
              as={
                <RadioGroup >
                  <FormControlLabel
                    value={'CP'}
                    control={<RadioButton />}
                    color="primary"
                    label={'CP'}
                    className={classes.radio}
                  />
                  <FormControlLabel
                    value={'SP'}
                    control={<RadioButton />}
                    label={'SP'}
                    className={classes.radio}
                  />
                </RadioGroup>
              }
              name={'exa_urinario'}
              row
              defaultValue={null}
              control={control}
            />
          </FormControl>
        </GridItem>

        {/* Músculo Esquelético */}
        <GridItem xs={12} sm={12} md={2}>
          <FormControl className={classes.formControl2}>
            <FormLabel htmlFor={'exa_musculo_esqueletico'} size="normal" className={classes.inputLabel} >
              {'Músculo Esquelético'}
            </FormLabel>
            <Controller
              as={
                <RadioGroup >
                  <FormControlLabel
                    value={'CP'}
                    control={<RadioButton />}
                    color="primary"
                    label={'CP'}
                    className={classes.radio}
                  />
                  <FormControlLabel
                    value={'SP'}
                    control={<RadioButton />}
                    label={'SP'}
                    className={classes.radio}
                  />
                </RadioGroup>
              }
              name={'exa_musculo_esqueletico'}
              row
              defaultValue={null}
              control={control}
            />
          </FormControl>
        </GridItem>

        {/* Endócrino */}
        <GridItem xs={12} sm={12} md={2}>
          <FormControl className={classes.formControl2}>
            <FormLabel htmlFor={'exa_endocrino'} size="normal" className={classes.inputLabel} >
              {'Endócrino'}
            </FormLabel>
            <Controller
              as={
                <RadioGroup >
                  <FormControlLabel
                    value={'CP'}
                    control={<RadioButton />}
                    color="primary"
                    label={'CP'}
                    className={classes.radio}
                  />
                  <FormControlLabel
                    value={'SP'}
                    control={<RadioButton />}
                    label={'SP'}
                    className={classes.radio}
                  />
                </RadioGroup>
              }
              name={'exa_endocrino'}
              row
              defaultValue={null}
              control={control}
            />
          </FormControl>
        </GridItem>


        {/* Hemo Linfático */}
        <GridItem xs={12} sm={12} md={2}>
          <FormControl className={classes.formControl2}>
            <FormLabel htmlFor={'exa_hemo_linfatico'} size="normal" className={classes.inputLabel} >
              {'Hemo Linfático'}
            </FormLabel>
            <Controller
              as={
                <RadioGroup >
                  <FormControlLabel
                    value={'CP'}
                    control={<RadioButton />}
                    color="primary"
                    label={'CP'}
                    className={classes.radio}
                  // checked={false} 
                  />
                  <FormControlLabel
                    value={'SP'}
                    control={<RadioButton />}
                    label={'SP'}
                    className={classes.radio}
                  // checked={opt.checked}
                  />
                </RadioGroup>
              }
              name={'exa_hemo_linfatico'}
              row
              defaultValue={null}
              control={control}
            />
          </FormControl>
        </GridItem>

        {/* Nervioso */}
        <GridItem xs={12} sm={12} md={2} >
          <FormControl className={classes.formControl2}>
            <FormLabel htmlFor={'exa_nervioso'} size="normal" className={classes.inputLabel} >
              {'Nervioso'}
            </FormLabel>
            <Controller
              as={
                <RadioGroup >
                  <FormControlLabel
                    value={'CP'}
                    control={<RadioButton />}
                    color="primary"
                    label={'CP'}
                    className={classes.radio}
                  />
                  <FormControlLabel
                    value={'SP'}
                    control={<RadioButton />}
                    label={'SP'}
                    className={classes.radio}
                  />
                </RadioGroup>
              }
              name={'exa_nervioso'}
              row
              defaultValue={null}
              control={control}
            />
          </FormControl>
        </GridItem>

      </GridContainer>

      <div>
        <CustomInput
          formControlProps={{
            error: true,
            className: classes.formControl
          }}
          name={`exa_organos_sistemas_desc`}
          control={control}
          defaultValue={''}
          as={<TextField />}
          error={errors[`exa_organos_sistemas_desc`] ? true : false}
          helperText={""}
          label="Descripción"
          style={{ margin: 8 }}
          fullWidth
          margin="normal"
          multiline
          minRows={4}
          errors={errors}
        />
      </div>

      {/* Fin Examen Órganos y Sistemas */}

      {/* Examen Físico Regional */}
      <div style={{ marginBottom: '10px' }}>
        <strong>Examen Físico Regional</strong>
        <Tooltip title="Resetear sección examen físico regional">
          <IconButton onClick={handleResetExamenFisicoRegional}>
            <ClearIcon />
          </IconButton>
        </Tooltip>
        <p style={{ margin: '2px' }}>CP = Con evidencia patológica | SP = Sin evidencia patológica</p>
      </div>

      <GridContainer spacing={2}>

        {/* Cabeza */}
        <GridItem xs={12} sm={12} md={2} >
          <FormControl className={classes.formControl2}>
            <FormLabel htmlFor={'exa_cabeza'} size="normal" className={classes.inputLabel} >
              {'Cabeza'}
            </FormLabel>
            <Controller
              as={
                <RadioGroup >
                  <FormControlLabel
                    value={'CP'}
                    control={<RadioButton />}
                    color="primary"
                    label={'CP'}
                    className={classes.radio}
                  />
                  <FormControlLabel
                    value={'SP'}
                    control={<RadioButton />}
                    label={'SP'}
                    className={classes.radio}
                  />
                </RadioGroup>
              }
              name={'exa_cabeza'}
              row
              defaultValue={null}
              control={control}
            />
          </FormControl>
        </GridItem>

        {/* Cuello */}
        <GridItem xs={12} sm={12} md={2} >
          <FormControl className={classes.formControl2}>
            <FormLabel htmlFor={'exa_cuello'} size="normal" className={classes.inputLabel} >
              {'Cuello'}
            </FormLabel>
            <Controller
              as={
                <RadioGroup >
                  <FormControlLabel
                    value={'CP'}
                    control={<RadioButton />}
                    color="primary"
                    label={'CP'}
                    className={classes.radio}
                  />
                  <FormControlLabel
                    value={'SP'}
                    control={<RadioButton />}
                    label={'SP'}
                    className={classes.radio}
                  />
                </RadioGroup>
              }
              name={'exa_cuello'}
              row
              defaultValue={null}
              control={control}
            />
          </FormControl>
        </GridItem>

        {/* Tórax */}
        <GridItem xs={12} sm={12} md={2} >
          <FormControl className={classes.formControl2}>
            <FormLabel htmlFor={'exa_torax'} size="normal" className={classes.inputLabel} >
              {'Tórax'}
            </FormLabel>
            <Controller
              as={
                <RadioGroup >
                  <FormControlLabel
                    value={'CP'}
                    control={<RadioButton />}
                    color="primary"
                    label={'CP'}
                    className={classes.radio}
                  />
                  <FormControlLabel
                    value={'SP'}
                    control={<RadioButton />}
                    label={'SP'}
                    className={classes.radio}
                  />
                </RadioGroup>
              }
              name={'exa_torax'}
              row
              defaultValue={null}
              control={control}
            />
          </FormControl>
        </GridItem>

        {/* Abdomen */}
        <GridItem xs={12} sm={12} md={2} >
          <FormControl className={classes.formControl2}>
            <FormLabel htmlFor={'exa_abdomen'} size="normal" className={classes.inputLabel} >
              {'Abdomen'}
            </FormLabel>
            <Controller
              as={
                <RadioGroup >
                  <FormControlLabel
                    value={'CP'}
                    control={<RadioButton />}
                    color="primary"
                    label={'CP'}
                    className={classes.radio}
                  />
                  <FormControlLabel
                    value={'SP'}
                    control={<RadioButton />}
                    label={'SP'}
                    className={classes.radio}
                  />
                </RadioGroup>
              }
              name={'exa_abdomen'}
              row
              defaultValue={null}
              control={control}
            />
          </FormControl>
        </GridItem>

        {/* Pelvis */}
        <GridItem xs={12} sm={12} md={2} >
          <FormControl className={classes.formControl2}>
            <FormLabel htmlFor={'exa_pelvis'} size="normal" className={classes.inputLabel} >
              {'Pelvis'}
            </FormLabel>
            <Controller
              as={
                <RadioGroup >
                  <FormControlLabel
                    value={'CP'}
                    control={<RadioButton />}
                    color="primary"
                    label={'CP'}
                    className={classes.radio}
                  />
                  <FormControlLabel
                    value={'SP'}
                    control={<RadioButton />}
                    label={'SP'}
                    className={classes.radio}
                  />
                </RadioGroup>
              }
              name={'exa_pelvis'}
              row
              defaultValue={null}
              control={control}
            />
          </FormControl>
        </GridItem>

        {/* Extremidades */}
        <GridItem xs={12} sm={12} md={2} >
          <FormControl className={classes.formControl2}>
            <FormLabel htmlFor={'exa_extremidades'} size="normal" className={classes.inputLabel} >
              {'Extremidades'}
            </FormLabel>
            <Controller
              as={
                <RadioGroup >
                  <FormControlLabel
                    value={'CP'}
                    control={<RadioButton />}
                    color="primary"
                    label={'CP'}
                    className={classes.radio}
                  />
                  <FormControlLabel
                    value={'SP'}
                    control={<RadioButton />}
                    label={'SP'}
                    className={classes.radio}
                  />
                </RadioGroup>
              }
              name={'exa_extremidades'}
              row
              defaultValue={null}
              control={control}
            />
          </FormControl>
        </GridItem>

      </GridContainer>

      <div>
        <CustomInput
          formControlProps={{
            error: true,
            className: classes.formControl
          }}
          name={`exa_fisico_regional_desc`}
          control={control}
          defaultValue={''}
          as={<TextField />}
          error={errors[`exa_fisico_regional_desc`] ? true : false}
          helperText={""}
          label="Descripción"
          style={{ margin: 8 }}
          fullWidth
          margin="normal"
          multiline
          minRows={4}
          errors={errors}
        />
      </div>

      {/* Fin examen Físico Regional */}

      {/* Plan de tratamiento */}
      <div>
        <CustomInput
          formControlProps={{
            error: true,
            className: classes.formControl
          }}
          name={`plan_tratamiento`}
          control={control}
          defaultValue={''}
          as={<TextField
            InputLabelProps={{
              className: classes.inputTextfield
            }}
          />}
          error={errors[`plan_tratamiento`] ? true : false}
          helperText={""}
          label="Plan de Tratamiento"
          style={{ margin: 8 }}
          fullWidth
          margin="normal"
          multiline
          minRows={4}
          errors={errors}
        />
      </div>

      {/* Facturación electrónica */}
      {
        consultaSelected && false &&
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button color="primary" onClick={() => setOpenModalBilling(true)}>Facturación</Button>
        </div>
      }

      <ModalContainer
        open={openModalBilling}
        onCloseModal={() => setOpenModalBilling(false)}
        title={'Sistema de facturación'}
      >
        <DoctorBilling consultaId={consultaSelected?.id} />
      </ModalContainer>
    </div>
  )
}
