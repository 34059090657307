
// Reservas default view
export const getReservasDefaultView = () => {
  if (localStorage.getItem('ui')) {
    const ui = JSON.parse(localStorage.getItem('ui'));
    return ui.reservasDefaultViewMode ?? 0;
  } else return 0;
}

export const saveReservasDefaultView = (value) => {
  const uiLocalStorage = localStorage.getItem('ui');
  let uiDefault;

  // Guardar cambios en local storage
  if (uiLocalStorage) {
    uiDefault = JSON.parse(uiLocalStorage);
    uiDefault.reservasDefaultViewMode = value;
  } else {
    uiDefault = {
      reservasDefaultViewMode: value,
    }
  }

  localStorage.setItem('ui', JSON.stringify(uiDefault));
}