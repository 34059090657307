import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import { updateEmail } from 'services/user';
import { TextPage } from 'components/text/TextPage';
import { MessageTextCentered } from 'components/text/MessageTextCentered';

export const EmailUpdate = () => {
  let { codigo } = useParams();

  const [activado, setActivado] = useState(false);
  const [error, setError] = useState(false);
  const [mensaje, setMensaje] = useState("Pagina no encontrada")

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    updateEmail(codigo)
      .then(datos => {
        if (isMounted.current) {
          if (datos.transaccion) {
            setMensaje(datos.mensaje);
            setActivado(true);
          } else {
            setError(true);
          }
        }
      })
      .catch(err => {
        if (isMounted.current) {
          setError(true);
        }
      });
  }, [codigo]);

  return (
    <TextPage>
      <div className="n-banner banner">
        <div className="n-banner-empty"></div>
        <div style={{ height: '100vh' }}>
          <MessageTextCentered>
            <Loading title="Cargando..." loading={!activado && !error}>
              <>
                {activado && (
                  <>
                    <h1>Actualización de correo</h1>
                    <h3>
                      {mensaje}
                    </h3>
                  </>
                )}

                {error && (
                  <>
                    <h1>Error</h1>
                    <h3>Página no encontrada</h3>
                  </>
                )}
              </>
            </Loading>
          </MessageTextCentered>
        </div>
      </div>
    </TextPage>


  );
};
