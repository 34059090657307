import Person from "@material-ui/icons/Person";
import LocationOnIcon from "@material-ui/icons/LocationOn";
// import { InstitucionPerfil } from "Views/Institucion/InstitucionPerfil";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AyudaSoporte from "Views/AyudaSoporte";
import { AyudaSoporteIcon } from "components/CustomIcons/Sidebar/AyudaSoporteIcon";
import CerrarSesion from "../Views/CerrarSesion";
import { InsitucionConfig } from "Views/Institucion/InsitucionConfig";
import Oficinas from "components/oficina/medico/oficina.component";
import ServiciosDoctor from "Views/ServiciosDoctor";
import PromocionesDoctor from "Views/Doctor/PromocionesDoctor";
import DashboardPage from "Views/Dashboard/DashboardMedico";
import HorarioCitas from "Views/HorarioCitas";
import Reservar from "Views/Reservar";
import PacientesDoctor from "Views/PacientesDoctor";
import { MisServiciosIcon } from "components/CustomIcons/Sidebar/MisServiciosIcon";
import { GestionPromocionesIcon } from "components/CustomIcons/Sidebar/GestionPromocionesIcon";
import { CitasAgendadasIcon } from "components/CustomIcons/Sidebar/CitasAgendadasIcon";
import { HorarioCitasIcon } from "components/CustomIcons/Sidebar/HorarioCitasIcon";
import { AgendarCitasIcon } from "components/CustomIcons/Sidebar/AgendarCitasIcon";
import { PacientesIcon } from "components/CustomIcons/Sidebar/PacientesIcon";
import { InstitucionProfesionalesAsociacion } from "Views/Institucion/InstitucionProfesionalesAsociacion";

const layout = "/institucion";

const dashboardRoutes = [
  {
    path: "/citas",
    name: "Citas Agendadas",
    icon: CitasAgendadasIcon,
    component: DashboardPage,
    layout,
  },
  {
    path: "/pacientes-lista",
    name: "Mis Pacientes",
    icon: PacientesIcon,
    component: PacientesDoctor,
    layout,
  },
  {
    path: "/reservaciones",
    name: "Agendar Citas",
    icon: AgendarCitasIcon,
    component: Reservar,
    layout,
  },
  {
    path: "/promociones",
    name: "Campañas",
    icon: GestionPromocionesIcon,
    component: PromocionesDoctor,
    layout,
  },
  {
    path: "/horario-citas",
    name: "Horarios",

    icon: HorarioCitasIcon,
    component: HorarioCitas,
    layout,
  },
  {
    path: "/servicios",
    name: "Mis servicios",
    icon: MisServiciosIcon,
    component: ServiciosDoctor,
    layout,
  },
  {
    path: "/profesionales",
    name: "Profesionales",
    icon: Person,
    component: InstitucionProfesionalesAsociacion,
    layout,
  },
  {
    path: "/oficinas",
    name: "Oficinas",
    icon: LocationOnIcon,
    component: Oficinas,
    layout,
  },
  {
    path: "/perfil",
    name: "Perfil",
    icon: Person,
    component: InsitucionConfig,
    layout,
  },
  {
    path: "/ayuda",
    name: "Ayuda y soporte",
    icon: AyudaSoporteIcon,
    component: AyudaSoporte,
    layout,
  },
  {
    path: "/cerrar-sesion",
    name: "Cerrar Sesión",
    icon: ExitToAppIcon,
    component: CerrarSesion,
    layout,
  },
];

export default dashboardRoutes;
