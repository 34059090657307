


import React, { useMemo, useState } from 'react';
import CRUD from 'components/CRUD/Crud.component';
import medicoSVG from "../../../src/assets/images/icons/medico.svg";
import { useDispatch } from 'react-redux';
import { getEspecialidadesPorBusqueda } from 'redux/actions/aEspecialidades';
import { useCallback } from 'react';
import { formatDate } from 'utils/format/format';
import { Register } from 'components/Login/Register';
import { RoleTypes } from 'types';
import { urlAdminDoctor } from 'constants/urls';

const DoctorCrud = () => {

  const fileField = React.useRef(null);

  const [especialidadesData, setEspecialidadesData] = useState([]);

  const dispatch = useDispatch();

  const handleSearchEspecialidades = useCallback(
    async (value) => {
      try {
        const { data } = await dispatch(getEspecialidadesPorBusqueda(value));
        setEspecialidadesData(data);
      } catch (error) {
      }
    },
    [dispatch],
  );

  const tableData = useMemo(() => [
    {
      type: "imageInput",
      name: "foto_nombre",
      label: "Imagen",
      imgDefault: medicoSVG,
      ref: fileField
    },
    {
      type: "inputDisabled",
      label: "Id",
      name: 'id',
      showOnCreate: false,
      showOnTable: true,
      tableProps: {
        title: 'Id',
        sort: {
          sortColumnName: 'id',
          sortColumnNameDB: 'id',
        }
      },
    },
    {
      type: "checkbox",
      name: "med_activo",
      label: "Afiliación",
      labelPlacement: "Start",
      tableProps: {
        title: 'Afiliación',
        formatData: (value) => value ? 'Afiliado' : 'No afiliado',
        sort: {
          sortColumnName: 'med_activo',
          sortColumnNameDB: 'med_activo',
        }
      },
    },
    {
      type: "input",
      name: "titulo_honorifico",
      label: "Título Honorífico",
      tableProps: {
        sort: {
          sortColumnName: 'titulo_honorifico',
          sortColumnNameDB: 'titulo_honorifico',
        }
      },
    },
    {
      type: "input",
      name: "nombres",
      label: "Nombres",
      validationOptions: {
        required: {
          value: true,
          message: 'El campo nombres es requerido',
        },
      },
      tableProps: {
        title: 'Nombres',
        sort: {
          sortColumnName: 'nombres',
          sortColumnNameDB: 'nombres',
        }
      },
    },
    {
      type: "input",
      name: "apellidos",
      label: "Apellidos",
      validationOptions: {
        required: {
          value: true,
          message: 'El campo apellidos es requerido',
        },
      },
      tableProps: {
        title: 'Apellidos',
        sort: {
          sortColumnName: 'apellidos',
          sortColumnNameDB: 'apellidos',
        }
      },
    },
    {
      type: "input",
      name: "declaracion_profesional",
      label: "Declaración Profesional",
      tableProps: {
        title: 'Declaración Profesional',
        sort: {
          sortColumnName: 'declaracion_profesional',
          sortColumnNameDB: 'declaracion_profesional',
        }
      },
    },

    {
      hideOnForm: true,
      name: "registro_date",
      label: "Fecha Registro",
      tableProps: {
        formatData: (value) => formatDate(value),
        title: 'Fecha Registro',
        sort: {
          sortColumnName: 'registro_date',
          sortColumnNameDB: 'registro_date',
        }
      },
    },

    {
      type: "date",
      name: "practica_desde",
      label: "Practica desde",
      tableProps: {
        formatData: (value) => formatDate(value),
        sort: {
          sortColumnName: 'practica_desde',
          sortColumnNameDB: 'practica_desde',
        }
      },

    },
    {
      type: "input",
      name: "numero_telefono",
      label: "Número de Teléfono",
      tableProps: {
        sort: {
          sortColumnName: 'numero_telefono',
          sortColumnNameDB: 'numero_telefono',
        }
      },
    },
    {
      type: "input",
      name: "msp",
      label: "MSP/Senescyt",
      tableProps: {
        sort: {
          sortColumnName: 'msp',
          sortColumnNameDB: 'msp',
        }
      },
    },
    {
      type: "input",
      name: "acess",
      label: "Acess",
      tableProps: {
        sort: {
          sortColumnName: 'msp',
          sortColumnNameDB: 'msp',
        }
      },
    },
    {
      name: "afiliacion_days_count",
      hideOnForm: true,
      label: "Afiliación total días",
      tableProps: {
        sort: {
          sortColumnName: 'd_afiliacion_days_count',
          sortColumnNameDB: 'd_afiliacion_days_count',
        }
      },
    },
    {
      type: "input",
      name: "afiliacion_dias_acumulados_cuenta",
      label: "Afiliación días acumulados",
      validationOptions: {
        pattern: {
          value: /^[1-9]\d*$/,
          message: "El campos debe ser un número entero positivo"
        }
      },
      tableProps: {
        sort: {
          sortColumnName: 'afiliacion_dias_acumulados_cuenta',
          sortColumnNameDB: 'afiliacion_dias_acumulados_cuenta',
        }
      },
    },
    {
      type: "date",
      name: "afiliacion_inicio_date",
      label: "Fecha inicial última afiliación",
      tableProps: {
        formatData: (value) => formatDate(value),
        sort: {
          sortColumnName: 'afiliacion_inicio_date',
          sortColumnNameDB: 'afiliacion_inicio_date',
        }
      },
    },
    {
      type: "date",
      name: "afiliacion_fin_date",
      label: "Fecha final última afiliación",
      tableProps: {
        formatData: (value) => formatDate(value),
        sort: {
          sortColumnName: 'afiliacion_fin_date',
          sortColumnNameDB: 'afiliacion_fin_date',
        }
      },
    },

    {
      type: "input",
      name: "promociones_creadas",
      label: "Promociones Creadas",
      validationOptions: {
        required: {
          value: true,
          message: 'El campo promociones Creadas es requerido',
        },
      },
      tableProps: {
        sort: {
          sortColumnName: 'promociones_creadas',
          sortColumnNameDB: 'promociones_creadas',
        }
      },
    },

    {
      type: "selectCombo",
      name: "med_visible",
      label: "Disponibilidad",
      defaultValue: null,
      options: [
        {
          value: null,
          label: "Seleccione un valor",
          disabled: true
        },
        {
          value: true,
          label: "Visible"
        },
        {
          value: false,
          label: "No Visible"
        },
      ],
      tableProps: {
        formatData: (value) => value ? 'Disponible' : 'No disponible',
        sort: {
          sortColumnName: 'med_visible',
          sortColumnNameDB: 'med_visible',
        }
      },
    },
    {
      type: "autocompleteMultipleV2",
      name: "especializaciones",
      label: "Especializaciones",
      multiple: true,
      freeSolo: false,
      dispatchFunction: handleSearchEspecialidades,
      options: especialidadesData,
    },
    {
      type: "selectCombo",
      name: "cuenta_activa",
      label: "Cuenta Aprobada",
      defaultValue: null,
      options: [
        {
          value: null,
          label: "Seleccione un valor",
          disabled: true
        },
        {
          value: true,
          label: "Aprobada"
        },
        {
          value: false,
          label: "No aprobada"
        },
      ],
      tableProps: {
        formatData: (value) => value ? 'Aprobada' : 'No aprobada',
        sort: {
          sortColumnName: 'cuenta_activa',
          sortColumnNameDB: 'cuenta_activa',
        }
      },
    },
    {
      name: "consultas_last_month_length",
      hideOnForm: true,
      label: "Consultas último mes",
      tableProps: {
        sort: {
          sortColumnName: 'consultas_last_month_length',
          sortColumnNameDB: 'consultas_last_month_length',
        }
      },
    },
  ], [especialidadesData, handleSearchEspecialidades]);


  const data = useMemo(() => ({
    defaultSortByColumn: 'registro_date',
    defaultSortOrder: 'desc',
    tableData,
    foreignData: {
      especialidadesData
    },
    urls: {
      url: urlAdminDoctor,
    }
  }), [especialidadesData, tableData])

  return (
    <CRUD data={data} CustomCreateFormComponent={() => <Register defaultRole={RoleTypes.doctor} adminMode />} />
  )
}

export default DoctorCrud;
