import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Alert, AlertTitle } from '@material-ui/lab';

import CustomTabs from 'components/CustomTabs/CustomTabs.js';

import Horario from 'components/HorarioLaboratorista/horario.component';
import { useDispatch, useSelector } from 'react-redux';
import { getHorariosOficina } from 'redux/actions/aOficinas';
import Loading from 'components/Loading';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const HorarioOficina = ({ oficinas,  }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data, isLoading, hasError } = useSelector(state => state.horariosOficinas);
  const laboratorista_id = useSelector(state => state.dataUser.data.laboratorista_id);
  useEffect(() => {
    dispatch(getHorariosOficina());
  }, [dispatch]);

  return (
    <Loading title="Cargando..." loading={isLoading}>
      <div className={classes.root}>
        {hasError && (
          <Alert severity={`Error`}>
            <AlertTitle>{'Error'}</AlertTitle>
            {hasError}
          </Alert>
        )}
        {data && (
          <CustomTabs
            title="Oficinas:"
            headerColor="primary"
            tabs={oficinas.map((oficina, i) => ({
              tabName: `${oficina.direccion}`,
              tabIcon: LocationOnIcon,
              tabContent: (
                <Horario
                laboratorista_id={laboratorista_id}
                  key={`oficina${i}`}
                  oficina_id={oficina.id}
                  tiempoConsulta={oficina.tiempo_por_cliente_minutos}
                  horarios={data.filter(horario => horario.oficina_id === oficina.id)}
                  getHorariosOficina={() => dispatch(getHorariosOficina())}
                />
              ),
            }))}
          />
        )}
      </div>
    </Loading>
  );
};

export default HorarioOficina;
