import Notificacion from 'components/ui/Notificacion';
import { urlCancelarSubscripcion } from 'constants/urls';
import { urlCrearServicio } from 'constants/urls';
import { urlEliminarServicio } from 'constants/urls';
import { urlDoctorCrearConsulta } from 'constants/urls';
import { urlAsignarServicioOficina } from 'constants/urls';
import { urlConsultaCrearPacienteVacuna } from 'constants/urls';
import { urlConsultaEliminarPacienteVacuna } from 'constants/urls';
import { urlEditarExamenPaciente } from 'constants/urls';
import { urlSearchInstituciones } from 'constants/urls';
import { urlEditarExamenListaPredeterminada } from 'constants/urls';
import { urlDoctorCertificateSignDownload } from 'constants/urls';
import { urlDoctorEmailCertificate } from 'constants/urls';
import { urlGetSegurosBusqueda } from 'constants/urls';
import { urlHistorialMedico } from 'constants/urls';
import { urlEliminarExamenListaPredeterminada } from 'constants/urls';
import { urlCrearExamenListaPredeterminada } from 'constants/urls';
import { urlEditarGrupoExamenPaciente } from 'constants/urls';
import { urlGetExamenes } from 'constants/urls';
import { urlConsultaActualizarPacienteVacuna } from 'constants/urls';
import { urlConsultaListarPacienteVacunas } from 'constants/urls';
import { urlGetVacunas } from 'constants/urls';
import { urlEditarServicio } from 'constants/urls';
import { urlListarDoctoresServicios } from 'constants/urls';
import { urlGetSubscripcion } from 'constants/urls';
import { createAction } from 'redux-actions';
import { encriptarRsa } from 'utils/encriptar';
import { clientes } from './aClientes';
import { jwt, updateDataUser } from './aLogin';
import { urlEnviarEmailPedidoExamenes } from 'constants/urls';
import { urlSavePayphoneConfig } from 'constants/urls';
import { urlDoctorSolicitarAsociacionOficinaInstitucion } from 'constants/urls';
import { urlDoctorObtenerDatosInstitucionesAsociaciones } from 'constants/urls';
import { getDoctorSucursalesAsociacion } from 'services/doctor';
import { urlFacturacionConfigParams } from 'constants/urls';
import { urlCreateFacturacionConfigParams } from 'constants/urls';
import { urlFirmarFacturaElectronicaConsulta } from 'constants/urls';
import { urlFacturaConsulta } from 'constants/urls';
import { urlFacturaConsultaEnviarSRI } from 'constants/urls';
import { urlFacturaConsultaSendReceipt } from 'constants/urls';
import { urlSecretariaFacturaConsulta } from 'constants/urls';
import { urlSecretariaFirmarFacturaElectronicaConsulta } from 'constants/urls';
import { urlSecretariaFacturaConsultaSendReceipt } from 'constants/urls';
import { urlSecretariaFacturaConsultaEnviarSRI } from 'constants/urls';
import { urlDoctorConsultaParametros } from 'constants/urls';
import { urlOficinasDoctor } from 'constants/urls';
import { hasErrorOficinas, oficinas } from './aOficinas';
import { empresa } from './aAdmin';
import { especialidades } from './aEspecialidades';
import { urlDoctorEliminarOficinaAfiliacion } from 'constants/urls';

export const subscripcion = createAction('LOAD SUBSCRIPCION');
export const hasErrorSubscripcion = createAction('ERROR SUBSCRIPCION');
export const isLoadingSubscription = createAction('LOADING SUBSCRIPCION');
export const subscriptionInitialState = createAction('SUBSCRIPCION INITIAL STATE');
export const servicios = createAction('LOAD DOCTOR SERVICIOS');


const messageSuccessSave = 'Registro guardado exitosamente';
const messageSuccessDelete = 'Registro eliminado exitosamente';

// export const isLoadingSubscripcion = createAction('IS_LOADING_SUBSCRIPCION');

// Configuración
export const savePayphoneConfig = (data) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    const raw = JSON.stringify(data);


    var requestOptions = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: raw
    };

    const response = await fetch(urlSavePayphoneConfig, requestOptions);
    const datos = await response.json();

    dispatch(updateDataUser({ ...datos.data }))

    if (datos.transaccion) {
      Notificacion({
        type: "success",
        text: 'Datos guardados correctamente',
      });
      return datos;
    }
    else throw (new Error(datos.mensaje));

  } catch (error) {
    console.log({ error });
    // return { transaccion:false , mensaje: 'No se pudo recuperar la información recargue la pagina '}
    throw (new Error("No se pudo recuperar la información recargue la pagina "))
  }
}

export const loadFacturacionParams = (data) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    var requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(urlFacturacionConfigParams, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) return datos;
    else throw (new Error(datos.mensaje));

  } catch (error) {
    console.log({ error });
    // return { transaccion:false , mensaje: 'No se pudo recuperar la información recargue la pagina '}
    throw (new Error("No se pudo recuperar la información recargue la pagina "))
  }
}

export const loadConsultaFactura = (consultaId, secretaria = false) => async (dispatch, getstate) => {
  const { token } = getstate();

  var requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  };

  const url = secretaria ? urlSecretariaFacturaConsulta : urlFacturaConsulta
  const response = await fetch(`${url}/${consultaId}`, requestOptions);
  const datos = await response.json();

  if (datos.transaccion) return datos;
  else throw (new Error(datos.mensaje));


}


export const guardarFacturacionConfig = (data) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    const raw = JSON.stringify(data);

    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: raw
    };

    const response = await fetch(urlCreateFacturacionConfigParams, requestOptions);
    const datos = await response.json();

    // dispatch(updateDataUser({ ...datos.data }))

    if (datos.transaccion) {
      Notificacion({
        type: "success",
        text: 'Datos guardados correctamente',
      });
      return datos;
    }
    else throw (new Error(datos.mensaje));

  } catch (error) {
    console.log({ error });
    // return { transaccion:false , mensaje: 'No se pudo recuperar la información recargue la pagina '}
    throw (new Error("No se pudo recuperar la información recargue la pagina "))
  }
}

export const enviarFacturaConsultaSRI = (consultaID, secretaria = false) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    const raw = JSON.stringify({});

    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: raw
    };

    const url = secretaria ? urlSecretariaFacturaConsultaEnviarSRI : urlFacturaConsultaEnviarSRI;

    const response = await fetch(`${url}/${consultaID}`, requestOptions);
    const datos = await response.json();

    // dispatch(updateDataUser({ ...datos.data }))

    if (datos.transaccion) {
      Notificacion({
        type: "success",
        text: 'Factura enviada correctamente al SRI',
      });
      return datos;
    }
    else throw (new Error(datos.mensaje));

  } catch (error) {
    console.log({ error });
    // return { transaccion:false , mensaje: 'No se pudo recuperar la información recargue la pagina '}
    throw (new Error("No se pudo recuperar la información recargue la pagina "))
  }
}
export const enviarFacturaConsultaReceipt = (consultaID, secretaria = false) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    const raw = JSON.stringify({});

    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: raw
    };

    const url = secretaria ? urlSecretariaFacturaConsultaSendReceipt : urlFacturaConsultaSendReceipt;
    const response = await fetch(`${url}/${consultaID}`, requestOptions);
    const datos = await response.json();

    // dispatch(updateDataUser({ ...datos.data }))

    if (datos.transaccion) {
      Notificacion({
        type: "success",
        text: 'Factura enviada correctamente al SRI',
      });
      return datos;
    }
    else throw (new Error(datos.mensaje));

  } catch (error) {
    console.log({ error });
    // return { transaccion:false , mensaje: 'No se pudo recuperar la información recargue la pagina '}
    throw (new Error("No se pudo recuperar la información recargue la pagina "))
  }
}

export const firmarFacturacionConsulta = (consultaId, data, secretaria = false) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    const raw = JSON.stringify(data);

    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: raw
    };

    const url = secretaria ? urlSecretariaFirmarFacturaElectronicaConsulta : urlFirmarFacturaElectronicaConsulta;
    const response = await fetch(`${url}/${consultaId}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      Notificacion({
        type: "success",
        text: 'Datos guardados correctamente',
      });
      return datos.data;
    }
    else throw (new Error(datos.mensaje));

  } catch (error) {
    console.log({ error });
    // return { transaccion:false , mensaje: 'No se pudo recuperar la información recargue la pagina '}
    throw (new Error("No se pudo recuperar la información recargue la pagina "))
  }
}

//Servicios
export const loadServicios = () => async (_, getstate) => {
  try {
    const { token } = getstate();

    var requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(urlListarDoctoresServicios, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) return datos;
    else throw (new Error(datos.mensaje));

  } catch (error) {
    console.log({ error });
    // return { transaccion:false , mensaje: 'No se pudo recuperar la información recargue la pagina '}
    throw (new Error("No se pudo recuperar la información recargue la pagina "))
  }
}

export const crearServicio = (data) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    const raw = JSON.stringify(data);

    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: raw
    };

    const response = await fetch(urlCrearServicio, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) return datos;
    else throw (new Error(datos.mensaje));

  } catch (error) {
    throw (error)
  }
};

export const editarServicio = (id, data) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    const raw = JSON.stringify(data);

    var requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: raw
    };

    const response = await fetch(`${urlEditarServicio}/${id}`, requestOptions);
    const datos = await response.json();
    console.log({ datos });

    if (datos.transaccion) return datos;
    else throw (new Error(datos.mensaje));

  } catch (error) {
    console.log("No se pudo editar registro ")
    // return { transaccion: false ,mensaje: 'No se pudo editar registro '}
    throw (new Error("No se pudo editar registro "))
  }
};

export const eliminarServicio = (id) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    var requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${urlEliminarServicio}/${id}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) return datos;
    else throw (new Error(datos.mensaje));

  } catch (error) {
    // return { transaccion: false ,mensaje: 'No se pudo eliminar registro '}
    throw (new Error("No se pudo eliminar registro "))
  }
};

// Oficinas
export const getOficinasDoctor = () => async (dispatch, getstate) => {
  try {
    const state = getstate();
    const { token } = state;

    var requestOptions = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(urlOficinasDoctor, requestOptions);
    const datos = await response.json();

    if (!token) {
      dispatch(jwt(""));
      return;
    }
    if (datos.transaccion) {
      dispatch(oficinas(datos.data));
      dispatch(servicios(datos.dataServicios));
      dispatch(empresa(datos.dataEmpresa));
      dispatch(especialidades(datos.dataEspecialidades ?? []));
    } else {
      Notificacion({
        type: "error",
        text: `Error de al obtener oficinas doctor`,
      });
    }

    return datos.dataOficinasAfiliadas;

  } catch (error) {
    dispatch(
      hasErrorOficinas(
        "No se pudo recuperar la información recargue la pagina "
      )
    );
  }
};

export const servicioAsignarOficinas = (id, data) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    const raw = JSON.stringify(data);

    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: raw
    };

    const response = await fetch(`${urlAsignarServicioOficina}/${id}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) return datos;
    else throw (new Error(datos.mensaje));

  } catch (error) {
    return { transaccion: false, mensaje: 'No se pudo editar registro ' }
  }
};

//Subscripciones
export const loadSubscripcion = () => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    fetch(urlGetSubscripcion, requestOptions)
      .then(res => res.json())
      .then(({ transaccion, data }) => {
        if (transaccion) {
          dispatch(subscripcion(data))
        }
        dispatch(isLoadingSubscription(false));
      })
      .catch(err => {
        dispatch(isLoadingSubscription(false));
        dispatch(hasErrorSubscripcion("Error al cargar datos de la subscripción"));
        console.log(err)
        dispatch(hasErrorSubscripcion("Error al cargar datos de la subscripción"))
      })

    // return datos;
  } catch (error) {
    // dispatch(hasErrorNotificaciones('No se pudo recuperar la información recargue la pagina '));
    // return {
    //   transaccion: false,
    //   mensaje: "No se pudo recuperar la información de la subscripción ",
    // };
  }
};

export const cancelSubscription = () => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },

    };

    const response = await fetch(urlCancelarSubscripcion, requestOptions);
    const { transaccion, data } = await response.json();

    if (!transaccion) throw (new Error("Ha ocurrido un error al cancelar la subscripción"))
    dispatch(subscripcion(data));

  } catch (error) {
    throw (error);
    // return {
    //   transaccion: false,
    //   mensaje: "No se pudo recuperar la información recargue la pagina ",
    // };
  }
};

//Consultas

export const startLoadHistoriaClinica = (clienteId, skip = 0, take = 10, filter = 0) => async (
  dispatch,
  getstate
) => {
  try {
    const state = getstate();
    const { token, data_clientes } = state;
    // const { doctor_id } = dataUser.data;

    var requestOptions = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${urlHistorialMedico}/${clienteId}?skip=${skip}&take=${take}&filter=${filter}`, requestOptions);
    const datos = await response.json();

    if (!datos.transaccion) throw (new Error('Error al listar consultas'))

    dispatch(clientes({
      ...data_clientes.data,
      consultas: datos?.data?.consultas,
      doctorGruposExamen: datos.data?.examenesGrupo,
      vacunas: datos.data?.clienteVacunas,
      seguros: datos.data?.clienteSeguros ?? [],
    }));

    return datos

  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const startLoadConsultaParams = () => async (
  _,
  getstate
) => {
  try {
    const state = getstate();
    const { token } = state;
    // const { doctor_id } = dataUser.data;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${urlDoctorConsultaParametros}`, requestOptions);
    const datos = await response.json();

    if (!datos.transaccion) throw (new Error('Error al listar consultas'))

    return datos.data

  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const crearConsulta = (data) => async (_, getstate) => {
  try {
    const { token } = getstate();

    const raw = JSON.stringify(data);

    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: raw
    };

    const response = await fetch(urlDoctorCrearConsulta, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) return datos;
    else throw (new Error(datos.mensaje));

  } catch (error) {
    // return { transaccion: false ,mensaje: 'No se pudo crear registro '}
    throw (new Error("No se pudo crear registro "))
  }
};

export const getExamenPorBusqueda = (cadenaBusqueda) => async (
  dispatch,
  getstate
) => {
  try {
    const { token } = getstate();
    // const {token, dataUser} = state;

    var requestOptions = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${urlGetExamenes}/${cadenaBusqueda}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) return datos;
    else throw (new Error(datos.mensaje));
  } catch (error) {
    throw (error);
  }
}


export const getVacunaPorBusqueda = (cadenaBusqueda) => async (
  dispatch,
  getstate
) => {
  try {
    const { token } = getstate();
    // const {token, dataUser} = state;

    var requestOptions = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${urlGetVacunas}/${cadenaBusqueda}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) return datos;
    else throw (new Error(datos.mensaje));
  } catch (error) {
    throw (error);
  }
}

export const getSegurosPorBusqueda = (cadenaBusqueda) => async (
  dispatch,
  getstate
) => {
  try {
    const { token } = getstate();
    // const {token, dataUser} = state;

    var requestOptions = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${urlGetSegurosBusqueda}/${cadenaBusqueda}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) return datos;
    else throw (new Error(datos.mensaje));
  } catch (error) {
    throw (error);
  }
}

//Paciente Vacunas

export const listarPacienteVacunas = (id) => async (dispatch, getstate) => {
  try {
    const { token, data_clientes } = getstate();

    var requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(`${urlConsultaListarPacienteVacunas}/${id}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      dispatch(clientes({ ...data_clientes.data, vacunas: datos.data }));
    }

    return datos;

  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const crearPacienteVacunas = (id, data) => async (dispatch, getstate) => {
  try {
    const { token, data_clientes } = getstate();

    const raw = JSON.stringify(data);

    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: raw
    };

    const response = await fetch(`${urlConsultaCrearPacienteVacuna}/${id}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      Notificacion({ type: "success", text: messageSuccessSave, });
      dispatch(clientes({ ...data_clientes.data, vacunas: [...data_clientes.data.vacunas, datos.data] }));
    }

    return datos;
  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const editarPacienteVacuna = (id, data) => async (dispatch, getstate) => {
  try {
    const { token, data_clientes } = getstate();

    const raw = JSON.stringify(data);

    var requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: raw
    };

    const response = await fetch(`${urlConsultaActualizarPacienteVacuna}/${id}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      Notificacion({ type: "success", text: messageSuccessSave, });
      const dataVacunas = data_clientes.data.vacunas ?? [];
      const newData = dataVacunas.map(item => item.id === datos.data.id ? datos.data : item);

      dispatch(clientes({ ...data_clientes.data, vacunas: newData }));
    }

    return datos;
  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const eliminarPacienteVacunas = (id) => async (dispatch, getstate) => {
  try {
    const { token, data_clientes } = getstate();

    var requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${urlConsultaEliminarPacienteVacuna}/${id}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      Notificacion({ type: "success", text: messageSuccessDelete, });
      const dataVacunas = data_clientes?.data?.vacunas ?? [];
      const newData = dataVacunas.filter(item => item.id !== datos.data.id);

      dispatch(clientes({ ...data_clientes.data, vacunas: newData }));
    }

    return datos;
  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

//Examenes
export const editarPacienteExamen = (id, data) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    const raw = JSON.stringify(data);

    var requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: raw
    };

    const response = await fetch(`${urlEditarExamenPaciente}/${id}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      Notificacion({ type: "success", text: messageSuccessSave, });
      return datos;

    } else
      throw (new Error('No se pudo actualizar la información recargue la pagina '))

  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const editarPacienteGrupoExamen = (id, data) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    const raw = JSON.stringify(data);

    var requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: raw
    };

    const response = await fetch(`${urlEditarGrupoExamenPaciente}/${id}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      Notificacion({ type: "success", text: messageSuccessSave, });
      return datos;

    } else
      throw (new Error('No se pudo actualizar la información recargue la pagina '))

  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const crearExamenListaPredeterminada = (data) => async (dispatch, getstate) => {
  try {
    const { token, data_clientes } = getstate();

    const raw = JSON.stringify(data);

    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: raw
    };

    const response = await fetch(`${urlCrearExamenListaPredeterminada}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      Notificacion({ type: "success", text: messageSuccessSave, });
      dispatch(clientes({ ...data_clientes.data, doctorGruposExamen: [...data_clientes.data.doctorGruposExamen, datos.data] }));
      return datos;

    } else
      throw (new Error('No se pudo actualizar la información recargue la pagina '))

  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const editarExamenListaPredeterminada = (id, data) => async (dispatch, getstate) => {
  try {
    const { token, data_clientes } = getstate();

    const raw = JSON.stringify(data);

    var requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: raw
    };

    const response = await fetch(`${urlEditarExamenListaPredeterminada}/${id}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      const grupoExamenesUpdated = data_clientes.data.doctorGruposExamen.map(item => item.id === datos.data.id ? datos.data : item);
      dispatch(clientes({ ...data_clientes.data, doctorGruposExamen: grupoExamenesUpdated }));
      Notificacion({ type: "success", text: messageSuccessSave, });
      return datos;

    } else
      throw (new Error('No se pudo actualizar la información recargue la pagina '))

  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const eliminarExamenListaPredeterminada = (id) => async (dispatch, getstate) => {
  try {
    const { token, data_clientes } = getstate();

    var requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${urlEliminarExamenListaPredeterminada}/${id}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      const grupoExamenesUpdated = data_clientes.data.doctorGruposExamen.filter(item => item.id !== id);
      dispatch(clientes({ ...data_clientes.data, doctorGruposExamen: grupoExamenesUpdated }));
      Notificacion({ type: "success", text: messageSuccessSave, });
      return datos;

    } else
      throw (new Error('No se pudo actualizar la información recargue la pagina '))

  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

//Instituciones
export const getInstitucionPorBusqueda = (cadenaBusqueda) => async (
  dispatch,
  getstate
) => {
  try {
    const { token } = getstate();

    var requestOptions = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${urlSearchInstituciones}/${cadenaBusqueda}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) return datos;
    else throw (new Error(datos.mensaje));
  } catch (error) {
    throw (error);
  }
}

// Certificado medico

export const descargarCertificadoFirmado = (clientId, data, showHeader = false) => async (
  dispatch,
  getstate
) => {
  try {
    const state = getstate();
    const { token } = state;

    var formdata = new FormData();
    formdata.append("date", data.date);
    formdata.append("text", data.text);
    formdata.append("file", data.file);
    formdata.append("password", await encriptarRsa(JSON.stringify({ value: data.password })));

    var requestOptions = {
      method: "post",
      headers: {
        authorization: `Bearer ${token}`,
      },
      body: formdata,
    };

    const showHeaderParam = showHeader ? '&header=true' : '';

    const response = await fetch(`${urlDoctorCertificateSignDownload}/${clientId}?signed=true${showHeaderParam}`, requestOptions)
    const responseStatus = response.status;

    if (responseStatus === 200) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `certificado.pdf`,);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      return { transaccion: true }
    } else {
      const datos = await response.json();
      const { mensaje } = datos;
      Notificacion({
        type: "error",
        text: mensaje,
      });
      return { transaccion: false }
    }

  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo enviar la información recargue la pagina ',
    });
    throw (error);
  }
};

export const enviarEmailCertificado = (clientId, data, { signed = false, download = false, showHeader = false }) => async (
  dispatch,
  getstate
) => {
  try {
    const state = getstate();
    const { token } = state;

    let requestOptions;

    if (signed) {
      var formdata = new FormData();
      formdata.append("date", data.date);
      formdata.append("text", data.text);
      formdata.append("file", data.file);
      formdata.append("password", await encriptarRsa(JSON.stringify({ value: data.password })));

      requestOptions = {
        method: "post",
        headers: {
          authorization: `Bearer ${token}`,
        },
        body: formdata,
      };

    } else {
      const raw = JSON.stringify({
        date: data.date,
        text: data.text,
      });
      requestOptions = {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: raw,
      };
    }

    const queryParams = {
      signed: signed ? 'true' : '',
      download: download ? 'true' : '',
      showHeader: showHeader ? 'true' : '',
    }
    const searchParams = new URLSearchParams(queryParams);

    const response = await fetch(`${urlDoctorEmailCertificate}/${clientId}?${searchParams.toString()}`, requestOptions)

    if (download) {
      const responseStatus = response.status;
      if (responseStatus === 200) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `receta.pdf`,);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        Notificacion({
          type: "success",
          text: 'Certificado enviado correctamente al email del paciente',
        });
        return { transaccion: true }
      } else {
        const datos = await response.json();
        const { mensaje } = datos;
        Notificacion({
          type: "error",
          text: mensaje,
        });
        return { transaccion: false }
      }
    }

    const datos = await response.json();
    const { token: tokenRes = true } = datos;
    if (!tokenRes) {
      dispatch(jwt(""));
      return;
    }
    if (datos.transaccion) {
      Notificacion({
        type: "success",
        text: datos.mensaje,
      });
      return { transaccion: true, mensaje: datos.mensaje }
    } else {
      Notificacion({
        type: "error",
        text: datos.mensaje,
      });
      return { transaccion: false, mensaje: datos.mensaje }
    }


  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo enviar la información recargue la pagina ',
    });
    throw (error);
  }
};
export const enviarPedidoExamenesEmail = (clientId, data, { signed = false, download = false, showHeader = false }) => async (
  dispatch,
  getstate
) => {
  try {
    const state = getstate();
    const { token } = state;

    console.log(data.exams)

    let requestOptions;

    if (signed) {
      var formdata = new FormData();
      // formdata.append("date", data.date);
      formdata.append("exams", JSON.stringify(data.exams));
      formdata.append("cie10Text", JSON.stringify(data.cie10Text));
      formdata.append("file", data.file);
      formdata.append("password", await encriptarRsa(JSON.stringify({ value: data.password })));

      requestOptions = {
        method: "post",
        headers: {
          authorization: `Bearer ${token}`,
        },
        body: formdata,
      };

    } else {
      const raw = JSON.stringify({
        date: data.date,
        text: data.text,
        exams: data.exams,
        cie10Text: data.cie10Text,
      });
      requestOptions = {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: raw,
      };
    }

    const queryParams = {
      signed: signed ? 'true' : '',
      download: download ? 'true' : '',
      showHeader: showHeader ? 'true' : '',
    }
    const searchParams = new URLSearchParams(queryParams);

    const response = await fetch(`${urlEnviarEmailPedidoExamenes}/${clientId}?${searchParams.toString()}`, requestOptions)

    if (download) {
      const responseStatus = response.status;
      if (responseStatus === 200) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `receta.pdf`,);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        Notificacion({
          type: "success",
          text: 'Certificado enviado correctamente al email del paciente',
        });
        return { transaccion: true }
      } else {
        const datos = await response.json();
        const { mensaje } = datos;
        Notificacion({
          type: "error",
          text: mensaje,
        });
        return { transaccion: false }
      }
    }

    const datos = await response.json();
    const { token: tokenRes = true } = datos;
    if (!tokenRes) {
      dispatch(jwt(""));
      return;
    }
    if (datos.transaccion) {
      Notificacion({
        type: "success",
        text: datos.mensaje,
      });
      return { transaccion: true, mensaje: datos.mensaje }
    } else {
      Notificacion({
        type: "error",
        text: datos.mensaje,
      });
      return { transaccion: false, mensaje: datos.mensaje }
    }


  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo enviar la información recargue la pagina ',
    });
    throw (error);
  }
};

// Instituciones sucursales
export const obtenerDatosInstitucionesAsociaciones = () => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    var requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${urlDoctorObtenerDatosInstitucionesAsociaciones}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      return datos.data
    }
    throw (new Error('Error al recuperar los datos'))

  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const enviarSolicitudAsociacionInstitucionSucursal = (oficinasId) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    const raw = JSON.stringify({
      oficinasId
    });

    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: raw,
    };

    const response = await fetch(`${urlDoctorSolicitarAsociacionOficinaInstitucion}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion)
      Notificacion({ type: "success", text: 'Solicitud enviada correctamente', });
    else
      Notificacion({ type: "success", text: 'Error al enivar la solicitud', });

    return datos;
  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const eliminarAfiliacionDoctorOficina = (oficinaId) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    var requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${urlDoctorEliminarOficinaAfiliacion}/${oficinaId}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      Notificacion({ type: "success", text: 'Asociación eliminada correctamente', });
      // dispatch(
      //   deleteTableCrudItemCustomCondition({
      //     condition: (item) => item.oficina_id !== oficinaId && item.doctor_id !== doctorId
      //   })
      // );
    }

    else
      Notificacion({ type: "error", text: 'Error al aceptar la solicitud', });

    return datos;
  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};



export const loadDoctorOficinasSucursales = (doctorId = undefined) => async (_, getstate) => {
  try {
    const { token } = getstate();
    const data = await getDoctorSucursalesAsociacion({
      doctorId,
      requestOptions: {
        token
      }
    });

    return data;
  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

