import React, { useState, useEffect, useRef } from 'react';

import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { IconButton } from '@material-ui/core';

import HorarioTable from './horario-table.component';
import HorarioForm from './horario-form.component';
import Button from "components/CustomButtons/Button.js";

import { deleteHora } from 'redux/actions/aOficinas';

const ordenarPorDia = arr => {
  const dias_semana = ['LUNES', 'MARTES', 'MIÉRCOLES', 'JUEVES', 'VIERNES', 'SÁBADO', 'DOMINGO'];
  return dias_semana.filter(dia => arr.includes(dia));
};

const Horario = ({ horarios, doctor_id = '', ...props }) => {
  const refForm = useRef(null);
  const dispatch = useDispatch();

  const [Horario2, setHorario2] = useState(horarios);

  const [showForm, setShowForm] = useState(false);
  const [unHorario, setunhorario] = useState({});

  const handleShowForm = valor => {
    if (!showForm) {
      refForm.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    setShowForm(!showForm);
    if (valor) {
      setunhorario({});
    }
  };

  const handlDeleteHorario = async param => {
    await dispatch(deleteHora(param));
  };

  const handleUnHorario = param => {
    setunhorario(param);
    if (!showForm) {
      refForm.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else if (showForm) {
      setShowForm(!showForm);
    }
    setShowForm(!showForm);
  };
  useEffect(() => {
    const Horario2 = horarios.sort(function (a, b) {
      if (a.hora_inicio > b.hora_inicio) {
        return 1;
      }
      if (a.hora_inicio < b.hora_inicio) {
        return -1;
      }
      return 0;
    });
    setHorario2(Horario2);
  }, [horarios]);

  return (
    <>
      {Horario2.length > 0 ? ( //Revisar si tiene horarios, si no mostrar mensaje de agregar
        ordenarPorDia(
          Horario2.map(horario => horario.dia_semana.toUpperCase()) //Pongo todos los días de todos los horarios que vienen del servicio, aunque sean repetidos en un array
            .filter((item, pos, self) => self.indexOf(item) === pos) //Elimino todos los repetidos del array
        ).map((dia, i) => (
          <div key={`dias${i}`}>
            <Box borderBottom={1}>

              <Typography display="inline" variant="subtitle1">
                {dia}
              </Typography>
            </Box>
            <div>
              <HorarioTable
                deleteHorario={handlDeleteHorario}
                onUnHorario={handleUnHorario}
                horario={Horario2.filter(horario_dia => horario_dia.dia_semana.toUpperCase() === dia)}
              />
            </div>
          </div>
        ))
      ) : (
        <Typography style={{ textAlign: 'center' }} variant="subtitle1">
          ¡No tiene horarios guardados!
        </Typography>
      )}
      <div
        style={{
          display: 'flex',
          flex: 1,
          marginTop: '50px',
          justifyContent: 'center',
        }}
      >
        {
          showForm ?
            <Button color="error" onClick={() => handleShowForm(true)}>
              Cancelar
            </Button>
            :
            <IconButton onClick={() => handleShowForm(true)} >
              <AddCircleIcon /> Añadir horario
            </IconButton>
        }
      </div>
      <div ref={refForm}>
        {showForm && (
          <HorarioForm
            unHorario={unHorario}
            doctor_id={doctor_id}
            dias={Horario2.length > 0 ? ordenarPorDia(Horario2.map(horario => horario.dia_semana.toUpperCase()).filter((item, pos, self) => self.indexOf(item) === pos)) : []}
            horarios={Horario2.length > 0 ? Horario2 : []}
            {...props}
            handleCerrar={() => setShowForm(false)}
          // handleVacio={() => handleShowForm(true)}
          />
        )}
      </div>
    </>
  );
};

export default Horario;
