import React, { createContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import Cliente from "./cliente.component";
import OficinaReserva from "./oficina-reservaciones.component";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { useSelector, useDispatch } from "react-redux";
import Resumen from "./resumen.component";
import { agregarCita } from "redux/actions/aCitas";
import moment from "moment";
// import { el } from "date-fns/esm/locale";
import { cleanCliente } from "redux/actions/aClientes";
import { getHorariosReserva } from "redux/actions/aOficinas";
import Loading from "components/Loading";
import Notificacion from "components/ui/Notificacion";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { SucursalReservacion } from "../SucursalReservacion";
import { loadDoctorOficinasSucursales } from "redux/actions/aMedico";

export const SucursalReservacionContext = createContext({
  oficinas: [],
  horarioSelected: undefined,
});

// moment.locale('es');
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    color: "white",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  components: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
  },
}));

const ReservaStep = ({ ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = ["Paciente", "Horario - Oficina", "Resumen"];

  const [isLoading, setisLoading] = React.useState(false);

  //Estados Date picker
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState(moment(new Date()).format('dddd'));

  // Oficinas
  const [oficinaIdSelected, setSetOficinaIdSelected] = useState('');

  // Estado sucursales
  const [loadingSucursales, setLoadingSucursales] = useState(false);
  const [oficinas, setOficinas] = useState([]);
  const [reservas, setReservas] = useState([]);

  const [selectedHorario, setSelectedHorario] = React.useState({
    fecha: new Date(),
    hora: "00:00-01:00",
    id_oficina: undefined,
    fechaSeleccionada: undefined,
    especializacion_id: '',
  });

  const cliente = useSelector((state) => state.data_clientes.data);
  const doctor_id = useSelector((state) => state.dataUser.data.doctor_id);

  const [error] = React.useState(null);
  const { hasError } = useSelector((state) => state.datos_citas);
  const handleClickCard = (objeto) => {
    setSelectedHorario(objeto);
  };
  const [, setIdCliente] = React.useState("");

  // Oficina/Sucursal: oficina=1, sucursal=2
  const [officeTypeSelected, setOfficeTypeSelected] = useState(1);

  const handleChangeOficinaSelected = (e) => {
    setOfficeTypeSelected(e.target.value)
  }

  const handleClickHorario = (horario) => {
    setSelectedHorario(horario);
  }

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <Cliente
            setIdCliente={(val) => setIdCliente(val)}
            onClick={handleNext}
          />
        );
      case 1:
        return (
          <>
            <div>

              <FormControl style={{ width: '100%' }}>
                <InputLabel id={`oficina_sucursal_label`}>Oficina/Sucursal</InputLabel>
                <Select
                  labelId={'oficina_sucursal_label'}
                  value={officeTypeSelected}
                  onChange={handleChangeOficinaSelected}
                >
                  <MenuItem value={1}>Oficinas</MenuItem>
                  <MenuItem value={2}>Sucursales</MenuItem>

                </Select>
              </FormControl>

            </div>

            <div className={classes.components}>
              {
                officeTypeSelected === 1
                  ?
                  <OficinaReserva
                    selectedHorario={selectedHorario}
                    handleClickCard={handleClickCard}
                    error={error}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    oficinaIdSelected={oficinaIdSelected}
                    setSetOficinaIdSelected={setSetOficinaIdSelected}
                  />
                  :
                  <Box width={1}>
                    <Loading loading={loadingSucursales} title="Cargando...">
                      <SucursalReservacionContext.Provider value={{
                        oficinas,
                        selectedDay,
                        selectedHorario,
                        handleClickHorario,
                        selectedDate,
                        reservas,
                      }}>
                        <SucursalReservacion
                          selectedDate={selectedDate}
                          setSelectedDate={setSelectedDate}
                          // selectedDay={selectedDay}
                          setSelectedDay={setSelectedDay}
                        />

                      </SucursalReservacionContext.Provider>
                    </Loading >

                  </Box>
              }

            </div>

          </>

        );
      case 2:
        return <Resumen selectedHorario={selectedHorario} />;
      default:
        return "Unknown stepIndex";
    }
  };

  const handleNext = async () => {
    if (activeStep === 0 && cliente !== undefined && !Array.isArray(cliente)) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep === 1) {
      if (selectedHorario.id_oficina !== undefined && selectedHorario.especializacion_id !== '') {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else Notificacion({
        type: "error",
        text: "Debe seleccionar la fecha y la especialidad",
      });
    } else if (activeStep === 2) {
      await guardaReserva();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const guardaReserva = async () => {
    try {
      const valores = {
        inicio: `${moment(
          `${selectedHorario.fechaSeleccionada} ${selectedHorario.hora.split("-")[0]
          }`
        )
          .format()}`,
        fin: `${moment(
          `${selectedHorario.fechaSeleccionada} ${selectedHorario.hora.split("-")[1]
          }`
        ).format()}`,
        fecha_reserva: moment(selectedHorario.fechaSeleccionada).format(),
        disponible: "",
        razon_no_disponibilidad: "",
        oficinaId: selectedHorario.id_oficina,
        canal_reserva: "WEB_MEDICO",
        estado_reserva: "APROBADO",
        phone_number: cliente.telefono,
        especializacion_id:
          selectedHorario.especializacion_id === '' ? null : selectedHorario.especializacion_id,
        cliente_id: cliente.id,
        doctor_id: doctor_id,
        servicio_id: selectedHorario.servicio_id === '0' ? null : selectedHorario.servicio_id,
      };

      if (officeTypeSelected === 2)
        valores.doctor_id_sucursal = doctor_id;

      setisLoading(true);

      await dispatch(agregarCita(valores));
      if (!hasError) {
        setOfficeTypeSelected(1);
        setSelectedDate(new Date());
        setSelectedDay(moment(new Date()).format('dddd'));
        await dispatch(getHorariosReserva());
        setTimeout(async () => {
          await dispatch(cleanCliente());
          setSelectedHorario({
            fecha: new Date(),
            hora: "00:00-01:00",
            id_oficina: undefined,
            fechaSeleccionada: undefined,
          });
        }, 500);
      }
      setisLoading(false);
      //  else {
      //   seterror({ title: 'error', mensaje: props.hasError });
      // }
    } catch (err) {
      // seterror({
      //   title: 'error',
      //   mensaje: 'Ocurrio un error, intente nuevamente' + err,
      // });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    if (officeTypeSelected !== 2) return

    setLoadingSucursales(true);
    dispatch(loadDoctorOficinasSucursales())
      .then(data => {
        setOficinas(data.oficinas);
        setReservas(data.reservas);
        setLoadingSucursales(false);
      });

  }, [officeTypeSelected, dispatch])

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <div
              style={{
                margin: "50px auto",
                display: "flex",
                flex: "1",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {!hasError && (
                <>
                  <Typography className={classes.instructions}>
                    Se ha reservado la cita
                  </Typography>
                  <CheckCircleIcon fontSize="large" htmlColor="#00c5b4" />
                </>
              )}
              {hasError && (
                <>
                  <Typography className={classes.instructions}>
                    No se ha reservado la cita
                  </Typography>
                  <HighlightOffIcon fontSize="large" htmlColor="red" />
                </>
              )}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="primary"
                // variant="contained"
                // color="primary"
                onClick={handleReset}
                className={classes.button}
              >
                Nueva reserva
              </Button>
            </div>
          </div>
        ) : (
          <Loading title="Cargando..." loading={isLoading}>
            <>
              <div className={activeStep !== 1 ? classes.components : ''}>
                {getStepContent(activeStep)}
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.backButton}
                >
                  Regresar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleNext}
                  disabled={!cliente}
                // className='primary'
                >
                  {activeStep === steps.length - 1 ? "Finalizar" : "Siguiente"}
                </Button>
              </div>
            </>
          </Loading>
        )}
      </div>
    </div>
  );
};

export default ReservaStep;
