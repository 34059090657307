import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { makeStyles } from '@material-ui/core/styles';

import { IconButton } from '@material-ui/core';

//Custom components
// import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';

import Card from 'components/Card/Card.js';

import CardBody from 'components/Card/CardBody.js';
import FormOficina from './oficina-form.component';
import { TableOficina } from 'components/oficina/laboratorista/oficina-table.component';
//Icons

import AddCircleIcon from '@material-ui/icons/AddCircle';

//actions redux
import { oficinaSetLaboratorista, deleteOficina, getOficinasLaboratorista } from '../../../redux/actions/aOficinas';

// const useStyles = makeStyles(styles);

const Oficinas = props => {
  const dispatch = useDispatch();

  const { isLoadingOficinas: isLoading, data: oficinas } = useSelector(
    state => state.data_oficinas
  );
  const { doctor_id = ""} = useSelector(state => state.dataUser.data);

  const refForm = useRef(null);
  // const classes = useStyles();

  const [showForm, setShowForm] = useState(false);
  const [unaOficina, setOficina] = useState({});

  useEffect(() => {
    dispatch(getOficinasLaboratorista());
  }, [dispatch]);

  const handleShowForm = valor => {
    if (!showForm) {
      refForm.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    setShowForm(!showForm);
    if (valor) {
      setOficina({});
    }
  };

  const handleUnaOficina = oficina => {
    setOficina(oficina);
    if (!showForm) {
      if (refForm && refForm.current) refForm.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else if (showForm) {
      setShowForm(!showForm);
    }
    setShowForm(!showForm);
  };

  const handleDeleteOficina = async oficina => {
    await dispatch(deleteOficina(oficina));
    setShowForm(false); //Cierra el formulario si tiene abierto
  };

  const handleCierraForm = () => {
    setShowForm(false); //Cierra el formulario si tiene abierto
  };

  return (
    <>
      {/* <div
        style={{
          display: "flex",
        }}
      >
         <LocationOnIcon />
         <div borders="borderBottom">
          <Typography display="inline" variant="subtitle1">
            Mis oficinas
          </Typography>
        </div>
      </div> */}
      <Card>
        {/* <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Oficinas</h4>
        </CardHeader> */}
        <CardBody>
          <TableOficina
            titulos={[
              'Tiempo por paciente',
              'Precio consulta afiliado',
              'Precio consulta',
              'Dirección',
              'Ciudad',
              'Referencias',
              'Configuración',
            ]}
            oficinas={oficinas}
            editarOficina={handleUnaOficina}
            deleteOficina={handleDeleteOficina}
          />
          <div
            style={{
              display: 'flex',
              flex: 1,
              marginTop: '50px',
              justifyContent: 'flex-end',
            }}
          >
            <IconButton onClick={() => handleShowForm(true)}>
              <AddCircleIcon />
              Añadir oficina
            </IconButton>
          </div>
          <div ref={refForm}>
            {showForm && (
              <FormOficina
                oficina={unaOficina}
                onCierraForm={handleCierraForm}
                agregarOficina={param => dispatch(oficinaSetLaboratorista(param))}
                doctor_id={doctor_id}
                isLoading={isLoading}
                {...props}
              />
            )}
          </div>
        </CardBody>
      </Card>
    </>
  );
};

// const mapStateToProps = (state) => ({
//   isLoading: state.data_oficinas.isLoadingOficinas,
//   hasError: state.data_oficinas.hasErrorOficinas,
//   oficinas: state.data_oficinas.data,
//   doctor_id: state.dataUser.data.doctor_id,
// });

// const mapDispatchToProps = (dispatch) => {
//   return {
//     agregarOficina: (param) => dispatch(oficinaSet(param)),
//     deleteOficina: (param) => dispatch(deleteOficina(param)),
//     getOficinas: () => dispatch(getOficinas()),
//   };
// };

export default Oficinas;
