import { handleActions } from "redux-actions";
import {
  notificaciones,
  isLoadingNotificaciones,
  hasErrorNotificaciones,
  clearNotificaciones
} from "../actions/aNotificaciones";

export const data_notificaciones = handleActions(
  {
    [notificaciones]: (state, action) => ({
      ...state,
      data: action.payload,
      isLoadingNotificaciones: false,
      hasErrorNotificaciones: false,
    }),
    [clearNotificaciones]: (state) => ({
      ...state,
      data: [],
      isLoadingNotificaciones: false,
      hasErrorNotificaciones: false,
    }),
    [isLoadingNotificaciones]: (state) => ({
      ...state,
      isLoadingNotificaciones: true,
      hasErrorNotificaciones: false,
    }),
    [hasErrorNotificaciones]: (state, action) => ({
      ...state,
      isLoadingNotificaciones: false,
      hasErrorNotificaciones: action.payload,
    }),
  },
  { isLoadingNotificaciones: false, hasErrorNotificaciones: false, data: [] }
);
