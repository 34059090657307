import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Box, Collapse, IconButton, SvgIcon, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@material-ui/core";
import Link from '@material-ui/core/Link';
import Close from "@material-ui/icons/Close";
import EditIcon from '@material-ui/icons/Edit';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { ReactComponent as UploadDocumentSvg } from 'assets/images/icons/subir_documento.svg';
import { urlBucket } from "constants/urls";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  link: {
    textDecoration: 'underline',
    color: '#3177b9',
    '&:visited': { color: '#3177b9', },
    '&:hover': { color: '#3177b9', },
    '&:focus': { color: '#3177b9', }
  },
}));

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

export const TableRowGrupoExamen = (props) => {
  const { row, handleDelete, handleEdit, auxId, handleDownloadBase64 } = props;
  const [open, setOpen] = React.useState(false);
  const classesRow = useRowStyles();
  const classes = useStyles();

  return (
    <React.Fragment>
      <TableRow className={classesRow.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">
          #{auxId}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.nombre}
        </TableCell>
        <TableCell>
          {
            row.archivo_nombre_base64 ?
              <strong style={{ cursor: 'pointer' }} onClick={() => handleDownloadBase64(row.archivo_nombre_base64)}>Resultados</strong>
              :
              (row.archivo_nombre) ?
                <Link
                  style={{ fontWeight: "bold" }}
                  color="primary"
                  variant="subtitle1"
                  className={classes.link}
                  href={`${urlBucket}${row.archivo_nombre}`} target="_blank">
                  Resultados
                </Link>
                :
                <strong>Sin archivo</strong>
          }
        </TableCell>
        <TableCell component="th" scope="row">
          {row.examenes ? (row.examenes.length > 0) ? (<strong>{row.examenes.length}</strong>) : row.examenes.length : 0}
        </TableCell>
        <TableCell>
          {
            !row.new &&
            <Tooltip
              id="tooltip-servicio"
              title={'Subir resultado'}
              placement="top"
              classes={{ tooltip: classesRow.tooltip }}
            >
              <IconButton
                aria-label="Edit"
                className={classesRow.tableActionButton}
                onClick={() => handleEdit(row, true)}
              >
                <SvgIcon className={classesRow.mySvgStyle}>
                  <UploadDocumentSvg />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          }
          <Tooltip
            id="tooltip-editar"
            title="Editar"
            placement="top"

            classes={{ tooltip: classesRow.tooltip }}
          >
            <IconButton
              aria-label="Close"
              className={classesRow.tableActionButton}
              onClick={() => handleEdit(row)}
            >
              <EditIcon
                className={
                  classesRow.tableActionButtonIcon + " " + classesRow.close
                }
              />
            </IconButton>

          </Tooltip>
          <Tooltip
            id="tooltip-eliminar"
            title="Eliminar"
            placement="top"

            classes={{ tooltip: classesRow.tooltip }}
          >
            <IconButton
              aria-label="Close"
              className={classesRow.tableActionButton}
              onClick={() => handleDelete(row.id)}
            >
              <Close
                className={
                  classesRow.tableActionButtonIcon + " " + classesRow.close
                }
              />
            </IconButton>

          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="subtitle1" gutterBottom component="div">
                Exámenes
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.examenes && row.examenes.map((examen) => (
                    <TableRow key={examen.id}>
                      <TableCell component="th" scope="row">
                        {examen.nombre}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

TableRowGrupoExamen.propTypes = {
  row: PropTypes.shape({
    nombre: PropTypes.string.isRequired,
    examenes: PropTypes.arrayOf(
      PropTypes.shape({
        nombre: PropTypes.string.isRequired,
        detalles: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};
