import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { TextFieldCustom as TextField } from 'components/forms/input/TextFieldCustom';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import Loading from '../Loading';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from '../CustomButtons/Button';
import { validacionesVadecum } from 'utils/validaciones';
import { useDispatch } from 'react-redux';
import { medicinaSet } from 'redux/actions/aVademecum';
import { deleteVadDoctor } from 'redux/actions/aVademecum';
import { consultarVademecumDoctor } from 'redux/actions/aVademecum';
import { urlBucket } from "constants/urls";
import ConfirmationDialog from 'components/dialog/custom-dialog.component';
import WarningLeaveFormPage from 'components/ui/WarningLeaveFormPage';
import medpalImg from "../../assets/images/icons/pills.svg";
import { FormErrorLabel } from 'components/forms/labels/FormErrorLabel';


const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
}));

const FormVadecum = ({ doctor, unObjeto, esNuevo, borrar, hasError, isLoading, handleShowForm }) => {

  // console.log({ unObjeto })
  //estilos
  const dispatch = useDispatch();
  const classes = useStyles();
  const firstRender = useRef(true);

  //Modal
  const [openModal, setOpenModal] = useState(false);

  //estados para el formulario
  const [error, seterror] = useState({ title: '', mensaje: '' });
  const [formDirty, setFormDirty] = useState(false);
  const [errors, seterrors] = React.useState({});

  const [imgTemp, setImgTemp] = useState(null);


  const valoresInit = useRef({
    nombre: !!unObjeto.nombre ? unObjeto.nombre : '',
    composicion: !!unObjeto.composicion ? unObjeto.composicion : '',
    funcion: !!unObjeto.funcion ? unObjeto.funcion : '',
    presentacion: !!unObjeto.presentacion ? unObjeto.presentacion : '',
    dosificacion: !!unObjeto.dosificacion ? unObjeto.dosificacion : '',
    casa_comercial: !!unObjeto.casa_comercial ? unObjeto.casa_comercial : '',
    contraindicaciones: !!unObjeto.contraindicaciones ? unObjeto.contraindicaciones : '',
    notas: !!unObjeto.notas ? unObjeto.notas : '',
    urlimagen: !!unObjeto.urlimagen ? unObjeto.urlimagen : 'n/a',
    doctor_id: doctor,
  });

  const [valores, setvalores] = React.useState(valoresInit.current);

  useEffect(() => {
    valoresInit.current = {
      nombre: !!unObjeto.nombre ? unObjeto.nombre : '',
      composicion: !!unObjeto.composicion ? unObjeto.composicion : '',
      funcion: !!unObjeto.funcion ? unObjeto.funcion : '',
      presentacion: !!unObjeto.presentacion ? unObjeto.presentacion : '',
      dosificacion: !!unObjeto.dosificacion ? unObjeto.dosificacion : '',
      casa_comercial: !!unObjeto.casa_comercial ? unObjeto.casa_comercial : '',
      contraindicaciones: !!unObjeto.contraindicaciones ? unObjeto.contraindicaciones : '',
      notas: !!unObjeto.notas ? unObjeto.notas : '',
      urlimagen: !!unObjeto.urlimagen ? unObjeto.urlimagen : 'n/a',
      doctor_id: doctor,
    };
    setvalores(valoresInit.current);
  }, [unObjeto, doctor]);
  useEffect(() => {
    if (firstRender.current) {
      return;
    }
    seterrors(validacionesVadecum(valores));
  }, [valores]);

  useEffect(() => {
    //Verificar si se ha modificado el formulario
    if (_.isEqual(valores, valoresInit.current)) setFormDirty(false);
    else setFormDirty(true);
  }, [valores]);


  const handleChange = e => {
    const { name, value } = e.target;
    setvalores({ ...valores, [name]: value });
  };


  function handleFileSelected(e) {
    const file = e.target.files[0];
    // divido para dos para 5MB max
    if (file.size / 1024 / 1024 < 5) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setvalores({ ...valores, urlimagen: reader.result });
        setImgTemp(reader.result);
      };
    } else {
    }
  }

  const handleClickEliminar = async () => {
    setOpenModal(false);
    let id = '';
    if (esNuevo) {
      id = undefined;
    } else {
      id = unObjeto.id !== undefined ? unObjeto.id : undefined;
    }
    await dispatch(deleteVadDoctor(id));
    dispatch(consultarVademecumDoctor());
    handleShowForm(true);
  }

  const handleClick = async () => {
    firstRender.current = false;
    const res = validacionesVadecum(valores);
    if (Object.keys(res).length > 0) {
      seterrors(res);
      return;
    }

    try {
      if (Object.keys(errors).length > 0) {
        setMensaje({ title: 'error', mensaje: 'Debe completar los campos correctamente' });
        return;
      }

      let id = '';
      if (esNuevo) {
        id = undefined;
      } else {
        id = (unObjeto.id !== undefined && unObjeto.doctor_id) ? unObjeto.id : undefined;
      }
      await dispatch(medicinaSet(valores, id));

      if (!hasError) {
        handleShowForm(true);
      } else {
        // setMensaje({ title: 'error', mensaje: hasError });
      }
    } catch (err) {
      setMensaje({
        title: 'error',
        mensaje: `'Ocurrio un error, intente nuevamente' ${err}`,
      });
    }
  };

  const setMensaje = ({ title, mensaje }) => {
    seterror({ title, mensaje });
    setTimeout(() => {
      seterror({ title: '', mensaje: '' });
    }, 3000);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <form
      style={{
        margin: '15px',
      }}
      className={classes.root}
      noValidate="noValidate"
      autoComplete="off"
    >
      <Loading title="Cargando..." loading={isLoading}>
        <>
          {/* Warning on exit page */}
          {/* Form stateL: {formDirty ? 'form dirty' : 'form no dirty'} */}
          <WarningLeaveFormPage when={formDirty} />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '300px',
              margin: 'auto',
            }}
          >
            {valores.urlimagen === 'n/a' && (
              <>
                <img
                  //src="/assets/images/medpal.png"
                  src={medpalImg}
                  alt="medicamento"
                  style={{ margin: 'auto', width: '100px', height: '100px' }}

                />
                <TextField
                  label="Subir imagen del producto"
                  id="urlimagen"
                  name="urlimagen"
                  type="file"
                  hidden
                  InputLabelProps={{ shrink: true }}
                  onChange={handleFileSelected}
                  value={''}
                  error={errors.urlimagen}
                />
                {errors.urlimagen && <FormErrorLabel>{errors.urlimagen}</FormErrorLabel>}
              </>
            )}
            {valores.urlimagen !== 'n/a' && (
              <>
                <img
                  // src="../../assets/images/medpal.png"
                  src={imgTemp ? imgTemp : `${urlBucket}${valores.urlimagen} `}
                  alt="medicamento"
                  style={{ margin: 'auto', border: '1px solid black', width: '100px', height: '100px' }}

                />
                <TextField
                  label="Subir imagen del producto"
                  id="urlimagen"
                  name="urlimagen"
                  type="file"
                  hidden
                  InputLabelProps={{ shrink: true }}
                  onChange={handleFileSelected}
                  value={''}
                  error={errors.urlimagen}
                />
                {errors.urlimagen && <FormErrorLabel>{errors.urlimagen}</FormErrorLabel>}
              </>

            )}
          </div>
          <GridContainer>
            <GridItem xs={6}>
              <TextField
                label="Nombre"
                id="nombre"
                name="nombre"
                type="text"
                fullWidth
                onChange={handleChange}
                value={valores.nombre}
                error={errors.nombre}
              />
              {errors.nombre && <FormErrorLabel>{errors.nombre}</FormErrorLabel>}
            </GridItem>
            <GridItem xs={6}>
              <TextField
                label="Composición"
                id="composicion"
                name="composicion"
                type="text"
                fullWidth
                onChange={handleChange}
                value={valores.composicion}
                error={errors.composicion}
              />
              {errors.composicion && <FormErrorLabel>{errors.composicion}</FormErrorLabel>}
            </GridItem>
            <GridItem xs={6}>
              <TextField
                label="Función"
                id="funcion"
                name="funcion"
                type="text"
                fullWidth
                onChange={handleChange}
                value={valores.funcion}
                error={errors.funcion}
              />
              {errors.funcion && <FormErrorLabel>{errors.funcion}</FormErrorLabel>}
            </GridItem>
            <GridItem xs={6}>
              <TextField
                label="Presentación"
                id="presentacion"
                name="presentacion"
                type="text"
                fullWidth
                onChange={handleChange}
                value={valores.presentacion}
                error={errors.presentacion}
              />
              {errors.presentacion && <FormErrorLabel>{errors.presentacion}</FormErrorLabel>}
            </GridItem>
            <GridItem xs={6}>
              <TextField
                label="Dosificación"
                id="dosificacion"
                name="dosificacion"
                type="text"
                fullWidth
                onChange={handleChange}
                value={valores.dosificacion}
                error={errors.dosificacion}
              />
              {errors.dosificacion && <FormErrorLabel>{errors.dosificacion}</FormErrorLabel>}
            </GridItem>
            <GridItem xs={6}>
              <TextField
                label="Casa comercial"
                id="casa_comercial"
                name="casa_comercial"
                type="text"
                fullWidth
                onChange={handleChange}
                value={valores.casa_comercial}
                error={errors.casa_comercial}
              />
              {errors.casa_comercial && <FormErrorLabel> {errors.casa_comercial}</FormErrorLabel>}
            </GridItem>
            <GridItem xs={6}>
              <TextField
                label="Contraindicaciones"
                id="contraindicaciones"
                name="contraindicaciones"
                type="text"
                fullWidth
                onChange={handleChange}
                error={errors.contraindicaciones}
                value={valores.contraindicaciones}
              />
              {errors.contraindicaciones && (
                <FormErrorLabel>{errors.contraindicaciones}</FormErrorLabel>
              )}
            </GridItem>
            <GridItem xs={6}>
              <TextField
                label="Notas"
                id="notas"
                name="notas"
                type="text"
                fullWidth
                onChange={handleChange}
                error={errors.notas}
                value={valores.notas}
              />
              {errors.notas && <FormErrorLabel>{errors.notas}</FormErrorLabel>}
            </GridItem>
          </GridContainer>
          <div
            style={{ border: '1px black solid', width: '500px', margin: '25px auto', textAlign: 'center' }}
          >
            <span style={{ fontSize: 'small' }}>
              {' '}
              {unObjeto.doctor_id === undefined
                ? 'Se agregará un nuevo registro a mi vademécum'
                : 'Se editará el registro en mi vademécum'}
            </span>
          </div>
          <div
            style={{
              margin: '20px 8px',
              flex: '1',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button variant="outlined" color="warning" onClick={() => handleClick()}>
              {unObjeto.doctor_id === undefined ? 'Guardar' : 'Editar'}
            </Button>
            {borrar === 'doctor' &&
              <Button variant="outlined" color="secondary" onClick={() => setOpenModal(true)}>
                Eliminar
              </Button>
            }
          </div>
        </>
      </Loading>
      <ConfirmationDialog
        id={`${unObjeto.id ? unObjeto.id : 'newVademecum'}dialog`}
        keepMounted
        open={openModal}
        mensaje="este medicamento?"
        onClose={handleClose}
        onConfirm={handleClickEliminar}
      />
      {error.mensaje && (
        <Alert severity={`${[error.title]}`}>
          <AlertTitle>{error.title === 'error' ? 'Error' : 'Success'}</AlertTitle>
          {error.mensaje}
        </Alert>
      )}
    </form>
  );
};

export default FormVadecum;
