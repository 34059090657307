import Notificacion from "components/ui/Notificacion";
import { useState } from "react";
import { useSelector } from "react-redux";
import { validacionesReceta } from "utils/validaciones";

export const useRecetaForm = () => {
  // Receta data
  const [cie10Data, setCie10Data] = useState([]);
  const [vademecumData, setVademecumData] = useState([]);
  const [includesReceta, setIncludesReceta] = useState(true);
  const [errorVademecum, setErrorVademecum] = useState([]);//
  const [recetaFormErrors, setRecetaFormErrors] = useState({ title: "", mensaje: "", html: false });//
  const [recetaFormValues, setRecetaFormValues] = useState({//
    cie: "",
    indicaciones: "",
    presuntivoDefinitivo: null
  });



  const { data: dataCliente } = useSelector(
    (state) => state.data_clientes
  );

  const getRecetaFormData = async () => {
    try {
      let stringErrorEmptyVademecum = "";
      let cie10Vademecums = [];

      let stringErrorVademecumFormValidation = "";

      if (cie10Data.length === 0) return {};


      //Recorrer lista de cie10 seleccionados por el usuario
      for (let i = 0; i < cie10Data.length; i++) {
        // let bandErrorFormVademecum = false;
        const cie = cie10Data[i];

        // Verificar si no existen registros de vademécum agregados en el actual cie10, y concatenar mensajes de error
        // Validar campos de form de vademécum

        cie10Vademecums.push({
          cie10: cie,
          // recetaDetalleCie10: cie10Medicinas[i]
        })
      }

      if (cie10Vademecums.length === 0) {
        Notificacion({
          type: "error",
          text: "Debe seleccionar por lo menos un cie10.",
        })
        throw (new Error('Debe seleccionar por lo menos un cie10.'))
      }

      if (includesReceta) {
        const valReceta = validacionesReceta(vademecumData);
        setErrorVademecum(valReceta);
        //Recorrer arreglo de validaciones de campos
        let errrorVademecumFlag = false;
        valReceta.map(valMed => {
          if (Object.keys(valMed).length > 0) errrorVademecumFlag = true;
          return null;
        });
        if (errrorVademecumFlag) {
          Notificacion({
            type: "error",
            text: "Existen campos no válidos en el formulario de vademécum",
          });
          throw (new Error('Existen campos no válidos en el formulario de vademécum.'))
        }
      }

      // Mostrar mensaje de error en caso de que exista por lo menos un cie10 que no tenga por lo menos un registreo de vademécum
      if (stringErrorEmptyVademecum || stringErrorVademecumFormValidation) {
        setRecetaFormErrors({ title: "error", mensaje: `${stringErrorEmptyVademecum} ${stringErrorVademecumFormValidation}`, html: true });
        throw (new Error('Error al validar campos de receta'));
      }


      // Verificar errors en formulario de consulta
      // const formValues = await getConsultaValues();
      // if (!formValues) return;

      setRecetaFormErrors({ title: "", mensaje: "", html: false });

      // let imc = '';
      // if (formValues.antropometria_peso && formValues.antropometria_talla) {
      //   imc = parseFloat(formValues.antropometria_peso) / parseFloat(Math.pow(parseFloat(formValues.antropometria_talla) / 100, 2))
      // }

      const medidas = {
        alergias: dataCliente.alergias,
        // peso: formValues.antropometria_peso,
        // talla: formValues.antropometria_talla,
        // imc,
        indicaciones: recetaFormValues.indicaciones
      }

      const receta = { cie10: cie10Vademecums, ...medidas, detalle: includesReceta ? vademecumData : [] };

      const dataToSave = { receta }
      return dataToSave;

      // onSubmit(dataToSave);
    } catch (error) {
      throw (error);
    }
  }

  return {
    // Cie10
    cie10Data,
    setCie10Data,
    // Vademecum
    vademecumData,
    setVademecumData,
    errorVademecum,
    setErrorVademecum,

    // Receta
    includesReceta,
    setIncludesReceta,
    recetaFormErrors,
    setRecetaFormErrors,
    recetaFormValues,
    setRecetaFormValues,

    getRecetaFormData
  }
}
