import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AutocompleteComponent } from "components/ui/AutocompleteComponent";
import { setCliente } from 'redux/actions/aClientes';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { getClientesPorBusqueda } from 'redux/actions/aClientes';
import { ClienteForm } from "components/forms/ClienteForm";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Button from "components/CustomButtons/Button.js";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const ModalCliente = ({ open, setOpen, dataCliente, doctorId }) => {

  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const { clients_search } = useSelector((state) => state.data_clientes);
  const history = useHistory();

  const handleClose = () => {
    setOpen("");
  };
  const handleBuscado = (event, values, esNuevo) => {
    if (values !== null) {
      dataCliente(values, open);
      dispatch(setCliente(values));
    }
  };

  const handleShowForm = () => setShowForm(!showForm);

  const handleShowFormCerrar = () => {
    setShowForm(false);
  };

  const handleSearchPacientes = useCallback(
    async (value) => dispatch(getClientesPorBusqueda(value, doctorId)),
    [dispatch, doctorId],
  );

  const handleNavToPacientesForm = () => {
    localStorage.setItem('redirectDoctorPacientesForm', 'secretaria:cajas');
    history.push('/secretaria/pacientes-atendidos');
  }


  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={!!open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">Clientes</DialogTitle>
      <DialogContent>
        {
          showForm ?
            <ClienteForm setShowForm={handleShowFormCerrar} />
            :
            <AutocompleteComponent
              freeSolo={true}
              poblarData={[]}
              reduxFunction={false}
              onBuscadoChange={(event, values) =>
                handleBuscado(event, values, true)
              }
              optionLabel={(option) =>
                `${option.cedula_identidad} ${option.nombres} ${option.apellidos} `
              }
              placeholder="CI/Nombres/Apellidos"
              dispatchFunction={handleSearchPacientes}
              dataSearch={clients_search}
            />
        }

        {
          showForm ?
            <IconButton color="error" onClick={() => handleShowForm(true)} style={{ color: 'red' }}>
              Cancelar
            </IconButton> :
            <IconButton
              style={{ marginTop: "10px", justifyContent: "flex-end" }}
              onClick={handleNavToPacientesForm}
            >
              <AddCircleIcon />
              Añadir pacientes
            </IconButton>
        }

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
