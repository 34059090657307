import { handleActions } from 'redux-actions';
import { citas, isLoadingCitas, hasErrorCitas, updateEstadoDisponible, updateEstado, updateRazonNoDisponible } from '../actions/aCitas';

export const datos_citas = handleActions(
  {
    [citas]: (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
      hasError: false,
    }),
    [isLoadingCitas]: state => ({ ...state, isLoading: true, hasError: false }),
    [hasErrorCitas]: (state, action) => ({
      ...state,
      isLoading: false,
      hasError: action.payload,
    }),
    [updateEstadoDisponible]: (state, action) => ({
  
      
      ...state,
      isLoading: false,
      hasError: false,
      data: state.data.map( 
        cita => 
         (action.payload.id === cita.r_id) ? { ...cita, r_disponible: action.payload.disponible } : {...cita}
      ),
    }),
    [updateEstado]: (state, action) => ({
      ...state,
      isLoading: false,
      hasError: false,
      data: state.data.map( 
        cita => 
         (action.payload.id === cita.r_id) ? { ...cita, 
          r_estado_reserva: action.payload.estado_reserva, 
          r_disponible: action.payload.disponible,
          r_razon_no_disponibilidad: action.payload.razonNoDisponibilidad
         } : {...cita}
      ),
    }),
    [updateRazonNoDisponible]: (state, action) => ({
      ...state,
      isLoading: false,
      hasError: false,
      data: state.data.map( 
        cita => 
         (action.payload.id === cita.r_id) ? { ...cita, 
          r_razon_no_disponibilidad: action.payload.razonNoDisponibilidad
         } : {...cita}
      ),
    }),
  },
  { isLoading: false, hasError: false, data: [] }
);
