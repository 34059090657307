export const dataLogin = {
  id: '',
  titulo_honorifico: '',
  nombres: '',
  apellidos: '',
  declaracion_profesional: '',
  practica_desde: '',
  numero_telefono: '',
  login: '',
  password_hash: '',
  email: '',
  image_url: '',
  lang_key: '',
  foto_perfil: '',
  doctor_id: '',
  isLogin: false,
};

//No se la data que me regresa
export const dataRegistro = {
  nombre: '',
  apellido: '',
  email: '',
  usuario: '',
  password_hash: '',
  isRegistro: false,
};

//Data para las citas reservadas
export const dataCitas = {
  reserva_id: '',
  nombre: '',
  inicio: '',
  fin: '',
  hora: '',
  phone_number: '',
  referencias_fisicas: '',
  estado_reserva: '',
  cliente_id: '',
};
