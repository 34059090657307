import CRUD from 'components/CRUD/Crud.component';
import { urlLoadInstitucionSolicitudes } from 'constants/urls';
import React, { useCallback, useMemo } from 'react'
import CustomButton from "components/CustomButtons/Button";
import { useDispatch } from 'react-redux';
import { aceptarSolicitudAsociacion } from 'redux/actions/aInstitucion';
import { useState } from 'react';
import Loading from 'components/Loading';
import { eliminarSolicitudAsociacionDoctorOficina } from 'redux/actions/aInstitucion';
import ConfirmDialog from 'components/Admin/CRUD/ConfirmDialog';

export const InstitucionAsociacionesSolicitudes = ({ tableData }) => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [itemSelected, setItemSelected] = useState(null);

  // Verificar si existe un evento de notificación de solicitud, y filtrar por parámetros de notificación
  const getDefaultSearchText = useCallback(
    () => {
      if (localStorage.getItem('notificationSelected')) {
        const notificacion = JSON.parse(localStorage.getItem('notificationSelected'));
        if (notificacion.route === '/institucion/profesionales') {
          localStorage.removeItem('notificationSelected');
          return notificacion.entity_id;
        }
      }
      return ''
    },
    [],
  )

  const data = useMemo(() => ({
    // defaultSortByColumn: 'registro_date',
    defaultSortOrder: 'desc',
    defaultSearchText: getDefaultSearchText(),
    tableData,
    urls: {
      url: urlLoadInstitucionSolicitudes,
    }
  }), [tableData, getDefaultSearchText])


  const handleConfirmRequest = async (item) => {
    console.log(item)
    setLoading(true);
    await dispatch(aceptarSolicitudAsociacion(item.oficina_id, item.doctor_id));
    setLoading(false);
    window.location.reload()
  }

  const handleOpenConfirmDeleteDialog = (item) => {
    setItemSelected(item);
    setOpenConfirmDialog(true);
  }

  const handleDeleteRequest = async () => {
    const item = itemSelected;
    setLoading(true);
    await dispatch(eliminarSolicitudAsociacionDoctorOficina(item.oficina_id, item.doctor_id));
    setLoading(false);
  }

  return (
    <>
      <ConfirmDialog title={"Confirmación de eliminación"} open={openConfirmDialog}
        setOpen={setOpenConfirmDialog} onConfirm={handleDeleteRequest}  >
        <div>¿Esta seguro/a de cancelar esta solicitud?</div>
      </ConfirmDialog>
      <Loading loading={loading}>
        <CRUD data={data} hideEditButton hideDeleteButton showNewButton={false}
          optionsButtonsCallback={(item) =>
            <>
              <CustomButton
                variant="contained"
                color="primary"
                size="sm"
                // className={classes.button}
                // startIcon={<QuestionAnswerIcon />}
                onClick={() => handleConfirmRequest(item)}
              >
                Aceptar
              </CustomButton>
              <CustomButton
                variant="contained"
                color="danger"
                size="sm"
                // className={classes.button}
                // startIcon={<QuestionAnswerIcon />}
                onClick={() => handleOpenConfirmDeleteDialog(item)}
              >
                Rechazar
              </CustomButton>
            </>

          } />
      </Loading>
    </>


  )
}
