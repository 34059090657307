/*eslint-disable*/
import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import styles from 'assets/jss/material-dashboard-react/components/footerStyle.js';
import { Link } from 'react-router-dom'

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  return (
    <footer className="footer">

      <p className='opcionesmedpal' style={{ margin: 'auto' }}> <Link to="/terminosCondiciones">Términos y Condiciones </Link></p >
      <p className='medpal'>Medpal &copy; {1900 + new Date().getYear()} </p>

    </footer>
  );
}
