import React from "react";
import moment from "moment";

//Components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
//Custom Components
import { CitasTableRow } from "./CitasTableRow";

import { TableContainer } from "components/Table/TableContainer";

const useStyles = makeStyles(styles);

const TableCellHeader = withStyles((theme) => ({
  head: {
    // backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: "bolder",
  },
  body: {
    fontSize: 15,
  },

}))(TableCell);

export const TableCitas = ({ titulos, citas }) => {
  const classes = useStyles();
  const citasActivas = citas.filter(c => moment(new Date(c.r_inicio)).isSameOrAfter(moment()) && c.r_estado_reserva === "APROBADO");
  const citasAnteriores = citas.filter(c => moment(new Date(c.r_inicio)).isBefore(moment()) || (moment(new Date(c.r_inicio)).isSameOrAfter(moment()) && c.r_estado_reserva !== "APROBADO"));
  const citasOrder = [...citasActivas, ...citasAnteriores];

  return (
    <TableContainer>
      <Table className={classes.table}>
        {titulos ? (
          <TableHead className={classes[`${'warning'}${'TableHeader'}`]}>
            <TableRow className={classes.tableHeadRow}>
              {titulos.map((prop, key) => {
                return (
                  <TableCellHeader
                    align="left"
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCellHeader>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {citasOrder.map((cita, indice) =>

            <CitasTableRow cita={cita} key={indice} />
          )}
        </TableBody>
      </Table>
    </TableContainer>


  );
};

export default TableCitas;
