import { backgroundColor } from "assets/jss/material-dashboard-react"

const paymentStyle = theme => ({
  mainContainer: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: "center",
    background: backgroundColor,

  },
  paymentContainer: {
    display: "flex",
    justifyContent: "center",
    margin: '10px',
  },
  confirmPaymentContainer: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: "center",
    textAlign: 'center',
    background: backgroundColor,
    padding: '10px'
  }
});

export default paymentStyle;