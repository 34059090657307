import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from "components/CustomButtons/Button.js";
import { FormRecetarioImprimirFirma } from 'components/Medico/Receta/FormRecetarioImprimirFirma';
import { CertificadoMedico } from '../certificado/CertificadoMedico';
import { CardContent, makeStyles } from '@material-ui/core';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import { ModalContainer } from 'components/Modal/ModalContainer';
import { DoctorBilling } from 'components/Billing/DoctorBilling';

const useStyles = makeStyles((theme) => (
  {
    ...styles,
    button: {
      margin: "5px",
    },
  }
));

const ConsultaResumen = ({ medicinas, medidas, cie10, alergias, receta }) => {

  const classes = useStyles();

  const [showCertForm, setShowCertForm] = useState(false);

  const [openModalBilling, setOpenModalBilling] = useState(false);

  const cliente = useSelector(state => state.data_clientes.data);

  const imc =
    parseFloat(medidas.peso) / parseFloat(Math.pow(parseFloat(medidas.talla), 2));

  const data = {
    ...receta,
    imc,
    pa: medidas.pa,
    peso: medidas.peso,
    talla: medidas.talla,
    indicaciones: medidas.indicaciones,
    alergias,
    cie10,
    detalle: medicinas,
    cliente,
  }

  return (
    <div style={{ width: '100%', flexDirection: 'column' }}>
      {/* Formulario email y firma */}
      <FormRecetarioImprimirFirma data={data} showPreview />

      {/* Certificado Médico */}
      {
        showCertForm &&
        <Card style={{ marginBottom: '20px' }}>
          <CardHeader color="primary">
            <strong className={classes.cardTitle}>Certificado Médico</strong>
          </CardHeader>
          <CardContent>
            <CertificadoMedico />
          </CardContent>

        </Card>
      }

      {/* Buttons */}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button className={classes.button} style={{}} variant="contained" color="primary" onClick={() => window.location.reload(false)}>
          Finalizar
        </Button>
        {
          !showCertForm &&
          <Button className={classes.button} style={{}} variant="contained" color="primary" onClick={() => setShowCertForm(true)}>
            Certificado
          </Button>
        }

        <Button className={classes.button} variant="contained" color="primary" onClick={() => setOpenModalBilling(true)}>Factura</Button>

      </div>

      <ModalContainer
        open={openModalBilling}
        onCloseModal={() => setOpenModalBilling(false)}
        title={'Sistema de facturación'}
      >
        <DoctorBilling consultaId={data?.consultaId} />
      </ModalContainer>


    </div>
  );
};

export default ConsultaResumen;
