import React from 'react';
import { useSelector } from 'react-redux';
import SelectMedico from 'components/SeleccionarMedico/SelectMedico.component';
import PacientesAtendidos from 'components/Pacientes/PacientesAtendidos/PacientesAtendidos.component';

export const PacientesDoctorSecretaria = () => {

  const { doctorSelected } = useSelector(state => state.medicos_secretaria);

  return (
    <>
      <SelectMedico />
      {doctorSelected && <PacientesAtendidos doctor_id={doctorSelected.id} secretaryRol />}
    </>
  );

};
