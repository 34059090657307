


import React from 'react';
import CRUD from 'components/CRUD/Crud.component';
import { urlCrearSeguro, urlEditarSeguro, urlEliminarSeguro, urlListarSeguros } from 'constants/urls';

const SegurosCrud = () => {
  const tableData = [
    {
      type: "inputDisabled",
      label: "Id",
      name: 'id',
      showOnCreate: false,
      showOnTable: true,
      tableProps: {
        title: 'Id',
        sort: {
          sortColumnName: 'id',
          sortColumnNameDB: 'id',
        }
      },

    },
    {
      type: "input",
      name: "nombre",
      label: "Nombre",
      tableProps: {
        title: 'Nombre',
        sort: {
          sortColumnName: 'nombre',
          sortColumnNameDB: 'nombre',
        }
      },
      validationOptions: {
        required: {
          value: true,
          message: 'El campo nombre es requerido',
        },
      },

    },
  ];

  const data = {
    tableData,
    urls: {
      createUrl: urlCrearSeguro,
      updateUrl: urlEditarSeguro,
      readUrl: urlListarSeguros,
      deleteUrl: urlEliminarSeguro,
    }
  }

  return (
    <CRUD data={data} />
  )
}

export default SegurosCrud;
