import { Typography, makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1.3),
    },
  },
  container: {
    padding: '20px',
  },
  cardContainer: {
    cursor: 'pointer',
    padding: '16px',
    boxShadow: "rgba(0, 0, 0, 0.14) 0px 1px 4px 0px",
    height: '90px',
    width: '200px',
    margin: '10px',
    borderRadius: '6px',
  },
  cardContainerSelected: {
    border: "2px solid green",
    boxShadow: "rgba(0, 0, 0, 0.14) 0px 10px 4px 0px",
  },
  cardContainerDisabled: {
    boxShadow: 'rgba(0, 0, 0, 0.14) 0px 1px 4px 0px',
    textDecoration: 'line-through',
    cursor: 'not-allowed',
    borderRightColor: 'red'
  }

}));

export const OficinaCardCheck = ({
  data,
  officinasIdsSelected = [],
  handleAddOfficeId,
  handleDeleteOfficeId,
  asociaciones = [],
  asociacionesSolicitudes = [],

}) => {

  const classes = useStyles();

  const isSelected = useMemo(() =>
    (officinasIdsSelected.includes(data.id)),
    [officinasIdsSelected, data]
  );

  const asociacionGuardada = useMemo(() =>
    (asociaciones.includes(data.id)),
    [asociaciones, data]
  );

  const asociacionSolicitudGuardada = useMemo(() =>
    (asociacionesSolicitudes.includes(data.id)),
    [asociacionesSolicitudes, data]
  );

  const handleClick = () => {
    if (asociacionGuardada || asociacionSolicitudGuardada) return;
    if (isSelected)
      handleDeleteOfficeId(data.id)
    else
      handleAddOfficeId(data.id)
  }

  const especialidad = useMemo(() => data.doctorEspecializaciones ? data.doctorEspecializaciones[0]?.especializacion : null, [data]);

  return (
    <div className={`${classes.cardContainer} ${isSelected && classes.cardContainerSelected} ${(asociacionGuardada || asociacionSolicitudGuardada) && classes.cardContainerDisabled}`} onClick={handleClick}>
      {
        especialidad &&
        <Typography >
          <strong>{especialidad.nombre}</strong>
        </Typography>
      }
      <Typography >
        {data.ciudad} - {data.pais}
      </Typography>
      <Typography >
        {data.direccion}
      </Typography>
      <Typography >
        {data.referencias_fisicas}
      </Typography>
    </div>
  )
}
