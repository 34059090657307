import { makeStyles, Paper, withStyles } from '@material-ui/core';
import React, { useMemo, useState } from 'react'
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { IconButton } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";

//Icons
import Close from "@material-ui/icons/Close";
import { calcularPrecioFinal } from "utils/calculos";
import { TableContainer } from "components/Table/TableContainer";
import { CheckIcon } from "components/icons/CheckIcon";
import { useDispatch, useSelector } from 'react-redux';
import ConfirmDialog from "components/Admin/CRUD/ConfirmDialog";
import { eliminarAfiliacionDoctorOficina } from 'redux/actions/aMedico';
import Loading from 'components/Loading';

const useStyles = makeStyles(styles);
const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    fontWeight: "bolder",
  },
  body: {
    fontSize: 15,
  },
}))(TableCell);

export const OficinasAfiliadasTable = ({ data, setOficinasAfiliadas }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const { descuento_afiliado } = useSelector(state => state.data_empresa.data);
  // const { doctor_id, med_activo } = useSelector(state => state.dataUser.data);
  const [loading, setLoading] = useState(false);

  const [oficinaIdSelected, setOficinaIdSelected] = useState(null);

  const titulos = useMemo(() => [
    'Nombre',
    'Tiempo por Paciente',
    'Afiliación',
    'Precio consulta afiliado',
    'Precio consulta',
    'Dirección',
    'Ciudad',
    'Configuración',
  ], []);

  const handleDeleteAfiliacion = async () => {
    if (oficinaIdSelected) {
      setLoading(true);
      await dispatch(eliminarAfiliacionDoctorOficina(oficinaIdSelected));
      setLoading(false);

      deleteOficinaFromLocalData(oficinaIdSelected);
      setOficinaIdSelected(null);
    }
  }

  const deleteOficinaFromLocalData = (oficinaId) => {
    const newData = data.filter(item => item.id !== oficinaId);
    setOficinasAfiliadas(newData);
  }

  return (
    <Loading loading={loading} title="Cargando...">
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          {titulos !== undefined ? (
            <TableHead className={classes[`${"warning"} ${"TableHeader"}${"primary"}`]}>
              <TableRow className={classes.tableHeadRow}>
                {titulos.map((prop, key) => {
                  // if (prop === 'Precio consulta afiliado' && !med_activo) return null;
                  return (
                    <StyledTableCell
                      align="left"
                      className={classes.tableCell + " " + classes.tableHeadCell}
                      key={key}
                    >
                      {prop}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {data.map((oficina, indice) => (
              <TableRow key={oficina.id} className={classes.tableBodyRow}>
                <TableCell className={classes.tableCell}>
                  {oficina.nombre}
                </TableCell>

                <TableCell className={classes.tableCell}>
                  {oficina.tiempo_por_cliente_minutos} minutos
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {oficina.afiliacion ? <CheckIcon style={{ fontSize: 40 }} /> : ''}
                </TableCell>
                {
                  // med_activo &&
                  <TableCell className={classes.tableCell}>
                    {/* {oficina.costo_primera_consulta ? `$${oficina.costo_primera_consulta}` : ''} */}
                    ${calcularPrecioFinal(oficina.costo_consulta, descuento_afiliado)}
                  </TableCell>
                }
                <TableCell className={classes.tableCell}>
                  ${oficina.costo_consulta}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {oficina.direccion}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {oficina.ciudad}
                </TableCell>
                {/* <TableCell className={classes.tableCell}>
            {oficina.referencias_fisicas}
          </TableCell> */}
                <TableCell className={classes.tableActions}>
                  <Tooltip
                    id="tooltip-eliminar"
                    title="Eliminar afiliación"
                    placement="top"
                    onClick={() => {
                      setOficinaIdSelected(oficina.id)
                      setOpenConfirmDialog(true)
                    }}
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <IconButton
                      aria-label="Close"
                      className={classes.tableActionButton}
                    >
                      <Close
                        className={
                          classes.tableActionButtonIcon + " " + classes.close
                        }
                      />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>

            ))}

            <ConfirmDialog
              title={"Confirmación de eliminación"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={handleDeleteAfiliacion}
            >
              <div>¿Esta seguro de eliminar la afiliación?</div>
            </ConfirmDialog>

          </TableBody>
        </Table>
      </TableContainer>

    </Loading>

  )
}
