import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TableHead, TableSortLabel } from "@material-ui/core";
import moment from "moment";
import _ from 'lodash';

import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CustomTableCellCrud from "components/Admin/CRUD/CustomTableCellCrud";

import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';

import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Alert } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';

import Loading from 'components/Loading';
import { TableRowCrud } from 'components/Admin/CRUD/TableRowCrud';
import { FormCrud } from 'components/Admin/CRUD/FormCrud';
import ConfirmDialog from 'components/Admin/CRUD/ConfirmDialog';

import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { loadDoctoresPromociones } from 'redux/actions/aAdmin';
import { editarDoctorServicio } from 'redux/actions/aAdmin';
import { eliminarDoctorServicio } from 'redux/actions/aAdmin';
import { calcularPrecioFinal } from 'utils/calculos';
import Notificacion from 'components/ui/Notificacion';
import { AdminLayout } from './AdminLayout';
import { SearchTextField } from 'components/forms/input/SearchTextField';
import { TableContainer } from 'components/Table/TableContainer';
let useStyles = makeStyles(styles);

const TableCellHeader = withStyles((theme) => {
  console.log("theme:", theme);
  return {
    head: {
      color: theme.palette.common.white,
      fontWeight: "bolder",
    },
  };
})(TableCell);

const rowsPerPageDefault = 20;

const DoctorPromocionesCrud = () => {
  const classes = useStyles();
  // 0: tabla, 1:crear, 2: editar
  const [view, setView] = useState(0);
  const [itemSelected, setItemSelected] = useState({});

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  //Ordenamiento
  const [orderData, setOrderData] = useState({
    sortBy: 'ds.id', // default sort column
    sortOrder: "asc" // default sort oder
  })

  const { sortBy, sortOrder } = orderData;
  const dispatch = useDispatch();
  const { dataCRUD, dataPagination } = useSelector(state => state.data_admin);
  const [especializacionesSelected, setEspecializacionesSelected] = useState([]);

  const { total } = dataPagination;


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [skipLocal, setSkipLocal] = React.useState(0);

  const handleInputSearchChange = ({ target }) => {
    setSearch(target.value);
    debouncedSearch(target.value);
  }

  const debouncedSearch = React.useRef(
    _.debounce(async (searchValue) => {
      setSearchValue(searchValue);
    }, 350)
  ).current;

  const initialStateFormComponents = useMemo(() => (
    [
      {
        type: "inputDisabled",
        label: "Id",
        value: itemSelected.id,
        showOnCreate: false
      },
      {
        type: "input",
        name: "descuento_valor",
        label: "Valor descuento",
        value: itemSelected.descuento_valor ? itemSelected.descuento_valor : "",
      },
      {
        type: "date",
        name: "descuento_inicio",
        label: "Fecha Inicio",
        value: itemSelected.descuento_inicio ? itemSelected.descuento_inicio : new Date(),
      },
      {
        type: "date",
        name: "descuento_fin",
        label: "Fecha Fin",
        value: itemSelected.descuento_fin ? itemSelected.descuento_fin : new Date(),
      },
      {
        type: "input",
        name: "promocion_detalles",
        label: "Detalles",
        value: itemSelected.promocion_detalles ? itemSelected.promocion_detalles : new Date(),
      },

    ]
  ), [itemSelected]);

  const handleChangePage = useCallback(
    (event, newPage) => {
      if (page > newPage) {
        setSkipLocal(skipLocal - rowsPerPage);
      } else {
        setSkipLocal(skipLocal + rowsPerPage);
      }
      setPage(newPage);
    },
    [skipLocal, page, rowsPerPage],
  )

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
      setSkipLocal(0);
    },
    [],
  )
  const handleClickEdit = useCallback(
    (item) => {
      setItemSelected(item);
      setEspecializacionesSelected(item.especializaciones);
      setView(2);
    },
    [],
  )

  const handleClickDelete = useCallback(
    item => {
      setItemSelected(item);
      setOpenConfirmDialog(true);
    },
    [],
  )

  const handleClickReturn = useCallback(
    () => {
      setItemSelected({});
      setEspecializacionesSelected([]);
      setView(0);
    },
    [],
  )

  const onSubmitForm = useCallback(
    async (data, type) => {
      let dataForm = { ...data, especializaciones: especializacionesSelected };

      // console.log({dataForm})
      // return
      if (type === "editar") {
        (!loading) && setLoading(true);
        let respuesta = await dispatch(editarDoctorServicio(itemSelected.id, dataForm));
        setLoading(false);
        const { transaccion, mensaje } = respuesta;
        !transaccion ? Notificacion({ type: "error", text: mensaje, }) : setError(false);
      }
    },
    [loading, itemSelected, especializacionesSelected, dispatch],
  )

  const handleConfirmDelete = useCallback(
    async () => {
      (!loading) && setLoading(true);
      let respuesta = await dispatch(eliminarDoctorServicio(itemSelected.id));
      setLoading(false);
      const { transaccion, mensaje } = respuesta;
      !transaccion ? setError(mensaje) : setError(false);
    },
    [loading, itemSelected, dispatch],
  )

  //Ordenamiento
  const handleRequestSort = useCallback(
    async (sortByColumn) => {
      // console.log(sortBy);
      // console.log(sortOrder);
      let sortOrderNew;
      if (!sortBy || sortByColumn !== sortBy) {
        sortOrderNew = "asc";
        setOrderData({
          sortBy: sortByColumn, // default sort column
          sortOrder: sortOrderNew // default sort oder
        })
      } else {
        if (sortByColumn === sortBy) {
          sortOrderNew = sortOrder === "asc" ? "desc" : "asc"
          setOrderData({
            sortBy: sortByColumn, // default sort column
            sortOrder: sortOrderNew// default sort oder
          })
        }
      }
    },
    [sortBy, sortOrder],
  );

  const titulosTable = [
    { title: "Id", onClickSort: () => handleRequestSort("ds.id"), columnName: "id" },
    { title: "Nombre", onClickSort: () => handleRequestSort("ds.nombre"), columnName: "nombre" },
    { title: "Precio servicio", onClickSort: () => handleRequestSort("ds.precio"), columnName: "precio" },
    { title: "Valor descuento", onClickSort: () => handleRequestSort("ds.descuento_valor"), columnName: "descuento_valor" },
    { title: "Precio Final", onClickSort: () => null, columnName: 'pfinal' },
    { title: "Des. Fecha Inicio", onClickSort: () => handleRequestSort("ds.descuento_inicio"), columnName: "descuento_inicio" },
    { title: "Des. Fecha Fin", onClickSort: () => handleRequestSort("ds.descuento_fin"), columnName: "descuento_fin" },
    { title: "Promoción detalles", onClickSort: () => handleRequestSort("ds.promocion_detalles"), columnName: "promocion_detalles" },
    { title: "Doctor Nombres", onClickSort: () => handleRequestSort("doctor.nombres"), columnName: "doctor_nombres" },
    { title: "Doctor Apellidos", onClickSort: () => handleRequestSort("doctor.apellidos"), columnName: "doctor_apellidos" },

    { title: "Opciones" },
  ];

  const initLoadData = useCallback(
    async ({ skip = 0, take = rowsPerPageDefault, search = '', sortBy = 'ds.id', sort = 'ASC' } = {}) => {
      setLoading(true);
      let respuesta = await dispatch(loadDoctoresPromociones(skip, take, search, sortBy, sort));
      setLoading(false);
      const { transaccion, mensaje } = respuesta;

      !transaccion ? setError(mensaje) : setError(false);
    },
    [dispatch],
  )

  useEffect(() => {
    initLoadData({ skip: skipLocal, take: rowsPerPage, search: searchValue, sortBy, sort: sortOrder });
  }, [rowsPerPage, skipLocal, initLoadData, sortOrder, sortBy, searchValue]);

  return (
    <AdminLayout viewState={view}>
      <div>
        <ConfirmDialog title={"Confirmación de eliminación"} open={openConfirmDialog}
          setOpen={setOpenConfirmDialog} onConfirm={handleConfirmDelete}  >
          <div>¿Esta seguro de eliminar esta promoción?</div>
        </ConfirmDialog>
        {error && <Alert severity="error">{error}</Alert>}
        {
          view === 0 ?

            <div>

              {/* Botón buscar */}
              <SearchTextField
                value={search}
                onChange={handleInputSearchChange}
                style={{ marginTop: '25px', marginBottom: '25px' }}
              />

              <Loading title="Cargando..." loading={loading}>

                <TableContainer component={Paper}>

                  <Table className={classes.table}>

                    <TableHead className={classes[`${"warning"} ${"TableHeader"}`]}>

                      <TableRow className={classes.tableHeadRow}>
                        {titulosTable &&
                          titulosTable.map((item, index) => (
                            item.onClickSort ?
                              <TableCellHeader key={index}>
                                <TableSortLabel
                                  active={sortBy === item.columnName}
                                  direction={sortOrder}
                                  onClick={item.onClickSort}
                                  hideSortIcon={sortBy !== item.columnName}
                                  className={classes.tableCell + " " + classes.tableHeadCell}
                                >
                                  {item.title}
                                </TableSortLabel>
                              </TableCellHeader>
                              :
                              <TableCellHeader key={index} className={classes.tableCell + " " + classes.tableHeadCell}>
                                {item.title}
                              </TableCellHeader>
                          ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        dataCRUD &&
                        dataCRUD.map((item) => (
                          <TableRowCrud key={item.id} keyValue={item.id} setView={setView} item={item}
                            handleEdit={handleClickEdit} handleDelete={handleClickDelete}>
                            <CustomTableCellCrud
                              // classes={classes.sizeCell}
                              data={item.id}
                            />
                            <CustomTableCellCrud
                              // classes={classes.sizeCell}
                              data={item.nombre}
                            />
                            <CustomTableCellCrud
                              // classes={classes.sizeCell}
                              data={`$${item.precio}`}
                            />
                            <CustomTableCellCrud
                              // classes={classes.sizeCell}
                              data={`${item.descuento_valor}%`}
                            />
                            <CustomTableCellCrud
                              // classes={classes.sizeCell}
                              data={`$${calcularPrecioFinal(item.precio, item.descuento_valor)}`}
                            />
                            <CustomTableCellCrud
                              // classes={classes.sizeCell}
                              data={moment(item.descuento_inicio).format('DD/MMMM/YYYY')}
                            />
                            <CustomTableCellCrud
                              // classes={classes.sizeCell}
                              data={moment(item.descuento_fin).format('DD/MMMM/YYYY')}
                            />
                            <CustomTableCellCrud
                              // classes={classes.sizeCell}
                              data={item.promocion_detalles}
                            />
                            <CustomTableCellCrud
                              // classes={classes.sizeCell}
                              data={item.doctor?.nombres}
                            />
                            <CustomTableCellCrud
                              // classes={classes.sizeCell}
                              data={item.doctor?.apellidos}
                            />
                          </TableRowCrud>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Loading>
              <TablePagination
                rowsPerPageOptions={[10, 20, 100]}
                component="div"
                count={total ? +total : 10}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />

            </div>
            :
            (
              <FormCrud setView={setView} edit={view === 2 ? true : false}
                formComponents={initialStateFormComponents}
                onSubmitForm={onSubmitForm} handleReturn={handleClickReturn} isLoading={loading}
                hasError={error}
              />
            )
        }

      </div>
    </AdminLayout>

  )
}

export default DoctorPromocionesCrud;
