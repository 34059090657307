import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormControl, IconButton, InputLabel, MenuItem, Paper, Select } from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import ConfirmDialog from "components/Admin/CRUD/ConfirmDialog";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Loading from "components/Loading";
import WarningLeaveFormPage from "components/ui/WarningLeaveFormPage";
import { useConsultaForm } from "hooks/medico/consulta/useConsultaForm";
import CustomButton from "../../CustomButtons/Button";
import { CertificadoMedico } from "../certificado/CertificadoMedico";
import { ConsultaDatePicker } from '../consulta/date/ConsultaDatePicker';
import { ConsultaFloatMenu } from "../consulta/menu/ConsultaFloatMenu";
import { PacienteAntecedentesForm, PacienteExamenesForm, RecetarioForm, SignosVitalesAntropometriaForm, VacunasForm } from "../forms";
import { ConsultaForm } from '../forms/ConsultaForm';
import { PacienteData } from "../paciente/PacienteData";
import Receta from '../receta/Receta';
import { ConsentimientoInformado } from '../consentimiento/ConsentimientoInformado';
import { ModalContainer } from 'components/Modal/ModalContainer';
import { DoctorBilling } from 'components/Billing/DoctorBilling';
import Card from 'components/Card/Card.js';
import { ConsultaContext } from '../ConsultaStepper';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  sticky: {
    position: 'fixed',
    zIndex: 1000,
    bottom: 70,
    right: 5
  },
  formOficinaContainer: {
    margin: '60px',
    '@media (max-width: 600px)': {
      margin: '30px',
    }
  },
  floatFormOficinaContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    position: 'fixed',
    zIndex: 1000,
    top: 70,
    // '@media (max-width: 780px)': {
    //   // width: '100%'
    // },
    '@media (max-width: 600px)': {
      width: "min-content"
    }
  },
  formOficinaSelector: {
    padding: '0 10px',
    width: 'unset',
    margin: '0 auto',
  },
  formControlSelect: {
    margin: theme.spacing(1),
    minWidth: 340,
    width: '100%',
  },
  formControlSelectFloat: {
    margin: theme.spacing(1),
    width: "180px",

  },

  //Accordion
  accordionDetails: {
    width: '100%'
  }
}));


export const ConsultaPaciente = ({
  cliente_id,
  handleClickNext,
  handleBack,
  handleSetMedidas,
  handleSetCie10,
  handleSetAlergias,
  handleSetMedicinas,
  setReceta,
}) => {
  const classes = useStyles();

  const pageRef = useRef(null);

  const {
    oficinasOptions,
    serviciosOptions,
    oficinaSelected,
    servicioSelected,
    setOficinaSelected,
    setServicioSelected,
  } = useContext(ConsultaContext);

  const {
    //Props
    cie10SelectedList,
    cliente_data_state,
    collapseDateOptions,
    disabledArrowBackButton,
    disabledArrowForwardButton,
    examenes,
    examenesGrupo,
    examenesGrupoMedico,
    examenesGrupoUpdated,
    examenesUpdated,
    filterByExams,
    formConsultaDirty,
    formHistoriaMedicaDirty,
    lastReceta,
    loadingConsultas,
    loadingPagination,
    onConsultaPageOn,
    openAccordion3,
    openAccordion34,
    openAccordion4,
    openAccordion5,
    openAccordionAntecedentes,
    openAccordionExamenes,
    openAccordionVacunas,
    openModalWarning,
    openModalWarningOnLeavePage,
    receta,
    recetaData,
    selectedDate,
    consultaSelected,
    oficinaError,
    // Form dirty
    recetaDirty,


    //Methods
    handleClickArrowButton,
    handleDateChange,
    handleDeleteForm,
    handleOpenAccordions,
    handleResetExamenFisicoRegional,
    handleResetExamenOrganosSistemas,
    handleReuseLastReceta,
    onSubmit,
    resetConsultaDate,
    setAntecedentesFormDirty,
    setCertificadoFormDirty,
    setCie10SelectedList,
    setCollapseDateOptions,
    setFilterByExams,
    setloadingConsultas,
    setOpenAccordionAntecedentes,
    setOpenAccordionVacunas,
    setopenModalWarning,
    setOpenModalWarningOnLeavePage,
    setRecetaDirty,
    setVacunasFormDirty,
    updateExamenes,
    updateGrupoExamenes,


    // React hook form
    control,
    errors,
    pulso,
    presSist,
    presDias,
    temp,
    frecResp,
    oximetria,
    peso,
    talla,
    IMC,

    // Receta form
    // Cie10
    cie10Data,
    setCie10Data,
    // Vademecum
    vademecumData,
    setVademecumData,
    errorVademecum,

    // Receta
    includesReceta,
    setIncludesReceta,
    recetaFormErrors,
    recetaFormValues,
    setRecetaFormValues,

  } = useConsultaForm({
    pageRef,
    cliente_id,
    setReceta,
    handleClickNext,
    handleSetMedidas,
    handleSetCie10,
    handleSetAlergias,
    handleSetMedicinas,
    oficinaSelected,
    servicioSelected,
  });

  const [openConfirmSave, setOpenConfirmSave] = useState(false);

  const [openModalBilling, setOpenModalBilling] = useState(false);

  const divOficinaSelectorRef = useRef(null);
  const [isOficinaSelectorVisible, setIsOficinaSelectorVisible] = useState(false);
  const [showFloatOficinaSelector, setShowFloatOficinaSelector] = useState(false);

  useEffect(() => {

    const divRef = divOficinaSelectorRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsOficinaSelectorVisible(entry.isIntersecting)
      }, {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    }
    );

    if (divRef) {
      observer.observe(divRef);
    }

    return () => {
      if (divRef) {
        observer.unobserve(divRef);
      }
    };
  }, [])

  useEffect(() => {
    setShowFloatOficinaSelector(isOficinaSelectorVisible ? false : true);
  }, [isOficinaSelectorVisible])



  const verifyBeforeSubmit = (recetaForm = false) => {
    // if (!consultaDirty)
    //   setOpenConfirmSave(true);

    onSubmit(() => {
      setOpenConfirmSave(true);
    });
  }


  return (
    <div ref={pageRef}>
      {/* Warning on exit page */}
      <WarningLeaveFormPage when={formHistoriaMedicaDirty && onConsultaPageOn} />

      {/* Confirmación de guardado */}
      <ConfirmDialog title={"Confirmación de guardado"} open={openConfirmSave}
        setOpen={setOpenConfirmSave} onConfirm={() => onSubmit()}  >
        <div>No se ha guardado ninguna información en Consulta. ¿Desea continuar?.</div>
      </ConfirmDialog>

      {/* Local Modal warning on leave page */}
      <ConfirmDialog
        title={"Los datos aún no han sido guardados."}
        open={openModalWarningOnLeavePage}
        setOpen={setOpenModalWarningOnLeavePage}
        onConfirm={() => handleBack(1)}
      >
        <div>¿Está seguro que desea salir sin guardar los cambios?</div>
      </ConfirmDialog>

      {/* Modal warning */}
      <ConfirmDialog
        title={"Selección de consulta anterior"}
        open={openModalWarning}
        setOpen={setopenModalWarning}
        cancelText='Cancelar'
        confirmText='Aceptar'
        onConfirm={() => handleClickArrowButton(0)}
      >
        <div>Al cambiar a una consulta anterior la información ingresada en el formulario se perderá</div>
      </ConfirmDialog>

      {/* Modal facturación */}
      <ModalContainer
        open={openModalBilling}
        onCloseModal={() => setOpenModalBilling(false)}
        title={'Sistema de facturación'}
      >
        {
          consultaSelected
            ?
            <DoctorBilling consultaId={consultaSelected?.id} />
            :
            <></>
        }

      </ModalContainer>

      {/* Nombre paciente */}
      <div style={{ textAlign: 'center', marginBottom: "15px", }}>
        <Typography display="inline" variant="subtitle1">
          <span style={{ fontWeight: "bold" }}>Paciente: </span>{" "}
          {` ${cliente_data_state.nombres} ${cliente_data_state.apellidos}`}
        </Typography>
      </div>

      {/* Float menu: Selector de oficinas y servicios */}
      {
        showFloatOficinaSelector &&
        <div className={classes.floatFormOficinaContainer}>
          <Card className={classes.formOficinaSelector}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <FormControl className={classes.formControlSelectFloat}>
                  <InputLabel id="office-float-label">Seleccione una oficina</InputLabel>
                  <Select
                    labelId="service-float-label"
                    value={oficinaSelected ?? ''}
                    onChange={(e) => { setOficinaSelected(e.target.value) }}
                    error={oficinaError}
                  >
                    {oficinasOptions &&
                      oficinasOptions.map((oficina) => (
                        <MenuItem key={oficina.id} value={oficina.id}>{`${oficina.nombre}`}</MenuItem>
                      ))}
                  </Select>
                </FormControl>

              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <FormControl className={classes.formControlSelectFloat}>
                  <InputLabel id="service-float-label">Seleccione un servicio</InputLabel>
                  <Select
                    labelId="service-float-label"
                    value={servicioSelected}
                    onChange={(e) => { setServicioSelected(e.target.value) }}
                    disabled={serviciosOptions.length === 0}
                  >
                    <MenuItem key={0} value={0}>{`Consulta`}</MenuItem>
                    {serviciosOptions &&
                      serviciosOptions.map((servicio) => (
                        <MenuItem key={servicio.id} value={servicio.id}>{`${servicio.nombre}`}</MenuItem>
                      ))}
                  </Select>
                </FormControl>

              </GridItem>
            </GridContainer>
          </Card>
        </div>
      }

      {/* Selector de oficinas y servicios */}
      <div className={classes.formOficinaContainer} ref={divOficinaSelectorRef}>
        <GridContainer >
          <GridItem xs={12} sm={12} md={6}>
            <FormControl className={classes.formControlSelect}>
              <InputLabel id="demo-simple-select-label">Seleccione una oficina</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={oficinaSelected ?? ''}
                onChange={(e) => { setOficinaSelected(e.target.value) }}
                error={oficinaError}
              >
                {oficinasOptions &&
                  oficinasOptions.map((oficina) => (
                    <MenuItem key={oficina.id} value={oficina.id}>{`${oficina.nombre}`}</MenuItem>
                  ))}
              </Select>
            </FormControl>

          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <FormControl className={classes.formControlSelect}>
              <InputLabel id="demo-simple-select-label">Seleccione un servicio</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={servicioSelected}
                onChange={(e) => { setServicioSelected(e.target.value) }}
                disabled={serviciosOptions.length === 0}
              >
                <MenuItem key={0} value={0}>{`Consulta`}</MenuItem>
                {serviciosOptions &&
                  serviciosOptions.map((servicio) => (
                    <MenuItem key={servicio.id} value={servicio.id}>{`${servicio.nombre}`}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </GridItem>

        </GridContainer>
      </div>

      {/* Datos filiacion */}
      <Accordion defaultExpanded={false}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography className={classes.heading}>Datos filiación</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PacienteData />
        </AccordionDetails>
      </Accordion>

      {/* Antecedentes */}
      <Accordion expanded={openAccordionAntecedentes}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={() => setOpenAccordionAntecedentes(!openAccordionAntecedentes)}
        >
          <Typography className={classes.heading}>Antecedentes</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PacienteAntecedentesForm setAntecedentesFormDirty={setAntecedentesFormDirty} />
        </AccordionDetails>
      </Accordion>

      {/* Vacunas */}
      <Accordion expanded={openAccordionVacunas}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={() => setOpenAccordionVacunas(!openAccordionVacunas)}
        >
          <Typography className={classes.heading}>Vacunas</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <VacunasForm cliente_id={cliente_id} setVacunasFormDirty={setVacunasFormDirty} />
        </AccordionDetails>
      </Accordion>


      <Loading title="Cargando..." loading={loadingConsultas}>

        {/* Float menu */}
        <form>
          {openAccordion34 && (
            <Paper className={classes.sticky} >
              <IconButton variant="contained" color="primary" onClick={() => setCollapseDateOptions(true)}>
                <MenuIcon size={50} />
              </IconButton>

            </Paper>
          )
          }
          {
            (openAccordion34 || openAccordionExamenes) && (
              <ConsultaFloatMenu
                collapseDateOptions={collapseDateOptions}
                setCollapseDateOptions={setCollapseDateOptions}
                handleClickArrowButton={handleClickArrowButton}
                loadingPagination={loadingPagination}
                disabledArrowBackButton={disabledArrowBackButton}
                clienteData={cliente_data_state}
                selectedDate={selectedDate}
                handleDateChange={handleDateChange}
                disabledArrowForwardButton={disabledArrowForwardButton}
                handleDeleteForm={handleDeleteForm}
                setOpenModalWarning={setopenModalWarning}
                formConsultaDirty={formConsultaDirty}
                data={{
                  peso,
                  talla,
                  IMC,
                  pulso,
                  presSist,
                  presDias,
                  temp,
                  frecResp,
                  oximetria,
                }}
              />
            )
          }

          {/* Not visible accordion */}
          <Accordion style={{ display: 'none' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className={classes.heading}>Consulta</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                sit amet blandit leo lobortis eget.
              </Typography>
            </AccordionDetails>
          </Accordion>

          {/* Signos Vitales */}
          <Accordion expanded={openAccordion3} >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              onClick={() => handleOpenAccordions(3)}
            >
              <Typography className={classes.heading}>Signos Vitales y Antropometría</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.accordionDetails}>
                {
                  selectedDate &&
                  <div style={{ display: 'flex', flexWrap: 'wrap', }}>
                    {/* Consulta, selector de fecha */}
                    <ConsultaDatePicker
                      disabledArrowBackButton={disabledArrowBackButton}
                      disabledArrowForwardButton={disabledArrowForwardButton}
                      formConsultaDirty={formConsultaDirty}
                      handleClickArrowButton={handleClickArrowButton}
                      handleDateChange={handleDateChange}
                      handleDeleteForm={handleDeleteForm}
                      loadingPagination={loadingPagination}
                      selectedDate={selectedDate}
                      setOpenModalWarning={setopenModalWarning}
                    />
                  </div>
                }

                {/* Signos vitales y antropometría form */}
                <SignosVitalesAntropometriaForm control={control} errors={errors} classes={classes} IMC={IMC} />
              </div>

            </AccordionDetails>
          </Accordion>

          {/* Consulta */}
          <Accordion expanded={openAccordion4}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              onClick={() => handleOpenAccordions(4)}
            >
              <Typography className={classes.heading}>Consulta</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ConsultaForm control={control} errors={errors} classes={classes} handleResetExamenFisicoRegional={handleResetExamenFisicoRegional} handleResetExamenOrganosSistemas={handleResetExamenOrganosSistemas} consultaSelected={consultaSelected} />
            </AccordionDetails>
          </Accordion>

          {/* Receta consulta anterior */}
          {
            receta &&
            <Accordion defaultExpanded={true}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Receta Anterior</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Receta data={receta} />
              </AccordionDetails>
            </Accordion>
          }

          {/* Diagnostico: Receta form */}
          <Accordion expanded={openAccordion5}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              onClick={() => handleOpenAccordions(5)}
            >
              <Typography className={classes.heading}>Diagnóstico y Receta</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <RecetarioForm
                resetConsultaDate={resetConsultaDate}
                handleClickNext={handleClickNext}
                handleBack={handleBack}
                setloadingConsultas={setloadingConsultas}
                receta={recetaData}
                handleReuseLastReceta={handleReuseLastReceta}
                lastReceta={lastReceta}
                onSubmit={verifyBeforeSubmit}
                recetaDirty={recetaDirty}
                setRecetaDirty={setRecetaDirty}
                setCie10SelectedGlobalList={setCie10SelectedList}
                // Receta
                cie10Data={cie10Data}
                setCie10Data={setCie10Data}
                vademecumData={vademecumData}
                setVademecumData={setVademecumData}
                errorVademecum={errorVademecum}
                includesReceta={includesReceta}
                setIncludesReceta={setIncludesReceta}
                recetaFormErrors={recetaFormErrors}
                recetaFormValues={recetaFormValues}
                setRecetaFormValues={setRecetaFormValues}
              />
            </AccordionDetails>
          </Accordion>

          {/* Exámenes */}
          <Accordion expanded={openAccordionExamenes}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              onClick={() => handleOpenAccordions(6)}
            >
              <Typography className={classes.heading}>Exámenes</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.accordionDetails}>
                <strong>Cargar y filtrar por: </strong>

                <Button color="primary" onClick={() => { setCie10SelectedList([]); setFilterByExams(filterByExams === 1 ? 0 : 1) }}>
                  {(filterByExams === 1) ? 'Todas las consultas' : 'Consultas con examenes'}
                </Button>
                <br /><br />

                <PacienteExamenesForm
                  cie10SelectedList={cie10SelectedList}
                  data={examenes}
                  dataExamenesGrupoMedico={examenesGrupoMedico}
                  dataGrupo={examenesGrupo}
                  examenesGrupoUpdated={examenesGrupoUpdated}
                  examenesUpdated={examenesUpdated}
                  onSubmit={verifyBeforeSubmit}
                  receta={recetaData}
                  setData={updateExamenes}
                  setDataGrupo={updateGrupoExamenes}
                />
              </div>

            </AccordionDetails>
          </Accordion>

        </form>
      </Loading>


      <Accordion defaultExpanded={false}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography className={classes.heading}>Certificado Médico</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CertificadoMedico setCertificadoFormDirty={setCertificadoFormDirty} />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded={false}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography className={classes.heading}>Consentimiento Informado</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ConsentimientoInformado />
        </AccordionDetails>
      </Accordion>

      <div style={{ marginTop: '10px' }}>
        <GridContainer justifyContent="center" direction="row" >
          <GridItem xs={"auto"}>
            <CustomButton
              onClick={() => formHistoriaMedicaDirty ? setOpenModalWarningOnLeavePage(true) : handleBack(0)}
              className={classes.backButton}
              color="warning"
            >
              Nueva Consulta
            </CustomButton>
            {/* Facturación electrónica */}


          </GridItem>
          <GridItem>
            {
              consultaSelected &&
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CustomButton color="primary" onClick={() => setOpenModalBilling(true)}>Facturación</CustomButton>
              </div>
            }
          </GridItem>
        </GridContainer>
      </div>

    </div>
  );
};

export default ConsultaPaciente;
