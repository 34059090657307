import React, { useState, useEffect, useRef, useCallback } from "react";
import _ from 'lodash';
import { FormControlLabel, Switch } from "@material-ui/core";
import { TextFieldCustom as TextField } from 'components/forms/input/TextFieldCustom';
import { makeStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import Loading from "../../Loading";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "../../CustomButtons/Button";
import { validacionesOficina } from "utils/validaciones";
import Paper from "@material-ui/core/Paper";
import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import { useDispatch, useSelector } from "react-redux";
import WarningLeaveFormPage from "components/ui/WarningLeaveFormPage";
import { getSecretariasPorBusqueda } from "redux/actions/aSecretaria";
import { AutocompleteComponent } from "components/ui/AutocompleteComponent";
import GoogleMaps from "components/Maps/GoogleMaps";
import { getInstitucionPorBusqueda } from "redux/actions/aMedico";
import { ModalInfo } from "components/Modal/ModalInfo";
import imgMembership from 'assets/images/icons/afiliacion_mepal.svg';
import imgPayReservation from 'assets/images/icons/pago_reserva.svg';
import InfoIcon from '@material-ui/icons/Info';
import ConfirmDialog from "components/Admin/CRUD/ConfirmDialog";
import { FormErrorLabel } from "components/forms/labels/FormErrorLabel";
import { getOficinasEspecialidadesOpciones } from "redux/actions/aOficinas";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1.3),
    },
  },
  ...styles,
}));

const infoAfiliacion = {
  title: 'Profesionales Afiliados MedPal',
  textBody: `A los profesionales que afilien su consultorio se les otorga beneficios que comprenden una gran campaña promocional de sus servicios, tener un posicionamiento privilegiado dentro del directorio, y acceso a las herramientas de gestión de consultorio sin costo. 
Al registrarse como afiliado aprueba que los usuarios de la plataforma puedan acceder a un descuento del 50% en las consultas.
El descuento es únicamente aplicado para consulta y no se aplica a otro servicio que haya registrado.
El médico es libre de afiliarse o desafiliarse cuando lo desee, tomando en cuenta las siguientes limitaciones:
El profesional debe considerar que las plazas son limitadas.
El profesional que decide desafiliarse no podrá volver a afiliar ese consultorio en un periodo de al menos 30 días y no se podrá garantizar su cupo previamente adquirido.
Los beneficios se mantendrán para los usuarios/pacientes que hayan separado cita en el tiempo en el que el profesional está afiliado, incluso si a la fecha de realizar la consulta ya no se encuentra afiliado.
Solo los médicos que mantengan la afiliación por un tiempo prolongado, serán considerados para las campañas realizadas en redes sociales o por cualquier otro medio por parte de MedPal.`
}

const infoDesafiliacion = {
  title: 'Desafiliación MedPal',
  textBody: `Al desafiliarse debe tomar en cuenta las siguientes consideraciones:

  Al desafiliarse no podrá afiliarse en un periodo de al menos 30 días y no se podrá garantizar su cupo previamente adquirido.
  Los beneficios se mantendrán para los usuarios/pacientes que hayan separado cita en el tiempo en el que el profesional está afiliado, incluso si a la fecha de realizar la consulta ya no se encuentra afiliado.
  Solo los médicos que mantengan la afiliación por un tiempo prolongado, serán considerados para las campañas realizadas en redes sociales o por cualquier otro medio por parte de MedPal.
  `
}

const infoPagocita = {
  title: 'Pago previo de cita',
  textBody: `Al seleccionar esta opción, solicitas a tus pacientes que realicen un pago previo para aprobar la reserva de la cita. Esta funcionalidad se ha desarrollado con la finalidad de reducir el ausentismo
   en las consultas. El costo para la reserva de la cita es de $5 y es un valor estándar que forma parte del costo de la consulta, NO ES UN COSTO EXTRA. Cuando se realiza esta funcionalidad de pago online, MedPal cobra una 
   pequeña comisión del 1.25% del valor de la consulta (fuera de los valores de la pasarela de pago PayPhone), este valor será tomado en el pago para la reserva de la cita o en un pago completo de la cita.`
}

const FormOficina = ({
  doctor_id,
  agregarOficina,
  oficina,
  onCierraForm,
  afiliado,
  ...props
}) => {
  //estilos
  const classes = useStyles();
  const firstRender = useRef(true);
  const dispatch = useDispatch();

  //estados para el formulario
  const [error, seterror] = useState({ title: "", mensaje: "" });
  const [formDirty, setFormDirty] = useState(false);

  const [secretariasBusqueda, setSecretariasBusqueda] = useState([]);

  const [institucionesBusqueda, setInstitucionesBusqueda] = useState([]);

  const { data: servicios = [] } = useSelector((state) => state.data_servicios);

  // Medico
  const { data: doctorEspecialidades = [] } = useSelector((state) => state.data_especialidades);

  // Institucion
  const [especialidades, setEspecialidades] = useState([]);


  const { institucion } = useSelector(state => state.dataUser.data);

  const [dataServicios, setDataServicios] = useState([]);

  //Modal afiliado
  const [openModalMembership, setOpenModalMembership] = useState(false);
  const [openModalConfirmMembership, setOpenModalConfirmMembership] = useState(false);

  //Modal reservation
  const [openModalReservation, setOpenModalReservation] = useState(false);

  // Confirm dialog
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [specieltiesChanges, setSpecieltiesChanges] = useState(false);

  const membershipValueInit = useRef(oficina.afiliacion ?? false);

  const valoresInit = useRef({
    tiempo_por_cliente_minutos: oficina.tiempo_por_cliente_minutos ?? "",
    costo_primera_consulta: oficina.costo_primera_consulta ?? "",
    costo_consulta: oficina.costo_consulta ?? "",
    nombre: oficina.nombre ?? "",
    direccion: oficina.direccion ?? "",
    ciudad: oficina.ciudad ?? "",
    provincia: oficina.provincia ?? "",
    pais: oficina.pais ?? "",
    codigo_zip: oficina.codigo_zip ?? "",
    referencias_fisicas: oficina.referencias_fisicas ?? "",
    longitud: oficina.longitud ?? 0,
    latitud: oficina.latitud ?? 0,
    doctor_id: doctor_id,
    oficina_id: oficina.id ?? "",
    secretaria_id: oficina.secretaria_id ?? "",
    secretarias: oficina.secretarias ?? [],
    afiliacion: oficina.afiliacion ?? false,
    pago_reserva_habilitacion: oficina.pago_reserva_habilitacion ?? false,
    serviciosAsignados: !!oficina.serviciosAsignados
      ? oficina.serviciosAsignados
      : [],
    especializaciones: !!oficina.especializaciones
      ? oficina.especializaciones
      : [],
    doctorEspecializaciones: !!oficina.doctorEspecializaciones
      ? oficina.doctorEspecializaciones
      : [],
    institucion: oficina.institucion ?? null,
  })

  const [valores, setvalores] = React.useState(valoresInit.current);

  //Espero que se termine de setear los valores, para con esos valores validar
  useEffect(() => {
    if (firstRender.current) {
      return;
    }
    seterrors(validacionesOficina(valores));
  }, [valores]);

  useEffect(() => {
    //Verificar si se ha modificado el formulario
    if (_.isEqual(valores, valoresInit.current)) setFormDirty(false);
    else setFormDirty(true);
  }, [valores]);

  const [errors, seterrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setvalores({ ...valores, [name]: value });
  };

  const handleChangeSwitch = (name, values) => {
    setvalores({ ...valores, [name]: values });
  };

  const handleChangeDireccionLatLng = (
    lat, lng,
    address = { formattedAddress: null, zipCode: null, country: null, state: null, city: null },
  ) => {
    const { formattedAddress, zipCode, country, state, city } = address;
    setvalores({
      ...valores,
      latitud: lat,
      longitud: lng,
      direccion: formattedAddress ?? valores.direccion,
      codigo_zip: zipCode ?? valores.codigo_zip,
      pais: country ?? valores.pais,
      provincia: state ?? valores.provincia,
      ciudad: city ?? valores.ciudad,
    });
  }

  // Save button
  const handleSave = () => {
    if (oficina.id && specieltiesChanges) setOpenConfirmDialog(true);
    else handleSubmit();
  }

  // Submit data
  const handleSubmit = async () => {
    // return
    firstRender.current = false;
    const res = validacionesOficina(valores);
    if (Object.keys(res).length > 0) {
      seterrors(validacionesOficina(valores));
      return;
    }
    try {
      if (Object.keys(errors).length > 0) {
        seterror({
          title: "error",
          mensaje: "Debe completar los campos correctamente",
        });
        return;
      }

      // Verify update of membership (activate) in form
      if ((!membershipValueInit.current && (membershipValueInit.current !== valores.afiliacion)) ||
        (membershipValueInit.current && (membershipValueInit.current !== valores.afiliacion))
      ) {
        setOpenModalConfirmMembership(true);
        return;
      }

      saveData();
    } catch (err) {
      seterror({
        title: "error",
        mensaje: "Ocurrio un error, intente nuevamente",
      });
    }
  };

  const saveData = async () => {
    try {
      const institucion_id = institucion
        ? institucion.id
        : (valores.institucion ? valores.institucion.id : null);

      await agregarOficina({ ...valores, institucion_id });

      if (props.hasError) {
        setvalores({
          tiempo_por_cliente_minutos: !oficina.tiempo_por_cliente_minutos
            ? ""
            : oficina.tiempo_por_cliente_minutos,
          costo_primera_consulta: !oficina.costo_primera_consulta
            ? ""
            : oficina.costo_primera_consulta,
          costo_consulta: !oficina.costo_consulta ? "" : oficina.costo_consulta,
          nombre: !oficina.nombre ? "" : oficina.nombre,
          direccion: !oficina.direccion ? "" : oficina.direccion,
          ciudad: !oficina.ciudad ? "" : oficina.ciudad,
          provincia: !oficina.provincia ? "" : oficina.provincia,
          pais: !oficina.pais ? "" : oficina.pais,
          codigo_zip: !oficina.codigo_zip ? "" : oficina.codigo_zip,
          referencias_fisicas: !oficina.referencias_fisicas
            ? ""
            : oficina.referencias_fisicas,
          longitud: !oficina.longitud ? 0 : oficina.longitud,
          latitud: !oficina.latitud ? 0 : oficina.latitud,
          secretaria_id: !oficina.secretaria_id ? "" : oficina.secretaria_id,
          doctor_id: doctor_id,
          serviciosAsignados: !!oficina.serviciosAsignados
            ? oficina.serviciosAsignados
            : [],
          especializaciones: !!oficina.especializaciones
            ? oficina.especializaciones
            : [],
          institucion: oficina.institucion ?? '',
        });
        seterror({ title: "success", mensaje: props.hasError });
      } else {
        onCierraForm();
        seterror({ title: "error", mensaje: props.hasError });
      }
    } catch (error) {
      seterror({
        title: "error",
        mensaje: "Ocurrio un error, intente nuevamente",
      });
    }
  }

  const handleChangeAutoServicios = (event, values) => {
    setvalores({
      ...valores,
      //Elimino que no ingrese repetidos
      serviciosAsignados: values
        .map((item) => item)
        .filter((item, pos, self) => self.indexOf(item) === pos),
    });
  };

  const handleChangeAutoEspecializaciones = (event, values) => {
    const newValues = values
      .map((item) => item)
      .filter((item, pos, self) => self.indexOf(item) === pos);

    const specialtiesIds = newValues.map(item => item.especializacion.id);
    // Filtrar data servicios, por las especialidades seleccionadas]
    const serviceDataFiltered = servicios.filter(item => specialtiesIds.includes(item.doctorEspecializacion?.especializacion?.id));
    const servicesSelectedFiltered = valores.serviciosAsignados.filter(item => specialtiesIds.includes(item.doctorEspecializacion?.especializacion?.id));
    setSpecieltiesChanges(true);
    setDataServicios(serviceDataFiltered);
    setvalores({
      ...valores,
      doctorEspecializaciones: newValues,
      serviciosAsignados: servicesSelectedFiltered,
    });

  };

  const handleChangeEspecializacion = (esp) => {
    const serviceDataFiltered = servicios.filter(item => item.doctorEspecializacion?.especializacion?.id === esp?.especializacion?.id);
    const servicesSelectedFiltered = valores.serviciosAsignados.filter(item => esp?.especializacion?.id === item.doctorEspecializacion?.especializacion?.id);

    setSpecieltiesChanges(true);
    setDataServicios(serviceDataFiltered);
    setvalores({
      ...valores,
      doctorEspecializaciones: esp ? [esp] : [],
      serviciosAsignados: servicesSelectedFiltered,
    });
  }

  const startSearchSecretaria = useCallback(
    async (value) => {
      try {
        const { data, transaccion } = await dispatch(getSecretariasPorBusqueda(value));
        console.log(data)
        if (transaccion)
          setSecretariasBusqueda(data);
      } catch (error) {
        console.log({ error })
      }

    },
    [dispatch],
  )

  const startSearchInstituciones = useCallback(
    async (value) => {
      try {
        const { data, transaccion } = await dispatch(getInstitucionPorBusqueda(value));
        if (transaccion)
          setInstitucionesBusqueda(data);
      } catch (error) {
        console.log({ error })
      }

    },
    [dispatch],
  )

  const handleSecretariaSelect = useCallback((___, values, __) => {
    const lastElement = values[values.length - 1];
    //Verificar ( Si el último elemento seleccionado es un objeto y no se encuentra en el arreglo anterior ) || (si el usuario elimino un valor del input)
    if ((values && typeof lastElement === "object" && !_.some(valores.secretarias, lastElement)) || values.length < valores.secretarias.length) {
      setvalores({
        ...valores,
        secretarias: values
      });
    }
  }, [valores]);

  const handleInstitucionSelect = useCallback((___, value, __) => {
    const institucion = (typeof value === "object") ? value : null;
    setvalores({
      ...valores,
      institucion
    });

  }, [valores]);

  const onClickInfoAfiliado = () => setOpenModalMembership(true);

  const onClickinfoPagocita = () => setOpenModalReservation(true);

  useEffect(() => {
    if (oficina.id && oficina.doctorEspecializaciones) {
      const specialtiesIds = oficina.doctorEspecializaciones.map(item => item.especializacion?.id);
      const serviceDataFiltered = servicios.filter(item => specialtiesIds.includes(item.doctorEspecializacion?.especializacion?.id));
      setDataServicios(serviceDataFiltered);
    } else {
      setDataServicios(servicios);
    }

  }, [servicios, oficina])

  useEffect(() => {
    // Cargar opciones de especialidad solo para instituciones
    console.log({ oficina })
    if (institucion)
      dispatch(getOficinasEspecialidadesOpciones(oficina?.id ? oficina.id : 0)).then(
        (data) => {
          console.log(data);
          setEspecialidades(data);
        }
      )
  }, [oficina, institucion, dispatch]);


  return (
    <Paper>
      <form
        style={{ margin: "15px" }}
        className={classes.root}
        noValidate="noValidate"
        autoComplete="off"
      >
        {/* Confirmacion de guardado */}
        <ConfirmDialog
          title={"Confirmación de guardado"}
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onConfirm={handleSubmit}
        >
          <div>Ha realizado cambios en la lista de especialidades de la oficina actual. Los servicios que esten relacionados con las especialidades, que hayan sido eliminadas de la lista, serán eliminados de la oficina actual, ¿desea confirmar los cambios?</div>
        </ConfirmDialog>

        {/* Modal Afiliado */}
        <ModalInfo
          open={openModalMembership}
          onCloseModal={() => setOpenModalMembership(false)}
          img={imgMembership}
          title={infoAfiliacion.title}
        // confirmDialog={{
        //   cancelText: 'Cancelar',
        //   confirmText: 'Acept'

        // }}
        >
          {/* Contenido */}
          {infoAfiliacion.textBody}
        </ModalInfo>
        {/* Fin Modal Afiliado */}

        {/* Modal Reserva */}
        <ModalInfo
          open={openModalReservation}
          onCloseModal={() => setOpenModalReservation(false)}
          img={imgPayReservation}
          title={infoPagocita.title}
        // confirmDialog={{
        //   cancelText: 'Cancelar',
        //   confirmText: 'Acept'

        // }}
        >
          {/* Contenido */}
          {infoPagocita.textBody}
        </ModalInfo>
        {/* Fin Modal Reserva */}

        {/* Modal Confirmar y cancelar Afiliación */}
        <ModalInfo
          open={openModalConfirmMembership}
          onCloseModal={() => setOpenModalConfirmMembership(false)}
          img={imgMembership}
          title={membershipValueInit.current ? infoDesafiliacion.title : infoAfiliacion.title}
          confirmDialog={{
            cancelText: 'Cancelar',
            confirmText: membershipValueInit.current ? 'Confirmar desafiliación' : 'Confirmar Afiliación',
            onConfirm: () => saveData()
          }}
        >
          {/* Contenido */}
          {
            membershipValueInit.current ? infoDesafiliacion.textBody : infoAfiliacion.textBody
          }

        </ModalInfo>
        {/* Fin Modal Confirmar Afiliación */}
        <Loading title="Cargando..." loading={props.isLoading}>
          <>
            {/* Warning on exit page */}
            {/* Form state: {formDirty ? 'form dirty' : 'form no dirty'} */}
            <WarningLeaveFormPage when={formDirty} />
            <GridContainer>
              {
                !institucion &&
                <GridItem xs={12} md={4}>
                  <AutocompleteComponent
                    label="Institución"
                    freeSolo={true}
                    poblarData={[]}
                    onBuscadoChange={(event, values) => handleInstitucionSelect(event, values, true)}
                    optionLabel={option => option.id ? `${option.nombre}` : ''}
                    dispatchFunction={startSearchInstituciones}
                    dataSearch={institucionesBusqueda}
                    value={valores.institucion}
                    reduxFunction={false}
                    getOptionSelected={(option, value) => option.id === value.id}
                  />
                </GridItem>
              }

              <GridItem xs={12} md={4}>
                <TextField
                  error={errors.nombre}
                  label="* Nombre"
                  id="nombre"
                  name="nombre"
                  type="text"
                  fullWidth
                  onChange={handleChange}
                  value={valores.nombre}
                />
                {errors.nombre && (
                  <FormErrorLabel>{errors.nombre}</FormErrorLabel>
                )}
              </GridItem>

              <GridItem xs={12} md={4}>
                <TextField
                  label="* Tiempo por paciente (en minutos)"
                  id="tiempo_por_cliente_minutos"
                  name="tiempo_por_cliente_minutos"
                  type="number"
                  inputProps={{ min: 0, max: 60 }}
                  fullWidth
                  onChange={handleChange}
                  value={valores.tiempo_por_cliente_minutos}
                  error={errors.tiempo_por_cliente_minutos}
                />
                {errors.tiempo_por_cliente_minutos && (
                  <FormErrorLabel>{errors.tiempo_por_cliente_minutos}</FormErrorLabel>
                )}
              </GridItem>
              <GridItem xs={12} md={4}>
                <TextField
                  label="* Costo consulta"
                  id="costo_consulta"
                  name="costo_consulta"
                  type="text"
                  fullWidth
                  onChange={handleChange}
                  value={valores.costo_consulta}
                  error={errors.costo_consulta}
                />
                {errors.costo_consulta && (
                  <FormErrorLabel>{errors.costo_consulta}</FormErrorLabel>
                )}
              </GridItem>
              <GridItem xs={12} md={4}>
                <TextField
                  error={errors.pais}
                  label="* Pais"
                  id="pais"
                  name="pais"
                  type="text"
                  fullWidth
                  onChange={handleChange}
                  value={valores.pais}
                />
                {errors.pais && (
                  <FormErrorLabel>{errors.pais}</FormErrorLabel>
                )}
              </GridItem>
              <GridItem xs={12} md={4}>
                <TextField
                  error={errors.provincia}
                  label="* Provincia"
                  id="provincia"
                  name="provincia"
                  type="text"
                  fullWidth
                  onChange={handleChange}
                  value={valores.provincia}
                />
                {errors.provincia && (
                  <FormErrorLabel>{errors.provincia}</FormErrorLabel>
                )}
              </GridItem>
              <GridItem xs={12} md={4}>
                <TextField
                  error={errors.ciudad}
                  label="* Ciudad"
                  id="ciudad"
                  name="ciudad"
                  type="text"
                  fullWidth
                  onChange={handleChange}
                  value={valores.ciudad}
                />
                {errors.ciudad && (
                  <FormErrorLabel>{errors.ciudad}</FormErrorLabel>
                )}
              </GridItem>
              <GridItem xs={12} md={institucion ? 12 : 8}>
                <TextField
                  error={errors.referencias_fisicas}
                  label="* Referencias"
                  id="referencias_fisicas"
                  name="referencias_fisicas"
                  type="text"
                  fullWidth
                  onChange={handleChange}
                  value={valores.referencias_fisicas}
                />
                {errors.referencias_fisicas && (
                  <FormErrorLabel>{errors.referencias_fisicas}</FormErrorLabel>
                )}
              </GridItem>
              <GridItem xs={12} md={6}>
                <AutocompleteComponent
                  id='sec'
                  label="Secretarias"
                  multiple
                  freeSolo={true}
                  poblarData={[]}
                  onBuscadoChange={(event, values) => handleSecretariaSelect(event, values, true)}
                  optionLabel={option => option.id ? `${option?.user?.first_name} ${option?.user?.last_name}` : ''}
                  dispatchFunction={startSearchSecretaria}
                  dataSearch={secretariasBusqueda}
                  filterOptions={(options) => options}
                  value={valores.secretarias}
                  reduxFunction={false}
                  getOptionSelected={(option, value) => option.id === value.id}
                />

              </GridItem>

              <GridItem xs={12} md={6}>
                <TextField
                  label="Codigo ZIP"
                  id="codigo_zip"
                  name="codigo_zip"
                  type="text"
                  fullWidth
                  onChange={handleChange}
                  value={valores.codigo_zip}
                  error={errors.codigo_zip}

                />
                {errors.codigo_zip && (
                  <FormErrorLabel>{errors.codigo_zip}</FormErrorLabel>
                )}
              </GridItem>

              {
                !institucion ?
                  <GridItem xs={12} md={6}>
                    <Autocomplete
                      multiple={institucion ? true : true}
                      id="especializaciones"
                      options={doctorEspecialidades}
                      onChange={handleChangeAutoEspecializaciones}
                      name="especializaciones"
                      value={valores.doctorEspecializaciones}
                      getOptionLabel={(option) => option.especializacion.nombre}
                      getOptionSelected={(option, value) => option.id === value.id}
                      limitTags={1}
                      // getOptionDisabled={(options) => ((valores.doctorEspecializaciones.length >= 1 && institucion) ? true : false)}
                      disabled={oficina.id && oficina.afiliacion ? true : false}
                      noOptionsText='Sin especialidades'
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="especializaciones"
                          label="Especialidades"
                        />
                      )}
                    />
                    {errors.especializaciones && (
                      <FormErrorLabel>{errors.especializaciones}</FormErrorLabel>
                    )}
                  </GridItem>

                  :
                  <GridItem xs={12} md={6}>
                    <Autocomplete
                      value={valores.doctorEspecializaciones[0] ?? null}
                      onChange={(event, newValue) => {
                        handleChangeEspecializacion(newValue);
                      }}
                      getOptionSelected={(option, value) => option.id === value.id}
                      options={especialidades}
                      getOptionLabel={(option) => option.especializacion.nombre}
                      renderInput={(params) => <TextField {...params} label="Especialidad" name="especializaciones" />}
                    />
                  </GridItem>
              }

              <GridItem xs={12} md={6}>
                <Autocomplete
                  multiple
                  // value={serviciosSelected}
                  id="serviciosAsignados"
                  options={dataServicios}
                  onChange={handleChangeAutoServicios}
                  name="especializaciones_categoria"
                  value={valores.serviciosAsignados}
                  getOptionLabel={(option) => option.nombre}
                  getOptionSelected={(option, value) => option.id === value.id}
                  limitTags={3}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="serviciosAsignados"
                      label="Servicios asignados"
                    />
                  )}
                />
                {errors.serviciosAsignados && (
                  <FormErrorLabel>{errors.serviciosAsignados}</FormErrorLabel>
                )}
              </GridItem>

              <GridItem xs={12} md={oficina.id ? 6 : 12}>
                <TextField
                  error={errors.direccion}
                  label="* Dirección"
                  id="direccion"
                  name="direccion"
                  type="text"
                  fullWidth
                  onChange={handleChange}
                  value={valores.direccion}
                />
                {errors.direccion && (
                  <FormErrorLabel>{errors.direccion}</FormErrorLabel>
                )}
              </GridItem>

              <GridItem xs={12} md={12}
                style={{
                  margin: "25px 0px 0px 0px",
                }}>
                <GoogleMaps
                  values={{ lat: valores.latitud, lng: valores.longitud }}
                  onMapClickUpdate={handleChangeDireccionLatLng}
                />
              </GridItem>

              {
                oficina.id &&
                <>
                  <GridItem xs={12} md={6}
                    style={{
                      margin: "25px auto 0px auto",
                    }}>
                    <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={valores.afiliacion}
                            onChange={(_, value) => handleChangeSwitch('afiliacion', value)}
                            name="activado"
                            color="primary"
                          // disabled={!usuario.cuenta_activa}
                          />
                        }
                        label="Afiliación MedPal"
                      />
                      <div style={{ display: 'inline-flex', verticalAlign: 'middle' }}>
                        <InfoIcon color="primary" style={{ cursor: 'pointer' }} onClick={onClickInfoAfiliado} />
                      </div>
                    </div>

                  </GridItem>

                  <GridItem xs={12} md={6}
                    style={{
                      margin: "25px auto 0px auto",
                    }}>
                    <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={valores.pago_reserva_habilitacion}
                            onChange={(_, value) => handleChangeSwitch('pago_reserva_habilitacion', value)}
                            name="pago_reserva_habilitacion"
                            color="primary"
                          // disabled={!usuario.cuenta_activa}
                          />
                        }
                        label="Pago por reserva"
                      />
                      <div style={{ display: 'inline-flex', verticalAlign: 'middle' }}>
                        <InfoIcon color="primary" style={{ cursor: 'pointer' }} onClick={onClickinfoPagocita} />
                      </div>
                    </div>

                  </GridItem>
                </>
              }

            </GridContainer>

            <div
              style={{
                margin: "20px 8px",
                flex: "1",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="outlined"
                color="warning"
                onClick={() => handleSave()}
              >
                Guardar
              </Button>
            </div>
          </>
        </Loading>
        {error.mensaje && (
          <Alert severity={`${[error.title]}`}>
            <AlertTitle>
              {error.title === "error" ? "Error" : "Success"}
            </AlertTitle>
            {error.mensaje}
          </Alert>
        )}
      </form>
    </Paper>
  );
};

export default FormOficina;
