import React, { useState } from 'react';
// import { TextField, IconButton, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

//Icons
// import SearchIcon from '@material-ui/icons/Search';
// import AddCircleIcon from '@material-ui/icons/AddCircle';
// import Close from '@material-ui/icons/Close';
//Custom components

//REDUX
import { useSelector, useDispatch } from 'react-redux';
import { Alert, AlertTitle } from '@material-ui/lab';
import TableCliente from 'components/reservaciones/medico/cliente-table.component';
import { AutocompleteComponent } from 'components/ui/AutocompleteComponent';
import { setCliente } from 'redux/actions/aClientes';
import { getClientesPorBusqueda } from 'redux/actions/aClientes';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
}));

const Cliente = ({ setIdCliente, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [showForm, setShowForm] = useState(false);
  console.log(showForm);
  const { data: clientes = '' } = useSelector(state => state.data_clientes);
  const { clients_search } = useSelector(state => state.data_clientes);

  const handleBuscado = (event, values, esNuevo) => {
    if (values !== null && values.id) {

      dispatch(setCliente(values));
      setIdCliente(values.id);
      // const newValue = { ...values };
      // setobjetoBuscado(newValue);
      // setnuevo(esNuevo);
    } else {
      // setobjetoBuscado({});
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flex: '1',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div style={{ width: '300px' }}>
        <GridContainer className={classes.root} style={{ flex: 1 }}>
          <GridItem xs={12}>
            <AutocompleteComponent
              freeSolo={true}
              poblarData={[]}
              onBuscadoChange={(event, values) => handleBuscado(event, values, true)}
              optionLabel={option => option.id ? `${option.cedula_identidad} ${option.nombres} ${option.apellidos}` : ''}
              placeholder="CI/Nombres/Apellidos"
              dispatchFunction={getClientesPorBusqueda}
              dataSearch={clients_search}
              onFocus={() => setShowForm(false)}
            />
          </GridItem>
        </GridContainer>
        {/* <GridContainer className={classes.root}>
          <GridItem xs={9}>
            <TextField
              fullWidth
              type="search"
              value={campoCliente}
              onChange={e => setCampoCliente(e.target.value)}
              placeholder="CI/Nombres/Apellidos"
              onKeyPress={ev => {
                if (ev.key === 'Enter') {
                  if (campoCliente.length > 0) {
                    handleSearchCliente();
                  } else {
                    cleanCliente();
                  }
                  ev.preventDefault();
                }
              }}
            />
          </GridItem>
          <GridItem xs={1}>
            <IconButton onClick={handleSearchCliente} fullWidth aria-label="buscar">
              <SearchIcon />
            </IconButton>
          </GridItem>
          <GridItem xs={1}>
            <IconButton fullWidth aria-label="agregar" onClick={() => dispatch(cleanCliente())}>
              <Close />
            </IconButton>
          </GridItem>
        </GridContainer> */}
        {props.hasError && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {props.hasError}
          </Alert>
        )}
      </div>

      {!Array.isArray(clientes) && (
        <TableCliente clientes={clientes} titulos={['Cédula', 'Nombres', 'Apellidos', 'Telefono', 'Sexo']} />
      )}

      {/* <IconButton
        style={{ marginTop: '10px', justifyContent: 'flex-end' }}
        onClick={() => handleShowForm(true)}
      >
        <AddCircleIcon />
        Añadir cliente
      </IconButton> */}
    </div>
  );
};

export default Cliente;
