import React, { useEffect, useRef, useState } from "react";
// @material-ui/core components
import { Input } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// core components
import TableCell from "@material-ui/core/TableCell";
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';

const useStyles = makeStyles(styles);

export const InputTableCell = ({
  row,
  rowItem,
  index,
  valueKey,
  Data,
  setData,
  esHora = false,
  error,
  esCedula = false,
  editable = true,
  disabled = false,
  setOpen = "",
}) => {
  const classes = useStyles();
  const [isEditMode, setIsEditMode] = useState(editable);
  const [value, setValue] = useState(row[valueKey]);

  const refSelf = useRef(null);

  useEffect(() => {
    setValue(rowItem);
  }, [rowItem]);

  useEffect(() => {
    setIsEditMode(editable);
  }, [editable]);

  const handleChange = (e) => {
    setValue(e.target.value);
    let new_value = e.target.value;

    let newArr = [...Data];
    newArr.map((i) => {
      i[valueKey] = i === row ? new_value : i[valueKey];
      return null;
    });
    setData(newArr);
  };


  const handleFocus = () => {
    if (esCedula) {
      setOpen(index);
    }
  };

  return (
    <>
      <TableCell align="center" ref={refSelf} className={classes.tableCell}>
        {isEditMode ? (
          <Input
            value={value ?? ''}
            name={valueKey}
            onClick={handleFocus}
            type={esHora ? "time" : "text"}
            onChange={(e) => handleChange(e, row)}
            disabled={disabled}
            error={!!error}
          />
        ) : (
          // limit(value, 20)
          value
        )}
      </TableCell>
    </>
  );
};