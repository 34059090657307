import React, { useEffect, useMemo } from 'react';
import Alert from '@material-ui/lab/Alert';

//Custom components
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ReservaOficinaCard } from './ReservaOficinaCard';
import { getOficinasDoctor } from 'redux/actions/aOficinas';
import { Box, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";


const OficinaReserva = ({
  handleClickCard,
  selectedHorario,
  error,
  selectedDate,
  setSelectedDate,
  selectedDay,
  setSelectedDay,
  oficinaIdSelected,
  setSetOficinaIdSelected,
}) => {

  const todosHorarios = useSelector(state => state.data_horarios_reservas.data.horarios_oficina);

  const { data: oficinas = [] } = useSelector(state => state.data_oficinas);

  const oficinaSelected = useMemo(() => oficinas.find(item => item.id === oficinaIdSelected), [oficinaIdSelected, oficinas]);

  const horariosOficinaSelected = useMemo(() =>
    todosHorarios.filter(horario_dia =>
      (horario_dia.h_dia_semana.toUpperCase() === selectedDay.toUpperCase()) &&
      horario_dia.o_id === oficinaIdSelected
    ), [todosHorarios, oficinaIdSelected, selectedDay]);


  const dispatch = useDispatch();

  const handleDateChange = date => {
    setSelectedDate(date);
    setSelectedDay(moment(date).format('dddd'));
  };

  useEffect(() => {
    dispatch(getOficinasDoctor());
  }, [dispatch])

  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="YYYY-MM-DD"
            margin="normal"
            id="fecha_reserva"
            label="Elija la fecha"
            minDate={new Date()}
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </div>

      {/* Selector oficinas */}
      <Box mb={2}>
        <FormControl fullWidth>
          <InputLabel id="oficina-label">Selecciona una oficina:</InputLabel>
          <Select
            labelId="oficina-label"
            // name="titulo_honorifico_select"
            // id="titulo_honorifico-select"
            value={oficinaIdSelected}
            onChange={(e) => setSetOficinaIdSelected(e.target.value)}
          // style={{ 'marginTop': '43px', width: '100%' }}
          >
            {
              oficinas.map((oficina) => (
                <MenuItem key={oficina.id} value={oficina.id}>{oficina.nombre}</MenuItem>
              ))
            }
          </Select>
        </FormControl>

      </Box>
      {
        oficinaSelected &&
        <ReservaOficinaCard
          oficina={oficinaSelected}
          horarios={horariosOficinaSelected}
          selectedDate={selectedDate}
          selectedDay={selectedDay}
          selectedHorario={selectedHorario}
          handleClickCard={handleClickCard}
        />
      }


      {/* <OficinaCard
        fecha={selectedDate}
        dia={selectedDay}
        ocupadosHorarios={ocupadosHorarios}
        selectedHorario={selectedHorario}
        onClick={handleClickCard}
        oficinas={todosHorarios.filter(
          horario_dia => horario_dia.h_dia_semana.toUpperCase() === selectedDay.toUpperCase()
        )}
      /> */}
      {
        (error) &&
        <Alert severity="error">Debe seleccionar un horario para continuar</Alert>

      }
    </div>
  );
};

export default OficinaReserva;
