import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//Socket
// import socketIOClient from "socket.io-client";
// import { urlSocket } from "constants/urls";

//Moment
// import moment from "moment";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes/cliente.routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import logo from "assets/images/medpal_lineal_white.svg";
import { cerrarsesion } from "redux/actions/aLogin";
import Topbar from "components/Navbars/Topbar";

let ps;

// const switchRoutes = (
//   <Switch>
//     {routes.map((prop, key) => {
//       if (prop.layout === "/cliente") {
//         return (
//           <Route
//             path={prop.layout + prop.path}
//             component={prop.component}
//             key={key}
//           />
//         );
//       }
//       return null;
//     })}
//     <Redirect from="/cliente" to="/cliente/dashboard" />
//   </Switch>
// );

const useStyles = makeStyles(styles);

function Cliente({ ...rest }) {

  // Top menu
  const [showTopMenu, setShowTopMenu] = useState(false);

  const rolesState = useSelector((state) => state.rol_cli);
  let roles = rolesState ? rolesState.split(",") : [];
  roles = roles.filter(r => r !== "");
  let routesFilter = routes.filter(r => roles.length > 1 || !r.rol)

  const switchRoutes = (
    <Switch>
      {routesFilter.map((prop, key) => {
        // console.log({prop});
        if (prop.layout === "/cliente") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      })}
      <Redirect from="/cliente" to="/cliente/dashboard" />
    </Switch>
  );

  const user = useSelector((state) => state.dataUser.data);

  const { isLogin = false } = user;
  if (!isLogin) {
    rest.history.replace("/");
  }

  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  // const [image, setImage] = React.useState(bgImage);
  // const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);

  // const handleImageClick = (image) => {
  //   setImage(image);
  // };
  // const handleColorClick = (color) => {
  //   setColor(color);
  // };
  // const handleFixedClick = () => {
  //   if (fixedClasses === "dropdown") {
  //     setFixedClasses("dropdown show");
  //   } else {
  //     setFixedClasses("dropdown");
  //   }
  // };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/cliente/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      // document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  //Conectar socket client
  const { token = "" } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    const initToken = () => {
      if (!token) {
        dispatch(cerrarsesion());
        rest.history.replace({ pathname: `/` });
      }
    };
    initToken();
  }, [token, dispatch, rest.history]);

  return (
    <div className={classes.wrapper}>
      {
        !showTopMenu &&
        <Sidebar
          routes={routesFilter}
          logo={logo}
          image={null}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          setShowTopMenu={setShowTopMenu}
          // color={color}
          {...rest}
        />
      }

      <div className={showTopMenu ? classes.mainPanel2 : classes.mainPanel} ref={mainPanel} id='doctorMainPanel' style={{
        width: '100% !important'
      }}>
        <Topbar
          routes={routesFilter}
          logo={logo}
          image={null}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          showTopMenu={showTopMenu}
          setShowTopMenu={setShowTopMenu}
          // color={'red'}
          {...rest} />

        <div style={{
          marginTop: '120px !important'
        }}>
          <Navbar
            customClasses={showTopMenu ? classes.marginBarTop : ''}
            routes={routesFilter}
            handleDrawerToggle={handleDrawerToggle}
            {...rest}
          />
        </div>

        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
        {getRoute() ? <Footer /> : null}

        {/* <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          bgColor={color}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
        /> */}
      </div>
    </div>
  );
}

// const mapDispatchToProps = dispatch => {
//   return {

//   };
// };

export default Cliente;
