import moment from "moment";

// Ordenar horarios y poner cambiar estado de disponibilidad
export const formatHorarios = (data) => {

  const { horarios = [], reservas = [], selectedDate, selectedDay, cliente } = data;

  const fechaSeleccionadaCalendario = moment(selectedDate).format('YYYY-MM-DD');

  // Quitar referencia de memoria de objetos del arreglo
  const horariosAux = horarios.map(object => ({ ...object }));

  // Filtrar horarios en el mismos día seleccionado
  let horariosUpdate = horariosAux.filter(horario => horario.dia_semana.toUpperCase() === selectedDay.toUpperCase());

  // Orderar horarios por hora de inicio
  horariosUpdate = horariosUpdate.sort((a, b) => (a.hora_inicio > b.hora_inicio)
    ? 1
    : ((b.hora_inicio > a.hora_inicio) ? -1 : 0)
  );

  //Pone en ocupado los horarios que han sido reservados
  for (let i = 0; i < horariosUpdate.length; i++) {
    let j = 0;

    const limiteEspaciosDisponibles = (horariosUpdate[i].espacios_disponibles) ?? 1;
    let espaciosDisponibles = limiteEspaciosDisponibles;

    // console.log('=======', horariosUpdate[i])
    while (j < reservas.length && espaciosDisponibles > 0) {

      const fechaReserva = moment(reservas[j].inicio).format('YYYY-MM-DD');
      if (moment(fechaSeleccionadaCalendario).isSame(fechaReserva)) {
        const horaReserva = `${moment(reservas[j].inicio).format('HH:mm')}-${moment(reservas[j].fin).format('HH:mm')}`;
        const horaOficina = `${horariosUpdate[i].hora_inicio}-${horariosUpdate[i].hora_fin}`;

        if (horaOficina === horaReserva) {
          // Si el mismo cliente ya tiene una reserva la misma hora, no permitir crear un nuevo horario
          if (reservas[j].cliente_id === cliente.id)
            espaciosDisponibles = 0;
          else
            espaciosDisponibles--;
        }
      }
      j = j + 1;
    }

    if (espaciosDisponibles === 0) {
      horariosUpdate[i].estado = true;
      horariosUpdate[i].esReserva = true;
    }
  }

  //Pone en ocupado los horarios que ya son anteriores
  for (let i = 0; i < horariosUpdate.length; i++) {
    const nowDiaHora = moment(new Date()).format('YYYY-MM-DD HH:mm')
    const auxConcat = moment(fechaSeleccionadaCalendario + ' ' + horariosUpdate[i].hora_inicio)
    const fechaSeleccionadaCalendarioHoras = moment(auxConcat).format('YYYY-MM-DD HH:mm')
    if (moment(fechaSeleccionadaCalendarioHoras).isBefore(nowDiaHora)) {
      horariosUpdate[i].estado = true;
    } else {
      if (horariosUpdate[i].estado === true && !horariosUpdate[i].esReserva) {
        horariosUpdate[i].estado = false;
      }
    }
  }
  return horariosUpdate;

}