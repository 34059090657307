import React, { useRef } from 'react'
import { useEffect } from 'react';

export const AdminLayout = ({ children, viewState }) => {
  
  //Scroll top
  const firstRender = useRef(false);
  const pageRef = useRef(null);
  useEffect(() => {
    if (firstRender.current) {
      pageRef.current.scrollIntoView();
    } else {
      firstRender.current = true;
    }

  }, [viewState]);

  return (
    <div ref={pageRef}>{children}</div>
  )
}
