export function createDataCajas(arr_data) {
  let professional = `${arr_data[0].d_nombres} ${arr_data[0].d_apellidos}`;
  let total_cost = 0;
  let checked_cost = 0;
  arr_data.forEach((data) => {
    total_cost += parseFloat(removeMoneyType(data.r_valor_cobro));
    data.r_entregado
      ? (checked_cost += parseFloat(removeMoneyType(data.r_valor_cobro)))
      : (checked_cost += 0);
  });
  let d_id = arr_data[0].d_id;
  return {
    professional,
    total_cost,
    d_id,
    checked_cost,
    row_data: arr_data,
  };
}

export function removeMoneyType(str) {
  return str.replace("$", "");
}

export function limitString(content, limit) {
  if (content !== undefined) {
    if (content.length <= limit) return content;
    return content.substring(0, limit) + "...";
  }
}