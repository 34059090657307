import React, { useEffect, useState } from 'react'
import { InputLabel, MenuItem, Select, Typography, makeStyles } from '@material-ui/core'
import Card from 'components/Card/Card'
import CardBody from "components/Card/CardBody.js"
import CardHeader from "components/Card/CardHeader.js"
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomInput from 'components/ReactHookForm/CustomInput'
import { Controller, useForm } from 'react-hook-form'
import TextField from "@material-ui/core/TextField"
import FormControl from "@material-ui/core/FormControl"
import Button from "components/CustomButtons/Button.js";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import CardFooter from 'components/Card/CardFooter'
import { validarRUC } from 'utils/validaciones'
import { savePayphoneConfig } from 'redux/actions/aMedico'
import { useDispatch, useSelector } from 'react-redux'
import Notificacion from 'components/ui/Notificacion'
import Loading from 'components/Loading'
import WarningLeaveFormPage from 'components/ui/WarningLeaveFormPage'
import dashboardStyles from "assets/jss/material-dashboard-react/views/dashboardStyle"

const useStyles = makeStyles({
  ...dashboardStyles,
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 15,
    fontWeight: 'bold'
  },
  pos: {
    marginBottom: 12,
  },
  formControl: {
    width: "100%",
    marginBottom: "25px",
  },
  formControlSelect: {
    width: "100%",
    marginLeft: "10px",
  },
  formErrorCellphoneText: {
    color: '#f44336',
    margin: 0,
    fontSize: '0.75rem',
    marginTop: '3px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
  }
});


export const DoctorConfig = () => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const usuario = useSelector((state) => state.dataUser.data);
  const loadingUser = useSelector((state) => state.dataUser.isLoading);

  const { control, handleSubmit, errors, formState: { isDirty }, watch } = useForm();

  const payphone_account_type_value = watch('payphone_account_type');

  const [usuarioPayphoneAccountType, setUsuarioPayphoneAccountType] = useState(usuario.payphone_account_type)

  useEffect(() => {
    setUsuarioPayphoneAccountType(usuario.payphone_account_type ?? 'business')
  }, [usuario])

  useEffect(() => {
    setUsuarioPayphoneAccountType(payphone_account_type_value)
  }, [payphone_account_type_value])

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await dispatch(savePayphoneConfig(data));
      setLoading(false);

    } catch (error) {
      console.log({ error })
      Notificacion({
        type: "error",
        text: 'Ha ocurrido un error al guardar',
      });
    }
  };

  return (
    <Loading title="Cargando..." loading={loadingUser || loading}>
      <>
        <form
          onSubmit={handleSubmit((data) =>
            onSubmit(data)
          )}
        >
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitle}>Configuración Pagos Online</h4>
            </CardHeader>
            <CardBody>



              <Typography className={classes.title} gutterBottom>
                PayPhone
              </Typography>

              {/* Form payphone config */}
              <div style={{ marginTop: '15px' }}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl className={classes.formControlSelect} >
                      <InputLabel shrink htmlFor={'payphone_account_type'}>
                        Tipo de cuenta (Para el uso de esta opción es necesario contar con una cuenta de PayPhone activa)
                      </InputLabel>
                      <Controller
                        defaultValue={usuario.payphone_account_type ?? 'business'}
                        name={'payphone_account_type'}
                        control={control}
                        className={classes.formControl}
                        // defaultValue={formDataComponent.value ?? null}
                        as={
                          <Select>
                            <MenuItem value={'business'}>
                              Business
                            </MenuItem>
                            <MenuItem value={'personal'}>
                              Personal
                            </MenuItem>
                          </Select>
                        }
                      />
                    </FormControl>
                  </GridItem>

                  {
                    usuarioPayphoneAccountType === 'business' ?
                      <>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            formControlProps={{
                              error: true,
                              className: classes.formControl
                            }}
                            defaultValue={
                              usuario.payphone_account_type === 'business' ? (usuario.payphone_identifier ?? '') : ''
                            }
                            name={`payphone_identifier_ruc`}
                            control={control}
                            as={<TextField
                              InputLabelProps={{
                                className: classes.inputTextfield
                              }}
                            />}
                            error={errors[`payphone_identifier_ruc`] ? true : false}
                            helperText={""}
                            label="RUC"
                            style={{ margin: 8 }}
                            fullWidth
                            margin="normal"
                            errors={errors}
                            rules={{
                              // validate: (value) => isValidPhoneNumber(value),
                              validate: (value) => {
                                try {
                                  if (!validarRUC(value)) {
                                    return "El ruc debe tener 13 dígitos"
                                  }
                                  return true;

                                } catch (error) {
                                  return "El ruc debe tener 13 dígitos"
                                }

                              }
                            }}
                          />
                        </GridItem>
                      </>
                      : usuarioPayphoneAccountType === 'personal' &&
                      <>
                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl className={classes.formControlSelect} >
                            <InputLabel shrink htmlFor={'payphone_identifier_cellphone'}>
                              Número de celular
                            </InputLabel>
                            <div style={{ marginTop: '15px' }}>
                              {watch('payphone_identifier_cellphone')}
                            </div>
                            {/* <label htmlFor="phone-input"></label> */}


                            <Controller
                              defaultValue={usuario.payphone_account_type === 'personal' ? (usuario.payphone_identifier ?? '') : ''}
                              name={'payphone_identifier_cellphone'}
                              control={control}
                              className={classes.formControl}
                              rules={{
                                validate: (value) => {
                                  try {
                                    if (!isValidPhoneNumber(value)) {
                                      return "El ńumero de celular ingresado no es válido"
                                    }
                                    return true;

                                  } catch (error) {
                                    return "El ńumero de celular ingresado no es válido"
                                  }

                                }
                              }}
                              render={({ onChange, value }) => (
                                <PhoneInput
                                  style={{ marginTop: '5px', }}
                                  value={value}
                                  onChange={onChange}
                                  defaultCountry={'EC'}
                                  id="phone-input"
                                />
                              )}
                            />

                            {
                              errors?.payphone_identifier_cellphone &&
                              <div className={classes.formErrorCellphoneText}>
                                {errors?.payphone_identifier_cellphone?.message ?? ''}
                              </div>
                            }

                          </FormControl>
                        </GridItem>
                      </>
                  }

                </GridContainer>
              </div>
            </CardBody>
            <CardFooter>
              <Button
                color="warning" type="submit"
              >
                Guardar
              </Button>
            </CardFooter>
          </Card>
        </form>

        {/* Warning on exit page */}
        <WarningLeaveFormPage when={isDirty} />

      </>
    </Loading>
  )
}


