import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';

export const useFormServicios = ({initStateForm, addService, hasError, validaciones}) => {

  const initState = useRef(initStateForm);

  const [valores, setvalores] =useState(initState.current);
  const [formDirty, setFormDirty] = useState(false);

  //estados para el formulario
  const [error, seterror] = useState({ title: "", mensaje: "" });
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      return;
    }
    seterrors(validaciones(valores));
  }, [valores, validaciones]);

  
  useEffect(() => {
    //Verificar si se ha modificado el formulario
    if(_.isEqual(valores, initState.current)) setFormDirty(false);
    else setFormDirty(true);
  }, [valores]);

  const [errors, seterrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setvalores({ ...valores, [name]: value });
  };

  const handleChangeKeyValue = (key, value) => {
    // console.log({value})
    setvalores({ ...valores, [key]: value });
  };


  const handleChangeKeyboardDatePicker = (value, name) => {
    setvalores({ ...valores, [name]: moment(value).format("YYYY-MM-DD") });
  };

  const handleClick = async (oficinasAsignadas = []) => {
    firstRender.current = false;
    const res = validaciones(valores);
    if (Object.keys(res).length > 0) {
      seterrors(validaciones(valores));
      return;
    }
    try {
      if (Object.keys(errors).length > 0) {
        seterror({
          title: "error",
          mensaje: "Debe completar los campos correctamente",
        });
        return;
      }
      await addService(valores, oficinasAsignadas);
      setFormDirty(false);

      if (hasError) {
        seterror({ title: "error", mensaje: hasError });
      } 
      
    } catch (err) {
      seterror({
        title: "error",
        mensaje: "Ocurrio un error, intente nuevamente",
      });
    }
  };

  return {
    valores,
    formDirty,
    handleChange,
    handleChangeKeyValue,
    handleChangeKeyboardDatePicker,
    handleClick,
    errors,
    error
  }
}