import React, { useRef, useState, useMemo } from 'react'
import { Collapse, IconButton, makeStyles, Paper, Tooltip, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { calculateAgeString } from "utils/paciente/paciente";
import { ConsultaDatePicker } from '../date/ConsultaDatePicker';

const useStyles = makeStyles((theme) => ({
  sticky: {
    position: 'fixed',
    zIndex: 1000,
    bottom: 70,
    right: 5
  },
  floatMenuContainer: { padding: '5px', maxWidth: '360px' }
}));

export const ConsultaFloatMenu = ({
  clienteData,
  collapseDateOptions,
  data,
  disabledArrowBackButton,
  disabledArrowForwardButton,
  formConsultaDirty,
  handleClickArrowButton,
  handleDateChange,
  handleDeleteForm,
  loadingPagination,
  selectedDate,
  setCollapseDateOptions,
  setOpenModalWarning,
}) => {

  const [dataArrayNavIndex, setDataArrayNavIndex] = useState(0);

  const dataArrayNav = useMemo(() => [
    { title: 'Edad', value: calculateAgeString(clienteData?.fecha_nacimiento) },
    { title: 'Tipo de sangre', value: clienteData?.tipo_sangre },
    { title: 'Peso (kg)', value: data?.peso },
    { title: 'Talla (cm)', value: data?.talla },
    { title: 'IMC', value: data?.IMC },
    { title: 'Pulso(X\')', value: data?.pulso },
    { title: 'Pres Sist (mmHg)', value: data?.presSist },
    { title: 'Pres Dias (mmHg)', value: data?.presDias },
    { title: 'Temp (°C)', value: data?.temp },
    { title: 'Frec Resp (x\')', value: data?.frecResp },
    { title: 'Oximetría (%)', value: data?.oximetria },

  ], [clienteData, data]);

  const classes = useStyles();

  const headerDateRef = useRef();

  const handleClickDataNavArrow = (arrow = 'forward') => {
    if (arrow === 'forward') {
      if ((dataArrayNav.length - 1) >= dataArrayNavIndex + 1)
        setDataArrayNavIndex(dataArrayNavIndex + 1)
      else
        setDataArrayNavIndex(0)
    } else {
      if ((dataArrayNavIndex - 1) >= 0)
        setDataArrayNavIndex(dataArrayNavIndex - 1)
      else
        setDataArrayNavIndex(dataArrayNav.length - 1)
    }
  }

  return (
    <Paper className={classes.sticky} ref={headerDateRef} >
      <Collapse in={collapseDateOptions}>
        <div className={classes.floatMenuContainer}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ paddingTop: '12px', paddingLeft: '12px' }}>
              <Typography display="inline" variant="subtitle1" style={{ alignContent: 'center' }}>
                <span style={{ fontWeight: "bold" }}>Paciente: </span>{" "}
                {` ${clienteData?.nombres} ${clienteData?.apellidos}`}
              </Typography>
            </div>
            <Tooltip
              // style={{ marginTop: '4px', marginLeft: '0' }}
              title="Ocultar menú"
              placement="top"
            >
              <IconButton onClick={() => setCollapseDateOptions(!collapseDateOptions)} color="primary" aria-label="Ocultar menú" component="span">
                <VisibilityOffIcon />
              </IconButton>

            </Tooltip>
          </div>

          <div>
            <div style={{
              width: '100%',
              minWidth: '350px',
              display: 'flex',
              justifyContent: 'space-between',
              paddingTop: '5px',
              alignItems: 'center'
            }}>
              <div>
                <IconButton onClick={() => handleClickDataNavArrow('back')} color='primary'>
                  <ArrowBackIosIcon />
                </IconButton>
              </div>
              <div
                style={{
                  width: '200px',
                  display: 'flex',
                  justifyContent: 'space-around',
                }}>
                <div>
                  <strong>
                    {dataArrayNav[dataArrayNavIndex]?.title}
                  </strong>

                </div>
                <div>
                  {dataArrayNav[dataArrayNavIndex]?.value}
                </div>
              </div>

              <div>
                <IconButton onClick={() => handleClickDataNavArrow()} color='primary'>
                  <ArrowForwardIosIcon />
                </IconButton>
              </div>

            </div>

          </div>

          {/* Consulta, selector de fecha */}
          <ConsultaDatePicker
            disabledArrowBackButton={disabledArrowBackButton}
            disabledArrowForwardButton={disabledArrowForwardButton}
            formConsultaDirty={formConsultaDirty}
            handleClickArrowButton={handleClickArrowButton}
            handleDateChange={handleDateChange}
            handleDeleteForm={handleDeleteForm}
            loadingPagination={loadingPagination}
            selectedDate={selectedDate}
            setOpenModalWarning={setOpenModalWarning}
          />
        </div>

      </Collapse>

    </Paper>
  )
}
