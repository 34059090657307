import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Checkbox, CircularProgress, Fade, Modal, TableHead, TableSortLabel } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import moment from "moment";
import _ from 'lodash';

import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CustomTableCellCrud from "components/Admin/CRUD/CustomTableCellCrud";

import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';

import Loading from 'components/Loading';
import { TableRowCrud } from 'components/Admin/CRUD/TableRowCrud';
import { FormCrud } from 'components/Admin/CRUD/FormCrud';
import ConfirmDialog from 'components/Admin/CRUD/ConfirmDialog';
import { startDeleteRoleUser } from 'redux/actions/aAdmin';
import { startAddRoleUser } from 'redux/actions/aAdmin';
import iconDefaultSVG from "../../../src/assets/images/icons/medico.svg";


import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { AdminLayout } from './AdminLayout';
import { urlListarUsuarios, urlEditarUsuario, urlEliminarUsuario } from 'constants/urls';
import { loadData, editRecord, deleteRecord, adminRestartState, loadUsuarioById } from 'redux/actions/aAdmin';
import { TableContainer } from 'components/Table/TableContainer';
import { SearchTextField } from 'components/forms/input/SearchTextField';

let useStyles = makeStyles(styles);

const TableCellHeader = withStyles((theme) => {
  return {
    head: {
      color: theme.palette.common.white,
      fontWeight: "bolder",
    },
  };
})(TableCell);

const rowsPerPageDefault = 20;

const UsuarioCrud = () => {
  const classes = useStyles();
  const fileField = React.useRef(null);

  // 0: tabla, 1:crear, 2: editar
  const [view, setView] = useState(0);
  const [itemSelected, setItemSelected] = useState({});

  const [loading, setLoading] = useState(true);

  const [loadingUserRoles, setLoadingUserRoles] = useState(false);
  const [loadingRol, setloadingRol] = useState(null);


  const [error, setError] = useState(false);

  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  //Ordenamiento
  const [orderData, setOrderData] = useState({
    sortBy: 'created_date', // default sort column
    sortOrder: "asc" // default sort oder
  })

  const { sortBy, sortOrder } = orderData;

  const dispatch = useDispatch();
  let { dataCRUD, dataForeign, dataPagination } = useSelector(state => state.data_admin);

  const { usrRoles } = dataForeign;

  const { total } = dataPagination;

  //Formulario
  const initialState = {
    fotoNombre: null
  };

  const [formValues, setFormValues] = useState(initialState);
  const { fotoNombre } = formValues;

  //Modal Roles
  const [openModalRol, setOpenModalRol] = useState(false);

  const [formError, setFormError] = useState(null);

  //Paginacion

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [skipLocal, setSkipLocal] = React.useState(0);

  const handleInputSearchChange = ({ target }) => {
    setSearch(target.value);
    debouncedSearch(target.value);
  }

  const debouncedSearch = React.useRef(
    _.debounce(async (searchValue) => {
      setSearchValue(searchValue);
    }, 350)
  ).current;
  const initialStateFormComponents = useMemo(() => (
    [
      {
        type: "imageInput",
        name: "fotoNombre",
        value: !!itemSelected.image_url ? itemSelected.image_url : null,
        valueTemp: fotoNombre,
        label: "Imagen",
        imgDefault: iconDefaultSVG,
        formValues,
        setFormValues,
        ref: fileField
      },
      {
        type: "inputDisabled",
        label: "Id",
        value: itemSelected.id,
        showOnCreate: false
      },
      {
        type: "input",
        name: "login",
        label: "Login",
        value: itemSelected.login ? itemSelected.login : "",
        validationOptions: {
          required: {
            value: true,
            message: 'El campo login es requerido',
          },

        },
      },
      {
        type: "input",
        name: "first_name",
        label: "Nombres",
        value: itemSelected.first_name ? itemSelected.first_name : "",
      },
      {
        type: "input",
        name: "last_name",
        label: "Apellidos",
        value: itemSelected.last_name ? itemSelected.last_name : "",

      },
      {
        type: "input",
        name: "email",
        label: "Email",
        value: itemSelected.email ? itemSelected.email : "",

      },
      {
        type: "selectCombo",
        name: "activated",
        label: "Activado",
        value: itemSelected.activated === true ? itemSelected.activated : itemSelected.activated === false ? false : null,
        defaultValue: false,
        options: [
          {
            value: null,
            label: "Seleccione un valor",
            disabled: true
          },
          {
            value: true,
            label: "Activado"
          },
          {
            value: false,
            label: "Desactivado"
          },
        ]
      },
    ]
  ), [itemSelected, formValues, fotoNombre]);

  const handleChangePage = useCallback(
    (event, newPage) => {
      if (page > newPage) {
        setSkipLocal(skipLocal - rowsPerPage);
      } else {
        setSkipLocal(skipLocal + rowsPerPage);
      }
      setPage(newPage);
    },
    [skipLocal, page, rowsPerPage],
  )


  const handleChangeRowsPerPage = useCallback(
    (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    },
    [],
  )

  const handleClickEdit = useCallback(
    (item) => {
      setItemSelected(item);
      setFormError(null);

      setView(2);
    },
    [],
  )


  const handleClickDelete = useCallback(
    item => {
      setItemSelected(item);
      setOpenConfirmDialog(true);
    },
    [],
  )

  const handleClickReturn = useCallback(
    () => {
      setFormError(null);
      setItemSelected({});
      setFormValues(initialState);
      setView(0);
    },
    [initialState],
  )

  const onSubmitForm = useCallback(
    async (data, type) => {

      let dataForm = { ...data };
      //Imagen
      if (fotoNombre) {
        dataForm = {
          ...dataForm,
          image_url: fotoNombre
        }
      }

      if (type === "editar") {
        (!loading) && setLoading(true);
        let respuesta = await dispatch(editRecord(urlEditarUsuario, itemSelected.id, dataForm));
        setLoading(false);
        const { transaccion, mensaje } = respuesta;
        !transaccion ? setError(mensaje) : setError(false);
      }
    },
    [loading, itemSelected, fotoNombre, dispatch],
  )

  const handleConfirmDelete = useCallback(
    async () => {
      (!loading) && setLoading(true);
      let respuesta = await dispatch(deleteRecord(urlEliminarUsuario, itemSelected.id));
      setLoading(false);
      const { transaccion, mensaje } = respuesta;
      !transaccion ? setError(mensaje) : setError(false);
    },
    [loading, itemSelected, dispatch],
  )

  //ROLES DE USUARIO
  const handleOpenModalRol = useCallback(
    async (item) => {
      setLoadingUserRoles(true);
      setOpenModalRol(true);
      const resp = await dispatch(loadUsuarioById(item.id))
      const { transaccion } = resp;

      if (transaccion) {
        setItemSelected(resp.data);
        setError(false);
      } else {
        setError(resp.mensaje);
      }
      setLoadingUserRoles(false);
    },
    [dispatch],
  );

  const handleCloseModalRol = useCallback(
    () => {
      setOpenModalRol(false);
      setItemSelected({});
    },
    [],
  );

  const verifyRolState = useCallback(
    //Verificar si un usuario tiene el rol de la lista del modal
    (rolName) => {
      let rolFind = [];
      if (!itemSelected.roles) {
        return false;
      }
      rolFind = itemSelected.roles.filter(rol => rol.authority_name === rolName);
      if (rolFind.length >= 1) {
        return true;
      } else {
        return false;
      }
    },
    [itemSelected],
  );
  const handleToggleRolUser = useCallback(
    async (rolName, currentState) => {
      setloadingRol(rolName);
      if (currentState) {
        const resp = await dispatch(startDeleteRoleUser(itemSelected.id, rolName));
        const { transaccion } = resp;
        if (transaccion) {
          let itemChange = {
            ...itemSelected,
            roles: itemSelected.roles.filter(rol => rol.authority_name !== rolName)
          }
          setItemSelected(itemChange);

          setError(false);

        } else {
          setError(resp.mensaje);
        }

      } else {
        const resp = await dispatch(startAddRoleUser(itemSelected.id, rolName));
        const { transaccion } = resp;
        if (transaccion) {
          let itemChange = {
            ...itemSelected,
            roles: [...itemSelected.roles, { authority_name: rolName }]
          }
          setItemSelected(itemChange);

          setError(false);
        } else {
          setError(resp.mensaje);
        }
      }
      setloadingRol(null);
    },
    [itemSelected, dispatch],
  );

  //Ordenamiento
  const handleRequestSort = useCallback(
    async (sortByColumn) => {
      let sortOrderNew;
      if (!sortBy || sortByColumn !== sortBy) {
        sortOrderNew = "asc";
        setOrderData({
          sortBy: sortByColumn, // default sort column
          sortOrder: sortOrderNew // default sort oder
        })
      } else {
        if (sortByColumn === sortBy) {
          sortOrderNew = sortOrder === "asc" ? "desc" : "asc"
          setOrderData({
            sortBy: sortByColumn, // default sort column
            sortOrder: sortOrderNew// default sort oder
          })
        }
      }
    },
    [sortBy, sortOrder],
  );

  const titulosTable = [
    { title: "Id", onClickSort: () => handleRequestSort("id"), columnName: "id" },
    { title: "Authority" },
    { title: "Login", onClickSort: () => handleRequestSort("login"), columnName: "login" },
    { title: "Nombres", onClickSort: () => handleRequestSort("first_name"), columnName: "first_name" },
    { title: "Apellidos", onClickSort: () => handleRequestSort("last_name"), columnName: "last_name" },
    { title: "Email", onClickSort: () => handleRequestSort("email"), columnName: "email" },
    { title: "Image url", onClickSort: () => handleRequestSort("image_url"), columnName: "image_url" },
    { title: "Activado", onClickSort: () => handleRequestSort("activated"), columnName: "activated" },
    { title: "Fecha registro", onClickSort: () => handleRequestSort("created_date"), columnName: "created_date" },

    { title: "Opciones" },
  ];

  const initLoadData = useCallback(
    async ({ skip = 0, take = rowsPerPageDefault, search = '', sortBy = 'fecha_reserva', sort = 'DESC' } = {}) => {
      setLoading(true);
      let respuesta = await dispatch(loadData({ url: urlListarUsuarios, skip, take, search, sortBy, sort }));
      setLoading(false);
      const { transaccion, mensaje } = respuesta;

      !transaccion ? setError(mensaje) : setError(false);
    },
    [dispatch],
  )

  useEffect(() => {
    initLoadData({ skip: skipLocal, take: rowsPerPage, search: searchValue, sortBy, sort: sortOrder });
  }, [rowsPerPage, skipLocal, initLoadData, sortOrder, sortBy, searchValue]);

  useEffect(() => {
    return () => {
      dispatch(adminRestartState())
    }
  }, [dispatch])

  return (
    <AdminLayout viewState={view}>
      <div>
        <Modal
          open={openModalRol}
          onClose={handleCloseModalRol}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}
        >
          <Fade in={openModalRol}>
            <Paper className={classes.paper} style={{ textAlign: 'center' }}>
              <div className={classes.paperTitle}>
                <h5>Roles de usuario</h5>
              </div>
              <Loading loading={loadingUserRoles}>
                <List dense className={classes.root}>
                  {
                    usrRoles && usrRoles.map((rol, index) => (
                      <ListItem key={index} button>
                        <ListItemText id={rol.name} primary={`${rol.name}`} />
                        <ListItemSecondaryAction>
                          {
                            loadingRol === rol.name ? <CircularProgress size={20} />
                              :
                              <Checkbox
                                edge="end"
                                onChange={() => handleToggleRolUser(rol.name, verifyRolState(rol.name))}
                                checked={verifyRolState(rol.name)}
                                inputProps={{ 'aria-labelledby': rol.name }}
                              />
                          }
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))
                  }
                </List>
              </Loading>
            </Paper>
          </Fade>
        </Modal>
        <ConfirmDialog title={"Confirmación de eliminación"} open={openConfirmDialog}
          setOpen={setOpenConfirmDialog} onConfirm={handleConfirmDelete}  >
          <div>¿Esta seguro de eliminar este registro?. La información ligada (cliente, médico, secretaria, roles) a este usuario también será eliminada.</div>
        </ConfirmDialog>
        {
          formError &&
          <Alert severity="error">{formError}</Alert>
        }
        {
          error &&
          <Alert severity="error">{error}</Alert>
        }
        {
          view === 0 ?

            <div>
              {/* Botón buscar */}
              <SearchTextField
                value={search}
                onChange={handleInputSearchChange}
                style={{ marginTop: '25px', marginBottom: '25px' }}
              />

              <Loading title="Cargando..." loading={loading}>
                <TableContainer component={Paper}>
                  <Table className={classes.table}>
                    <TableHead className={classes[`${'warning'} ${'TableHeader'}`]}>
                      <TableRow className={classes.tableHeadRow}>
                        {titulosTable &&
                          titulosTable.map((item, index) => (
                            item.onClickSort ?
                              <TableCellHeader key={index}>
                                <TableSortLabel
                                  active={sortBy === item.columnName}
                                  direction={sortOrder}
                                  onClick={item.onClickSort}
                                  hideSortIcon={sortBy !== item.columnName}
                                  className={classes.tableCell + " " + classes.tableHeadCell}
                                >
                                  {item.title}
                                </TableSortLabel>
                              </TableCellHeader>
                              :
                              <TableCellHeader key={index} className={classes.tableCell + " " + classes.tableHeadCell}>
                                {item.title}
                              </TableCellHeader>
                          ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {
                        dataCRUD &&
                        dataCRUD.map((item) => (
                          <TableRowCrud key={item.id} keyValue={item.id} setView={setView} item={item}
                            handleEdit={handleClickEdit} handleDelete={handleClickDelete}>
                            <CustomTableCellCrud classes={classes.sizeCell} data={item.id} />
                            <TableCell size="small" className={classes.tableCell}>
                              <div style={{ display: "flex", flexDirection: "row" }}>
                                <Button
                                  variant="contained"
                                  className={classes.button}
                                  onClick={() => handleOpenModalRol(item)}
                                >
                                  Administrar
                                </Button>
                              </div>
                            </TableCell>
                            <CustomTableCellCrud
                              data={item.login}
                            />
                            <CustomTableCellCrud
                              data={item.first_name}
                            />
                            <CustomTableCellCrud
                              data={item.last_name}
                            />
                            <CustomTableCellCrud
                              data={item.email}
                            />
                            <CustomTableCellCrud
                              data={item.image_url}
                            />
                            <CustomTableCellCrud
                              data={(item.activated === true) ? "Activado" : (item.activated === false) ? "Desactivado" : "Sin definir"}
                            />

                            <CustomTableCellCrud
                              data={item.created_date && moment(item.created_date).format('DD/MMMM/YYYY')}
                            />
                          </TableRowCrud>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Loading>
              <TablePagination
                rowsPerPageOptions={[10, 20, 100]}
                component="div"
                count={total ? +total : 10}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
            :
            (

              <FormCrud setView={setView} edit={view === 2 ? true : false}
                formComponents={initialStateFormComponents}
                onSubmitForm={onSubmitForm} handleReturn={handleClickReturn} isLoading={loading}
                hasError={error}
              />
            )
        }
      </div>
    </AdminLayout>

  )
}

export default UsuarioCrud;
