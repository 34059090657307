import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { LoginModal } from "components/Login/modal/LoginModal";
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  divLink: {
    width: "100%",
  },
  link: {
    width: "100%",
    height: "100%",
    color: "#fff",
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

function TransitionsModal() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <div className={classes.divLink}>
      <Link
        className={classes.link} onClick={() => setOpen(true)}
      >
      Ingresar
      </Link>
      {/* <Button className={classes.link} onClick={() => setOpen(true)}>
        INGRESAR
      </Button> */}
      <LoginModal open={open} handleOpen={() => setOpen(true)} handleClose={() => setOpen(false)} initValue={0} />
    </div>
  );
}

export default TransitionsModal;
