import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import { Typography } from '@material-ui/core';
import TodayIcon from '@material-ui/icons/Today';
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import RecetarioStep from 'components/HistoriaClinica/ConsultaStepper';
import { cleanCliente } from 'redux/actions/aClientes';
import { cleanRecetas } from 'redux/actions/aRecetas';

const useStyles = makeStyles(styles);

const Recetario = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    //Verificar si se establecio un cliente desde otra página
    const setClienteFlag = localStorage.getItem('setCliente')
    dispatch(cleanRecetas());
    if (!setClienteFlag) dispatch(cleanCliente());
    else localStorage.removeItem('setCliente');
    setIsLoaded(true);
    return () => {
      dispatch(cleanCliente());
      dispatch(cleanRecetas());
    }
  }, [dispatch]);


  return (
    <div>
      <div style={{ display: 'flex' }}>
        <TodayIcon />
        <div borders="borderBottom">
          <Typography display="inline" variant="subtitle1">
            Historia Clínica
          </Typography>
        </div>
      </div>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitle}>Consulta</h4>
        </CardHeader>
        <CardBody>
          <RecetarioStep isLoaded={isLoaded} />
        </CardBody>
      </Card>
    </div>
  );
};

export default Recetario;
