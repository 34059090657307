import React, { useMemo } from 'react'
import moment from 'moment'
import { ListItemText } from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { useSelector } from 'react-redux'

export const PacienteData = () => {

  const cliente_data_state = useSelector((state) => state.data_clientes?.data);

  const data = useMemo(() => ([
    { label: 'Cédula Identidad', value: !!cliente_data_state.cedula_identidad ? cliente_data_state.cedula_identidad : '-', },
    { label: 'Cédula Representante', value: !!cliente_data_state.cedula_representante ? cliente_data_state.cedula_representante : '-', },
    { label: 'Título Honorífico', value: !!cliente_data_state.titulo_honorifico ? cliente_data_state.titulo_honorifico : '-', },
    { label: 'Nombres', value: !!cliente_data_state.nombres ? cliente_data_state.nombres : '-', },
    { label: 'Apellidos', value: !!cliente_data_state.apellidos ? cliente_data_state.apellidos : '-', },
    { label: 'Nombres Padre', value: !!cliente_data_state.nombres_padre ? cliente_data_state.nombres_padre : '-', },
    { label: 'Nombres Madre', value: !!cliente_data_state.nombres_madre ? cliente_data_state.nombres_madre : '-', },
    { label: 'Dirección', value: !!cliente_data_state.direccion ? cliente_data_state.direccion : '-', },
    { label: 'Ciudad', value: !!cliente_data_state.ciudad ? cliente_data_state.ciudad : '-', },
    { label: 'País de residencia', value: !!cliente_data_state.pais ? cliente_data_state.pais : '-', },
    { label: 'País de origen', value: !!cliente_data_state.nacionalidad ? cliente_data_state.nacionalidad : '-', },
    { label: 'Sexo', value: !!cliente_data_state.sexo ? cliente_data_state.sexo : '-', },
    { label: 'Teléfono', value: !!cliente_data_state.telefono ? cliente_data_state.telefono : '-', },
    { label: 'Celular', value: !!cliente_data_state.celular ? cliente_data_state.celular : '-', },
    { label: 'Fecha Nacimiento', value: !!cliente_data_state.fecha_nacimiento ? moment.utc(cliente_data_state.fecha_nacimiento).format('LL') : '-', },
    { label: 'Tipo Sangre', value: !!cliente_data_state.tipo_sangre ? cliente_data_state.tipo_sangre : '-', },
    { label: 'Correo', value: !!cliente_data_state.correo ? cliente_data_state.correo : '-', },
    { label: 'Instrucción', value: !!cliente_data_state.instruccion ? cliente_data_state.instruccion : '-', },
    { label: 'Ocupación', value: !!cliente_data_state.ocupacion ? cliente_data_state.ocupacion : '-', },
    { label: 'Estado Civil', value: !!cliente_data_state.estado_civil ? cliente_data_state.estado_civil : '-', },
    { label: 'Seguros', value: cliente_data_state.seguros && cliente_data_state.seguros.length > 0  ? cliente_data_state.seguros.map( item => item.nombre).join(', ') : '-', },
  ]), [cliente_data_state])
  
  return (
    <div style={{ width: '100%' }}>
      <GridContainer>
        {
          cliente_data_state && data.map((field, index) => (
            <GridItem item xs={12} sm={6} md={4} key={index}>
              <ListItemText primary={field.label} secondary={field.value} />
            </GridItem>
          ))
        }
      </GridContainer>
    </div>
  )
}
