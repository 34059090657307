import { createAction } from 'redux-actions';
import { urlBuscarMed, urlBuscarMedDoctor } from '../../constants/urls';
import { jwt } from './aLogin';
import Notificacion from "components/ui/Notificacion";

export const vademecum = createAction('SET_DATA_VADEMECUM');
export const isLoadingVademecum = createAction('LOADING_VADEMECUM');
export const hasErrorVademecum = createAction('ERROR_VADEMECUM');

export const vademecumDoctor = createAction('SET_DATA_VADEMECUM_DOCTOR');
export const isLoadingVademecumDoctor = createAction('LOADING_VADEMECUM_DOCTOR');
export const hasErrorVademecumDoctor = createAction('ERROR_VADEMECUM_DOCTOR');

export const consultarVademecum = busqueda => async (dispatch, getstate) => {
  dispatch(isLoadingVademecum());
  const state = getstate();

  const { token } = state;

  var requestOptions = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  };

  return fetch(urlBuscarMed + busqueda, requestOptions)
    .then(response => response.json())
    .then(datos => {
      const { token = true } = datos;
      if (!token) {
        dispatch(jwt(''));
        return;
      }

      if (datos.transaccion) {
        const { data } = datos;
        dispatch(vademecum(data));
      } else {
        dispatch(hasErrorVademecum(`${datos.mensaje}`));
      }
    })
    .catch(e => {
      dispatch(hasErrorVademecum(`Error en el servicio, ${e}`));
    });
};

export const consultarVademecumTodo = busqueda => async (dispatch, getstate) => {
  dispatch(isLoadingVademecum());
  const state = getstate();

  const { token, dataUser } = state;

  const { doctor_id } = dataUser.data;
  var requestOptions = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  };

  return fetch(urlBuscarMed + 'todo/' + busqueda + '/' + doctor_id, requestOptions)
    .then(response => response.json())
    .then(datos => {
      const { token = true } = datos;
      if (!token) {
        dispatch(jwt(''));
        return;
      }

      if (datos.transaccion) {
        const { data } = datos;
        dispatch(vademecum(data));
      } else {
        dispatch(hasErrorVademecum(`${datos.mensaje}`));
      }
    })
    .catch(e => {
      dispatch(hasErrorVademecum(`Error en el servicio, ${e}`));
    });
};

export const consultarVademecumTodoLimit = busqueda => async (dispatch, getstate) => {
  dispatch(isLoadingVademecum());
  const state = getstate();

  const { token, dataUser } = state;

  const { doctor_id } = dataUser.data;
  var requestOptions = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  };

  const limit = 40;

  return fetch(urlBuscarMed + 'todo/' + busqueda + '/' + doctor_id + '/' + limit, requestOptions)
    .then(response => response.json())
    .then(datos => {
      const { token = true } = datos;
      if (!token) {
        dispatch(jwt(''));
        return;
      }

      if (datos.transaccion) {
        const { data } = datos;
        dispatch(vademecum(data));
      } else {
        dispatch(hasErrorVademecum(`${datos.mensaje}`));
      }
    })
    .catch(e => {
      dispatch(hasErrorVademecum(`Error en el servicio, ${e}`));
    });
};

export const getAllVademecum = () => async (dispatch, getstate) => {
  dispatch(isLoadingVademecum());
  const state = getstate();

  const { token } = state;

  var requestOptions = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  };

  return fetch(urlBuscarMed, requestOptions)
    .then(response => response.json())
    .then(datos => {
      const { token = true } = datos;
      if (!token) {
        dispatch(jwt(''));
        return;
      }
      if (datos.transaccion) {
        const { data } = datos;
        dispatch(vademecum(data));
      } else {
        dispatch(hasErrorVademecum(`${datos.mensaje}`));
      }
    })
    .catch(e => {
      dispatch(hasErrorVademecum(`Error en el servicio, ${e}`));
    });
};

export const medicinaSet = (param, id) => async (dispatch, getstate) => {
  dispatch(isLoadingVademecumDoctor());
  try {
    const state = getstate();
    const { token } = state;
    const url = id ? urlBuscarMed + id : urlBuscarMed;

    const raw = JSON.stringify(param);
    var requestOptions = {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: raw,
    };

    return fetch(url, requestOptions)
      .then(response => response.json())
      .then(datos => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(''));
          return;
        }
        if (datos.transaccion) {
          const { data } = datos;
          dispatch(vademecumDoctor(data));
          Notificacion({
            type: "success",
            text: !id ? 'Se ha agregado con éxito un nuevo medicamento' : 'Se ha editado con éxito un nuevo medicamento',
          });
        } else {
          Notificacion({
            type: "error",
            text: datos.mensaje,
          });
          dispatch(hasErrorVademecumDoctor(datos.mensaje));
        }
      });
  } catch (error) {
    dispatch(hasErrorVademecumDoctor('No se pudo guardar la información recargue la pagina '));
    Notificacion({
      type: "error",
      text: 'No se pudo guardar la información recargue la pagina',
    });
  }
};

export const deleteVadDoctor = param => async (dispatch, getstate) => {
  dispatch(isLoadingVademecumDoctor());
  try {
    const state = getstate();
    const { token } = state;

    var requestOptions = {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };

    return fetch(urlBuscarMed + param, requestOptions)
      .then(response => response.json())
      .then(datos => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(''));
          return;
        }
        if (datos.transaccion) {
          Notificacion({
            type: "success",
            text: 'Registro eliminado existosamente',
          });
        } else {
          dispatch(hasErrorVademecumDoctor(datos.mensaje));
          Notificacion({
            type: "error",
            text: datos.mensaje,
          });
        }
      });
  } catch (error) {
    dispatch(hasErrorVademecumDoctor('No se pudo recuperar la información recargue la pagina '));
    Notificacion({
      type: "error",
      text: 'No se pudo guardar la información recargue la pagina',
    });
  }
};

export const consultarVademecumDoctor = () => async (dispatch, getstate) => {
  dispatch(isLoadingVademecumDoctor());
  const state = getstate();

  const { token, dataUser } = state;
  const { doctor_id } = dataUser.data;

  var requestOptions = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  };

  return fetch(urlBuscarMedDoctor + doctor_id, requestOptions)
    .then(response => response.json())
    .then(datos => {
      const { token = true } = datos;
      if (!token) {
        dispatch(jwt(''));
        return;
      }
      if (datos.transaccion) {
        const { data } = datos;
        dispatch(vademecumDoctor(data));
      } else {
        dispatch(hasErrorVademecumDoctor(`${datos.mensaje}`));
      }
    })
    .catch(e => {
      dispatch(hasErrorVademecumDoctor(`Error en el servicio, ${e}`));
    });
};
