import React, { createContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import ConsultaResumen from './steps/ConsultaResumen';
import PacienteSelector from './steps/PacienteSelector';
import ConsultaPaciente from './steps/ConsultaPaciente';
import { useDispatch, useSelector } from 'react-redux';
import { OficinaSelector } from './steps/OficinaSelector';
import { startLoadConsultaParams } from 'redux/actions/aMedico';
import { daysWeeksNumber } from 'constants/constants';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
}));

// Context
export const ConsultaContext = createContext({
  oficinasOptions: [],
  serviciosOptions: [],
  oficinaSelected: null,
  servicioSelected: '0',
  loading: false,
  setOficinaSelected: () => { },
  setServicioSelected: () => { },
  handleNext: () => { },
  setLoading: () => { },
});

const RecetarioStep = ({ isLoaded }) => {

  const { data: dataCliente } = useSelector(
    (state) => state.data_clientes
  );

  const { id: idCliente } = dataCliente;

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = ['Consultorio/Servicio', 'Paciente', 'Consulta', 'Resumen'];
  const [medidasCliente, setmedidasCliente] = React.useState({
    peso: '',
    talla: '',
    pa: '',
    cie: '',
    imc: '',
  });

  const [cie10, setCie10] = useState([]);
  const [alergias, setAlergias] = useState('');
  const [medicinas, setMedicinas] = useState([]);

  const [receta, setReceta] = useState(null);
  const [IdCliente, setIdCliente] = useState('');

  const [loading, setLoading] = useState(false);

  // Oficina/Servicios
  const [oficinasOptions, setOficinasOptions] = useState([]);
  const [serviciosOptions, setServiciosOptions] = useState([]);
  const [oficinaSelected, setOficinaSelected] = useState(null);
  const [servicioSelected, setServicioSelected] = useState(null);


  const handleNext = () => {
    if (activeStep === 0) {
      // Oficina/Servicio
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    } else if (activeStep === 1) {
      // Cliente
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    } else if (activeStep === 2) {
      // Consulta
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    } else if (activeStep === 3) {
      // Resumen
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = (step = null) => {
    if (step !== null)
      setActiveStep(step);
    else
      setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const dispatch = useDispatch();

  // Oficinas/Servicios
  useEffect(() => {
    setServicioSelected(null);
    if (oficinaSelected) {
      const oficinaSelectedInfo = oficinasOptions.find((oficina) => oficina.id === oficinaSelected);
      setServiciosOptions(oficinaSelectedInfo.serviciosAsignados ?? []);
    }
    setServicioSelected("0");
  }, [oficinaSelected, oficinasOptions])

  useEffect(() => {
    if (isLoaded && idCliente) setIdCliente(idCliente);
  }, [isLoaded, idCliente])

  // load consulta options
  useEffect(() => {
    setLoading(true);
    dispatch(startLoadConsultaParams()).then(({ oficinas }) => {
      if (oficinas) setOficinasOptions(oficinas);
      setLoading(false);
    })
  }, [dispatch])

  // Oficinas: Seleccionar oficina por defecto
  useEffect(() => {
    if (oficinasOptions.length === 0) return;
    if (oficinasOptions.length === 1) {
      setOficinaSelected(oficinasOptions[0].id)
      return
    }
    // Seleccionar oficina por defecto
    const currentDayNumberWeek = moment().day();
    const currentDayName = daysWeeksNumber[currentDayNumberWeek].toString();

    const currentDateOnly = moment().format('YYYY-MM-DD');
    // 16:00:00+00

    let officeSelectedFilter = null;

    for (let i = 0; i < oficinasOptions.length; i++) {
      const oficina = oficinasOptions[i];
      if (!oficina.horarios) continue;
      for (let j = 0; j < oficina.horarios.length; j++) {
        const horario = oficina.horarios[j];
        // let dateTimeString = `${currentDateOnly}T${horario.hora_inicio_tz}:00`;
        const startDate = moment(`${currentDateOnly}T${horario.hora_inicio_tz}:00`);
        const endDate = moment(`${currentDateOnly}T${horario.hora_fin_tz}:00`);

        if (currentDayName === horario.dia_semana && (moment().isSameOrAfter(startDate) && moment().isSameOrBefore(endDate))) {
          officeSelectedFilter = oficina.id;
          break;
        }
      }

      if (officeSelectedFilter) break;
    }

    if (officeSelectedFilter) setOficinaSelected(officeSelectedFilter);

  }, [oficinasOptions]);

  return (
    <ConsultaContext.Provider value={{
      oficinasOptions,
      serviciosOptions,
      oficinaSelected,
      servicioSelected,
      setOficinaSelected,
      setServicioSelected,
      handleNext,
      loading,
      setLoading,
    }}>
      <div className={classes.root}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <div
                style={{
                  margin: '50px auto',
                  display: 'flex',
                  flex: '1',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography className={classes.instructions}>Se ha reservado la cita</Typography>
                <CheckCircleIcon fontSize="large" htmlColor="green" />
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={handleReset}>Nueva reserva</Button>
              </div>
            </div>
          ) : (
            <div>
              {
                activeStep === 0 && (
                  <OficinaSelector />
                )
              }
              {activeStep === 1 && (
                <PacienteSelector setIdCliente={val => setIdCliente(val)} onClick={handleNext} isLoaded={isLoaded} />
              )}

              {activeStep === 2 && (
                <ConsultaPaciente
                  cliente_id={IdCliente}
                  handleClickNext={handleNext}
                  handleBack={handleBack}
                  handleSetMedidas={param => setmedidasCliente(param)}
                  handleSetCie10={setCie10}
                  handleSetAlergias={setAlergias}
                  handleSetMedicinas={setMedicinas}
                  setReceta={setReceta}
                />
              )}
              {activeStep === 3 && <ConsultaResumen receta={receta} medidas={medidasCliente} medicinas={medicinas} cie10={cie10} alergias={alergias} />}

            </div>
          )}
        </div>
      </div>
    </ConsultaContext.Provider>

  );
};

export default RecetarioStep;
