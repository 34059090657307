import { createAction } from "redux-actions";
import {
  urlHorariosOficinas,
  urlOficinas,
  urlHorariosReservas,
  urlDeleteHorariosOficinas,
  urlGetMedicos,
  urlOficinasLaboratorista,
  urlHorariosOficinasLaboratorista,
  urlHorariosReservasLaboratorista,
  urlInstitucionOficinasEspecialidades,
  urlInstitucionGuardarOficina,
  urlDoctorGuardarOficina,
} from "../../constants/urls";
import { jwt } from "./aLogin";
import Notificacion from "components/ui/Notificacion";
import { servicios } from "./aMedico";
import { empresa } from "./aAdmin";
import { especialidades } from "./aEspecialidades";

//Oficinas
export const oficinas = createAction("OFICINAS");
export const selectOficina = createAction("OFICINA_SELECTED");
export const isLoadingOficinas = createAction("IS_LOADING_O");
export const hasErrorOficinas = createAction("HAS_ERROR_O");

//Horarios
export const horariosOficina = createAction("HORARIOS_OFICINA");
export const isLoading = createAction("IS_LOADING_H_O");
export const hasError = createAction("HAS_ERROR_H_O");

//Horarios-Reservas
export const horarios_reservas = createAction("HORARIOS_RESERVAS");
export const isLoadingHorarioReservas = createAction("IS_LOADING_H_R");
export const hasErrorHorarioReservas = createAction("HAS_ERROR_H_R");

//Medicos de la secretaria
export const medicos = createAction("MEDICOS");
export const isLoadingMedicos = createAction("IS_LOADING_M");
export const hasErrorMedicos = createAction("HAS_ERROR_M");

export const getMedicos = (param) => async (dispatch, getstate) => {
  dispatch(isLoadingMedicos());
  try {
    const state = getstate();
    const { token } = state;

    const arrayDoctores = state.dataUser.data.oficinas.map(oficina => oficina.doctor_id);

    const raw = JSON.stringify(arrayDoctores);

    var requestOptions = {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: raw,
    };

    const response = await fetch(urlGetMedicos, JSON.parse(JSON.stringify(requestOptions)));
    const datos = await response.json();

    const { token: tk = true } = datos;
    if (!tk) {
      dispatch(jwt(""));
      return;
    }
    if (datos.transaccion) dispatch(medicos(datos.data));

  } catch (error) {
    console.log(error)
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    dispatch(
      hasErrorMedicos("No se pudo guardar la información recargue la pagina ")
    );
  }
};

export const getOficinasDoctor = () => async (dispatch, getstate) => {
  dispatch(isLoadingOficinas());
  try {
    const state = getstate();
    const { token, dataUser } = state;
    const { doctor_id } = dataUser.data;

    var requestOptions = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    return fetch(urlOficinas + doctor_id, requestOptions)
      .then((response) => response.json())
      .then((datos) => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(""));
          return;
        }
        if (datos.transaccion) {
          dispatch(oficinas(datos.data));
          dispatch(servicios(datos.dataServicios));
          dispatch(empresa(datos.dataEmpresa));
          dispatch(especialidades(datos.dataEspecialidades ?? []));
        } else {
          dispatch(hasErrorOficinas(datos.mensaje));
        }
      })
      .catch(err => {
        Notificacion({
          type: "error",
          text: `Error de conexión`,
        });
      });
  } catch (error) {
    dispatch(
      hasErrorOficinas(
        "No se pudo recuperar la información recargue la pagina "
      )
    );
  }
};
export const getOficinasLaboratorista = () => async (dispatch, getstate) => {
  dispatch(isLoadingOficinas());
  try {
    const state = getstate();
    const { token, dataUser } = state;
    const { laboratorista_id } = dataUser.data;

    var requestOptions = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    return fetch(urlOficinasLaboratorista + laboratorista_id, requestOptions)
      .then((response) => response.json())
      .then((datos) => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(""));
          return;
        }
        if (datos.transaccion) {
          dispatch(oficinas(datos.data));
        } else {
          dispatch(hasErrorOficinas(datos.mensaje));
        }
      });
  } catch (error) {
    dispatch(
      hasErrorOficinas(
        "No se pudo recuperar la información recargue la pagina "
      )
    );
  }
};

export const oficinaSet = (param, institucion = false) => async (dispatch, getstate) => {
  dispatch(isLoadingOficinas());
  try {
    const state = getstate();
    const { token } = state;
    // const { doctor_id } = dataUser.data;

    const raw = JSON.stringify(param);
    var requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: raw,
    };

    const url = institucion ? urlInstitucionGuardarOficina : urlDoctorGuardarOficina;

    return fetch(url, requestOptions)
      .then((response) => response.json())
      .then((datos) => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(""));
          return { transaccion: false };
        }
        if (datos.transaccion) {
          dispatch(oficinas(datos.data));
          Notificacion({
            title: "Oficina",
            text: "Oficina Guardada",
            type: "success",
          });
        } else {
          Notificacion({
            title: "Oficina",
            text: `Error guardando, ${datos.mensaje}`,
            type: "error",
          });
          // dispatch(hasErrorOficinas(datos.mensaje));
        }
        return datos;
      });
  } catch (error) {
    Notificacion({
      title: "Oficina",
      text: `Error, ${error}`,
      type: "error",
    });
    return { transaccion: false };
    // dispatch(
    //   hasErrorOficinas("No se pudo guardar la información recargue la pagina ")
    // );
  }
};
export const oficinaSetLaboratorista = (param) => async (
  dispatch,
  getstate
) => {
  dispatch(isLoadingOficinas());
  try {
    const state = getstate();
    const { token, dataUser } = state;
    const { laboratorista_id } = dataUser.data;

    const raw = JSON.stringify(param);
    var requestOptions = {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: raw,
    };

    return fetch(urlOficinasLaboratorista + laboratorista_id, requestOptions)
      .then((response) => response.json())
      .then((datos) => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(""));
          return;
        }
        if (datos.transaccion) {
          dispatch(oficinas(datos.data));
        } else {
          dispatch(hasErrorOficinas(datos.mensaje));
        }
      });
  } catch (error) {
    dispatch(
      hasErrorOficinas("No se pudo guardar la información recargue la pagina ")
    );
  }
};

export const getHorariosOficina = (doctor_id) => async (dispatch, getstate) => {
  dispatch(isLoading());
  try {
    const state = getstate();
    const { token } = state;

    var requestOptions = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    return fetch(
      urlHorariosOficinas + doctor_id,
      JSON.parse(JSON.stringify(requestOptions))
    )
      .then((response) => response.json())
      .then((datos) => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(""));
          return;
        }
        if (datos.transaccion) {
          dispatch(horariosOficina(datos.data));
        } else {
          dispatch(hasError(datos.mensaje));
        }
      })
      .catch(errr => { });
  } catch (error) {
    dispatch(
      hasError("No se pudo recuperar la información recargue la pagina ")
    );
  }
};

export const getHorariosLaboratorista = () => async (dispatch, getstate) => {
  dispatch(isLoading());
  try {
    const state = getstate();
    const { token, dataUser } = state;
    const { laboratorista_id } = dataUser.data;
    var requestOptions = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    return fetch(
      urlHorariosOficinasLaboratorista + laboratorista_id,
      JSON.parse(JSON.stringify(requestOptions))
    )
      .then((response) => response.json())
      .then((datos) => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(""));
          return;
        }
        if (datos.transaccion) {
          dispatch(horariosOficina(datos.data));
        } else {
          dispatch(hasError(datos.mensaje));
        }
      });
  } catch (error) {
    dispatch(
      hasError("No se pudo recuperar la información recargue la pagina ")
    );
  }
};

export const getHorariosReserva = () => async (dispatch, getstate) => {
  dispatch(isLoadingHorarioReservas());
  try {
    const state = getstate();
    const { token, dataUser } = state;
    const { doctor_id } = dataUser.data;

    var requestOptions = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    return fetch(
      urlHorariosReservas + doctor_id,
      JSON.parse(JSON.stringify(requestOptions))
    )
      .then((response) => response.json())
      .then((datos) => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(""));
          return;
        }
        if (datos.transaccion) {
          let { horarios_oficina, ocupados } = datos;

          dispatch(horarios_reservas({ horarios_oficina, ocupados }));
        } else {
          dispatch(hasErrorHorarioReservas(datos.mensaje));
        }
      })
      .catch(errr => { });
  } catch (error) {
    dispatch(
      hasErrorHorarioReservas(
        "No se pudo recuperar la información recargue la pagina "
      )
    );
  }
};

export const getHorariosReservaLaboratorista = () => async (
  dispatch,
  getstate
) => {
  dispatch(isLoadingHorarioReservas());
  try {
    const state = getstate();
    const { token, dataUser } = state;
    const { laboratorista_id } = dataUser.data;

    var requestOptions = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    return fetch(
      urlHorariosReservasLaboratorista + laboratorista_id,
      JSON.parse(JSON.stringify(requestOptions))
    )
      .then((response) => response.json())
      .then((datos) => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(""));
          return;
        }
        if (datos.transaccion) {
          let { horarios_oficina, ocupados } = datos;

          dispatch(horarios_reservas({ horarios_oficina, ocupados }));
        } else {
          dispatch(hasErrorHorarioReservas(datos.mensaje));
        }
      });
  } catch (error) {
    dispatch(
      hasErrorHorarioReservas(
        "No se pudo recuperar la información recargue la pagina "
      )
    );
  }
};
export const getHorariosReservaSecretaria = (doctor_id) => async (
  dispatch,
  getstate
) => {
  dispatch(isLoadingHorarioReservas());

  try {
    const state = getstate();
    const { token } = state;

    var requestOptions = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    return fetch(
      urlHorariosReservas + doctor_id,
      JSON.parse(JSON.stringify(requestOptions))
    )
      .then((response) => response.json())
      .then((datos) => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(""));
          return;
        }
        if (datos.transaccion) {
          let { horarios_oficina, ocupados } = datos;

          dispatch(horarios_reservas({ horarios_oficina, ocupados }));
        } else {
          dispatch(hasErrorHorarioReservas(datos.mensaje));
        }
      });
  } catch (error) {
    dispatch(
      hasErrorHorarioReservas(
        "No se pudo recuperar la información recargue la pagina "
      )
    );
  }
};

export const horariosOficinaSet = (data, oficina_id) => async (
  dispatch,
  getstate
) => {
  dispatch(isLoading());
  try {
    const state = getstate();
    const { token } = state;

    const raw = JSON.stringify(data);
    var requestOptions = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: raw,
    };

    const response = await fetch(
      urlHorariosOficinas + oficina_id,
      JSON.parse(JSON.stringify(requestOptions))
    )
    const datos = await response.json();
    const { token: tokenRes = true } = datos;
    if (!tokenRes) {
      dispatch(jwt(""));
      return;
    }
    if (datos.transaccion) {
      dispatch(horariosOficina(datos.data));
      //Hacer el dispatch agregando lo que viene por que el servicio no retorna nuevamente los horarios
    } else {
      dispatch(hasError(datos.mensaje));
    }
    return

    // .then((response) => response.json())
    // .then((datos) => {
    //   const { token = true } = datos;
    //   if (!token) {
    //     dispatch(jwt(""));
    //     return;
    //   }
    //   if (datos.transaccion) {
    //     dispatch(horariosOficina(datos.data));
    //     //Hacer el dispatch agregando lo que viene por que el servicio no retorna nuevamente los horarios
    //   } else {
    //     dispatch(hasError(datos.mensaje));
    //   }
    // });
  } catch (error) {
    dispatch(hasError("No se pudo guardar la información recargue la pagina "));
  }
};

export const horariosOficinaSetLaboratorista = (data, oficina_id) => async (
  dispatch,
  getstate
) => {
  dispatch(isLoading());
  try {
    const state = getstate();
    const { token } = state;

    const raw = JSON.stringify(data);
    var requestOptions = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: raw,
    };

    return fetch(
      urlHorariosOficinasLaboratorista + oficina_id,
      JSON.parse(JSON.stringify(requestOptions))
    )
      .then((response) => response.json())
      .then((datos) => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(""));
          return;
        }
        if (datos.transaccion) {
          dispatch(horariosOficina(datos.data));
          //Hacer el dispatch agregando lo que viene por que el servicio no retorna nuevamente los horarios
        } else {
          dispatch(hasError(datos.mensaje));
        }
      });
  } catch (error) {
    dispatch(hasError("No se pudo guardar la información recargue la pagina "));
  }
};

export const horariosOficinaUpdate = (nueva_data, oficina_id) => async (
  dispatch,
  getstate
) => {
  dispatch(isLoading());
  try {
    const state = getstate();
    const {
      token,
      horariosOficinas: { data },
    } = state;

    const raw = JSON.stringify(nueva_data);
    var requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: raw,
    };

    const response = await fetch(urlDeleteHorariosOficinas + oficina_id, requestOptions)
    const datos = await response.json();
    const { token: tokenRes = true } = datos;
    if (!tokenRes) {
      dispatch(jwt(""));
      return;
    }
    if (datos.transaccion) {
      const index_buscado = data.findIndex((d) => d.id === oficina_id);
      data[index_buscado].hora_inicio = nueva_data.hora_inicio;
      data[index_buscado].hora_fin = nueva_data.hora_fin;
      // const modificado_data = [...data];

      dispatch(horariosOficina(datos.data));
      //Hacer el dispatch agregando lo que viene por que el servicio no retorna nuevamente los horarios
    } else {
      dispatch(hasError(datos.mensaje));
    }
    return
    // .then((reresponsesponse) => response.json())
    // .then((datos) => {
    //   const { token = true } = datos;
    //   if (!token) {
    //     dispatch(jwt(""));
    //     return;
    //   }
    //   if (datos.transaccion) {
    //     const index_buscado = data.findIndex((d) => d.id === oficina_id);
    //     data[index_buscado].hora_inicio = nueva_data.hora_inicio;
    //     data[index_buscado].hora_fin = nueva_data.hora_fin;
    //     const modificado_data = [...data];

    //     dispatch(horariosOficina(datos.data));
    //     //Hacer el dispatch agregando lo que viene por que el servicio no retorna nuevamente los horarios
    //   } else {
    //     dispatch(hasError(datos.mensaje));
    //   }
    // });
  } catch (error) {
    dispatch(hasError("No se pudo guardar la información recargue la pagina "));
  }
};

export const deleteOficina = (param) => async (dispatch, getstate) => {
  dispatch(isLoadingOficinas());
  try {
    const state = getstate();
    const {
      token,
      data_oficinas: { data },
    } = state;

    var requestOptions = {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    return fetch(urlOficinas + param, requestOptions)
      .then((response) => response.json())
      .then((datos) => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(""));
          return;
        }
        if (datos.transaccion) {
          // const index_eliminado = data.findIndex((d) => d.id === param);
          // const eliminado = data.splice(index_eliminado, 1);
          dispatch(oficinas(data.filter(ofi => ofi.id !== param)));
          Notificacion({
            type: "success",
            text: 'Registro eliminado exitosamente',
          });
        } else {
          dispatch(hasErrorOficinas(datos.mensaje));
        }
      });
  } catch (error) {
    dispatch(
      hasErrorOficinas(
        "No se pudo recuperar la información recargue la pagina "
      )
    );
  }
};

export const deleteHora = (param) => async (dispatch, getstate) => {
  dispatch(isLoading());
  try {
    const state = getstate();
    const {
      token,
      horariosOficinas: { data },
    } = state;

    var requestOptions = {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    return fetch(urlDeleteHorariosOficinas + param, requestOptions)
      .then((response) => response.json())
      .then((datos) => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(""));
          return;
        }
        if (datos.transaccion) {
          // const index_eliminado = data.findIndex((d) => d.id === param);
          // const eliminado = data.splice(index_eliminado, 1);
          Notificacion({
            type: "success",
            text: 'Datos eliminados correctamente.',
          });
          const data_nueva = data.filter(item => item.id !== param);
          dispatch(horariosOficina(data_nueva));
        } else {
          dispatch(hasError(datos.mensaje));
        }
      });
  } catch (error) {
    dispatch(
      hasError("No se pudo recuperar la información recargue la pagina ")
    );
  }
};

// Opciones insitucion especialidades

export const getOficinasEspecialidadesOpciones = (oficinaId, data) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    const raw = JSON.stringify(data);

    var requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: raw
    };

    const response = await fetch(`${urlInstitucionOficinasEspecialidades}/${oficinaId}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) return datos.data;
    else throw (new Error(datos.mensaje));

  } catch (error) {
    return { transaccion: false, mensaje: 'No se pudo obtener datos ' }
  }
};

