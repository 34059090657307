import React, { useEffect, useState } from 'react'
import { FormControlLabel, IconButton, Switch, Tooltip } from '@material-ui/core'
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import PrintIcon from '@material-ui/icons/Print'
import { useReactToPrint } from 'react-to-print'
import { ConsentimientoInformadoPrint } from './ConsentimientoInformadoPrint'
import { useSelector } from 'react-redux'
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from "@date-io/moment"
import { AutocompleteComponent } from 'components/ui/AutocompleteComponent'
import { getCie10PorBusqueda } from "redux/actions/aRecetas"

export const ConsentimientoInformado = () => {

  // const [formValues, setFormValues] = useState(valoresInit.current);

  const { dataCie10 } = useSelector(state => state.data_recetas);

  const [showHeader, setShowHeader] = useState(false);

  const [formSubmit, setFormSubmit] = useState(false);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [cie10Code, setCie10Code] = useState("");
  const [imgIntervencion, setImgIntervencion] = useState(null);

  const [cie10values, setCi10values] = React.useState({
    cie: "",
  });

  const componentPrintRef = React.useRef();
  const componentImgRef = React.useRef(null);

  const { data: dataUser } = useSelector(
    (state) => state.dataUser
  );

  const { data: dataCliente } = useSelector(
    (state) => state.data_clientes
  );


  const [consentimientoData, setConsentimientoData] = useState({
    patient: { ...dataCliente },
    doctor: { ...dataUser.medico },
    date: new Date(),
    text: 'texto personalizado'
  });

  const handleReactPrint = useReactToPrint({
    content: () => componentPrintRef.current,
  });

  const handleClickPrint = async () => {
    // const formValues = await getFormValues();
    // if (!formValues) return;
    onPrint();
  }

  const onPrint = () => {
    setConsentimientoData({ ...consentimientoData, date: selectedDate, cie10: cie10Code, imgIntervencion })
    setFormSubmit(true);
  }

  // Cie10 autocomplete
  const handleChangeCIE10 = (event, values, esNuevo) => {
    if (!values) return;
    setCie10Code(`${values.cod_4}`);
    setCi10values({ cie: `${values.cod_4} ${values.descripcion_codigos_cuatro_caracteres}` })
  };

  // Imagen
  const handleFileImgSelected = (event) => {
    event.stopPropagation()
    const file = event.target.files[0];
    // divido para dos para 5MB max
    if (file.size / 1024 / 1024 < 5) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImgIntervencion(reader.result);
      };
    }
  };

  // Date
  const handleDateChange = (date) => {
    setSelectedDate(date.toDate())
    // moment().toDate()
  }

  useEffect(() => {
    if (formSubmit) {
      handleReactPrint();
      setFormSubmit(false);
    }
  }, [consentimientoData, handleReactPrint, formSubmit, selectedDate, cie10Code, imgIntervencion])

  return (
    <div style={{ width: '100%' }}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={2}>
          <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
            <KeyboardDateTimePicker
              variant="inline"
              label="Fecha consulta"
              // inputVariant="outlined"
              format="DD/MM/YYYY HH:mm"
              value={selectedDate}
              onChange={handleDateChange}
            // renderDay={renderWrappedWeekDay}
            // maxDate={new Date()}
            />
          </MuiPickersUtilsProvider>

        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <AutocompleteComponent
            label="cie10"
            id="cie"
            name="cie"
            type="text"
            freeSolo={true}
            fullWidth
            poblarData={[]}
            onBuscadoChange={(event, values) => handleChangeCIE10(event, values, true)}
            optionLabel={option => option && option.cod_4
              ? `${option.cod_4} ${option.descripcion_codigos_cuatro_caracteres} `
              : ''
            }
            dispatchFunction={getCie10PorBusqueda}
            dataSearch={dataCie10}
            value={
              (cie10values.cie) ? { cod_4: cie10values.cie, descripcion_codigos_cuatro_caracteres: '' } : null
            }
          />

        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <input
            type="file"
            name="image"
            accept=".jpeg, .png, .jpg"
            onChange={handleFileImgSelected}
            ref={componentImgRef}
          />
        </GridItem>

      </GridContainer>

      {/* Div imprimir */}
      <div
        style={{ display: "none" }}
      >
        <ConsentimientoInformadoPrint ref={componentPrintRef} header={showHeader}
          data={consentimientoData}
          pdf
        />
      </div>
      {/* Fin div imprimir */}

      {/* Opciones */}
      <div>

        <div style={{ textAlign: "center" }}>
          <FormControlLabel control={<Switch color="primary" checked={showHeader} onChange={(e) => setShowHeader(e.target.checked)} />} label="Imprimir con membrete" />
        </div>

        <GridContainer justifyContent="center" direction="row">

          <GridItem xs={"auto"}>

            <Tooltip
              title="Imprimir"
              placement="top"
            >
              <IconButton
                className="no-printme"
                variant="outlined"
                color="primary"
                onClick={handleClickPrint}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>

          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
