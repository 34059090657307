import { handleActions } from 'redux-actions';
import {
  servicios,

} from '../actions/aMedico';

export const data_servicios = handleActions(
  {
    [servicios]: (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
      hasError: false,
    }),
  },
  { isLoading: false, hasError: false, data: [] }
);
