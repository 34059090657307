import React from "react";
import { Switch } from "react-router-dom";
import LayoutRoute from "../Layout/LayoutRoute";
import LandingPage from "./LandingPage";
import Nosotros from "./Nosotros";
import MainLayout from "Layout/MainLayout";
import { Contacto } from "./Contacto";

const Main = () => (
  <Switch>
    <LayoutRoute
      exact
      path="/Contacto"
      layout={MainLayout}
      component={Contacto}
    />
    <LayoutRoute
      exact
      path="/Nosotros"
      layout={MainLayout}
      component={Nosotros}
    />
    <LayoutRoute exact path="/" layout={MainLayout} component={LandingPage} />
    <LayoutRoute exact path="*" layout={MainLayout} component={LandingPage} />
  </Switch>
);

export default Main;
