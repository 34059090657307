import React, { useEffect, useState, useCallback } from 'react'
import { CircularProgress, TextField } from "@material-ui/core";

import Paper from '@material-ui/core/Paper';

import { makeStyles } from '@material-ui/core/styles';
import { Alert, Autocomplete } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';

import Loading from 'components/Loading';
import { loadHorariosPorOficina } from 'redux/actions/aAdmin';
import { Horario } from 'components/Admin/OficinaHorario/Horario';
import { loadOficinas } from 'redux/actions/aAdmin';

const useStyles = makeStyles({
  paper: {
    padding: "15px 20px 15px 20px",
    marginTop: "20px"
  }
});

const HorarioOficinaCrud = () => {

  const classes = useStyles();
  const { dataCRUD, hasErrorAdmin } = useSelector(state => state.data_admin);
  const [oficinaSelected, setOficinaSelected] = useState({});

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  //Autocomplete
  const [open, setOpen] = React.useState(false);
  const [oficinas, setOficinas] = useState([]);
  const loadingAutoComplete = open && oficinas.length === 0;

  const dispatch = useDispatch();

  const onChangeHandle = async value => {
    let respuesta = await dispatch(loadOficinas(0, 10, value, true));
    const { transaccion, mensaje } = respuesta;
    if (transaccion) {
      setOficinas(respuesta.data.data);

    }
    !transaccion ? setError(mensaje) : setError(false);
  };

  const initHorarios = useCallback(
    async (oficinaSelected) => {
      setLoading(true);
      let respuesta = await dispatch(loadHorariosPorOficina(oficinaSelected.id));
      setLoading(false);
      const { transaccion, mensaje } = respuesta;

      !transaccion ? setError(mensaje) : setError(false);
    },
    [dispatch],
  );

  useEffect(() => {
    if (oficinaSelected.id) {
      initHorarios(oficinaSelected);
    }
  }, [oficinaSelected, initHorarios]);

  return (
    <>
      {error && <Alert severity="error">{error}</Alert>}
      {hasErrorAdmin && <Alert severity="error">{hasErrorAdmin}</Alert>}

      <div>
        <Autocomplete

          id="asynchronous-auto"
          style={{ width: 300 }}
          open={open}
          onChange={(event, newValue) => {
            if (newValue)
              setOficinaSelected(newValue);
          }}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={option => option.id}
          options={oficinas}
          loading={loadingAutoComplete}
          renderInput={params => (
            <TextField
              {...params}
              label="Ingrese el id de la oficina"
              variant="outlined"
              onChange={ev => {
                // dont fire API if the user delete or not entered anything
                if (ev.target.value !== "" || ev.target.value !== null) {
                  onChangeHandle(ev.target.value);
                }
              }}
              InputProps={{
                ...params.InputProps,
                style: {
                  borderRadius: "50px",
                  background: 'white'
                },
                endAdornment: (
                  <React.Fragment>
                    {loadingAutoComplete ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
          )}
        />
      </div>
      {
        oficinaSelected.id &&
        <Loading title="Cargando..." loading={loading}>
          <Paper className={classes.paper}>
            {
              <Horario
                doctor_id={1}
                key={oficinaSelected.id}
                oficina_id={oficinaSelected.id}
                tiempoConsulta={oficinaSelected.tiempoPorClienteMinutos}
                horarios={dataCRUD}
                getHorariosOficina={() => null}
                // refreshData={init}
                setLoading={(value) => setLoading(value)}
              />
            }

          </Paper>
        </Loading>
      }
    </>
  )
}

export default HorarioOficinaCrud;
