import Notificacion from "components/ui/Notificacion";

import { urlSecretaria } from "constants/urls";
import { createAction } from "redux-actions";

export const secretariaDoctorSelected = createAction("SECRETARIA_DOCTOR_SELECTED");

export const getSecretariasPorBusqueda = (cadenaBusqueda) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${urlSecretaria}search?search=${cadenaBusqueda}`, requestOptions);
    const datos = await response.json();

    return datos;
  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return {
      transaccion: false,
      mensaje: "No se pudo recuperar la información recargue la pagina ",
    };
  }
};