import React, { useMemo } from 'react';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import moment from "moment";
import TableCitas from './citas-table.component';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import Calendar from '../medico/calendario/Calendar.component';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Loading from 'components/Loading';
import MomentUtils from '@date-io/moment';
import { useCitasState } from 'hooks/medico/citas/useCitasState';
import { useSelector } from 'react-redux';
moment.normalizeUnits('GMT');

const Citas = ({ oficina }) => {

  const {
    calendarOnNavigate,
    calendarOnRangeChange,
    calendarOnSelectEvent,
    calendarOnSelectSlot,
    calendarOnViewChange,
    calendarView,
    citas,
    citasScreenState,
    handleDateChangeFinal,
    handleDateChangeInicial,
    isLoading,
    modeOptions,
    onCalendarOnDoubleClick,
    handleSelectMode,
  } = useCitasState({ entityId: oficina, entityName: 'oficina' });

  const { selectedMode, selectedDateInicial, selectedDateFinal, selectedCalendarDate } = citasScreenState;

  const { oficinaSelected } = useSelector(state => state.data_oficinas);
  console.log(oficinaSelected?.medico?.med_activo)

  const { oficinas = [] } = useSelector(state => state.dataUser.data);

  console.log(oficinas)

  const doctor = useMemo(() => oficinaSelected?.medico && oficinas
    ? (
      oficinas.filter(item => item.id === oficinaSelected.id)
        ? oficinas.filter(item => item.id === oficinaSelected.id)[0].medico
        : null
    )
    : null,
    [oficinaSelected, oficinas]
  );

  console.log(doctor)


  return (
    <Loading title="Cargando..." loading={isLoading}>
      <Card>
        <CardBody>
          {/* Selector de vista */}
          <div >
            <GridContainer >
              <GridItem xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel id="selectModeVistaLabel">Seleccione modo de visualización</InputLabel>
                  <Select
                    labelId="selectModeVistaLabel"
                    id="selectMode"
                    value={selectedMode}
                    onChange={handleSelectMode}

                  >
                    {
                      modeOptions.map(({ label, value }) => (
                        <MenuItem key={value} value={value}>{label}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
          </div>
          {/* Opciones de vista */}
          {
            selectedMode === 0
              ?
              <div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '150px', alignContent: 'center', marginBottom: '20px', marginRight: '15px' }}>
                    <span style={{ marginRight: '20px' }}>Fecha Inicial</span>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="YYYY-MM-DD"
                        value={selectedDateInicial}
                        onChange={handleDateChangeInicial}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '150px', alignContent: 'center', marginBottom: '20px' }}>
                    <span style={{ marginRight: '20px' }}>Fecha Final</span>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="YYYY-MM-DD"
                        value={selectedDateFinal}
                        onChange={handleDateChangeFinal}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                <TableCitas
                  tableHeaderColor="warning"
                  citas={citas}
                  titulos={doctor?.med_activo
                    ?
                    ['Doctor', 'Nombre', 'Identificación', 'Fecha', 'Hora', 'Estado', 'Disponibilidad', 'Motivo', 'Créditos', 'Pago pendiente', 'Servicio',]
                    :
                    ['Doctor', 'Nombre', 'Identificación', 'Fecha', 'Hora', 'Estado', 'Disponibilidad', 'Motivo', 'Pago pendiente', 'Servicio',]
                  }
                />
              </div>
              :
              //  Vista Calendario
              <div style={{ marginTop: '20px' }}>
                <Calendar
                  onDoubleClickEvent={onCalendarOnDoubleClick}
                  onSelectEvent={calendarOnSelectEvent}
                  onView={calendarOnViewChange}
                  onRangeChange={calendarOnRangeChange}
                  events={citas}
                  // date={selectedCalendarDateInicial}
                  onSelectSlot={calendarOnSelectSlot}
                  defaultDate={selectedCalendarDate}
                  startAccessor={'r_inicio'}
                  endAccessor={'r_fin'}
                  onNavigate={calendarOnNavigate}
                  view={calendarView}
                />

              </div>
          }
        </CardBody>
      </Card>
    </Loading>
  );
};

export default Citas;
