import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';

import ReservaStep from 'components/reservaciones/secretaria/reserva-step.component';

//REDUX
import SelectMedico from 'components/SeleccionarMedico/SelectMedico.component';
import { getHorariosReservaSecretaria } from 'redux/actions/aOficinas';
import { cleanCliente } from 'redux/actions/aClientes';

const useStyles = makeStyles(styles);

export const ReservarSecretaria = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [oficinasMedico, setOficinasMedico] = React.useState([]);
  const { doctorSelected } = useSelector(state => state.medicos_secretaria);

  useEffect(() => {
    if (doctorSelected) dispatch(getHorariosReservaSecretaria(doctorSelected.id));
  }, [doctorSelected, dispatch]);

  useEffect(() => {
    //Verificar si se establecio un cliente desde otra página
    const setClienteFlag = localStorage.getItem('setCliente')
    if (!setClienteFlag) dispatch(cleanCliente());
    else localStorage.removeItem('setCliente');
    return () => {
      dispatch(cleanCliente());
    }
  }, [dispatch]);

  return (
    <div>
      <SelectMedico setOficinasMedico={setOficinasMedico} />
      {doctorSelected && (
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitle}>Reserva una cita</h4>
          </CardHeader>
          <CardBody>
            <ReservaStep oficinas={oficinasMedico} doctor_id={doctorSelected.id} />
          </CardBody>
        </Card>
      )}
    </div>
  );
};


