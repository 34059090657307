/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import DashboardPage from "../Views/Dashboard/DashboardCliente";
import CerrarSesion from "../Views/CerrarSesion";
import RolesChange from "Views/Roles/RolesChange";
import AyudaSoporte from "Views/AyudaSoporte";
import { RolesIcon } from "components/CustomIcons/Sidebar/RolesIcons";
import { AyudaSoporteIcon } from "components/CustomIcons/Sidebar/AyudaSoporteIcon";



const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Cliente Información",
    icon: Person,
    component: DashboardPage,
    layout: "/cliente",
  },
  {
    path: "/roles",
    name: "Roles",
    icon: RolesIcon,
    component: RolesChange,
    layout: "/cliente",
    // rol: true,
  },
  {
    path: "/ayuda",
    name: "Ayuda y Soporte",
    icon: AyudaSoporteIcon,
    component: AyudaSoporte,
    layout: "/cliente",
  },
  {
    path: "/cerrar-sesion",
    name: "Cerrar Sesión",
    icon: ExitToAppIcon,
    component: CerrarSesion,
    layout: "/cliente",
  },
];

export default dashboardRoutes;
