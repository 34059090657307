import React from 'react';
import {Typography, Card } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
// import GridContainer from 'components/Grid/GridContainer';
// import GridItem from 'components/Grid/GridItem';

//Icons
// import SearchIcon from '@material-ui/icons/Search';
// import AddCircleIcon from '@material-ui/icons/AddCircle';
//Custom components

// import ClienteForm from './cliente-form.component';

//REDUX
import { useSelector } from 'react-redux';

// const useStyles = makeStyles(theme => ({
//   root: {
//     '& .MuiTextField-root': {
//       margin: theme.spacing(1),
//     },
//   },
// }));

const Resumen = ({ selectedHorario }) => {
  // const classes = useStyles();
  const clientes = useSelector(state => state.data_clientes.data);
  return (
    <Card style={{ width: '600px', padding: '20px' }}>
      <Typography
        style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}
        display="inline"
        variant="h4"
      >
        Resumen de la cita
      </Typography>
      <Typography style={{ display: 'flex', justifyContent: 'center', padding: '10px' }} variant="subtitle1">
        Paciente
      </Typography>
      <Typography style={{ display: 'flex', justifyContent: 'center' }} variant="body2">
        <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Cédula: </span>
        {clientes.cedula_identidad}
      </Typography>
      <Typography style={{ display: 'flex', justifyContent: 'center' }} variant="body2">
        <span style={{ fontWeight: 'bold', marginRight: '5px' }}> Nombre: </span>
        {clientes.nombres} {clientes.apellidos}
      </Typography>
      <Typography style={{ display: 'flex', justifyContent: 'center', padding: '10px' }} variant="subtitle1">
        Lugar
      </Typography>
      <Typography style={{ display: 'flex', justifyContent: 'center', padding: '10px' }} variant="body2">
        {selectedHorario.referencia_oficina}
      </Typography>
      <Typography style={{ display: 'flex', justifyContent: 'center', padding: '10px' }} variant="subtitle1">
        Fecha y Hora
      </Typography>
      <Typography style={{ display: 'flex', justifyContent: 'center', padding: '10px' }} variant="body2">
        {selectedHorario.fechaSeleccionada} a las {selectedHorario.hora}
      </Typography>
    </Card>
  );
};

export default Resumen;
