import React from "react";
import moment from "moment";
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from "react-redux";
import { List, ListItem, ListItemAvatar, ListItemText, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { formatCie10 } from "utils/format";
import { FormRecetarioImprimirFirma } from "components/Medico/Receta/FormRecetarioImprimirFirma";

const useStyles = makeStyles(styles);

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
    rootTable: {
      width: "100%",
    },
    container: {
      maxHeight: 440,
    },
  },
  head: {
    color: '#fff',
    fontWeight: "bolder",
  },
});

export const ClienteConsultaReceta = ({ data }) => {

  const classes = useRowStyles();
  const classesTable = useStyles();

  const { data: dataUser } = useSelector(
    (state) => state.dataUser
  );

  const { data: dataCliente } = useSelector(
    (state) => state.data_clientes
  );

  return (
    <div style={{ width: '100%', /*overflow: 'scroll'*/ }}>

      {/* Options */}

      <Table style={{ minWidth: '600px' }} aria-label="collapsible table" className={classesTable.table}>
        <TableBody>
          <TableRow className={classes.root}>
            <TableCell component="th" scope="row">
              {moment(data.created_date).format('YYYY-MM-DD HH:mm')}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {/* Cie10 */}
      <List>
        {
          data && data.detalleCie10.map(({ id, cie10 }) => (
            <ListItem key={id}>
              <ListItemAvatar>
                <div style={{ fontWeight: 'bold' }}>
                  {formatCie10(cie10, true)}
                </div>
              </ListItemAvatar>
              <ListItemText
                primary={formatCie10(cie10)}
                primaryTypographyProps={{
                  fontWeight: 'bold',
                  style: { fontWeight: 'bold' }
                }}
              // style={{fontWeight: 'bold'}}
              />
            </ListItem>
          ))
        }
      </List>

      {/* Detalle */}
      <Table size="small" aria-label="purchases">
        <TableHead>
          <TableRow>
            <TableCell>Titulo</TableCell>
            <TableCell>Cantidad</TableCell>
            <TableCell align="right">Medicamento</TableCell>
            <TableCell align="right">Dosificacion</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.detalle.map((medicina) => (
            <TableRow key={medicina.id}>
              <TableCell component="th" scope="row">
                {
                  `${(medicina.medicamento) ? medicina.medicamento : ''} ${(medicina.composicion) ? medicina.composicion : ''} ${(medicina.casa_comercial) ? medicina.casa_comercial : ''} ${(medicina.presentacion) ? medicina.presentacion : ''}`
                }
              </TableCell>
              <TableCell>{medicina.cantidad}</TableCell>
              <TableCell align="right">
                {medicina.medicamento}
              </TableCell>
              <TableCell align="right">
                {medicina.dosificacion}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      
      {/* Formulario email y firma */}
      <FormRecetarioImprimirFirma data={{ ...data, cie10: data.detalleCie10, cliente: { ...dataCliente }, doctor: { ...dataUser.medico }, usuario: { cedula_identidad: dataUser?.cedula_identidad } }} />

    </div>
  );
};

export default ClienteConsultaReceta;
