import { handleActions } from 'redux-actions';
import {
  clientes,
  isLoadingClientes,
  hasErrorClientes,
  allclients,
  clientesBusqueda,
  loadPacientesAtendidos,
  paginationPacientes,
  updatePacienteAtendido,
  deletePacienteAtendido
} from '../actions/aClientes';

export const data_clientes = handleActions(
  {
    [clientes]: (state, action) => ({
      ...state,
      data: action.payload,
      isLoadingClientes: false,
      hasErrorClientes: false,
    }),
    [allclients]: (state, action) => ({
      ...state,
      clients_list: action.payload,
      isLoadingClientes: false,
      hasErrorClientes: false,
    }),
    [isLoadingClientes]: state => ({
      ...state,
      isLoadingClientes: true,
      hasErrorClientes: false,
    }),
    [hasErrorClientes]: (state, action) => ({
      ...state,
      isLoadingClientes: false,
      hasErrorClientes: action.payload,
    }),
    [clientesBusqueda]: (state, action) => ({
      ...state,
      clients_search: action.payload,
      isLoadingClientes: false,
      hasErrorClientes: action.payload,
    }),
    [loadPacientesAtendidos]: (state, action) => ({
      ...state,
      pacientes: {
        ...state.pacientes,
        isLoading: false,
        data: action.payload,
        hasErrorPacientes: false
      }
    }),
    [paginationPacientes]: (state, action) => ({
      ...state,
      pacientes: {
        ...state.pacientes,
        dataPagination: action.payload,
        isLoading: false,
      }
    }),
    [updatePacienteAtendido]: (state, action) => ({
      ...state,
      pacientes: {
        ...state.pacientes,
        isLoading: false,
        data: state.pacientes.data.map((item) =>
          item.id === action.payload.id ? {...action.payload, mp_doctor_id: item.mp_doctor_id, mp_cliente_id: item.mp_cliente_id, } : item
        ),
        hasErrorPacientes: false
      }
    }),
    [deletePacienteAtendido]: (state, action) => ({
      ...state,
      pacientes: {
        ...state.pacientes,
        isLoading: false,
        data: state.pacientes.data.filter((item) => item.id !== action.payload),
        hasErrorPacientes: false
      }
    }),
  },
  { isLoadingClientes: false, hasErrorClientes: false, data: '', clients_list: [], clients_search: [], pacientes: {data: [], dataPagination: {}, isLoading: false, hasErrorPacientes: false} }
);
