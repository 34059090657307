import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";
import IconButton from '@material-ui/core/IconButton';
import { SideMenuIcon } from 'components/CustomIcons/Sidebar/SideMenuIcon';
// core components

import styles from "../../assets/jss/material-dashboard-react/components/topbarStyle";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles(styles);

const CustomIcon = React.forwardRef(function MyComponent(props, ref) {
  //  Spread the props to the underlying DOM element.
  return <Icon {...props} ref={ref}>{props.children}</Icon>
});


export default function Topbar(props) {
  const classes = useStyles();
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }

  // color: color de fondo de menu item
  const { color = 'white', logo, image, logoText, routes, setShowTopMenu, showTopMenu } = props;

  let logoutOption = null;

  const blackFontClasses = classNames({
    [" " + classes.blackFont]: activeRoute(logoutOption ? (logoutOption.layout + logoutOption.path) : ''),
  });
  

  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        var activePro = " ";
        var listItemClasses;
        if (prop.path === "/cerrar-sesion") {
          logoutOption = prop;
          activePro = classes.activePro + " ";
          listItemClasses = classNames({
            [" " + classes[color]]: true,
          });
        } else {
          listItemClasses = classNames({
            [" " + classes[color]]: activeRoute(prop.layout + prop.path),
          });
        }
        const blackFontClasses = classNames({
          [" " + classes.blackFont]: activeRoute(prop.layout + prop.path),
        });

        if (prop.hidden) return null;
        if (prop.path !== "/cerrar-sesion")
          return (

            <NavLink
              to={prop.layout + prop.path}
              className={activePro + classes.item}
              activeClassName="active"
              key={key}
            >
              <ListItem button className={classes.itemLink + listItemClasses}>
                {typeof prop.icon === "string" ? (
                  <Tooltip title={props.rtlActive ? prop.rtlName : prop.name}>
                    <Icon
                      className={classNames(classes.itemIcon, blackFontClasses, {
                        [classes.itemIconRTL]: props.rtlActive,
                      })}
                    >
                      {prop.icon}
                    </Icon>
                  </Tooltip>

                ) : (
                  <Tooltip title={props.rtlActive ? prop.rtlName : prop.name}>
                    <CustomIcon>
                      <prop.icon
                        className={classNames(classes.itemIcon, blackFontClasses, {
                          [classes.itemIconRTL]: props.rtlActive,
                        })}
                      />
                    </CustomIcon>

                  </Tooltip>
                )}

              </ListItem>
            </NavLink>

          );
        return null;
      })}
      <NavLink
        to={logoutOption.layout + logoutOption.path}
        className={classes.activePro + classes.item}
        activeClassName="active"
      >
        <ListItem button className={classes.itemLink + classNames({
          [" " + classes['whiteNoSelected']]: true,
        })}>
          {typeof logoutOption.icon === "string" ? (
            <Tooltip title={props.rtlActive ? logoutOption.rtlName : logoutOption.name}>
              <Icon
                className={classNames(classes.itemIcon, blackFontClasses, {
                  [classes.itemIconRTL]: props.rtlActive,
                })}
              >
                {logoutOption.icon}
              </Icon>
            </Tooltip>

          ) : (
            <Tooltip title={props.rtlActive ? logoutOption.rtlName : logoutOption.name}>
              <logoutOption.icon
                className={classNames(classes.itemIcon, blackFontClasses, {
                  [classes.itemIconRTL]: props.rtlActive,
                })}
              />
            </Tooltip>

          )}

        </ListItem>
      </NavLink>
    </List>
  );


  // let fixedBottomArea = (
  //   <div className={classes.fixedBottomAreaWrapper}>
  //     {
  //       logoutOption &&
  //       <NavLink
  //         to={logoutOption.layout + logoutOption.path}
  //         className={classes.activePro + classes.item}
  //         activeClassName="active"
  //       >
  //         <ListItem button className={classes.itemLink + classNames({
  //           [" " + classes['whiteNoSelected']]: true,
  //         })}>
  //           {typeof logoutOption.icon === "string" ? (
  //             <Tooltip title={props.rtlActive ? logoutOption.rtlName : logoutOption.name}>
  //               <Icon
  //                 className={classNames(classes.itemIcon, blackFontClasses, {
  //                   [classes.itemIconRTL]: props.rtlActive,
  //                 })}
  //               >
  //                 {logoutOption.icon}
  //               </Icon>
  //             </Tooltip>

  //           ) : (
  //             <Tooltip title={props.rtlActive ? logoutOption.rtlName : logoutOption.name}>
  //               <logoutOption.icon
  //                 className={classNames(classes.itemIcon, blackFontClasses, {
  //                   [classes.itemIconRTL]: props.rtlActive,
  //                 })}
  //               />
  //             </Tooltip>

  //           )}

  //         </ListItem>
  //       </NavLink>
  //     }
  //   </div>
  // )

  let hideButtonArea = (
    <div className={classes.closeButton}>

      <IconButton aria-label="delete" size="small" onClick={() => setShowTopMenu(false)}
        style={{ color: 'white', padding: '10px', fontWeight: 'bold', fontSize: '25px' }}>
        <SideMenuIcon fontSize="inherit" />
      </IconButton>

    </div>
  )
  var brand = (
    <div className={classes.logo}>
      <div className={classes.logoImage}>
        <a href="/">
          <img src={logo} alt="logo" className={classes.img} />
        </a>
      </div>
      {logoText}

    </div>
  );
  return (
    <>
      {
        showTopMenu &&
        <Hidden smDown implementation="css">
          <div style={{
            display: 'flex',
            justifyContent: 'center'
          }}>

            <div className={classes.drawerPaper}>
              <div className={classes.sidebarWrapper}>{links}</div>
              <div style={{
                width: "auto",
                display: "flex",
                minWidth: '250px'
              }}>
                {/* {fixedBottomArea} */}
                {brand}
                {hideButtonArea}
              </div>


              {image !== undefined ? (
                <div
                  // className={classes.background}
                  style={{ backgroundImage: "url(" + image + ")" }}
                />
              ) : null}
            </div>

          </div>
        </Hidden>
      }

      {/* Responsive */}
      <Hidden mdUp implementation="css">
        <div style={{
          display: 'flex',
          justifyContent: 'center',

        }}>
          <div className={classes.drawerPaper}>
            <div className={classes.sidebarWrapper}>{links}</div>
            <div style={{
              width: "auto",
              display: "flex",
              minWidth: '200px'
            }}>
              {/* {fixedBottomArea} */}
              {brand}
            </div>
          </div>

        </div>
      </Hidden>
    </>

  );
}

Topbar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};
