import Notificacion from "components/ui/Notificacion";
import { createAction } from "redux-actions";
import { encriptarRsa } from "utils/encriptar";
import { urlGetCie10, urlRecetas, urlRecetasSendMail, urlRecetasSignDownload, urlRecetasSignSendMail } from "../../constants/urls";
import { jwt } from "./aLogin";

//Oficinas
export const recetas = createAction("RECETAS");
export const isLoadingRecetas = createAction("IS_LOADING_RECETAS");
export const hasErrorRecetas = createAction("HAS_ERROR_RECETAS");
export const setRecetaSeleccionada = createAction("SET RECETA SELECCIONADA");
export const resetRecetaSeleccionada = createAction(
  "SET RESET RECETA SELECCIONADA"
);
export const ci10Busqueda = createAction("CIE10_SEARCH");

export const getRecetaClienteDoctor = (params) => async (
  dispatch,
  getstate
) => {
  dispatch(isLoadingRecetas());

  try {
    const state = getstate();
    const { token, dataUser } = state;
    const { doctor_id } = dataUser.data;
    var requestOptions = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${urlRecetas}${params}/${doctor_id}`, requestOptions)
    const datos = await response.json();

    if (datos.transaccion) {
      dispatch(recetas(datos.data));
    } else {
      dispatch(hasErrorRecetas(datos.mensaje));
    }
  } catch (error) {
    dispatch(
      hasErrorRecetas("No se pudo recuperar la información recargue la pagina ")
    );
  }
};

export const cleanRecetas = () => (dispatch) => {
  dispatch(recetas([]));
};

export const agregarReceta = (data, cliente_id) => async (
  dispatch,
  getstate
) => {
  dispatch(isLoadingRecetas());
  try {
    const state = getstate();
    const { token, dataUser } = state;
    const { doctor_id } = dataUser.data;

    const raw = JSON.stringify(data);
    var requestOptions = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: raw,
    };

    const response = await fetch(`${urlRecetas}crearReceta/${cliente_id}/${doctor_id}`, requestOptions)
    const { token: tokenRes = true, transaccion, data: dataRes, mensaje } = await response.json();
    if (!tokenRes) {
      dispatch(jwt(""));
      return;
    }
    if (transaccion) {
      Notificacion({
        type: "success",
        text: "Receta guardada correctamente.",
      });
      // dispatch(recetas(dataRes));
      return { transaccion: true, mensaje, data: dataRes };
    } else {
      dispatch(hasErrorRecetas(mensaje));
      return { transaccion: false, mensaje };
    }
  } catch (error) {
    dispatch(
      hasErrorRecetas("No se pudo guardar la información recargue la pagina ")
    );
    return { transaccion: false, mensaje: error };
  }
};

export const enviarRecetaMailCliente = (recetaId, download = false, showHeader = false) => async (
  dispatch,
  getstate
) => {
  try {
    const state = getstate();
    const { token } = state;

    const raw = JSON.stringify({ id: recetaId });
    var requestOptions = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: raw,
    };

    let query = download ? '&download=true' : '';
    query += showHeader ? '&showHeader=true' : '';
    const response = await fetch(`${urlRecetasSendMail}${query}`, requestOptions)


    if (download) {
      const responseStatus = response.status;
      if (responseStatus === 200) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `receta.pdf`,);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        Notificacion({
          type: "success",
          text: 'Receta enviado correctamente al email del paciente',
        });
        return { transaccion: true }
      } else {
        const datos = await response.json();
        const { mensaje } = datos;
        Notificacion({
          type: "error",
          text: mensaje,
        });
        return { transaccion: false }
      }
    }

    const datos = await response.json();
    const { token: tokenRes = true } = datos;
    if (!tokenRes) {
      dispatch(jwt(""));
      return;
    }
    if (datos.transaccion) {
      Notificacion({
        type: "success",
        text: datos.mensaje,
      });
      return { transaccion: true, mensaje: datos.mensaje }
    } else {
      Notificacion({
        type: "error",
        text: datos.mensaje,
      });
      return { transaccion: false, mensaje: datos.mensaje }
    }

  } catch (error) {
    return { transaccion: false, mensaje: 'No se pudo enviar email recargue la pagina ' }
  }
};

export const firmarEnviaRecetaMailCliente = (recetaId, data, options = { download: false, showHeader: false }) => async (
  dispatch,
  getstate
) => {
  try {
    const state = getstate();
    const { token } = state;
    const { download, showHeader } = options;

    var formdata = new FormData();
    formdata.append("file", data.file);
    formdata.append("password", await encriptarRsa(JSON.stringify({ value: data.password })));

    var requestOptions = {
      method: "post",
      headers: {
        authorization: `Bearer ${token}`,
      },
      body: formdata,
    };
    const queryDownload = download ? '?download=true' : '';
    const querySeparator = queryDownload ? '&' : '?'
    const queryShowHeader = showHeader ? 'showHeader=true' : '';
    const response = await fetch(`${urlRecetasSignSendMail}/${recetaId}${queryDownload}${querySeparator}${queryShowHeader}`, requestOptions)

    if (download) {
      const responseStatus = response.status;
      if (responseStatus === 200) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `receta.pdf`,);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        Notificacion({
          type: "success",
          text: 'Receta enviado correctamente al email del paciente',
        });
        return { transaccion: true }
      } else {
        const datos = await response.json();
        const { mensaje } = datos;
        Notificacion({
          type: "error",
          text: mensaje,
        });
        return { transaccion: false }
      }
    }

    const datos = await response.json();
    const { token: tokenRes = true } = datos;
    if (!tokenRes) {
      dispatch(jwt(""));
      return;
    }
    if (datos.transaccion) {
      Notificacion({
        type: "success",
        text: datos.mensaje,
      });
      return { transaccion: true, mensaje: datos.mensaje }
    } else {
      Notificacion({
        type: "error",
        text: datos.mensaje,
      });
      return { transaccion: false, mensaje: datos.mensaje }
    }

  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo enviar la información recargue la pagina ',
    });
    throw (error);
  }
};

export const descargarRecetaFirmada = (recetaId, data, options) => async (
  dispatch,
  getstate
) => {
  try {
    const state = getstate();
    const { token } = state;

    const { showHeader } = options;

    let query = showHeader ? '?showHeader=true' : '';

    var formdata = new FormData();
    formdata.append("file", data.file);
    formdata.append("password", await encriptarRsa(JSON.stringify({ value: data.password })));

    var requestOptions = {
      method: "post",
      headers: {
        authorization: `Bearer ${token}`,
      },
      body: formdata,
    };

    const response = await fetch(`${urlRecetasSignDownload}/${recetaId}${query}`, requestOptions)
    const responseStatus = response.status;

    if (responseStatus === 200) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `receta.pdf`,);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      return { transaccion: true }
    } else {
      const datos = await response.json();
      const { mensaje } = datos;
      Notificacion({
        type: "error",
        text: mensaje,
      });
      return { transaccion: false }
    }

  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo enviar la información recargue la pagina ',
    });
    throw (error);
  }
};




export const getCie10PorBusqueda = (cadenaBusqueda) => async (
  dispatch,
  getstate
) => {
  dispatch(isLoadingRecetas());
  try {
    const { token } = getstate();

    var requestOptions = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    return fetch(`${urlGetCie10}${cadenaBusqueda}`, requestOptions)
      .then((response) => response.json())
      .then((datos) => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(""));
          return;
        }
        if (datos.transaccion) {
          dispatch(ci10Busqueda(datos.data));
        } else {
          dispatch(ci10Busqueda([]));
          dispatch(hasErrorRecetas(datos.mensaje));
        }
      });
  } catch (error) {
    dispatch(ci10Busqueda([]));
    dispatch(
      hasErrorRecetas("No se pudo recuperar la información recargue la pagina ")
    );
  }
};
