import React, { useState } from 'react'
import { Backdrop, Fade, FormControl, Modal, Paper, TextField, makeStyles } from '@material-ui/core'
import Button from "components/CustomButtons/Button.js";
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import { useDispatch } from 'react-redux';
import { requestDeleteAccountDeletion } from 'redux/actions/aLogin';
import Loading from 'components/Loading';
import { confirmDeleteAccount } from 'redux/actions/aLogin';
import { useHistory } from "react-router-dom";
import { cerrarsesion } from 'redux/actions/aLogin';
import Notificacion from 'components/ui/Notificacion';

const useStyles = makeStyles((theme) => (
  {
    ...styles,
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
      },
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    formControl: {
      margin: theme.spacing(1),
      width: '96%',
    },
    formContainer: {
      padding: '24px'
    },
    modalForm: {
      padding: '24px'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      width: "90%",
      maxWidth: 500,
    },
  }
));

export const ModalRequestAccountDeletion = ({ open, onCloseModal, confirmDialog = null }) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [showDeleteCodeForm, setShowDeleteCodeForm] = useState(false);

  // Inputs state
  const [password, setPassword] = useState('');
  const [confirmCode, setConfirmCode] = useState('');

  const handleConfirmRequest = async () => {
    try {
      if (!password) {
        Notificacion({ type: "error", text: 'Debe ingresar su contraseña', });
        return
      }
      setLoading(true);
      const { transaccion } = await dispatch(requestDeleteAccountDeletion(password));
      setLoading(false);
      if (transaccion) setShowDeleteCodeForm(true);

      // Close modal
    } catch (error) {
    }

  }

  const handleConfirmAccountDeletion = async () => {
    try {
      if (!confirmCode) {
        Notificacion({ type: "error", text: 'Debe ingresar el código de confirmación enviado a su correo', });
        return
      }
      setLoading(true);
      const { transaccion } = await dispatch(confirmDeleteAccount(confirmCode))
      setLoading(false);
      if (transaccion) {
        // TODO: LOGOUT
        console.log('logout and delete account')
        cerrarSesion();
        // onCloseModal();
      }
    } catch (error) {

    }
  }

  const cerrarSesion = () => {
    localStorage.removeItem("notifications");
    dispatch(cerrarsesion())
    history.replace({ pathname: `/` });
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => onCloseModal()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Paper className={classes.paper} square>
          {/* <ConfirmDialog title={"Confirmación de eliminación"} open={openConfirmDialog}
            setOpen={setOpenConfirmDialog} onConfirm={() => newRoleSelected === 'ROLE_SEC' ? handleRequestNewRole() : handleCreateNewRole()}  >
            <div>¿Esta seguro de crear el nuevo rol con los datos ingresados?</div>
          </ConfirmDialog> */}

          <div style={{ textAlign: 'center' }}>
            <h4 style={{ marginBottom: '10px' }}>Solicitud de eliminación de cuenta</h4>
          </div>
          <div className={classes.formContainer}>
            <Loading title="Cargando..." loading={loading}>
              <FormControl className={classes.formControl}>
                {
                  !showDeleteCodeForm
                    ?
                    <>
                      <TextField
                        id="password"
                        name="password"
                        label="Ingresa su contraseña"
                        variant="outlined"
                        type='password'
                        style={{
                          width: '100%',
                        }}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <Button fullWidth size="lg" variant="outlined" color="primary" onClick={handleConfirmRequest}>
                        Solicitar eliminación de cuenta
                      </Button>
                    </>
                    :
                    <>
                      <TextField
                        id="confirmCode"
                        name="confirmCode"
                        label="Ingrese el código de confirmación"
                        variant="outlined"
                        style={{
                          width: '100%',
                        }}
                        value={confirmCode}
                        onChange={(e) => setConfirmCode(e.target.value)}
                      />
                      <Button fullWidth size="lg" variant="outlined" color="primary" onClick={handleConfirmAccountDeletion}>
                        Confirmar eliminación de cuenta
                      </Button>
                    </>
                }
              </FormControl>
            </Loading>
          </div>
        </Paper>
      </Fade>
    </Modal>
  )
}
