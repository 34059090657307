


import React, { useMemo } from 'react';
import CRUD from 'components/CRUD/Crud.component';
import { urlListarVademecum, urlCrearVademecum, urlEditarVademecum, urlEliminarVademecum } from 'constants/urls';
import iconDefaultSVG from "../../../src/assets/images/icons/medico.svg";

const VademecumCrud = () => {

  const fileField = React.useRef(null);

  const tableData = useMemo(() => [
    {
      type: "imageInput",
      name: "fotoNombre",
      label: "Imagen",
      imgDefault: iconDefaultSVG,
      ref: fileField
    },
    {
      type: "inputDisabled",
      label: "Id",
      name: 'id',
      showOnCreate: false,
      showOnTable: true,
      tableProps: {
        title: 'Id',
        sort: {
          sortColumnName: 'id',
          sortColumnNameDB: 'id',
        }
      },
    },
    {
      type: "input",
      name: "nombre",
      label: "Nombre",
      tableProps: {
        title: 'Nombre',
        sort: {
          sortColumnName: 'nombre',
          sortColumnNameDB: 'nombre',
        }
      },
    },
    {
      type: "input",
      name: "composicion",
      label: "Composición",
      tableProps: {
        sort: {
          sortColumnName: 'composicion',
          sortColumnNameDB: 'composicion',
        }
      },
    },
    {
      type: "input",
      name: "funcion",
      label: "Función",
      tableProps: {
        sort: {
          sortColumnName: 'funcion',
          sortColumnNameDB: 'funcion',
        }
      },
    },
    {
      type: "input",
      name: "presentacion",
      label: "Presentación",
      tableProps: {
        sort: {
          sortColumnName: 'presentacion',
          sortColumnNameDB: 'presentacion',
        }
      },
    },
    {
      type: "input",
      name: "dosificacion",
      label: "Dosificación",
      tableProps: {
        sort: {
          sortColumnName: 'dosificacion',
          sortColumnNameDB: 'dosificacion',
        }
      },
    },
    {
      type: "input",
      name: "casa_comercial",
      label: "Casa comercial",
      tableProps: {
        sort: {
          sortColumnName: 'casa_comercial',
          sortColumnNameDB: 'casa_comercial',
        }
      },
    },
    {
      type: "input",
      name: "contraindicaciones",
      label: "Contraindicaciones",
      tableProps: {
        sort: {
          sortColumnName: 'contraindicaciones',
          sortColumnNameDB: 'contraindicaciones',
        }
      },
    },

  ], []);


  const data = useMemo(() => ({
    tableData,
    urls: {
      createUrl: urlCrearVademecum,
      updateUrl: urlEditarVademecum,
      readUrl: urlListarVademecum,
      deleteUrl: urlEliminarVademecum,
    }
  }), [tableData])

  return (
    <CRUD data={data} />
  )
}

export default VademecumCrud;
