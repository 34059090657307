import React, { useCallback, useEffect, useState, useMemo, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import Button from "../../CustomButtons/Button";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Notificacion from "components/ui/Notificacion";
import { FormControl, FormLabel, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { getExamenPorBusqueda, editarPacienteExamen, editarPacienteGrupoExamen, editarExamenListaPredeterminada, crearExamenListaPredeterminada, eliminarExamenListaPredeterminada } from "redux/actions/aMedico";
import { AutocompleteComponent } from "components/ui/AutocompleteComponent";
import { createFilterOptions } from "@material-ui/lab";
import Loading from "components/Loading";
import CustomInput from "components/CustomInput/CustomInput";
import EditIcon from '@material-ui/icons/Edit';
import ConfirmDialog from "components/Admin/CRUD/ConfirmDialog";
import { TableRowExamen } from "../examen/TableRowExamen";
import { TableRowGrupoExamen } from "../examen/TableRowGrupoExamen";
import { TableRowGrupoExamenDoctor } from "../examen/TableRowGrupoExamenDoctor";
import { useReactToPrint } from "react-to-print";
import { ExamenPrint } from "../examen/ExamenPrint";
import { FormErrorLabel } from "components/forms/labels/FormErrorLabel";
import PrintIcon from '@material-ui/icons/Print'
import EmailIcon from '@material-ui/icons/Email';
import { enviarPedidoExamenesEmail } from "redux/actions/aMedico";
import { formatCie10Code } from "utils/format";


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  formControl: {
    marginTop: "25px",
  },
  container: {
    width: '100%',
  }
}));

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});
const validacionesForm = valores => {
  let errors = {};

  if (!valores.nombre) {
    errors.nombre = 'nombre es obligatorio';
  }

  return errors;
};

const validacionesFormLista = valores => {
  let errors = {};

  if (!valores.nombre) {
    errors.nombre = 'nombre es obligatorio';
  }

  return errors;
};

const filter = createFilterOptions();

export const PacienteExamenesForm = ({
  onSubmit,
  data = [],
  dataGrupo = [],
  setData,
  setDataGrupo,
  examenesUpdated,
  examenesGrupoUpdated,
  dataExamenesGrupoMedico = [],
  cie10SelectedList = [],
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const classesRow = useRowStyles();

  const { data: dataCliente } = useSelector(
    (state) => state.data_clientes
  );

  const { data: dataUser } = useSelector(
    (state) => state.dataUser
  );

  const verifyExamenUpdate = useCallback(
    (examen) => {
      const itemFind = examenesUpdated.filter(item => item.id === examen.id)
      if (itemFind && itemFind.length === 1) return itemFind[0];
      return null;
    },
    [examenesUpdated],
  );

  const verifyGrupoExamenUpdate = useCallback(
    (examenGrupo) => {
      const itemFind = examenesGrupoUpdated.filter(item => item.id === examenGrupo.id)
      if (itemFind && itemFind.length === 1) return itemFind[0];
      return null;
    },
    [examenesGrupoUpdated],
  );

  //Verificar examenes actualizados
  const examenesData = useMemo(() => examenesUpdated.length > 0 ? data.map(item => verifyExamenUpdate(item) ?? item) : data, [data, verifyExamenUpdate, examenesUpdated]);

  //Verificar actualizaciones de registros
  const examenesGrupoData = useMemo(() => examenesGrupoUpdated.length > 0 ? dataGrupo.map(item => verifyGrupoExamenUpdate(item) ?? item) : dataGrupo, [dataGrupo, verifyGrupoExamenUpdate, examenesGrupoUpdated]);;

  const auxId = useRef(0);
  const auxGrupoId = useRef(1);

  const componentRef = React.useRef();

  const valoresInit = useRef({
    id: '',
    nombre: '',
    archivo_nombre: '',
    detalles: ''
  });

  const valoresListaInit = useRef({
    id: '',
    nombre: '',
    archivo_nombre: '',
  });

  const valoresListaPredeterminadaInit = useRef({
    id: '',
    nombre: '',
    examenes: [],
  });

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [valores, setValores] = useState(valoresInit.current);
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [formEdit, setFormEdit] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [itemDeleteId, setItemDeleteId] = useState(null);

  const [valoresLista, setValoresLista] = useState(valoresListaInit.current);
  const archivoListaRef = useRef(null);
  const [showFormLista, setShowFormLista] = useState(false);
  const [formEditArchivoLista, setFormEditArchivoLista] = useState(false);
  const [listaSelected, setListaSelected] = useState(null);
  const [valoresListaPredeterminada, setValoresListaPredeterminada] = useState(valoresListaPredeterminadaInit.current);
  const [errorsListaPredeterminada, setErrorsListaPredeterminada] = useState({});
  const [isSubmitListaPredeterminada, setIsSubmitListaPredeterminada] = useState(false);

  const [showFormGrupoExamenMedico, setShowFormGrupoExamenMedico] = useState(false);
  const [showFormGrupoExamenMedicoExamen, setShowFormGrupoExamenMedicoExamen] = useState(false);
  const [examenValoresAux, setExamenValoresAux] = useState(valoresInit.current);
  const [errorsExamenValoresAux, setErrorsExamenValoresAux] = useState({});
  const [isSubmitGrupoExamenMedico, setIsSubmitGrupoExamenMedico] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('Guardando...')

  const [examenData, setExamenData] = useState([]);

  const handleChange = (e, form = 1) => {

    let { name, value } = e.target;
    if (form === 1)
      setValores({ ...valores, [name]: value });
    else if (form === 2)
      setValoresLista({ ...valoresLista, [name]: value });
    else
      setValoresListaPredeterminada({ ...valoresListaPredeterminada, [name]: value });
  };

  function handleFileSelected(e, form = 1) {

    const file = e.target.files[0];
    // divido para dos para 5MB max
    if (file && file.size / 1024 / 1024 < 5) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        if (form === 1)
          setValores({ ...valores, archivo_nombre_base64: reader.result });
        else
          setValoresLista({ ...valoresLista, archivo_nombre_base64: reader.result, file });
      };
    } else {
      Notificacion({
        type: "error",
        text: "El archivo no debe ser de mas de 5mb.",
      });
    }
  }

  const handleDownloadBase64 = (fileBase64) => {
    let a = document.createElement('a');
    a.href = fileBase64;
    a.download = '';
    a.click();
  }

  const resetForm = () => {
    setIsSubmit(false);
    setValores(valoresInit.current);
    setShowForm(false);
    setFormEdit(false);
  }

  const handleCancel = () => {
    if (showForm) {
      setShowForm(false);
      resetForm();
    } else {
      setShowFormLista(false);
      setFormEditArchivoLista(false);
    }

  }

  const handleSubmit = async () => {
    try {
      if (!showForm) {
        setShowForm(true);
        setValores(valoresInit.current);
        return;
      }

      setIsSubmit(true);
      if (Object.keys(errors).length > 0) {
        Notificacion({
          type: "error",
          text: "Debe completar los campos correctamente.",
        });
        return;
      }

      if (formEdit) {
        setLoading(true);
        const { data: dataRes } = await dispatch(editarPacienteExamen(valores.id, valores));

        if (dataRes) {
          const newData = data.filter(item => item.id === dataRes.id ? dataRes : item);
          setData(newData, dataRes)
        }

        setLoading(false);
      } else {
        const examen = { ...valores, id: auxId.current, new: true };
        auxId.current = auxId.current + 1
        const newData = [
          ...data,
          examen
        ]
        setData(newData, null)
      }
      resetForm();
    } catch (error) {
      setLoading(false);
    }
  }

  const handleEdit = (data) => {
    setValores(data);
    setShowForm(true);
    setFormEdit(true);
    //Lista
    setShowFormLista(false);
    setFormEditArchivoLista(false);
  }

  const handleDelete = (id) => {
    const newData = data.filter(item => item.id !== id);
    setData(newData)
  }

  const handleDeleteGrupoExamen = (id) => {
    const newData = dataGrupo.filter(item => item.id !== id);
    setDataGrupo(newData)
  }

  const handleBuscarExamenes = useCallback(async (value) => {

    try {
      const { data } = await dispatch(getExamenPorBusqueda(value));
      setExamenData(data);

    } catch (error) {
    }


  }, [dispatch]);

  const handleSelectExamen = useCallback((_, values, __) => {
    if (values !== null) {
      if (values.inputValue) setValores({ ...valores, 'nombre': values.inputValue });
      else setValores({ ...valores, 'nombre': values.nombre });
    }
  }, [valores]);

  //Lista personalizadas

  const handleCreateNewLista = () => {
    const newGrupo = { id: auxGrupoId.current, new: true, examenes: [], nombre: `Lista personalizada ${auxGrupoId.current}` }
    auxGrupoId.current = auxGrupoId.current + 1
    const newData = [
      ...dataGrupo,
      newGrupo
    ]
    setDataGrupo(newData, null)
  }

  const handleEditGrupoExamen = (data, editFile = false) => {
    //Examen
    setShowForm(false);
    setFormEdit(false);
    //Lista
    setValoresLista(data);
    setShowFormLista(true);
    setListaSelected(data);
    if (!data.new && editFile) setFormEditArchivoLista(true);
    else setFormEditArchivoLista(false);
  }

  const handleUpdateItemGroup = (groupId, examen) => {
    const dataGrupoUpdated = dataGrupo.map(
      item => {
        if (!groupId)
          return { ...item, examenes: item.examenes.filter(examenItem => examenItem.id !== examen.id) };

        const examenFind = item.examenes.filter(examenItem => examenItem.id === examen.id);
        if (examenFind.length > 0 && item.id === groupId) return item;
        else if (examenFind.length > 0 && item.id !== groupId)
          return { ...item, new: true, examenes: item.examenes.filter(examenItem => examenItem.id !== examen.id) };
        else if (examenFind.length === 0 && item.id !== groupId)
          return item;
        return { ...item, new: true, examenes: [...item.examenes, examen] };
      }
    );
    setDataGrupo(dataGrupoUpdated, null)
  }

  useEffect(() => {
    //Reset input file state lista
    if (formEditArchivoLista)
      archivoListaRef.current.value = null;
  }, [listaSelected, formEditArchivoLista])


  const handleSubmitlista = async () => {
    if (formEditArchivoLista) {
      setLoading(true);
      const { data: dataRes } = await dispatch(editarPacienteGrupoExamen(valoresLista.id, valoresLista));

      if (dataRes) {
        const newData = dataGrupo.filter(item => item.id === dataRes.id ? dataRes : item);
        setDataGrupo(newData, dataRes);
      }

      setLoading(false);
      setShowFormLista(false);
    } else {
      const newData = dataGrupo.map(grupo => grupo.id === valoresLista.id ? valoresLista : grupo);
      setDataGrupo(newData, null);
      setShowFormLista(false);
    }

  }

  //Medico grupo examenes

  const handleEditListaPredeterminada = (item) => {
    setShowFormGrupoExamenMedico(true);
    setValoresListaPredeterminada(item);
  }

  const handleDeleteListaPredeterminada = async () => {
    try {

      setLoading(true);
      await dispatch(eliminarExamenListaPredeterminada(itemDeleteId));
      setLoading(false);
      setShowFormGrupoExamenMedico(false);
      setShowFormGrupoExamenMedicoExamen(false);
      setValoresListaPredeterminada(valoresListaPredeterminadaInit.current);
    } catch (error) {
      setLoading(false);
    }
  }

  const handleUseMedicoGrupoExamen = (item) => {

    const { examenes = [], nombre } = item

    //Copiar examenes a examenes de la consulta actual
    let examenesArray = [];
    let newData = data;
    for (let i = 0; i < examenes.length; i++) {
      const examenItem = examenes[i];
      const examen = { ...examenItem, id: auxId.current, new: true };
      examenesArray.push(examen);
      auxId.current = auxId.current + 1;
      newData = [
        ...newData,
        examen
      ];
    }
    setData(newData, null)

    //Copiar lista a listas de la consulta actual
    const newGrupo = { id: auxGrupoId.current, new: true, examenes: examenesArray, nombre }
    auxGrupoId.current = auxGrupoId.current + 1
    newData = [
      ...dataGrupo,
      newGrupo
    ]
    setDataGrupo(newData, null)
  }

  const handleNuevaListaPredeterminada = () => {
    if (!showFormGrupoExamenMedico) setShowFormGrupoExamenMedico(true);
    else setShowFormGrupoExamenMedico(false);
  }

  const handleAutoCompleteSelectExamenGrupo = useCallback((_, values, __) => {
    if (values !== null) {
      if (values.inputValue) setExamenValoresAux({ ...examenValoresAux, 'nombre': values.inputValue });
      else setExamenValoresAux({ ...examenValoresAux, 'nombre': values.nombre });
    }
  }, [examenValoresAux]);

  const handleChangeFormExamenListaPredeterminada = (e) => {
    let { name, value } = e.target;
    setExamenValoresAux({ ...examenValoresAux, [name]: value });
  }

  const handleEditFormExamenListaPredeterminada = (data) => {
    setExamenValoresAux(data);
    setShowFormGrupoExamenMedicoExamen(true);
  }

  const handleDeleteFormExamenListaPredeterminada = (examenId) => {
    const examenesUpdated = valoresListaPredeterminada.examenes.filter(item => item.id !== examenId);
    setValoresListaPredeterminada({ ...valoresListaPredeterminada, 'examenes': examenesUpdated });
  }


  const handleSubmitExamenListaPredeterminada = () => {
    setIsSubmitGrupoExamenMedico(true);
    if (Object.keys(errorsExamenValoresAux).length > 0) {
      Notificacion({
        type: "error",
        text: "Debe completar los campos correctamente.",
      });
      return;
    }
    if (examenValoresAux.id) {
      const examenesUpdated = valoresListaPredeterminada.examenes.map(item => item.id === examenValoresAux.id ? examenValoresAux : item);
      setValoresListaPredeterminada({ ...valoresListaPredeterminada, 'examenes': examenesUpdated });
    } else {
      //New examen
      const data = { ...examenValoresAux, new: true };
      const newExamenes = [...valoresListaPredeterminada.examenes, data]
      setValoresListaPredeterminada({ ...valoresListaPredeterminada, 'examenes': newExamenes });
    }
    setIsSubmitGrupoExamenMedico(false);
    setShowFormGrupoExamenMedicoExamen(false);
    setExamenValoresAux(valoresInit);
  }

  const handleSubmitListaPredeterminada = async () => {
    try {

      setIsSubmitListaPredeterminada(true);

      if (Object.keys(errorsListaPredeterminada).length > 0) {
        Notificacion({
          type: "error",
          text: "Debe completar los campos correctamente.",
        });
        return;
      }

      if (valoresListaPredeterminada.examenes.length === 0) {
        Notificacion({
          type: "error",
          text: "La lista debe tener al menos un exámen.",
        });
        return;
      }

      setLoading(true);

      if (valoresListaPredeterminada.id) {
        //Update lista predeterminada
        await dispatch(editarExamenListaPredeterminada(valoresListaPredeterminada.id, valoresListaPredeterminada));
      } else {
        //New lista predeterminada
        await dispatch(crearExamenListaPredeterminada(valoresListaPredeterminada));
      }
      setLoading(false);
      setValoresListaPredeterminada(valoresListaPredeterminadaInit.current);
      setIsSubmitListaPredeterminada(false);
      setShowFormGrupoExamenMedico(false);
    } catch (error) {
      setLoading(false);
      // console.log({ error });
    }

  }

  // Print pedido examenes
  const handleReactPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleSendEmail = async () => {
    try {
      setLoadingText('Enviando email...');

      const cie10Text = cie10SelectedList && Array.isArray(cie10SelectedList) ? formatCie10Code(cie10SelectedList) : '';

      setLoading(true)
      await dispatch(enviarPedidoExamenesEmail(dataCliente.id, { exams: examenesData, cie10Text }, { showHeader: true, download: true }));
      setLoading(false);
      setLoadingText('Guardando...');

    } catch (error) {
      console.log(error)
    }
  }

  //Enviar email pedido exámenes


  useEffect(() => {
    //Validaciones form
    setErrors(validacionesForm(valores));
  }, [valores]);

  useEffect(() => {
    //Validaciones form
    setErrors(validacionesFormLista(valoresLista));
  }, [valoresLista]);

  useEffect(() => {
    //Validaciones form
    setErrorsExamenValoresAux(validacionesForm(examenValoresAux));
  }, [examenValoresAux]);

  useEffect(() => {
    //Validaciones form
    setErrorsListaPredeterminada(validacionesForm(valoresListaPredeterminada));
  }, [valoresListaPredeterminada]);

  // Save data
  // const handleConfirmSave = () => {
  //   if (!consultaDirty) setOpenConfirmSave(true)
  //   else handleSaveReceta();
  // }

  // Guardar datos de consulta
  const handleSubmitConsulta = () => {
    if (data.length === 0) {
      Notificacion({
        type: "error",
        text: "Debe agregar por lo menos un exámen",
      });
      return
    }
    onSubmit(true);
  }


  return (
    <div className={classes.container}>
      <Loading title={loadingText} loading={loading}>
        <div style={{ width: '100%', /*overflow: 'scroll'*/ }}>
          <ConfirmDialog
            title={"Confirmación de eliminación"}
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            onConfirm={handleDeleteListaPredeterminada}
          >
            <div>¿Esta seguro de eliminar este registro?</div>
          </ConfirmDialog>
          {
            examenesData.length > 0 &&
            <div>
              <Tooltip
                title="Imprimir pedido exámenes"
                placement="top"
              >
                <IconButton
                  className="no-printme"
                  variant="outlined"
                  color="primary"
                  onClick={handleReactPrint}
                >
                  <PrintIcon />
                </IconButton>


              </Tooltip>
              <Tooltip
                title="Enviar email"
                placement="top"
              >
                <IconButton
                  className="no-printme"
                  variant="outlined"
                  color="primary"
                  onClick={() => handleSendEmail()}
                >
                  <EmailIcon />

                </IconButton>
              </Tooltip>
            </div>
          }
          {/* Div imprimir */}
          <div
            style={{ display: "none" }}
          >
            <ExamenPrint ref={componentRef} header={true}
              data={{
                patient: dataCliente,
                doctor: { ...dataUser.medico },
                exams: examenesData,
                cie10List: cie10SelectedList,
              }}
              pdf
            />
          </div>
          {/* Fin div imprimir */}
          {/* Table Examenes  */}
          <div>
            <Table size="small" aria-label="purchases" className={classes.table} >
              <TableHead>
                <TableRow>
                  <TableCell align="center">Nombre</TableCell>
                  <TableCell align="center">Detalles</TableCell>
                  <TableCell align="center">Archivo</TableCell>
                  <TableCell align="center">Lista</TableCell>
                  <TableCell align="center">Opciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  examenesData.map(examen => (
                    <TableRowExamen
                      row={examen}
                      handleDownloadBase64={handleDownloadBase64}
                      handleDelete={handleDelete}
                      handleEdit={handleEdit}
                      grupoData={examenesGrupoData}
                      handleUpdateItemGroup={handleUpdateItemGroup}
                      key={examen.id}
                    />
                  ))
                }
              </TableBody>
            </Table>
          </div>
          {/* Table Examenes Grupo  */}
          <div style={{ marginBottom: '10px', marginTop: '15px' }}>
            <strong> Lista de exámenes personalizados</strong>
          </div>
          <div>
            <TableContainer component={Paper} className={classes.table}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Número</TableCell>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Archivo</TableCell>
                    <TableCell>Exámenes agregados</TableCell>
                    <TableCell>Opciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {examenesGrupoData.map((row, index) => (
                    <TableRowGrupoExamen
                      handleDownloadBase64={handleDownloadBase64}
                      key={row.id}
                      auxId={index + 1}
                      row={row}
                      handleDelete={handleDeleteGrupoExamen}
                      handleEdit={handleEditGrupoExamen}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div style={{ marginTop: '20px' }}></div>
          {/* Formulario */}
          {
            showForm &&
            <div>
              <GridContainer>
                {/* Archivo */}
                {
                  formEdit &&
                  <GridItem xs={12} xl={3}>
                    <FormControl className={classes.formControl}>
                      <FormLabel htmlFor={'exa_respiratorio'} size="normal" className={classes.inputLabel} >
                        {'Subir archivo'}
                      </FormLabel>
                      <div style={{ marginTop: '10px', marginBottom: '10px', maxWidth: '100%' }}>
                        <input
                          type="file"
                          name="imagen"
                          id="imagen"
                          // accept=".pdf, image/png, image/gif, image/jpeg"
                          onChange={handleFileSelected}
                        // ref={fileField}
                        />
                      </div>
                    </FormControl>
                  </GridItem>
                }
                {/* Autocomplete examen */}
                <GridItem xs={12} xl={4}>
                  <div style={{ marginBottom: '10px' }}>
                    <AutocompleteComponent
                      value={valores.nombre}
                      freeSolo={true}
                      poblarData={[]}
                      onBuscadoChange={(event, values) => {
                        handleSelectExamen(event, values, true)
                      }}
                      optionLabel={option => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.nombre;
                        }
                        // Regular option
                        return option.nombre;
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        // Suggest the creation of a new value
                        if (params.inputValue !== '') {
                          filtered.push({
                            inputValue: params.inputValue,
                            nombre: `${params.inputValue}`,
                          });
                        }
                        return filtered;
                      }}
                      label="Nombre"
                      dispatchFunction={handleBuscarExamenes}
                      dataSearch={examenData}
                      reduxFunction={false}
                      getOptionSelected={
                        (option, value) => option.nombre === value
                      }
                    />
                    <div style={{ textAlign: 'start' }}>
                      Opcion seleccionada:
                      {!!valores.nombre ? ' ' + valores.nombre : ' Ninguna Opcion seleccionada'}
                    </div>
                  </div>
                  {errors.nombre && isSubmit && (
                    <FormErrorLabel>{errors.nombre}</FormErrorLabel>
                  )}
                </GridItem>
                {/* Detalles */}
                <GridItem xs={12} xl={4}>
                  <CustomInput
                    withStyles={false}
                    labelText="Detalles"
                    formControlProps={{
                      fullWidth: true,
                      style: {
                        margin: '0px'
                      }
                    }}
                    id="detalles"
                    inputProps={{
                      onChange: handleChange,
                      name: "detalles",
                      multiline: true,
                      rows: 5,
                      value: valores.detalles,
                    }}
                    error={errors.detalles ? true : false}
                  />
                  {errors.detalles && (
                    <FormErrorLabel>{errors.detalles}</FormErrorLabel>
                  )}
                </GridItem>
              </GridContainer>
            </div>
          }
          {/* Formulario Lista */}
          {
            showFormLista &&
            <div>
              <GridContainer>
                {/* nombre lista */}
                {
                  <GridItem xs={12} xl={4}>
                    <CustomInput
                      withStyles={false}
                      labelText="Nombre"
                      formControlProps={{
                        fullWidth: true,
                        style: {
                          margin: '0px'
                        }
                      }}
                      id="nombreLista"
                      inputProps={{
                        onChange: (e) => handleChange(e, 2),
                        name: "nombre",
                        value: valoresLista.nombre,
                        disabled: formEditArchivoLista
                      }}
                    />
                  </GridItem>
                }
                {/* Archivo */}
                {
                  formEditArchivoLista &&
                  <GridItem xs={12} xl={3}>
                    <FormControl className={classes.formControl}>
                      <FormLabel htmlFor={'archivoLista'} size="normal" className={classes.inputLabel} >
                        {'Subir archivo'}
                      </FormLabel>
                      <div style={{ marginTop: '10px', marginBottom: '10px', maxWidth: '100%' }}>
                        <input
                          ref={archivoListaRef}
                          type="file"
                          name="archivo"
                          id="archivoLista"
                          // accept=".pdf, image/png, image/gif, image/jpeg"
                          onChange={(e) => handleFileSelected(e, 2)}
                        // ref={fileField}
                        />
                      </div>
                    </FormControl>
                  </GridItem>
                }
              </GridContainer>
            </div>
          }
          <div style={{ marginTop: '10px' }}>
            <GridContainer justifyContent="center" direction="row">
              <GridItem xs={"auto"}>
                {
                  !showFormLista &&
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    {
                      !showForm ?
                        'Nuevo Examen'
                        :
                        formEdit ? 'Subir Archivo' : 'Agregar'
                    }
                  </Button>
                }
                {
                  !showForm && !showFormLista &&
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCreateNewLista}
                  >
                    Nueva lista personalizada
                  </Button>
                }
                {/* Guardar */}
                {
                  !showForm && showFormLista &&
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={handleSubmitlista}
                  >
                    {
                      formEditArchivoLista ?
                        'Subir Archivo'
                        :
                        'Actualizar'
                    }
                  </Button>
                }
                {
                  (showForm || showFormLista) && (
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleCancel}
                    >
                      Cancelar
                    </Button>
                  )
                }
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button color="warning" onClick={handleSubmitConsulta}>
                    Guardar
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
          </div>
          {/* Table Examenes predeterminados medico  */}
          <div style={{ marginTop: '10px' }}>
            <strong> Lista de exámenes predeterminados</strong>
          </div>
          <div>
            <TableContainer component={Paper} className={classes.table}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Nombre</TableCell>
                    <TableCell>Exámenes agregados</TableCell>
                    <TableCell>Opciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataExamenesGrupoMedico.map((row, index) => (
                    <TableRowGrupoExamenDoctor
                      key={row.id}
                      row={row}
                      handleDelete={(id) => {
                        setItemDeleteId(id);
                        setOpenConfirmDialog(true);
                      }}
                      handleEdit={handleEditListaPredeterminada}
                      handleUse={handleUseMedicoGrupoExamen}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {/* Table Examenes formulario medico  */}
          {showFormGrupoExamenMedico &&
            <div style={{ padding: '10px' }}>
              <GridContainer>
                <GridItem xs={12} xl={4}>
                  <CustomInput
                    withStyles={false}
                    labelText="Nombre de la lista"
                    formControlProps={{
                      fullWidth: true,
                      style: {
                        margin: '0px'
                      }
                    }}
                    id="nombreLista"
                    inputProps={{
                      onChange: (e) => handleChange(e, 3),
                      name: "nombre",
                      value: valoresListaPredeterminada.nombre,
                      disabled: formEditArchivoLista
                    }}
                    error={errorsListaPredeterminada.nombre && isSubmitListaPredeterminada ? true : false}
                  />
                  {isSubmitListaPredeterminada && errorsListaPredeterminada.nombre && (
                    <FormErrorLabel>{errorsListaPredeterminada.nombre}</FormErrorLabel>
                  )}
                </GridItem>
                <GridItem xs={12}>
                  <div style={{ marginTop: '10px' }}>
                    Exámenes de la lista
                  </div>
                  {
                    !showFormGrupoExamenMedicoExamen &&
                    <div>
                      <GridContainer>
                        <GridItem xs={"auto"}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setShowFormGrupoExamenMedicoExamen(true)}
                          >
                            Agregar examen a la lista
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  }
                  {
                    !showFormGrupoExamenMedicoExamen &&
                    <Table className={classes.table} size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Nombre</TableCell>
                          <TableCell align="right">Detalles</TableCell>
                          <TableCell align="right">Opciones</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {valoresListaPredeterminada.examenes.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {row.nombre}
                            </TableCell>
                            <TableCell align="right">{row.detalles}</TableCell>
                            <TableCell align="right">
                              <Tooltip
                                id="tooltip-editar-examen"
                                title="Editar"
                                placement="top"
                                classes={{ tooltip: classesRow.tooltip }}
                              >
                                <IconButton
                                  aria-label="Close"
                                  className={classesRow.tableActionButton}
                                  onClick={() => handleEditFormExamenListaPredeterminada(row)}
                                >
                                  <EditIcon
                                    className={
                                      classesRow.tableActionButtonIcon + " " + classesRow.close
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                id="tooltip-eliminar-examen"
                                title="Eliminar de lista"
                                placement="top"
                                classes={{ tooltip: classesRow.tooltip }}
                              >
                                <IconButton
                                  aria-label="Close"
                                  className={classesRow.tableActionButton}
                                  onClick={() => handleDeleteFormExamenListaPredeterminada(row.id)}
                                >
                                  <Close
                                    className={
                                      classesRow.tableActionButtonIcon + " " + classesRow.close
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  }
                </GridItem>
                {/* Lista predeterminada form examen */}
                {
                  showFormGrupoExamenMedicoExamen &&
                  <>
                    <GridItem xs={12} xl={4}>
                      <div style={{ marginBottom: '5px' }}>
                        <AutocompleteComponent
                          // defaultValue={{ id: null, nombre: examenSelected?.nombre ? examenSelected.nombre : '' }}
                          value={examenValoresAux.nombre}
                          freeSolo={true}
                          poblarData={[]}
                          onBuscadoChange={(event, values) => {
                            handleAutoCompleteSelectExamenGrupo(event, values, true)
                          }}
                          optionLabel={option => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.nombre;
                            }
                            // Regular option
                            return option.nombre;
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            // Suggest the creation of a new value
                            if (params.inputValue !== '') {
                              filtered.push({
                                inputValue: params.inputValue,
                                nombre: `${params.inputValue}`,
                              });
                            }
                            return filtered;
                          }}
                          label="Nombre de examen"
                          dispatchFunction={handleBuscarExamenes}
                          dataSearch={examenData}
                          // onFocus={() => setShowForm(false)}
                          reduxFunction={false}
                          getOptionSelected={
                            (option, value) => option.nombre === value
                          }
                        // onUpdateInput={_.debounce((value) => doSearch(value), 500)}
                        />
                        <div style={{ textAlign: 'start' }}>
                          Opcion seleccionada:
                          {!!examenValoresAux.nombre ? ' ' + examenValoresAux.nombre : ' Ninguna Opcion seleccionada'}
                        </div>
                      </div>
                      {errorsExamenValoresAux.nombre && isSubmitGrupoExamenMedico && (
                        <FormErrorLabel> {errorsExamenValoresAux.nombre}</FormErrorLabel>
                      )}
                    </GridItem>
                    <GridItem xs={12} xl={4}>
                      <CustomInput
                        withStyles={false}
                        labelText="Detalles de examen"
                        formControlProps={{
                          fullWidth: true,
                          style: {
                            margin: '0px'
                          }
                        }}
                        id="detalles"
                        inputProps={{
                          onChange: handleChangeFormExamenListaPredeterminada,
                          name: "detalles",
                          multiline: true,
                          rows: 5,
                          value: examenValoresAux.detalles,
                        }}
                        error={errors.detalles ? true : false}
                      />
                    </GridItem>
                    <GridItem xs={12} >
                      <GridContainer justifyContent="center" direction="row">
                        <GridItem xs={"auto"}>
                          <Button
                            variant="contained"
                            color="warning"
                            onClick={handleSubmitExamenListaPredeterminada}
                          >
                            {
                              examenValoresAux.id ?
                                'Actualizar examen'
                                : 'Agregar'
                            }
                          </Button>
                        </GridItem>
                        <GridItem xs={"auto"}>
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                              setExamenValoresAux(valoresInit)
                              setShowFormGrupoExamenMedicoExamen(false)
                            }}
                          >
                            Cancelar
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </>
                }
              </GridContainer>
            </div>
          }
          <div>
            <GridContainer justifyContent="center" direction="row">
              {
                showFormGrupoExamenMedico && !showFormGrupoExamenMedicoExamen &&
                (
                  <GridItem xs={"auto"}>
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={handleSubmitListaPredeterminada}
                    >
                      {
                        valoresListaPredeterminada.id ?
                          'Actualizar Lista'
                          :
                          'Guardar Lista'
                      }
                    </Button>
                  </GridItem>
                )
              }
              {
                !showFormGrupoExamenMedicoExamen &&
                <GridItem xs={"auto"}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleNuevaListaPredeterminada}
                  >
                    {
                      !showFormGrupoExamenMedico ?
                        'Nueva Lista predeterminada'
                        :
                        'Cancelar'
                    }
                  </Button>
                </GridItem>
              }
            </GridContainer>
          </div>
        </div>
      </Loading >
    </div>
  );
};

export default PacienteExamenesForm;
