import React from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ReservasSucursales } from './ReservasSucursales';
import { Box } from '@material-ui/core';

export const SucursalReservacion = ({
  selectedDate,
  setSelectedDate,
  setSelectedDay,
}) => {

  const handleDateChange = date => {
    setSelectedDate(date);
    setSelectedDay(moment(date).format('dddd'));
  };


  return (
    <Box width={1}>
      <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>

        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="YYYY-MM-DD"
            margin="normal"
            id="fecha_reserva"
            label="Elija la fecha"
            minDate={new Date()}
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </div>

      {/* Oficinas */}

      <ReservasSucursales />
    </Box>
  )
}
