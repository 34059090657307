import { UserRoleTypes } from 'constants/constants'
import React, { useState } from 'react'
import { DoctorRegisterForm } from './DoctorRegisterForm';
import { UserRegisterForm } from './UserRegisterForm';

export const RegisterForm = () => {

  // Tipo de formulario de registro de la página
  const [registerUserFormType, setRegisterUserFormType] = useState(UserRoleTypes.cliente);

  const handleChangeUserRoleRegisterForm = (event) => setRegisterUserFormType(event.target.value);

  return (
    <div id="form" className="register-form form even">

      <h3>"¿Estás listo para impulsar tu crecimiento?, ¡Únete ya a nuestra plataforma!"</h3>
      <div style={{ maxWidth: '500px' }}>

        <h1 style={{ textAlign: 'center' }}>REGISTRO</h1>
        <div style={{paddingInline: '20px', paddingTop: '20px'}}>
          {/* <form> */}
            <label htmlFor={'userFormSelect'}>¿Qué soy?</label>
            <select
              id='userFormSelect'
              onChange={handleChangeUserRoleRegisterForm}
              defaultValue={UserRoleTypes.cliente}
            >
              <option value={UserRoleTypes.cliente}>Cliente</option>
              <option value={UserRoleTypes.doctor}>Profesional de Salud</option>
              <option value={UserRoleTypes.laboratorista}>Laboratorista</option>
              <option value={UserRoleTypes.secretaria}>Secretaria/o</option>
            </select>
          {/* </form> */}
        </div>

        {
          (registerUserFormType === UserRoleTypes.doctor) ? (
            <DoctorRegisterForm />
          ) : (
            <UserRegisterForm userRole={registerUserFormType} />
          )
        }
      </div>

    </div>
  )
}
