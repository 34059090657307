import React, { useMemo } from 'react'
import medicoSVG from "../../../src/assets/images/icons/medico.svg"
import CRUD from 'components/CRUD/Crud.component';
import { urlCrearBlog, urlEditarBlog, urlListarBlogs, urlEliminarBlog } from 'constants/urls';
import { validarBlogIntroduccion } from 'utils/validaciones';
import { formatDate } from 'utils/format/format';

export const BlogsCrud = () => {
  const fileField = React.useRef(null);

  const tableData = useMemo(() => [
    {
      type: "imageInput",
      name: "img_url",
      label: "Imagen principal",
      imgDefault: medicoSVG,
      ref: fileField
    },
    {
      type: "inputDisabled",
      label: "Id",
      name: 'id',
      showOnCreate: false,
      showOnTable: true,
      tableProps: {
        title: 'Id',
        sort: {
          sortColumnName: 'id',
          sortColumnNameDB: 'id',
        }
      },
    },
    {
      type: "input",
      name: "titulo",
      label: "Título",
      tableProps: {
        sort: {
          sortColumnName: 'titulo',
          sortColumnNameDB: 'titulo',
        }
      },
      validationOptions: {
        required: {
          value: true,
          message: 'El campo es requerido',
        },
      },
    },
    {
      type: "input",
      name: "subtitulo",
      label: "Subtítulo",
      tableProps: {
        sort: {
          sortColumnName: 'subtitulo',
          sortColumnNameDB: 'subtitulo',
        }
      },
      validationOptions: {
        required: {
          value: true,
          message: 'El campo es requerido',
        },
      },
    },
    {
      type: "editorTinyMCE",
      name: "contenido_introductorio",
      label: "Contenido introductorio",
      tinyMCEOptions: {
        height: 200,
        menubar: false
      },
      validationOptions: {
        required: {
          value: true,
          message: 'El campo es requerido',
        },
        validate: (value) => {
          if (!validarBlogIntroduccion(value))
            return 'El campo no debe tener mas de 200 caracteres'
          return true;
        }
      },
    },
    {
      name: "created_date",
      label: "Fecha de creación",
      tableProps: {
        formatData: (value) => formatDate(value),
        sort: {
          sortColumnName: 'created_date',
          sortColumnNameDB: 'created_date',
        }
      },
    },
    {
      type: "editorTinyMCE",
      name: "contenido",
      label: "Contenido",
    },
  ], []);

  const data = useMemo(() => ({
    tableData,
    urls: {
      createUrl: urlCrearBlog,
      updateUrl: urlEditarBlog,
      readUrl: urlListarBlogs,
      deleteUrl: urlEliminarBlog,
    }
  }), [tableData])

  return (
    <CRUD data={data} />
  )
}
