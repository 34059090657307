import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
// core components
import WarningLeaveFormPage from "components/ui/WarningLeaveFormPage";
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import Notificacion from "components/ui/Notificacion";
import Loading from 'components/Loading';
import secretariaSVG from '../../src/assets/images/icons/secretaria.svg';
import dashboardStyles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';

//Validaciones
import { validacionesPerfilSecretaria } from 'utils/validaciones';

import { secretariaUpdate, login as loginData } from 'redux/actions/aLogin';
import { urlBucket } from 'constants/urls';
import { cambioPass } from 'redux/actions/aLogin';
import { encriptarRsa } from 'utils/encriptar';
import { MenuItem, Select } from '@material-ui/core';
import { FormErrorLabel } from 'components/forms/labels/FormErrorLabel';
import { ModalRequestAccountDeletion } from 'components/Modal/ModalRequestAccountDeletion';

const useStyles = makeStyles(dashboardStyles);

const PerfilSecretaria = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const state = useSelector(state => state);
  const usuario = useSelector(state => state.dataUser.data);

  const [formDirty, setFormDirty] = useState(false);
  const [errors, seterrors] = useState({});

  const [loading, setloading] = useState(false);
  const [imgTemp, setImgTemp] = useState(null);

  // Request account deletion
  const [openRequestDeletionAccountModal, setOpenRequestDeletionAccountModal] = useState(false);

  // const [cambioImagen, setcambioimagen] = useState(false);

  const tituloHonorificoOpciones = [
    'Dr.',
    'Dra.',
    'Lcdo.',
    'Lcda.',
    'Sr.',
    'Sra.',
    'Srta.',
    'Otro',
  ]
  const tituloHonorificoInputRef = useRef(null);

  let timeout = useRef(null);

  const valoresInit = useRef({
    login: !!usuario.login ? usuario.login : '',
    titulo_honorifico: !!usuario.titulo_honorifico ? usuario.titulo_honorifico : '',
    email: !!usuario.email ? usuario.email : '',
    nombres: !!usuario.nombres ? usuario.nombres : '',
    apellidos: !!usuario.apellidos ? usuario.apellidos : '',
    foto_nombre: !!usuario.foto_nombre ? usuario.foto_nombre : '',
  })

  const [valores, setvalores] = useState(valoresInit);

  const [pass, setpass] = useState({
    anterior: '',
    nueva: '',
    nuevaConfirmacion: '',
  });
  const fileField = React.useRef(null);

  function handleFileSelected(e) {
    const file = e.target.files[0];
    // divido para dos para 5MB max
    if (file.size / 1024 / 1024 < 5) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setvalores({ ...valores, foto_nombre: reader.result });
        setImgTemp(reader.result);
      };
    } else {
    }
  }

  const handleClickCambiar = async () => {
    try {
      setloading(true);
      if (pass.nueva === pass.nuevaConfirmacion) {
        if (pass.nueva.length < 5 || !/.*^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/.test(pass.nueva)) {
          setloading(false);
          Notificacion({
            type: "error",
            text: `La contraseña debe tener al menos 5 caracteres, al menos una mayúscula, una minúscula y un número`,
          });
          return;
        } else {
          const data = await encriptarRsa(
            JSON.stringify({ anterior: pass.anterior, nueva: pass.nueva })
          );
          await dispatch(cambioPass(data, state));
          setloading(false);

        }
      } else {
        setloading(false);
        Notificacion({
          type: "error",
          text: `La contraseña nueva no coincide!`,
        });
      }
    } catch (error) {
      setloading(false);
      Notificacion({
        type: "error",
        text: `Ocurrio un error, intente nuevamente ${error}`,
      });
    }
  };

  const handleChangePass = e => {
    const { name, value } = e.target;
    setpass({ ...pass, [name]: value });
  };

  const handleChange = e => {
    let { name, value } = e.target;

    if (name === 'titulo_honorifico_select') {
      name = 'titulo_honorifico';
      if (value === 'Otro') {
        value = '';
        timeout.current = setTimeout(() => {
          // inputRef.current.focus();
          tituloHonorificoInputRef.current.focus();
        }, 400);
      };
    }
    setvalores({ ...valores, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      if (Object.keys(errors).length > 0) {
        Notificacion({
          type: "error",
          text: "Debe completar los campos correctamente",
        });
        return;
      }
      setloading(true);
      const res = await dispatch(secretariaUpdate(valores, state));
      if (!res.error) {
        if (imgTemp) setImgTemp(null);
        dispatch(loginData(res.nueva_data));
      } else {
        Notificacion({
          type: "error",
          text: res.error,
        });
      }
    } catch (err) {
      Notificacion({
        type: "error",
        text: "Ocurrio un error, intente nuevamente",
      });
    }
    setloading(false);
  };

  useEffect(() => {

    valoresInit.current = {
      id: usuario.id !== undefined ? usuario.id : '',
      login: usuario.login !== undefined ? usuario.login : '',
      titulo_honorifico: usuario.titulo_honorifico !== undefined ? usuario.titulo_honorifico : '',
      email: usuario.email !== undefined ? usuario.email : '',
      nombres: usuario.nombres !== undefined ? usuario.nombres : '',
      apellidos: usuario.apellidos !== undefined ? usuario.apellidos : '',
      // numero_telefono: usuario.numero_telefono !== undefined ? usuario.numero_telefono : '',
      foto_nombre: !!usuario.foto_nombre ? usuario.foto_nombre : '',
    };

    setvalores(valoresInit.current);
  }, [usuario]);

  //Espero que se termine de setear los valores, para con esos valores validar
  useEffect(() => {
    seterrors(validacionesPerfilSecretaria(valores));

    //Verificar si se ha modificado el formulario
    if (_.isEqual(valores, valoresInit.current)) setFormDirty(false);
    else setFormDirty(true);
  }, [valores]);

  useEffect(() => {
    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
  }, []);

  return (
    <Loading title="Cargando..." loading={loading}>
      <>
        {/* Modal request deletion account*/}
        <ModalRequestAccountDeletion
          open={openRequestDeletionAccountModal}
          onCloseModal={() => setOpenRequestDeletionAccountModal(false)}
        />
        {/* End Modal request deletion account */}

        {/* Warning on exit page */}
        {/* Form state: {formDirty ? 'form dirty' : 'form no dirty'} */}
        <WarningLeaveFormPage when={formDirty} />
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitle}>Editar Perfil</h4>
              </CardHeader>

              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      {valores.foto_nombre !== ' ' && (
                        <>
                          <img
                            width="150px"
                            src={imgTemp ? imgTemp : `${urlBucket}${valores.foto_nombre}`}
                            alt='imgSecretaria'
                          />
                          <input
                            type="file"
                            name="imagen"
                            id="imagen"
                            accept=".jpeg, .png, .jpg"
                            onChange={handleFileSelected}
                            ref={fileField}
                          />
                        </>
                      )}
                      {valores.foto_nombre === ' ' && (
                        <>
                          {' '}
                          <img width="150px" src={secretariaSVG} alt='imgSecretaria' />{' '}
                          <input
                            type="file"
                            name="imagen"
                            id="imagen"
                            accept=".jpeg, .png, .jpg"
                            onChange={handleFileSelected}
                            ref={fileField}
                          />
                        </>
                      )}
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Su código es"
                      formControlProps={{
                        fullWidth: true,
                        disabled: true,
                      }}
                      inputProps={{
                        id: 'id',
                        name: 'id',
                        value: valores.id,
                        enable: false,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Nombre de usuario"
                      formControlProps={{
                        fullWidth: true,
                        disabled: true,
                      }}
                      inputProps={{
                        id: 'login',
                        name: 'login',
                        value: valores.login,
                        enable: false,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <GridContainer>
                      <GridItem xs={10} >
                        <CustomInput
                          labelText="Título Honorífico"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          id="titulo_honorifico"
                          inputProps={{
                            inputRef: tituloHonorificoInputRef,
                            onChange: handleChange,
                            name: 'titulo_honorifico',
                            value: valores.titulo_honorifico,
                            placeholder: 'Sra.',
                          }}
                          error={errors.titulo_honorifico}
                          success={!errors.titulo_honorifico}
                        />
                      </GridItem>
                      <GridItem xs={2} >
                        <Select
                          labelId="demo-simple-select-label"
                          name="titulo_honorifico_select"
                          id="titulo_honorifico-select"
                          value={tituloHonorificoOpciones.includes(valores.titulo_honorifico) ? valores.titulo_honorifico : 'Otro'}
                          onChange={handleChange}
                          style={{ 'marginTop': '43px', width: '100%' }}
                        >
                          {
                            tituloHonorificoOpciones.map((op, index) => (
                              <MenuItem key={index} value={op}>{op}</MenuItem>
                            ))
                          }

                        </Select>
                      </GridItem>
                    </GridContainer>
                    {errors.titulo_honorifico && (
                      <FormErrorLabel>{errors.titulo_honorifico}</FormErrorLabel>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      id="email"
                      inputProps={{
                        onChange: handleChange,
                        name: 'email',
                        value: valores.email,
                      }}
                      error={errors.email}
                      success={!errors.email}
                    />
                    {errors.email && <FormErrorLabel>{errors.email}</FormErrorLabel>}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Nombres"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      id="nombres"
                      inputProps={{
                        onChange: handleChange,
                        name: 'nombres',
                        value: valores.nombres,
                      }}
                      error={errors.nombres}
                      success={!errors.nombres}
                    />
                    {errors.nombres && <FormErrorLabel>{errors.nombres}</FormErrorLabel>}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Apellidos"
                      id="apellidos"
                      inputProps={{
                        onChange: handleChange,
                        name: 'apellidos',
                        value: valores.apellidos,
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      error={errors.apellidos}
                      success={!errors.apellidos}
                    />
                    {errors.apellidos && <FormErrorLabel>{errors.apellidos}</FormErrorLabel>}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Contraseña anterior"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      id="anterior"
                      inputProps={{
                        onChange: handleChangePass,
                        name: 'anterior',
                        value: valores.anterior,
                        type: 'password',
                        placeholder: '********',
                      }}
                    // error={errors.anterior}
                    // success={!errors.anterior}
                    />
                    {errors.anterior && <FormErrorLabel>{errors.anterior}</FormErrorLabel>}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Nueva contraseña"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      id="nueva"
                      inputProps={{
                        onChange: handleChangePass,
                        name: 'nueva',
                        value: valores.nueva,
                        placeholder: '********',
                        type: 'password',
                      }}
                    // error={errors.nueva}
                    // success={!errors.nueva}
                    />
                    {errors.nueva && <FormErrorLabel>{errors.nueva}</FormErrorLabel>}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Confirmar contraseña"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      id="nuevaConfirmacion"
                      inputProps={{
                        onChange: handleChangePass,
                        name: 'nuevaConfirmacion',
                        value: valores.nuevaConfirmacion,
                        placeholder: '********',
                        type: 'password',
                      }}
                    // error={errors.nuevaConfirmacion}
                    // success={!errors.nuevaConfirmacion}
                    />
                    {errors.nuevaConfirmacion && (
                      <FormErrorLabel>{errors.nuevaConfirmacion}</FormErrorLabel>
                    )}
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="warning" onClick={() => handleSubmit()}>
                  Actualizar Perfil
                </Button>
                <Button color="warning" onClick={() => handleClickCambiar()} >
                  Cambiar contraseña
                </Button>
                <Button color="danger" onClick={() => setOpenRequestDeletionAccountModal(true)}>
                  Eliminar cuenta
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    </Loading>
  );
};

export default PerfilSecretaria;
