


import React, { useMemo } from 'react';
import CRUD from 'components/CRUD/Crud.component';
import { formatDate } from 'utils/format/format';
import { urlListarTransacciones, urlCrearTransaccion, urlEditarTransaccion, urlEliminarTransaccion } from 'constants/urls';

const TransaccionCrud = () => {

  const tableData = useMemo(() => [
    {
      type: "inputDisabled",
      label: "Id",
      name: 'id',
      showOnCreate: false,
      showOnTable: true,
      tableProps: {
        title: 'Id',
        sort: {
          sortColumnName: 'id',
          sortColumnNameDB: 'id',
        }
      },
    },
    {
      type: "input",
      label: "Número",
      name: 'numero',
      tableProps: {
        title: 'Número',
        sort: {
          sortColumnName: 'numero',
          sortColumnNameDB: 'numero',
        }
      },
    },
    {
      type: "input",
      name: "estado",
      label: "Estado",
      tableProps: {
        title: 'Estado',
        sort: {
          sortColumnName: 'estado',
          sortColumnNameDB: 'estado',
        }
      },
    },
    {
      type: "input",
      name: "valor",
      label: "Valor",
      tableProps: {
        title: 'Valor',
        sort: {
          sortColumnName: 'valor',
          sortColumnNameDB: 'valor',
        }
      },
      validationOptions: {
        validate: (value) => {
          if (isNaN(value)) {
            return "El campo valor debe ser un valor numérico"
          }
          return true;
        }
      }
    },
    {
      type: "input",
      name: "creditos",
      label: "Creditos",
      tableProps: {
        title: 'Creditos',
        sort: {
          sortColumnName: 'creditos',
          sortColumnNameDB: 'creditos',
        }
      },
    },
    {
      name: "created_date",
      label: "Fecha de creación",
      tableProps: {
        formatData: (value) => formatDate(value),
        sort: {
          sortColumnName: 'created_date',
          sortColumnNameDB: 'created_date',
        }
      },
    },
    {
      type: "input",
      name: "tipo_transaccion",
      label: "Tipo transacción",
      tableProps: {
        title: 'Tipo transacción',
        sort: {
          sortColumnName: 'tipo_transaccion',
          sortColumnNameDB: 'tipo_transaccion',
        }
      },
    },
    {
      name: "cliente_id",
      label: "Cliente ID",
      tableProps: {
        sort: {
          sortColumnName: 'cliente_id',
          sortColumnNameDB: 'cliente_id',
        }
      },
    },

  ], []);


  const data = useMemo(() => ({
    tableData,
    urls: {
      createUrl: urlCrearTransaccion,
      updateUrl: urlEditarTransaccion,
      readUrl: urlListarTransacciones,
      deleteUrl: urlEliminarTransaccion,
    }
  }), [tableData])

  return (
    <CRUD data={data} hideOptions/>
  )
}

export default TransaccionCrud;
