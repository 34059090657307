import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import HorarioOficina from '../components/horario-oficina/laboratorista/horario-oficina.component';
import { getHorariosLaboratorista } from '../redux/actions/aOficinas';
const HorarioCitas = () => {
  const dispatch = useDispatch();
  const oficinas = useSelector(state => state.data_oficinas.data);


  useEffect(() => {
    dispatch(getHorariosLaboratorista());
  }, [dispatch]);

  return <HorarioOficina oficinas={oficinas} />;
};

export default HorarioCitas;
