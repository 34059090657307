import { createAction } from "redux-actions";
import {
  urlGetCajasReservasbySecretaria,
  urlGetNumeroCajasReservasSecretaria,
  urlListarCajas,
} from "../../constants/urls";

import { jwt } from "./aLogin";
import moment from "moment";
// import { id } from "date-fns/esm/locale";
export const cajas = createAction("SET_DATA_CAJAS");
export const isLoadingCajas = createAction("LOADING_CAJAS");
export const LoadCajasProgramas = createAction("LOAD_CAJAS_PROGRAMAS");
export const LoadCajasReservasbySecretaria = createAction(
  "LOAD CAJAS RESERVAS POR SECRETARIA"
);
export const LoadNumeroCajasReservasbySecretaria = createAction(
  "LOAD NUMERO A CAJAS RESERVAS POR SECRETARIA"
);

export const isLoadingCajasReservasbySecretaria = createAction(
  "LOADING CAJAS RESERVAS POR SECRETARIA"
);
export const isLoadingNumeroCajasReservasbySecretaria = createAction(
  "LOADING NUMERO CAJAS RESERVAS POR SECRETARIA"
);

export const hasErrorCajas = createAction("ERROR_CAJAS");

export const consultarCajas = (secretaira_id, medico_id, fecha) => async (
  dispatch,
  getstate
) => {
  try {
    dispatch(isLoadingCajas());
    const state = getstate();

    const { token } = state;

    var requestOptions = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    const momentDate = moment(fecha).startOf('day');
    const date = momentDate.toISOString();

    const response = await fetch(
      `${urlListarCajas}${secretaira_id}/${medico_id}?fecha=${date}`,
      requestOptions
    );

    const datos = await response.json()

    if (!token) {
      dispatch(jwt(""));
      return;
    }
    if (datos.transaccion) {
      const { data, dataProgramas } = datos;
      dispatch(cajas(data));
      dispatch(LoadCajasProgramas(dataProgramas));
    } else {
      dispatch(hasErrorCajas(`${datos.mensaje}`));
    }
  } catch (error) {
    dispatch(
      hasErrorCajas("No se pudo guardar la información recargue la pagina ")
    );
  }
};

export const updateCajas = (data, caja_id, medico_id, fecha) => async (
  dispatch,
  getstate
) => {
  dispatch(isLoadingCajas());
  try {
    const state = getstate();
    const { token } = state;

    const raw = JSON.stringify(data);
    var requestOptions = {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: raw,
    };

    const resp = await fetch(
      `${urlListarCajas}${caja_id}/${medico_id}?fecha=${moment(fecha).format(
        "YYYY-MM-DD"
      )}`,
      requestOptions
    );
    const respJson = await resp.json();

    const { token: tk = true } = respJson;
    if (!tk) {
      dispatch(jwt(""));
      return;
    }
    if (respJson.transaccion) {
      dispatch(cajas(respJson.data));
    } else {
      dispatch(hasErrorCajas(respJson.mensaje));
    }
  } catch (error) {
    dispatch(
      hasErrorCajas("No se pudo guardar la información recargue la pagina ")
    );
  }
};

export const guardarCajas = (data, medico_id, fecha) => async (
  dispatch,
  getstate
) => {
  dispatch(isLoadingCajas());
  try {
    const state = getstate();
    const { token } = state;

    const raw = JSON.stringify(data);
    var requestOptions = {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: raw,
    };

    const resp = await fetch(
      `${urlListarCajas}/guardarCajas/${medico_id}/${fecha}`,
      requestOptions
    );
    const respJson = await resp.json();

    const { token: tk = true } = respJson;
    if (!tk) {
      dispatch(jwt(""));
      return;
    }
    if (respJson.transaccion) {
      dispatch(cajas(respJson.data));
    } else {
      dispatch(hasErrorCajas(respJson.mensaje));
    }

  } catch (error) {
    dispatch(
      hasErrorCajas("No se pudo guardar la información recargue la pagina ")
    );
  }
};

export const startLoadCajasReservasBySecretaria = (
  fechaInicio = null,
  fechaFinal = null
) => async (dispatch, getstate) => {
  dispatch(isLoadingCajas());
  dispatch(isLoadingCajasReservasbySecretaria());
  try {
    const state = getstate();
    const { token } = state;
    let fechaQuery = "";

    if (fechaInicio && fechaFinal) {
      fechaQuery = `?fechaInicial=${moment(fechaInicio).format(
        "YYYY-MM-DD"
      )}&fechaFinal=${moment(fechaFinal).format("YYYY-MM-DD")}`;
    }
    // const raw = JSON.stringify();
    var requestOptions = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      // body: raw,
    };

    return fetch(
      `${urlGetCajasReservasbySecretaria}${fechaQuery}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((datos) => {
        if (datos.transaccion) {
          dispatch(LoadCajasReservasbySecretaria(datos.data));
        } else {
          dispatch(hasErrorCajas(datos.mensaje));
        }
      });
  } catch (error) {
    dispatch(
      hasErrorCajas("No se pudo guardar la información recargue la pagina ")
    );
  }
};

export const startLoadNumeroCajasReservasBySecretaria = (
  fecha = null
) => async (dispatch, getstate) => {
  dispatch(isLoadingCajas());
  dispatch(isLoadingNumeroCajasReservasbySecretaria());

  try {
    let fechaQuery = "";

    if (fecha) {
      fechaQuery = `?fecha=${moment(fecha).format("YYYY-MM-DD")}`;
    }
    const state = getstate();
    const { token } = state;

    // const raw = JSON.stringify();
    var requestOptions = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      // body: raw,
    };

    return fetch(
      `${urlGetNumeroCajasReservasSecretaria}${fechaQuery}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((datos) => {
        if (datos.transaccion) {
          dispatch(LoadNumeroCajasReservasbySecretaria(datos.data));
        } else {
          dispatch(hasErrorCajas(datos.mensaje));
        }
      });
  } catch (error) {
    dispatch(
      hasErrorCajas("No se pudo guardar la información recargue la pagina ")
    );
  }
};
