import React from 'react'
import "../../styles/landing/doctorLanding.scss"
import { DoctorRegisterForm } from 'components/forms/register/native/DoctorRegisterForm'

export const DoctorLandingPage = () => {
  return (
    <div className='doctor-landing-page'>
      <div className="section1 even">
        <div className="hero">
          <div className="medpal_logo_h"></div>
          <div className="medpal_logo_v"></div>
          <h1 className="side_l">La plataforma digital que potencia tu práctica médica</h1>
          <button className="registrarse" onClick={() => window.location.href = '#form'}>Regístrate</button>
        </div>
        <div className="hero_devices"></div>
      </div>

      <div className="section_intro">
        <h1>Únete a nuestra comunidad de profesionales de la salud y
          descubre una forma innovadora de conectarte con tus
          pacientes y optimizar tu trabajo</h1>
        <h2>¿Cómo Medpal puede ser tu aliado?</h2>
      </div>

      <div className="section2 even">
        <div className="features1 features">
          <h1>Vademécum Digital</h1>
          <h2>Accede a una amplia <b>base de datos de
            medicamentos</b> específicos para Ecuador.
            Obtén información actualizada sobre
            dosis, funciones, composición y más.</h2>
          <div id="img_feature1" className="img"></div>
        </div>
        <div className="features1_1">
          <div className="highlight features">
            <h2> <b>Con Medpal, tendrás un recurso valioso al alcance de tus manos para brindar una
              atención médica precisa y actualizada</b></h2>
          </div>
        </div>
      </div>

      <div className="section3 odd">
        <div className="features2 features">
          <h1>Promueve tu práctica médica</h1>
          <h2>Aprovecha nuestra comunidad en
            crecimiento y <b>muestra tus servicios a una
              audiencia diversa.</b> iAmplía tu alcance y
            deja que más personas se beneficien de
            tu experiencia!</h2>
          <div id="img_feature2" className="img"></div>
        </div>
        <div className="features2_1 features">
          <h1>Crea campañas
            personalizadas y
            atractivas para tus
            servicios</h1>
          <h2>Con Medpal, tienes el control total sobre tu
            promoción. <b>Crea campañas ofreciendo
              descuentos exclusivos a los usuarios de
              nuestra plataforma.</b> Destaca entre la
            competencia y atrae a nuevos pacientes
            que buscan atención médica de calidad a
            precios más accesibles.</h2>
          <div id="img_feature2_1" className="img"></div>
        </div>
      </div>

      <div className="section4 even">
        <div className="features3 features">
          <h1>Simplifica y
            optimiza tu trabajo
            con nuestro registro
            digital de historia
            clínica</h1>
          <h2> <b>Accede fácilmente a los antecedentes
            médicos de cada paciente</b>, realiza un
            seguimiento detallado de sus
            tratamientos y mantén un historial
            completo de sus consultas. <b>Con Medpat,
              la gestión de la información se vuelve
              más eficiente y segura.</b></h2>
          <div id="img_feature3" className="img"></div>
        </div>
      </div>

      <div className="section5 odd">
        <div className="features4 features">
          <h1>Gestión de citas</h1>
          <h2>Di adiós al caos de la programación
            manual de citas. Con Medpal, puedes
            gestionar tus citas de manera sencilla y
            organizada. <b>Tanto tú como tus pacientes
              recibirán recordatorios automáticos, 10
              que reduce las ausencias y optimiza tu
              tiempo.</b></h2>
          <div id="img_feature4" className="img"></div>
        </div>
      </div>

      <div className="section6 even">
        <div className="reviews">
          <h1>Ellos ya están disfrutando de las ventajas de MedPal</h1>
          <div id="img_customer"></div>
          <h3>"Como médico, Medpal ha transformado por completo mi forma de ejercer la
            medicina. Esta plataforma ha sido un verdadero impulso para mi práctica médica.
            Gracias al vademécum digital de medicina ecuatoriana, tengo acceso instantáneo a
            información actualizada sobre medicamentos específicos para Ecuador, 10 que me
            permite brindar a mis pacientes una atención más precisa y segura"</h3>
          <h4>Fabián Cárdenas <br /><i>Fisioterapéuta</i></h4>
        </div>
      </div>

      {/* Formulario de registro */}
      {/* <div id="form" className="form odd">
        <DoctorRegisterForm />
      </div> */}


      <div id="form" className="form odd" style={{ marginTop: '10px' }}>
        <h3>"Únete a nuestra plataforma y descubre cómo
          podemos ayudarte a crecer y destacarte en el
          mundo de la salud."</h3>
        <h1>REGÍSTRATE</h1>
        <DoctorRegisterForm />
      </div>
    </div>
  )
}
