
import moment from "moment";
import Notificacion from "components/ui/Notificacion";
import { platempExp } from 'constants/urls';
import { urlAgregarRolUsuario, urlEliminarRolUsuario, urlObtenerUsuarioPorId, urlListarUsuarios } from 'constants/urls';

import {
  urlListarHorariosPorOficina, urlDeleteHorariosOficinas, urlSetHorarioByOficina,
  urlListarOficinas
} from 'constants/urls';
import { createAction } from 'redux-actions';
import { jwt } from "./aLogin";
import { urlResponderContacto } from 'constants/urls';
import { urlEnviarMensajeAyuda } from 'constants/urls';
import { urlEmpresa } from "constants/urls";
import { urlAdminListarDoctoresPromociones } from "constants/urls";
import { urlAdminEditarDoctorServicio } from "constants/urls";
import { urlAdminEliminarDoctorServicio } from "constants/urls";
import { urlBuscarCiudades } from "constants/urls";
import { urlListarCiudadesEspecializacionAfiliacion } from "constants/urls";
import { urlCrearCiudadEspecializacionAfiliacion } from "constants/urls";
import { urlEditarCiudadEspecializacionAfiliacion } from "constants/urls";
import { urlEliminarCiudadEspecializacionAfiliaciom } from "constants/urls";
import { urlNoticiaImagen } from "constants/urls";

//Acciones
export const adminTableCRUD = createAction('ADMIN_TABLE_CRUD');
export const adminItemCRUD = createAction('ADMIN_TABLE_ITEM');
export const adminForeignData = createAction('ADMIN_FOREIGN_CRUD');
export const paginationData = createAction('ADMIN_PAGINATION_DATA');
export const adminRestartState = createAction('ADMIN_RESTART_STATE');

export const updateTableCrud = createAction('UPDATE_TABLE_CRUD');
export const updateTableCrudItem = createAction('UPDATE_TABLE_CRUD_ITEM');
export const updateTableCrudItemCustomCondition = createAction('UPDATE_TABLE_CRUD_ITEM_CUSTOM_CONDITION');
export const deleteTableCrudItem = createAction('DELETE_TABLE_CRUD_ITEM');
export const deleteTableCrudItemCustomCondition = createAction('DELETE_TABLE_CRUD_ITEM_CUSTOM_CONDITION');

export const updateTableCrudProperty = createAction('UPDATE_TABLE_CRUD_PROPERTY');
export const updateTableCrudPropertyItem = createAction('UPDATE_TABLE_CRUD_PROPERTY_ITEM');
export const deleteTableCrudPropertyItem = createAction('DELETE_TABLE_CRUD_PROPERTY_ITEM');

export const isLoadingAdmin = createAction('IS_LOADING_ADMIN');
export const hasErrorAdmin = createAction('HAS_ERROR_ADMIN');

export const empresa = createAction('LOAD EMPRESA');

const messageSuccessSave = 'Registro guardado exitosamente';
const messageSuccessDelete = 'Registro eliminado exitosamente';

//Datos de Empresa
export const loadEmpresa = () => async (dispatch, getstate) => {
  try {
    const { token } = getstate();
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    return fetch(`${urlEmpresa}`, requestOptions)
      .then((response) => response.json())
      .then((datos) => {
        if (datos.transaccion) {
          dispatch(empresa(datos.data));
        }
        return datos;
      });
  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return {
      transaccion: false,
      mensaje: "No se pudo recuperar la información recargue la pagina ",
    };
  }
};

export const editarDatosEmpresa = (data) => async (dispatch, getstate) => {
  dispatch(isLoadingAdmin());

  try {
    const { token } = getstate();
    const { id, ...dataSend } = data;

    const raw = JSON.stringify(dataSend);

    var requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: raw,
    };
    return fetch(`${urlEmpresa}`, requestOptions)
      .then(response => response.json())
      .then(datos => {
        if (datos.transaccion) {
          Notificacion({ type: "success", text: messageSuccessSave, });
          dispatch(empresa(datos.data));
        } else {
          dispatch(hasErrorAdmin(datos.mensaje));
        }
      });
  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo editar recargue la pagina ',
    });
    // dispatch(
    //   hasErrorAdmin("No se pudo recuperar la información recargue la pagina ")
    // );
  }
};

//Clientes
export const loadClienteReferenteForeign = (skip, take, search) => async (dispatch, getstate) => {

  try {
    const { token } = getstate();

    var requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(`${urlListarUsuarios}?skip=${skip}&take=${take}&search=${search}`, requestOptions);
    const datos = await response.json();
    return datos;

  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

//Doctores Promociones
export const loadDoctoresPromociones = (skip, take, search, sortBy = "ds.id", sort = "asc") => async (dispatch, getstate) => {

  try {
    const { token } = getstate();

    var requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(`${urlAdminListarDoctoresPromociones}?skip=${skip}&take=${take}&search=${search}&orderBy=${sortBy}&order=${sort}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      dispatch(adminTableCRUD(datos.data.data));
      dispatch(paginationData(datos.data.pagination));
      // dispatch(adminForeignData(datos.data.foreignData));
    }

    return datos;

  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const editarDoctorServicio = (id, data) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    const { descuento_inicio, descuento_fin } = data;

    const descuento_inicio_tz = new Date(descuento_inicio);
    const descuento_fin_tz = new Date(descuento_fin);
    const payload = { ...data, descuento_inicio_tz, descuento_fin_tz, descuento_inicio: moment(descuento_inicio).local().format('YYYY-MM-DD'), descuento_fin: moment(descuento_fin).local().format('YYYY-MM-DD') }

    const raw = JSON.stringify(payload);

    var requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: raw
    };

    const response = await fetch(`${urlAdminEditarDoctorServicio}/${id}${platempExp}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      Notificacion({ type: "success", text: messageSuccessSave, });
      dispatch(updateTableCrudItem(datos.data));
    }

    return datos;
  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const eliminarDoctorServicio = (id) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    var requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${urlAdminEliminarDoctorServicio}/${id}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      Notificacion({ type: "success", text: messageSuccessDelete, });
      dispatch(deleteTableCrudItem(id));
    }

    return datos;
  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

//Usuarios
export const loadUsuarioById = (id) => async (dispatch, getstate) => {

  try {
    const { token } = getstate();

    var requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(`${urlObtenerUsuarioPorId}/${id}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      // dispatch(adminItemCRUD(datos.data));
    }

    return datos;

  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const startAddRoleUser = (userId, roleName) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${urlAgregarRolUsuario}/${userId}/${roleName}`, requestOptions);
    const datos = await response.json();

    return datos;
  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};
export const startDeleteRoleUser = (userId, roleName) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    var requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${urlEliminarRolUsuario}/${userId}/${roleName}`, requestOptions);
    const datos = await response.json();

    return datos;
  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

//Oficinas
export const loadOficinas = (skip, take, search, foreign = false, sortBy = "id", sort = "asc") => async (dispatch, getstate) => {

  try {
    const { token } = getstate();

    var requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(`${urlListarOficinas}?skip=${skip}&take=${take}&search=${search}&orderBy=${sortBy}&order=${sort}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      if (foreign) {
        dispatch(adminForeignData({ oficinas: datos.data.data }));
      } else {
        dispatch(adminTableCRUD(datos.data.data));
        dispatch(paginationData(datos.data.pagination));
        dispatch(adminForeignData(datos.data.foreignData));
      }
    }

    return datos;

  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const loadHorariosPorOficina = (idOficina) => async (dispatch, getstate) => {

  try {
    const { token } = getstate();

    var requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(`${urlListarHorariosPorOficina}/${idOficina}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      dispatch(adminTableCRUD(datos.data));
    }

    return datos;

  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const horariosOficinaSet = (data, oficina_id) => async (
  dispatch,
  getstate
) => {
  // dispatch(isLoadingAdmin());
  try {
    const state = getstate();
    const { token } = state;

    const raw = JSON.stringify(data);
    var requestOptions = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: raw,
    };

    return fetch(
      urlSetHorarioByOficina + oficina_id,
      JSON.parse(JSON.stringify(requestOptions))
    )
      .then((response) => response.json())
      .then((datos) => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(""));
          return;
        }
        if (datos.transaccion) {
          // dispatch(horariosOficina(datos.data));
          //Hacer el dispatch agregando lo que viene por que el servicio no retorna nuevamente los horarios
        } else {
          dispatch(hasErrorAdmin(datos.mensaje));
        }
      });
  } catch (error) {
    dispatch(hasErrorAdmin("No se pudo guardar la información recargue la pagina "));
  }
};

export const horariosOficinaUpdate = (nueva_data, oficina_id) => async (
  dispatch,
  getstate
) => {
  dispatch(isLoadingAdmin());
  try {
    const state = getstate();
    const {
      token,
      // data_admin: { dataCRUD: data },
    } = state;


    const raw = JSON.stringify(nueva_data);
    var requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: raw,
    };

    return fetch(urlDeleteHorariosOficinas + oficina_id, requestOptions)
      .then((response) => response.json())
      .then((datos) => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(""));
          return;
        }
        if (datos.transaccion) {
          // dispatch(updateTableCrudItem(nueva_data));
          // const index_buscado = data.findIndex((d) => d.id === oficina_id);
          // data[index_buscado].hora_inicio = nueva_data.hora_inicio;
          // data[index_buscado].hora_fin = nueva_data.hora_fin;
          // const modificado_data = [...data];

          // dispatch(horariosOficina(datos.data));
          //Hacer el dispatch agregando lo que viene por que el servicio no retorna nuevamente los horarios
        } else {
          dispatch(hasErrorAdmin(datos.mensaje));
        }
      });
  } catch (error) {
    // dispatch(hasError("No se pudo guardar la información recargue la pagina "));
  }
};

export const deleteHora = (param) => async (dispatch, getstate) => {
  dispatch(isLoadingAdmin());
  try {
    const state = getstate();
    const {
      token,
      // horariosOficinas: { data },
    } = state;

    var requestOptions = {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    return fetch(urlDeleteHorariosOficinas + param, requestOptions)
      .then((response) => response.json())
      .then((datos) => {
        const { token = true } = datos;
        if (!token) {
          dispatch(jwt(""));
          return;
        }
        if (datos.transaccion) {
          // const index_eliminado = data.findIndex((d) => d.id === param);
          // const eliminado = data.splice(index_eliminado, 1);
          // const data_nueva = [...data];
          dispatch(deleteTableCrudItem(param));
          // dispatch(horariosOficina(data_nueva));
        } else {
          dispatch(hasErrorAdmin(datos.mensaje));
        }
      });
  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    dispatch(
      hasErrorAdmin("No se pudo recuperar la información recargue la pagina ")
    );
  }
};

// Contactos
export const responderContacto = (data, id) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    const raw = JSON.stringify(data);

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: raw
    };

    const response = await fetch(urlResponderContacto + `/${id}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      Notificacion({ type: "success", text: 'Respuesta enviada correctamente', });
      dispatch(updateTableCrudItem(datos.data));
    }

    return datos;
  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return {
      transaccion: false,
      mensaje: "No se pudo recuperar la información recargue la pagina ",
    };
  }
};

export const enviarMensajeAyuda = (data) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    const raw = JSON.stringify(data);

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: raw
    };

    const response = await fetch(urlEnviarMensajeAyuda, requestOptions);
    const datos = await response.json();

    return datos;
  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return {
      transaccion: false,
      mensaje: "No se pudo recuperar la información recargue la pagina ",
    };
  }
};

//Ciudades
export const loadCiudadesEspecializacionAfiliacion = (cityId, skip, take, search, sortBy = "c.nombre", sort = "asc") => async (dispatch, getstate) => {

  try {
    const { token } = getstate();

    var requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(`${urlListarCiudadesEspecializacionAfiliacion}/${cityId}?skip=${skip}&take=${take}&search=${search}&orderBy=${sortBy}&order=${sort}`, requestOptions);
    const datos = await response.json();


    if (datos.transaccion) {
      dispatch(adminTableCRUD(datos.data.data));
      dispatch(paginationData(datos.data.pagination));
      dispatch(adminForeignData(datos.data.foreignData));
    }

    return datos;

  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const crearCiudadEspecializacionAfiliacion = (id, data) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    const raw = JSON.stringify(data);

    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: raw
    };
    const response = await fetch(`${urlCrearCiudadEspecializacionAfiliacion}/${id}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      Notificacion({ type: "success", text: messageSuccessSave, });
      dispatch(updateTableCrud(datos.data));
    }

    return datos;
  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const editarCiudadEspecializacionAfiliacion = (id, data) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    const raw = JSON.stringify(data);

    var requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: raw
    };

    const response = await fetch(`${urlEditarCiudadEspecializacionAfiliacion}/${id}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      Notificacion({ type: "success", text: messageSuccessSave, });
      dispatch(updateTableCrudItemCustomCondition({
        condition: (item) => item.especializacion_id === datos.data.especializacion_id, data: datos.data
      }));
    }

    return datos;
  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const eliminarCiudadEspecializacionAfiliacion = (ciudadId, especializacionId) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    var requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${urlEliminarCiudadEspecializacionAfiliaciom}/${ciudadId}/${especializacionId}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      Notificacion({ type: "success", text: messageSuccessDelete, });
      dispatch(deleteTableCrudItemCustomCondition({ condition: (item) => item.especializacion_id !== especializacionId }));
    }

    return datos;
  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const startBusquedaCiudades = (cadenaBusqueda) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    var requestOptions = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${urlBuscarCiudades}/${cadenaBusqueda}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) return datos;
    else throw (new Error(datos.mensaje));
  } catch (error) {
    throw (error);
  }
}

// General CRUD
export const loadData = (param) => async (dispatch, getstate) => {

  const { url, skip, take, search, sortBy = "id", sort = "asc" } = param;
  try {
    const { token } = getstate();

    var requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(`${url}?skip=${skip}&take=${take}&search=${search}&orderBy=${sortBy}&order=${sort}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      dispatch(adminTableCRUD(datos.data.data));
      dispatch(paginationData(datos.data.pagination));
      if (datos.data.foreignData)
        dispatch(adminForeignData(datos.data.foreignData));
    }

    return datos;

  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const createRecord = (url, data) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    const raw = JSON.stringify(data);

    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: raw
    };
    const response = await fetch(`${url}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      Notificacion({ type: "success", text: messageSuccessSave, });
      dispatch(updateTableCrud(datos.data));
    }

    return datos;
  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const editRecord = (url, id, data) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    const raw = JSON.stringify(data);

    var requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: raw
    };

    const response = await fetch(`${url}/${id}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      Notificacion({ type: "success", text: messageSuccessSave, });
      dispatch(updateTableCrudItem(datos.data));
    }

    return datos;
  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

export const deleteRecord = (url, id) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();

    var requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${url}/${id}`, requestOptions);
    const datos = await response.json();

    if (datos.transaccion) {
      Notificacion({ type: "success", text: messageSuccessDelete, });
      dispatch(deleteTableCrudItem(id));
    }

    return datos;
  } catch (error) {
    Notificacion({
      type: "error",
      text: 'No se pudo recuperar la información recargue la pagina ',
    });
    return { transaccion: false, mensaje: 'No se pudo recuperar la información recargue la pagina ' }
  }
};

//Images
export const uploadImage = (file) => async (dispatch, getstate) => {
  try {
    const { token } = getstate();


    var formData = new FormData();
    formData.append('file', file.blob(), file.filename());

    var requestOptions = {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    const response = await fetch(urlNoticiaImagen, requestOptions);
    const datos = await response.json();

    if (datos.location) {
      return datos.location
    }
    throw (new Error('Error el subir imagen'));
  } catch (error) {
    throw (error);
  }
};

