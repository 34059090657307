import { makeStyles } from '@material-ui/core';
import React from 'react'

const useStyles = makeStyles((theme) => (
  {
    messageContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      textAlign: 'center',
    },
  }
));

export const MessageTextCentered = ({children}) => {

  const classes = useStyles();

  return (
    <div className={classes.messageContainer}>
      <div>
          {children}
      </div>
    </div>
  )
}
