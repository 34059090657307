import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import _ from 'lodash';

import { makeStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";

import Loading from "../../Loading";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "../../CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput.js";
import WarningLeaveFormPage from "components/ui/WarningLeaveFormPage";

import Paper from "@material-ui/core/Paper";
import styles from 'assets/jss/material-dashboard-react/components/customInputStyle.js';
import { useFormServicios } from "hooks/medico/servicios/useFormServicios";
import { validacionesServicio } from "utils/validaciones";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { Checkbox, InputLabel, List, ListItem, ListItemSecondaryAction, ListItemText, TextField } from "@material-ui/core";
import { useSelector } from "react-redux";
import { SketchPicker } from 'react-color';
import { primaryColor } from "assets/jss/material-dashboard-react";
import Notificacion from "components/ui/Notificacion";
import { validarServicioRangoFechaPromocion } from "utils/doctor/servicios/servicios";
import { useMemo } from "react";
import dashboardStyles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import { FormErrorLabel } from "components/forms/labels/FormErrorLabel";
import formStyles from "assets/jss/material-dashboard-react/forms/formStyle";

const useStyles = makeStyles((theme) => ({
  ...dashboardStyles,
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1.3),
    },
  },
  colorPopover: {
    position: 'absolute',
    zIndex: '4'
  },
  colorPickerCover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  colorSelectedContainer: {
    backgroundColor: ({ colorContainerSelected }) => colorContainerSelected,
    height: "14px",
    cursor: 'pointer',
    padding: '5px',
    borderRadius: '3px',
    marginTop: '5px',
  },
  ...styles
}));

const useFormStyles = makeStyles(formStyles);

const ServiciosForm = ({
  doctor_id,
  addService,
  servicio,
  onCloseForm,
  handleGuardarOficinasAsignadas,
  especialidades,
  ...props
}) => {

  const promocionVigente = useMemo(() => validarServicioRangoFechaPromocion(servicio), [servicio]);

  const { valores, handleChange, handleChangeKeyValue, handleClick, errors, error, formDirty } = useFormServicios({
    initStateForm: {
      nombre: servicio.nombre ? servicio.nombre : "",
      promocion_detalles: servicio.promocion_detalles ? servicio.promocion_detalles : "",
      descuento_valor: servicio.descuento_valor ? servicio.descuento_valor.toString() : '0',
      descuento_inicio: servicio.descuento_inicio ? moment(servicio.descuento_inicio).format("YYYY-MM-DD") : null,
      descuento_fin: servicio.descuento_fin ? moment(servicio.descuento_fin).format("YYYY-MM-DD") : null,
      precio: servicio.precio ? servicio.precio.toString() : '0',
      id: servicio.id,
      doctor_id: doctor_id,
      background_color: servicio.background_color ?? primaryColor[0],
      especializacion: servicio.especializacion?.id,
      doctorEspecializacion: servicio.doctorEspecializacion ?? null,
    },
    addService,
    hasError: props.hasError,
    validaciones: validacionesServicio,
    onCloseForm
  });

  const [oficinasFormDirty, setOficinasFormDirty] = useState(false);

  //Color picker
  const [showColorPicker, setShowColorPicker] = useState(false);

  //estilos
  const classes = useStyles({ colorContainerSelected: valores.background_color });

  const formClasses = useFormStyles();

  const esOficinaAsignada = (oficinaId) => {
    const result = oficinasAsignadas.filter(oficina => oficina.id === oficinaId);

    if (result.length === 1) return true
    return false;
  }

  const officeHasSpecialtie = (office, speciatieId) => {
    const specialties = office.doctorEspecializaciones;
    let foundFlag = false;
    for (let i = 0; i < specialties.length; i++) {
      const specialtie = specialties[i]?.especializacion;
      if (speciatieId === specialtie.id) {
        foundFlag = true;
        break;
      }
    }
    return foundFlag;
  }

  // const [changes, setChanges] = useState(false);
  const { data: oficinas } = useSelector(state => state.data_oficinas);
  const { oficinasAsignadas = [] } = servicio;

  const oficinaInitState = useRef(servicio.id
    ? (oficinas.map(oficina => esOficinaAsignada(oficina.id) ? { ...oficina, isChecked: true } : { ...oficina, isChecked: false }))
    : oficinas.map(oficina => ({ ...oficina, isChecked: false })));

  const [oficinasState, setOficinasState] = useState(
    servicio.id && servicio.doctorEspecializacion
      ? oficinaInitState.current.filter(item => officeHasSpecialtie(item, servicio.doctorEspecializacion?.especializacion?.id))
      : []
  );

  const filterOfficeBySpecialtie = (_, values) => {
    handleChangeKeyValue('doctorEspecializacion', values);
    // Filter ofices whith specialties selected
    const officesFiltered = oficinaInitState.current.filter(item => officeHasSpecialtie(item, values.especializacion.id));
    setOficinasState(officesFiltered);
  }

  const handleToggle = (id) => () => {
    // setChanges(true);
    const newOficinasState = oficinasState.map(oficina => oficina.id === id ? { ...oficina, isChecked: !oficina.isChecked } : oficina);
    setOficinasState(newOficinasState);
  };

  const handleSave = () => {

    const oficinasIdToSave = [];
    oficinasState.map(oficina => { if (oficina.isChecked) oficinasIdToSave.push(oficina.id); return null; });
    if (oficinasIdToSave.length === 0) {
      Notificacion({
        type: "error",
        text: "Debe seleccionar por lo menos una especialidad y una oficina.",
      });
      return;
    }
    handleClick(oficinasIdToSave);

  }

  const handleToggleShowColorPicker = () => setShowColorPicker(!showColorPicker);
  const handleCloseColorPicker = () => setShowColorPicker(false);

  useEffect(() => {
    if (_.isEqual(oficinasState, oficinaInitState.current)) setOficinasFormDirty(false);
    else setOficinasFormDirty(true);
  }, [oficinasState])

  useEffect(() => {
    return () => {
      setOficinasFormDirty(false);
    }
  }, [])
  

  return (
    <div>
      <Loading title="Cargando..." loading={props.isLoading}>
        <>
          {/* Warning on exit page */}
          {/* Form stateL: {(formDirty || oficinasFormDirty) ? 'form dirty' : 'form no dirty'} */}
          <WarningLeaveFormPage when={formDirty || oficinasFormDirty} />
          <Card style={{ paddingRight: "5px", paddingLeft: "5px" }}>
            <CardHeader color="primary">
              <strong className={classes.cardTitle}>{servicio.nombre ? servicio.nombre : 'Nuevo Servicio'}</strong>
            </CardHeader>

            {/* Servicio informacion */}
            <form
              style={{ margin: "15px" }}
              className={classes.root}
              noValidate="noValidate"
              autoComplete="off"
            >
              <GridContainer>
                <GridItem xs={12} sm={5} md={6}>
                  <CustomInput
                    labelText="Nombre"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    id="nombre"
                    inputProps={{
                      onChange: handleChange,
                      name: "nombre",
                      value: valores.nombre,
                      disabled: promocionVigente
                    }}
                    error={errors.nombre ? true : false}
                    success={!errors.nombre}
                  />
                  {errors.nombre && (
                    <FormErrorLabel>{errors.nombre}</FormErrorLabel>
                  )}
                </GridItem>
                <GridItem xs={12} sm={5} md={6}>
                  <CustomInput
                    labelText="Precio del servicio"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    id="precio"
                    inputProps={{
                      onChange: handleChange,
                      name: "precio",
                      value: valores.precio,
                      disabled: promocionVigente
                    }}
                    error={errors.precio ? true : false}
                    success={!errors.precio}
                  />
                  {errors.precio && (
                    <FormErrorLabel>{errors.precio}</FormErrorLabel>
                  )}
                </GridItem>
                <GridItem xs={12} sm={5} md={6}>

                  <Autocomplete

                    value={valores.doctorEspecializacion}
                    getOptionSelected={(option, value) => option.id === value.id}
                    options={especialidades}
                    onChange={filterOfficeBySpecialtie}


                    getOptionLabel={(option) => option?.especializacion?.nombre}


                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="especializaciones"
                        label="Especialidad"
                        style={{ margin: '0px', marginTop: '27px' }}
                      />
                    )}
                  />
                  {errors.especializaciones && (
                    <FormErrorLabel>{errors.especializaciones}</FormErrorLabel>
                  )}


                </GridItem>
                <GridItem xs={12} sm={2} md={6}>
                  <div style={{ marginTop: '27px' }}>
                    <InputLabel
                      className={formClasses.formLabel}
                    >
                      Color en la agenda
                    </InputLabel>
                    <div className={classes.colorSelectedContainer} onClick={promocionVigente ? null : handleToggleShowColorPicker}>
                    </div>
                    {
                      showColorPicker &&
                      <div className={classes.colorPopover}>
                        <div className={classes.colorPickerCover} onClick={handleCloseColorPicker}></div>
                        <div>
                          <SketchPicker
                            color={valores.background_color}
                            onChangeComplete={({ hex }) => handleChangeKeyValue('background_color', hex)}
                          />
                        </div>
                      </div>
                    }

                  </div>
                </GridItem>
              </GridContainer>

              {/* Oficinas */}
              <Card style={{ marginBottom: "15px", marginTop: "40px" }}>
                <CardHeader color="primary">
                  <strong className={classes.cardTitle}> Oficinas Asignadas</strong>
                </CardHeader>
                <Paper >

                  <List dense className={classes.root}>
                    {
                      oficinasState.map(oficina => (
                        <ListItem key={oficina.id}>
                          <ListItemText id={oficina.id} primary={oficina.institucion ? oficina.institucion.nombre : oficina.direccion} />
                          <ListItemSecondaryAction>
                            <Checkbox
                              edge="end"
                              onChange={handleToggle(oficina.id)}
                              checked={oficina.isChecked}
                              inputProps={{ 'aria-labelledby': 1 }}
                              color="primary"
                              disabled={promocionVigente}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))
                    }
                  </List>
                  {
                    oficinasState.length === 0 && (
                      <div style={{ textAlign: 'center', padding: '5px' }} >
                        No se han encontrado oficinas con la especialidad seleccionada
                      </div>
                    )
                  }
                </Paper>
              </Card>
              {
                !promocionVigente &&
                <div
                  style={{
                    margin: "20px 8px",
                    flex: "1",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    disabled={promocionVigente}
                    variant="outlined"
                    color="warning"
                    onClick={() => handleSave()}
                  >
                    Guardar
                  </Button>
                </div>
              }

            </form>
          </Card>
        </>
      </Loading>
      {error.mensaje && (
        <Alert severity={`${[error.title]}`}>
          <AlertTitle>
            {error.title === "error" ? "Error" : "Success"}
          </AlertTitle>
          {error.mensaje}
        </Alert>
      )}
    </div>
  );
};

export default ServiciosForm;
