


import React from 'react';
import CRUD from 'components/CRUD/Crud.component';
import { urlAdminTransaction } from 'constants/urls';
import { formatPayphonePrice } from 'utils/format';
import { formatDate } from 'utils/format/format';
import { getTransactionString } from 'utils/format';

const Transactions = () => {
  const tableData = [
    {
      type: "inputDisabled",
      label: "Id",
      name: 'id',
      showOnCreate: false,
      showOnTable: true,
      tableProps: {
        title: 'Id',
        sort: {
          sortColumnName: 'id',
          sortColumnNameDB: 'id',
        }
      },

    },
    {

      name: "payment_value",
      label: "Valor",
      tableProps: {
        formatData: (value) => formatPayphonePrice(value),
        sort: {
          sortColumnName: 'payment_value',
          sortColumnNameDB: 'payment_value',
        }
      },
    },
    {

      name: "pp_transaction_id",
      label: "Id transacción",
      tableProps: {
        sort: {
          sortColumnName: 'pp_transaction_id',
          sortColumnNameDB: 'pp_transaction_id',
        }
      },
    },
    {

      name: "pp_client_transaction_id",
      label: "Client Id transacción",
      tableProps: {
        sort: {
          sortColumnName: 'pp_client_transaction_id',
          sortColumnNameDB: 'pp_client_transaction_id',
        }
      },
    },
    {

      name: "transaction_type",
      label: "Tipo de transacción",
      tableProps: {
        formatData: (value) => getTransactionString(value),
        sort: {
          sortColumnName: 'transaction_type',
          sortColumnNameDB: 'transaction_type',
        }
      },
    },
    {

      name: "transaction_test",
      label: "Ambiente",
      tableProps: {
        formatData: (value) => value ? 'Pruebas' : 'Producción',
        sort: {
          sortColumnName: 'transaction_type',
          sortColumnNameDB: 'transaction_type',
        }
      },
    },
    {

      name: "payment_date",
      label: "Fecha",
      tableProps: {
        formatData: (value) => formatDate(value),
        title: 'Fecha',
        sort: {
          sortColumnName: 'payment_date',
          sortColumnNameDB: 'payment_date',
        }
      },
    },
    {
      type: "input",
      name: "user",
      label: "Usuario",
      tableProps: {
        formatData: (value) => `${value?.login}`,
        sort: {
          sortColumnName: 'user.login',
          sortColumnNameDB: 'user.login',
        }
      },
    },
    {
      type: "input",
      name: "pp_transfer_to",
      label: "Split",
      tableProps: {
        // formatData: (value) => formatPayphonePrice(value),
        sort: {
          sortColumnName: 'pp_transfer_to',
          sortColumnNameDB: 'pp_transfer_to',
        }
      },
    },
  ];

  const data = {
    defaultSortByColumn: 'payment_date',
    defaultSortOrder: 'desc',
    tableData,
    urls: {
      url: urlAdminTransaction,
    }
  }

  return (
    <CRUD data={data} hideOptions />
  )
}

export default Transactions;
