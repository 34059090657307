import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { loadServicios } from 'redux/actions/aMedico';
import { crearServicio } from 'redux/actions/aMedico';
import { editarServicio } from 'redux/actions/aMedico';
import { eliminarServicio } from 'redux/actions/aMedico';
import ServiciosMedico from 'components/Medico/Servicios/ServiciosMedico';
import { useDispatch } from 'react-redux';
import { getOficinasDoctor } from 'redux/actions/aOficinas';

const ServiciosDoctor = () => {
  const { doctor_id } = useSelector(state => state.dataUser.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOficinasDoctor());
  }, [dispatch])
  

  return (
    <ServiciosMedico doctorId={doctor_id} createService={crearServicio} updateService={editarServicio} deleteService={eliminarServicio} loadServices={loadServicios}   />
  );
};

export default ServiciosDoctor;
