import CustomTabsControlled from 'components/CustomTabs/CustomTabsControlled';
import { InstitucionAsociaciones } from 'components/institucion/profesionales/InstitucionAsociaciones';
import { InstitucionAsociacionesSolicitudes } from 'components/institucion/profesionales/InstitucionAsociacionesSolicitudes';
import React, { useEffect, useMemo, useState } from 'react'
import { formatDate } from 'utils/format/format';

export const InstitucionProfesionalesAsociacion = () => {

  const [tabSelected, setTabSelected] = useState(0);

  const tableData = useMemo(() => [
    {
      hideOnForm: true,
      name: "created_at",
      label: "Fecha",
      tableProps: {
        formatData: (value) => formatDate(value),
        sort: {
          sortColumnName: 'od.created_at',
          sortColumnNameDB: 'od.created_at',
        }
      },
    },
    {
      type: "input",
      name: "doctor_nombres",
      label: "Nombres",
      objectName: "doctor",
      tableProps: {
        formatData: (value) => value['nombres'],
        sort: {
          sortColumnName: 'doctor.nombres',
          sortColumnNameDB: 'doctor.nombres',
        }
      },
    },
    {
      type: "input",
      name: "doctor_apellidos",
      objectName: "doctor",
      label: "Apellidos",
      tableProps: {
        formatData: (value) => value['apellidos'],
        sort: {
          sortColumnName: 'doctor.apellidos',
          sortColumnNameDB: 'doctor.apellidos',
        }
      },
    },
    {
      type: "input",
      name: "oficina_direccion",
      objectName: "oficina",
      label: "Oficina Dirección",
      tableProps: {
        formatData: (value) => value['direccion'],
        sort: {
          sortColumnName: 'doctor.apellidos',
          sortColumnNameDB: 'doctor.apellidos',
        }
      },
    },
    {
      type: "input",
      name: "oficina_doctorEspecializaciones_especialidad_nombre",
      objectName: "oficina",
      label: "Especialidad",
      tableProps: {
        formatData: ({ doctorEspecializaciones }) =>
          doctorEspecializaciones && doctorEspecializaciones[0].especializacion
            ? doctorEspecializaciones[0]?.especializacion?.nombre
            : '',
        sort: {
          sortColumnName: 'doctor.apellidos',
          sortColumnNameDB: 'doctor.apellidos',
        }
      },
    },

  ], []);

  useEffect(() => {
    if (localStorage.getItem('notificationSelected')) {
      const notificacion = JSON.parse(localStorage.getItem('notificationSelected'));
      if (notificacion.route === '/institucion/profesionales')
        setTabSelected(1);
    }

  }, []);

  return (
    <>
      <CustomTabsControlled
        value={tabSelected}
        setValue={setTabSelected}
        title="Profesionales"
        headerColor="primary"
        tabsPosition="body"
        tabs={[
          {
            tabName: 'Asociaciones',
            // tabIcon: LocationOnIcon,
            tabContent: <InstitucionAsociaciones tableData={tableData} />,
          },
          {
            tabName: 'Solicitudes',
            // tabIcon: LocationOnIcon,
            tabContent:
              <InstitucionAsociacionesSolicitudes
                tableData={tableData}

              />,
          },
        ]}
      />
    </>
  )
}
