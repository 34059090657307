// import { dataCitas } from 'constants/estructuras';
import { encriptarRsa } from 'utils/encriptar';
import {urlActivarCuentaUsuario, urlActualizarPasswordPorResetKey, urlUpdateEmail, urlVerificarResetKey} from '../constants/urls';

export const activarUsuario = async (codigoActivacionCuenta) => {
    return new Promise ( (resolve, reject) => {
        
        var requestOptions = { method: 'POST'};
   
        return fetch(`${urlActivarCuentaUsuario}${codigoActivacionCuenta}`, requestOptions)
          .then(response => response.json())
          .then(datos => {
            resolve(datos);
            // if (datos.transaccion) {
            //   dispatch(cajas(datos.data));
            // } else {
            //   dispatch(hasErrorCajas(datos.mensaje));
            // }
          })
          .catch( err => reject(err));
    });
}

export const updateEmail = async (codigo) => {
    return new Promise ( (resolve, reject) => {
        
        var requestOptions = {  method: 'PATCH' };
   
        return fetch(`${urlUpdateEmail}${codigo}`, requestOptions)
          .then(response => response.json())
          .then(datos => {
            resolve(datos);
          })
          .catch( err => reject(err));
    });
}

export const verificarResetKey = async (resetKey) => {
    return new Promise ( (resolve, reject) => {
        var requestOptions = { method: 'GET'};
   
        return fetch(`${urlVerificarResetKey}${resetKey}`, requestOptions)
          .then(response => response.json())
          .then(datos => resolve(datos))
          .catch( err => reject(err));
    });
}
export const confirmarCambioPassword = async (resetKey, newPassword) => {
    return new Promise ( async(resolve, reject) => {
      try {
        const data = await encriptarRsa(JSON.stringify({newPassword}));
        if (data.error) reject("Error al encriptar password");
        var requestOptions = {
          method: 'PATCH', 
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ data }),
        };
   
        return fetch(`${urlActualizarPasswordPorResetKey}${resetKey}`, requestOptions)
          .then(response => response.json())
          .then(datos => resolve(datos))
          .catch( err => reject(err));
        
      } catch (error) {
        reject(error)
      }
        
    });
}