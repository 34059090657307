import { FormControl, TextField } from '@material-ui/core'
import React from 'react'

export const SearchTextField = ({ handleChange, value, style, ...props }) => {
  return (
    <FormControl fullWidth style={style}>
      <TextField
        label="Búsqueda"
        variant="outlined"
        value={value}
        onChange={handleChange}
        InputProps={{
          style: {
            borderRadius: "50px",
            background: 'white'
          }
        }}
        {...props}
      />
    </FormControl>
  )
}
