import React from 'react'

export const CalendarEvent = ({event}) => {
    // const {title, user} = event;
    // console.log(event)
    const { c_nombres, c_apellidos } = event;
    return (
        <div>
            <strong>{`${c_nombres} ${c_apellidos}`}</strong>
            {/* <strong>- {user.name} </strong> */}
        </div>
    )
}
